/**
 * columnConfigs must have an object with a 'columns' field
 * participant list must have a 'default' key
 */
export const defaultColumnConfig = {
  default: {
    columns: ['name', 'statusNode', 'access', 'password', 'settings'],
    breakpoints: [
      {
        minWidth: 635,
        columns: ['name', 'statusNode', 'access', 'password', 'settings'],
      },
      { minWidth: 470, columns: ['name', 'password', 'settings'] },
    ],
  },
};

export const SSOColumnConfig = {
  default: {
    columns: ['name', 'statusNode', 'access', 'settings'],
    breakpoints: [
      { minWidth: 635, columns: ['name', 'statusNode', 'access', 'settings'] },
      { minWidth: 470, columns: ['name', 'settings'] },
    ],
  },
};

export const hsaColumnConfig = {
  default: {
    columns: ['name', 'organizationName', 'statusNode', 'access', 'password', 'settings'],
    breakpoints: [
      {
        minWidth: 635,
        columns: ['name', 'organizationName', 'statusNode', 'access', 'password', 'settings'],
      },
      { minWidth: 470, columns: ['name', 'password', 'settings'] },
    ],
  },
};

export const hsaSSOColumnConfig = {
  default: {
    columns: ['name', 'organizationName', 'statusNode', 'access', 'settings'],
    breakpoints: [
      { minWidth: 635, columns: ['name', 'organizationName', 'statusNode', 'access', 'settings'] },
      { minWidth: 470, columns: ['name', 'settings'] },
    ],
  },
};

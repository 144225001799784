import { MenuItem, Select } from '@mui/material';
import React, { FC } from 'react';

export const allAdherenceStatuses = ['All', 'Adherent (≥80%)', 'Not adherent (<80%)'] as const;
export type IAllAdherenceStatuses = typeof allAdherenceStatuses[number];

export type AdherenceStatusSelectProps = {
  adherentStatus: IAllAdherenceStatuses;
  setAdherentStatus: (status: IAllAdherenceStatuses) => void;
};

export const AdherenceStatusSelect: FC<AdherenceStatusSelectProps> = ({
  adherentStatus,
  setAdherentStatus,
}) => {
  return (
    <Select
      label="Adherence Status"
      value={adherentStatus}
      onChange={(e) => {
        setAdherentStatus(e.target.value.toString() as IAllAdherenceStatuses);
      }}
      sx={{ width: 200 }}
    >
      {allAdherenceStatuses.map((status) => {
        return (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        );
      })}
    </Select>
  );
};

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ExecutiveView from 'Utilization/ExecutiveView';
import OrgView from './OrgView';
import { admitTypesList } from './Context';

import { styles } from './styles';

interface ContentSectionProps {
  utilizationData: any;
  path: string;
}
interface ContentSectionViewProps {
  utilizationData: any;
}

const ContentSectionView: React.FC<ContentSectionViewProps> = ({ utilizationData }) => {
  return (
    <div style={styles.contentSection}>
      <ExecutiveView />
      <div style={styles.orgView}>
        <OrgView utilizationData={utilizationData} />
      </div>
    </div>
  );
};

const ContentSection: React.FC<ContentSectionProps> = ({ path, utilizationData }) => {
  const routePaths = [''].concat(admitTypesList);

  const renderRoute = (routePath: string) => (
    <Route
      exact={routePath === ''}
      key={`${path}/${routePath}`}
      path={`${path}/${routePath}`}
      render={() => <ContentSectionView utilizationData={utilizationData} />}
    />
  );

  return <Switch>{routePaths.map((routePath) => renderRoute(routePath))}</Switch>;
};

export default ContentSection;

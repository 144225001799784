import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { formatDate } from '@util/dateFunctions';
import { admissionLength } from '@util/admissionType';
import { useGetSessionUser } from '@util/session';

import { EHR_TRUCHART } from 'Settings/userSettingsPropType';

import { resetSelectedHosp } from '@global-state/redux/hospitalizationsSlice';

import { Dropdown, Button } from 'react-bootstrap';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NatureRoundedIcon from '@mui/icons-material/NatureRounded';
import { SpinnerOrError } from '@intus-ui';
import SimpleTooltip from '@intus-ui/components/SimpleTooltip';
import CustomDropdownToggle from '@intus-ui/components/CustomDropdownToggle';
import DropdownTitleAboveValue from 'Dashboard/DropdownTitleAboveValue';
import pointPropType from '../../Participants/acuity/riskTrendLine/pointPropType';
import DetailCard from './detailCard';
import HospIcon from './lengthIcon';

import '@intus-ui/components/filters/FilterDropdowns.scss';
import '@intus-ui/styles/Utilities.scss';

const riskTooltip = () => (
  <div className="p-2 d-flex flex-column align-items-start justify-content-start">
    <h6 className="text-secondary pb-2">Length of Stay</h6>
    <div className="d-flex flex-row align-items-center justify-content-start">
      <HospIcon stayLength={1} />
      <h6 className="pl-2">{admissionLength.SHORT.name}</h6>
    </div>
    <div className="d-flex flex-row align-items-center justify-content-start">
      <HospIcon stayLength={3} />
      <h6 className="pl-2">{admissionLength.MEDIUM.name}</h6>
    </div>
    <div className="d-flex flex-row align-items-center justify-content-start">
      <HospIcon stayLength={9} />
      <h6 className="pl-2">{admissionLength.LONG.name}</h6>
    </div>
    <div className="d-flex flex-row align-items-center justify-content-start">
      <HospIcon stayLength={null} />
      <h6 className="pl-2">{admissionLength.UNKNOWN.name}</h6>
    </div>
  </div>
);

const HospInfo = (
  <SimpleTooltip content={riskTooltip()}>
    <InfoIcon variant="success" className="info-icon cursor-pointer p-0" />
  </SimpleTooltip>
);

const RecentHospitalizations = ({
  hospitalizations,
  selectedHosp,
  resetSelected,
  patientDetail,
}) => {
  const [timeFilter, setTimeFilter] = useState('All');
  const [typeFilter, setTypeFilter] = useState('All');

  const filteredByTime = () => {
    if (timeFilter === 'All') return hospitalizations;
    if (timeFilter === admissionLength.UNKNOWN.name) {
      return hospitalizations.filter((item) => item.lengthOfStay === null);
    }
    if (timeFilter === admissionLength.LONG.name) {
      return hospitalizations.filter(
        (item) => item.lengthOfStay !== null && item.lengthOfStay >= admissionLength.LONG.min_cutoff
      );
    }
    if (timeFilter === admissionLength.MEDIUM.name) {
      return hospitalizations.filter(
        (item) =>
          item.lengthOfStay !== null &&
          item.lengthOfStay > admissionLength.MEDIUM.min_cutoff &&
          item.lengthOfStay < admissionLength.MEDIUM.max_cutoff
      );
    }
    // admissionsLength.SHORT
    return hospitalizations.filter(
      (item) =>
        item.lengthOfStay !== null &&
        item.lengthOfStay >= admissionLength.SHORT.min_cutoff &&
        item.lengthOfStay <= admissionLength.SHORT.max_cutoff
    );
  };

  const filterByType = () => {
    const hosps = filteredByTime();
    if (typeFilter === 'All') return hosps;
    if (typeFilter === 'Emergency') {
      return hosps.filter(({ placeOfStay }) => placeOfStay.toLowerCase().startsWith('emerg'));
    }
    if (typeFilter === 'Inpatient') {
      return hosps.filter(({ placeOfStay }) => placeOfStay.toLowerCase().startsWith('inpat'));
    }
    if (typeFilter === 'SNF') {
      return hosps.filter(({ placeOfStay }) => placeOfStay.toLowerCase().startsWith('skill'));
    }
    if (typeFilter === 'ALF') {
      return hosps.filter(({ placeOfStay }) => placeOfStay.toLowerCase().includes('assist'));
    }
    // LTC (other)
    return hosps.filter(
      ({ placeOfStay }) =>
        !placeOfStay.toLowerCase().startsWith('emerg') &&
        !placeOfStay.toLowerCase().startsWith('inpat') &&
        !placeOfStay.toLowerCase().startsWith('skill') &&
        !placeOfStay.toLowerCase().includes('assist')
    );
  };

  const timeDropdown = (
    <Dropdown id="admitTimeFilter" onSelect={(eventKey) => setTimeFilter(eventKey)}>
      <Dropdown.Toggle as={CustomDropdownToggle} id="admits-time-dropdown">
        <DropdownTitleAboveValue
          title="Length"
          value={timeFilter === admissionLength.UNKNOWN.name ? 'Unknown' : timeFilter}
          icon={<DateRangeIcon fontSize="small" className="mr-2" />}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu align="right">
        <Dropdown.Item eventKey="All">All</Dropdown.Item>
        <Dropdown.Item eventKey={admissionLength.SHORT.name}>
          {admissionLength.SHORT.name}
        </Dropdown.Item>
        <Dropdown.Item eventKey={admissionLength.MEDIUM.name}>
          {admissionLength.MEDIUM.name}
        </Dropdown.Item>
        <Dropdown.Item eventKey={admissionLength.LONG.name}>
          {admissionLength.LONG.name}
        </Dropdown.Item>
        <Dropdown.Item eventKey={admissionLength.UNKNOWN.name}>Unknown</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const {
    organization: { subscriptions },
  } = useGetSessionUser();
  const isTruChartSubscriber = subscriptions.includes(EHR_TRUCHART);

  const typeDropdown = (
    <Dropdown id="admitTypeFilter" onSelect={(eventKey) => setTypeFilter(eventKey)}>
      <Dropdown.Toggle as={CustomDropdownToggle} id="admits-type-dropdown">
        <DropdownTitleAboveValue
          title="Type"
          value={typeFilter}
          icon={<NatureRoundedIcon fontSize="small" className="mr-2" />}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu align="right">
        <Dropdown.Item eventKey="All">All</Dropdown.Item>
        <Dropdown.Item eventKey="Emergency">Emergency</Dropdown.Item>
        <Dropdown.Item eventKey="Inpatient">Inpatient</Dropdown.Item>
        {isTruChartSubscriber && (
          <>
            <Dropdown.Item eventKey="SNF">SNF</Dropdown.Item>
            <Dropdown.Item eventKey="ALF">ALF</Dropdown.Item>
          </>
        )}
        <Dropdown.Item eventKey="LTC">LTC</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  if (!hospitalizations) return <SpinnerOrError />;
  return (
    <div id="fluidContentContainer" style={styles.container}>
      <div className="d-flex flex-shrink-1 justify-content-between align-items-center w-100">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <h2 className="text-secondary pb-1 pr-1">Admits</h2>
          {HospInfo}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="mr-3">{typeDropdown}</div>
          {timeDropdown}
        </div>
      </div>
      {selectedHosp ? (
        <>
          <div className="d-flex flex-row flex-grow-1 overflow-hidden justify-content-center align-items-center w-100 pb-2">
            <h6 className="text-info font-weight-bold pr-2">
              Currently Viewing: {formatDate(selectedHosp.data.x, 'MMM D, YYYY')}{' '}
            </h6>
            <Button
              className="view-button border-info d-flex flex-row align-items-center justify-content-center font-weight-bold pl-3 pr-0 py-0"
              onClick={() => resetSelected()}
            >
              <div>View All</div>
              <ChevronRightRoundedIcon className="chevron p-0" style={{ fontSize: '2.0rem' }} />
            </Button>
          </div>
          <div className="selected-bg w-100 px-4 pt-1 rounded-lg">
            <DetailCard
              info={hospitalizations[selectedHosp.data.hospIndex]}
              patientDetail={patientDetail}
              key="hospitalization-detail-selected"
            />
          </div>
        </>
      ) : (
        <div className="w-100 h-100 d-flex flex-grow-1 overflow-hidden">
          {filterByType()?.length ? (
            <div className="w-100 h-100 overflow-auto pb-5">
              {filterByType().map((item, i) => (
                <DetailCard
                  info={item}
                  patientDetail={patientDetail}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`hospitalization-detail-${i}`}
                  className={`${
                    i < hospitalizations.length - 1 ? 'border-bottom border-muted' : ''
                  }`}
                />
              ))}
            </div>
          ) : (
            <SpinnerOrError error="No hospitalizations available." />
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    overflowY: 'auto',
  },
};

RecentHospitalizations.propTypes = {
  hospitalizations: PropTypes.arrayOf(PropTypes.object.isRequired),
  selectedHosp: pointPropType,
  resetSelected: PropTypes.func.isRequired,
};

RecentHospitalizations.defaultProps = {
  hospitalizations: null,
  selectedHosp: null,
};

const mapState = (state) => ({
  hospitalizations: state.hospitalizations.recentHosps,
  selectedHosp: state.hospitalizations.selectedHosp,
  patientDetail: state.patientDetail.patient,
});

const mapDispatch = (dispatch) => ({
  resetSelected: () => dispatch(resetSelectedHosp()),
});

export default withRouter(connect(mapState, mapDispatch)(RecentHospitalizations));

import React, { useRef, useState, useEffect } from 'react';

import {
  Checkbox,
  Panel,
  DefaultButton,
  TextField,
  SpinButton,
  Dropdown,
  IDropdownOption,
} from '@fluentui/react';
import { getFeatureFlag } from '@config';
import styles from './Chat.module.css';

import {
  ragChatApi,
  RetrievalMode,
  ChatAppResponse,
  ChatAppResponseOrError,
  ChatAppRequest,
  ResponseMessage,
} from '../../api';
import { Answer, AnswerError, AnswerLoading } from '../../components/Answer';
import { QuestionInput } from '../../components/QuestionInput';
import { ExampleList } from '../../components/Example';
import { UserChatMessage } from '../../components/UserChatMessage';
import { AnalysisPanel, AnalysisPanelTabs } from '../../components/AnalysisPanel';
import { SettingsButton } from '../../components/SettingsButton';
import { ClearChatButton } from '../../components/ClearChatButton';

const Chat = () => {
  const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
  const [promptTemplate, setPromptTemplate] = useState<string>('');
  const [retrieveCount, setRetrieveCount] = useState<number>(3);
  const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
  const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
  const [shouldStream, setShouldStream] = useState<boolean>(true);
  const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
  const [excludeCategory, setExcludeCategory] = useState<string>('');
  const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);

  const lastQuestionRef = useRef<string>('');
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const [activeCitation, setActiveCitation] = useState<string>();
  const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<
    AnalysisPanelTabs | undefined
  >(undefined);

  const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
  const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
  const [streamedAnswers, setStreamedAnswers] = useState<
    [user: string, response: ChatAppResponse][]
  >([]);
  const handleAsyncRequest = async (
    question: string,
    answers: [string, ChatAppResponse][],
    // @ts-ignore
    setAnswers: Function,
    responseBody: ReadableStream<any>
  ) => {
    let answer = '';
    let askResponse: ChatAppResponse = {} as ChatAppResponse;

    const updateState = (newContent: string) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          answer += newContent;
          const latestResponse: ChatAppResponse = {
            ...askResponse,
            choices: [
              {
                ...askResponse.choices[0],
                message: { content: answer, role: askResponse.choices[0].message.role },
              },
            ],
          };
          setStreamedAnswers([...answers, [question, latestResponse]]);
          resolve(null);
        }, 33);
      });
    };
    const decoder = new TextDecoder('utf-8');
    // let resporesponsnseBody = []; // Store chunks of parsed responses

    const reader = responseBody.getReader();
    let content = '';

    try {
      setIsStreaming(true);
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        content += decoder.decode(value, { stream: true });
        const parts = content.split('\n');
        // @ts-ignore
        content = parts.pop();

        for (const line of parts) {
          const event = JSON.parse(line);
          const choices = event.choices && event.choices[0];

          if (choices?.context?.data_points) {
            choices.message = choices.delta;
            askResponse = event;
          } else if (choices?.delta?.content) {
            setIsLoading(false);
            await updateState(choices.delta.content);
          } else if (choices?.context) {
            askResponse.choices[0].context = {
              ...askResponse.choices[0].context,
              ...choices.context,
            };
          } else if (event.error) {
            throw new Error(event.error);
          }
        }
      }
    } finally {
      setIsStreaming(false);
    }
    const fullResponse: ChatAppResponse = {
      ...askResponse,
      choices: [
        {
          ...askResponse.choices[0],
          message: { content: answer, role: askResponse.choices[0].message.role },
        },
      ],
    };
    return fullResponse;
  };

  // const client = undefined;

  const makeApiRequest = async (question: string) => {
    lastQuestionRef.current = question;

    error && setError(undefined);
    setIsLoading(true);
    setActiveCitation(undefined);
    setActiveAnalysisPanelTab(undefined);

    try {
      const messages: ResponseMessage[] = answers.flatMap((a) => [
        { content: a[0], role: 'user' },
        { content: a[1].choices[0].message.content, role: 'assistant' },
      ]);

      const request: ChatAppRequest = {
        messages: [...messages, { content: question, role: 'user' }],
        stream: shouldStream,
        context: {
          overrides: {
            prompt_template: promptTemplate.length === 0 ? undefined : promptTemplate,
            exclude_category: excludeCategory.length === 0 ? undefined : excludeCategory,
            top: retrieveCount,
            retrieval_mode: retrievalMode,
            semantic_ranker: useSemanticRanker,
            semantic_captions: useSemanticCaptions,
            suggest_followup_questions: useSuggestFollowupQuestions,
          },
        },
        // ChatAppProtocol: Client must pass on any session state received from the server
        session_state: answers.length
          ? answers[answers.length - 1][1].choices[0].session_state
          : null,
      };
      const response = await ragChatApi(request);
      if (!response.body) {
        throw Error('No response body');
      }
      if (shouldStream) {
        const parsedResponse: ChatAppResponse = await handleAsyncRequest(
          question,
          answers,
          setAnswers,
          response.body
        );
        setAnswers([...answers, [question, parsedResponse]]);
      } else {
        const parsedResponse: ChatAppResponseOrError = await response.json();
        if (response.status > 299 || !response.ok) {
          throw Error(parsedResponse.error || 'Unknown error');
        }
        setAnswers([...answers, [question, parsedResponse as ChatAppResponse]]);
      }
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const clearChat = () => {
    lastQuestionRef.current = '';
    error && setError(undefined);
    setActiveCitation(undefined);
    setActiveAnalysisPanelTab(undefined);
    setAnswers([]);
    setStreamedAnswers([]);
    setIsLoading(false);
    setIsStreaming(false);
  };

  useEffect(() => {
    if (isStreaming) {
      chatMessageStreamEnd.current?.scrollIntoView({ behavior: 'auto', block: 'end' });
    } else {
      chatMessageStreamEnd.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [isStreaming, streamedAnswers]);
  const onPromptTemplateChange = (
    _ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setPromptTemplate(newValue || '');
  };

  const onRetrieveCountChange = (
    _ev?: React.SyntheticEvent<HTMLElement, Event>,
    newValue?: string
  ) => {
    setRetrieveCount(parseInt(newValue || '3'));
  };

  const onRetrievalModeChange = (
    _ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<RetrievalMode> | undefined,
    // @ts-ignore
    index?: number | undefined
  ) => {
    setRetrievalMode(option?.data || RetrievalMode.Hybrid);
  };

  const onUseSemanticRankerChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticRanker(!!checked);
  };

  const onUseSemanticCaptionsChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSemanticCaptions(!!checked);
  };

  const onShouldStreamChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setShouldStream(!!checked);
  };

  const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
    setExcludeCategory(newValue || '');
  };

  const onUseSuggestFollowupQuestionsChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean
  ) => {
    setUseSuggestFollowupQuestions(!!checked);
  };

  const onExampleClicked = (example: string) => {
    makeApiRequest(example);
  };

  const onShowCitation = (citation: string, index: number) => {
    if (
      activeCitation === citation &&
      activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab &&
      selectedAnswer === index
    ) {
      setActiveAnalysisPanelTab(undefined);
    } else {
      setActiveCitation(citation);
      setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
    }

    setSelectedAnswer(index);
  };

  const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
    if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
      setActiveAnalysisPanelTab(undefined);
    } else {
      setActiveAnalysisPanelTab(tab);
    }

    setSelectedAnswer(index);
  };
  const { featureIsActive: intusCareOrgChanger } = getFeatureFlag('intus-care-org-changer');

  return (
    <div className={styles.container}>
      <div className={styles.commandsContainer}>
        <ClearChatButton
          className={styles.commandButton}
          onClick={clearChat}
          disabled={!lastQuestionRef.current || isLoading}
        />
        <SettingsButton
          className={styles.commandButton}
          onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)}
        />
      </div>
      <div className={styles.chatRoot}>
        <div className={styles.chatContainer}>
          <div className={styles.messagesGoHere}>
            {!lastQuestionRef.current ? (
              <div className={styles.chatEmptyState}>
                <h1 className={styles.chatEmptyStateTitle}>Talk to PACE Genie</h1>
                <h2 className={styles.chatEmptyStateSubtitle}>
                  Try a sample, or ask your own question of our 100+ PACE specific docs
                </h2>
                <ExampleList onExampleClicked={onExampleClicked} />
              </div>
            ) : (
              <div className={`${styles.chatMessageStream}`}>
                {isStreaming &&
                  streamedAnswers.map((streamedAnswer, index) => (
                    <div key={index}>
                      <UserChatMessage message={streamedAnswer[0]} />
                      <div className={styles.chatMessageGpt}>
                        <Answer
                          isStreaming
                          key={index}
                          answer={streamedAnswer[1]}
                          isSelected={false}
                          onCitationClicked={(c) => onShowCitation(c, index)}
                          onThoughtProcessClicked={() =>
                            onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)
                          }
                          onSupportingContentClicked={() =>
                            onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)
                          }
                          onFollowupQuestionClicked={(q) => makeApiRequest(q)}
                          showFollowupQuestions={
                            useSuggestFollowupQuestions && answers.length - 1 === index
                          }
                        />
                      </div>
                    </div>
                  ))}
                {!isStreaming &&
                  answers.map((answer, index) => (
                    <div key={index}>
                      <UserChatMessage message={answer[0]} />
                      <div className={styles.chatMessageGpt}>
                        <Answer
                          isStreaming={false}
                          key={index}
                          answer={answer[1]}
                          isSelected={
                            selectedAnswer === index && activeAnalysisPanelTab !== undefined
                          }
                          onCitationClicked={(c) => onShowCitation(c, index)}
                          onThoughtProcessClicked={() =>
                            onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)
                          }
                          onSupportingContentClicked={() =>
                            onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)
                          }
                          onFollowupQuestionClicked={(q) => makeApiRequest(q)}
                          showFollowupQuestions={
                            useSuggestFollowupQuestions && answers.length - 1 === index
                          }
                        />
                      </div>
                    </div>
                  ))}
                {isLoading && (
                  <>
                    <UserChatMessage message={lastQuestionRef.current} />
                    <div className={styles.chatMessageGptMinWidth}>
                      <AnswerLoading />
                    </div>
                  </>
                )}
                {error ? (
                  <>
                    <UserChatMessage message={lastQuestionRef.current} />
                    <div className={styles.chatMessageGptMinWidth}>
                      <AnswerError
                        error={error.toString()}
                        onRetry={() => makeApiRequest(lastQuestionRef.current)}
                      />
                    </div>
                  </>
                ) : null}
                <div ref={chatMessageStreamEnd} />
              </div>
            )}
          </div>

          <div className={styles.chatInput}>
            <QuestionInput
              clearOnSend
              placeholder="Type a new question (e.g. What are the requirements for a Quality Plan in PACE?)"
              disabled={isLoading}
              onSend={(question) => makeApiRequest(question)}
            />
          </div>
        </div>

        {answers.length > 0 && activeAnalysisPanelTab && (
          <AnalysisPanel
            className={styles.chatAnalysisPanel}
            activeCitation={activeCitation}
            onActiveTabChanged={(x) => onToggleTab(x, selectedAnswer)}
            citationHeight="810px"
            answer={answers[selectedAnswer][1]}
            activeTab={activeAnalysisPanelTab}
          />
        )}

        <Panel
          headerText="Configure answer generation"
          isOpen={isConfigPanelOpen}
          isBlocking={false}
          onDismiss={() => setIsConfigPanelOpen(false)}
          closeButtonAriaLabel="Close"
          onRenderFooterContent={() => (
            <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>
          )}
          isFooterAtBottom
        >
          {intusCareOrgChanger && (
            <TextField
              className={styles.chatSettingsSeparator}
              defaultValue={promptTemplate}
              label="Override prompt template"
              multiline
              autoAdjustHeight
              onChange={onPromptTemplateChange}
            />
          )}
          <SpinButton
            className={styles.chatSettingsSeparator}
            label="Retrieve this many search results:"
            min={1}
            max={50}
            defaultValue={retrieveCount.toString()}
            onChange={onRetrieveCountChange}
          />
          {intusCareOrgChanger && (
            <TextField
              className={styles.chatSettingsSeparator}
              label="Exclude category"
              onChange={onExcludeCategoryChanged}
            />
          )}

          {intusCareOrgChanger && (
            <Checkbox
              className={styles.chatSettingsSeparator}
              checked={useSemanticRanker}
              label="Use semantic ranker for retrieval"
              onChange={onUseSemanticRankerChange}
            />
          )}

          {intusCareOrgChanger && (
            <Checkbox
              className={styles.chatSettingsSeparator}
              checked={useSemanticCaptions}
              label="Use query-contextual summaries instead of whole documents"
              onChange={onUseSemanticCaptionsChange}
              disabled={!useSemanticRanker}
            />
          )}

          {intusCareOrgChanger && (
            <Checkbox
              className={styles.chatSettingsSeparator}
              checked={useSuggestFollowupQuestions}
              label="Suggest follow-up questions"
              onChange={onUseSuggestFollowupQuestionsChange}
            />
          )}

          {intusCareOrgChanger && (
            <Dropdown
              className={styles.chatSettingsSeparator}
              label="Retrieval mode"
              options={[
                {
                  key: 'hybrid',
                  text: 'Vectors + Text (Hybrid)',
                  selected: retrievalMode == RetrievalMode.Hybrid,
                  data: RetrievalMode.Hybrid,
                },
                {
                  key: 'vectors',
                  text: 'Vectors',
                  selected: retrievalMode == RetrievalMode.Vectors,
                  data: RetrievalMode.Vectors,
                },
                {
                  key: 'text',
                  text: 'Text',
                  selected: retrievalMode == RetrievalMode.Text,
                  data: RetrievalMode.Text,
                },
              ]}
              required
              onChange={onRetrievalModeChange}
            />
          )}
          {intusCareOrgChanger && (
            <Checkbox
              className={styles.chatSettingsSeparator}
              checked={shouldStream}
              label="Stream chat completion responses"
              onChange={onShouldStreamChange}
            />
          )}
        </Panel>
      </div>
    </div>
  );
};

export default Chat;

import { Button, Modal, Text } from '@intus-ui';

const DeleteConfirmationModal = ({
  existingDashboard,
  openDeleteModal,
  setOpenDeleteModal,
  runDeleteDashboardQuery,
}) => {
  return (
    <Modal
      header={{ title: 'Share Dashboard', centered: true }}
      type="small"
      open={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '15px',
        }}
      >
        <Text>Are you sure you want to delete {existingDashboard?.name}?</Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Button textOnly onClick={() => setOpenDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={() => {
              runDeleteDashboardQuery();
              setOpenDeleteModal(false);
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;

import React, { useState } from 'react';
import '@intus-ui/styles/Utilities.scss';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangeDropdown, Icon, TeamFacilityFilter, Text, TooltipComponent } from '@intus-ui';
import { PerformanceBenchmarkDashboard } from 'Dashboard/DashboardTypes/PerformanceBenchmark/PerformanceBenchmarkDashboard';
import { formatDate, newDate, startOfDate } from '@util/dateFunctions';
import { setTimeFilter } from '@global-state/redux/filtersSlice';

export const PerformanceBenchmarkWrapper = () => {
  const dispatch = useDispatch();
  const timeFilter = useSelector((state) => state.filters.timeFilter);
  const startDate = timeFilter.startDate || startOfDate(newDate(), 'month');
  const endDate = timeFilter.endDate || newDate();

  const [timeFilterVal, setTimeFilterVal] = useState(
    `${formatDate(startDate, 'MM/DD/YY')} - ${formatDate(endDate, 'MM/DD/YY')}`
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        backgroundColor: '#f6f7fa',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap: 15,
          zIndex: 10,
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 30px 0px 30px',
        }}
      >
        <div
          id="header"
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}
        >
          <Text type="title" color="navy">
            Performance Benchmark
          </Text>
          <Icon name="chart" size="medium" className="ml-2 text-muted" />
          <TooltipComponent
            anchorId="performance-benchmark-disclaimer-tooltip"
            style={{
              maxWidth: 400,
            }}
            content={
              <Text type="body">
                Due to potential delays in data entry, benchmarks from the current quarter are
                subject to change and will be updated with newest data as it becomes available.
              </Text>
            }
          >
            <Icon name="info" color="link" />
          </TooltipComponent>
        </div>

        <div id="dropdowns" style={{ display: 'flex', flexDirection: 'row', zIndex: 10 }}>
          <TeamFacilityFilter />
          <DateRangeDropdown
            range={[1, 3, 6, 12]}
            value={timeFilterVal}
            dateRangeDirection="left"
            onChange={(val) => {
              if (!val.isRange) {
                setTimeFilterVal(val.label);
                dispatch(setTimeFilter({ startDate: val.start, endDate: val.end }));
              }
              return null;
            }}
            onApplyRange={(val) => {
              setTimeFilterVal(val.label);
              dispatch(setTimeFilter({ startDate: val.start, endDate: val.end }));
            }}
          />
        </div>
      </div>
      <PerformanceBenchmarkDashboard />
    </div>
  );
};

export default PerformanceBenchmarkWrapper;

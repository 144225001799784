import { useHistory } from 'react-router-dom';

import { useDispatch } from '@global-state';
import { setCurrPatient, setPatientList, updateSearchState } from '@global-state/redux';

import { Flag, ParticipantList } from '@intus-ui';
import { getParticipantListColumns } from '@intus-ui/components/ParticipantList/list/format';
import produce from 'immer';

const ResultTable = ({
  participants = [],
  addedPatientIds = [],
  searchState,
  titleRowElement,
  loading,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Function to handle custom rendering of the participant list
   * @returns {Array} - The format for the participant list
   */
  function getCohortParticipantListFormat() {
    return produce(getParticipantListColumns(), (draft) => {
      // Change the styling of the name column to add a "NEW" flag to new patients entering a cohort
      const nameColumn = draft.find((column) => column.field === 'name');
      nameColumn.render = (participant) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
            }}
          >
            {participant.item.name}
            {addedPatientIds.includes(participant.item.id) && <Flag content="NEW" />}
          </div>
        );
      };
    });
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'auto',
        gap: 10,
        padding: '20px 30px 15px 30px',
        borderRadius: 20,
        backgroundColor: 'white',
      }}
    >
      <ParticipantList
        participantList={participants}
        showDisenrolledParticipants
        customFormat={getCohortParticipantListFormat()}
        loading={loading}
        onClickItem={(item) => {
          const PPTSelectorList = participants.map((patient) => ({
            id: patient.id,
            name: patient.name,
          }));
          dispatch(setPatientList(PPTSelectorList));
          dispatch(setCurrPatient(PPTSelectorList.findIndex((ppt) => item.id === ppt.id)));
          if (searchState) {
            dispatch(updateSearchState(searchState));
          }
          history.push(`/patient/${item.id}/current-acuity`);
        }}
        searchable={false}
        titleRowElement={titleRowElement}
        emptyListMessage="No participants for this filter."
      />
    </div>
  );
};

export default ResultTable;

import React from 'react';
import { Table } from 'react-bootstrap';
import { Button, Modal, Text, layout } from '@intus-ui';
import Select from 'react-select';
import { bulkUpsertReportUsers, bulkRemoveReportUsers, getReportUsers } from '@api';

const EditReportUsersModal = ({ show, onHide, report, users, setReportUsers }) => {
  // make a function named handleUpdateUser which takes in a user
  const handleUpdateUser = (user, newRole) => {
    // call the api function bulkUpsertReportUsers with the reportId, userIds, and the selectedRole
    bulkUpsertReportUsers(report.reportId, {
      usersToAdd: [user.userId],
      permissionType: newRole,
    }).then(() => {
      getReportUsers(report.reportId).then((response) => {
        setReportUsers(response);
      });
    });
  };
  const handleRemoveUser = (user) => {
    bulkRemoveReportUsers(report.reportId, {
      usersToRemove: [user.userId],
    }).then(() => {
      getReportUsers(report.reportId).then((response) => {
        setReportUsers(response);
      });
    });
  };
  const items = [
    { value: 'VIEWER', label: 'VIEWER' },
    { value: 'ADMIN', label: 'ADMIN' },
  ];
  // return a modal component
  return (
    <Modal
      open={show}
      onClose={onHide}
      header={{
        title: `Edit ${report && report.reportName} users`,
      }}
      type="large"
      style={{ padding: 20, height: '80vh' }}
    >
      {users.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Email</th> */}
              <th>Permission Type</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {users
              .sort((a, b) => a.firstName.localeCompare(b.firstName))
              .map((user) => (
                <tr key={user.email}>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  {/* <td>{user.email}</td> */}
                  <td>
                    <Select
                      options={items}
                      defaultValue={items.find((item) => item.value === user.permissionType)}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={(selected) => handleUpdateUser(user, selected.value)}
                    />
                  </td>
                  <td>
                    <Button onClick={() => handleRemoveUser(user)}>Remove</Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <div style={{ ...layout.centeredContentContainer, gap: 5 }}>
          <Text>No existing</Text>
          <Text type="subtitle">{`${report && report.reportName}`}</Text>
          <Text>users</Text>
        </div>
      )}
    </Modal>
  );
};

export default EditReportUsersModal;

import { quadColors } from '@intus-ui/styles/SecondaryColors';

const styles = {
  wrapper: {
    //  position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: 334,
    minHeight: 450,
    paddingBottom: 10,
  },
  tabs: {
    display: 'flex',
    borderBottom: `solid 1px ${quadColors.disabled}`,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: 10,
  },
  actionButtons: {
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    justifyContent: 'center',
    justifySelf: 'end',
  },
  calendarView: { flex: 1 },
  byQuarterView: {
    display: 'flex',
    flex: 1,
    // border: 'solid 1px red',
    flexDirection: 'column',
  },
  byQuarterViewHeader: {
    // these fixed heights smh
    display: 'flex',

    height: 51.9,
    backgroundColor: '#F0F2F8',
    width: '100%',
    padding: 10,
    gap: 10,
  },
  yearPickerView: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: 20,
    alignItems: 'center',
  },
  quartersView: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: 20,
    alignItems: 'center',
  },
  tab: {
    padding: 6,
    borderRadius: 5,

    cursor: 'pointer',
  },
};

export default styles;

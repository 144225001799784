import { useSelector } from 'react-redux';
import { Icon, Modal, SpinnerOrError, Text } from '@intus-ui';
import { useQuery } from '@api/useQuery';
import { getMetricModalById } from '@api/dashboard';
import { Tooltip } from '@mui/material';
import { widgetComponents } from './components';

const WidgetModal = ({ metric, isOpen, setIsOpen, manualTimeFilter, selectedFilters }) => {
  const { metricId, metricConfig, modal = {} } = metric;
  const { component } = modal;
  const WrapperComponent = widgetComponents[component] || null;

  let timeFilter = useSelector((state) => state.filters.customDashTimeFilter) || {
    startDate: new Date(),
    endDate: new Date(),
  };
  if (manualTimeFilter) timeFilter = manualTimeFilter;

  const {
    data: metricData,
    loading: metricDataLoading,
    error: metricDataError,
  } = useQuery(() =>
    getMetricModalById(
      metricId,
      timeFilter.startDate,
      timeFilter.endDate,
      metricConfig,
      selectedFilters
    )
  );

  let title = metricData?.data?.title || metric?.name;

  if (metricData?.data?.tooltip) {
    title = (
      <Text type="title" color="navy">
        {title}
        <Tooltip
          arrow
          style={{ display: 'inline-block' }}
          placement="right"
          title={<Text type="caption">{metricData?.data?.tooltip}</Text>}
        >
          <div
            style={{
              paddingLeft: '5px',
              display: 'inline-block',
              height: '20px',
              lineHeight: '20px',
              marginBottom: '5px',
            }}
          >
            <Icon name="info" color="blue" size="medium" />
          </div>
        </Tooltip>
      </Text>
    );
  }

  let modalHeight = 'large';

  if (metric?.modal?.component === 'ParticipantList') {
    modalHeight = 'regular';
    if (metricData?.data?.columns?.length > 1) {
      modalHeight = 'medium';
    }
  }

  if (metricDataLoading || metricDataError) {
    return (
      <Modal
        header={{ title, centered: false }}
        open={isOpen}
        type={modalHeight}
        onClose={() => setIsOpen(false)}
        style={{ height: '100%', maxHeight: '100%', overflow: 'hidden' }}
      >
        {metricDataLoading && <SpinnerOrError />}
        {metricDataError && <SpinnerOrError error="An error occurred while loading modal" />}
      </Modal>
    );
  }

  return (
    <Modal
      key={isOpen.toString()}
      header={{ title, centered: false }}
      open={isOpen}
      type={modalHeight}
      onClose={() => setIsOpen(false)}
      style={{ height: '100%', maxHeight: '100%', overflow: 'hidden' }}
      modalStyles={{ height: '90%' }}
    >
      {!WrapperComponent && <div>Select a metric</div>}

      {WrapperComponent && <WrapperComponent metric={metric} metricData={metricData} />}
    </Modal>
  );
};
export default WidgetModal;

import { useEffect, useState } from 'react';
import { layout } from '@intus-ui';
import ChangeEventCard from 'Digest/ChangeEventCard';
import payload from './timelinePayload';
import TimeSeries from './TimeSeries';

const Timeline = () => {
  const [events, setEvents] = useState([]);
  const [timeSeries, setTimeSeries] = useState({});

  useEffect(() => {
    setEvents(payload.events);
    setTimeSeries(payload.timeSeries);
  }, []);

  return (
    <div style={styles.container}>
      <TimeSeries timeSeries={timeSeries} />
      <div style={styles.timeline}>
        {events.map((event, i) => {
          const changesKey = i;
          return <ChangeEventCard key={changesKey} event={event} />;
        })}
      </div>
    </div>
  );
};

const styles = {
  container: { ...layout.rowContainer, gap: 10 },
  timeline: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    gap: 10,
  },
};
export default Timeline;

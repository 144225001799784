// in the following file, I will make a functional react component that will be used to render the multiselect component.

import React from 'react';
import { Multiselect } from '@intus-ui/components/Multiselect/Multiselect';
import { getSessionUser, setSessionUser } from '@util/session';
import { changeMyFlags, getAllFeatureFlags } from '@api';
import { useLazyQuery, useQuery } from '@api/useQuery';
import { getLogger } from '@util/logger';

const log = getLogger('changeMyFlags');

const ChangeMyFlags = () => {
  const { flags } = getSessionUser() || {};
  const [selectedFlags, setSelectedFlags] = React.useState(flags);

  const handleChange = (selectedFlags) => {
    setSelectedFlags(selectedFlags);
  };

  const { data: featureItems } = useQuery(() => getAllFeatureFlags());
  const { runQuery: runChangeMyFlags } = useLazyQuery(
    (updatedFlags) => changeMyFlags(updatedFlags),
    {
      onSuccess: (data) => {
        setSessionUser(data);
        window.location.reload();
      },
      onError: (error) => {
        log.error('Error fetching teams');
        log.error(error);
      },
    }
  );

  const handleUpdateUserFlags = () => {
    runChangeMyFlags({ flags: selectedFlags });
  };

  const containerStyle = {
    display: 'flex',
  };

  const columnStyle = {
    flex: 1,
    padding: '16px', // Adjust as needed
    border: '1px solid #ddd', // Optional: Add borders for separation
  };
  return (
    <div>
      <div className="mx-4" style={containerStyle}>
        <div className="col s12 m6" style={columnStyle}>
          <Multiselect
            label="Select your feature flags"
            items={featureItems ? featureItems.map((f) => f.id) : []}
            selectedItems={selectedFlags}
            allowSelectAll
            onChange={(selectedItems) => handleChange(selectedItems)}
          />
        </div>
        <div className="col s12 m6" style={columnStyle}>
          Your selected flags are:
          {selectedFlags.map((flag) => {
            return <div>{flag}</div>;
          })}
        </div>
      </div>
      <div className="mx-4" style={containerStyle}>
        <div className="col s12 m6" style={columnStyle}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setSelectedFlags(getSessionUser().flags);
            }}
          >
            Reset Selection
          </button>
        </div>
        <div className="col s12 m6" style={columnStyle}>
          <button type="button" className="btn btn-primary" onClick={handleUpdateUserFlags}>
            update flags
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeMyFlags;

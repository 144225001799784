import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import PropTypes from 'prop-types';
import { Chip } from '@nivo/tooltip';
import '@intus-ui/styles/Utilities.scss';
import { formatDate } from '@util/dateFunctions';

class IntusLineGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      xTitle,
      yTitle,
      data,
      maxScore,
      minScore,
      margin,
      legends,
      axisCustomization,
      onClick,
      sliceTooltip,
      yFormat,
      enableArea,
    } = this.props;

    if (!data) return null;

    const toolTipElement = (props) => {
      const { point } = props;
      return (
        <div className="px-3 py-2 rounded-lg box-shadow-on-gray to-front bg-transparent-90">
          <strong>{formatDate(point.data.x, 'MMMM YYYY')}</strong>
          <hr className="m-0" />
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center pr-2">
              <Chip size={12} color={point.serieColor} />
              <span className="pl-2">{point.serieId}</span>
            </div>
            <strong>{point.data.yFormatted}</strong>
          </div>
        </div>
      );
    };

    return (
      <div className="w-100 h-100">
        <ResponsiveLine
          theme={{
            fontFamily: 'Muli',
            axis: {
              legend: {
                text: {
                  fontWeight: 'bold',
                  fontSize: 14,
                },
              },
              ticks: {
                text: {
                  fontSize: 14,
                },
              },
            },
            labels: {
              text: {
                fontSize: 14,
              },
            },
            legends: {
              text: {
                fontSize: 14,
              },
            },
          }}
          className="linechart"
          data={data}
          margin={margin}
          xScale={{
            type: 'time',
            useUTC: false,
            format: '%Y-%m-%d',
            precision: 'day',
          }}
          xFormat="time:%Y-%m-%d"
          yFormat={yFormat}
          yScale={{
            type: 'linear',
            min: minScore,
            max: maxScore,
            stacked: false,
            reverse: false,
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: axisCustomization.axisBottomFormat || '%b %y',
            tickValues: axisCustomization.xTickValues || 'every 1 month',
            legend: xTitle,
            legendOffset: axisCustomization.xLegendOffset || 40,
            legendPosition: 'middle',
          }}
          axisLeft={{
            legend: yTitle,
            legendPosition: 'middle',
            legendOffset: axisCustomization.yLegendOffset || -60,
            tickValues: axisCustomization.yTickValues || 5,
            format: axisCustomization.axisLeftFormat,
          }}
          gridYValues={5}
          tooltip={sliceTooltip ? undefined : toolTipElement}
          colors={{ scheme: 'nivo' }}
          pointSize={9}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabel="y"
          pointLabelYOffset={-8}
          useMesh
          enableArea={enableArea}
          areaOpacity={0.1}
          areaBaselineValue={minScore}
          lineWidth={3}
          crosshairType="cross"
          enableGridX={false}
          legends={legends}
          onClick={onClick}
          enableSlices={sliceTooltip ? 'x' : undefined}
          sliceTooltip={sliceTooltip || undefined}
        />
      </div>
    );
  }
}

IntusLineGraph.propTypes = {
  xTitle: PropTypes.string,
  yTitle: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  // this can be a number or 'auto'
  maxScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  legends: PropTypes.arrayOf(PropTypes.object),
  sliceTooltip: PropTypes.func,
  axisCustomization: PropTypes.shape({
    xTickValues: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xLegendOffset: PropTypes.number,
    yTickValues: PropTypes.number,
    yLegendOffset: PropTypes.number,
    axisLeftFormat: PropTypes.func,
    axisBottomFormat: PropTypes.string,
  }),
  onClick: PropTypes.func,
  yFormat: PropTypes.func,
  enableArea: PropTypes.bool,
};

IntusLineGraph.defaultProps = {
  xTitle: '',
  yTitle: '',
  maxScore: 'auto',
  minScore: 0,
  margin: {
    top: 0,
    right: 30,
    bottom: 60,
    left: 70,
  },
  legends: [],
  sliceTooltip: undefined,
  axisCustomization: undefined,
  onClick: undefined,
  yFormat: undefined,
  enableArea: true,
};

export default IntusLineGraph;

import { useHistory, useRouteMatch } from 'react-router-dom';

import { layout } from '@intus-ui';

import SideMenu from 'AdvancedCohort/SideMenu';
import { textColors } from '@intus-ui/styles/SecondaryColors';

export const InitiativesSideBar = () => {
  const { url } = useRouteMatch();

  const history = useHistory();

  const menuItems = [
    {
      name: 'Initiatives',
      active: url.includes('/initiatives'),
      onClick: () => {
        history.push('/initiatives');
      },
    },
  ];

  return (
    <div
      style={{
        ...layout.container,
        flex: '1 1 auto',
        flexDirection: 'row',
        height: '100%',
      }}
    >
      <SideMenu items={menuItems} style={styles.sideBar}>
        <p style={styles.initiativeSidebarText}>
          Track the impact of the changes you make at the group-level. Once you create an
          initiative, you can track success metrics for cohorts over specific time ranges.
        </p>
      </SideMenu>
    </div>
  );
};

const styles = {
  initiativeSidebarText: {
    padding: '5.5px 15px',
    color: textColors.caption,
    height: 'calc(100% - 20px)',
  },
  sideBar: {
    marginRight: 5,
    marginTop: 5,
  },
};

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Col } from 'react-bootstrap';
import FormikFormControl from '@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';
import SettingsBox from './SettingsBox';
import { getUserInfo, updateUserSettings } from '../@global-state/redux/userSettingsSlice';
import userSettingsPropType from './userSettingsPropType';

const schema = Yup.object().shape({
  newpass1: Yup.string()
    .required('New password must be at least 9 characters')
    .min(9, 'New password must be at least 9 characters'),
  newpass2: Yup.string()
    .oneOf([Yup.ref('newpass1'), null], "Passwords don't match")
    .required('Confirm Password is required'),
});

const PasswordSettingsBox = ({ doUpdateUserSettings, userSettings }) => {
  const resetFormRef = useRef();
  const { profile } = userSettings;
  const safeResetForm = () => {
    if (resetFormRef.current) {
      resetFormRef.current();
    }
  };
  return (
    <SettingsBox title="Reset Password" cancel={safeResetForm}>
      {({ editable, setEditable }) => (
        <Formik
          initialValues={{ ...profile }}
          validateOnChange
          onSubmit={(values, { setErrors, setTouched, setStatus }) => {
            const includedFields = {
              password: values.newpass1,
            };
            setEditable(false);
            // This gets rid of the validation without getting rid of the new values.
            setErrors({});
            setTouched({});
            doUpdateUserSettings({ ...profile, ...includedFields });
            safeResetForm(); // replaces filled-in password with placeholders
            setStatus({ success: 'Successfully Updated Password' });
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, submitForm, resetForm, errors, status }) => {
            resetFormRef.current = resetForm;
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <FormikFormControl
                    as={Col}
                    md="12"
                    label="New Password"
                    type="password"
                    name="newpass1"
                    placeholder="********"
                    disabled={!editable}
                  />
                </Form.Row>
                {editable ? (
                  <Form.Row>
                    <FormikFormControl
                      as={Col}
                      md="12"
                      label="Confirm New Password"
                      type="password"
                      name="newpass2"
                      placeholder="********"
                      disabled={!editable}
                    />
                  </Form.Row>
                ) : null}
                {status ? <p className="text-success font-weight-bold">{status.success}</p> : null}
                {editable ? (
                  <SettingsBox.Buttons
                    cancel={() => {
                      resetForm();
                      setEditable(false);
                    }}
                    submit={submitForm}
                    disabled={Object.values(errors).length > 0}
                  />
                ) : null}
              </Form>
            );
          }}
        </Formik>
      )}
    </SettingsBox>
  );
};

PasswordSettingsBox.propTypes = {
  userSettings: userSettingsPropType,
  doUpdateUserSettings: PropTypes.func.isRequired,
};

PasswordSettingsBox.defaultProps = {
  userSettings: undefined,
};

const mapState = (state) => ({
  userSettings: state.userSettings,
});

const mapDispatch = (dispatch) => ({
  doGetUserLocalData: () => dispatch(getUserInfo()),
  doUpdateUserSettings: (body) => dispatch(updateUserSettings(body)),
});

export default connect(mapState, mapDispatch)(PasswordSettingsBox);

/**
 * CORE MODULE: https://github.com/pimterry/loglevel
 * PREFIX PLUGIN: https://github.com/kutuluk/loglevel-plugin-prefix
 *
 * NOTE: THE PREFIX MODULE IS USED TO EASILY APPLY A NAMESPACE TO THE LOGGER INSTANCE
 * THIS NAMESPACE IS PRIMARILY USED TO SET A STRING PREFIXED TO EACH LOG MESSAGE
 *
 * LOGGER INSTANCES SHOULD BE NAMED WITH SOMETHING DESCRIPTIVE FOR DEBUGGING
 * STANDARD PRACTICE:THE FILENAME SHOULD BE USED
 * (WITH THE EXCEPTION OF index FILES, WHICH SHOULD USE THE DIRECTORY NAME)
 *
 * AMBIGUOUS FILENAMES SHOULD USE A <PARENT DIRECTORY>/<FILENAME> PATTERN
 *
 * TO SET THE NAME IN THE LOG, YOU CAN CREATE A NEW LOGGER INSTANCE WITH THE ASSIGNED NAME
 * ```
 * import { getLogger } from '@util/logger';
 * const log = getLogger('Login');
 * log.info('this is a test message');
 * > [23:52:47] (Login) DEBUG: this is a test message
 * ```
 *
 * OR SIMPLY CHAIN THE CALL IF YOU ONLY NEED TO MAKE A SINGLE LOG
 *
 * ```
 * import { getLogger } from '@util/logger';
 * getLogger('Login').info('this is a test message');
 * ```
 *
 * IF YOU DO NOT NEED TO SET THE NAME PREFIX, YOU CAN USE THE LOG DIRECTLY
 *
 * ```
 * import { log } from '@util/logger';
 * log.info('this is a test message');
 * ```
 *
 * A CUSTOM METHOD .http() IS INTENDED TO ACCEPT ARGUMENTS, A MESSAGE<STRING> AND HTTPRESPONSE<OBJECT>
 * THIS METHOD WILL CHECK THE STATUS AND SET LOG LEVEL ACCORDINGLY (200 VS )
 *
 * NOTE: CURRENT VERSION OF THE PREFIX PLUGIN OVERRIDES THE LINE NUMBER DISPLAYED IN CONSOLE
 * TO DISABLE THE PREFIX MODULE FOR CLASSIC LOGGING, SET BASIC_LOGGING=true IN YOUR LOCAL ENV
 *
 * FULLSTORY DOES NOT DISPLAY LINE NUMBERS, SO USING NAMED LOGGERS WILL ALLOW US TO
 * MORE EASILY TRACE ERRORS BACK TO THE SOURCE
 */

import log from 'loglevel';
import prefix from 'loglevel-plugin-prefix';

import { formatDate } from '@util/dateFunctions';
import { pick } from 'lodash';

if (!process.env.BASIC_LOGGING) {
  prefix.reg(log);
  log.enableAll();

  prefix.apply(log, {
    template: '[%t] (%n) %l:',
    levelFormatter(level) {
      return level.toUpperCase();
    },
    nameFormatter(name) {
      return name || 'global';
    },
    timestampFormatter(date) {
      return formatDate(date, 'HH:mm:ss');
    },
  });
}

// NOTE: WILL ONLY LOG ERRORS IN PRODUCTION
if (process.env.NODE_ENV !== 'production') log.setLevel('trace');

// CONVENIENCE FUNCTION FOR PULLING SPECIFIC VALUES FROM AN HTTP RESPONSE OBJECT
const logHttp = (httpResponse, msg = '') => {
  let logLevel;

  if (httpResponse.status >= 200 && httpResponse.status < 300) {
    logLevel = 'info';
  } else if (httpResponse.status < 400) {
    logLevel = 'warn';
  } else {
    logLevel = 'error';
  }

  log.getLogger('HTTP')[logLevel](msg, pick(httpResponse, ['url', 'status']));
};

log.http = logHttp;

// QUICK AND DIRTY WAY TO ALWAYS HAVE .http() METHOD AVAILABLE
const getLogger = (name = '') => {
  const logger = log.getLogger(name);
  logger.http = logHttp;
  return logger;
};

export { log, getLogger };

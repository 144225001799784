import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import FilteredList from '@intus-ui/components/FilteredList';
import AdminModal from './AdminModal';
import AdminListPropType from './AdminListPropType';
import AddAdminModal from './AddAdminModal';

const AdminList = ({ history, admins }) => {
  const testAdmin = {
    id: -1,
    name: 'You should never see this',
    email: 'test@email.com',
    phoneNumber: '9999999999',
    orgId: -2,
  };
  const [editMode, setEditMode] = useState(false);
  const [addAdmin, setAddAdmin] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(testAdmin);
  const addAdminFunc = () => setAddAdmin(true);
  const hideModal = () => {
    setEditMode(false);
    setAddAdmin(false);
  };
  const selectAdmin = (val) => {
    setSelectedAdmin(val);
    setEditMode(true);
  };

  const format = [
    {
      valueField: 'name',
      nodeField: 'name',
      md: 6,
      className: 'text-dark',
      sorting: 'lexographical',
      heading: 'Name',
    },
    {
      valueField: 'email',
      nodeField: 'email',
      md: 6,
      className: 'text-dark',
      sorting: 'lexographical',
      heading: 'Email',
    },
  ];

  const items = admins.map((admin) => ({
    id: admin.id,
    name: `${admin.firstName} ${admin.lastName}`,
    email: admin.email,
    phoneNumber: admin.phoneNumber,
    orgId: admin.organizationId,
  }));

  const toolbar = (
    <Button className="ml-4 mr-2 rounded-lg" onClick={addAdminFunc}>
      Add an Admin
    </Button>
  );

  return (
    <div className="w-100 h-100 overflow-hidden d-flex justify-content-start align-items-center flex-column">
      <AddAdminModal show={addAdmin} hide={hideModal} />
      <AdminModal show={editMode} hide={hideModal} info={selectedAdmin} />
      <div className="d-flex flex-shrink-1 justify-content-start align-items-start w-100 pl-3 pt-3">
        <Button
          variant="outline-primary"
          className="rounded-lg"
          onClick={() => history.push('/admin')}
        >
          Back to Organizations
        </Button>
      </div>
      <div className="d-flex flex-grow-1 w-100 overflow-hidden p-3">
        <FilteredList
          title="Admins"
          format={format}
          items={items}
          search={{
            valueField: 'name',
            name: 'Admin Name',
          }}
          toolbar={toolbar}
          onClick={(val) => selectAdmin(val)}
        />
      </div>
    </div>
  );
};

AdminList.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  admins: PropTypes.arrayOf(AdminListPropType.isRequired),
};

AdminList.defaultProps = {
  admins: [],
};

const mapState = (state) => ({
  admins: state.admin.admins,
});

export default withRouter(connect(mapState)(AdminList));

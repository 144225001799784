import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { Icon, Text } from '@intus-ui';
import CenteredSpinner from './CenteredSpinner';

type SpinnerOrErrorProps = {
  error?: string;
  onClick?: () => void;
  buttonText?: string;
  icon?: boolean;
  style?: React.CSSProperties;
  spinnerStyle?: React.CSSProperties;
};

const SpinnerOrError: FC<SpinnerOrErrorProps> = (props) => {
  const { error, onClick, buttonText, icon, style, spinnerStyle } = props;

  const BootstrapButton = Button as any;

  if (error) {
    return (
      <div
        className="text-dark text-center d-flex flex-column h-100 w-100 align-items-center justify-content-center"
        style={style}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {icon && (
            <Icon
              name="SorryFace"
              color="#404040"
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            />
          )}
          <Text type="caption" color="error">
            {error}
          </Text>
        </div>
        {onClick && (
          <BootstrapButton onClick={onClick} className="px-4 py-3">
            <h3 className="font-weight-bold">{buttonText}</h3>
          </BootstrapButton>
        )}
      </div>
    );
  }

  return <CenteredSpinner className="w-100 h-100" style={spinnerStyle} />;
};

export default SpinnerOrError;

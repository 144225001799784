import React, { useState, useEffect } from 'react';

import SingleSelect from '@intus-ui/components/SingleSelect/index';

import { useListContext } from '../../List/Context';
import { useParticipantListContext } from '../Context';

import '@intus-ui/styles/ListHeader.scss';

// TODO: replace with a component that is currently being made without bootstrap
const LivingSituationDropdown = () => {
  const { addFilter, filters } = useListContext();
  const { filterOptions } = useParticipantListContext();
  const [selected, setSelected] = useState('All');

  useEffect(() => {
    const selectedOption = filters.find((filter) => filter.type === 'livingSituation');
    if (selectedOption) setSelected(selectedOption?.value);
    else setSelected('All');
  }, [filters]);

  useEffect(() => {
    addFilter({ type: 'livingSituation', value: selected });
  }, [selected]);

  return (
    <SingleSelect
      onClickItem={(event) => {
        setSelected(event);
      }}
      options={filterOptions.livingSituation}
      rightAligned={false}
      rightIcon
      defaultValue="All"
    />
  );
};

export default LivingSituationDropdown;

export const oldNamesToNewNames = {
  neurologicalAndBehavioralSubscore: 'Musculoskeletal and Incident-Related Conditions',
  recurrentSubscore: 'Cardiovascular & Respiratory Conditions',
  socialSubscore: 'Neurological & Social Conditions',
  coordinationComplexitySubscore: 'Renal and Hepatic Conditions',
  diseaseSubscore: 'Infection-Related Conditions',
  externalCostSubscore: 'Cancer and Immune-Related Conditions',
};

export const newCategories = {
  categories: [
    'Cardiovascular & Respiratory Conditions',
    'Neurological & Social Conditions',
    'Infection-Related Conditions',
    'Renal and Hepatic Conditions',
    'Musculoskeletal and Incident-Related Conditions',
    'Cancer and Immune-Related Conditions',
  ],
  categoryToQuestionLabels: {
    'Cardiovascular & Respiratory Conditions': [
        'Acute Myocardial Infarction',
        'Asthma',
        'Cardiac dysrhythmias',
        'Cardio-Respiratory Failure and Shock',
        'Cardiomyopathy/Myocarditis',
        'Cerebral infarction',
        'Chronic Obstructive Pulmonary Disease, Interstitial Lung Disorders, and Other Chronic Lung Disorders',
        'Deep Vein Thrombosis and Pulmonary Embolism',
        'Diabetes',
        'Disorders of lipid metabolism',
        'Heart Failure',
        'Heart Transplant Status/Complications',
        'Hypertension with complications and secondary hypertension',
        'Influenza',
        'Ischemic or Unspecified Stroke',
        'Nonrheumatic and unspecified valve disorders',
        'Nonspecific chest pain',
        'Oxygen Dependence',
        'Peripheral and visceral vascular disease',
        'Pleurisy  pleural effusion and pulmonary collapse',
        'Pneumonia (except that caused by tuberculosis)',
        'Severe Diabetic Eye Disease, Retinal Vein Occlusion, and Vitreous Hemorrhage',
        'Severe Persistent Asthma',
        'Sinusitis',
        'Specified Heart Arrhythmias',
        'Unstable Angina and Other Acute Ischemic Heart Disease',
        'Vascular Disease with Complications',
    ],
    'Neurological & Social Conditions': [
      'Alcohol-related disorders',
      'Anxiety and fear-related disorders',
      'Bipolar Disorders without Psychosis',
      'Cerebral Palsy, Except Quadriplegic',
      'Dementia, Mild or Unspecified',
      'Dementia, Moderate',
      'Dementia, Severe',
      'Depressive disorders',
      'Drug Use Disorder, Moderate/Severe, or Drug Use with Non-Psychotic Complications',
      'Drug Use with Psychotic Complications',
      'Failure to Thrive',
      'General sensation perception signs and symptoms',
      'HIV/AIDS',
      'Intracranial Hemorrhage',
      'Lacks Social Connection',
      'Lives Alone',
      'Lives at a nursing facility',
      'Lives in assisted living',
      'Malaise and fatigue',
      'Morbid Obesity',
      'Nervous system pain and pain syndromes',
      'Non-compliance',
      'Paraplegia',
      'Quadriplegia',
      'Quadriplegic Cerebral Palsy',
      'Schizophrenia',
      'Seizure Disorders and Convulsions',
      'Spinal Cord Disorders/Injuries',
      'Stimulant-related disorders',
      'Suicidal ideation attempt intentional self-harm',
      'Tobacco-related disorders',
      'Trauma- and stressor-related disorders',
    ],
    'Infection-Related Conditions': [
      'Caries  periodontitis  and other preventable dental conditions',
      'Cellulitis',
      'Diseases of inner ear and related conditions',
      'Diseases of mouth  excluding dental',
      'Diverticulosis and diverticulitis',
      'Intestinal Obstruction/Perforation',
      'Noninfectious gastroenteritis',
      'Septicemia, Sepsis, Systemic Inflammatory Response Syndrome/Shock',
      'Skin Subcutaneous signs and symptoms',
      'Systemic Lupus Erythematosus and Other Specified Systemic Connective Tissue Disorders',
      'Urinary tract infections',
    ],
    'Renal and Hepatic Conditions': [
      'Acute and unspecified renal failure',
      'Artificial Openings for Feeding or Elimination',
      'Chronic Hepatitis',
      'Chronic Kidney Disease Stage 1,2, unspecified',
      'Chronic Kidney Disease, Moderate (Stage 3, Except 3B)',
      'Chronic Kidney Disease, Moderate (Stage 3B)',
      'Chronic Kidney Disease, Severe (Stage 4)',
      'Chronic Kidney Disease, Stage 5',
      'Chronic Liver Failure/End-Stage Liver Disorders',
      'Cirrhosis of Liver',
    ],
    'Musculoskeletal and Incident-Related Conditions': [
      '3+ falls with injury past 90 days',
      '5+ falls with injury past 90 days',
      'Amputation Status, Lower Limb/Amputation Complications',
      'ED Visit in last 30 days',
      'Exudative Macular Degeneration',
      'Fracture of lower limb (except hip)  subsequent encounter',
      'Fracture of the spine and back  subsequent encounter',
      'Hospitalization/ER Event in Past 90 days',
      'Multiple Admissions in last 90 days',
      'Musculoskeletal pain  not low back pain',
      'Osteoarthritis',
      'Pressure Ulcers',
      'Readmission in last 90 days',
      'Spondylopathies spondyloarthropathy (including infective)',
      'Superficial injury  contusion  initial encounter',
    ],
    'Cancer and Immune-Related Conditions': [
      'Bladder, Colorectal, and Other Cancers',
      'Cancer Metastatic to Bone, Other and Unspecified Metastatic Cancer; Acute Leukemia Except Myeloid',
      'Cancer Metastatic to Lung, Liver, Brain, and Other Organs; Acute Myeloid Leukemia Except Promyelocytic',
      'COVID-19',
      'Lung and Other Severe Cancers',
      'Lymphoma and Other Cancers',
      'Myelodysplastic Syndromes, Multiple Myeloma, and Other Cancers',
      'Palliative Care',
      'Prostate, Breast, and Other Cancers and Tumors',
    ],
  },
};

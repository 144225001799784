/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import riskBreakup from '@util/riskGrouping';

import { textColors } from '@intus-ui/styles/SecondaryColors';
import { Chart, Plugin, Point } from 'chart.js';
import { newDate } from '@util/dateFunctions';
import { getFeatureFlag } from '@config';
import { getBadge, matchAcuityPoint } from './badges';
// import { BADGE_OFFSET } from './Context';

/**
 * Redraws yAxis and adds labels for Acuity and Events
 */

export const removeYAxisLabelsAboveLimit: Plugin<'scatter'> = {
  id: 'yAxisLimit',
  beforeDatasetsDraw(chart, _args, options) {
    options = JSON.parse(JSON.stringify(options));
    const {
      ctx,
      scales,
      chartArea: { left, bottom },
    } = chart;
    const { maxAcuity } = options;

    const yValue = scales.y;
    const yAxisLimit = yValue.getPixelForValue(maxAcuity);

    ctx.save();
    ctx.beginPath();

    // DRAW Y-AXIS
    ctx.moveTo(left, yAxisLimit);
    ctx.lineTo(left, yValue.bottom);
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'black';
    ctx.stroke();

    // DRAW Y-AXIS LABELS
    ctx.font = '15px Inter, Arial, sans-serif';
    ctx.fillStyle = textColors.body;
    ctx.textAlign = 'center';

    // MOVE CTX TO DRAW LABELS
    ctx.translate(left - 40, bottom);
    ctx.rotate(Math.PI * -0.5);

    ctx.fillText('Acuity Score', (bottom - yAxisLimit) * 0.5, 0);
    ctx.fillText('Events', bottom - yAxisLimit * 0.5, 0);

    ctx.restore();
  },
};

/**
 * Draws dotted lines for events
 */
export const drawDottedLines: Plugin<'scatter'> = {
  id: 'drawDottedLines',
  beforeDatasetsDraw(chart, _args, options) {
    // options = JSON.parse(JSON.stringify(options));
    const {
      ctx,
      scales,
      chartArea: { left, right },
      config: {
        data: { datasets },
      },
    } = chart;
    const { hoveredPoint, selectedPoint } = options;
    const xAxis = scales.x;
    const yAxis = scales.y;

    ctx.save();
    ctx.beginPath();

    const activePoints: number[] = [];
    if (hoveredPoint) activePoints.push(hoveredPoint.x);
    if (selectedPoint) activePoints.push(selectedPoint.x);

    datasets.forEach((dataset) => {
      const lineValue = (dataset as any).lineValue as number;
      if (lineValue) {
        // DRAW EVENT DATASET HORIZONTAL LINE
        const xPosition = yAxis.getPixelForValue(lineValue);
        ctx.beginPath();
        ctx.setLineDash([5]);
        ctx.moveTo(left, xPosition);
        ctx.lineTo(right, xPosition);
        ctx.lineWidth = 0.4;
        ctx.strokeStyle = '#9D9D9D';
        ctx.stroke();

        // DRAW EVENT BADGE VERTICAL LINES
        dataset.data.forEach((myEvent) => {
          const event = myEvent as Point;
          const yPosition = xAxis.getPixelForValue(newDate(event.x).toDate().getTime());

          // DRAW HOVER / SELECTED VERTICAL LINES
          ctx.beginPath();
          if (activePoints.includes(event.x)) {
            ctx.moveTo(yPosition, yAxis.bottom);
            ctx.lineTo(yPosition, yAxis.getPixelForValue(lineValue));
            ctx.lineWidth = 0.8;
            ctx.strokeStyle = 'black';
          } else {
            // STANDARD VERTICAL LINES
            ctx.moveTo(yPosition, yAxis.bottom);
            ctx.lineTo(yPosition, yAxis.getPixelForValue(lineValue));
            ctx.lineWidth = 0.4;
            ctx.strokeStyle = '#9D9D9D';
          }
          ctx.stroke();
        });
      }
    });

    ctx.stroke();

    ctx.restore();
  },
};

/**
 * Colors the background of the chart area.
 * Colors correspond to risk grouping used in the rest of the app.
 * Add in labels corresponding to acuity groups
 */
export const backgroundChartArea: Plugin<'scatter'> = {
  id: 'backgroundChartArea',
  beforeDatasetsDraw(chart, _args, options) {
    options = JSON.parse(JSON.stringify(options));
    const {
      ctx,
      chartArea,
      scales,
      config: {
        data: { datasets },
      },
    } = chart;
    const { maxAcuity } = options;
    const { bottom, left, right } = chartArea;
    const yValue = scales.y;

    const maxYPixelValue = yValue.getPixelForValue(maxAcuity);
    const { featureIsActive: AcuityV2 } = getFeatureFlag('AcuityV2');

    const acuityLimitProperty = AcuityV2 ? 'v2LowerLimit' : 'lowerLimit';
    const lowRiskPixelValue = yValue.getPixelForValue(
      riskBreakup.MODERATE[acuityLimitProperty] - 1
    );
    const moderateRiskPixelValue = yValue.getPixelForValue(
      riskBreakup.HIGH[acuityLimitProperty] - 1
    );
    const highRiskPixelValue = yValue.getPixelForValue(
      riskBreakup.HIGHEST[acuityLimitProperty] - 1
    );

    if (!chartArea) {
      return;
    }

    const canvas = ctx.canvas.getContext('2d')!;
    canvas.save();

    // ACUITY LABELS
    canvas.font = 'bold 10px Inter, Arial, sans-serif';
    canvas.textAlign = 'right';

    const textOffset = right - 5;

    // LOW ACUITY
    canvas.fillStyle = riskBreakup.LOW.mutedColor;
    canvas.fillRect(left, lowRiskPixelValue, right - left, bottom - lowRiskPixelValue);
    canvas.fillStyle = '#9D9D9D';
    canvas.fillText('Low', textOffset, bottom - 10);

    // MODERATE ACUITY
    canvas.fillStyle = riskBreakup.MODERATE.mutedColor;
    canvas.fillRect(
      left,
      moderateRiskPixelValue,
      right - left,
      lowRiskPixelValue - moderateRiskPixelValue
    );
    canvas.fillStyle = '#9D9D9D';
    canvas.fillText('Moderate', textOffset, lowRiskPixelValue - 10);

    // HIGH ACUITY
    canvas.fillStyle = riskBreakup.HIGH.mutedColor;
    canvas.fillRect(
      left,
      highRiskPixelValue,
      right - left,
      moderateRiskPixelValue - highRiskPixelValue
    );
    canvas.fillStyle = '#9D9D9D';
    canvas.fillText('High', textOffset, moderateRiskPixelValue - 10);

    // HIGHEST ACUITY
    canvas.fillStyle = riskBreakup.HIGHEST.mutedColor;
    canvas.fillRect(left, maxYPixelValue, right - left, highRiskPixelValue - maxYPixelValue);
    canvas.fillStyle = '#9D9D9D';
    canvas.fillText('Highest', textOffset, highRiskPixelValue - 10);

    // EVENT LABELS
    datasets.forEach((dataset) => {
      const lineValue = (dataset as any).lineValue as number;
      if (lineValue) {
        canvas.fillText(dataset.label!, textOffset, yValue.getPixelForValue(lineValue) - 14);
      }
    });

    canvas.restore();
  },
};

/**
 * Draws acuity dots and event badges
 */
const drawBadges = (chart: Chart<'scatter'>, x: string, isActive: boolean) => {
  const {
    config: {
      data: { datasets },
    },
  } = chart;

  const xValue = chart.scales.x.getPixelForValue(newDate(x).toDate().getTime());
  const yAxis = chart.scales.y;

  // DRAW ACUITY DOTS
  const index = datasets.findIndex((d) => (d as any).pointType === 'acuityScore');
  const acuity = datasets[index].data.find((o) => (o as any).x === x) as Point | null;
  const { featureIsActive: AcuityV2 } = getFeatureFlag('AcuityV2');

  const acuityLimitProperty = AcuityV2 ? 'v2LowerLimit' : 'lowerLimit';
  if (acuity) {
    const acuityIcon = matchAcuityPoint(
      acuity.y,
      {
        // use acuitylimitproperty to get the correct lower limit
        MODERATE: yAxis.getPixelForValue(riskBreakup.MODERATE[acuityLimitProperty]),
        HIGH: yAxis.getPixelForValue(riskBreakup.HIGH[acuityLimitProperty]),
        HIGHEST: yAxis.getPixelForValue(riskBreakup.HIGH[acuityLimitProperty]),
      },
      isActive
    );

    chart.ctx.drawImage(acuityIcon, xValue - 8, yAxis.getPixelForValue(acuity.y) - 8);
  }

  // DRAW EVENTS
  datasets
    .filter((dataset) => (dataset as any).eventType)
    .forEach((dataset) => {
      for (let i = 0; i < dataset.data.length; i++) {
        const point = dataset.data[i] as Point;
        if ((point.x as any as string) === x) {
          const label = (point as any).label as string;
          const badge = getBadge(label, isActive ? 'active' : 'hover');
          const xPosition = xValue - badge.width * 0.5;
          const yValue = yAxis.getPixelForValue(point.y);
          const yPosition = yValue - badge.height * 0.5;
          chart.ctx.drawImage(badge, xPosition, yPosition);
        }
      }
    });
};

// ADJUST Y VALUE FOR EVENTS OF THE SAME TYPE ON THE SAME DAY
export const formatBadges: Plugin<'scatter'> = {
  id: 'formatBadges',
  beforeUpdate(chart) {
    const { datasets } = chart.config.data;
    datasets
      .filter((dataset) => (dataset as any).eventType)
      .forEach((dataset) => {
        for (let i = 1; i < dataset.data.length; i++) {
          const point = dataset.data[i] as Point;
          const previousPoint = dataset.data[i - 1] as Point;
          if (point.x === previousPoint.x) {
            // CALCULATE PIXEL OFFSET FOR BADGES
            point.y = previousPoint.y - 10;
            // point.y = chart.scales.y.getValueForPixel(
            //   chart.scales.y.getPixelForValue(previousPoint.y) + BADGE_OFFSET
            // )!;
          }
        }
      });
  },
  // DRAW HOVER STATE BADGES
  afterDraw(chart, _args, options) {
    const { hoveredPoint, selectedPoint } = options;
    const hoveredDate = hoveredPoint?.date;
    const selectedDate = selectedPoint?.date;
    if (hoveredDate && hoveredDate !== selectedDate) drawBadges(chart, hoveredDate, false);
    if (selectedDate) drawBadges(chart, selectedDate, true);
  },
};

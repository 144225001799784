import React from 'react';
import popup from 'assets/welcomeFlow_inserts/custom home left.gif';
import WelcomeBox from './WelcomeBox';
import '@intus-ui/styles/Login.scss';
import '@intus-ui/styles/WelcomeFlow.scss';
import ModalButtons from './ModalButtons';

const HomePageIntroStep2 = () => {
  const modalButtons = <ModalButtons />;

  return (
    <WelcomeBox buttons={modalButtons}>
      <div className="text-dark h-100 w-100 d-flex flex-column justify-content-start align-items-center px-5">
        <div className="d-flex mb-3 justify-content-center">
          <h4 className="text-center text-secondary font-weight-bold">
            Next, let’s select 3 dashboards for your custom homepage.
          </h4>
        </div>
        <div className="d-flex flex-column py-3">
          <img className="mr-3" alt="Example homepage" src={popup} width="400em" />
          <p className="justify-self-right text-right text-muted pr-3">Example Home Page</p>
        </div>
      </div>
    </WelcomeBox>
  );
};
export default HomePageIntroStep2;

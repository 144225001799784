import { defaultChartColors, initiativeChartColors } from '@intus-ui/styles/SecondaryColors';
import { fill, set } from 'lodash';

export const adjustColorBrightness = (color, opacity) =>
  `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
    color.slice(5, 7),
    16
  )}, ${opacity})`;

// chart default config
export const defaultChartConfig = (index, metricColor) => {
  const config = { borderWidth: 1 };

  if (metricColor) {
    const colorValue = initiativeChartColors[metricColor];
    config.backgroundColor = adjustColorBrightness(colorValue, 0.5);
    config.borderColor = colorValue;
  } else {
    const color = Object.values(initiativeChartColors)[index];
    config.backgroundColor = adjustColorBrightness(color, 0.5);
    config.borderColor = color;
  }

  return config;
};

export const createDefaultChartConfig = (metricData, metricColor) => {
  const { chartData } = metricData?.data || {};
  const { datasets = [], labels = [] } = chartData || {};

  return {
    labels,
    datasets: datasets?.map((dataset, index) => {
      return {
        label: dataset.label,
        data: dataset.data,
        ...defaultChartConfig(index, metricColor),
        ...dataset,
      };
    }),
  };
};

export const generateHighlightColorArrayAtIndex = (metricColor, number, HighlightIndex) => {
  const colorValue = initiativeChartColors[metricColor] || defaultChartColors[metricColor];
  const arr = fill(Array(number), adjustColorBrightness(colorValue, 0.5));
  if (HighlightIndex >= 0) set(arr, HighlightIndex, adjustColorBrightness(colorValue, 1));

  return arr;
};

import { useGlobalState } from '@global-state';

import { getSessionUser } from '@util/session';

const useSubscriptions = () => {
  const userSettings = useGlobalState((state) => state.userSettings);
  const { subscriptions } = userSettings.subscriptions
    ? userSettings
    : getSessionUser().organization;

  return subscriptions || [];
};

export default useSubscriptions;

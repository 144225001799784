import PropTypes from 'prop-types';

const currentDashPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
});

export default currentDashPropType;

export const PREMIUM_DASH = 'PREMIUM_DASH';
export const CUSTOM_DASH = 'CUSTOM_DASH';
export const ORG_DASH = 'ORG_DASH';

import { useHistory } from 'react-router-dom';

import { Button, Card, Text, layout } from '@intus-ui';

const DetailsView = ({ cohort }) => {
  const { description, essentialNeeds = [], initiatives = [] } = cohort || {};
  const history = useHistory();

  const splitDescription = description?.split('\n') ?? [];

  const isActionCohort = cohort.type === 'Home';

  return (
    <div style={styles.container}>
      <div style={styles.description}>
        <Text type="title" color="navy">
          Description
        </Text>
        <Text wrapper="p" type="body">
          {!description && <>Sorry, it seems like no description has been added here.</>}
          {description && (
            <>
              {splitDescription.map((s) => {
                return <div>{s}</div>;
              })}
            </>
          )}
        </Text>
      </div>

      {essentialNeeds.length > 0 && (
        <div style={styles.planForCare}>
          <Text type="title" color="navy">
            Plan for Care
          </Text>

          {essentialNeeds.map(({ type, plan }) => {
            return (
              <div key={type} style={styles.planForCareItem}>
                <Text type="subtitle">{type}</Text> <Text type="body">{plan}</Text>
              </div>
            );
          })}
        </div>
      )}

      {!isActionCohort && (
        <div style={styles.initiatives}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <Text type="title" color="navy">
              Initiatives
            </Text>
            <Button
              onClick={() => {
                const params = new URLSearchParams();
                params.append('cohortId', cohort.id);
                history.push({
                  pathname: '/initiatives/create',
                  search: params.toString(),
                });
              }}
            >
              Create Initiative
            </Button>
          </div>
          {initiatives.length > 0 ? (
            <>
              <Text wrapper="p" type="body">
                This Cohort is used in the following Initiative(s):
              </Text>
              <div style={styles.initiativesCardsContainer}>
                {initiatives.map((initiative) => (
                  <Card
                    key={initiative.id}
                    fluid
                    onClick={() => {
                      history.push(`/initiatives/${initiative.id}/trials/${initiative.trialId}`);
                    }}
                    style={{
                      height: 172,
                    }}
                  >
                    <Text color="navy" type="subheader-bold">
                      {initiative.name}
                    </Text>
                    <br />
                    <Text
                      color="caption"
                      type="caption"
                      style={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitLineClamp: 8,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {initiative.description.length > 0
                        ? initiative.description
                        : 'Sorry, it seems like no description has been added here.'}
                    </Text>
                  </Card>
                ))}
              </div>
            </>
          ) : (
            <Text wrapper="p" type="body">
              Sorry, it seems like no Initiative has been added here.
            </Text>
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: { ...layout.container, gap: 20, padding: '0 20px', marginBottom: 15 },
  description: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'none',
    gap: 10,
  },
  planForCare: { display: 'flex', flexDirection: 'column', gap: 10 },
  planForCareItem: { display: 'flex', flexDirection: 'column', gap: 2 },
  initiatives: { flex: 1, display: 'flex', flexDirection: 'column', gap: 10 },
  initiativesCardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: '1fr',
    gap: 21,
  },
};

export default DetailsView;

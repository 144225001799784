import { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { deleteCohort, downloadCohort } from '@api';

import { getStringReplacement } from '@util/stringReplacements';
import { useGetSessionUser } from '@util/session';
import { getLogger } from '@util/logger';
import { newDate, subtractDate } from '@util/dateFunctions';

import { Alert, Snackbar } from '@mui/material';

import {
  Text,
  layout,
  Button,
  Icon,
  ConfirmationModal,
  Modal,
  SpinnerOrError,
  useParticipantFilters,
  TooltipComponent,
  TeamFacilityFilter,
} from '@intus-ui';
import Pills from 'AdvancedCohort/Pills';
import ResultTable from 'AdvancedCohort/SearchCreate/ResultView/ResultTable';
import { USER_ACCESS_ADMIN, USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import { useDownload } from '@api/useDownload';
import CohortsTabs from './CohortsTabs';
import DetailsView from './DetailsView';
import CohortMetrics from './CohortMetrics';
import { metrics } from './cohortMetricList';

import { getCohortById } from '../helpers';

const log = getLogger('ResultView');

const CohortFocusView = ({ searchState, setSearchState }) => {
  const { filter: existingFilter } = searchState;

  const { selectedFilters } = useParticipantFilters();

  const [cohort, setCohort] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeleteErrorModal, setShowDeleteErrorModal] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  const [fetchError, setFetchError] = useState();
  const [loading, setLoading] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState(null);

  const history = useHistory();
  const { cohortType, cohortId, tab } = useParams();
  const { search } = useLocation();
  const isActionCohort = cohortType === 'action';
  const isSharedCohort = cohortType === 'shared';

  const { access } = useGetSessionUser();

  const canModify =
    cohortType === 'personal' ||
    (isSharedCohort && (access === USER_ACCESS_ADMIN || access === USER_ACCESS_HEALTH_SYS_ADMIN));

  const isFixedCohort = (searchState?.fixed === true || cohort?.fixed === true) ?? false;

  const cohortTabs = [
    {
      tab: getStringReplacement('Participant', { plural: true }),
      tabUrl: `/cohorts/${cohortType}/${cohortId}/participants`,
      content: (
        <ResultTable
          participants={cohort?.formattedPatientList}
          addedPatientIds={cohort?.addedPatientIds}
          loading={loading}
        />
      ),
      rightButton: canModify ? (
        <Button
          name={isFixedCohort ? 'Edit' : 'Edit Filters'}
          onClick={() => {
            if (!isFixedCohort) {
              setSearchState({
                ...searchState,
                existingCohort: { ...cohort },
                cohortType,
                cohortId,
                fixed: isFixedCohort,
                view: 'SEARCH_CREATE',
              });
              history.push(`/cohorts/rolling/${cohortId}/edit`);
            } else {
              history.push(`/cohorts/fixed/${cohortId}/edit`);
            }
          }}
          secondary
        >
          <Icon name="Edit" color="#052D8F" />
        </Button>
      ) : null,
    },
    {
      tab: 'Details',
      tabUrl: `/cohorts/${cohortType}/${cohortId}/details`,
      content: <DetailsView cohort={cohort} />,
      rightButton: canModify ? (
        <Button
          name="Edit"
          hoverText="Edit"
          onClick={() => {
            if (!isFixedCohort) {
              setSearchState({
                ...searchState,
                existingCohort: { ...cohort },
                cohortType,
                cohortId,
                fixed: isFixedCohort,
                view: 'SEARCH_DETAILS',
              });
              history.push(`/cohorts/rolling/${cohortId}/edit`);
            } else {
              history.push(`/cohorts/fixed/${cohortId}/edit`);
            }
          }}
          secondary
        >
          <Icon name="Edit" color="#052D8F" />
        </Button>
      ) : null,
    },
    {
      tab: 'Metrics',
      tabUrl: `/cohorts/${cohortType}/${cohortId}/metrics`,
      content: <CohortMetrics cohort={cohort} isStatic={false} />,
      rightButton: null,
    },
  ];

  const tabParamList = cohortTabs.map((o) => o.tab.toLowerCase());

  useEffect(() => {
    if (!tabParamList.includes(tab))
      history.replace(`/cohorts/${cohortType}/${cohortId}/participants`);

    setLoading(true);
    getCohortById({
      cohortId,
      cohortType,
      isFixedCohort: search.includes('fixed=true'),
      filters: selectedFilters,
    })
      .then((cohortById) => {
        setCohort(cohortById);
        setLoading(false);
      })
      .catch((e) => {
        setFetchError('Internal system error - unable to fetch data.');
        log.error(e);
        setLoading(false);
      });
  }, [selectedFilters]);

  const handleDeleteCohort = () => {
    if (!canModify) return;

    deleteCohort(cohortId)
      .then(async (response) => {
        if (response.status === 409) {
          const message = await response.text();
          setDeleteError(message);
          setShowDeleteErrorModal(true);
        } else {
          if (isSharedCohort) {
            history.push('/cohorts/shared');
          } else {
            history.push('/cohorts/personal');
          }
        }
      })
      .catch((e) => {
        log.error(e);
      });
    setShowConfirmationModal(false);
  };

  // Replace non alphanumeric characters in the dashboard name.
  const fileName = `${cohort?.name?.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.xlsx`;

  const { runQuery: runDownloadQuery, loading: loadingDownloadCohort } = useDownload(
    fileName,
    async (data) => {
      return downloadCohort(data);
    },
    {
      onSuccess: () => {
        //  handleClose();
      },
      onError: () => {
        setSnackbarErrorMessage('An error occurred downloading the cohort');
      },
    }
  );

  return (
    <>
      <div style={styles.container}>
        {!cohort ? (
          <SpinnerOrError error={fetchError} />
        ) : (
          <>
            <div style={styles.header}>
              {/* Shows error messages if things like deleting a metric fails. */}
              <Snackbar
                open={snackbarErrorMessage != null}
                autoHideDuration={5000}
                onClose={() => setSnackbarErrorMessage(null)}
              >
                <Alert
                  onClose={() => setSnackbarErrorMessage(null)}
                  severity="error"
                  sx={{ width: '100%' }}
                >
                  {snackbarErrorMessage}
                </Alert>
              </Snackbar>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center', gap: 10 }}>
                <Button
                  style={{ width: 'fit-content' }}
                  hoverText="Back Button"
                  name="Back"
                  onClick={() => history.go(-1)}
                >
                  <Icon name="caret-left" />
                </Button>
                <Text type="title" color="navy">
                  {cohort?.name}
                </Text>
              </div>
              <div style={styles.headerButtons}>
                <TeamFacilityFilter isCohortsPage />
                {canModify && (
                  <Button
                    name="Delete"
                    hoverText="Delete"
                    onClick={() => setShowConfirmationModal(true)}
                    secondary
                  >
                    <Icon name="Trash" color="#052D8F" />
                  </Button>
                )}
                <Button
                  name="Download"
                  hoverText="Download Cohort as an Excel"
                  secondary
                  style={{ minWidth: '135px' }}
                  busy={loadingDownloadCohort}
                  onClick={() =>
                    runDownloadQuery({
                      cohort,
                      metrics,
                      filters: selectedFilters,
                      startDate: subtractDate(newDate(), 6, 'months'),
                      endDate: newDate(),
                    })
                  }
                >
                  <Icon name="Download" color="#052D8F" />
                </Button>
              </div>
            </div>
            <div style={styles.cohortDetails}>
              {!isActionCohort && !isFixedCohort && (
                <div style={styles.pillsContainer}>
                  <Pills filter={existingFilter || cohort.filter} focused />
                </div>
              )}
              <div style={styles.quadWrapper}>
                <CohortsTabs
                  items={cohortTabs}
                  topLeftContent={
                    <>
                      <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                        <Icon name="People" size="medium" color="#9D9D9D" />
                        <Text type="body" color="caption">
                          {cohort?.count}
                        </Text>
                      </div>
                      <TooltipComponent
                        anchorId="Info"
                        content={
                          <Text type="caption">
                            This number only reflects the actively enrolled participants.
                          </Text>
                        }
                      >
                        <Icon name="Info" size="tiny" color="#052D8F" hoverColor="#052D8F" />{' '}
                      </TooltipComponent>
                    </>
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        open={showDeleteErrorModal}
        onClose={() => setShowDeleteErrorModal(false)}
        header={{ title: 'Error Deleting Cohort', centered: true }}
        style={(layout.centeredContentContainer, { textAlign: 'center' })}
      >
        {deleteError}
      </Modal>
      {canModify && (
        <ConfirmationModal
          open={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          title="Delete Cohort"
          description={`Are you sure you want to delete cohort "${cohort?.name}"`}
          confirmText="Delete Cohort"
          onClickConfirm={() => handleDeleteCohort()}
        />
      )}
    </>
  );
};

const styles = {
  container: {
    ...layout.container,
    gap: 12,
    padding: '0 20px 15px 0',
  },
  header: {
    ...layout.rowContainer,
    flex: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowY: 'visible',
  },
  teamFacilityFilters: {
    display: 'flex',
    alignItems: 'center',
  },
  headerButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  pillsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  },
  cohortDetails: {
    ...layout.container,
    flex: 1,
    gap: 10,
  },
  quadWrapper: {
    ...layout.container,
    flex: 1,
    gap: 10,
    padding: 5,
  },
};

export default CohortFocusView;

import { each } from 'lodash';
import { buildPatientFilterQueryString } from '@api/patients/buildPatientFilterQueryString';
import { deleteAPI, get, post, put } from '@api/apiMethods';
import { clearSession, setSessionToken, getSessionUser } from '../@util/session';

const toJson = async (resp) => resp.json();

export const renewAuthToken = async () => {
  const tokenDictionary = await post('users/renew-token', {}, false).then(toJson);
  if (tokenDictionary.status === 401) {
    clearSession();
  }
  setSessionToken(tokenDictionary.token);
  return null;
};

export const getPowerBiEmbedData = async (reportName) => get(`powerBI/${reportName}/getEmbedInfo`);

export const getReportUsers = async (reportName) =>
  get(`powerBI/${reportName}/getReportUsers`).then(toJson);

export const getElligibleReportUsers = async (reportName) =>
  get(`powerBI/${reportName}/getElligibleReportUsers`).then(toJson);

export const getPowerBiAdminableReports = async () =>
  get(`powerBI/getAdminableReports`).then(toJson);

export const bulkUpsertReportUsers = async (reportId, body) =>
  post(`powerBI/${reportId}/bulkUpsertReportUsers`, body);

export const bulkRemoveReportUsers = async (reportId, body) =>
  post(`powerBI/${reportId}/bulkRemoveReportUsers`, body);

export const getPowerBiReportsList = async () => get('powerBI/getViewableReports').then(toJson);

export const getPatientAcuity = (patientId) => get(`acuity-level?patientId=${patientId}`);

export const getPatientHospitalizations = (patientId) =>
  get(`hospitalizations?patientId=${patientId}`);

export const getPatientAdmits = (patientId) => get(`admits?patientId=${patientId}`);

export const updatePatientHospice = (body) => post(`patients/hospice`, body);
export const getPatientCarePlan = (patientId) => get(`care-plan?patientId=${patientId}`);

export const updateOrganizationAPI = (body, organizationId) =>
  put(`organizations/${organizationId}`, body);

export const getAcuityQuestionsAPI = () => get('acuity-level/questions');

export const postAcuityAPI = (body) => post('acuity-level', body);

export const postOrganizationAPI = (body) => post('organizations', body);

export const getOrgs = () => get('organizations', false).then(toJson);

export const getHealthSystemAdminOrganizations = () => get('organizations');

export const getOrgByIdAPI = (orgId) => get(`organizations/${orgId}`);

export const getInactiveOrgs = () => get('organizations?status=INACTIVE', false).then(toJson);

export const getPatientsByOrgAPI = (orgId) => get(`patients?organizationId=${orgId}`);
// retrieves all patients (active and inactive) in the current org (sent in the headers)
export const getAllPatients = (selectedFilters) => {
  const filters = buildPatientFilterQueryString(selectedFilters);
  return get(`patients?${filters}`);
};

export const getActivePatients = (selectedFilters) => {
  const filters = buildPatientFilterQueryString(selectedFilters);
  return get(`patients?status=ACTIVE&${filters}`);
};

export const getInactivePatients = (selectedFilters) => {
  const filters = buildPatientFilterQueryString(selectedFilters);
  return get(`patients?status=INACTIVE&${filters}`);
};

export const getUsersByOrg = (orgId) => get(`users?organizationId=${orgId}`).then(toJson);

export const getUsersByOrgAPI = (orgId) => get(`users?organizationId=${orgId}&showAll=true`);

export const getLatestOrgAcuities = async () => get('acuity-level?newestOnly=true').then(toJson);

export const getUserByIdAPI = (userId) => get(`users/${userId}`);

export const getPatientByIdAPI = (patientId) => {
  renewAuthToken();
  return get(`patients/${patientId}`);
};

export const getPatientTimeline = async (patientId) =>
  get(`patients/${patientId}/timeline`).then(toJson);

export const getPatientCurrentMedications = async (patientId) => {
  return get(`patients/${patientId}/currentMedications`);
};

export const updateUserAPI = (body, userId) => put(`users/${userId}`, body);

export const changeMyOrg = (body) => put('users/changeMyOrg', body);

export const getAllFeatureFlags = () => get('featureFlags');

export const changeMyFlags = (body) => put('users/changeMyFlags', body);

export const postPatientAPI = (data) => post('patients', data);

// given a list of ids, returns list of patients
export const postPatientListAPI = (data) => {
  return post('patients/list', data);
};

export const postUserAPI = (data) => post('users', data);

export const postBulkUsersAPI = (data) => post('users/bulkcreate', data);

export const postUserToken = (data) => post('users/verifyToken', data, true);

export const fetchUser = (userId) => get(`users/${userId}`).then(toJson);

export const fetchUserByEmail = (email) =>
  get(`users?email=${encodeURIComponent(email)}`).then(toJson);

export const postResetPassword = (data) => post('users/resetPassword', data);

export const fetchEnrollments = (body) => post('patients/recentlyUpdated', body);

export const getDashboardMonthlyAPI = () => get('monthly-data');

export const getNewestMonthlyData = () => get('monthly-data?newestOnly=true');

export const getAcuityLevelChanges = (id, range) =>
  get(`acuity-level/changes?patientId=${id}&range=${range}`);

export const sendFeedback = (data) => post('users/sendFeedback', data);

export const getTeamsByOrg = async (orgId) => {
  const bodyVal = await get(`organizations/${orgId}/teams`);
  if (bodyVal.status === 401) {
    clearSession();
  }
  return bodyVal;
};

export const getFacilitiesByOrg = (orgId) => get(`organizations/${orgId}/facilities`);

export const getLeaderboardTeamsData = (body) => post('dashboard/leaderboard', body);

export const getLeaderboardMonthlyComparison = (body) => post('dashboard/monthlyComparison', body);

export const getIncidentsByPatient = (patientId) => get(`patients/${patientId}/incidents`);

export const getPatientFallsAnalysis = (patientId) =>
  get(`patients/${patientId}/fallsAnalysis`).then(toJson);

export const getRecentStats = (patientId, startDate) =>
  get(`patients/${patientId}/recent-stats?startDate=${startDate}`);

export const getRecentEvents = (selectedEventTypes, daysBefore, filters) => {
  const filterString = buildPatientFilterQueryString(filters);

  const url = new URLSearchParams(filterString);
  url.append('daysBefore', daysBefore);
  selectedEventTypes.forEach((eventType) => {
    url.append(eventType, true);
  });

  return get(`patients/recentEvents?${url.toString()}`);
};

export const getIcdCodes = (codes) => post('icdCodes/list', { codes }).then(toJson);

export const getAllIndicators = () => get('dashboard/indicators');

export const getAllIndicatorsFlat = () => get('dashboard/indicators?format=indexByDisplayName');

export const getDashItem = (body) => post('dashboard', body);

// Would be helpful if this had cohorts in it as well or just returned an id instead of null
export const getDashItemsByList = (list) => post('dashboard/list', list);

export const downloadXLSX = async (data, months) => post('dashboard/xlsx', { months, data });

export const createDashboardAPI = (userId, body) => post(`users/${userId}/customDashboard`, body);

export const updateDashboardAPI = (userId, customDashId, body) =>
  put(`users/${userId}/customDashboard/${customDashId}`, body);

export const getCustomDashIndicatorsAPI = (userId, customDashId) =>
  get(`users/${userId}/customDashboard/${customDashId}/indicators`);

export const deleteDashboardAPI = (userId, customDashboardId) =>
  deleteAPI(`users/${userId}/customDashboard/${customDashboardId}`);

export const createOrgDashboardAPI = (orgId, body) =>
  post(`organizations/${orgId}/customDashboard`, body);

export const updateOrgDashboardAPI = (orgId, customDashId, body) =>
  put(`organizations/${orgId}/customDashboard/${customDashId}`, body);

export const deleteOrgDashboardAPI = (orgId, customDashId) =>
  deleteAPI(`organizations/${orgId}/customDashboard/${customDashId}`);

export const getOrgDashIndicatorsAPI = (orgId, dashId) =>
  get(`organizations/${orgId}/customDashboard/${dashId}/indicators`);

export const getStaticCohorts = ({ isShared }) => {
  const { organizationId } = getSessionUser();
  return get(`static-cohorts?organizationId=${organizationId}&isShared=${isShared}`);
};

export const deleteStaticCohort = ({ cohortId }) => {
  return deleteAPI(`static-cohorts/${cohortId}`);
};

export const updateStaticCohort = (cohortId, body) => {
  return put(`static-cohorts/${cohortId}`, body);
};

export const createStaticCohorts = (body) => {
  const { organizationId } = getSessionUser();
  return post(`static-cohorts?organizationId=${organizationId}`, body);
};

const formatCohortFilters = ({ filters, fixed = false, query = {} }) => {
  const queryStrings = [];
  if (filters) queryStrings.push(buildPatientFilterQueryString(filters));
  if (fixed === true) {
    queryStrings.push('fixed=true');
  }

  each(query, (v, k) => queryStrings.push(`${k}=${v}`));

  if (queryStrings.length) {
    return `?${queryStrings.join('&')}`;
  }

  return '';
};

export const getCohorts = (params) => get(`cohorts${formatCohortFilters(params)}`);

export const getCohortsNames = () => get(`cohorts/cohortsNames`);

export const getActionCohorts = (params) =>
  get(`cohorts/billingCohorts${formatCohortFilters(params)}`);

export const getCohort = (id) => get(`cohorts?id=${id}`);

export const createCohort = (body) => post('cohorts', body);

// [DEPRECATED] body must include organizationId
export const createSharedCohort = (body) => post('cohorts/shared', body);

export const editCohort = (cohortId, body) => put(`cohorts/${cohortId}`, body);

export const deleteCohort = (cohortId) => deleteAPI(`cohorts/${cohortId}`);

export const getCohortSearchTerms = () => get('cohorts/searchTerms');

export const postCohortSearch = (body) => post(`cohorts/search`, body);
export const getTrackingEvent = (event) => post('tracking/event', event).then(toJson);

export const getRecentOrgStatus = (orgId) => get(`status?organizationId=${orgId}`);

export const getAllTrackingEvents = (orgId, userId) =>
  get(`/tracking/event?organizationId=${orgId}&userId=${userId}`);

export const getTrackingEventsNoOrg = () => get('tracking/event');

export const getAllTrackingSessions = () => get('tracking/session');

export const getRecentTrackingSessions = (sessionsAfterDate) =>
  get(`/tracking/recentLogins?sessionsAfterDate=${sessionsAfterDate}`);

export const createNpsSurvey = (body) => post('nps-survey', body);

// HOME PAGE

export const getAllHomePageBoxes = () => get('settings/homePageBoxNames');

export const getAllJobTypes = () => get('settings/jobTypes');

export const getPreview = (boxName, startDate, endDate, filters) => {
  const url = new URLSearchParams({
    startDate,
    endDate,
  });

  const filterString = buildPatientFilterQueryString(filters);

  return get(`dashboard/homeBoxes/${boxName}/preview?${url.toString()}&${filterString}`);
};

export const getPopup = (boxName, startDate, endDate, filters) => {
  const url = new URLSearchParams({
    startDate,
    endDate,
  });

  const filterString = buildPatientFilterQueryString(filters);

  return get(`dashboard/homeBoxes/${boxName}/popup?${url.toString()}&${filterString}`);
};

export const saveConnectionInfo = (data) => post('connection-info', data);

export const downloadCohort = (data) => post(`cohorts/download`, data);

import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, ImageRun, TableCell, Table, TableRow, TextRun } from 'docx';
import html2canvas from 'html2canvas';
import { dateDifference, formatDate } from '@util/dateFunctions';

const getCanvasBlob = async () => {
  const canvasData = await html2canvas(document.getElementById('fluidContentContainer'));
  const dataUrl = canvasData.toDataURL('image/png');
  const graphBlob = await fetch(dataUrl)
    .then((response) => response.blob())
    // eslint-disable-next-line no-console
    .catch((error) => console.error(error));

  return graphBlob;
};

const textRunCell = (text, bold = false) => {
  return new TableCell({
    children: [
      new Paragraph({
        children: [new TextRun({ text, bold })],
      }),
    ],
  });
};

const generateTable = (
  firstName,
  lastName,
  formattedPrincipalDx,
  lengthOfStay,
  startDate,
  endDate,
  iconDesc,
  timeSinceEnrollment,
  livingSituation
) => {
  const table = new Table({
    rows: [
      new TableRow({
        children: [
          textRunCell('Ppt Name', true),
          textRunCell(`${firstName} ${lastName}`),
          textRunCell('Dates of admit/Ed visit', true),
          textRunCell(
            `${formatDate(startDate, 'MM/DD/YYYY')} - ${
              endDate ? formatDate(endDate, 'MM/DD/YYYY') : 'no end date'
            } `
          ),
        ],
      }),
      new TableRow({
        children: [
          textRunCell('Last PCP Visit', true),
          textRunCell(``),
          textRunCell('Code Status', true),
          textRunCell(``),
        ],
      }),
      new TableRow({
        children: [
          textRunCell('Admitting Dx', true),
          textRunCell(formattedPrincipalDx),
          textRunCell('Type of Stay', true),
          textRunCell(iconDesc),
        ],
      }),
      new TableRow({
        children: [
          textRunCell('HX of conditions', true),
          textRunCell('TBD'),
          textRunCell('Length of stay', true),
          textRunCell(`${lengthOfStay} days`),
        ],
      }),
      new TableRow({
        children: [
          textRunCell('Time since enrollment', true),
          textRunCell(`${timeSinceEnrollment} months`),
          textRunCell('Time of presentation to ED', true),
          textRunCell('TBD'),
        ],
      }),
      new TableRow({
        children: [
          textRunCell('admitted from', true),
          textRunCell('TBD'),
          textRunCell('living situation', true),
          textRunCell(livingSituation),
        ],
      }),
      new TableRow({
        children: [
          textRunCell('Was PO_NAME PACE aware of ppt in ED? ', true),
          textRunCell('TBD'),
          textRunCell('previous adm last 6 months?', true),
          textRunCell('TBD'),
        ],
      }),
    ],
  });
  return table;
};

const generateBottomTable = () => {
  const prompts = [
    'Summary of events 3 weeks and immediately before ppt presented to ED (was ppt seen by PCP in prior 48 hours?):',
    'Is there evidence of management of the condition/symptoms with the goal of preventing the progression of symptoms to a state requiring hospitalization?',
    'Was the management of the condition consistent with the ppt’s goals of care and what matters most to him/her?',
    'Is there evidence of worsening symptoms of the condition prior to the ED visit or hospitalization? Were symptoms reported to the clinic?',
    'Is there evidence of access to care in the clinic or the ppt’s home with the PCP at the time when symptoms were observed?',
    'Is there evidence of a change in care in response to the reported symptoms? ',
    'Did the ppt respond to the treatment as expected?',
    'Was the ppt’s care plan updated following a previous ED visit or hospitalization? Is there evidence that the new or changed intervention(s) was implemented?',
    'Was the ppt’s care plan updated after this ED visit or hospitalization with an intervention(s) to address the cause of the ED visit or hospitalization?',
    ' Date of post hospital/ED PCP evaluation. Were hospitalist/ED MD recommendations reviewed and considered?',
    'Date of RN Care Coordinator/Manager visit. What was the outcome?',
    'What could have prevented this hospitalization? What follow up is necessary?',
  ];
  const table = new Table({
    rows: prompts.map((prompt) => {
      return new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph(prompt),
              new Paragraph(' '),
              new Paragraph(' '),
              new Paragraph(' '),
              new Paragraph(' '),
            ],
          }),
        ],
      });
    }),
  });
  return table;
};
const generatePrettyDoc = async (
  firstName,
  lastName,
  formattedPrincipalDx,
  lengthOfStay,
  startDate,
  endDate,
  iconDesc,
  timeSinceEnrollment,
  livingSituation
) => {
  const imgBlob = await fetch(`${window.location.origin}/logo512.png`).then((r) => r.blob());
  const graphBlob = await getCanvasBlob();
  const imgBlobData = await imgBlob;
  const graphBlobData = await graphBlob;

  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            children: [
              new ImageRun({
                data: imgBlobData,
                transformation: {
                  width: 100,
                  height: 100,
                },
              }),
            ],
          }),
          generateTable(
            firstName,
            lastName,
            formattedPrincipalDx,
            lengthOfStay,
            startDate,
            endDate,
            iconDesc,
            timeSinceEnrollment,
            livingSituation
          ),
          new Paragraph({
            children: [
              new TextRun({
                text: '-',
                bold: true,
              }),
            ],
          }),
          generateBottomTable(),
          new Paragraph({
            children: [
              new ImageRun({
                data: graphBlobData,
                transformation: {
                  width: 600,
                  height: 500,
                },
              }),
            ],
          }),
        ],
      },
    ],
  });
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, 'RCA.docx');
  });
  return doc;
};

export const copyToClipAndMakeDoc = async (data, patientDetail) => {
  const {
    formattedPrincipalDx,
    type,
    principalDX,
    startDate,
    date,
    endDate,
    iconDesc,
    livingSituation,
  } = data;
  const { firstName, lastName, enrollmentDate } = patientDetail;
  const timeSinceEnrollment = dateDifference(startDate || date, enrollmentDate, 'months');

  generatePrettyDoc(
    firstName,
    lastName,
    formattedPrincipalDx || principalDX,
    (startDate || date) && endDate
      ? dateDifference(endDate, startDate || date, 'days')
      : 'lengthOfStay not available',
    startDate || date,
    endDate,
    iconDesc || type,
    timeSinceEnrollment,
    livingSituation
  );
};

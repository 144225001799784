/**
 * columnConfigs must have an object with a 'columns' field
 * participant list must have a 'default' key
 */
export const defaultColumnConfig = {
  default: {
    columns: ['name', 'livingSituation', 'age', 'acuity', 'scoreGroup'],
    breakpoints: [
      { minWidth: 635, columns: ['name', 'livingSituation', 'age', 'acuity', 'scoreGroup'] },
      { minWidth: 470, columns: ['name', 'acuity', 'scoreGroup'] },
      { minWidth: 415, columns: ['name'] },
    ],
  },
  disenrolled: {
    columns: ['name', 'livingSituation', 'disenrollDate', 'disenrollReason'],
    breakpoints: [
      {
        minWidth: 635,
        columns: ['name', 'livingSituation', 'disenrollDate', 'disenrollReason'],
      },
      { minWidth: 415, columns: ['name', 'disenrollDate', 'disenrollReason'] },
    ],
  },
};

export const pinnedPptConfig = {
  default: {
    columns: ['name', 'acuity', 'scoreGroup'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'acuity', 'scoreGroup'] }],
  },
};

export const pinnedPptCollapsedConfig = {
  default: {
    columns: ['name'],
    breakpoints: [{ minWidth: 1, columns: ['name'] }],
  },
};

export const pinnedPptEditConfig = {
  default: {
    columns: ['pinnedIcon', 'name', 'acuity', 'scoreGroup'],
    breakpoints: [{ minWidth: 1, columns: ['pinnedIcon', 'name', 'acuity', 'scoreGroup'] }],
  },
};

export const customDashModalConfig = {
  default: {
    columns: ['name', 'scoreGroup'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'scoreGroup'] }],
  },
};

export const customDashModalCountableConfig = {
  default: {
    columns: ['name', 'instanceCount', 'scoreGroup'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'instanceCount', 'scoreGroup'] }],
  },
};

export const highestRiskPptsConfig = {
  default: {
    columns: ['name', 'acuity', 'scoreGroup'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'acuity', 'scoreGroup'] }],
  },
};

export const frequentFallersConfig = {
  default: {
    columns: ['name', 'instanceCount'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'instanceCount'] }],
  },
};

export const admissionsConfig = {
  default: {
    columns: ['name', 'cohorts', 'admissions'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'admissions'] }],
  },
};

export const inpatientAdmissionsConfig = {
  default: {
    columns: ['name', 'cohorts', 'inpatientAdmissions'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'inpatientAdmissions'] }],
  },
};

export const fallsConfig = {
  default: {
    columns: ['name', 'cohorts', 'falls'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'falls'] }],
  },
};

export const lengthOfStayConfig = {
  default: {
    columns: ['name', 'cohorts', 'totalLOS'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'totalLOS'] }],
  },
};

export const infectionsConfig = {
  default: {
    columns: ['name', 'cohorts', 'infections'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'infections'] }],
  },
};

export const medicineErrorsConfig = {
  default: {
    columns: ['name', 'cohorts', 'medicineErrors'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'medicineErrors'] }],
  },
};

export const emergencyroomReoccurrencesConfig = {
  default: {
    columns: ['name', 'cohorts', 'emergencyroomReoccurrences'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'emergencyroomReoccurrences'] }],
  },
};

export const inpatientReoccurrencesConfig = {
  default: {
    columns: ['name', 'cohorts', 'inpatientReoccurrences'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'inpatientReoccurrences'] }],
  },
};

export const majorHarmOrDeathFallIncindentsConfig = {
  default: {
    columns: ['name', 'cohorts', 'majorHarmOrDeathFallIncindents'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'majorHarmOrDeathFallIncindents'] }],
  },
};

export const fallCountConfig = {
  default: {
    columns: ['name', 'cohorts', 'fallCount'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'fallCount'] }],
  },
};

export const erVisitCountConfig = {
  default: {
    columns: ['name', 'cohorts', 'erVisitCount'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'erVisitCount'] }],
  },
};

export const inpatientAdmissionsCountConfig = {
  default: {
    columns: ['name', 'cohorts', 'inpatientAdmissionsCount'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'cohorts', 'inpatientAdmissionsCount'] }],
  },
};

export const configMap = {
  admissions: admissionsConfig,
  inpatientAdmissions: inpatientAdmissionsConfig,
  falls: fallsConfig,
  totalLOS: lengthOfStayConfig,
  infections: infectionsConfig,
  medicineErrors: medicineErrorsConfig,
  emergencyroomReoccurrences: emergencyroomReoccurrencesConfig,
  inpatientReoccurrences: inpatientReoccurrencesConfig,
  majorHarmOrDeathFallIncindents: majorHarmOrDeathFallIncindentsConfig,
  fallCount: fallCountConfig,
  erVisitCount: erVisitCountConfig,
  inpatientAdmissionsCount: inpatientAdmissionsCountConfig,
};

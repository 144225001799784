import React, { useState } from 'react';

import { formatDate } from '@util/dateFunctions';
import { useGetSessionUser } from '@util/session';
import { logEvent } from '@util/userTracking';

import { DetailsButton, Modal, SpinnerOrError, Text, useParticipantFilters } from '@intus-ui';
import { USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import DetailsModal from 'Utilization/DetailsModal';
import ExecutiveViewChartDetails from 'Utilization/ExecutiveView/ExecutiveViewChartDetails';
import { getAdmmittedParticipants } from 'Utilization/ExecutiveView/helperFunctions';
import { styles } from 'Utilization/Sidebar/styles';

import { useAdmitsContext } from 'Admits/Context';

const General = ({ data }) => {
  const user = useGetSessionUser();
  const { selectedFilters } = useParticipantFilters();
  const orgName = selectedFilters?.organization || 'All organizations';
  const isHealthSysAdmin = user.access === USER_ACCESS_HEALTH_SYS_ADMIN;
  const { admitsType, startDate, endDate } = useAdmitsContext();
  const { totalAdmits } = data;

  return (
    <>
      <Text type="subtitle">General</Text>
      <div style={styles.generalAnalysisItems}>
        <Text type="headline" style={{ paddingRight: '10px' }}>
          {totalAdmits.toLocaleString()}
        </Text>
        <Text>
          {admitsType} admits between {formatDate(startDate, 'M/D/YYYY')} and{' '}
          {formatDate(endDate, 'M/D/YYYY')} {isHealthSysAdmin && `in ${orgName}`}
        </Text>
      </div>
    </>
  );
};

const READMIT_INCLUDE_LIST = ['IP', 'ED', 'ED/IP', 'ED/OBS', 'IP PSYCH', 'OBS', 'SNF'];
const READMIT_TOOLTIP = {
  IP: 'The readmission period for acute inpatient admissions is set at 30 days.',
  ED: 'The readmission period for ED admissions is set at 90 days.',
  'ED/IP': 'The readmission period for ED to Inpatient is set at 30 days.',
  'ED/OBS': 'The readmission period for ED to Observation is set at 30 days.',
  'IP PSYCH': 'The readmission period for Inpatient Psychiatric admissions is set at 30 days.',
  OBS: 'The readmission period for Observation stays is set at 30 days.',
  SNF: 'The readmission period for Skilled Nursing Facility admissions is set at 6 months.',
};

const Trends = ({ data, admits }) => {
  const { admitsType, admitTypeMap } = useAdmitsContext();
  const { principalDx } = data;
  const [openModal, setOpenModal] = useState(false);
  const [openPrincipalDxDetailsModal, setOpenPrincipalDxDetailsModal] = useState(false);
  const [modalAdmits, setModalAdmits] = useState([]);
  const [modalTitle, setModalTitle] = useState();
  // Sort the principalDx by value and take the top 5
  const sortedPrincipalDx = [...principalDx].sort((a, b) => b.value - a.value).slice(0, 5);

  const { readmits, losGTE10days } = data;

  const admitTypeCode = admitTypeMap[admitsType];
  const tooltipContent = READMIT_TOOLTIP[admitTypeCode];

  const onClick = (title, admitIds) => {
    const filteredAdmits = admits.filter((admit) => admitIds.includes(admit.id));
    setModalTitle(title);
    setModalAdmits(filteredAdmits);
    setOpenModal(true);
  };

  const showPrincipalDxDetails = () => {
    setOpenPrincipalDxDetailsModal(true);
  };

  const { chartData, admittedParticipants } = generateDetailViewData(sortedPrincipalDx, admits);

  return (
    <>
      <Modal
        header={{ title: modalTitle, centered: true }}
        type="large"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DetailsModal admits={modalAdmits} admitsType={admitsType} parentPage="Admits Feed" />
      </Modal>

      <Modal
        header={{
          title: (
            <Text type="title" color="navy">
              Top 5 Principal Dx
            </Text>
          ),
          centered: false,
        }}
        open={openPrincipalDxDetailsModal}
        onClose={() => setOpenPrincipalDxDetailsModal(false)}
        type="large"
      >
        <ExecutiveViewChartDetails
          xAxisLabel={modalTitle}
          chartData={chartData}
          admittedParticipants={admittedParticipants}
          top5PrincipalDxData={sortedPrincipalDx}
        />
      </Modal>

      <div style={styles.analyticsContainer}>
        <Text type="subtitle">Trends</Text>

        {READMIT_INCLUDE_LIST.includes(admitTypeCode) && (
          <div style={styles.analyticsItem}>
            <div style={styles.analyticsStat}>
              <Text type="title">{readmits.value}%</Text>
              <Text wrapper="span">of these admits were readmissions</Text>
            </div>
            <div>
              <Text type="caption">{tooltipContent}</Text>
            </div>
            <div style={styles.seeDetails}>
              <DetailsButton
                onClick={() => {
                  logEvent(`Admits Feed, Analytics Tab: Clicked 'See Details' button`);
                  onClick('Readmissions', readmits.admits);
                }}
              />
            </div>
          </div>
        )}

        <div style={styles.analyticsItem}>
          <div style={styles.analyticsStat}>
            <Text type="title">{losGTE10days.value}%</Text>
            <Text wrapper="span">of these admit stays were longer than 10 days</Text>
          </div>
          <div style={styles.seeDetails}>
            <DetailsButton
              onClick={() => {
                onClick('Long Length of Stay', losGTE10days.admits);
              }}
            />
          </div>
        </div>
        <div style={{ ...styles.analyticsItem, gap: 10 }}>
          <div style={styles.analyticsStat}>
            <Text type="title">Top 5 Principal Dx</Text>
          </div>
          <div style={{ display: 'flex', gap: 5, flexDirection: 'column', width: '100%' }}>
            {sortedPrincipalDx.length > 0 ? (
              sortedPrincipalDx.map((dx, index) => (
                <div style={{ ...styles.analyticsStat, gap: 5 }} key={dx.id}>
                  <Text type="body">{index + 1}.</Text>
                  <Text type="body">{dx.description}</Text>
                </div>
              ))
            ) : (
              <div style={styles.analyticsStat}>
                <Text type="body">No Principal Dx available</Text>
              </div>
            )}
            {sortedPrincipalDx.length > 0 && (
              <div style={styles.seeDetails}>
                <DetailsButton onClick={showPrincipalDxDetails} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const AnalyticsTab = ({ data, loading, error }) => {
  if (loading || error || !data) {
    return <SpinnerOrError error={error && 'An error Occured'} />;
  }
  return (
    <div style={styles.sidebarContentContainer}>
      <General data={data.analytics} />
      <Trends data={data.analytics} admits={data.admits} />
    </div>
  );
};

const generateDetailViewData = (sortedPrincipalDx, allAdmits) => {
  const chartData = {
    labels: [],
    dataPoints: [],
    targets: [],
  };

  const admittedParticipants = {
    all: [],
  };

  sortedPrincipalDx.forEach((item) => {
    chartData.labels.push(item.description);
    chartData.dataPoints.push(item.value);
    chartData.targets.push(0); // No targets for this chart
    // Map the admit IDs to full admit objects for each description
    admittedParticipants[item.description] = item.admits
      .map((admitId) => allAdmits.find((admit) => admit.id === admitId))
      .filter((admit) => admit != null); // Ensure that only valid admits are included
    // Add all admits to the 'all' key
    admittedParticipants.all.push(...item.admits);
  });

  // Deduplicate the 'all' array and map to full admit objects
  admittedParticipants.all = [...new Set(admittedParticipants.all)]
    .map((admitId) => allAdmits.find((admit) => admit.id === admitId))
    .filter((admit) => admit != null); // Ensure that only valid admits are included

  // Now, apply the getAdmmittedParticipants transformation to each group
  Object.keys(admittedParticipants).forEach((key) => {
    admittedParticipants[key] = getAdmmittedParticipants(admittedParticipants[key]);
  });

  return { chartData, admittedParticipants };
};

export default AnalyticsTab;

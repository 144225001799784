import React from 'react';
import PropTypes from 'prop-types';
import '@intus-ui/styles/FilteredList.scss';
import '@intus-ui/styles/Utilities.scss';
import { shadesOfBlue } from '@intus-ui/styles/SecondaryColors';

// Icon
const Pin = ({ fillColor, hoverColor, lineColor, padding }) => (
  <div className={`rounded-lg ${padding}`}>
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34" height="34" rx="5" fill={hoverColor} fillOpacity="0.16" />
      <path
        d="M20.5245 19.5178L27.2028 12.8395L22.1606 7.79731L15.4823 14.4756C14.1809 14.0856 12.7982 14.0552 11.4809 14.3875C10.1636 14.7198 8.96078 15.4025 8.0001 16.3632L18.6369 27C19.5976 26.0393 20.2803 24.8365 20.6126 23.5192C20.9449 22.2019 20.9145 20.8192 20.5245 19.5178Z"
        stroke={lineColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M20.2141 5.85107L29.1467 14.7837"
        stroke={lineColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8 26.9973L13.3196 21.6777"
        stroke={lineColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  </div>
);

Pin.propTypes = {
  lineColor: PropTypes.string,
  hoverColor: PropTypes.string,
  fillColor: PropTypes.string,
  padding: PropTypes.string,
};

Pin.defaultProps = {
  hoverColor: 'none',
  lineColor: shadesOfBlue.intusNavy,
  fillColor: 'none',
  padding: 'p-0',
};

export default Pin;

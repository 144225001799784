import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Card from '@intus-ui/components/Card';
import Icon from '@intus-ui/components/Icon';
import Text from '@intus-ui/components/Text';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import { useParticipantFilters } from '@intus-ui/components/filters';
import { widgetComponents } from '@intus-ui/components/Widget/components';

import { useLazyQuery } from '@api/useQuery';
import layouts from '@intus-ui/layouts';
import { getMetricById } from '@api/dashboard';

import WidgetHeader from '@intus-ui/components/Widget/WidgetHeader';
import WidgetModal from '@intus-ui/components/Widget/WidgetModal';
import { newDate, subtractDate } from '@util/dateFunctions';

const CohortWidget = ({ metric, hideSeeMore, cohort }) => {
  const history = useHistory();
  const location = useLocation();

  const startDate = subtractDate(newDate(), 6, 'months');
  const endDate = newDate();
  const isBilling = location.pathname.includes('action');
  const formattedCohort = [{ id: cohort?.id, name: cohort?.name, isBilling }];
  const [isOpen, setIsOpen] = useState(() => {
    // If the user clicked a participant in a list in the focus view and hit the back button,
    // we re-open the modal when they go back.
    const params = new URLSearchParams(history.location.search);
    const openMetricId = params.get('visibleMetricId');
    if (openMetricId != null && openMetricId === metric.metricId) {
      return true;
    }

    return false;
  });

  const { selectedFilters } = useParticipantFilters();

  const {
    data: metricData,
    loading: metricDataLoading,
    error: metricDataError,
    runQuery,
  } = useLazyQuery(() =>
    getMetricById(metric.metricId, startDate, endDate, metric.metricConfig, {
      ...selectedFilters,
      cohorts: formattedCohort,
    })
  );

  // We need to do a deep diff on the fields in the metric config.
  // If any of the fields change re-run the query.
  const metricConfigJSON = JSON.stringify(metric.metricConfig);

  useEffect(() => {
    if (metric.metricId) {
      runQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metric.metricId, metricConfigJSON, selectedFilters, cohort]);

  // Wrapper around setIsOpen that adds query params to the URL to
  // let us re-open the modal if the user clicks a participant and hits the back button.
  const setIsOpenWrapper = useCallback(
    (isOpen) => {
      const params = new URLSearchParams(history.location.search);

      setIsOpen(isOpen);

      // Add a query param to the URL to indicate that the metric library is open.
      // This is used in AppCues to show a tutorial if on the tutorial URL.
      if (isOpen) {
        if (metric.metricId != null) {
          params.set('visibleMetricId', metric.metricId);
        }

        history.replace({
          search: `?${params.toString()}`,
        });
      } else {
        params.delete('visibleMetricId');

        history.replace({
          search: `?${params.toString()}`,
        });
      }
    },
    [history, metric.metricId]
  );

  const onClickSeeMore = () => {
    setIsOpenWrapper(true);
  };

  const componentName = metric?.component ?? metricData?.component;

  const WrapperComponent = componentName != null ? widgetComponents[componentName] : null;

  const cardContentStyle = { ...styles.content, width: '100%', height: '100%' };
  const containerStyle = { ...styles.container };

  return (
    <div data-id={metric.metricId} style={{ display: 'flex', width: '100%', height: '100%=' }}>
      <div style={{ display: 'flex', flex: 1 }}>
        {isOpen && (
          <WidgetModal
            metric={metric}
            isOpen={isOpen}
            setIsOpen={setIsOpenWrapper}
            manualTimeFilter={{ startDate, endDate }}
            selectedFilters={{ ...selectedFilters, cohorts: formattedCohort }}
          />
        )}
        <Card
          baseStyle={{ display: 'flex', flex: 1 }}
          fluid
          dropshadow
          error={!!metricDataError}
          loading={metricDataLoading}
          hideHeaderDivider
          header={
            <WidgetHeader metric={metric} metricData={metricData} hideSeeMore={hideSeeMore} />
          }
          contentStyle={cardContentStyle}
          style={containerStyle}
        >
          {metricDataLoading && <SpinnerOrError loading={metricDataLoading} />}

          {metricDataError && (
            <SpinnerOrError error={!!metricDataError && 'An error occurred loading this metric'} />
          )}

          {WrapperComponent && (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                paddingTop: 4,
                justifyContent: 'center',
              }}
            >
              {metricData?.data?.dateRange && (
                <Text type="caption" color="caption">
                  {metricData?.data?.dateRange}
                </Text>
              )}
              <WrapperComponent metric={metric} metricData={metricData} />
              {metricData?.modal && !hideSeeMore && (
                <div
                  style={{ alignSelf: 'end', display: 'flex', gap: 5, backgroundColor: 'white' }}
                >
                  <Text type="subtitle" color="link" onClick={metricData?.modal && onClickSeeMore}>
                    See more
                  </Text>
                  <Icon
                    onClick={metricData?.modal && onClickSeeMore}
                    name="ArrowRight"
                    color="#2E62E7"
                    hoverColor="#2E62E7"
                  />
                </div>
              )}
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: 'white',
  },
  content: {
    ...layouts.container,
    ...layouts.centeredContentContainer,
    gap: 10,
  },
};
export default CohortWidget;

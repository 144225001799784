import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getCohorts } from '@api';
import { useQuery } from '@api/useQuery';
import { Button, ButtonGroup, Modal, SearchInput, SpinnerOrError, layout } from '@intus-ui';
import CohortCard from 'AdvancedCohort/CohortCard';

const AddEditCohortsModal = ({
  open,
  onClose,
  cohorts: existingCohorts,
  handleAddCohorts = () => {},
  defaultStartDate,
  defaultEndDate,
}) => {
  const modalTitle = existingCohorts ? 'Selected Cohorts' : 'Select Cohorts';
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCohorts, setSelectedCohorts] = useState([]);

  // Grab the cohortId from the query params
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const cohortId = queryParameters.get('cohortId');

  const handleOnChangeSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const {
    data = [],
    loading,
    error,
  } = useQuery(() => getCohorts({ query: {}, team: 'All', facility: 'All' }), {
    onSuccess: (allCohorts) => {
      const existingCohortIds = new Set(existingCohorts.map((c) => c.id));
      const existingSelectedCohorts = allCohorts.filter((c) => existingCohortIds.has(c.id));

      // Check if cohortId is in query params -- if so, add it to selectedCohorts
      if (cohortId) {
        const cohort = allCohorts.find((c) => c.id === Number(cohortId));
        if (!cohort) {
          return;
        }
        existingSelectedCohorts.push(cohort);
      }
      setSelectedCohorts(existingSelectedCohorts);
    },
  });

  // Iterate over existingCohorts and add them to the shared data array if there is no id match.
  // This ensures that both shared and personal cohorts are displayed in the modal if they are part of the initiative.
  const allCohorts = data ? [...data] : [];
  const dataCohortIds = data ? data.map((obj) => obj.id) : [];
  existingCohorts.forEach((cohort) => {
    if (!dataCohortIds.includes(cohort.id)) {
      allCohorts.push(cohort);
    }
  });

  const cohortsList = useMemo(() => {
    if (allCohorts == null) {
      return [];
    }

    if (searchTerm == null || searchTerm.trim().length === 0) {
      return allCohorts;
    }

    return allCohorts.filter((cohort) =>
      cohort.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );
  }, [allCohorts, searchTerm]);

  const isSelectedCohort = (cohort) => {
    return !!selectedCohorts.find((chrt) => chrt.id === cohort.id);
  };

  const selectCohort = (checked, cohort) => {
    if (checked) {
      const arr = [...selectedCohorts];
      arr.splice(selectedCohorts.indexOf(cohort), 1);
      setSelectedCohorts(arr);
    } else {
      setSelectedCohorts([...selectedCohorts, cohort]);
    }
  };

  /**
   * Function to handle adding cohorts to the initiative and closing the modal
   */
  const handleAddCohortsExt = () => {
    const extendSelectedCohorts = selectedCohorts.map((cohort) => {
      return {
        ...cohort,
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        isOnGoing: defaultEndDate == null,
      };
    });
    handleAddCohorts(extendSelectedCohorts);
    onClose();
  };

  return (
    <Modal
      header={{ title: modalTitle, centered: true }}
      open={open}
      onClose={onClose}
      type="large"
      modalStyles={styles.container}
    >
      {(loading || error) && (
        <SpinnerOrError error={error && 'Error occured: failed to get cohorts list'} />
      )}
      {!loading && (
        <div style={styles.content}>
          <SearchInput
            placeholder="Search existing cohorts"
            handleSearch={handleOnChangeSearch}
            searchTerm={searchTerm}
            containerStyle={{ width: '350px' }}
            id="initiatives-search-existing-cohorts"
          />
          <div style={styles.cardsContainer}>
            {cohortsList.map((cohort) => (
              <div
                key={cohort.id}
                style={styles.cardWrapper}
                onClick={() => selectCohort(isSelectedCohort(cohort), cohort)}
                role="none"
              >
                <div>
                  <input
                    type="checkbox"
                    aria-label={`${cohort.name} ${cohort.description}`}
                    checked={isSelectedCohort(cohort)}
                    onChange={() => selectCohort(isSelectedCohort(cohort), cohort)}
                    id={`initiatives-select-cohorts-checkbox-${cohort.id}`}
                  />
                </div>

                <CohortCard
                  cohort={cohort}
                  cohortType="shared"
                  config={{ flat: true, clickable: false, patientCounts: false }}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <ButtonGroup>
        <Button secondary onClick={onClose} id="initiatives-cancel-edit-cohorts-button">
          Cancel
        </Button>
        <Button
          onClick={handleAddCohortsExt}
          disabled={selectedCohorts.length === 0}
          id="initiatives-save-cohorts-button"
        >
          Update Cohorts
        </Button>
      </ButtonGroup>
    </Modal>
  );
};
const styles = {
  container: { ...layout.container },
  content: { ...layout.container, overflowY: 'auto', padding: '30px 10px 10px 10px', gap: 20 },
  cardsContainer: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gap: 30,
  },
  cardWrapper: {
    display: 'flex',
    gap: 10,
    width: '100%',
  },
};
export default AddEditCohortsModal;

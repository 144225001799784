/* eslint-disable prefer-arrow-callback */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import '@intus-ui/styles/Profile.scss';
import '@intus-ui/styles/Utilities.scss';
import { withRouter } from 'react-router-dom';
// import SettingsIcon from '@mui/icons-material/Settings';
import { setWelcomeFlowModal } from '@global-state/redux/welcomeSlice';
import userSettingsPropType from 'Settings/userSettingsPropType';
import { updateUserSettings } from '@global-state/redux/userSettingsSlice';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
// import EditIcon from '../assets/Icons/Edit';
import { ReactComponent as HomeIcon } from 'assets/Home.svg';
import ModalButtons from './ModalButtons';

const CongratulationsModal = ({
  welcomeFlowModal,
  doSetWelcomeFlowFinalModal,
  userSettings,
  doUpdateUserSettings,
}) => {
  const [profile] = useState(userSettings.profile);

  const { setting } = profile;

  const updatedSetting = {
    ...setting,
    welcomeFlowCompleted: true,
  };

  if (!welcomeFlowModal) {
    return null;
  }

  if (!profile) {
    return <SpinnerOrError error="Could not load the page. Please refresh and try again. " />;
  }
  return (
    <Modal
      dialogClassName="home-modal  min-w-75"
      show={!!welcomeFlowModal}
      onHide={() => {
        doUpdateUserSettings({ ...profile, settings: updatedSetting });
        doSetWelcomeFlowFinalModal(false);
      }}
      aria-labelledby="congratulations-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="pt-5 mt-3 pl-3 w-100 d-flex align-items-center justify-content-center">
          <h2 className="text-center text-primary mt-2">Congratulations!</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center align-items-center pb-4 pl-2">
          <h3 className="text-center d-flex flex-column justify-content-center align-content-center text-secondary font-weight-normal">
            You’ve finished setting up your
            <br />
            <span className="d-flex flex-row align-items-center justify-content-center font-weight-bold text-secondary">
              <span className="pr-2 py-2">
                <HomeIcon padding="p-2 mb-2" />
              </span>
              Home Page
            </span>
          </h3>
          {/* Commenting out until we add edit capability for user */}
          {/* <h3 className="text-center justify-content-center align-content-center text-secondary font-weight-normal pt-4">
            <span className="d-flex flex-row align-items-center justify-content-center font-weight-normal">
              To edit, click the Edit buttons
              <span className="pl-1 font-weight-bold text-primary">
                <EditIcon padding="p-0 m-0" />
              </span>
            </span>
            <span className="d-flex flex-row align-items-center justify-content-center font-weight-normal">
              or the Setting icon in the top bar
              <span className="pl-1 font-weight-bold text-primary">
                <SettingsIcon padding="p-0 m-0" />
              </span>
            </span>
          </h3> */}
        </div>
        <div className="pl-2">
          <ModalButtons
            onClick={() => {
              doUpdateUserSettings({ ...profile, settings: updatedSetting });
              doSetWelcomeFlowFinalModal(false);
            }}
            nextText="Continue"
            noBack
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

CongratulationsModal.propTypes = {
  welcomeFlowModal: PropTypes.bool.isRequired,
  doSetWelcomeFlowFinalModal: PropTypes.func.isRequired,
  doUpdateUserSettings: PropTypes.func.isRequired,
  userSettings: userSettingsPropType.isRequired,
};

const mapState = (state) => ({
  welcomeFlowModal: state.welcome.welcomeFlowModal,
  userSettings: state.userSettings,
});

const mapDispatch = (dispatch) => ({
  doSetWelcomeFlowFinalModal: (value) => dispatch(setWelcomeFlowModal(value)),
  doUpdateUserSettings: (body) => dispatch(updateUserSettings(body)),
});

export default withRouter(connect(mapState, mapDispatch)(CongratulationsModal));

import { useHistory } from 'react-router-dom';
import { Card, Icon, Text } from '@intus-ui';

const SmallCohortCard = ({ cohort, style, clickable, dateText }) => {
  const count = cohort.participantsCount ? cohort.participantsCount : cohort.count;
  const history = useHistory();
  const cohortType = cohort.shared ? 'shared' : 'personal';

  const handleCohortCardClick = () => {
    let search = '';
    if (cohort.fixed === true) {
      search += 'fixed=true';
    }
    history.push({
      pathname: `/cohorts/${cohortType}/${cohort.id}/participants`,
      search,
      state: { cohort: { ...cohort } },
    });
  };

  return (
    <Card
      dropshadow
      contentStyle={{ ...style, ...cardStyles.cohortCard, cursor: clickable && 'pointer' }}
      style={{ backgroundColor: 'white' }}
      onClick={clickable && handleCohortCardClick}
    >
      <Text type="subtitle" style={cardStyles.cohortText}>
        {cohort.name}
      </Text>
      <Text> {dateText}</Text>

      <div style={cardStyles.iconWithCount}>
        <Icon name="people" color="caption" />
        <Text color="caption">{count}</Text>
      </div>
    </Card>
  );
};

const cardStyles = {
  iconWithCount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '5px',
    width: '100%',
  },
  cohortCard: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxHeight: '108px',
    gap: 2,
  },
  cohortText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  },
};

export default SmallCohortCard;

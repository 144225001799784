import { useState } from 'react';
import { removeReactElementsFromObj } from '@util/utilFunctions';

import { groupBy } from 'lodash';

import { Icon, SpinnerOrError, Text, Modal, MedicationsList } from '@intus-ui';

import * as styles from './styles';

const TotalFalls = ({ totalNumberFalls, timeFrame }) => {
  return (
    <>
      <Text type="subtitle">General</Text>
      <div style={styles.generalAnalysisItems}>
        <Text type="display" style={{ paddingRight: '10px' }}>
          {totalNumberFalls}
        </Text>
        <Text type="title">
          total falls <br /> {timeFrame}
        </Text>
      </div>
    </>
  );
};

const TrendsAnalysisItems = ({ sortedData, totalNumberFalls, insights }) => {
  if (sortedData?.length === 0) {
    return (
      <>
        <Text type="subtitle">Trends</Text>
        <div style={styles.analyticsItem}>
          <Text color="caption">No trends for the given time period.</Text>
        </div>
      </>
    );
  }

  return (
    <>
      <Text type="subtitle">Trends</Text>
      {sortedData?.map((item) => {
        const itemValue = item?.value;
        const itemProperty = item?.property;
        const itemId = item?.id;

        return (
          <div key={JSON.stringify(removeReactElementsFromObj(item))} style={styles.analyticsItem}>
            <Text type="headline">{Math.round((itemValue / totalNumberFalls) * 100)}%</Text>
            <Text>
              {itemProperty !== undefined ? `of falls ${insights[itemProperty]} ` : ''}
              <Text wrapper="span" type="subtitle">
                {itemId}
              </Text>
            </Text>
          </div>
        );
      })}
    </>
  );
};

const RiskFactorsMessage = () => {
  const link =
    'https://www.ahrq.gov/patient-safety/settings/long-term-care/resource/ontime/fallspx/implmatls.html';
  return (
    <Text type="caption" color="caption">
      Risk Factors are based on&nbsp;
      <a href={link} target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
        Agency for Healthcare Research and Quality (AHRQ) Fall Prevention and Assessment
      </a>
    </Text>
  );
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const FallRiskMedications = ({ currentMedications, fallsRiskMedications }) => {
  const [medicationModalIsOpen, setMedicationModalIsOpen] = useState(false);
  return (
    <>
      <Modal
        header={{
          title: `Current Medications`,
          centered: true,
        }}
        type="large"
        open={medicationModalIsOpen}
        onClose={() => setMedicationModalIsOpen(false)}
      >
        <MedicationsList data={currentMedications} searchable />
      </Modal>
      <Text type="subtitle">Potential fall risk medications</Text>
      {fallsRiskMedications.map((obj) => {
        return (
          <div
            key={`${obj.name}-${obj.startDate}`}
            style={{
              display: 'flex',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            <Icon name="bullet" />
            <Text>
              {obj.name} ({obj.fallsRiskFactors.toString()})
            </Text>
          </div>
        );
      })}
      <Text
        style={{ textAlign: 'right', width: '100%', paddingTop: 10 }}
        onClick={() => setMedicationModalIsOpen(true)}
        type="subtitle"
        color="link"
      >
        All Current Medications
        <Icon name="ArrowRight" color="link" size="small" />
      </Text>
    </>
  );
};

const RiskFactors = ({ codes }) => {
  const labelsWithTitles = codes?.map((code) => {
    return { title: code.fallsRiskFactors?.[0], description: code.description?.trim() };
  });
  const groupedByTitles = groupBy(labelsWithTitles, 'title');

  if (codes === undefined) {
    return <SpinnerOrError />;
  }

  if (codes?.length === 0) {
    return (
      <>
        <Text type="subtitle">Risk Factors</Text>
        <div style={styles.riskFactorItem}>
          <Text color="caption">
            This participant does not currently have risk factors related to falls.
          </Text>
        </div>
        <RiskFactorsMessage />
      </>
    );
  }

  return (
    <>
      <Text type="subtitle">Risk Factors</Text>
      {/* 
      TODO: Add this back in once we have reliable falls risk factors mapped to medications
      {medicationsIsActive && fallsRiskMedications.length > 0 && (
        <div style={styles.riskFactorItem}>
          <FallRiskMedications
            currentMedications={currentMedications}
            fallsRiskMedications={fallsRiskMedications}
          />
        </div>
      )} */}
      {Object.entries(groupedByTitles)?.map(([k, v]) => {
        return (
          <div key={`${k}-${v}`} style={styles.riskFactorItem}>
            <Text type="subtitle">{k}</Text>
            {v.map((item) => {
              return (
                item.title !== item.description && (
                  <div
                    style={{
                      display: 'flex',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                    }}
                    key={JSON.stringify(item)}
                  >
                    <Icon name="bullet" />
                    <Text>{item.description}</Text>
                  </div>
                )
              );
            })}
          </div>
        );
      })}
      <RiskFactorsMessage />
    </>
  );
};

const AnalyticsTab = ({ fallData, sortedData, timeFrame, codes, currentMedications }) => {
  const totalNumberFalls = fallData?.length;
  const insights = {
    location: 'happened in',
    activity: 'happened while',
    time: 'happened between',
    severity: 'resulted in',
  };

  const fallsRiskMedications = currentMedications?.filter((obj) => obj.fallsRiskFactors.length > 0);

  return (
    <div style={styles.sidebarContentContainer}>
      <TotalFalls totalNumberFalls={totalNumberFalls} timeFrame={timeFrame} />
      <TrendsAnalysisItems
        sortedData={sortedData}
        totalNumberFalls={totalNumberFalls}
        insights={insights}
      />
      <RiskFactors
        codes={codes}
        currentMedications={currentMedications}
        fallsRiskMedications={fallsRiskMedications}
      />
    </div>
  );
};

export default AnalyticsTab;

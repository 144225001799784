import { Icon, Text } from '@intus-ui';
import { formatDate } from '@util/dateFunctions';
import React from 'react';

export const AdmitCard = ({ admit }: any) => {
  const {
    type,
    admitDate,
    dischargeDate,
    principalDiagnoses,
    admitFacility,
    dischargeDisposition,
    name,
  } = admit;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        cursor: 'pointer',
        padding: '10px 15px',
        borderRadius: 5,
        border: '0.5px solid black',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5, flex: 1, width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text type="subtitle">{type}</Text>
        </div>
        <div style={styles.listItem}>
          <Icon name="People" style={styles.listIcon} />
          <Text
            style={{
              marginLeft: 10,
              lineHeight: '19.5px',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {name}
          </Text>
        </div>
        <div style={styles.listItem}>
          <Icon name="Calendar" style={styles.listIcon} />
          <Text
            style={{
              marginLeft: 10,
              lineHeight: '19.5px',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {formatDate(admitDate, 'MM/DD/YYYY')}
            {dischargeDate && ` - ${formatDate(dischargeDate, 'MM/DD/YYYY')}`}
          </Text>
        </div>

        <div style={styles.listItem}>
          <Icon name="Diagnosis" style={styles.listIcon} />
          {principalDiagnoses.map((dx: string) => (
            <Text
              style={{
                marginLeft: 10,
                lineHeight: '19.5px',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
              }}
            >
              {dx}
            </Text>
          ))}
        </div>

        {admitFacility && (
          <div style={styles.listItem}>
            <Icon name="Admit" style={styles.listIcon} />
            <Text
              style={{
                marginLeft: 10,
                lineHeight: '19.5px',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
              }}
            >
              {admitFacility}
            </Text>
          </div>
        )}

        <div style={styles.listItem}>
          <Icon name="Discharge" style={styles.listIcon} />
          <Text
            style={{
              marginLeft: 10,
              lineHeight: '19.5px',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
          >
            {dischargeDisposition}
          </Text>
        </div>
      </div>
    </div>
  );
};

const styles = {
  acuityCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    cursor: 'pointer',
    padding: '10px 15px',
    borderRadius: 5,
    border: '0.5px solid black',
  },
  listItem: {
    display: 'flex',
    alignItems: 'start',
  },
  listIcon: {
    color: '#97999b',
    width: 22,
    height: 22,
  },
};

import { useEffect, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';
import { Button, ButtonGroup } from '@intus-ui';

const timeout = 1000 * 60 * 1; // expiry after popup if no activity (ms * sec * min)

const SessionTimeoutPopup = ({ showModal, handleClose, logout, expired }) => {
  const timeoutRef = useRef();

  useEffect(() => {
    if (showModal && timeoutRef.current) timeoutRef.current.reset();
    else if (!showModal && timeoutRef.current) timeoutRef.current.pause();
  }, [showModal]);

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>You have been idle!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your session will expire soon and you will be logged out.
          <ButtonGroup style={{ marginTop: 30 }}>
            <Button secondary onClick={() => logout()} style={{ margin: 'initial' }}>
              Logout
            </Button>
            <Button onClick={() => handleClose()} style={{ margin: 'initial' }}>
              Continue Session
            </Button>
          </ButtonGroup>
        </Modal.Body>
      </Modal>

      <IdleTimer
        ref={timeoutRef}
        onIdle={() => expired()}
        timeout={timeout}
        startOnMount={false}
        events={[]}
      />
    </>
  );
};

SessionTimeoutPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  expired: PropTypes.func.isRequired,
};

export default SessionTimeoutPopup;

import { useState, useRef, useEffect } from 'react';

import { toSentenceCase } from '@util/utilFunctions';

import { Icon, Text } from '@intus-ui';
import { textColors, input, quadBackgroundColors } from '@intus-ui/styles/SecondaryColors';
import { useOutsideClick, useHover } from '@intus-ui/hooks';
import DropdownList from '@intus-ui/components/Dropdown/DropdownList';
import DropdownItem from '@intus-ui/components/Dropdown/DropdownItem';
import { PopperPortal } from '@intus-ui/components/Popper/PortalPopper';

const SingleSelect = ({
  id,
  type, //  text || risk badge
  rightIcon,
  name,
  options,
  rightAligned,
  defaultValue,
  disabled,
  onClickItem,
  renderListItem,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedVal, setSelectedVal] = useState(defaultValue || options[0]);
  const [hoverRef, isHovered] = useHover();
  const dropDownRef = useRef(null);

  // Closes dropdown when clicked outside
  useOutsideClick(dropDownRef, () => {
    setIsCollapsed(false);
  });

  const handleTriggerDropdown = () => {
    if (disabled) return;
    setIsCollapsed(!isCollapsed);
  };
  useEffect(() => {}, [isCollapsed]);
  let bgColor = input.default;
  let bordColor = input.default;
  let txtColor = 'link';
  if (isHovered) {
    bgColor = input.hover;
    bordColor = input.active;
    txtColor = 'link';
  } else if (isCollapsed) {
    // equivalent to active state
    bgColor = input.default;
    bordColor = input.active;
    txtColor = 'link';
  } else if (disabled) {
    bgColor = input.disabled;
    bordColor = textColors.caption;
    txtColor = 'caption';
  } else {
    // default state
    bordColor = quadBackgroundColors.secondary;
    bgColor = input.default;
    txtColor = 'link';
  }

  const styles = {
    dropdownWrapper: {
      width: 'max-content',
      inlineSize: 'max-content',
      blockSize: 'max-content',
      whiteSpace: 'nowrap',
      position: 'relative',
    },
    select: {
      borderRadius: 13,
      border: '2px solid',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      height: '22px',
    },
    dropdown: {
      marginTop: 1,
      right: rightAligned ? 0 : 'initial',
      left: rightAligned ? 'initial' : 0,
      width: 'max-content',
      top: '100%',
    },
    selectBody: {
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <div id={id || name} name={name} ref={dropDownRef} style={styles.dropdownWrapper}>
      <div
        ref={hoverRef}
        style={{
          ...styles.select,
          borderColor: bordColor,
          outline: isCollapsed ? '2px solid' : '0px',
          outlineColor: isCollapsed ? quadBackgroundColors.secondary : 'none',
          backgroundColor: bgColor,
          padding: rightIcon ? '3px 0px 3px 7px' : '3px 7px',
        }}
        onClick={handleTriggerDropdown}
        onKeyDown={handleTriggerDropdown}
        role="none"
        disabled={disabled}
      >
        <div style={styles.selectBody}>
          <Text type="caption" color={txtColor}>
            {selectedVal}
          </Text>
        </div>
        {/* TODO: For some reason this breaks its clickability, nice to have that the arrow changes direction
        <Icon name={isCollapsed ? 'CaretUp' : 'CaretDown'} size="tiny" color={input.active} /> */}
        <Icon name="caret-down" size="tiny" color={input.active} />
      </div>

      {isCollapsed && (
        <PopperPortal anchorRef={dropDownRef}>
          <DropdownList styles={styles.dropdown}>
            {options.length === 0 ? (
              <DropdownItem empty hasSuggestion={false} />
            ) : (
              options.map((option, i) => {
                const key = i;
                return (
                  <DropdownItem
                    key={key}
                    index={i}
                    option={option}
                    renderListItem={renderListItem}
                    onSelect={(val) => {
                      if (type === 'badge') {
                        onClickItem(val);
                        setSelectedVal(toSentenceCase(val.props.riskGroup.toLowerCase()));
                      } else {
                        onClickItem(val);
                        setSelectedVal(val);
                      }
                    }}
                    setIsCollapsed={setIsCollapsed}
                  />
                );
              })
            )}
          </DropdownList>
        </PopperPortal>
      )}
    </div>
  );
};

export default SingleSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import Centered from '@intus-ui/components/Centered';
import { getOrganizations } from 'Admin/UserTracking/AdminSlice';
import OrganizationListPropType from 'Admin/admin/OrganizationListPropType';
import UserTrackingList from './UserTrackingList';

class UserTrack extends React.Component {
  componentDidMount() {
    const { doGetOrgs } = this.props;
    doGetOrgs();
  }

  render() {
    const { orgs } = this.props;
    let content;

    if (!orgs) {
      // If organizations array is undefined, shows a loading spinner.
      content = <CenteredSpinner className="d-flex flex-grow-1" />;
    } else if (!orgs.length) {
      content = (
        <Centered className="d-flex flex-grow-1">
          <p>There are no organizations.</p>
        </Centered>
      );
    } else {
      content = <UserTrackingList />;
    }
    return (
      <div className="w-100 h-100 d-flex flex-column bg-light justify-content-start align-items-center overflow-hidden">
        <div className="d-flex w-100 justify-content-between align-items-center container-side-padding-sm mt-3">
          <div style={{ margin: '0 15px' }} className="d-flex flex-row align-items-center">
            <h2 style={{ lineHeight: 'normal' }} className="font-weight-bold text-secondary">
              User Tracking
            </h2>
          </div>
        </div>
        <div className="w-100 d-flex flex-grow-1 overflow-hidden">{content}</div>
      </div>
    );
  }
}

UserTrack.propTypes = {
  doGetOrgs: PropTypes.func.isRequired,
  orgs: PropTypes.arrayOf(OrganizationListPropType.isRequired),
};

UserTrack.defaultProps = {
  orgs: undefined,
};

const mapState = (state) => ({
  orgs: state.admin.orgs,
});

const mapDispatch = (dispatch) => ({
  doGetOrgs: () => dispatch(getOrganizations()),
});

export default connect(mapState, mapDispatch)(UserTrack);

import { getStringReplacement } from '@util/stringReplacements';
import { startCase } from 'lodash';

export const filterRowSchemas = (type) => {
  const filterConfigurations = {
    incidents: {
      // "components" represents a list of components that will be
      // rendered when a filterOption that matches this
      // object's key is selected
      components: {
        FilterInputSelect: { placeholder: 'Select Incident Type' },
        FilterTimeRange: {
          defaults: { frequency: { gte: 1 } },
        },
      },
      // "format" represents the format of the data that will be
      // sent to the server (see dataFormat in useFilter)
      // when a filterOption that matches this
      // object's key is selected.
    },
    hospitalizations: {
      components: {
        FilterInputSelect: { placeholder: 'Select Hospitalization Type' },
        FilterTimeRange: {
          defaults: { frequency: { gte: 1 } },
        },
      },
    },
    diagnosis: {
      logicOptions: [
        { label: 'include', value: '=' },
        { label: 'do not include', value: '!=' },
      ],
      components: {
        FilterSearchDropdown: {
          placeholder: 'Search Diagnoses',
        },
      },
    },
    medicationClasses: {
      logicOptions: [
        { label: 'include', value: '=' },
        { label: 'do not include', value: '!=' },
      ],
      components: {
        MedicationClassDropdown: {
          placeholder: 'Medication Classes',
          allMedClasses: [],
        },
      },
    },
    diagnosisCCSR: {
      logicOptions: [
        { label: 'include', value: '=' },
        { label: 'do not include', value: '!=' },
      ],
      components: {
        FilterSearchDropdown: {
          placeholder: 'Search Diagnoses',
        },
      },
    },
    team: {
      logicOptions: [
        { label: 'is', value: '=' },
        { label: 'is not', value: '!=' },
      ],
      components: {
        FilterInputSelect: { placeholder: `Select ${getStringReplacement('Team')}` },
      },
    },
    facility: {
      logicOptions: [
        { label: 'is', value: '=' },
        { label: 'is not', value: '!=' },
      ],
      components: {
        FilterInputSelect: { placeholder: `Select ${getStringReplacement('Center')}` },
      },
    },
    age: {
      components: {
        FilterBetweenRange: {
          inputLabel: 'years old',
          defaults: { frequency: { gte: 45 }, betweenLte: 65 },
        },
      },
    },
    acuityScoreGroup: {
      components: {
        FilterInputSelect: { placeholder: 'Select Score Group' },
      },
    },
    enrollmentDuration: {
      components: {
        FilterBetweenSelectRange: {},
      },
    },
    livingSituation: {
      logicOptions: [
        { label: 'is', value: '=' },
        { label: 'is not', value: '!=' },
      ],
      components: {
        FilterInputSelect: { placeholder: 'Select Living Situation' },
      },
    },
    gender: {
      components: {
        FilterInputSelect: { placeholder: 'Select Gender' },
      },
    },
    medicationCount: {
      components: {
        FilterBetweenRange: {
          inputLabel: 'medications',
          defaults: { frequency: { gte: 1 }, betweenLte: 2 },
        },
      },
    },
    medicationName: {
      logicOptions: [
        { label: 'equal', value: '=' },
        { label: 'include', value: 'contains' },
        { label: 'do not include', value: 'notContains' },
        { label: 'start with', value: 'startsWith' },
      ],
      components: {
        FilterInputText: { placeholder: 'Medication Name' },
      },
    },

    document: {
      components: {
        FilterInputSelectWithOptions: {
          placeholder: 'Select documentation',
          logicOptionsPlaceholder: 'Select document status',
          logicOptions: [
            { label: 'completed', value: 'completed' },
            { label: 'never completed', value: 'neverCompleted' },
            { label: 'completed in the past...', value: 'completedInThePast' },
            { label: 'not completed in the past...', value: 'notCompletedInThePast' },
          ],
        },
      },
    },
  };
  if (type && type.includes('patientGroup')) {
    filterConfigurations[type] = {
      type,
      logicOptions: [
        { label: 'is', value: '=' },
        { label: 'is not', value: '!=' },
      ],
      components: {
        FilterInputSelect: { placeholder: `Select ${startCase(type)}` },
      },
    };
  }

  return filterConfigurations;
};

import { useHistory } from 'react-router-dom';

import { Text, layout, Icon } from '@intus-ui';
import { cohortChangesColors, textColors } from '@intus-ui/styles/SecondaryColors';
import Pills from 'AdvancedCohort/Pills';
import { subtractDate, newDate, isAfterDate } from '@util/dateFunctions';
import { useEffect } from 'react';

const defaultConfig = {
  clickable: true,
  flat: false,
  patientCounts: true,
  cursor: 'pointer',
};

const CohortCard = ({ cohort, cohortType, config, style }) => {
  const customConfig = config ? { ...defaultConfig, ...config } : defaultConfig;
  const history = useHistory();
  const { id, searchCriteria, fixed } = cohort;
  const filter = { ...searchCriteria };

  const { isHighlighted } = useHighlightCohortCard(cohort);

  const handleCohortCardClick = () => {
    if (customConfig.clickable) {
      let search = '';
      if (fixed === true) {
        search += 'fixed=true';
      }
      history.push({
        pathname: `/cohorts/${cohortType}/${id}/participants`,
        search,
        state: { cohort: { ...cohort, filter } },
      });
    }
  };

  // The cohort is "new" if it was created less than 7 days ago.
  // We display a "new" tag on the cohort card in that case.
  let isNewCohort = false;
  if (cohort.createdAt != null) {
    const sevenDaysAgo = subtractDate(newDate(), 7, 'days');
    isNewCohort = isAfterDate(newDate(cohort.createdAt), sevenDaysAgo);
  }

  let border = customConfig.flat ? '1px solid #9D9D9D' : 'none';

  if (isHighlighted) {
    border = '2px solid #2E62E7';
  }

  let boxShadow = customConfig.flat ? 'none' : '0px 0.4px 0.4rem 0.06rem rgba(0, 0, 0, 0.2)';
  if (isHighlighted) {
    boxShadow = 'none';
  }

  // Only render the patient counts if we get the data from the backend.
  // We use this on some pages (/initiatives) to avoid showing the up and down arrows
  // for the patient changes.
  const hasPatientCounts = customConfig.patientCounts && cohort?.addedPatientIds != null;

  const patientsAdded = cohort?.addedPatientIds?.length || 0;
  const patientsRemoved = cohort?.removedPatientIds?.length || 0;

  return (
    <div
      id={`cohortCard-${id}`}
      style={{
        ...styles.cohortCardOuter,
        boxShadow,
        border,
        borderRadius: customConfig.flat ? 10 : 20,
        cursor: customConfig.cursor,
        ...style,
      }}
      onClick={handleCohortCardClick}
      onKeyDown={handleCohortCardClick}
      role="none"
    >
      <div style={styles.cohortCardContent}>
        <div style={styles.nameAndTagContainer}>
          <Text type="title" color="body" style={{ fontWeight: 700 }}>
            {cohort?.name}
          </Text>
          {isNewCohort && (
            <div style={styles.tag}>
              <Text
                color="white"
                type="caption-bold"
                style={{ fontWeight: 'bold', lineHeight: '1em' }}
              >
                NEW
              </Text>
            </div>
          )}
        </div>
        {!fixed && <Pills filter={filter} keyPrefix={`pills-for-cohort-card-${id}`} summary />}
        <Text type="body" style={styles.cohortDescription} color="caption">
          {cohort?.description}
        </Text>
      </div>
      <div style={styles.cohortCardFooter}>
        <div style={styles.cohortMemberTotal}>
          <Icon name="People" color="#9D9D9D" />
          <Text type="body" color="caption">
            {cohort?.count}
          </Text>
        </div>
        {hasPatientCounts && (
          <div style={styles.cohortCardChanges}>
            <div style={styles.numberWithArrow}>
              <Text type="subtitle" color={patientsAdded > 0 ? 'countRed' : 'countDisabled'}>
                {patientsAdded}
              </Text>
              <Icon
                name="IncreaseArrow"
                color={patientsAdded > 0 ? cohortChangesColors.red : cohortChangesColors.disabled}
                hoverColor={
                  patientsAdded > 0 ? cohortChangesColors.red : cohortChangesColors.disabled
                }
                style={styles.arrowIcon}
              />
            </div>
            <div style={styles.numberWithArrow}>
              <Text type="subtitle" color={patientsRemoved > 0 ? 'countBlue' : 'countDisabled'}>
                {patientsRemoved}
              </Text>
              <Icon
                name="DecreaseArrow"
                color={
                  patientsRemoved > 0 ? cohortChangesColors.blue : cohortChangesColors.disabled
                }
                hoverColor={
                  patientsRemoved > 0 ? cohortChangesColors.blue : cohortChangesColors.disabled
                }
                style={styles.arrowIcon}
              />
            </div>
            <Text type="body" color="caption">
              past week
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

function useHighlightCohortCard(cohort) {
  const history = useHistory();
  const { id } = cohort;

  // Set to true if the user just made this cohort and we want to highlight and scroll it into view.
  // We need the `?.` here to avoid errors in storybook.
  const isHighlighted = history?.location?.state?.highlightCohortId === id;
  const scrollIntoView = history?.location?.state?.scrollIntoView;
  const newCohort = history?.location?.state;

  useEffect(() => {
    if (isHighlighted && scrollIntoView) {
      // Find our card by id and scroll it into view.
      const cohortCard = document.getElementById(`cohortCard-${id}`);
      cohortCard?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Remove the scrollIntoView state so we don't scroll again.
      history.replace({ state: { ...newCohort, scrollIntoView: false } });
    }
  }, []);

  return {
    isHighlighted,
  };
}

const styles = {
  cohortCardOuter: {
    ...layout.container,
    alignItems: 'start',
    overflowY: 'hidden',
    height: 280,
    padding: '10px 20px 20px 20px',
    backgroundColor: 'white',
    gap: 20,
  },
  cohortCardContent: {
    ...layout.container,
    flexGrow: 1,
    gap: 10,
  },
  cohortCardFooter: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '100%',
  },
  cohortCardChanges: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    gap: 3,
    overflowY: 'auto',
  },
  nameAndTagContainer: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    paddingTop: 5,
  },
  tag: {
    backgroundColor: textColors.link,
    color: '#FFFFFF',
    padding: '5px 8px',
    borderRadius: 3,
    lineHeight: 0,
  },
  cohortDescription: {
    flexGrow: 4,
  },
  cohortMemberTotal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    overflowY: 'auto',
  },
  numberWithArrow: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
  arrowIcon: {
    width: 14,
    height: 14,
  },
};

export default CohortCard;

import React, { useState } from 'react';

import { capitalizeFirst } from '@util/utilFunctions';
import { RadioButtonV2 } from '@intus-ui/components/index';
import { useListContext } from '@intus-ui/components/List/Context';

const EnrollFilter = ({ columnConfig, setColumnConfig }) => {
  const [selectedOption, setSelectedOption] = useState('Enrolled');
  const filterOptions = ['enrolled', 'disenrolled'];
  const { resetFilters, addFilter } = useListContext();

  // Setting selected option, the columnConfig, and filtering the data based on filters
  const handleSelected = (val) => {
    resetFilters();
    addFilter({ type: 'livingSituation', value: 'All' });
    setSelectedOption(val);
    if (val === 'Enrolled') {
      setColumnConfig(columnConfig.default);
      addFilter({
        type: 'enroll',
        value: (item) => {
          return item?.disenrollDate === 'Invalid Date';
        },
      });
    }
    if (val === 'Disenrolled') {
      if (columnConfig.disenrolled) setColumnConfig(columnConfig.disenrolled);
      addFilter({
        type: 'enroll',
        value: (item) => {
          return item?.disenrollDate !== 'Invalid Date';
        },
      });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
      {filterOptions.map((option) => {
        return (
          <RadioButtonV2
            key={`${JSON.stringify(option)}-${selectedOption}`}
            option={capitalizeFirst(option)}
            size="small"
            labelPosition="horizontal"
            selected={capitalizeFirst(selectedOption)}
            setSelected={(val) => handleSelected(val)}
          />
        );
      })}
    </div>
  );
};

export default EnrollFilter;

import { useHistory, useLocation } from 'react-router-dom';

import { stringToClassname } from '@util/utilFunctions';

import { TabButton } from '@intus-ui';

const TabMenu = () => {
  const history = useHistory();
  const location = useLocation();

  const pages = [
    { name: 'First Fills', url: '/polypharmacy/first-fills' },
    { name: 'Adherence', url: '/polypharmacy/adherence' },
  ];

  return (
    <div style={styles.tabContainer}>
      <div id="tabMenu" style={styles.menu}>
        {pages.map((p, index) => {
          return (
            <span
              key={p.name}
              style={styles.menuItem}
              id={`ppt-focus-${stringToClassname(p.name)}`}
            >
              {index !== 0 ? <span className="text-muted px-3">|</span> : null}
              <TabButton
                name={p.name}
                active={location.pathname.includes(p.url)}
                hoverText={p.name}
                onClick={() => {
                  history.replace(p.url);
                }}
              />
            </span>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  tabContainer: {
    backgroundColor: 'white',
    width: '100%',
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 10,
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default TabMenu;

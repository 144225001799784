import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SubmitButton = ({
  isSubmitting,
  formNotComplete,
  text,
  className,
  defaultButton,
  hideTextOnSubmit,
}) => {
  if (defaultButton) {
    return (
      <button className={className} type="submit" disabled={formNotComplete || isSubmitting}>
        {isSubmitting ? (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            className="m-1"
          />
        ) : null}
        {hideTextOnSubmit && isSubmitting ? null : text}
      </button>
    );
  }
  return (
    <Button className={className} type="submit" disabled={formNotComplete || isSubmitting}>
      {isSubmitting ? (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className="m-1"
        />
      ) : null}
      {hideTextOnSubmit && isSubmitting ? null : text}
    </Button>
  );
};

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  formNotComplete: PropTypes.bool.isRequired,
  text: PropTypes.node.isRequired,
  className: PropTypes.string,
  defaultButton: PropTypes.bool,
  hideTextOnSubmit: PropTypes.bool,
};

SubmitButton.defaultProps = {
  className: 'w-100',
  defaultButton: false,
  hideTextOnSubmit: false,
};

export default SubmitButton;

import { useEffect } from 'react';
import { chain } from 'lodash';

import { formatDate } from '@util/dateFunctions';

import { Text, RiskBadge, SpinnerOrError } from '@intus-ui';
import EventCard from './EventCard';

import { useRiskTrendlineContext } from '../Context';

const EventGroup = ({ eventGroup }) => {
  const { acuityData } = useRiskTrendlineContext();
  const acuity = acuityData.find((o) => o.date === eventGroup[0].date) || eventGroup[0];
  const { acuityScore, date, riskGroup } = acuity;

  return (
    <div
      data-scroll-target
      key={`${JSON.stringify(eventGroup)}`}
      data-group-date={formatDate(date, 'YYYY-MM-DD')}
    >
      <div style={styles.groupHeaderContainer}>
        <Text color="black">{formatDate(date, 'MM/DD/YYYY')}</Text>
        <hr style={styles.hr} />
        <RiskBadge riskGroup={riskGroup} smallText />
        <Text style={{ paddingLeft: 12 }}>{acuityScore}</Text>
      </div>
      <div style={styles.eventsContainer}>
        {eventGroup.map((event) => {
          return <EventCard event={event} key={JSON.stringify(event)} />;
        })}
      </div>
    </div>
  );
};

const EventGroups = () => {
  const { sidebarData, selectedPoint, ready } = useRiskTrendlineContext();

  useEffect(() => {
    if (selectedPoint) {
      const targetElement = document.querySelector(
        `[data-scroll-target="true"][data-group-date="${formatDate(
          selectedPoint.date,
          'YYYY-MM-DD'
        )}"]`
      );

      if (targetElement)
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, [selectedPoint]);

  if (!ready) return <SpinnerOrError />;

  if (!sidebarData.length)
    return <Text>No events for this participant with the selected filters.</Text>;

  const eventGroups = chain(sidebarData)
    .groupBy('date')
    .values()
    .orderBy((a) => new Date(a[0].date), 'desc')
    .value();

  return (
    <>
      {eventGroups.map((eventGroup) => {
        return <EventGroup key={JSON.stringify(eventGroup)} eventGroup={eventGroup} />;
      })}
    </>
  );
};

const styles = {
  groupHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'between',
    alignItems: 'center',
    marginBottom: 10,
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  hr: {
    height: 0,
    width: '100%',
    margin: '0 15px',
    backgroundColor: '#C8C8C8',
  },
};

export default EventGroups;

import { List, SpinnerOrError } from '@intus-ui';
import { format } from '@intus-ui/components/MedicationsList/list/format';
import { getMedicationsTabColumnConfig } from '@intus-ui/components/MedicationsList/list/columnConfigs';
import { useMemo } from 'react';

const MedicationsModalList = ({ medicationsData, loading, error }) => {
  const listFormat = format();

  const filteredMedications = useMemo(() => {
    return medicationsData?.filter((item) => item.source === 'CLAIM') ?? [];
  }, [medicationsData]);

  if (error) {
    return <SpinnerOrError error={`An error ocurred loading the patient's medications`} />;
  }

  if (loading || !medicationsData) {
    return <SpinnerOrError />;
  }

  const patientId = medicationsData[0]?.patientId;

  const includePDCColumn = filteredMedications.some((item) => item.pdc != null);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        gap: '5px',
        paddingTop: '10px',
        height: '100%',
      }}
    >
      <List
        noPadding
        data={filteredMedications}
        format={listFormat}
        columnConfig={getMedicationsTabColumnConfig(includePDCColumn).claimsConfig}
        searchable
        listStyle="striped"
      />
      <div style={{ textAlign: 'right' }}>
        <a
          href={`/patient/${patientId}/medications`}
          target="_blank"
          rel="noreferrer"
          style={{
            display: 'inline-block',
            paddingTop: 10,
            fontWeight: 'bold',
            fontSize: 16,
          }}
        >
          See full medication history for patient
        </a>
      </div>
    </div>
  );
};

export default MedicationsModalList;

import { formatDate } from '@util/dateFunctions';
import * as XLSX from 'xlsx';

const downloadAdmitsExcelData = (
  currentlyAdmitted: any[],
  discharged: any[],
  admitsConfig: any = {}
) => {
  const combinedData = [...currentlyAdmitted, ...discharged];

  const mappedCombinedData = combinedData
    .map((item) => {
      // Safely combine principalDx descriptions into a single string
      const principalDxDescription =
        item.principalDx && item.principalDx.length > 0
          ? item.principalDx
              .map((dx: { code: string; description: string }) => dx.description)
              .join(', ')
          : '';

      return {
        'Participant Name': `${item.patient.firstName} ${item.patient.lastName} (${item.patient.memberId})`,
        Admitted: formatDate(item.admitDate, 'MM/DD/YYYY'),
        Discharged: item.dischargeDate ? formatDate(item.dischargeDate, 'MM/DD/YYYY') : '',
        LOS: item.los,
        'Admit Type': item.admitType,
        Location: item.admitFacility,
        'Principal Diagnosis': principalDxDescription,
        'Discharge Disposition': item.dischargeDisposition,
      };
    })
    .sort((a, b) => {
      // sort by discharge date, nulls first
      if (!a.Discharged && b.Discharged) return -1;
      if (a.Discharged && !b.Discharged) return 1;
      if (!a.Discharged && !b.Discharged) return 0;
      return new Date(b.Discharged).getTime() - new Date(a.Discharged).getTime();
    });

  // Prepare configuration information
  const configData = [
    [`Admit Type: ${admitsConfig.admitsType || 'N/A'}`],
    [
      `Center Filter: ${
        admitsConfig.selectedFilters?.facilities?.length > 0
          ? admitsConfig.selectedFilters.facilities.join(', ')
          : 'All'
      }`,
    ],
    [
      `Team Filter: ${
        admitsConfig.selectedFilters?.teams?.length > 0
          ? admitsConfig.selectedFilters.teams.join(', ')
          : 'All'
      }`,
    ],
    [
      `Cohorts Filter: ${
        admitsConfig.selectedFilters?.cohorts?.length > 0
          ? admitsConfig.selectedFilters.cohorts.map((c: any) => c.name).join(', ')
          : 'All'
      }`,
    ],
    [
      `Time Range: ${formatDate(admitsConfig.startDate, 'MM/DD/YY')} - ${formatDate(
        admitsConfig.endDate,
        'MM/DD/YY'
      )}`,
    ],
    [], // Empty row for spacing
  ];

  // Prepare the worksheet data with headers
  const worksheetData = [];

  if (mappedCombinedData.length > 0) {
    const headers = Object.keys(mappedCombinedData[0]);
    worksheetData.push(headers); // Add headers
    mappedCombinedData.forEach((item) => {
      worksheetData.push(Object.values(item)); // Add row data
    });
  }

  // Combine config data and worksheet data
  const finalWorksheetData = [...configData, ...worksheetData];

  // Create a worksheet and add the data
  const worksheet = XLSX.utils.aoa_to_sheet(finalWorksheetData);

  // Set minimum column widths
  const columnWidths = [
    { wpx: 150 }, // 'Participant Name'
    { wpx: 100 }, // 'Admitted'
    { wpx: 100 }, // 'Discharged'
    { wpx: 50 }, // 'LOS'
    { wpx: 100 }, // 'Admit Type'
    { wpx: 180 }, // 'Location'
    { wpx: 300 }, // 'Principal Diagnosis'
    { wpx: 180 }, // 'Discharge Disposition'
  ];
  worksheet['!cols'] = columnWidths;

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Admits Data');

  // Write the workbook to a file
  XLSX.writeFile(workbook, 'AdmitsData.xlsx');
};

export default downloadAdmitsExcelData;

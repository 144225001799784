import { SortIconNoNeutral } from '../../List/SortIcon';
import TimeFilter from '../filters/TimeFilter';

/**
 * A list of all possible fields with their corresponding names, styles, and addOns
 * columnConfig selects specific fields from this to display

 */

export const format = [
  {
    field: 'name',
    name: 'Name',
    flex: 6,
    bg: 'lightgreen',
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIconNoNeutral key="name" id="name" /> }],
  },
  {
    field: 'enrollment',
    name: 'Type',
    flex: 4,
    bg: 'lightpink',
    align: 'start',
    paddingRight: '10px',
    // addOns: [{ type: 'sort', element: <SortIconNoNeutral id="enrollmentValue" /> }],
  },
  {
    field: 'date',
    name: 'Date',
    flex: 3,
    bg: 'lightblue',
    align: 'center',
    addOns: [{ type: 'filter', element: <TimeFilter key="timeFilter" /> }],
  },
];

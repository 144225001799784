import React from 'react';
import PropTypes from 'prop-types';

// This component is meant to be used with the React Bootstrap Dropdown Component.
// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomDropdownToggle = React.forwardRef(({ children, onClick, disabled }, ref) => (
  <button
    type="button"
    ref={ref}
    // TODO: figure out why focus outline is not happening (may be the preventdefault below)
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="p-0 m-0 bg-transparent border-0 outline-none"
    disabled={disabled}
  >
    {children}
  </button>
));

CustomDropdownToggle.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CustomDropdownToggle.defaultProps = {
  children: null,
  disabled: false,
};

export default CustomDropdownToggle;

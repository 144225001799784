import React from 'react';
import PropTypes from 'prop-types';
import { getStringReplacement } from '@util/stringReplacements';
import { formatDate } from '@util/dateFunctions';
import LineChart from '@intus-ui/components/graphs/LineChart';
import Quad from 'Dashboard/Quad';
import BigTitle from 'Dashboard/QuadContent/BigTitle';
import TextData from 'Dashboard/QuadContent/TextData';
import { SpinnerOrError } from '@intus-ui';
import { censusModalPropType } from 'Homes/modalPropTypes/censusModalPropType';

const TotalParticipantsTrendline = ({ subcards, error }) => {
  const getCurrentEnrollment = () => {
    const total = subcards.totalParticipantsTrendline.slice(-1)[0].value;
    return total;
  };

  const textArea = subcards && (
    <>
      <BigTitle title="Month Start Enrollment:" />
      <div>
        <TextData
          data={getCurrentEnrollment()}
          subtext={{
            text: `${getStringReplacement('Participant', {
              lower: true,
              plural: true,
            })}`,
            vertical: true,
          }}
        />
      </div>
    </>
  );

  const chartArea = subcards && (
    <LineChart
      data={{
        labels: subcards.totalParticipantsTrendline.map((item) =>
          formatDate(item.month, 'MMM YYYY')
        ),
        datasets: [
          {
            label: 'Enrollments over time',
            data: subcards.totalParticipantsTrendline.map((item) => item.value),
            fill: true,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.3)',
            tension: 0.1,
          },
        ],
      }}
      xLabel="Time (Months)"
      yLabel={`${getStringReplacement('Participant', { plural: true })} (#)`}
      responsive
      aspectRatio={false}
      noPadding
      maxXTicks={9}
      maxYTicks={4}
    />
  );

  let mainArea = (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <SpinnerOrError error={error} />
    </div>
  );
  if (subcards) {
    mainArea = (
      <div className="w-100 h-100 d-flex flex-row align-items-center justify-content-center overflow-scroll">
        <div className="px-4 pt-4 pb-3 d-flex flex-column align-items-center justify-content-center">
          {textArea}
        </div>
        <div className="pt-2 pb-3 h-100 w-100 d-flex">{chartArea}</div>
      </div>
    );
  }

  return (
    <Quad
      scrollable
      title={`Total ${getStringReplacement('Participant', { plural: true })} Trendline`}
      padding="overflow-hidden"
    >
      {mainArea}
    </Quad>
  );
};

TotalParticipantsTrendline.propTypes = {
  subcards: censusModalPropType,
  error: PropTypes.string,
};

TotalParticipantsTrendline.defaultProps = {
  subcards: undefined,
  error: undefined,
};

export default TotalParticipantsTrendline;

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { pluralize } from '@util/utilFunctions';

import { setPage } from '@global-state/redux/profileSlice';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';

import ParticipantList from '@intus-ui/components/ParticipantList';
import {
  customDashModalConfig,
  customDashModalCountableConfig,
} from '@intus-ui/components/ParticipantList/list/columnConfigs';

// TODO WE SHOULD PASS THE TYPE OF PPT FOCUS VIEW TARGET TAB IN AS A PROP
const admitTitles = ['ER Visits', 'Inpatient Admissions'];
const fallsTitles = [
  'Participants with Multiple Falls',
  'All Participants with Falls',
  'Injury with Major Harm',
  'Injury with Moderate Harm',
  'Participants with Repeated Falls',
  'Participants with Falls',
  'Falls Count',
];

const DashboardPatientList = ({ history, data, setProfilePage }) => {
  // Constructs the patient data that's given to ParticipantList.
  // Not the full/standard API data, this adds/overrides some properties,
  // otherwise the list would come up completely empty due to filtering
  const items = data.list.map((patient) => ({
    ...patient,
    firstName: patient.name.split(' ')[0],
    lastName: patient.name.split(' ')[1],
    disenrollDate: 'Invalid Date',
    disenrollReason: 'Unknown',
    livingSituation: 'Unknown',
    ...(patient.instanceCount && { instanceCount: patient.instanceCount }),
  }));

  // used for alt title for dashboard cards in allowDuplicatePatients mode
  const totalInstances = items.reduce((sum, patient) => {
    return sum + (patient.instanceCount || 0);
  }, 0);

  const participantClick = (patient) => {
    if (data.notClickable) return;

    let profilePage = 'Current Acuity';
    let linkTo = 'current-acuity';

    if (admitTitles.includes(data.title)) {
      profilePage = 'Admits';
      linkTo = 'admits';
    } else if (fallsTitles.includes(data.title)) {
      profilePage = 'Falls';
      linkTo = 'falls';
    }

    setProfilePage(profilePage);
    history.push(`/patient/${patient.id}/${patient.backLoc ? patient.backLoc : linkTo}`);
  };

  // Sort by `instanceCount` if we get it from the backend.
  // If not use the default sort of acuity score.
  let defaultSort = { field: 'instanceCount', direction: 'desc' };
  if (items.length > 0 && items[0].instanceCount == null) {
    defaultSort = undefined;
  }

  return (
    <div className="d-flex flex-column h-100 w-100 font-weight-bold">
      {data.title === 'Risk Increasers' || data.title === 'Risk Decreasers' ? (
        <div className="d-flex pl-4 flex-row text-secondary">
          <h3>{data.title}</h3>
          {data.title === 'Risk Increasers' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </div>
      ) : (
        <h4 className="pl-4">
          {' '}
          {totalInstances > 0 &&
            `${totalInstances} ${pluralize('instance', totalInstances)} across ${
              items.length
            } ${pluralize('participant', items.length)}`}
          {totalInstances <= 0 && data.title && `${data.title} (${items.length} ppts)`}{' '}
        </h4>
      )}
      {data ? (
        <ParticipantList
          customColumnConfig={
            totalInstances > 0 ? customDashModalCountableConfig : customDashModalConfig
          }
          participantList={items}
          onClickItem={participantClick}
          searchable={false}
          titleRowElement={null}
          topRightElementsList={null}
          emptyListMessage="No Participants match this criteria."
          defaultFilter={{
            type: 'enroll',
            value: () => true,
          }}
          defaultSort={defaultSort}
        />
      ) : (
        <CenteredSpinner className="w-100 h-100" />
      )}
    </div>
  );
};

DashboardPatientList.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    title: PropTypes.string,
    notClickable: PropTypes.bool,
  }).isRequired,
  setProfilePage: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch) => ({
  setProfilePage: (page) => dispatch(setPage(page)),
});

export default withRouter(connect(null, mapDispatch)(DashboardPatientList));

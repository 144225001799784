/**
 * columnConfigs must have an object with a 'columns' field
 * list must have a 'default' key
 */
export const defaultColumnConfig = {
  default: {
    columns: ['name', 'strength', 'sig', 'dateOrdered'],
    breakpoints: [
      {
        minWidth: 735,
        columns: ['name', 'strength', 'sig', 'dateOrdered'],
      },
      { minWidth: 470, columns: ['name', 'strength', 'dateOrdered'] },
      { minWidth: 415, columns: ['name'] },
    ],
  },
};

export const columnConfigNoLastOrdered = {
  default: {
    columns: defaultColumnConfig.default.columns.filter(
      (s) => s !== 'dateFilled' && s !== 'dateOrdered'
    ),
    breakpoints: defaultColumnConfig.default.breakpoints.map((breakpoint) => {
      const { columns, ...rest } = breakpoint;
      return { ...rest, columns: columns.filter((s) => s !== 'dateFilled' && s !== 'dateOrdered') };
    }),
  },
};

export function getMedicationsTabColumnConfig(includePDCColumn) {
  const pdcColumn = includePDCColumn ? ['pdc'] : [];
  return {
    default: {
      columns: ['name', 'dateOrdered', 'strength', 'sig', 'metricQuantity'],
    },
    claimsConfig: {
      columns: [
        'name',
        'dateFilled',
        'strength',
        'daysSupply',
        'metricQuantity',
        ...pdcColumn,
        'physician',
      ],
      breakpoints: [
        {
          minWidth: 735,
          columns: [
            'name',
            'dateFilled',
            'strength',
            'daysSupply',
            'metricQuantity',
            ...pdcColumn,
            'physician',
          ],
        },
        { minWidth: 470, columns: ['name', 'strength', 'metricQuantity'] },
        { minWidth: 415, columns: ['name'] },
      ],
    },
  };
}

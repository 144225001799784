import React from 'react';

import { capitalizeFirst } from '@util/utilFunctions';

import '@intus-ui/styles/Badges.scss';

const RiskBadge = ({ riskGroup = 'insufficient', smallText, style, isDisenrolled, customText }) => {
  let className = `risk-badge risk-badge-${riskGroup?.toLowerCase()}`;
  if (isDisenrolled) className += ' risk-badge-disenrolled';
  if (smallText) className += ' text-sm-p';

  return (
    <div className={className} style={style}>
      {customText || capitalizeFirst(riskGroup)}
    </div>
  );
};

export default RiskBadge;

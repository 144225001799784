import generateTrackingInfo from 'Login/generateTrackingInfo';

const TwoFactorAuthenticationApi = {
  tagTypes: ['TwoFactorAuthentication'],
  endpoints: (builder) => ({
    // Generate a new two-factor authentication secret.
    twoFactorGenerate: builder.mutation({
      query: (jwt) => ({
        url: 'twoFactorAuthentication/generate',
        method: 'POST',
        headers: { 'x-auth-token': `Bearer ${jwt}` },
      }),
    }),

    // Verify the newly generated secret.
    twoFactorConfirm: builder.mutation({
      query: ({ jwt, token }) => ({
        url: 'twoFactorAuthentication/confirm',
        method: 'POST',
        headers: { 'x-auth-token': `Bearer ${jwt}` },
        body: { token, trackingInfo: generateTrackingInfo() },
      }),
    }),

    // Validate the current code when logging in.
    twoFactorValidate: builder.mutation({
      query: ({ jwt, token, rememberMe }) => ({
        url: 'twoFactorAuthentication/validate',
        method: 'POST',
        headers: { 'x-auth-token': `Bearer ${jwt}` },
        body: { token, rememberMe, trackingInfo: generateTrackingInfo() },
      }),
    }),

    // Reset a user's two-factor authentication.
    twoFactorReset: builder.mutation({
      query: (id) => ({
        url: `users/${id}`,
        method: 'PUT',
        body: { twoFactorAuthenticationEnabled: false },
      }),
    }),
  }),
};

// TODO: Hash the emails?
// Ideally, it would not be possible to see who else is remembered in the current browser.
// Hashing weakly accomplishes this. If this is important, we could also save the remember me tokens with
// completely random keys and send all of them (the server would then accept if any are valid).
export const createRememberMeTokenKey = (email) => `rememberMeToken_${email}`;

export default TwoFactorAuthenticationApi;

import { capitalizeFirst } from '@util/utilFunctions';

import '@intus-ui/styles/Badges.scss';

const EventBadge = ({ event }) => {
  const className = `event-badge event-badge-${event?.toLowerCase()}`;

  return (
    <div className={className}>
      {event?.toLowerCase() === 'er' || event?.toLowerCase() === 'er visit'
        ? 'ER Visit'
        : capitalizeFirst(event)}
    </div>
  );
};

export default EventBadge;

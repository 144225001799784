import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { getStringReplacement } from '@util/stringReplacements';
import { useGetSessionUser } from '@util/session';

import { getActivePatients } from '@api/api';
import { useQuery } from '@api/useQuery';
import { mapPatient } from '@api/patients/mapPatient';

import { updateUserSettings, getUserInfo } from '@global-state/redux/userSettingsSlice';

import {
  layout,
  Button,
  ButtonGroup,
  SpinnerOrError,
  Text,
  useParticipantFilters,
} from '@intus-ui';
import ParticipantList from '@intus-ui/components/ParticipantList';
import { pinnedPptEditConfig } from '@intus-ui/components/ParticipantList/list/columnConfigs';

import '@intus-ui/styles/ListItem.scss';
import '@intus-ui/styles/Utilities.scss';

const PinnedModal = ({ showModal, hide }) => {
  const dispatch = useDispatch();
  const { selectedFilters } = useParticipantFilters();

  const user = useGetSessionUser();
  const pinnedPptIds = user?.setting?.pinnedParticipants ?? [];

  const [unsavedPinnedPptIds, setUnsavedPinnedPptIds] = useState();

  const pinOrUnpinPpt = (item) => {
    if (item.pinned) {
      setUnsavedPinnedPptIds(unsavedPinnedPptIds.filter((select) => select !== item.id));
    } else {
      setUnsavedPinnedPptIds([...unsavedPinnedPptIds, item.id]);
    }
  };

  const { data, loading, runQuery } = useQuery(() => getActivePatients(selectedFilters));

  const patients = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.map((patient) => mapPatient(patient));
  }, [data]);

  useEffect(() => {
    setUnsavedPinnedPptIds(pinnedPptIds);
  }, [pinnedPptIds.join(',')]);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  // Re-run the query when the filters change.
  useEffect(() => {
    runQuery();
  }, [selectedFilters]);

  const itemsPinned = patients
    ?.filter((patient) => unsavedPinnedPptIds?.includes(patient.id))
    .map((item) => ({ ...item, pinned: true }));

  const itemsUnpinned = patients
    ?.filter((patient) => !unsavedPinnedPptIds?.includes(patient.id))
    .map((item) => ({ ...item, pinned: false }));

  useEffect(() => {
    itemsPinned?.filter((patient) => unsavedPinnedPptIds?.includes(patient.id));
    itemsUnpinned?.filter((patient) => !unsavedPinnedPptIds?.includes(patient.id));
  }, [unsavedPinnedPptIds, itemsPinned, itemsUnpinned]);

  const updatedSetting = {
    ...user?.setting,
    pinnedParticipants: unsavedPinnedPptIds,
  };

  let emptyListMessage = `You have not pinned any ${getStringReplacement('Participant', {
    lower: true,
    plural: true,
  })}, pin by clicking the edit icon above.`;
  if (pinnedPptIds?.length !== 0) {
    emptyListMessage = `There are no pinned ${getStringReplacement('Participant', {
      lower: true,
      plural: true,
    })} that match the filter criteria.`;
  }

  return (
    <Modal
      dialogClassName="home-modal min-w-90 overflow-hidden rounded-lg"
      show={!!showModal}
      onHide={() => {
        hide(false);
        setUnsavedPinnedPptIds(pinnedPptIds);
      }}
    >
      <Modal.Header className="py-0 pl-4 pb-0 m-0 pt-4" closeButton>
        <Modal.Title className="text-align-left text-secondary bg-light m-0 pl-3 pb-2 font-weight-normal">
          Edit Pinned {getStringReplacement('Participant', { plural: true })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pl-2 ml-4 mr-4 pr-2 pt-0 pb-0">
        {loading || !user ? (
          <SpinnerOrError />
        ) : (
          <div style={{ ...layout.container }}>
            <div style={styles.multiColList}>
              <div style={{ ...layout.content }}>
                <ParticipantList
                  customColumnConfig={pinnedPptEditConfig}
                  participantList={itemsPinned}
                  onClickItem={pinOrUnpinPpt}
                  searchable
                  titleRowElement={<Text type="title">Currently Pinned</Text>}
                  topRightElementsList={null}
                  emptyListMessage={emptyListMessage}
                  style={styles.pptList}
                />
              </div>
              <div style={{ ...layout.content }}>
                <ParticipantList
                  customColumnConfig={pinnedPptEditConfig}
                  participantList={itemsUnpinned}
                  onClickItem={pinOrUnpinPpt}
                  searchable
                  titleRowElement={<Text type="title">Not Pinned</Text>}
                  topRightElementsList={null}
                  emptyListMessage={`No ${getStringReplacement('Participant', {
                    lower: true,
                    plural: true,
                  })} found matching your search`}
                  style={styles.pptList}
                />
              </div>
            </div>
            <div style={styles.modalButtons}>
              <ButtonGroup>
                <Button
                  secondary
                  onClick={() => {
                    hide(false);
                    setUnsavedPinnedPptIds(pinnedPptIds);
                  }}
                  style={{ margin: 'initial' }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    dispatch(updateUserSettings({ ...user, settings: updatedSetting }));
                    hide(false);
                  }}
                  style={{ margin: 'initial' }}
                >
                  Save Changes
                </Button>
              </ButtonGroup>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const styles = {
  modalButtons: { ...layout.container, padding: '0 1.5rem 1.5rem' },
  multiColList: { ...layout.rowContainer, overflowY: 'auto', maxHeight: '70vh' },
  pptList: {
    borderRadius: '20px',
  },
};

PinnedModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

export default PinnedModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

// Do not add feature-specific code to this component. It should remain as general as possible.

// TabPageHeader contains the styling and layout for any body Columns in tab pages (ex. Ppt, Cohorts, Dashboards)
const TabPageCol = ({ colNum, padMarg, children }) => (
  <Col xs={colNum} className={`d-flex h-100 flex-basis-1 ${padMarg}`} style={{ flexGrow: 1 }}>
    {children}
  </Col>
);

TabPageCol.propTypes = {
  colNum: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  padMarg: PropTypes.string,
};

TabPageCol.defaultProps = {
  padMarg: 'p-0 m-0',
};
export default TabPageCol;

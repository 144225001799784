import { getStringReplacement } from '@util/stringReplacements';
import { Button, Text, Icon } from '@intus-ui';
import CohortCard from 'AdvancedCohort/CohortCard';
import { useHistory } from 'react-router-dom';
import { sampleFixedCohort, sampleRollingCohort } from './sampleCohort';

const CohortTypeSelection = () => {
  const history = useHistory();
  return (
    <div style={styles.container}>
      {/* HEADER  */}
      <div style={styles.header}>
        <Button secondary onClick={() => history.goBack()}>
          <Icon name="caret-left" />
          Back
        </Button>
        <Text type="headline" color="navy" style={{ fontSize: '18px', fontWeight: '700' }}>
          Create Cohort
        </Text>
      </div>
      <div style={{ alignSelf: 'center', paddingBottom: '25px' }}>
        <Text type="headline" color="navy" style={{ fontSize: '28px', fontWeight: '600' }}>
          Select a Type of Cohort
        </Text>
      </div>
      <div style={styles.body}>
        {/* FIXED COHORT OPTION */}
        <div style={styles.leftOption}>
          <div style={styles.titleDescription}>
            <Text type="headline" color="navy">
              Fixed Cohort
            </Text>
            <Text type="body" color="body">
              You select a static list of {getStringReplacement('Participant', {lower: true, plural: true})} to be in the cohort. You can search by name
              or use filters to find the {getStringReplacement('Participant', {lower: true, plural: true})} you want to include. {getStringReplacement('Participant', {plural: true})} are added
              and removed manually.
            </Text>
          </div>

          <div style={styles.sample}>
            <div style={styles.caption}>
              <Text type="subtitle">Useful for:</Text>
              <Text type="body" color="body">
                <li>Creating watchlists of specific {getStringReplacement('Participant', {plural: true})}</li>
                <li>
                  Tracking the success of interventions targeted at a fixed group of individuals
                </li>
              </Text>
            </div>
            <Text type="subtitle">Sample:</Text>
            <CohortCard
              cohort={sampleFixedCohort}
              style={styles.cohort}
              clickable="false"
              config={{
                flat: true,
                clickable: false,
                patientCounts: false,
                cursor: 'default',
              }}
            />
          </div>
          <Button style={{ width: '180px' }} onClick={() => history.push('/cohorts/fixed/create')}>
            Create Fixed Cohort
          </Button>
        </div>

        {/* ROLLING COHORT OPTION */}
        <div style={styles.rightOption}>
          <div style={styles.titleDescription}>
            <Text type="headline" color="navy">
              Rolling Cohort
            </Text>
            <Text type="body" color="body">
              You select filter criteria for the cohort. {getStringReplacement('Participant', {plural: true})} are added or removed
              automatically from the cohort as they meet or no longer meet the criteria.
            </Text>
          </div>

          <div style={styles.sample}>
            <div style={styles.caption}>
              <Text type="subtitle">Useful for:</Text>
              <Text type="body" color="body">
                <li>
                  Tracking the success of protocols applied to all {getStringReplacement('Participant', {lower: true, plural: true})} with a condition
                </li>
                <li> Monitoring the size of certain populations over time</li>
                <li> Identifying {getStringReplacement('Participant', {lower: true, plural: true})} who newly meet certain criteria</li>
              </Text>
            </div>
            <Text type="subtitle">Sample:</Text>
            <CohortCard
              cohort={sampleRollingCohort}
              style={styles.cohort}
              clickable="false"
              config={{
                flat: true,
                clickable: false,
                patientCounts: true,
                cursor: 'default',
              }}
            />
          </div>
          <Button
            style={{ minWidth: '185px' }}
            onClick={() => history.push('/cohorts/rolling/create')}
          >
            Create Rolling Cohort
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CohortTypeSelection;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'start',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    justifyContent: 'start',
    paddingLeft: '30px',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    padding: '0px',
  },
  leftOption: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    borderRight: '1px solid #9D9D9D',
    padding: '0px 80px 0px 0px',
    height: '100%',
    gap: '15px',
    width: '433px',
  },
  rightOption: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    padding: '0px 0px 0px 80px',
    height: '100%',
    gap: '15px',
    width: '433px',
  },
  sample: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '5px',
  },
  titleDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
    textAlign: 'center',
    minHeight: '100px',
  },
  caption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    minHeight: '100px',
  },
  cohort: {
    minHeight: '215px',
  },
};

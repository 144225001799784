/**
 * @description
 * Provides user login session storage and management methods.
 *
 * Uses local storage module for persistence.
 */

import { createRememberMeTokenKey } from '@api/TwoFactorAuthentication';
import { getLogger } from '@util/logger';
import { findLocalData, createLocalData, clearLocalData, useLocalStorage } from './localStorage';

const log = getLogger('session');

export const setSessionUser = (data) => {
  const user = getSessionUser();
  if (!user) {
    log.info('setSessionUser', 'Storing session user');
    createLocalData('user', data);
  } else {
    log.info('setSessionUser', 'Updating session user');
    createLocalData('user', { ...user, ...data });
  }
};

export const getSessionUser = () => {
  return findLocalData('user');
};

/**
 * Re-usable hook that returns the logged in user from local storage.
 *
 * Automatically detects any changes to the user object in local storage and re-renders the React component.
 */
export const useGetSessionUser = () => {
  return useLocalStorage('user');
};

export const setSessionToken = (token, rememberMeToken, email) => {
  log.info('setSessionToken', 'Storing session token');
  createLocalData('authToken', token);
  if (rememberMeToken) {
    createLocalData(createRememberMeTokenKey(email), rememberMeToken);
  }
};

export const getSessionToken = () => {
  try {
    return findLocalData('authToken');
  } catch (e) {
    log.warn('getSessionToken', 'Could not find authToken');
  }
  return null;
};

export const renewSession = () => {
  log.info('renewSession', 'Renewing auth token');
  // renewAuthToken();
};

export const validateSession = (renew = false) => {
  if (!getSessionToken()) {
    log.warn('validateSession', 'Invalid session - clearing');
    clearSession();
  }
  if (renew) renewSession();
  return getSessionUser();
};

export const clearSession = (
  destination = window.location.pathname,
  redirectReason = 'sessionExpired'
) => {
  log.info(
    'clearSession',
    'Clearing session',
    window.location.toString(),
    destination,
    redirectReason
  );
  clearLocalData();
  const queryParams = new URLSearchParams({ redirectTo: destination, redirectReason });
  const targetUrl = `/${
    destination && destination !== '' && destination !== '/'
      ? `?${queryParams.toString()}`
      : destination
  }`;
  window.location.replace(targetUrl);
};

export const redirectToMaintenance = (redirectUrl) => {
  if (redirectUrl === '/maintenance') return;

  const queryParams = new URLSearchParams({
    redirectTo: redirectUrl,
    redirectReason: 'maintenancePage',
  });
  const targetUrl = `${window.location.origin}/maintenance${
    queryParams.toString() ? `?${queryParams.toString()}` : ''
  }`;

  log.info(
    'redirectToMaintenance',
    'Redirecting to maintenance page',
    window.location.toString(),
    'maintenancePage'
  );

  window.location.replace(targetUrl);
};

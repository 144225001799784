import { put, get } from '@api/apiMethods';
import { Checklist, ReviewNoteFormData } from 'Workflows/AdmitReviewNote/types';

type AdmitReviewNote = {
  compositeKey: string;
  arnData: ReviewNoteFormData;
};

type AdmitReviewNoteChecklist = {
  compositeKey: string;
  updatedChecklist: Checklist;
};

export const getAdmitReviewNote = (compositeKey: string) => {
  return get(`admit-review-notes/${compositeKey}`);
};

export const updateAdmitReviewNote = ({ compositeKey, arnData }: AdmitReviewNote) => {
  return put(`admit-review-notes/${compositeKey}`, arnData);
};

export const getNoteOptions = () => {
  return get('admit-review-notes/note-options');
};

export const updateChecklist = ({ compositeKey, updatedChecklist }: AdmitReviewNoteChecklist) => {
  return put(`admit-review-notes/${compositeKey}/checklist`, updatedChecklist);
};

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getStringReplacement } from '@util/stringReplacements';
import { formatDate, newDate, startOfDate } from '@util/dateFunctions';

import { Row } from 'react-bootstrap';
import { SpinnerOrError } from '@intus-ui';
import TextData from 'Dashboard/QuadContent/TextData';
import BigTitle from 'Dashboard/QuadContent/BigTitle';

import '@intus-ui/styles/Utilities.scss';

const CensusPreview = ({ content, error }) => (
  <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center py-3">
    <div className="d-flex flex-column pb-2 align-items-center">
      <BigTitle title="Month Start Enrollment: " />
      <Row className="w-100 h-100 d-flex flex-column align-items-center justify-content-center pt-3">
        {content ? (
          <TextData
            data={content[formatDate(startOfDate(newDate(), 'month'), 'MM/DD/YYYY')].ids.length}
            subtext={{
              text: getStringReplacement('Participant', { lower: true, plural: true }),
              vertical: true,
            }}
          />
        ) : (
          <SpinnerOrError error={error} />
        )}
      </Row>
    </div>
  </div>
);

CensusPreview.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
  error: PropTypes.string,
};

CensusPreview.defaultProps = {
  // content: undefined,
  error: undefined,
};

const mapState = (state) => ({
  error: state.homepage.homeError,
});

export default connect(mapState)(CensusPreview);

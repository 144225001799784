import React from 'react';
import PropTypes from 'prop-types';

import { moneyAbbreviate } from '@util/utilFunctions';

import nivoColors from '@intus-ui/styles/NivoColors';

const DataBubble = ({ data }) => {
  const total = Math.round(Object.values(data || {}).reduce((a, b) => a + b, 0));
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div
        className="position-relative box-shadow-on-gray"
        style={{
          paddingBottom: '70%',
          borderRadius: '50%',
          width: '70%',
          backgroundColor: nivoColors[4],
        }}
      >
        <div
          className="position-absolute d-flex flex-column justify-content-center align-items-center"
          style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <div className="text-white" style={{ fontSize: '1.2em' }}>
            Total External Cost
          </div>
          <div className="text-white p-4 font-weight-bold" style={{ fontSize: '4.5em' }}>
            {moneyAbbreviate(total, 1)}
          </div>
        </div>
      </div>
    </div>
  );
};

DataBubble.propTypes = {
  // this is a complex object that will differ from facility to facility
  data: PropTypes.instanceOf(Object),
};

DataBubble.defaultProps = {
  data: undefined,
};

export default DataBubble;

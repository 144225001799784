import React, { FC } from 'react';

import { quadColors } from '@intus-ui/styles/SecondaryColors';

type DividerProps = {
  color?: keyof typeof quadColors;
  style?: React.CSSProperties;
};

const Divider: FC<DividerProps> = ({ color = 'disabled', style }) => {
  const styles = {
    height: 0,
    width: '100%',
    margin: 0,
    backgroundColor: quadColors[color],
    ...style,
  };
  return <hr style={styles} />;
};

export default Divider;

import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMostRecentNavbarItem } from '@global-state/redux/userSettingsSlice';

/**
 * @param { string } displayTitle display title of navigation bar item
 * @param { NAVIGATION_TAB_NAME } name name of tab, if the item has a parent category, this will be the category name
 * @param { NAVIGATION_TAB_NAME } subName name of subpage, only filled if item has a parent category
 * @param { string } root name of the root page we are on, e.g. Settings, Participants, etc.
 */
const NavBarItem = ({ displayTitle, name, subName, root }) => {
  const { tabName, subPage } = useParams();
  // the link created through the props (name and subName), that would be the url if this item is selected
  const link = subName ? `${name}/${subName}` : name;
  // the current url link, retrived with useParams() above
  const paramLink = subPage ? `${tabName}/${subPage}` : tabName;

  const history = useHistory();
  const isSelected = paramLink === link;
  const dispatch = useDispatch();

  const handleClickNavBarItem = () => {
    /* persist tab state so that when navigating back to cohorts from some other
     * app feature (like participants) the tab is defaulted to the last visited tab
     */
    dispatch(setMostRecentNavbarItem(link));
    history.push(`/${root}/${link}`);
  };

  return (
    <button
      onClick={handleClickNavBarItem}
      title={displayTitle}
      type="button"
      style={{ textDecoration: 'none' }}
      className="w-100 px-3 py-2 text-left border-0 outline-none bg-transparent mb-3 rounded hover-dark-blue"
    >
      <h6
        className={`d-flex justify-content-start align-items-center ${
          isSelected ? 'text-secondary font-weight-bold' : 'text-primary font-weight-normal'
        }`}
      >
        <span>{displayTitle}</span>
      </h6>
    </button>
  );
};

export default NavBarItem;

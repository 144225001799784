import { MetricParticipantList } from '@intus-ui/components/Widget/components/MetricParticipantList';

export const ParticipantListFocusView = ({ metric: _metric, metricData }) => {
  const { participants } = metricData.data;

  return (
    <div style={styles.container}>
      <MetricParticipantList
        metricData={metricData}
        participantsList={participants}
        selectedLabel=""
        handleOnClickCloseButton={() => {}}
        style={{
          border: 'none',
          padding: 0,
          margin: 0,
        }}
        noPadding
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px 0px 20px',
    gap: 20,
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
};

import { Icon, ParticipantList, Text } from '@intus-ui';
import { formatDate, newDateInFormat } from '@util/dateFunctions';
import { useMemo } from 'react';
import './MetricParticipantsDrillDownList.scss';
import produce from 'immer';
import { getParticipantListColumns } from '@intus-ui/components/ParticipantList/list/format';

/**
 * Component that renders a list of participants when clicking on a month in the metric chart.
 */
export function MetricParticipantsDrillDownList({
  metric,
  clickedChartMonth,
  setClickedChartMonth,
}) {
  // Flatten the list of participants in the cohorts into a single list.
  const participantsInMonth = useMemo(() => {
    if (clickedChartMonth == null || metric == null) {
      return [];
    }

    const patientIdToPatient = new Map();

    for (const cohort of metric.cohorts) {
      for (const patient of cohort.results[clickedChartMonth].patientsInMonth) {
        // We do not show patients who have no events in the month.
        if (patient.count === 0) {
          continue;
        }
        if (!patientIdToPatient.has(patient.id)) {
          patientIdToPatient.set(patient.id, patient);
        }
      }
    }

    const allPatients = Array.from(patientIdToPatient.values());

    return allPatients;
  }, [clickedChartMonth, metric]);

  return (
    <div
      style={{
        // Hacky negative margin to get this in the correct position.
        // Not ideal but it's hard to change the higher level layout to support this on the right.
        marginTop: -60,
        marginLeft: 12,
        flex: '1 1 auto',
        border: '2px solid #2E62E7',
        borderRadius: '10px',
        padding: '10px 15px 0px 15px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Show the month clicked in a format like `Sep' 23` */}
        <Text type="title" color="navy">
          {formatDate(newDateInFormat(clickedChartMonth, 'MM/YY'), "MMM 'YY")}
        </Text>
        <Icon
          role="button"
          tabIndex={0}
          aria-label="Close Participant Month View"
          name="Remove"
          color="#9D9D9D"
          hoverColor="#9D9D9D"
          onClick={() => setClickedChartMonth(null)}
        />
      </div>
      <div
        className="metricParticipantsDrillDown-participantList"
        style={{ height: 375, marginLeft: -20, marginRight: -12 }}
      >
        <ParticipantList
          participantList={participantsInMonth}
          customColumnConfig={participantDrillDownColumnConfig}
          customFormat={getInitiativesParticipantListFormat()}
          onClickItem={(ppt) => window.open(`/patient/${ppt.id}/current-acuity`, '_blank')}
          searchable={false}
          defaultSort={{ field: 'instanceCount', direction: 'desc' }}
        />
      </div>
    </div>
  );
}

const participantDrillDownColumnConfig = {
  default: {
    columns: ['name', 'instanceCount'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'instanceCount'] }],
  },
};

/**
 * Change the styling of the name column so the cohorts column does not overflow
 * Overflowing breaks the tooltip on the MORE tag.
 */
function getInitiativesParticipantListFormat() {
  return produce(getParticipantListColumns(), (draft) => {
    draft.find((column) => column.field === 'name').flex = '1 1 auto';
    draft.find((column) => column.field === 'instanceCount').flex = '1 1 auto';
  });
}

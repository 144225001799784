import {
  getFacilitiesByOrg,
  getHealthSystemAdminOrganizations,
  getTeamsByOrg,
  getCohortsNames,
} from '@api/api';
import { getOrganizationConfiguration } from '@api/organizations';
import { useLazyQuery } from '@api/useQuery';
import {
  setFacilities as setFacilitiesRedux,
  setFacilityFilter as setFacilityFilterRedux,
  setOrgFilter as setOrgFilterRedux,
  setOrganizations as setOrganizationsRedux,
  setTeamFilter as setTeamFilterRedux,
  setTeams as setTeamsRedux,
} from '@global-state/redux/filtersSlice';
import { getLogger } from '@util/logger';
import { useGetSessionUser } from '@util/session';
import { USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import produce from 'immer';
import { flatten, groupBy, sortBy } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const ParticipantFiltersContext = createContext({});

/**
 * @typedef {{ type: string, groupId: number, name: string }} PatientGroup
 * @typedef {{ teams: string[], facilities: string[], groups: Record<string, PatientGroup[] | null>, organization: string | null }} SelectedFiltersType
 * @typedef {{id: number, name: string}} OrganizationType
 *
 * @typedef {{
 *    selectedFilters: SelectedFiltersType,
 *    areFiltersLoaded: boolean,
 *    teams: string[],
 *    facilities: string[],
 *    healthSystemAdminOrganizations: OrganizationType[],
 *    groupFilters: { name: string, type: string, groups: { id: number, name: string }[] }[],
 *    setTeams: (teams: string[]) => void,
 *    setFacilities: (facilities: string[]) => void,
 *    setGroupFilter: (type: string, groups: number[]) => void,
 *    setSelectedOrg: (org: string | OrganizationType) => void,
 *  }} ParticipantFiltersContextType
 *
 * @returns {ParticipantFiltersContextType}
 */
export function useParticipantFilters() {
  return useContext(ParticipantFiltersContext);
}

const log = getLogger('ParticipantFiltersProvider');

// Avoid breaking memoization.
const emptyArray = [];

/**
 * Context provider that handles loading the teams, facilities, and extra patient group filters for an org.
 * Handles updating the selected filters. The selected filters are stored in memory so they will be lost on refresh.
 *
 * CAREFUL: This does not block the render of the rest of the app while it loads the data.
 *          The teams/facilities will be returned as an empty array while this is loading.
 */
export const ParticipantFiltersProvider = ({ children }) => {
  const user = useGetSessionUser();

  const { teams, selectedTeams, setTeams } = useTeamFilter();

  const { facilities, selectedFacilities, setFacilities } = useFacilityFilter();

  const { groupFilters, selectedGroupFilters, setGroupFilter } = usePatientGroupFilters();

  const { healthSystemAdminOrganizations, selectedOrg, setSelectedOrg } = useOrganizationsFilter();

  const { cohorts, selectedCohorts, setSelectedCohorts } = useCohortsFilter();

  const isHealthSysAdmin = user?.access === USER_ACCESS_HEALTH_SYS_ADMIN;

  const selectedFilters = useMemo(() => {
    return {
      teams: selectedTeams,
      facilities: selectedFacilities,
      groups: selectedGroupFilters,
      organization: selectedOrg,
      cohorts: selectedCohorts,
    };
  }, [selectedTeams, selectedFacilities, selectedGroupFilters, selectedOrg, selectedCohorts]);

  /** @type {ParticipantFiltersContextType} */
  const contextReturn = useMemo(() => {
    return {
      selectedFilters,
      areFiltersLoaded:
        teams != null &&
        facilities != null &&
        groupFilters != null &&
        cohorts != null &&
        (!isHealthSysAdmin || healthSystemAdminOrganizations != null),
      groupFilters: groupFilters || emptyArray,
      teams: teams || emptyArray,
      facilities: facilities || emptyArray,
      healthSystemAdminOrganizations: healthSystemAdminOrganizations || emptyArray,
      cohorts: cohorts || emptyArray,
      setTeams,
      setFacilities,
      setGroupFilter,
      setSelectedOrg,
      setSelectedCohorts,
    };
  }, [
    selectedFilters,
    teams,
    facilities,
    groupFilters,
    isHealthSysAdmin,
    cohorts,
    healthSystemAdminOrganizations,
    setTeams,
    setFacilities,
    setGroupFilter,
    setSelectedOrg,
    setSelectedCohorts,
  ]);

  return (
    <ParticipantFiltersContext.Provider value={contextReturn}>
      {children}
    </ParticipantFiltersContext.Provider>
  );
};

function useTeamFilter() {
  const dispatch = useDispatch();

  // We pull teams/facilities from Redux now since a lot of existing code uses Redux for this.
  const teams = useSelector((state) => state.filters?.teams);
  const selectedTeams = useSelector((state) => state.filters?.teamFilter);

  const setTeams = useCallback(
    (newTeams) => {
      // If all the items are selected, then we aren't filtering by team so save null as the selected team.
      if (newTeams.length === teams?.length) {
        dispatch(setTeamFilterRedux(null));
      } else {
        dispatch(setTeamFilterRedux(newTeams));
      }
    },
    [dispatch, teams]
  );

  const user = useGetSessionUser();

  const organizationId = user?.organization?.id;

  const userSettingTeam = user?.setting?.team;

  // null means use all teams.
  const defaultTeams = useMemo(() => {
    if (userSettingTeam == null) {
      return null;
    }
    if (userSettingTeam === 'All') {
      return null;
    }

    return [userSettingTeam];
  }, [userSettingTeam]);

  const { runQuery: runTeamsQuery } = useLazyQuery((orgId) => getTeamsByOrg(orgId), {
    onSuccess: (data) => {
      // Use redux as the source of truth for now to avoid breaking existing code.
      dispatch(setTeamsRedux([...data.sort()]));
      dispatch(setTeamFilterRedux(defaultTeams));
    },
    onError: (error) => {
      log.error('Error fetching teams');
      log.error(error);
    },
  });

  // Only run these queries for logged in users.
  useEffect(() => {
    if (organizationId == null) {
      return;
    }

    // Only load teams and facilities if we don't have them in Redux already.
    if (teams == null || teams.length === 0) {
      runTeamsQuery(organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return {
    teams,
    selectedTeams,
    setTeams,
  };
}

function useFacilityFilter() {
  const dispatch = useDispatch();

  const facilities = useSelector((state) => state.filters?.facilities);
  const selectedFacilities = useSelector((state) => state.filters?.facilityFilter);

  const setFacilities = useCallback(
    (newFacilities) => {
      // If all the items are selected, then we aren't filtering by team so save null as the selected team.
      if (newFacilities.length === facilities?.length) {
        dispatch(setFacilityFilterRedux(null));
      } else {
        dispatch(setFacilityFilterRedux(newFacilities));
      }
    },
    [dispatch, facilities]
  );

  const user = useGetSessionUser();

  const userSettingFacility = user?.setting?.facility;

  const organizationId = user?.organization?.id;

  // null means use all facilities
  const defaultFacilities = useMemo(() => {
    if (userSettingFacility == null) {
      return null;
    }
    if (userSettingFacility === 'All') {
      return null;
    }

    return [userSettingFacility];
  }, [userSettingFacility]);

  // All the queries here are lazy-loaded so they are not called for a user who is not logged in.
  const { runQuery: runFacilitiesQuery } = useLazyQuery((orgId) => getFacilitiesByOrg(orgId), {
    onSuccess: (data) => {
      // Use redux as the source of truth for now to avoid breaking existing code.
      dispatch(setFacilitiesRedux([...data.sort()]));
      dispatch(setFacilityFilterRedux(defaultFacilities));
    },
    onError: (error) => {
      log.error('Error fetching facilities');
      log.error(error);
    },
  });

  // Only run these queries for logged in users.
  useEffect(() => {
    if (organizationId == null) {
      return;
    }

    // Only load facilities if we don't have them in Redux already.
    if (facilities == null || facilities.length === 0) {
      runFacilitiesQuery(organizationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return {
    facilities,
    selectedFacilities,
    setFacilities,
  };
}

function usePatientGroupFilters() {
  // A mapping of the group.type field to an array of groups that are selected.
  const [selectedGroupFilters, setSelectedGroupFilters] = useState({});

  const user = useGetSessionUser();
  const organizationId = user?.organization?.id;

  // All the queries here are lazy-loaded so they are not called for a user who is not logged in.
  const { data: orgConfigData, runQuery: runOrgConfigQuery } = useLazyQuery(
    (orgId) => getOrganizationConfiguration(orgId),
    {
      onError: (error) => {
        log.error('Error fetching organization configuration');
        log.error(error);
      },
    }
  );

  const typeToGroups = useMemo(() => {
    if (orgConfigData == null) {
      return {};
    }
    const allGroups = flatten(orgConfigData.patientGroupFilters.map((c) => c.groups));
    return groupBy(allGroups, (g) => g.type);
  }, [orgConfigData]);

  // Only run these queries for logged in users.
  useEffect(() => {
    if (organizationId == null) {
      return;
    }
    runOrgConfigQuery(organizationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  /** Handles setting the patient group filters or clearing out a filter when All is selected. */
  const setGroupFilter = useCallback(
    (type, groupIds) => {
      setSelectedGroupFilters((previousGroupFilters) => {
        return produce(previousGroupFilters, (draft) => {
          // Remove all existing groups that have the same type.
          draft[type] = null;

          // If they unchecked all the filters, use empty array to mean no filters selected.
          if (groupIds == null || groupIds.length === 0) {
            draft[type] = [];
            return;
          }

          // If we're selecting all the filters, use null to mean all filters.
          if (groupIds.length === typeToGroups[type].length) {
            draft[type] = null;
            return;
          }

          const groupIdSet = new Set(groupIds);

          // Find the groups that have a matching type and ID and add them to the list of selected groups.
          for (const groupFilters of orgConfigData.patientGroupFilters) {
            if (groupFilters.type === type) {
              draft[type] = [];

              if (groupIds.length === groupFilters.groups.length) {
                break;
              }
              for (const group of groupFilters.groups) {
                if (groupIdSet.has(group.id)) {
                  draft[type].push(group);
                }
              }
            }
          }
        });
      });
    },
    [orgConfigData, typeToGroups]
  );

  return {
    selectedGroupFilters,
    groupFilters: orgConfigData?.patientGroupFilters,
    setGroupFilter,
  };
}

function useOrganizationsFilter() {
  const dispatch = useDispatch();

  const organizations = useSelector((state) => state.filters?.organizations);
  const selectedOrg = useSelector((state) => state.filters?.orgFilter);

  const setSelectedOrg = useCallback(
    (org) => {
      if (org === 'All') {
        dispatch(setOrgFilterRedux('All'));
      } else {
        dispatch(setOrgFilterRedux(org.id.toString()));
      }
    },
    [dispatch]
  );

  const user = useGetSessionUser();
  const organizationId = user?.organization?.id;
  const isHealthSysAdmin = user?.access === USER_ACCESS_HEALTH_SYS_ADMIN;

  const { runQuery: runHealthSystemAdminOrganizationsQuery } = useLazyQuery(
    () => getHealthSystemAdminOrganizations(),
    {
      onSuccess: (data) => {
        dispatch(setOrganizationsRedux(data));
      },
      onError: (error) => {
        log.error('Error fetching health system admin organizations');
        log.error(error);
      },
    }
  );

  // Only run the query for logged in users.
  useEffect(() => {
    if (organizationId == null) {
      return;
    }

    if (isHealthSysAdmin) {
      if (organizations == null || organizations.length === 0) {
        runHealthSystemAdminOrganizationsQuery();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, isHealthSysAdmin]);

  return {
    healthSystemAdminOrganizations: organizations,
    selectedOrg,
    setSelectedOrg,
  };
}

export function useCohortsFilter() {
  const [selectedCohorts, setSelectedCohorts] = useState([]);
  const [cohorts, setCohorts] = useState([]);

  const user = useGetSessionUser();
  const organizationId = user?.organization?.id;

  const { runQuery: runCohortsQuery } = useLazyQuery(() => getCohortsNames(), {
    onSuccess: (data) => {
      const sortedData = sortBy(data, 'name');
      setCohorts(sortedData);
    },
    onError: (error) => {
      log.error('Error fetching cohorts');
      log.error(error);
    },
  });

  useEffect(() => {
    if (organizationId == null) {
      return;
    }
    runCohortsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return {
    cohorts,
    selectedCohorts,
    setSelectedCohorts,
  };
}

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getTeamsByOrg, getFacilitiesByOrg, getOrgByIdAPI } from '@api/api';
import { formatDate, newDate, startOfDate, subtractDate } from '@util/dateFunctions';
import { getLogger } from '@util/logger';

const log = getLogger('filtersSlice');

const monthToDate = {
  start: formatDate(startOfDate(newDate(), 'month'), 'MM/DD/YYYY'),
  end: formatDate(newDate(), 'MM/DD/YYYY'),
};

const previous90Days = {
  start: formatDate(subtractDate(newDate(), 90, 'days'), 'MM/DD/YY'),
  end: formatDate(newDate(), 'MM/DD/YY'),
};

// This is where the global state of all the filters will be kept.
const filtersSlice = createSlice({
  name: 'filters',
  initialState: {
    startDate: formatDate(subtractDate(newDate(), 30, 'days'), 'MM/DD/YYYY'),
    endDate: formatDate(newDate(), 'MM/DD/YYYY'),
    timeFilter: {
      startDate: monthToDate.start,
      endDate: monthToDate.end,
    },
    customDashTimeFilter: {
      startDate: previous90Days.start,
      endDate: previous90Days.end,
    },
    teams: undefined,
    teamFilter: undefined,
    defaultTeam: 'All',
    facilities: undefined,
    facilityFilter: undefined,
    defaultFacility: 'All',
    orgName: undefined,
    orgId: undefined,
    orgFilter: undefined,
    organizations: undefined,
    isHealthSysAdmin: false,
  },
  reducers: {
    setTeams(state, action) {
      state.teams = action.payload;
    },
    setFacilities(state, action) {
      state.facilities = action.payload;
    },
    setOrganizations(state, action) {
      state.organizations = action.payload;
    },
    setTeamFilter(state, action) {
      state.teamFilter = action.payload;
    },
    setFacilityFilter(state, action) {
      state.facilityFilter = action.payload;
    },
    setDefaultTeam(state, action) {
      state.defaultTeam = action.payload;
    },
    setDefaultFacility(state, action) {
      state.defaultFacility = action.payload;
    },
    setOrgName(state, action) {
      state.orgName = action.payload;
    },
    setOrgFilter(state, action) {
      state.orgFilter = action.payload;
    },
    setTimeFilter(state, action) {
      state.timeFilter = action.payload;
    },
    setCustomDashTimeFilter(state, action) {
      state.customDashTimeFilter = action.payload;
    },
    setIsHealthSysAdmin(state, action) {
      state.isHealthSysAdmin = action.payload;
      if (state.isHealthSysAdmin && !state.orgFilter) state.orgFilter = 'All';
    },
  },
});

export default filtersSlice.reducer;

export const {
  setTeams,
  setFacilities,
  setTeamFilter,
  setFacilityFilter,
  setOrgName,
  setDefaultFacility,
  setDefaultTeam,
  setOrgFilter,
  setOrganizations,
  setIsHealthSysAdmin,
  setTimeFilter,
  setCustomDashTimeFilter,
} = filtersSlice.actions;

export const getTeams = (id) => async (dispatch) => {
  try {
    const res = await getTeamsByOrg(id);
    if (res.ok) {
      const resJ = await res.json();
      dispatch(setTeams(resJ));
    } else {
      log.error('error fetching teams');
    }
  } catch (error) {
    log.error(error);
  }
};

export const getFacilities = (id) => async (dispatch) => {
  try {
    const res = await getFacilitiesByOrg(id);
    if (res.ok) {
      const resJ = await res.json();
      dispatch(setFacilities(resJ));
    } else {
      log.error('error fetching facilities');
    }
  } catch (error) {
    log.error(error);
  }
};

export const getOrgName = (id) => async (dispatch) => {
  try {
    const res = await getOrgByIdAPI(id);
    if (res.ok) {
      const resJ = await res.json();
      dispatch(setOrgName(resJ));
    } else {
      log.error('error fetching organizations');
    }
  } catch (error) {
    log.error(error);
  }
};

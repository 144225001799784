import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NavBarItem from './NavBarItem';

/**
 * @param { string } catTitle category title of navigation bar category
 * @param { NAVIGATION_TAB_NAME } catName name of category tab
 * @param { string } root name of the root page we are on, e.g. Settings, Participants, etc.
 * @param { array } subPages an array of all the subpage objects nested under this category
 */
const NavBarCat = ({ catTitle, catName, root, subPages }) => {
  // the current tabName parameter in the live url
  const { tabName } = useParams();
  const history = useHistory();
  // if the tabName parameter in the live url matches the catName of this category tab, then expand its nested pages
  const [isExpanded, setIsExpanded] = useState(tabName === catName);

  // if the tabName changes, then re-check if the tabName matches and collapse category if not
  useEffect(() => {
    setIsExpanded(tabName === catName);
  }, [tabName]);

  const handleClickCat = () => {
    history.push(`/${root}/${catName}`);
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex flex-column ">
      <button
        onClick={handleClickCat}
        title={catTitle}
        type="button"
        style={{ textDecoration: 'none' }}
        className="d-flex flex-row align-items-center w-100 px-3 py-2 text-left border-0 outline-none bg-transparent mb-3 rounded hover-dark-blue"
      >
        <h6
          className={`d-flex justify-content-start align-items-center text-primary ${
            isExpanded ? 'font-weight-bold' : 'font-weight-normal'
          }`}
        >
          {catTitle}
        </h6>
        <ArrowDropDownIcon className={`mt-1 text-primary ${isExpanded ? 'rotate-180deg' : ''}`} />
      </button>
      {isExpanded && (
        <div className="pl-4">
          {subPages.map((item) => {
            return (
              <NavBarItem
                key={item.displayTitle}
                displayTitle={item.displayTitle}
                name={catName}
                subName={item.name}
                root={root}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NavBarCat;

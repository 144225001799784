import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Dropdown, DropdownButton, Button } from 'react-bootstrap';
import FilteredList from '@intus-ui/components/FilteredList';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import EventListPropType from './EventListPropType';

const UserTrackingEventList = ({ trackingEvents, backAction, session, history }) => {
  const [pageFilter, setPageFilter] = useState('All');
  const items = trackingEvents
    .filter((event) => event.trackingSessionId === session.sessionId)
    .filter((event) => pageFilter === 'All' || event.page === pageFilter);
  const eventLogoutAction = items.find((event) => event.action === 'Logout action');
  let sessionEndTime;
  if (eventLogoutAction) {
    sessionEndTime = eventLogoutAction.time;
  }

  const format = [
    {
      valueField: 'page',
      nodeField: 'page',
      md: 3,
      className: 'text-dark',
      sorting: 'none',
      heading: 'Page',
    },
    {
      valueField: 'action',
      nodeField: 'action',
      md: 3,
      className: 'text-dark',
      sorting: 'none',
      heading: ' Action',
    },
    {
      valueField: 'dashIndicators',
      nodeField: 'dashIndicators',
      md: 3,
      className: 'text-dark',
      sorting: 'none',
      heading: ' Dashboard Indicators',
    },
    {
      valueField: 'time',
      nodeField: 'time',
      md: 3,
      sorting: 'none',
      heading: 'Time of Action',
    },
  ];

  const titleNode = (
    <div className="justify-content-between">
      <Button
        className="px-3 py-2 d-flex align-items-center justify-content-center bg-primary text-white "
        onClick={() => {
          backAction(false);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </Button>
    </div>
  );

  const uniquePages = [
    ...new Map(trackingEvents.map((trackingEvent) => [trackingEvent.page, trackingEvent])).values(),
  ];

  const pageFilterButton = (
    <span key="page-filter">
      <DropdownButton
        size="sm"
        onClick={(event) =>
          event.target.id === 'dropdown-button-page-filter' || event.target.id === ''
            ? null
            : setPageFilter(event.target.id)
        }
        id="dropdown-button-page-filter"
        title={pageFilter}
      >
        <Dropdown.Item id="All">All</Dropdown.Item>
        {uniquePages.map((event) => (
          <Dropdown.Item key={event.id} id={event.page}>
            {event.page}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </span>
  );

  // gets patientId from the event page clicked
  const getNumFromStr = (page) => page.match(/\d+/g);

  return (
    <div className="w-100 h-100 d-flex justify-content-between align-items-center flex-column px-3">
      {session.orgName && session.user && (
        <div className="w-100">
          <div className="d-flex justify-content-between mt-2">
            <div className="p-2">
              Organization: <span className="text-blue">{session.orgName}</span>
            </div>
            <div className="p-2">
              User: <span className="text-blue">{session.user}</span>
            </div>
            <div className="p-2">
              Session Start Time: <span className="text-blue">{session.sessiondate}</span>
            </div>
            <div className="p-2">
              Session End Time: <span className="text-blue">{sessionEndTime}</span>
            </div>
          </div>
        </div>
      )}
      <FilteredList
        title={titleNode}
        items={items}
        format={format}
        fieldFilters={['Page']}
        filters={[pageFilterButton]}
        onClick={(item) => {
          if (item.page.includes('details')) {
            const patientId = getNumFromStr(item.page);
            history.replace(`/patient/${patientId}?userTrack`);
          }
        }}
      />
    </div>
  );
};

UserTrackingEventList.propTypes = {
  trackingEvents: PropTypes.arrayOf(EventListPropType.isRequired),
  backAction: PropTypes.func.isRequired,
  session: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

UserTrackingEventList.defaultProps = {
  trackingEvents: [],
};

const mapState = (state) => ({
  trackingEvents: state.admin.trackingEvents,
});

export default withRouter(connect(mapState)(UserTrackingEventList));

import { useState, useEffect, useRef } from 'react';
import { has } from 'lodash';
import { getStringReplacement } from '@util/stringReplacements';
import { getLogger } from '@util/logger';

import { postCohortSearch } from '@api';

import { Button, ButtonGroup, Text } from '@intus-ui';

import produce from 'immer';
import OperatorSelectInput from './OperatorSelectInput';
import FilterGroup from './FilterGroup';
import { scrollToDiv } from './helpers';
import useFilter from './useFilter';

const log = getLogger('CreateFilter');

const CreateFilter = ({
  searchState,
  setSearchState,
  isFixedCohortSearch,
  isInWrapperComponent,
  onApplyFilterLabel,
  customHeader,
}) => {
  const { existingCohort } = searchState;
  const existingFilter = existingCohort?.searchCriteria || searchState?.searchCriteria;
  const scrollableDiv = useRef(null);
  const { isReady, newFilterGroup, singleFilterGroup, operators } = useFilter();
  const [filter, setFilter] = useState(existingFilter || newFilterGroup.searchCriteria);

  const { groups } = filter;
  const [operator, setOperator] = useState(filter.operator || operators[0]);

  useEffect(() => {
    if (!existingFilter) setFilter(newFilterGroup.searchCriteria);
  }, [isReady]);

  const handleAddFilterGroup = () => {
    const newFilter = produce(filter, (draft) => {
      draft.groups.push(singleFilterGroup);
    });
    setFilter(newFilter);
    scrollToDiv(scrollableDiv);
  };

  const handleChangeGroupOperator = (value) => {
    setOperator(value);
    setFilter({ ...filter, groupsOperator: value });
  };

  const handleClearFilter = () => setFilter(newFilterGroup.searchCriteria);

  const validateFilterRows = (flt) => {
    const invalidFilters = [];
    const filterGroups = flt.groups;

    // special case for fixed cohorts: isValid when there are no filters
    const isValidForFixedCohorts =
      isFixedCohortSearch &&
      filterGroups.length === 1 &&
      filterGroups[0]?.conditions[0]?.type === undefined &&
      filterGroups[0]?.conditions[0]?.searchTerm === undefined;

    if (isValidForFixedCohorts) return { invalidFilters, isValid: true };

    filterGroups.forEach(({ conditions: filterRows }, i) => {
      const filterGroupIndex = i;
      filterRows.forEach((filterRow, index) => {
        const filterRowIndex = index;
        const hasSearchTerm = has(filterRow, 'searchTerm');
        const hasLogic = has(filterRow, 'logic');
        if (
          filterRow.type === undefined ||
          (hasSearchTerm && filterRow.searchTerm === undefined) ||
          (hasLogic && filterRow.logic === undefined)
        ) {
          invalidFilters.push({
            filterGroupIndex,
            filterRowIndex,
            filterRow,
          });
        }
      });
    });

    return { invalidFilters, isValid: invalidFilters.length === 0 };
  };

  const { invalidFilters, isValid } = validateFilterRows(filter);

  const handleApplyFilter = async () => {
    if (isValid) {
      const query = { searchCriteria: filter };
      try {
        let res = null;
        if (!hasNoFilters(filter)) {
          const search = await postCohortSearch(query);
          res = await search.json();
        }

        const newSearchState = produce(searchState, (draft) => {
          // Not sure why this is here twice, but we need
          // to keep both this and the filter in sync.
          if (draft.existingCohort != null) {
            draft.existingCohort.searchCriteria = filter.searchCriteria;
          }
          draft.filter = filter;
          draft.data = res;
          draft.searchCriteria = query.searchCriteria;
          draft.view = 'SEARCH_PREVIEW';
        });
        setSearchState(newSearchState);
      } catch (e) {
        log.error(e);
      }
    } else {
      log.error(invalidFilters);
    }
  };

  return (
    <div
      style={{
        ...styles.quad,
        flex: isInWrapperComponent && 1,
        boxShadow: isInWrapperComponent && 'none',
      }}
    >
      {customHeader || (
        <Text color="caption">
          {`Search for ${getStringReplacement('Participant', { lower: true, plural: true })}`}
        </Text>
      )}
      <div
        id="filterGroupWrapper"
        ref={scrollableDiv}
        style={{ ...styles.filterGroupWrapper, flex: isInWrapperComponent && 1 }}
      >
        {groups.map(({ operator: op, conditions: cdt }, index) => (
          <div key={`${index + 1}`} style={styles.filterGroup}>
            <div style={styles.operators}>
              {index === 0 ? (
                <Text type="subtitle">Whose</Text>
              ) : (
                <OperatorSelectInput
                  index={index}
                  operator={operator}
                  handleChangeGroupOperator={handleChangeGroupOperator}
                />
              )}
            </div>
            <FilterGroup
              index={index}
              operator={op}
              conditions={cdt}
              setFilter={setFilter}
              filter={filter}
              invalidFilters={invalidFilters}
              isValid={isValid}
              isSingleFilterGroup={groups.length <= 1}
            />
          </div>
        ))}
        <Button
          style={{ width: 'fit-content' }}
          secondary
          disabled={!isValid}
          onClick={() => handleAddFilterGroup()}
          hoverText="+ add search criteria"
        >
          + add search criteria
        </Button>
      </div>

      <ButtonGroup>
        <Button
          name="Clear Filters"
          style={styles.button}
          onClick={handleClearFilter}
          textOnly
          hoverText="Clear filters button"
        />
        <Button
          name={onApplyFilterLabel || 'Show Results'}
          style={styles.button}
          onClick={handleApplyFilter}
          hoverText="Apply filters button"
          disabled={!isValid}
        />
      </ButtonGroup>
    </div>
  );
};

const styles = {
  quad: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    borderRadius: 20,
    backgroundColor: 'white',
    flex: 1,
  },
  button: {
    whiteSpace: 'nowrap',
  },
  buttonAutoWidth: {
    width: 'fit-content',
  },
  filterGroupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'visible',
    gap: 12,
  },
  filterGroup: {
    display: 'flex',
  },
  operators: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 120,
    padding: '15px 10px 10px 10px',
  },
};

export default CreateFilter;

function hasNoFilters(filter) {
  if (filter == null) {
    return true;
  }
  if (filter.groups == null || filter.groups.length === 0) {
    return true;
  }

  // If we only have 1 condition and it's null then we have no filters.
  if (filter?.groups?.length === 1 && filter.groups[0].conditions?.length === 1) {
    if (filter.groups[0].conditions[0].type == null) {
      return true;
    }
  }

  return false;
}

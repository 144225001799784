import React from 'react';
import PropTypes from 'prop-types';
import { censusModalPropType } from 'Homes/modalPropTypes/censusModalPropType';
import Quad from 'Dashboard/Quad';
import BigTitle from 'Dashboard/QuadContent/BigTitle';
import TextData from 'Dashboard/QuadContent/TextData';
import { SpinnerOrError } from '@intus-ui';

const YearsEnrolled = ({ subcards, error }) => {
  let content = (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <SpinnerOrError error={error} />
    </div>
  );
  if (subcards) {
    content = (
      <div className="w-100 h-100 d-flex flex-row overflow-scroll">
        <div className="w-50 pt-3 pr-3 d-flex flex-column align-items-center justify-content-center border-right">
          <BigTitle title="Avg:" />
          <div>
            <TextData
              data={(Math.round(subcards.averageYearsEnrolled * 10) / 10).toString()}
              subtext={{ text: 'years', vertical: true }}
            />
          </div>
        </div>
        <div className="w-50 pt-3 pl-5 d-flex flex-column align-items-center justify-content-center">
          <BigTitle title="Max:" />
          <div>
            <TextData
              data={(Math.round(subcards.maxYearsEnrolled * 10) / 10).toString()}
              subtext={{ text: 'years', vertical: true }}
            />
          </div>
        </div>
      </div>
    );
  }
  return <Quad title="Years Enrolled">{content}</Quad>;
};

YearsEnrolled.propTypes = {
  subcards: censusModalPropType,
  error: PropTypes.string,
};

YearsEnrolled.defaultProps = {
  subcards: undefined,
  error: undefined,
};

export default YearsEnrolled;

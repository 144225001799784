import { createRememberMeTokenKey } from '@api/TwoFactorAuthentication';
import { post } from '@api/apiMethods';
import { findLocalData } from '@util';
import { LoggedInUser } from 'types/user/loggedInUser';

type LoginUserParams = {
  email: string;
  password: string;
  trackingInfo: Record<string, unknown>;
};

type LoginUserReturn =
  | LoggedInUser
  | {
      mustConfigureTwoFactorAuthentication: boolean;
      jwt: string;
    }
  | {
      requiresTwoFactorAuthentication: boolean;
      allowsRememberMe: boolean;
      jwt: string;
    };

/**
 * Attempts to log the user in with their email/password.
 *
 * Returns an error code if they fail to login:
 * 401 = Bad username/password
 * 429 = Too many failed login attempts
 * Anything else = Something went wrong on the server, show a generic error message.
 */
export function loginUser(data: LoginUserParams) {
  return post<LoginUserReturn>(
    'users/login',
    { ...data, rememberMeToken: findLocalData(createRememberMeTokenKey(data.email)) },
    true
  );
}

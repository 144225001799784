/* eslint-disable react/no-unstable-nested-components */
import { useState } from 'react';
import { Switch, Redirect, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';

import { getSessionUser } from '@util/session';

import { layout } from '@intus-ui';
import SearchCreate from './SearchCreate';
import CohortsList from './CohortsList';
import CohortFocusView from './CohortFocusView';
import SideMenu from './SideMenu';
import CohortTypeSelection from './FixedCohorts/CohortTypeSelection';
import CreateFixedCohort from './FixedCohorts/CreateFixedCohort';
import CreateRollingCohort from './RollingCohorts/CreateRollingCohort';
import EditRollingCohort from './RollingCohorts/EditRollingCohort';
import EditFixedCohort from './FixedCohorts/EditFixedCohorts';

const AdvancedCohort = () => {
  const { organizationId } = getSessionUser();

  const defaultSearchState = {
    filter: null,
    data: null,
    view: 'SEARCH_CREATE',
  };

  const [searchState, setSearchState] = useState(defaultSearchState);

  function resetSearchState() {
    setSearchState(defaultSearchState);
  }

  const history = useHistory();

  const { url } = useRouteMatch();

  const { search } = useLocation();

  const viewingFixedCohorts = url.includes('fixed') || search.includes('fixed');

  const menuItems = [];

  menuItems.push({
    name: 'Search & Create',
    active: url.includes('search'),
    onClick: () => {
      resetSearchState();
      history.push('/cohorts/select');
    },
  });

  menuItems.push({
    name: 'Personal Cohorts',
    active: url.includes('personal') && !viewingFixedCohorts,
    onClick: () => {
      resetSearchState();
      history.push('/cohorts/personal');
    },
  });

  menuItems.push({
    name: 'Shared Cohorts',
    active: url.includes('shared') && !viewingFixedCohorts,
    onClick: () => {
      resetSearchState();
      history.push({
        pathname: '/cohorts/shared',
        state: { isShared: true },
      });
    },
  });

  // SCAN OVERRIDE
  if (organizationId !== 200) {
    menuItems.push({
      name: 'Action Cohorts',
      active: url.includes('action'),
      onClick: () => {
        resetSearchState();
        history.push('/cohorts/action');
      },
    });
  }

  return (
    <div
      style={{
        ...layout.container,
        flexDirection: 'row',
        height: '100%',
        backgroundColor: '#F6F7FA',
        paddingTop: 15,
        gap: 20,
      }}
    >
      {!url.includes('select') &&
        (!url.includes('fixed') || url === '/cohorts/fixed') &&
        (!url.includes('rolling') || url === '/cohorts/rolling') && <SideMenu items={menuItems} />}

      <div style={layout.container}>
        <Switch>
          <Route exact path="/cohorts/search">
            <SearchCreate searchState={searchState} setSearchState={setSearchState} />
          </Route>
          <Route exact path="/cohorts/select">
            <CohortTypeSelection />
          </Route>
          <Route exact path="/cohorts/fixed/create">
            <CreateFixedCohort />
          </Route>
          <Route exact path="/cohorts/fixed/:cohortId/edit">
            <EditFixedCohort />
          </Route>
          <Route exact path="/cohorts/rolling/create">
            <CreateRollingCohort />
          </Route>
          <Route exact path="/cohorts/rolling/:cohortId/edit">
            <EditRollingCohort />
          </Route>
          <Route exact path="/cohorts/:cohortType">
            <CohortsList searchState={searchState} setSearchState={setSearchState} />
          </Route>
          <Route exact path="/cohorts/:cohortType/:cohortId/edit">
            <SearchCreate
              searchState={{ ...searchState, editing: true }}
              setSearchState={setSearchState}
            />
          </Route>
          <Route exact path="/cohorts/:cohortType/:cohortId/:tab">
            <CohortFocusView searchState={searchState} setSearchState={setSearchState} />
          </Route>
          <Redirect to="/cohorts/personal" />
        </Switch>
      </div>
    </div>
  );
};

export default AdvancedCohort;

/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { CENSUS_DASH_ID } from 'Dashboard/premiumDashNames';
import {
  createDashboardAPI,
  createOrgDashboardAPI,
  deleteDashboardAPI,
  deleteOrgDashboardAPI,
  updateDashboardAPI,
  updateOrgDashboardAPI,
} from '@api/api';
import { getLogger } from '@util/logger';
import { setCurrentDash } from '@global-state/redux/dashboardSlice';
import { setShowNotification } from '@global-state/redux/notificationSlice';
import { dateDifference } from '@util/dateFunctions';
import { getSessionUser, setSessionUser } from '@util/session';
import {
  indicatorStatusUpdate,
  indicatorsReceived,
  updateInitialIndicators,
} from './customDashIndicatorsSlice';
import {
  removeSpreadsheetModalDashboard,
  updateSpreadsheetModalDashboard,
} from '../spreadsheet/spreadsheetModalSlice';
import { CUSTOM_DASH, ORG_DASH, PREMIUM_DASH } from '../dashDataPropTypes/currentDashPropType';

const log = getLogger('customDashboardSlice');

const customDashboardAdapter = createEntityAdapter({
  sortComparer: (a, b) => dateDifference(b.createdAt, a.createdAt),
});

const customDashboardSlice = createSlice({
  name: 'customDashboard',
  initialState: customDashboardAdapter.getInitialState({
    status: 'idle',
  }),
  reducers: {
    dashboardAdded: customDashboardAdapter.addOne,
    dashboardUpdated: customDashboardAdapter.updateOne,
    dashboardDeleted: customDashboardAdapter.removeOne,
    dashboardStatusUpdate: (state, action) => {
      state.status = action.payload;
    },
    dashboardsReceived: (state, action) => {
      if (state.status === 'loading') {
        customDashboardAdapter.setAll(state, action.payload);
        state.status = 'idle';
      }
    },
  },
});

export default customDashboardSlice.reducer;

export const {
  dashboardAdded,
  dashboardUpdated,
  dashboardDeleted,
  dashboardStatusUpdate,
  dashboardsReceived,
} = customDashboardSlice.actions;

export const {
  selectById: selectDashboardById,
  selectIds: selectDashboardIds,
  selectAll: selectAllDashboards,
} = customDashboardAdapter.getSelectors((state) => state.customDashboard);

/**
 * Selector that safely gets a dashboard name by id (will not error due to the optional chaining,
 * whereas we could get an error in the case where the component makes use of the
 * selectDashboardById selector but entities has not been initialized yet).
 *
 * @param {Number} id the id of the dashboard we are looking for
 */
export const selectDashNameById = (id) =>
  createSelector(
    (state) => state?.customDashboard?.entities[id]?.name,
    (name) => name
  );

export const selectAllCustomDashboards = createSelector(selectAllDashboards, (allDashboards) =>
  allDashboards.filter(({ orgDash }) => !orgDash)
);

export const selectAllOrgDashboards = createSelector(selectAllDashboards, (allDashboards) =>
  allDashboards.filter(({ orgDash }) => orgDash)
);

/**
 * This selector calls the selectAllDashboards selector to retrieve dashboard entities
 * in their sorted order and returns an array of the dashboard names of these entitles.
 * This saves us having to do this calculation in the components that use this selector.
 */
export const selectCustomDashNames = createSelector(selectAllCustomDashboards, (allDashboards) =>
  allDashboards.map(({ name }) => name)
);

export const selectOrgDashNames = createSelector(selectAllOrgDashboards, (allDashboards) =>
  allDashboards.map(({ name }) => name)
);

/**
 * initializes customDashboard slice with normalized state.
 */
export const initializeCustomDashboard = () => (dispatch) => {
  const { customDashboards, organization } = getSessionUser();
  dispatch(dashboardStatusUpdate('loading'));
  const orgDashboards = organization.customDashboards.map((item) => ({
    ...item,
    orgDash: true,
  }));
  dispatch(dashboardsReceived(customDashboards.concat(orgDashboards)));
};

/**
 * creates a custom dashboard.
 * @param {Object} body the body to send over
 * @param {Boolean} orgDash whether or not this is an org dash
 */
export const createDashboard = (body, orgDash) => async (dispatch) => {
  dispatch(dashboardStatusUpdate('loading'));
  try {
    const userId = getSessionUser().id;
    const orgId = getSessionUser().organization.id;
    const res = orgDash
      ? await createOrgDashboardAPI(orgId, body)
      : await createDashboardAPI(userId, body);
    if (res.ok) {
      const { id, createdAt, indicators, name } = await res.json();
      const newDash = {
        id,
        createdAt,
        indicators,
        name,
        orgDash,
      };
      dispatch(dashboardAdded(newDash));
      dispatch(indicatorStatusUpdate('loading'));
      dispatch(indicatorsReceived(indicators));
      dispatch(setCurrentDash({ id, type: orgDash ? ORG_DASH : CUSTOM_DASH }));
      dispatch(dashboardStatusUpdate('idle'));
      const user = { ...getSessionUser() };
      if (orgDash) user.organization.customDashboards.push(newDash);
      else user.customDashboards.push(newDash);
      setSessionUser(user);
    } else {
      log.error('could not create dashboard');
      dispatch(dashboardStatusUpdate('error'));
      dispatch(
        setShowNotification(
          'Error Creating Dashboard. Please check your network connection and refresh.'
        )
      );
    }
  } catch (e) {
    log.error(e);
    dispatch(dashboardStatusUpdate('error'));
    dispatch(
      setShowNotification(
        'Error Creating Dashboard. Please check your network connection and refresh.'
      )
    );
  }
};

/**
 * Updates a custom dashboard when it is saved.
 * @param {Object} updateInfo info regarding the update
 */
export const saveDashboard = (updateInfo) => async (dispatch) => {
  dispatch(dashboardStatusUpdate('loading'));
  try {
    const { orgDash } = updateInfo;
    const userId = getSessionUser().id;
    const orgId = getSessionUser().organization.id;
    const res = orgDash
      ? await updateOrgDashboardAPI(orgId, updateInfo.id, updateInfo.changes)
      : await updateDashboardAPI(userId, updateInfo.id, updateInfo.changes);
    if (res.ok) {
      dispatch(updateSpreadsheetModalDashboard(updateInfo));
      dispatch(dashboardUpdated(updateInfo));
      dispatch(updateInitialIndicators());
      dispatch(dashboardStatusUpdate('idle'));
      const resJ = await res.json();
      const user = { ...getSessionUser() };
      if (orgDash) {
        user.organization.customDashboards = user.organization.customDashboards.map((item) =>
          item.id === updateInfo.id ? resJ : item
        );
      } else {
        user.customDashboards = user.customDashboards.map((item) =>
          item.id === updateInfo.id ? resJ : item
        );
      }
      setSessionUser(user);
    } else {
      dispatch(dashboardStatusUpdate('error'));
      log.error('could not update dashboard');
      dispatch(
        setShowNotification(
          'Error Updating Dashboard. Please check your network connection and refresh.'
        )
      );
    }
  } catch (e) {
    dispatch(dashboardStatusUpdate('error'));
    log.error(e);
    dispatch(
      setShowNotification(
        'Error Updating Dashboard. Please check your network connection and refresh.'
      )
    );
  }
};

/**
 * Deletes a dashboard.
 * @param {Object} dashInfo the info of the dashboard to delete
 */
export const deleteDashboard = (dashInfo) => async (dispatch) => {
  dispatch(dashboardStatusUpdate('loading'));
  try {
    const userId = getSessionUser().id;
    const orgId = getSessionUser().organization.id;
    const res =
      dashInfo.type === ORG_DASH
        ? await deleteOrgDashboardAPI(orgId, dashInfo.id)
        : await deleteDashboardAPI(userId, dashInfo.id);
    if (res.ok) {
      dispatch(removeSpreadsheetModalDashboard(dashInfo));
      dispatch(setCurrentDash({ id: CENSUS_DASH_ID, type: PREMIUM_DASH }));
      dispatch(dashboardDeleted(dashInfo.id));
      dispatch(dashboardStatusUpdate('idle'));
      const user = { ...getSessionUser() };
      if (dashInfo.type === ORG_DASH) {
        const indexToDelete = user.organization.customDashboards
          .map(({ id }) => id)
          .indexOf(dashInfo.id);
        if (indexToDelete >= 0) user.organization.customDashboards.splice(indexToDelete, 1);
      } else {
        const indexToDelete = user.customDashboards.map(({ id }) => id).indexOf(dashInfo.id);
        if (indexToDelete >= 0) user.customDashboards.splice(indexToDelete, 1);
      }
      setSessionUser(user);
    } else {
      dispatch(dashboardStatusUpdate('error'));
      log.error('could not delete dashboard');
      dispatch(
        setShowNotification(
          'Error Deleting Dashboard. Please check your network connection and refresh.'
        )
      );
    }
  } catch (e) {
    log.error(e);
    dispatch(
      setShowNotification(
        'Error Deleting Dashboard. Please check your network connection and refresh.'
      )
    );
  }
};

import { useState } from 'react';

import { formatDate } from '@util/dateFunctions';

import Text from '../Text';
import Icon from '../Icon';
import styles from './styles';

import Tab from './Tab';
import './style.css';

const ByQuarterView = ({ handleOnChange, state }) => {
  const currentYear = new Date().getFullYear();
  const [displayedYear, setDisplayedYear] = useState(currentYear);
  const [currentSelection, setCurrentSelection] = useState(null);

  const isYearInFuture = displayedYear >= currentYear;

  const getQuarterEndDate = (endDate) => {
    // returns current date if quarter's endDate is greater than today
    if (endDate.getTime() > new Date().getTime()) {
      return new Date();
    }
    return endDate;
  };

  const getRangeByQuarter = (quarterString) => {
    switch (quarterString) {
      case 'Q1':
        return {
          startDate: new Date(`January 1, ${displayedYear}`),
          endDate: getQuarterEndDate(new Date(`March 31, ${displayedYear}`)),
        };
      case 'Q2':
        return {
          startDate: new Date(`April 1, ${displayedYear}`),
          endDate: getQuarterEndDate(new Date(`June 30, ${displayedYear}`)),
        };
      case 'Q3':
        return {
          startDate: new Date(`July 1 , ${displayedYear}`),
          endDate: getQuarterEndDate(new Date(`September 30, ${displayedYear}`)),
        };
      case 'Q4':
        return {
          startDate: new Date(`October 1, ${displayedYear}`),
          endDate: getQuarterEndDate(new Date(`December 31, ${displayedYear}`)),
        };
      default:
        return {
          startDate: new Date(),
          endDate: new Date(),
        };
    }
  };

  const isFutureQuarter = (quarter) => {
    const range = getRangeByQuarter(quarter);
    const isFutureDate = new Date(range.startDate) > new Date().getTime();
    return isFutureDate;
  };

  const generateTitle = (quarter, year) => {
    const string = `${quarter}  \`${year && year.toString().slice(-2)}`;
    return string;
  };

  const handleSelectQuarter = (quarter) => {
    const newRange = getRangeByQuarter(quarter);
    // UI Update
    setCurrentSelection(generateTitle(quarter, displayedYear));
    // Data update
    handleOnChange({ selection: { ...newRange, key: 'selection' } });
  };

  return (
    <div style={styles.byQuarterView}>
      <div style={styles.byQuarterViewHeader}>
        <input
          disabled
          className="pickerInput"
          type="text"
          value={formatDate(state[0].startDate, 'MM/DD/YYYY')}
        />

        <input
          disabled
          type="text"
          className="pickerInput"
          value={formatDate(state[0].endDate, 'MM/DD/YYYY')}
        />
      </div>
      <div style={styles.yearPickerView}>
        <Icon
          name="CaretLeft"
          color="navy"
          hoverColor="navy"
          onClick={() => setDisplayedYear(displayedYear - 1)}
        />
        <Text type="subtitle">{displayedYear}</Text>
        <Icon
          name="CaretRight"
          color={isYearInFuture ? '#9D9D9D' : 'navy'}
          hoverColor={isYearInFuture ? '#9D9D9D' : 'navy'}
          onClick={isYearInFuture ? null : () => setDisplayedYear(displayedYear + 1)}
        />
      </div>
      <div style={styles.quartersView}>
        <Tab
          isActive={currentSelection === generateTitle('Q1', displayedYear)}
          onClick={() => handleSelectQuarter('Q1')}
          title={generateTitle('Q1', displayedYear)}
          disabled={isFutureQuarter('Q1')}
        />
        <Tab
          isActive={currentSelection === generateTitle('Q2', displayedYear)}
          onClick={() => handleSelectQuarter('Q2')}
          title={generateTitle('Q2', displayedYear)}
          disabled={isFutureQuarter('Q2')}
        />
        <Tab
          isActive={currentSelection === generateTitle('Q3', displayedYear)}
          onClick={() => handleSelectQuarter('Q3')}
          title={generateTitle('Q3', displayedYear)}
          disabled={isFutureQuarter('Q3')}
        />
        <Tab
          isActive={currentSelection === generateTitle('Q4', displayedYear)}
          onClick={() => handleSelectQuarter('Q4')}
          title={generateTitle('Q4', displayedYear)}
          disabled={isFutureQuarter('Q4')}
        />
      </div>
    </div>
  );
};

export default ByQuarterView;

import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

// Do not add feature-specific code to this component. It should remain as general as possible.

// TabPageBody contains the styling and layout for the body in tab pages (ex. Ppt, Cohorts, Dashboards)
const TabPageBody = ({ children }) => (
  <Row className="w-100 pl-4 overflow-hidden" style={{ flexGrow: 2 }}>
    {children}
  </Row>
);

TabPageBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TabPageBody;

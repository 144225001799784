/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import useHover from '@intus-ui/hooks/useHover';

import './RadioButton.scss';
import Text from '../Text';

const RadioButton = ({
  // TODO: allow option to support label and value keys
  option,
  label,
  selected,
  setSelected,
  size = 'small',
  labelPosition,
  onChange,
  disabled,
}) => {
  const [hoverRef, isHovered] = useHover();

  // TODO DETERMINE MEDIUM/LARGE DIMENSIONS
  const dimensions = {
    small: [17, 13, 7],
    medium: [17, 13, 7],
    large: [17, 13, 7],
  };

  let dimensionValues = dimensions[size];
  if (!dimensionValues) dimensionValues = dimensions.small;

  let radioFillClass = 'radio-fill-disabled';
  let radioOutlineClass = 'radio-outline-selected';
  if (isHovered) radioFillClass = 'radio-fill-hover';
  if (disabled) radioOutlineClass = 'radio-outline-disabled';
  if (selected === option) {
    radioFillClass = 'radio-fill-selected';
  }

  const handleChange = () => {
    setSelected(option);
  };
  if (disabled === true) {
    setSelected('not an option');
  }

  return (
    <div
      ref={hoverRef}
      className={
        labelPosition === 'horizontal' ? 'radio-label-wrapper' : 'radio-label-wrapper flex-column'
      }
      // id={`${name}-${option}`}
      onClick={() => {
        handleChange();
        onChange(option);
      }}
      style={{ cursor: isHovered ? 'pointer' : 'default' }}
    >
      <div
        className={isHovered && !disabled ? 'radio-wrapper-hover' : 'radio-wrapper'}
        style={{
          width: `${dimensionValues[0]}px`,
          height: `${dimensionValues[0]}px`,
        }}
      >
        <div
          className={radioOutlineClass}
          // className="radio-outline-disabled"
          style={{
            width: `${dimensionValues[1]}px`,
            height: `${dimensionValues[1]}px`,
          }}
        >
          <div
            className={radioFillClass}
            style={{
              width: `${dimensionValues[2]}px`,
              height: `${dimensionValues[2]}px`,
            }}
          />
        </div>
      </div>

      <Text color={disabled ? 'caption' : 'body'}>{label || option}</Text>
    </div>
  );
};

export default RadioButton;

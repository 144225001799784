import { useEffect, useState } from 'react';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';

import { getLogger } from '@util/logger';
import { capitalizeFirst } from '@util/utilFunctions';

import {
  Container,
  Text,
  SpinnerOrError,
  useParticipantFilters,
  TeamFacilityFilter,
} from '@intus-ui';
import CohortCard from './CohortCard';
import ActionCohortCard from './ActionCohortCard';
import { fetchCohorts } from './helpers';
import SuccessModal from './SuccessModal';

const log = getLogger('CohortsList');

const CohortsList = () => {
  const { url } = useRouteMatch();
  const { cohortType } = useParams();
  const history = useHistory();

  const [cohorts, setCohorts] = useState();
  const [fetchError, setFetchError] = useState();

  const newCohort = history?.location?.state;
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(newCohort?.scrollIntoView);
  const query =
    cohortType === 'action' || cohortType === 'fixed'
      ? null // shared is irrelevant for `GET /billingCohorts`
      : {
          shared: cohortType === 'shared',
        };

  const { selectedFilters } = useParticipantFilters();

  useEffect(() => {
    setCohorts(null);
    fetchCohorts({
      cohortType,
      query,
      filters: selectedFilters,
    })
      .then(({ cohorts: cohortsList }) =>
        setCohorts(sortBy(cohortsList, (c) => c.name.toLowerCase()))
      )
      .catch((e) => {
        setFetchError('Internal system error - unable to fetch data.');
        log.error(e);
      });
  }, [url, selectedFilters]);

  const Card = cohortType === 'action' ? ActionCohortCard : CohortCard;
  const title = `${capitalizeFirst(cohortType)} Cohorts`;
  const id = cohortTypeToID[cohortType];

  if (!cohorts) return <SpinnerOrError error={fetchError} />;

  return (
    <Container style={styles.container}>
      <SuccessModal
        cohort={newCohort}
        isOpen={successModalIsOpen}
        setIsOpen={setSuccessModalIsOpen}
      />
      <div style={styles.subHeader}>
        <Text id={id} type="title" color="navy">
          {title}
        </Text>

        {cohorts.length > 0 && <TeamFacilityFilter isCohortsPage />}
      </div>

      {cohorts.length === 0 && (
        <SpinnerOrError style={{ marginTop: -90 }} error={`No ${title} found.`} />
      )}

      {cohorts.length > 0 && (
        <div
          style={{
            ...styles.content,
            ...(cohortType === 'action'
              ? { gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))' }
              : {}),
          }}
        >
          {cohorts.map((cohort) => {
            return (
              <Card
                key={JSON.stringify(cohort)}
                cohort={cohort}
                cohortType={cohort.shared ? 'shared' : 'personal'}
              />
            );
          })}
        </div>
      )}
    </Container>
  );
};

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 10,
  },
  subHeader: {
    display: 'flex',
    padding: '0px, 0px, 0px, 10px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr))',
    flex: '1 1 0',
    minHeight: 0,
    overflowY: 'auto',
    gap: 20,
    padding: '5px 20px 20px 5px',
  },
};

/**
 * A mapping of each cohort type to an ID to display near the title
 * This is used to display a link near the title in Appcues so if these are changed please check with Product.
 */
const cohortTypeToID = {
  action: 'action-cohorts-title',
  shared: 'shared-cohorts-title',
  personal: 'personal-cohorts-title',
};

export default CohortsList;

import PropTypes from 'prop-types';
import acuityLevelPropType from '../acuity/acuityLevelPropType';

const patientDetailPatientPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  // patientNotes: PropTypes.string,
  phoneNumber: PropTypes.string,
  dateOfBirth: PropTypes.string,
  // address: PropTypes.shape({
  //   city: PropTypes.string,
  //   secondaryAddress: PropTypes.string,
  //   state: PropTypes.string,
  //   street: PropTypes.string,
  //   zip: PropTypes.number,
  // }),
  acuityLevels: PropTypes.arrayOf(acuityLevelPropType.isRequired),
  team: PropTypes.string,
  organizationName: PropTypes.string,
});

export default patientDetailPatientPropType;

import { chain } from 'lodash';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getLogger } from '@util/logger';
import { isSameOrAfterDate, isSameOrBeforeDate, newDate, subtractDate } from '@util/dateFunctions';

import { getPatientFallsAnalysis } from '@api';

import { layout, SpinnerOrError } from '@intus-ui';
import FallAnalysisSidebar from './Sidebar';
import FallsChartContainer from './Charts';
import Header from './Header';
import {
  formatTimeData,
  formatSeverityData,
  formatActivityData,
  formatLocationData,
  getSortedTrendData,
} from './helpers/formatDataHelpers';

const log = getLogger('FallsAnalysis');

const FallAnalysis = () => {
  const { patientId } = useParams();
  const [dateFilter, setDateFilter] = useState({
    start: subtractDate(newDate(), 6, 'month'),
    end: newDate(),
    displayText: 'in the past 6 months',
  });
  const [overallMaxValue, setOverallMaxValue] = useState(0);
  const [ready, setReady] = useState(false);
  const [allFalls, setAllFalls] = useState([]);
  const [currentMedications, setCurrentMedications] = useState([]);

  const [fetchError, setFetchError] = useState();

  // FETCH FORMATTED FALLS ANALYSIS DATA FROM API
  useEffect(() => {
    const fetchData = async () => {
      const data = await getPatientFallsAnalysis(patientId);
      setReady(true);

      if (data) {
        setAllFalls(data.incidents);
        setCurrentMedications(data.currentMedications);
      }
    };

    fetchData().catch((e) => {
      setFetchError('System error - unable to fetch data.');
      log.error(e);
    });
  }, []);

  const updateOverallMax = (item) => {
    let value;

    if ('value' in item) value = item.value;
    else {
      // ACTIVITY DATA DOES NOT HAVE A VALUE, INSTEAD SUM THE COMPONENTS
      value = chain(item).omit('id').values().sum().value();
    }

    if (value > overallMaxValue) {
      setOverallMaxValue(value);
    }
  };

  const checkOverallMax = () => {
    // Scan values of each graph to determine an overall max across all 4
    if (locationData?.length && locationData[1]) {
      locationData[1].forEach(updateOverallMax);
    }
    if (activityData?.length && activityData[0]) {
      activityData[0].forEach(updateOverallMax);
    }
    if (severityData?.length) {
      severityData.forEach(updateOverallMax);
    }
    if (timeData?.length) {
      timeData.forEach(updateOverallMax);
    }
  };

  // Set an initial state for filtered falls (filtered by date)
  const [filteredFalls, setFilteredFalls] = useState(
    allFalls?.filter((item) => isSameOrAfterDate(item.date, dateFilter.start)) || []
  );

  // Getting fall data broken down by property
  const locationData = formatLocationData(filteredFalls);
  const timeData = formatTimeData(filteredFalls);
  const activityData = formatActivityData(filteredFalls);
  const severityData = formatSeverityData(filteredFalls);

  checkOverallMax();

  // Getting fall trends for each property for the sidebar, sorted from largest to smallest trend.
  const sortedData = getSortedTrendData(locationData[1], activityData[2], severityData, timeData);

  useEffect(() => {
    setOverallMaxValue(0);

    // IF NO START DATE, SET FILTERED FALLS TO ALL FALLS
    if (dateFilter.start === null && dateFilter.start !== undefined) {
      setFilteredFalls(allFalls);
    } else {
      setFilteredFalls(
        allFalls?.filter((item) => {
          return (
            isSameOrAfterDate(item.date, dateFilter.start, 'day') &&
            isSameOrBeforeDate(item.date, dateFilter.end, 'day')
          );
        })
      );
    }

    checkOverallMax();
  }, [dateFilter, allFalls]);

  return (
    <div id="tabContentContainer" style={{ ...layout.container, flexDirection: 'row', gap: 30 }}>
      {ready && !fetchError ? (
        <>
          <div id="fluidContentContainer" style={{ ...layout.container }}>
            <Header setDateFilter={setDateFilter} dateFilter={dateFilter} headerText="Falls" />
            {filteredFalls?.length === 0 ? (
              <div style={layout.centeredContentContainer}>
                <b>No falls for the given time period</b>
              </div>
            ) : (
              <FallsChartContainer
                locationData={locationData}
                activityData={activityData}
                severityData={severityData}
                timeData={timeData}
                maxValue={overallMaxValue > 0 ? overallMaxValue : 'auto'}
              />
            )}
          </div>
          <FallAnalysisSidebar
            fallData={filteredFalls}
            sortedData={sortedData}
            timeRange={dateFilter.displayText}
            currentMedications={currentMedications}
          />
        </>
      ) : (
        <SpinnerOrError error={fetchError} />
      )}
    </div>
  );
};

export default FallAnalysis;

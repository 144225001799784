import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'react-bootstrap';
import CostTable from 'Dashboard/Tables/CostTable';

const ModalChart = ({ data, maxRows }) => {
  const sortCosts = (items) => {
    const dataToArray = Object.entries(items);
    dataToArray.sort((a, b) => {
      let comparison = 0;
      comparison = a[1] - b[1];
      return -comparison;
    });
    return dataToArray;
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {data ? (
        <Col className="h-100 w-100">
          <CostTable data={sortCosts(data)} maxRows={maxRows} />
        </Col>
      ) : null}
    </>
  );
};

ModalChart.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  maxRows: PropTypes.number,
};

ModalChart.defaultProps = {
  maxRows: undefined,
};

export default ModalChart;

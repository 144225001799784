/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getLogger } from '@util/logger';

import { getAllJobTypes, getAllHomePageBoxes, getOrgByIdAPI, postPatientListAPI } from '@api/api';
import { setDashError } from '@global-state/redux/dashboardSlice';

const log = getLogger('welcomeSlice');

const welcomeSlice = createSlice({
  name: 'welcome',
  initialState: {
    page: 0,
    jobTypes: undefined,
    homePageBoxes: undefined,
    orgName: undefined,
    orgId: undefined,
    welcomeFlowModal: false,
    pinnedList: undefined,
    teams: undefined,
  },
  reducers: {
    setPage(state, action) {
      state.page = action.payload;
    },
    setJobTypes(state, action) {
      state.jobTypes = action.payload;
    },
    setPinnedList(state, action) {
      state.pinnedList = action.payload;
    },
    setHomePageBoxes(state, action) {
      state.homePageBoxes = action.payload;
    },
    setOrgName(state, action) {
      state.orgName = action.payload;
    },
    setOrgId(state, action) {
      state.orgId = action.payload;
    },
    setWelcomeFlowModal(state, action) {
      state.welcomeFlowModal = action.payload;
    },
  },
});

export default welcomeSlice.reducer;

export const {
  setPage,
  setJobTypes,
  setHomePageBoxes,
  setTeams,
  setFacilities,
  setOrganizations,
  setTeamFilter,
  setFacilityFilter,
  setOrgName,
  setOrgId,
  setWelcomeFlowModal,
  setPinnedList,
} = welcomeSlice.actions;

export const getJobTypes = () => async (dispatch) => {
  const res = await getAllJobTypes();
  if (res.ok) {
    const json = await res.json();
    dispatch(setJobTypes(json));
  }
};

export const getHomePageBox = () => async (dispatch) => {
  const res = await getAllHomePageBoxes();
  if (res.ok) {
    const json = await res.json();
    dispatch(setHomePageBoxes(json));
  }
};

// This is only used in Welcome.jsx
export const getOrgName = (id) => async (dispatch) => {
  try {
    const res = await getOrgByIdAPI(id);
    if (res.ok) {
      const resJ = await res.json();
      dispatch(setOrgName(resJ));
    } else {
      log.error('error fetching teams');
    }
  } catch (error) {
    log.error(error);
  }
};

const formatPatient = (patient) => ({
  id: patient.id,
  name: `${patient.firstName} ${patient.lastName}`,
  memberId: patient.memberId,
  riskGroup: (patient.acuityLevels[0] || {}).riskGroup,
  acuityScore: (patient.acuityLevels[0] || {}).acuityScore,
  team: patient.team,
  facility: patient.facility,
  organizationId: patient.organizationId,
});

export const getPinnedPatientList = (idList) => async (dispatch) => {
  try {
    const res = await postPatientListAPI(idList);
    if (res.ok) {
      const json = await res.json();
      dispatch(setPinnedList(json.map((patient) => formatPatient(patient))));
    } else {
      log.error(res);
    }
  } catch (err) {
    dispatch(setDashError('Could not fetch data.'));
    log.error(err);
  }
};

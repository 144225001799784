import React, { useState } from 'react';
import '@intus-ui/styles/Settings.scss';
import '@intus-ui/styles/Utilities.scss';

import { Row } from 'react-bootstrap';
import adminPreview from 'assets/userGuides/admin-guide.jpg';
import clinicPreview from 'assets/userGuides/clinician-guide.jpg';
import nonClinicPreview from 'assets/userGuides/non-clinical-guide.jpg';
import GuidePreview from './GuidePreview';

const UserGuides = () => {
  const [selectedGuide, setSelectedGuide] = useState('');
  return (
    <div className="w-100 h-100 overflow-scroll d-flex flex-column align-items-center justify-content-start">
      <Row>
        <h2 className="font-weight-bold text-secondary py-4 border-bottom">
          How to use Intus Care in your daily workflow:
        </h2>
      </Row>
      <Row className="py-4 px-4 ">
        <GuidePreview
          setPdf={setSelectedGuide}
          currPdf={selectedGuide}
          url="https://intus.care/clinician-guide.pdf"
          previewImg={clinicPreview}
          userType="Clinical Staff"
        />
        <GuidePreview
          setPdf={setSelectedGuide}
          currPdf={selectedGuide}
          url="https://intus.care/admin-guide.pdf"
          previewImg={adminPreview}
          userType="Administrative Staff"
        />
        <GuidePreview
          setPdf={setSelectedGuide}
          currPdf={selectedGuide}
          url="https://intus.care/non-clinical-guide.pdf"
          previewImg={nonClinicPreview}
          userType="Non-clinical Staff"
        />
      </Row>
      {selectedGuide ? (
        <Row className="w-100 h-100 d-flex flex-column align-items-center pt-3">
          <a id="pdf-viewer" className="w-75" href="settings/tutorials/useCases">
            <iframe
              title="Selected Guide"
              className="w-100 mb-5"
              style={{ height: '100vh' }}
              src={selectedGuide}
            />
          </a>
        </Row>
      ) : null}
    </div>
  );
};

export default UserGuides;

import { combineReducers } from '@reduxjs/toolkit';
import {
  acuitySlice,
  caregiverDetailSlice,
  caregiverListSlice,
  carePlanSlice,
} from '@global-state/redux';
import patientListSlice from '@global-state/redux/patientListSlice';
import patientDetailSlice from '@global-state/redux/patientDetailSlice';
import dashboardSlice from '@global-state/redux/dashboardSlice';
import filtersSlice from '@global-state/redux/filtersSlice';
import profileSlice from '@global-state/redux/profileSlice';
import userSettingsSlice from '@global-state/redux/userSettingsSlice';
import notificationSlice from '@global-state/redux/notificationSlice';
import updatesSlice from '@global-state/redux/updatesSlice';
import adminSlice from 'Admin/UserTracking/AdminSlice';
import helpSlice from '@global-state/redux/helpSlice';
import hospitalizationsSlice from '@global-state/redux/hospitalizationsSlice';
import customDashboardSlice from 'Dashboard/normalizedDashRedux/customDashboardSlice';
import customDashIndicatorsSlice from 'Dashboard/normalizedDashRedux/customDashIndicatorsSlice';
import spreadsheetModalSlice from 'Dashboard/spreadsheet/spreadsheetModalSlice';
import leaderboardSlice from '@global-state/redux/leaderboardSlice';
import welcomeSlice from '@global-state/redux/welcomeSlice';
import homepageSlice from '@global-state/redux/homepageSlice';
import advancedCohortsSlice from '@global-state/redux/advancedCohortsSlice';
import { apiSlice } from '@api';

const appReducer = combineReducers({
  acuity: acuitySlice,
  patientList: patientListSlice,
  patientDetail: patientDetailSlice,
  caregiverList: caregiverListSlice,
  caregiverDetail: caregiverDetailSlice,
  profile: profileSlice,
  userSettings: userSettingsSlice,
  help: helpSlice,
  dashboard: dashboardSlice,
  notification: notificationSlice,
  updates: updatesSlice,
  admin: adminSlice,
  carePlan: carePlanSlice,
  hospitalizations: hospitalizationsSlice,
  customDashboard: customDashboardSlice,
  customDashIndicators: customDashIndicatorsSlice,
  spreadsheetModal: spreadsheetModalSlice,
  leaderboard: leaderboardSlice,
  welcome: welcomeSlice,
  homepage: homepageSlice,
  filters: filtersSlice,
  advancedCohorts: advancedCohortsSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const rootReducer = (state, action) => {
  // reseting all reducers to their initialState
  if (action.type === 'USER_LOGOUT') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { logEvent } from '@util/userTracking';

import { setCurrPatient, setPatientList } from '@global-state/redux';

import { Icon, ParticipantList, Text } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { defaultChartColors } from '@intus-ui/styles/SecondaryColors';
import { getGroupLabel } from '../helperFunctions';

const AdmitsParticipantList = ({
  chartColor,
  participantList,
  selectedGroup,
  handleOnClickCloseButton,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const groupIsSelected = selectedGroup !== 'all';
  const title = `${getGroupLabel(selectedGroup)} Admits`;

  const highlightColor = defaultChartColors[chartColor];

  const columnFormat = [
    {
      field: 'name',
      name: `Participant Name (${participantList?.length})`,
      align: 'start',
      addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
      flex: 2,
    },
    {
      field: 'admitDate',
      name: `Admit Date`,
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="admitDate" id="admitDate" /> }],
      flex: 1,
    },
  ];

  return (
    <div
      style={{
        ...styles.listContainer,
        border: `solid 2px ${groupIsSelected ? highlightColor : '#C8C8C8'}`,
        flex: 1,
      }}
    >
      <div style={styles.listTitle}>
        <Text type="subtitle" color="navy">
          {title}
        </Text>

        {groupIsSelected && (
          <Icon
            name="Remove"
            color="#9D9D9D"
            hoverColor="#9D9D9D"
            onClick={handleOnClickCloseButton}
          />
        )}
      </div>
      <ParticipantList
        participantList={participantList}
        searchable={false}
        onClickItem={(item) => {
          const currIndex = participantList?.findIndex((ppt) => ppt.id === item.id);
          logEvent('Utilization: Executive Detail View: Clicked Admits Participant', {
            patientId: item.id,
          });
          dispatch(setPatientList(participantList));
          dispatch(setCurrPatient(currIndex));
          history.push(`/patient/${item.id}/admits`);
        }}
        defaultSort={{ field: 'admitDate', direction: 'desc' }}
        customColumnConfig={columnConfig}
        customFormat={columnFormat}
        titleRowElement={undefined}
        showColumnHeaders={undefined}
        loading={undefined}
        style={undefined}
        listStyle={undefined}
        customListItem={undefined}
        extraRenders={undefined}
        showDisenrolledParticipants={undefined}
        virtualizedOptions={undefined}
        noPadding={undefined}
      />
    </div>
  );
};

const columnConfig = {
  default: {
    columns: ['name', 'admitDate'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'admitDate'] }],
  },
  disenrolled: {
    columns: ['name', 'admitDate'],
    breakpoints: [{ minWidth: 1, columns: ['name', 'admitDate'] }],
  },
};

const styles = {
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    border: 'solid 2px #C8C8C8',
    padding: 5,
    width: '100%',
    height: '100%',
  },
  listTitle: {
    padding: '10px 20px 0px 20px',
    gap: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

export default AdmitsParticipantList;

import { StatMetricConfiguration } from 'CustomDashboardV2/MetricLibrary/MetricConfigs/StatWidgetConfiguration';
import { BreakdownMetricConfiguration } from './BreakdownMetricConfiguration';

export function getMetricConfigComponent(configData) {
  switch (configData.component) {
    case 'ParticipantStatConfiguration':
      return StatMetricConfiguration;
    case 'BreakdownConfig':
      return BreakdownMetricConfiguration;
    default:
      throw new Error(`No configuration component found for ${configData.component}`);
  }
}

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { uniq } from 'lodash';

import { formatDate } from '@util/dateFunctions';

import { setPage, setPatientList, setCurrPatient } from '@global-state/redux/profileSlice';
import { resetDisenrollmentPopup } from '@global-state/redux/updatesSlice';

import { Modal } from 'react-bootstrap';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import EventBadge from '@intus-ui/components/Badges/EventBadge';

import '@intus-ui/styles/Utilities.scss';

const DisenrollmentPopup = ({
  show,
  id,
  type,
  name,
  date,
  disenrollmentReason,
  closeDisenrollmentPopup,
  history,
  principalDX,
  startDate,
  endDate,
  patientList,
  setProfilePage,
  doSetCurrPatient,
  doSetPatientList,
}) => (
  <Modal show={show} onHide={closeDisenrollmentPopup} centered className="w-100">
    <Modal.Header closeButton>
      <Modal.Title
        className="text-secondary justify-content-center"
        style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
        }}
      >
        {name} <EventBadge event={type} className="mx-2" />
        {type === 'ADMIT' || type === 'DISCHARGED' ? (
          <EventBadge event="inpatient" className="mx-2" />
        ) : null}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="d-flex flex-column justify-content-center align-items-center mx-4 pl-5 pb-4 ">
      <div className="w-100 pl-3 mb-1 d-flex justify-content-start">
        {(type === 'DISENROLLED' || type === 'ENROLLED') && (
          <div>
            <span className="text-dark mr-1">
              <strong>{type === 'DISENROLLED' ? 'Disenrollment' : 'Enrollment'} Date:</strong>
            </span>
            <span>{`${formatDate(date, 'MM/DD/YYYY')}`}</span>
          </div>
        )}
        {type === 'DISCHARGED' && (
          <div className="d-flex flex-column">
            <div>
              <span className="text-dark mr-1">
                <strong>Admit Date:</strong>
              </span>
              <span>{`${formatDate(startDate, 'MM/DD/YYYY')}`}</span>
            </div>
            <div>
              <span className="text-dark mr-1">
                <strong>Discharged Date:</strong>
                <span>{` ${formatDate(endDate, 'MM/DD/YYYY')}`}</span>
              </span>
            </div>
          </div>
        )}
        {type === 'ADMIT' && (
          <div className="d-flex flex-column">
            <div>
              <span className="text-dark mr-1">
                <strong>Admit Date:</strong>
              </span>
              <span>{` ${formatDate(startDate, 'M/D/YYYY')}`}</span>
            </div>
          </div>
        )}
        {type === 'ER VISIT' && (
          <div className="d-flex flex-column">
            <div>
              <span className="text-dark mr-1">
                <strong>Visit Date:</strong>
              </span>
              <span>{` ${formatDate(startDate, 'M/D/YYYY')}`}</span>
            </div>
          </div>
        )}
      </div>
      {type === 'DISENROLLED' && (
        <div className="w-100 my-1 pl-3 py-2 d-flex justify-content-start">
          <span className="text-dark mr-1">
            <strong>Disenrollment Reason:</strong>
          </span>
          <span>
            {disenrollmentReason
              ? disenrollmentReason.charAt(0).toUpperCase() + disenrollmentReason.slice(1)
              : 'No information available'}
          </span>
        </div>
      )}
      {type === 'ENROLLED' && (
        <button
          type="button"
          className="w-100 my-1 pl-3 py-2 d-flex justify-content-start text-primary cursor-pointer transparent"
          onClick={() => {
            history.push(`/patient/${id}/current-acuity`);
          }}
        >
          <strong>View Participant Info </strong>
          <ChevronRightRoundedIcon />
        </button>
      )}
      {(type === 'ADMIT' || type === 'DISCHARGED' || type === 'ER VISIT') && (
        <div className="w-100 my-1 pl-3 py-2 d-flex justify-content-start flex-column">
          <div>
            <span className="text-dark mr-1">
              <strong>PrincipalDX:</strong>
            </span>
            <span>{principalDX}</span>
          </div>
          <div>
            <button
              type="button"
              className="w-100 my-1 pl-0 py-3 d-flex justify-content-start text-primary cursor-pointer transparent"
              onClick={() => {
                setProfilePage('Risk Trendline');
                doSetCurrPatient(uniq(patientList).findIndex((patient) => patient.id === id));
                doSetPatientList(uniq(patientList));
                history.push(`/patient/${id}/current-acuity`);
              }}
            >
              <strong>View Participant Info </strong>
              <ChevronRightRoundedIcon />
            </button>
          </div>
        </div>
      )}
    </Modal.Body>
  </Modal>
);
DisenrollmentPopup.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  show: PropTypes.bool,
  id: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  principalDX: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  disenrollmentReason: PropTypes.string,
  closeDisenrollmentPopup: PropTypes.func.isRequired,
  setProfilePage: PropTypes.func.isRequired,
  doSetPatientList: PropTypes.func.isRequired,
  doSetCurrPatient: PropTypes.func.isRequired,
};

DisenrollmentPopup.defaultProps = {
  show: false,
  id: null,
  type: null,
  name: '',
  date: null,
  disenrollmentReason: null,
  principalDX: null,
  startDate: null,
  endDate: null,
};

const mapState = (state) => ({ ...state.updates.disenrollmentPopup });

const mapDispatch = (dispatch) => ({
  closeDisenrollmentPopup: () => dispatch(resetDisenrollmentPopup()),
  setProfilePage: (page) => dispatch(setPage(page)),
  doSetPatientList: (list) => dispatch(setPatientList(list)),
  doSetCurrPatient: (id) => dispatch(setCurrPatient(id)),
});

export default withRouter(connect(mapState, mapDispatch)(DisenrollmentPopup));

/* eslint-disable consistent-return */
import { chain, pick, without, sortBy, first, last, filter } from 'lodash';
import { createContext, useContext, useMemo, useState } from 'react';

import { monthsOptions } from '@intus-ui';

import { getFeatureFlag } from '@config';
import { newDate, formatDate, isSameOrAfterDate, isSameOrBeforeDate } from '@util/dateFunctions';
import { badges, getBadge } from './badges';

const RiskTrendlineContext = createContext();

const CHART_DATASETS = ['acuityScores', 'acuityEvents', 'incidents', 'admits', 'changes'];
const EVENT_DATASETS = without(CHART_DATASETS, 'acuityScores');
// PUT IN THE ORDER THE DATASETS SHOULD GO FROM TOP TO BOTTOM
export const CHART_EVENTS_CONFIG = [
  { key: 'incidents', label: 'Incidents' },
  { key: 'admits', label: 'Admits' },
  { key: 'changes', label: 'Changes' },
];

export const PROPORTION_VALUE = 2;
export const BADGE_OFFSET = 25;

const mapPointKeys = (datasets, yValue) => {
  return sortBy(
    datasets.map((item) => {
      const obj = { ...item, x: item.date };
      if (item.eventType === 'acuityScore') obj.y = item.acuityScore;
      else {
        obj.y = yValue;
      }

      return obj;
    }),
    (o) => {
      return new Date(o.date);
    }
  );
};

export const Provider = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [rawData, setRawData] = useState();
  const [acuityData, setAcuityData] = useState();
  const [maxAcuity, setMaxAcuity] = useState(30);
  const [chartData, setChartData] = useState();
  const [sidebarData, setSidebarData] = useState([]);

  const [hoveredPoint, setHoveredPoint] = useState();
  const [selectedPoint, setSelectedPointState] = useState();
  const setSelectedPoint = (point) => {
    if (point && selectedPoint?.date === point.date) setSelectedPointState();
    else setSelectedPointState(point);
  };

  // POLYPHARMACY FILTERS
  const { featureIsActive: polypharmacyIsActive } = getFeatureFlag('polypharmacy');
  const [polypharmacyFilters, setPolypharmacyFilters] = useState({
    fills: {
      options: [
        { label: 'Initial Fill', value: 'initial' },
        { label: 'All Fills', value: 'all' },
      ],
      selected: polypharmacyIsActive ? 'initial' : 'all',
    },
    drugClass: {
      options: ['All Medication Classes'],
      selected: 'All Medication Classes',
    },
  });
  const setPolypharmacyFilterValue = (key, value) => {
    const newFilters = { ...polypharmacyFilters };
    newFilters[key].selected = value;
    setPolypharmacyFilters(newFilters);
    // RESET SELECTED POINT
    if (selectedPoint) setSelectedPointState();
    // APPLY FILTER TO DATASET
    generateDatasets(rawData, maxAcuity, { dateFilter, ...eventFilters });
  };
  const setPolypharmacyFilterOptions = (key, options) => {
    const newFilters = { ...polypharmacyFilters };
    newFilters[key].options = ['All Medication Classes', ...options];
    setPolypharmacyFilters(newFilters);
  };

  // MULTISELECT EVENT FILTERING
  // ADD CONFIGS IN THE ORDER YOU WISH THEM TO APPEAR IN THE UI
  const eventFilterConfigs = {
    incidents: {
      key: 'incidents',
      eventType: 'incident',
      title: 'Incidents',
      values: {
        types: [],
        enabled: [],
      },
    },
    admits: {
      key: 'admits',
      eventType: 'admit',
      title: 'Admits',
      values: {
        types: [],
        enabled: [],
      },
    },
    changes: {
      key: 'changes',
      eventType: 'change',
      title: 'Changes',
      values: {
        types: [],
        enabled: [],
      },
    },
  };
  const [eventFilters, setEventFilters] = useState({ ...eventFilterConfigs });
  const setEventFilterValue = (key, obj, generateData) => {
    // SAVE FILTER TO STATE
    const newFilters = { ...eventFilters };
    newFilters[key].values = obj;
    setEventFilters(newFilters);
    // RESET SELECTED POINT
    if (selectedPoint) setSelectedPointState();
    // APPLY FILTER TO DATASET
    if (generateData) generateDatasets(rawData, maxAcuity, { dateFilter, ...newFilters });
  };

  const startingDateFilter = monthsOptions['6'];
  const [dateFilter, setDateFilterState] = useState({
    ...startingDateFilter,
    start: newDate(startingDateFilter.start),
    end: newDate(startingDateFilter.end),
  });
  const setDateFilter = (obj) => {
    // CONVERT START/END TO DAYJS OBJECT
    // TODO REMOVE THIS WHEN COMPONENT DOES THIS
    const filter = {
      ...obj,
      start: obj.start ? newDate(obj.start) : null,
      end: newDate(obj.end),
    };

    // SAVE FILTER TO STATE
    setDateFilterState(filter);
    // RESET SELECTED POINTS
    if (selectedPoint) setSelectedPointState();
    // APPLY FILTER TO DATASET
    const filters = initEventFilters(rawData, filter);

    generateDatasets(rawData, maxAcuity, filters);
  };
  const [earliestDate, setEarliestDate] = useState();

  // GETS ALL POSSIBLE EVENT FILTER OPTIONS FOR THE TIME FILTERED DATASET
  // RETURNS THE MODIFIED FILTERS TO BE USED IN CHART DATASET CREATION
  const initEventFilters = (data, newDateFilter) => {
    if (!data) return;

    // FLATTEN EVENTS
    let filteredData = chain(data).pick(EVENT_DATASETS).values().flatten().value();
    // FILTER TO STARTING DATE RANGE
    filteredData = filterByDate(filteredData, newDateFilter || dateFilter);

    // SET FILTER OPTIONS
    const newFilters = { ...eventFilters };

    Object.values(eventFilterConfigs).forEach((eventFilter) => {
      const types = chain(filteredData)
        .filter({ eventType: eventFilter.eventType })
        .map('type')
        .sort()
        .sortedUniq()
        .value();
      const obj = { types, enabled: types };
      newFilters[eventFilter.key].values = obj;
      setEventFilterValue(eventFilter.key, obj);
    });

    // SET POLYPHARMACY FILTER OPTIONS
    const medData = filterByDate(
      filter(data.changes, { type: 'Medication Changes' }),
      newDateFilter || dateFilter
    );
    let medClassOptions = [];
    medData.forEach((item) => {
      medClassOptions = [
        ...medClassOptions,
        ...item.events.added.map((o) => o.drugClass),
        ...item.events.removed.map((o) => o.drugClass),
      ];
    });
    medClassOptions = chain(medClassOptions).sort().sortedUniq().value();
    // SET DRUG CLASS FILTER OPTIONS, REMOVING NULL/EMPTY VALUES
    setPolypharmacyFilterOptions('drugClass', medClassOptions.filter(Boolean));

    return {
      dateFilter: newDateFilter,
      ...newFilters,
    };
  };

  const filterByDate = (data, filter) => {
    if (!filter?.start) return data;
    return data.filter(
      (item) =>
        isSameOrAfterDate(item.date, filter.start) && isSameOrBeforeDate(item.date, filter.end)
    );
  };

  // HANDLES THE INITIAL DATA PARSING AND GENERATES KEY VALUES
  // SETS INITIAL FILTER STATE AND DATASETS
  const setData = (data) => {
    // SAVE UNMODIFIED DATASET
    setRawData(pick(data, CHART_DATASETS));

    // DETERMINE EARLIEST DATE
    const firstDate = chain(data)
      .values()
      .flatten()
      .sortBy((o) => {
        return new Date(o.date);
      })
      .map('date')
      .head()
      .value();
    setEarliestDate(firstDate);

    // CALCULATE MAX ACUITY
    const max = chain(data.acuityScores).map('acuityScore').max().value();
    const { featureIsActive: AcuityV2 } = getFeatureFlag('AcuityV2');
    const startingMaxAcuityVal = AcuityV2 ? 30 : 100;
    const maxAcuityVal = max > startingMaxAcuityVal ? max + 10 : startingMaxAcuityVal;
    setMaxAcuity(maxAcuityVal);

    // INIT FILTERS
    const filters = initEventFilters(data, dateFilter);

    // APPLY FILTERS TO DATASET
    generateDatasets(data, maxAcuityVal, filters);

    setReady(true);
  };

  // GENERATES THE CHART AND SIDEBAR DATASETS BY FILTERING THE DATA WITH THE PROVIDED FILTERS
  // GENERATES THE SIDEBAR DATASET
  const generateDatasets = (data, maxAcuityVal, filters) => {
    if (!data) return;
    const filteredData = {};
    CHART_DATASETS.forEach((key) => {
      filteredData[key] = filterByDate(data[key], filters.dateFilter);

      if (key === 'acuityScores') {
        // ALWAYS ENSURE THERE IS AT LEAST ONE DATAPOINT
        if (!filteredData.acuityScores.length) {
          const firstData = first(data.acuityScores);
          const lastData = last(data.acuityScores);

          // RANGE IS BEFORE FIRST KNOWN POINT
          if (isSameOrBeforeDate(filters.dateFilter.end, firstData.date)) {
            filteredData.acuityScores.push(firstData);
          } else if (isSameOrAfterDate(filters.dateFilter.start, lastData.date)) {
            // RANGE IS AFTER LAST KNOWN POINT
            filteredData.acuityScores.push(lastData);
          } else {
            // RANGE MUST BE WITHIN DATASET
            const beforeData = data.acuityScores.filter((item) =>
              isSameOrBeforeDate(item.date, filters.dateFilter.start)
            );
            const afterData = data.acuityScores.filter((item) =>
              isSameOrAfterDate(item.date, filters.dateFilter.end)
            );
            filteredData.acuityScores.push(last(beforeData));
            filteredData.acuityScores.push(first(afterData));
          }
        }

        // ALWAYS ENSURE THERE IS A POINT TO START THE TIME RANGE
        if (filters.dateFilter.start) {
          const startDate = formatDate(filters.dateFilter.start, 'MM/DD/YYYY');
          const existingPoint = filteredData.acuityScores.find((o) => o.date === startDate);
          if (!existingPoint) {
            const firstPoint = first(filteredData.acuityScores);
            filteredData.acuityScores.unshift({
              ...firstPoint,
              date: startDate,
              acuityScoreChange: 0,
              visible: false,
            });
          }
        }

        // ALWAYS ENSURE THERE IS A POINT TO END THE TIME RANGE
        if (filters.dateFilter.end) {
          const endDate = formatDate(filters.dateFilter.end, 'MM/DD/YYYY');
          const existingPoint = filteredData.acuityScores.find((o) => o.date === endDate);
          if (!existingPoint) {
            const lastPoint = last(filteredData.acuityScores);
            filteredData.acuityScores.push({
              ...lastPoint,
              date: endDate,
              acuityScoreChange: 0,
              visible: false,
            });
          }
        }
      }

      // APPLY EVENT FILTERS
      if (eventFilters[key]) {
        filteredData[key] = filteredData[key].filter((event) => {
          return filters[key].values.enabled.includes(event.type);
        });
      }

      // APPLY POLYPHARMACY FILTERS
      if (key === 'changes') {
        // FILTER BY DRUG CLASS
        if (polypharmacyFilters.drugClass.selected !== 'All Medication Classes') {
          filteredData[key] = filteredData[key].filter((event) => {
            // ONLY FILTER MEDICATION CHANGES
            if (event.type !== 'Medication Changes') return true;

            // LOOK FOR A MATCH IN EITHER ADDED OR REMOVED
            const added = event.events.added.find(
              (o) => o.drugClass === polypharmacyFilters.drugClass.selected
            );
            const removed = event.events.removed.find(
              (o) => o.drugClass === polypharmacyFilters.drugClass.selected
            );
            return added || removed;
          });

          // REMOVE DRUG CLASSES THAT AREN'T IN THE FILTERED DATASET
          filteredData[key] = filteredData[key].map((event) => {
            if (event.type !== 'Medication Changes') return event;

            const added = event.events.added.filter(
              (o) => o.drugClass === polypharmacyFilters.drugClass.selected
            );
            const removed = event.events.removed.filter(
              (o) => o.drugClass === polypharmacyFilters.drugClass.selected
            );
            return {
              ...event,
              events: {
                added,
                removed,
              },
            };
          });
        }

        // FILTER BY FILL FREQUENCY (INITIAL VS ALL)
        if (polypharmacyFilters.fills.selected === 'initial') {
          // REVERSE THE ARRAY SO THAT THE FIRST MED CHANGE IS THE INITIAL FILL
          filteredData[key] = filteredData[key].reverse();

          // FILTER OUT ALL BUT THE FIRST MEDICATION CHANGE FOR EACH MED BY NDC
          const nameList = [];
          filteredData[key] = filteredData[key].filter((event) => {
            // ONLY FILTER MEDICATION CHANGES
            if (event.type !== 'Medication Changes') return true;

            // LOOK FOR A MATCH IN EITHER ADDED OR REMOVED
            const added = event.events.added.find((o) => {
              if (nameList.includes(o.name)) return false;
              nameList.push(o.name);
              return true;
            });

            return added;
          });

          // REVERSE THE ARRAY TO THE ORIGINAL ORDER
          filteredData[key] = filteredData[key].reverse();
        }
      }
    });

    // CHART ACUITY DATASETs
    const acuityDataset = mapPointKeys(filteredData.acuityScores);
    setAcuityData(acuityDataset);

    const datasets = [
      {
        label: 'Acuity Model Change',
        data: [
          { x: '01/17/2021', y: 0 },
          { x: '02/14/2021', y: 0 },
        ],
        showLine: false,
        tension: 0,
        radius: 7,
        pointHoverRadius: 7,
        drawActiveElementsOnTop: true,
        pointStyle(context) {
          context.globalCompositeOperation = 'destination-over';
          return badges.default.infoIcon;
        },
      },
      {
        label: 'Acuity Score',
        pointType: 'acuityScore',
        data: acuityDataset,
        showLine: true,
        tension: 0,
        fill: false,
        borderWidth: 1,
        borderColor: 'black',
        backgroundColor: 'white',
        radius(context) {
          const { visible } = context.dataset.data[context.dataIndex];
          return visible ? 7 : 0;
        },
        pointHoverRadius(context) {
          const { visible } = context.dataset.data[context.dataIndex];
          return visible ? 7 : 0;
        },
      },
    ];

    // CALCULATES THE POSITIONING BY Y-VALUE FOR EACH EVENT DATASET
    const maxChartValue = maxAcuityVal * PROPORTION_VALUE;
    const CHART_EVENT_GAP = 5;
    const CHART_TOP_MARGIN = 15;
    const eventValueRange = ((maxChartValue - CHART_EVENT_GAP) * 0.5) / CHART_EVENTS_CONFIG.length;

    // CHART EVENTS DATASETS
    CHART_EVENTS_CONFIG.forEach((config, index) => {
      const lineValue = maxChartValue - CHART_TOP_MARGIN - index * eventValueRange;
      datasets.push({
        label: config.label,
        eventType: config.key,
        data: mapPointKeys(filteredData[config.key], lineValue),
        drawActiveElementsOnTop: true,
        showLine: false,
        tension: 0,
        fill: false,
        backgroundColor: 'white',
        borderDash: [5],
        borderWidth: 0.4,
        borderColor: 'black',
        radius: 14,
        pointHoverRadius: 14,
        pointStyle(context) {
          const item = context.dataset.data[context.dataIndex];
          if (item == null) return undefined;
          return getBadge(item.label);
        },
        lineValue,
      });
    });
    setChartData({ datasets });

    // APPLY EVENT FILTERS TO SIDEBAR
    const sidebarDataset = chain(filteredData)
      .pick(EVENT_DATASETS)
      .values()
      .flatten()
      .sortBy((o) => {
        return new Date(o.date);
      })
      .value();

    setSidebarData(sidebarDataset);
  };

  const trendlineContext = useMemo(() => {
    return {
      polypharmacyFilters,
      setPolypharmacyFilterValue,

      dateFilter,
      setDateFilter,

      eventFilters,
      setEventFilterValue,

      selectedPoint,
      setSelectedPoint,
      hoveredPoint,
      setHoveredPoint,

      ready,
      setReady,

      setData,
      earliestDate,
      maxAcuity,
      acuityData,
      sidebarData,
      chartData,
    };
  });

  return (
    <RiskTrendlineContext.Provider value={trendlineContext}>
      {children}
    </RiskTrendlineContext.Provider>
  );
};

export const useRiskTrendlineContext = () => {
  return useContext(RiskTrendlineContext);
};

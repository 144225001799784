import React from 'react';
import PropTypes from 'prop-types';
import '@intus-ui/styles/Switch.scss';

const Switch = ({ onClickFunc, labels }) => (
  <span className="d-flex flex-row align-items-center">
    <h6 className="text-muted">{labels[0]}</h6>

    <input
      className="react-switch-checkbox cursor-pointer"
      id="react-switch-new"
      type="checkbox"
      onClick={() => onClickFunc()}
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label className="react-switch-label mx-3 my-0" htmlFor="react-switch-new">
      <span className="react-switch-button" />
    </label>
    <h6 className="text-muted">{labels[1]}</h6>
  </span>
);

Switch.propTypes = {
  onClickFunc: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
};

Switch.defaultProps = {
  labels: [null, null],
};

export default Switch;

import React from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { admissionType } from '@util/admissionType';
import '@intus-ui/styles/Utilities.scss';

const AdmitBadge = ({ admission }) => {
  let badge = { text: admissionType.NO_INFO.name, color: admissionType.NO_INFO.badgeColor };
  if (admission === admissionType.EMERGENCY.enum || admission.toLowerCase().includes(admissionType.EMERGENCY.name.toLowerCase())) {
    badge = { text: admissionType.EMERGENCY.name, color: admissionType.EMERGENCY.badgeColor };
  } else if (admission === admissionType.URGENT.enum) {
    badge = { text: admissionType.URGENT.name, color: admissionType.URGENT.badgeColor };
  } else if (admission === admissionType.ELECTIVE.enum) {
    badge = { text: admissionType.ELECTIVE.name, color: admissionType.ELECTIVE.badgeColor };
  } else if (admission === admissionType.NEWBORN.enum) {
    badge = { text: admissionType.NEWBORN.name, color: admissionType.NEWBORN.badgeColor };
  } else if (admission === admissionType.TRAUMA.enum) {
    badge = { text: admissionType.TRAUMA.name, color: admissionType.TRAUMA.badgeColor };
  } else if (admission === admissionType.EMERGENT_VISIT.name || admission.toLowerCase().includes(admissionType.ED_VISIT.name.toLowerCase())) {
    badge = {
      text: admissionType.EMERGENT_VISIT.name,
      color: admissionType.EMERGENT_VISIT.badgeColor
    };
  } else if (admission === admissionType.HOSPITALIZATION.name || admission.toLowerCase().startsWith(admissionType.INPATIENT_ADMIT.name.toLowerCase())) {
    badge = {
      text: admissionType.HOSPITALIZATION.name,
      color: admissionType.HOSPITALIZATION.badgeColor
    };
  } else if (admission.toLowerCase() === admissionType.OTHER.name.toLowerCase()) {
    badge = {
      text: admissionType.OTHER.name,
      color: admissionType.OTHER.badgeColor
    };
  }
  return (
    <div className="d-flex flex-column">
      <Badge className="tooltip-text m-0" variant={badge.color}>
        <h5 className="font-weight-bold">{badge.text}</h5>
      </Badge>
    </div>
  );
};

AdmitBadge.propTypes = {
  admission: PropTypes.string.isRequired,
};

export default AdmitBadge;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = { searchState: null };

const advancedCohortSlice = createSlice({
  name: 'searchState',
  initialState,
  reducers: {
    updateSearchState(state, action) {
      state.searchState = action.payload;
    },
  },
});

export const { updateSearchState } = advancedCohortSlice.actions;
export default advancedCohortSlice.reducer;

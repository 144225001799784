export const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflowY: 'scroll',
    backgroundColor: '#EFF0F3',
  },
  sidebar: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    padding: '15px 20px 20px 20px',
    gap: '20px',
    height: 'max-content',
  },
  trialContainer: {
    display: 'flex',
    flex: 10,
    flexDirection: 'column',
    padding: '15px',
    height: 'fit-content',
  },
  trial: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: 'fit-content',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    boxShadow: '2px 2px 16px rgba(157, 157, 157, 0.22)',
    borderRadius: '10px',
    padding: '20px 30px 0px 30px',
    gap: '10px',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    height: 'fit-content',
    alignItems: 'center',
    gap: '10px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  cohortsList: {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    gap: '15px',
    overflowY: 'auto',
  },
  cohortCards: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: '10px',
  },
  metricsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
  },
  loadingMetricsContainer: {
    padding: '200px',
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '8px 0px 16px 0px',
    gap: '10px',
  },

  metric: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  metricContent: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 1200,
  },
  pptList: {
    border: '1px solid #9D9D9D',
    borderRadius: '10px',
    marginBottom: 30,
    maxHeight: '500px',
    padding: 4,
  },
  metricSelectContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  datePicker: {
    display: 'flex',
    flexDirection: 'column',
  },
  chart: {
    display: 'flex',
    flex: '1 1 100%',
    maxWidth: 600,
  },
};

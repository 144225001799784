/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { passwordReset, setEditingCaregiver } from '@global-state/redux/caregiverListSlice';

import { Button, Icon } from '@intus-ui';

const PasswordReset = ({ caregiverID, passwordReset: handleClick }) => (
  <Button
    secondary
    onClick={() => {
      handleClick(caregiverID);
    }}
  >
    <Icon name="key" />
  </Button>
);

PasswordReset.propTypes = {
  passwordReset: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  passwordReset: state.caregiverList.resetPassword,
});

const mapDispatch = (dispatch) => ({
  passwordReset: (id) => {
    dispatch(passwordReset(id));
    dispatch(setEditingCaregiver(false));
  },
});

export default withRouter(connect(mapState, mapDispatch)(PasswordReset));

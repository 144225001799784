import { useListContext } from '@intus-ui/components/List/Context';
import { useParticipantListContext } from '@intus-ui/components/ParticipantList/Context';
import SingleSelect from '@intus-ui/components/SingleSelect';
import { Tag } from '@intus-ui/components/Tags';
import { useEffect, useMemo, useState } from 'react';

export const CohortDropdown = () => {
  const { addFilter } = useListContext();
  const { filterOptions } = useParticipantListContext();
  const [selected, setSelected] = useState('All');

  const cohorts = filterOptions?.cohorts ?? [];

  const dropdownItems = useMemo(() => {
    return ['All', ...cohorts.map((c) => c.name)];
  }, [cohorts]);

  const cohortNameToColorName = useMemo(() => {
    const map = new Map(cohorts.map((c) => [c.name, c.color]));

    map.set('All', 'white');

    return map;
  }, [cohorts]);

  useEffect(() => {
    addFilter({
      type: 'cohorts',
      value: (row) => {
        if (selected == null || selected === 'All') return true;
        if (row.cohorts == null) return false;

        return row.cohorts.some((cohort) => cohort.name === selected);
      },
    });
  }, [selected]);

  return (
    <SingleSelect
      onClickItem={(event) => {
        setSelected(event);
      }}
      options={dropdownItems}
      rightAligned={false}
      rightIcon
      defaultValue="All"
      renderListItem={(cohortName) => {
        return (
          <Tag
            key={cohortName}
            style={{ maxWidth: 350, height: 'auto' }}
            color={cohortNameToColorName.get(cohortName)}
          >
            {cohortName}
          </Tag>
        );
      }}
    />
  );
};

import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  setTeamFilter,
  setFacilityFilter,
  setDefaultTeam,
  setDefaultFacility,
} from '@global-state/redux/filtersSlice';
import { updateUserSettings } from '@global-state/redux/userSettingsSlice';

import { Form, Dropdown } from 'react-bootstrap';
import CustomDropdownToggle from '@intus-ui/components/CustomDropdownToggle';
import DropdownTitleAboveValue from 'Dashboard/DropdownTitleAboveValue';
import { getStringReplacement } from '@util/stringReplacements';
import SettingsBox from './SettingsBox';

const schema = Yup.object().shape({
  team: Yup.string().required('Required'),
  facility: Yup.string().required('Required'),
});

const GlobalFiltersSettingsBox = ({ setting }) => {
  const dispatch = useDispatch();

  const userSettings = useSelector((state) => state.userSettings);
  const teams = useSelector((state) => state.userSettings.teams) ?? [];
  const facilities = useSelector((state) => state.userSettings.facilities) ?? [];

  const resetFormRef = useRef();
  const [selectedTeam, setSelectedTeam] = useState(setting.team || 'All');
  const [selectedFacility, setSelectedFacility] = useState(setting.facility || 'All');
  const { profile } = userSettings;
  if (!profile) {
    return null;
  }

  const safeResetForm = () => {
    if (resetFormRef.current) {
      resetFormRef.current();
    }
  };

  const updated = { ...setting, team: selectedTeam, facility: selectedFacility };

  return (
    <SettingsBox title="Global Filter Defaults" cancel={safeResetForm}>
      {({ editable, setEditable }) => (
        <Formik
          initialValues={{ ...setting, ...profile }}
          onSubmit={() => {
            setEditable(false);
            dispatch(setTeamFilter([selectedTeam]));
            dispatch(setDefaultTeam([selectedTeam]));
            dispatch(setFacilityFilter([selectedFacility]));
            dispatch(setDefaultFacility([selectedFacility]));
            dispatch(updateUserSettings({ ...profile, settings: updated }));
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, submitForm, resetForm }) => {
            resetFormRef.current = resetForm;
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Row className="mb-4">
                  {/* <Col md={6}> */}
                  <Dropdown onSelect={(eventKey) => setSelectedTeam(eventKey)}>
                    <Dropdown.Toggle
                      disabled={!editable}
                      as={CustomDropdownToggle}
                      id="dash-team-dropdown"
                    >
                      <DropdownTitleAboveValue
                        title={getStringReplacement('Team')}
                        value={selectedTeam}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="All">All</Dropdown.Item>
                      {teams !== undefined
                        ? teams.map((team) => (
                            <Dropdown.Item key={team} eventKey={team}>
                              {team}
                            </Dropdown.Item>
                          ))
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown
                    className="ml-1 text-center"
                    onSelect={(eventKey) => setSelectedFacility(eventKey)}
                  >
                    <Dropdown.Toggle
                      disabled={!editable}
                      as={CustomDropdownToggle}
                      id="dash-facility-dropdown"
                    >
                      <DropdownTitleAboveValue
                        title={getStringReplacement('Center')}
                        value={selectedFacility}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="All">All</Dropdown.Item>
                      {facilities !== undefined
                        ? facilities.map((team) => (
                            <Dropdown.Item key={team} eventKey={team}>
                              {team}
                            </Dropdown.Item>
                          ))
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Row>
                {editable ? (
                  <SettingsBox.Buttons
                    cancel={() => {
                      resetForm();
                      setSelectedTeam(setting.team);
                      setSelectedFacility(setting.facility);
                      setEditable(false);
                    }}
                    submit={submitForm}
                  />
                ) : null}
              </Form>
            );
          }}
        </Formik>
      )}
    </SettingsBox>
  );
};

GlobalFiltersSettingsBox.propTypes = {
  setting: PropTypes.instanceOf(Object),
};

GlobalFiltersSettingsBox.defaultProps = {
  setting: undefined,
};

export default GlobalFiltersSettingsBox;

import React from 'react';

import { IAllPatientFirstFillStatus } from '@api/polypharmacy/getRisingStars';
import {
  IPatientStarsAdherence,
  IPatientStarsMeasureData,
} from '@api/polypharmacy/getSTARSAdherence';

import { getStringReplacement } from '@util/stringReplacements';

import { Checkbox } from '@mui/material';
import { DetailsButton } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { textColors } from '@intus-ui/styles/SecondaryColors';

import { PatientMedicationStatus } from './components/PatientFirstFillStatus';
import { starsMeasureToName } from './types/STARSMeasures';
import { NextFillDueColumn } from './components/NextFillDueColumn';

type RowItem = { item: IPatientStarsAdherence };

export const format = (
  onClickSeeDetails: (patientId: number) => void,
  onChangeStatus: (
    patientId: number,
    measure: string,
    status: IAllPatientFirstFillStatus | null
  ) => void,
  onChangeHospiceStatus: (patientId: number, hospiceStatus: boolean) => void
) => {
  return [
    {
      field: 'name',
      name: `${getStringReplacement('Participant')} Name`,
      flex: '0 0 220px',
      addOns: [{ type: 'sort', element: <SortIcon id="name" key="name" /> }],
    },
    {
      field: 'age',
      name: `Age`,
      flex: '0 0 60px',
      addOns: [{ type: 'sort', element: <SortIcon id="age" key="age" /> }],
    },
    {
      field: 'hospice',
      name: 'Hospice',
      flex: '0 0 60px',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <Checkbox
            checked={patient.hospice}
            onChange={(event) => onChangeHospiceStatus(patient.id, event.target.checked)}
          />
        );
      },
    },
    {
      field: 'patientStatus',
      name: 'Status',
      flex: '0 0 100px',
      className: 'starsAdherence-measureColumn',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-status-${item.measure}-${item.status}`}
                  className="starsAdherence-measureColumn-subRow"
                >
                  <PatientMedicationStatus
                    patient={{ id: patient.id, measure: item.measure, status: item.status }}
                    onChangeStatus={(status) => onChangeStatus(row.item.id, item.measure, status)}
                    tab="adherence"
                  />
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'measure',
      name: 'Adherence Measure',
      flex: '0 0 240px',
      className: 'starsAdherence-measureColumn',
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-measure-${item.measure}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    width: '275px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {starsMeasureToName[item.measure]}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'medication',
      name: 'Medication',
      flex: '0 0 240px',
      className: 'starsAdherence-measureColumn',
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-medication-${item.medication}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    width: '275px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.medication}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'fillCount',
      name: '# of Fills',
      flex: '0 0 65px',
      className: 'starsAdherence-measureColumn',
      align: 'end',
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-fills-${item.measure}-${item.fillCount}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    textAlign: 'right',
                    width: '100px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                  }}
                >
                  {item.fillCount}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'percentageDaysCovered',
      name: 'PDC',
      flex: '0 0 40px',
      className: 'starsAdherence-measureColumn',
      align: 'center',
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-pdc-${item.measure}-${item.percentageDaysCovered}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    width: '75px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                    color: item.percentageDaysCovered < 0.8 ? textColors.error : undefined,
                  }}
                >
                  {`${parseFloat((item.percentageDaysCovered * 100).toFixed(0))}%`}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'priority',
      name: 'Priority',
      flex: '0 0 100px',
      className: 'starsAdherence-measureColumn',
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  style={{
                    width: '135px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                    color: item.percentageDaysCovered < 0.8 ? textColors.error : undefined,
                  }}
                  className="starsAdherence-measureColumn-subRow"
                >
                  {item.priority || <span>&nbsp;</span>}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'gapDays',
      name: `Gap Days`,
      className: 'starsAdherence-measureColumn',
      flex: '0 0 70px',
      align: 'end',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-gapdays-${item.measure}-${item.gapDays}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    textAlign: 'right',
                    width: '105px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                  }}
                >
                  {item.gapDays}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'minCoverageDays',
      name: `Min Coverage Days`,
      className: 'starsAdherence-measureColumn',
      flex: '0 0 140px',
      align: 'end',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-mincoveragedays-${item.measure}-${item.minCoverageDays}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    textAlign: 'right',
                    width: '175px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.minCoverageDays}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'adherenceComplianceDate',
      name: `Adherence Compliance Date`,
      className: 'starsAdherence-measureColumn',
      flex: '0 0 210px',
      align: 'end',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-compliancedate-${item.measure}-${item.adherenceComplianceDate}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    textAlign: 'right',
                    width: '245px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.adherenceComplianceDate}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'fillDueDate',
      name: 'Next fill due',
      className: 'starsAdherence-measureColumn',
      flex: '0 0 180px',
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-fillduedate-${item.measure}-${item.fillDueDate}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    width: '215px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                  }}
                >
                  <NextFillDueColumn fillDueDate={item.fillDueDate} />
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'seeDetails',
      name: '',
      flex: '0 0 150px',
      customStyles: {
        display: 'block',
        float: 'right',
        width: '100%',
        height: '100%',
        justifyContent: 'end',
        alignItems: 'end',
      },
      render: (row: RowItem) => {
        const patient = row.item;
        if (!patient) return null;

        return <DetailsButton onClick={() => onClickSeeDetails(patient.id)} />;
      },
    },
  ];
};

type FlatPatient = IPatientStarsMeasureData & {
  id: number;
  name: string;
};

export const singleMeasureFormat = (
  onClickSeeDetails: (patientId: number) => void,
  onChangeStatus: (
    patientId: number,
    measure: string,
    status: IAllPatientFirstFillStatus | null
  ) => void,
  onChangeHospiceStatus: (patientId: number, hospiceStatus: boolean) => void
) => {
  return [
    {
      field: 'name',
      name: 'Participant Name',
      flex: '0 0 220px',
      addOns: [{ type: 'sort', element: <SortIcon id="name" /> }],
    },
    {
      field: 'age',
      name: `Age`,
      flex: '0 0 60px',
      addOns: [{ type: 'sort', element: <SortIcon id="age" key="age" /> }],
    },
    {
      field: 'hospice',
      name: 'Hospice',
      flex: '0 0 60px',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <Checkbox
            checked={patient.hospice}
            onChange={(event) => onChangeHospiceStatus(patient.id, event.target.checked)}
          />
        );
      },
    },
    {
      field: 'patientStatus',
      name: 'Status',
      flex: '0 0 100px',
      addOns: [{ type: 'sort', element: <SortIcon id="patientStatus" key="patientStatus" /> }],
      render: (row: { item: FlatPatient }) => {
        return (
          <PatientMedicationStatus
            patient={row.item}
            onChangeStatus={(status) => onChangeStatus(row.item.id, row.item.measure, status)}
            tab={null}
          />
        );
      },
    },
    {
      field: 'measure',
      name: 'Adherence Measure',
      flex: '0 0 240px',
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        return (
          <div
            style={{
              width: '240px', // column width
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {starsMeasureToName[patient.measure]}
          </div>
        );
      },
    },
    {
      field: 'medication',
      name: 'Medication',
      flex: '0 0 240px',
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        return (
          <div
            style={{
              width: '240px', // column width
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {starsMeasureToName[patient.measure]}
          </div>
        );
      },
    },
    {
      field: 'fillCount',
      name: '# of Fills',
      flex: '0 0 80px',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon id="fillCount" /> }],
    },
    {
      field: 'percentageDaysCovered',
      name: 'PDC',
      flex: '0 0 50px',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon id="percentageDaysCovered" /> }],
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        return (
          <span
            style={{ color: patient.percentageDaysCovered < 0.8 ? textColors.error : undefined }}
          >
            {parseFloat((patient.percentageDaysCovered * 100).toFixed(0))}%
          </span>
        );
      },
    },
    {
      field: 'priority',
      name: 'Priority',
      flex: '0 0 100px',
    },
    {
      field: 'gapDays',
      name: `Gap Days`,
      flex: '0 0 70px',
      align: 'end',
    },
    {
      field: 'minCoverageDays',
      name: `Min Coverage Days`,
      flex: '0 0 140px',
      align: 'end',
    },
    {
      field: 'adherenceComplianceDate',
      name: `Adherence Complicance Date`,
      flex: '0 0 210px',
      align: 'end',
    },
    {
      field: 'fillDueDate',
      name: 'Next fill due',
      className: 'starsAdherence-measureColumn',
      flex: '0 0 180px',
      addOns: [{ type: 'sort', element: <SortIcon id="fillDueDate" /> }],
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            <NextFillDueColumn fillDueDate={patient.fillDueDate} />
          </div>
        );
      },
    },
    {
      field: 'seeDetails',
      name: '',
      flex: '0 0 150px',
      customStyles: {
        display: 'block',
        float: 'right',
        width: '100%',
        height: '100%',
        justifyContent: 'end',
        alignItems: 'end',
      },
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        return <DetailsButton onClick={() => onClickSeeDetails(patient.id)} text="See Details" />;
      },
    },
  ];
};

import React from 'react';
import '@intus-ui/styles/Settings.scss';
import '@intus-ui/styles/Utilities.scss';

import { useFeatureFlag } from '@util';

import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileSettingsBox from './ProfileSettingsBox';
import ConnectionInfoBox from './ConnectionInfoBox';
import AccountSettingsBox from './AccountSettingsBox';
import { getUserInfo } from '../@global-state/redux/userSettingsSlice';
import PasswordSettingsBox from './PasswordSettingsBox';
import GlobalFiltersSettingsBox from './GlobalFiltersSettingsBox';
import userSettingsPropType from './userSettingsPropType';

class SettingsGeneralTab extends React.Component {
  componentDidMount() {
    const { doGetUserLocalData } = this.props;
    doGetUserLocalData();
  }

  render() {
    const { userSettings, isHealthSysAdmin } = this.props;
    const { profile } = userSettings;
    const { featureIsActive: connectionInfoActive } = useFeatureFlag('can-set-connection-info');

    if (!profile) {
      return null;
    }
    const { setting } = profile;

    return (
      <div className="w-100 h-100 overflow-scroll">
        <div className="w-80 h-100 ">
          <Row>
            <Col>
              <ProfileSettingsBox profile={profile} />
              {connectionInfoActive && <ConnectionInfoBox profile={profile} />}
            </Col>
            <Col>
              {!isHealthSysAdmin && <GlobalFiltersSettingsBox setting={setting} />}
              <PasswordSettingsBox />
              <AccountSettingsBox />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

SettingsGeneralTab.propTypes = {
  doGetUserLocalData: PropTypes.func.isRequired,
  userSettings: userSettingsPropType.isRequired,
  isHealthSysAdmin: PropTypes.bool.isRequired,
};

const mapDispatch = (dispatch) => ({
  doGetUserLocalData: () => dispatch(getUserInfo()),
});

const mapState = (state) => ({
  userSettings: state.userSettings,
  isHealthSysAdmin: state.filters.isHealthSysAdmin,
});

export default connect(mapState, mapDispatch)(SettingsGeneralTab);

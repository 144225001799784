import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
/**
 * Returns n items in a string array as a list separated by ; with a "see more/less..." expandability for the rest

 * @param {Array} strArray the array of codes to be truncated
 * @param {Number} num the desired # of list items to display
 * @param {String} errorMessage the desired error message to display if list in invalid or empty
 */
const TruncList = ({ strArr, num, errorMessage, expandAlt }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const getItems = () => {
    if (isExpanded || strArr.length === num) {
      return strArr;
    }
    return strArr.slice(0, num);
  };
  useEffect(() => {
    getItems();
  }, [isExpanded]);

  const expandButton = expandAlt || (
    <Button variant="link" className="py-0 px-1 mb-1" onClick={() => setIsExpanded(!isExpanded)}>
      {isExpanded ? 'see less...' : 'see more...'}
    </Button>
  );

  if (!strArr) return <div>{errorMessage}</div>;

  return (
    <span>
      {getItems().map((item, i) => (
        <span key={item}>{`${item}${i !== strArr.length - 1 ? '; ' : ' '}`}</span>
      ))}
      {strArr.length > num && expandButton}
    </span>
  );
};

TruncList.propTypes = {
  strArr: PropTypes.arrayOf(PropTypes.string),
  num: PropTypes.number,
  errorMessage: PropTypes.string,
  expandAlt: PropTypes.node,
};

TruncList.defaultProps = {
  strArr: undefined,
  num: 1,
  errorMessage: 'No information listed.',
  expandAlt: undefined,
};

export default TruncList;

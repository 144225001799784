let cachedCanvas = null;

/**
 * Computes the width in pixels of a line of text if that text was rendered in HTML and is only a single line.
 *
 * @param {string} text
 * @param {{ fontSize: number, fontWeight?: string}} param1
 * @returns {number}
 */
export function getTextWidthInPixels(text, { fontSize, fontWeight = 'normal' }) {
  // re-use canvas object for better performance
  const canvas = cachedCanvas || (cachedCanvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = `${fontWeight} ${fontSize}px 'Inter', Arial, sans-serif`;
  const metrics = context.measureText(text);
  return metrics.width;
}

import React from 'react';
import { DateRange } from 'react-date-range';
import { textColors } from '@intus-ui/styles/SecondaryColors';
import styles from './styles';

const CalendarView = ({ handleOnChange, state }) => {
  return (
    <div style={styles.calendarView}>
      <DateRange
        editableDateInputs={false}
        dateDisplayFormat="MM/dd/yyyy"
        retainEndDateOnFirstSelection
        onChange={handleOnChange}
        moveRangeOnFirstSelection={false}
        ranges={state}
        rangeColors={[textColors.navy]}
        maxDate={new Date()}
        startDatePlaceholder="Start Date"
        endDatePlaceholder="End Date"
      />
    </div>
  );
};

export default CalendarView;

import { post } from '@api/apiMethods';
import { IAllPatientFirstFillStatus } from '@api/polypharmacy/getRisingStars';
import { IAllStarsMeasures } from 'Polypharmacy/types/STARSMeasures';

type FirstFillStatusParams = {
  patientId: number;
  measure: IAllStarsMeasures;
  measureYear: number;
  status: IAllPatientFirstFillStatus | null;
  tab: 'first_fill' | 'adherence' | null;
};

type FirstFillStatusResponse = {
  id: number;
  patientId: number;
  status: IAllPatientFirstFillStatus | null;
};

export function updateFirstFillStatus(params: FirstFillStatusParams) {
  return post<FirstFillStatusResponse | null>(`polypharmacy/upsert-first-fill-status`, params);
}

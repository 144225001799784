import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

const QRCodeImage = ({ url, className, style }) => {
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    QRCode.toDataURL(url).then(setImageUrl);
  }, []);
  return <img className={className} style={style} src={imageUrl} alt="QR Code" />;
};

QRCodeImage.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

QRCodeImage.defaultProps = {
  className: '',
  style: {},
};

export default QRCodeImage;

import { setCurrentDash } from '@global-state/redux/dashboardSlice';
import { DashboardRoutes } from 'Dashboard/DashboardRoutes';
import { PREMIUM_DASH } from 'Dashboard/dashDataPropTypes/currentDashPropType';
import {
  CENSUS_DASH_ID,
  FALLS_DASH_ID,
  FINANCIAL_DASH_ID,
  HOSPITALIZATIONS_DASH_ID,
  PERFORMANCE_BENCHMARK_DASH_ID,
  RISKACUITY_DASH_ID,
  UTILIZATION_DASH_ID,
} from 'Dashboard/premiumDashNames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export function DashboardWrapper() {
  const [didRender, setDidRender] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setDidRender(true);
  }, []);

  if (window.location.href.includes('/dashboard/falls')) {
    dispatch(setCurrentDash({ id: FALLS_DASH_ID, type: PREMIUM_DASH }));
  } else if (window.location.href.includes('/dashboard/census')) {
    dispatch(setCurrentDash({ id: CENSUS_DASH_ID, type: PREMIUM_DASH }));
  } else if (window.location.href.includes('/dashboard/riskacuity')) {
    dispatch(setCurrentDash({ id: RISKACUITY_DASH_ID, type: PREMIUM_DASH }));
  } else if (window.location.href.includes('/dashboard/financial')) {
    dispatch(setCurrentDash({ id: FINANCIAL_DASH_ID, type: PREMIUM_DASH }));
  } else if (window.location.href.includes('/dashboard/hospitalizations')) {
    dispatch(setCurrentDash({ id: HOSPITALIZATIONS_DASH_ID, type: PREMIUM_DASH }));
  } else if (window.location.href.includes('/dashboard/performancebenchmark')) {
    dispatch(setCurrentDash({ id: PERFORMANCE_BENCHMARK_DASH_ID, type: PREMIUM_DASH }));
  } else if (window.location.href.includes('/dashboard/utilization')) {
    dispatch(setCurrentDash({ id: UTILIZATION_DASH_ID, type: PREMIUM_DASH }));
  }

  if (!didRender) return null;

  return <DashboardRoutes />;
}

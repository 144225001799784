import React from 'react';
import CollapsibleDrawer, {
  CollapsibleDrawerProps,
  DEFAULT_HEIGHT,
  DEFAULT_WIDTH,
} from '@intus-ui/components/CollapsibleDrawer';
import SidebarwithTabs from '@intus-ui/components/SidebarwithTabs';

interface CollapsibleTabsDrawerProps extends CollapsibleDrawerProps {
  tabs: any;
  defaultTabIndex?: number;
  contentStyles?: React.CSSProperties;
  onClickTab?: (arg0: string) => void;
}

const CollapsibleTabsDrawer: React.FC<CollapsibleTabsDrawerProps> = ({
  tabs,
  open,
  minimized,
  onClose,
  onMinimize,
  defaultTabIndex = 0,
  contentStyles,
  height = DEFAULT_HEIGHT,
  onClickTab,
  ...rest
}) => {
  const contentSectionHeight = `${parseInt(height) - 5}vh`;
  const width = DEFAULT_WIDTH;

  return (
    <CollapsibleDrawer
      open={open}
      minimized={minimized}
      onClose={onClose}
      onMinimize={onMinimize}
      height={height}
      width={width}
      {...rest}
      containerStyle={{
        // overrides the CollapsibleDrawer containerStyle
        border: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        zIndex: 1100,
      }}
      header=""
    >
      <SidebarwithTabs
        defaultTabIndex={defaultTabIndex}
        items={tabs}
        width={width}
        onClickTab={onClickTab}
        contentStyles={{
          height: contentSectionHeight,
          width,
          backgroundColor: '#fff',
          padding: 0,
          ...contentStyles,
        }}
      />
    </CollapsibleDrawer>
  );
};

export default CollapsibleTabsDrawer;

import './Adherence.scss';

import React, { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, last } from 'lodash';
import { produce } from 'immer';
import { Divider, List, Modal, SpinnerOrError, Text, SearchInput } from '@intus-ui';
import { useLazyQuery } from '@api/useQuery';
import { updatePatientHospice } from '@api';
import {
  IPatientStarsAdherence,
  IPatientStarsMeasureData,
  IStarsAdherenceResponse,
  getSTARSAdherence,
} from '@api/polypharmacy/getSTARSAdherence';
import {
  IAllPatientFirstFillStatus,
  allPatientFirstFillStatus,
} from '@api/polypharmacy/getRisingStars';
import { IPolypharmacyFilters } from '@api/polypharmacy/types/IPolypharmacyFilters';
import { getPatientMedicationsWithFilters } from '@api/patients/getPatientMedications';
import { MenuItem, Select } from '@mui/material';
import { IAllStarsMeasures, starsMeasuresPossibleYears } from 'Polypharmacy/types/STARSMeasures';
import { StarsMeasureDropdown } from 'Polypharmacy/components/StarsMeasureDropdown';
import {
  INextFillDueStatus,
  getNextFillDueStatus,
} from 'Polypharmacy/components/NextFillDueColumn';
import {
  AdherenceStatusSelect,
  IAllAdherenceStatuses,
} from 'Polypharmacy/components/AdherenceStatusSelect';
import { NextFillDueSelect } from 'Polypharmacy/components/NextFillDueSelect';
import { IPriority } from 'Polypharmacy/types/Priority';
import { PrioritySelect } from 'Polypharmacy/components/PrioritySelect';
import MedicationsModalList from './MedicationsModalList';
import { format, singleMeasureFormat } from './getAdherenceFormat';
import { HospiceFilter } from './components/HospiceFilter';
import { PatientStatusFilter } from './components/PatientStatusFilter';

type AdherenceProps = {
  filters: IPolypharmacyFilters;
};

type PossibleMeasures = 'all' | IAllStarsMeasures;

const columnConfig = {
  default: {
    columns: [
      'name',
      'age',
      'hospice',
      'patientStatus',
      'measure',
      'medication',
      'fillCount',
      'percentageDaysCovered',
      'priority',
      'gapDays',
      'minCoverageDays',
      'adherenceComplianceDate',
      'fillDueDate',
      'seeDetails',
    ],
  },
};

type FlatPatient = IPatientStarsMeasureData & {
  id: number;
  name: string;
  age: number;
};

export const Adherence: FC<AdherenceProps> = ({ filters }) => {
  const [medicationsModalOpen, setMedicationsModalOpen] = useState(false);
  const [selectedMeasure, setSelectedMeasure] = useState<PossibleMeasures>('all');
  const [adherentStatus, setAdherentStatus] = useState<IAllAdherenceStatuses>('All');
  const [nextFillDueStatus, setNextFillDueStatus] = useState<INextFillDueStatus>('All');
  const [data, setData] = useState<IStarsAdherenceResponse | null>(null);
  // If the filters are changed we do want to show the loading spinner.
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
  const [hospiceFilter, setHospiceFilter] = useState('No');
  const [patientStatusFilter, setPatientStatusFilter] = useState<IAllPatientFirstFillStatus[]>([
    ...allPatientFirstFillStatus,
  ]);
  const [priority, setPriority] = useState<'All' | IPriority>('All');
  const [selectedYear, setSelectedYear] = useState(() => {
    const currentYear = new Date().getFullYear();
    return starsMeasuresPossibleYears.includes(currentYear)
      ? currentYear
      : last(starsMeasuresPossibleYears)!;
  });
  const [search, setSearch] = useState('');

  const { data: queryData, loading, error, runQuery } = useLazyQuery(getSTARSAdherence);

  useEffect(() => {
    if (queryData) {
      setData(queryData);
    }
  }, [queryData]);

  const {
    data: medicationsData,
    loading: medicationsDataLoading,
    error: medicationsDataError,
    runQuery: runGetMedicationsData,
  } = useLazyQuery((patientId) =>
    getPatientMedicationsWithFilters({
      patientId,
      starsMeasure: selectedMeasure,
      starsMeasureYear: selectedYear,
      active: false,
      isRisingStars: false,
    })
  );

  const updatePatientStatus = useCallback(
    (patientId: number, measure: string, status: IAllPatientFirstFillStatus | null) => {
      setShowLoadingSpinner(false);
      setData(() => {
        return produce(data, (d) => {
          const patient = d?.patientAdherence.find((p) => {
            return p.id === patientId;
          });
          if (patient == null) return;

          const measureData = patient.measureData.find((m) => {
            return m.measure === measure;
          });

          if (measureData == null) return;

          if (measureData.status == null) {
            measureData.status = {
              status: status as IAllPatientFirstFillStatus,
            };
          } else {
            if (status != null) {
              measureData.status.status = status;
            } else {
              measureData.status = null;
            }
          }
        });
      });
    },
    [setData, data]
  );

  const onClickSeeDetails = useCallback(
    (patientId: number) => {
      runGetMedicationsData(patientId);
      setMedicationsModalOpen(true);
    },
    [runGetMedicationsData]
  );

  const onChangePatientStatus = useCallback(
    (patientId: number, measure: string, status: IAllPatientFirstFillStatus | null) => {
      setShowLoadingSpinner(false);
      updatePatientStatus(patientId, measure, status);
    },
    [updatePatientStatus]
  );

  const onChangeHospiceStatus = useCallback(
    (patientId: number, newHospiceStatus: boolean) => {
      updatePatientHospice({ patientId, newHospiceStatus });
      setData(() => {
        return produce(data, (d) => {
          const patient = d?.patientAdherence.find((p) => {
            return p.id === patientId;
          });
          if (patient == null) return;
          patient.hospice = newHospiceStatus;
        });
      });
    },
    [data]
  );

  const fullListFormat = useMemo(() => {
    return format(onClickSeeDetails, onChangePatientStatus, onChangeHospiceStatus);
  }, [onClickSeeDetails, onChangePatientStatus, onChangeHospiceStatus]);

  const singleMeasureListFormat = useMemo(() => {
    return singleMeasureFormat(onClickSeeDetails, onChangePatientStatus, onChangeHospiceStatus);
  }, [onClickSeeDetails, onChangePatientStatus, onChangeHospiceStatus]);

  useEffect(() => {
    runQuery(selectedYear, filters, 'adherence'); // add string to indicate which tab
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, selectedYear]);

  const filteredPatientAdherence = useMemo(() => {
    if (data == null) return [];

    return produce(data.patientAdherence, (draft) => {
      let filteredPatients: IPatientStarsAdherence[] = [];
      for (const patient of draft) {
        // Patient Name search
        if (!isEmpty(search)) {
          if (!patient.name.toLowerCase().includes(search.toLowerCase())) continue;
        }

        let filteredMeasures = patient.measureData;
        if (selectedMeasure !== 'all') {
          filteredMeasures = patient.measureData.filter((m) => m.measure === selectedMeasure);
        }

        if (adherentStatus === 'Adherent (≥80%)') {
          filteredMeasures = filteredMeasures.filter((m) => m.percentageDaysCovered >= 0.8);
        } else if (adherentStatus === 'Not adherent (<80%)') {
          filteredMeasures = filteredMeasures.filter((m) => m.percentageDaysCovered < 0.8);
        }

        // Filter by the status tag for the measure.
        if (patientStatusFilter.length !== allPatientFirstFillStatus.length) {
          const patientStatusSet = new Set(patientStatusFilter);
          filteredMeasures = filteredMeasures.filter((m) => {
            if (patientStatusSet.has('needs_review') && m.status == null) return true;
            return m.status != null && patientStatusSet.has(m.status.status);
          });
        }

        // Filter by next fill due status
        if (nextFillDueStatus !== 'All') {
          filteredMeasures = filteredMeasures.filter(
            (m) => getNextFillDueStatus(m.fillDueDate)?.status === nextFillDueStatus
          );
        }

        // Filter by priority
        if (priority !== 'All') {
          filteredMeasures = patient.measureData.filter((m) => m.priority === priority);
        }

        if (filteredMeasures.length > 0) {
          filteredPatients.push({
            ...patient,
            measureData: filteredMeasures,
          });
        }
      }
      if (hospiceFilter === 'No') {
        filteredPatients = filteredPatients.filter((patient) => {
          return patient.hospice === false;
        });
      } else if (hospiceFilter === 'Yes') {
        filteredPatients = filteredPatients.filter((patient) => patient.hospice === true);
      }
      return filteredPatients;
    });
  }, [
    data,
    search,
    adherentStatus,
    nextFillDueStatus,
    selectedMeasure,
    patientStatusFilter,
    hospiceFilter,
    priority,
  ]);

  const flattenedPatients = useMemo(() => {
    const flatPatients: FlatPatient[] = [];

    for (const patient of filteredPatientAdherence) {
      flatPatients.push({
        ...patient.measureData[0],
        id: patient.id,
        name: patient.name,
        age: patient.age,
      });
    }
    return flatPatients;
  }, [filteredPatientAdherence]);

  if (error) return <SpinnerOrError error="An error occurred loading adherence." />;

  if (showLoadingSpinner && (loading || data == null)) return <SpinnerOrError />;

  if (!data) return <SpinnerOrError error="No adherence data found." />;

  return (
    <div style={{ padding: '0 10px 10px 10px' }}>
      <Modal
        open={medicationsModalOpen}
        onClose={() => setMedicationsModalOpen(false)}
        type="large"
        style={{ overflow: 'hidden' }}
        modalStyles={{ height: '90%' }}
        header={{ title: 'Medications', centered: true }}
      >
        <MedicationsModalList
          medicationsData={medicationsData}
          loading={medicationsDataLoading}
          error={medicationsDataError}
        />
      </Modal>

      {/* Adherence Year & Measure */}
      <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
        <Text type="title">
          {filteredPatientAdherence != null ? filteredPatientAdherence.length : '~'} patients for
        </Text>
        {/* Measure Year selector */}
        {new Date().getFullYear() > 2023 && (
          <Select
            label="Select a Year"
            value={selectedYear}
            onChange={(e) => {
              setSelectedYear(parseInt(e.target.value.toString()));
            }}
            sx={{ width: 100 }}
          >
            {starsMeasuresPossibleYears.map((year) => {
              return (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              );
            })}
          </Select>
        )}

        {/* Measure selector */}
        <StarsMeasureDropdown
          value={selectedMeasure}
          onChange={(measure) => setSelectedMeasure(measure)}
        />
      </div>

      {/* Adherence Cards */}
      <div style={styles.cardGrid}>
        <AdherenceCard
          title="% of Pts Adherent for Diabetes Medications"
          value={data.diabetesAdherence}
        />
        <AdherenceCard
          title="% of Pts Adherent for Hypertension (RAS Antagonists)"
          value={data.rasAdherence}
        />
        <AdherenceCard
          title="% of Pts Adherent for Cholesterol (Statins)"
          value={data.statinsAdherence}
        />
      </div>

      {/* Adherence Disclaimer */}
      <div style={{ padding: '10px 0px 10px 0px' }}>
        <Text type="caption" color="caption">
          Disclaimer: We provide insights into individual patient and population-level adherence to
          medications. The calculations are based on similar criteria but should not be considered
          an official representation of STARs Ratings. Exclusions that do not factor into
          calculations include: Hospice Enrollment, Dialysis Coverage Dates
        </Text>
        <Divider style={{ marginTop: 15 }} />
      </div>

      {/* Adherence Filters */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <SearchInput
            placeholder="Search by name"
            closeIcon
            onClose={() => setSearch('')}
            handleSearch={(event: any) => setSearch(event.target.value)}
            searchTerm={search}
            containerStyle={{
              width: 350,
            }}
          />
        </div>

        <div style={{ display: 'flex', gap: 15 }}>
          {/* Hospice status filter */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text type="caption-bold">In Hospice</Text>
            <HospiceFilter hospiceStatus={hospiceFilter} setHospiceStatus={setHospiceFilter} />
          </div>

          {/* Patient status filter */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text type="caption-bold">Patient Status</Text>
            <PatientStatusFilter
              patientStatusFilter={patientStatusFilter}
              setPatientStatusFilter={(items) => {
                setPatientStatusFilter(items);
              }}
              sx={{
                width: 220,
              }}
            />
          </div>

          {/* Adherence or Not Adherent patient selector */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text>Adherence Status</Text>
            <AdherenceStatusSelect
              adherentStatus={adherentStatus}
              setAdherentStatus={setAdherentStatus}
            />
          </div>

          {/* Action Date Status filter */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text>Action Date Status</Text>
            <NextFillDueSelect
              dueDateStatus={nextFillDueStatus}
              setDueDateStatus={setNextFillDueStatus}
            />
          </div>

          {/* Priority filter */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text>Priority</Text>
            <PrioritySelect priority={priority} setPriority={setPriority} />
          </div>
        </div>
      </div>

      {/* Adherence Table */}
      <div
        style={{
          minWidth: 2250, // NOTE: MUST BE UPDATED IF COLUMNS ARE ADDED, REMOVED OR RESIZED
        }}
      >
        {selectedMeasure === 'all' && (
          <List
            key="allMeasureList"
            data={filteredPatientAdherence}
            format={fullListFormat}
            columnConfig={columnConfig.default}
            defaultSort={{ field: 'name', direction: 'asc' }}
            listStyle="striped"
            className="adherence-list"
          />
        )}
        {selectedMeasure !== 'all' && (
          <List
            key="singleMeasureList"
            data={flattenedPatients}
            format={singleMeasureListFormat}
            columnConfig={columnConfig.default}
            defaultSort={{ field: 'name', direction: 'asc' }}
            listStyle="striped"
            className="adherence-list"
          />
        )}
      </div>
    </div>
  );
};

type AdherenceCardProps = {
  title: string;
  value: number;
};

const AdherenceCard: FC<AdherenceCardProps> = ({ title, value }) => {
  return (
    <div style={styles.adherenceCard}>
      <Text type="subtitle">{title}</Text>
      <Text type="display">{(value * 100).toFixed(1)}%</Text>
    </div>
  );
};

const styles: Record<string, CSSProperties> = {
  cardGrid: {
    display: 'flex',
    gap: 10,
    justifyContent: 'space-between',
    height: 171,
    margin: '20px 40px',
  },
  adherenceCard: {
    flex: '1 1 100px',
    maxWidth: 320,
    gap: 10,
    border: '1px solid black',
    borderRadius: 10,
    padding: '10px 15px 15px 15px',
    textAlign: 'center',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

/* eslint-disable camelcase */
import React, { useMemo } from 'react';

import { formatDate, newDate } from '@util/dateFunctions';
import { initiativeChartColors } from '@intus-ui/styles/SecondaryColors';
import { ReviewNoteResponseData } from 'Workflows/AdmitReviewNote/types';
import { DetailsButton, Tag, Text } from '@intus-ui';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

import { logEvent } from '@util/userTracking';
import { useFeatureFlag } from '@util';
import tableConfig from './table.config';

export type AdmitsData = {
  patientId: number;
  admitId: number;
  compositeKey: string;
  admitDate: string;
  isReadmission: boolean;
  dischargeDate: string;
  type: string;
  lengthOfStay: string;
  admitFacility: string;
  dischargeDisposition: string;
  principalDiagnoses: string[];
  otherDiagnoses: string[];
  admitReviewNote: ReviewNoteResponseData;
};

type AdmitsListProps = {
  admitsData: AdmitsData[];
  setArnIsOpen: any;
  arnIsOpen: any;
  setArnAdmit: any;
};

const AdmittedCell: React.FC<{
  renderedCellValue: string | false | 0 | null | undefined;
  isReadmission: boolean;
  setArnIsOpen: any;
  arnIsOpen: any;
  setArnAdmit: any;
  admit: AdmitsData;
}> = ({ renderedCellValue, isReadmission, setArnIsOpen, arnIsOpen, setArnAdmit, admit }) => {
  const { admitReviewNote } = admit;

  const getArnTagProps = (status: string) => {
    const statusToTagProps: {
      [key: string]: { color: keyof typeof initiativeChartColors; text: string };
    } = {
      'IN PROGRESS': { color: 'orange', text: 'ARN In Progress' },
      COMPLETED: { color: 'green', text: 'ARN Completed' },
      default: { color: 'orange', text: 'ARN In Progress' },
    };
    return statusToTagProps[status] || statusToTagProps.default;
  };

  const arnTagProps = getArnTagProps(admitReviewNote?.status);
  const { featureIsActive: arnIsActive } = useFeatureFlag('admit-review-notes');
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 5 }}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
        <Text type="body">{renderedCellValue}</Text>
        {isReadmission && <Tag color="blue">Readmission</Tag>}
      </div>

      {arnIsActive && (
        <>
          <DetailsButton
            onClick={() => {
              logEvent('PFV Admits: Clicked ARN', {
                admitDate: formatDate(newDate(admit?.admitDate), 'MM-DD-YYYY'),
                createdAt: formatDate(newDate(admit?.admitReviewNote?.createdAt), 'MM-DD-YYYY'),
                lastEditedAt:
                  formatDate(newDate(admit?.admitReviewNote?.lastEditedAt), 'MM-DD-YYYY') || '',
                admitId: admit?.admitId,
                pptId: admit?.patientId,
              });
              setArnAdmit(admit);
              setArnIsOpen({ ...arnIsOpen, open: true });
            }}
            text="Admit Review Note"
          />

          <div style={{ display: 'flex', gap: 5 }}>
            {admitReviewNote?.status && <Tag color={arnTagProps.color}>{arnTagProps.text}</Tag>}
          </div>
        </>
      )}
    </div>
  );
};

const AdmitsList: React.FC<AdmitsListProps> = ({
  admitsData,
  setArnIsOpen,
  arnIsOpen,
  setArnAdmit,
}) => {
  const columns = useMemo<MRT_ColumnDef<AdmitsData>[]>(
    () => [
      {
        accessorKey: 'admitDate',
        size: 120,
        header: 'Admitted',
        Cell: ({ renderedCellValue, row }) => (
          <AdmittedCell
            renderedCellValue={
              renderedCellValue && formatDate(renderedCellValue?.toLocaleString(), 'MM/DD/YY')
            }
            isReadmission={row.original.isReadmission}
            setArnIsOpen={(val: any) => setArnIsOpen(val)}
            arnIsOpen={arnIsOpen}
            setArnAdmit={setArnAdmit}
            admit={row.original}
          />
        ),
      },
      {
        accessorKey: 'dischargeDate',
        header: 'Discharged',
        Cell: ({ renderedCellValue }) => (
          <Text type="body">
            {renderedCellValue && formatDate(renderedCellValue?.toLocaleString(), 'MM/DD/YY')}
          </Text>
        ),
        muiTableBodyCellProps: {
          align: 'left',
          style: { verticalAlign: 'top' },
        },
      },
      {
        accessorKey: 'lengthOfStay',
        header: 'LOS',
        Cell: ({ renderedCellValue }) => (
          <Text type="body">{parseInt(renderedCellValue?.toLocaleString() ?? '0')}</Text>
        ),
        size: 50,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
          style: { verticalAlign: 'top' },
        },
      },
      {
        accessorKey: 'admitType',
        header: 'Admit Type',
        Cell: ({ renderedCellValue }) => <Text type="body">{renderedCellValue}</Text>,
        size: 200,
        muiTableBodyCellProps: {
          align: 'left',
          style: { verticalAlign: 'top' },
        },
      },
      {
        accessorKey: 'admitFacility',
        header: 'Location',
        Cell: ({ renderedCellValue }) => <Text type="body">{renderedCellValue}</Text>,
        size: 200,
        muiTableBodyCellProps: {
          align: 'left',
          style: { verticalAlign: 'top' },
        },
      },
      {
        accessorKey: 'principalDiagnoses',
        header: 'Principal Diagnosis',
        Cell: ({ renderedCellValue }) => <Text type="body">{renderedCellValue}</Text>,
        size: 200,
        enableSorting: false,
        muiTableBodyCellProps: {
          align: 'left',
          style: { verticalAlign: 'top' },
        },
      },
      {
        accessorKey: 'dischargeDisposition',
        enableColumnFilter: false,
        header: 'Discharge Disposition',
        minSize: 150,
        Cell: ({ cell }) => {
          const text = cell.getValue<string>();
          return <Text>{text}</Text>;
        },
      },
    ],
    [arnIsOpen, setArnIsOpen, setArnAdmit]
  );

  const table = useMaterialReactTable({
    columns,
    data: admitsData,
    defaultColumn: {
      minSize: 25,
      size: 50,
      maxSize: 200,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: 'calc(100vh - 215px)',
      },
    },
    initialState: {
      sorting: [
        {
          id: 'admitDate',
          desc: true,
        },
      ],
    },
    ...tableConfig,
  });

  return (
    <div
      style={{
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <MaterialReactTable table={table} />
    </div>
  );
};

export default AdmitsList;

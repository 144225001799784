import PropTypes from 'prop-types';

const EventListPropType = PropTypes.shape({
  id: PropTypes.number,
  page: PropTypes.string,
  action: PropTypes.string,
  time: PropTypes.string,
  trackingSessionID: PropTypes.number,
});

export default EventListPropType;

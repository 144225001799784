import React, { useEffect } from 'react';

import { useDispatch, useSelector } from '@global-state';
import { getUserInfo } from '@global-state/redux/userSettingsSlice';

import { SpinnerOrError } from '@intus-ui';
import HomePage from './HomePage/HomePage';

const HomeRenderer = () => {
  const dispatch = useDispatch();
  const doGetUserInfo = true;
  useEffect(() => {
    dispatch(getUserInfo(doGetUserInfo));
  }, [doGetUserInfo]);

  const userSettings = useSelector((state) => state.userSettings);
  const { profile } = userSettings;

  if (!profile) {
    return <SpinnerOrError />;
  }

  return <HomePage />;
};

export default HomeRenderer;

import React, { useEffect, useState } from 'react';
import { useTwoFactorGenerateMutation, useTwoFactorConfirmMutation } from '@api';
import { Button } from '@intus-ui/components/index';
import { useHistory, useLocation } from 'react-router-dom';
import BoxOnBlue from '@intus-ui/components/BoxOnBlue';
import { useDispatch } from 'react-redux';
import QRCodeImage from './QRCodeImage';
import TwoFactorCode from './TwoFactorCode';
import onSuccessfulLogin from './onSuccessfulLogin';

const TwoFactorSetup = () => {
  const [pressedContinue, setPressedContinue] = useState(false);
  const history = useHistory();
  const { state } = useLocation();
  const [generate, generateResult] = useTwoFactorGenerateMutation();
  const [confirm, confirmResult] = useTwoFactorConfirmMutation();
  const [hideError, setHideError] = useState(false);
  const dispatch = useDispatch();

  // If the user is missing a temporary auth token, redirect them to the login page.
  if (!state) {
    history.push('/');
    return null;
  }

  const { jwt } = state;
  const queryParams = new URLSearchParams(window.location.search);

  if (confirmResult?.data?.token) {
    onSuccessfulLogin({
      dispatch,
      history,
      queryParams,
      result: confirmResult.data,
    });
  }

  // As soon as the page loads, request a new secret.
  useEffect(() => {
    generate(jwt);
  }, []);

  let message;
  let messageClass = '';
  if (confirmResult.isSuccess) {
    message = 'Successfully set up two-factor authentication!';
    messageClass = 'text-success';
  } else if (confirmResult.isLoading) {
    message = 'Verifying...';
    messageClass = 'text-secondary';
  } else if (confirmResult.isError && !hideError) {
    message = 'Could not confirm. Please try again using the latest code.';
    messageClass = 'text-danger';
  }

  const preContinue = () => (
    <>
      <div className="mb-3">
        Your administrator requires you to use two-factor authentication. You will need your
        smartphone (iOS or Android) to continue.
      </div>
      <Button onClick={() => setPressedContinue(true)}>Continue</Button>
    </>
  );

  const postContinue = () => (
    <>
      <h1 style={{ fontSize: '1rem' }}>Instructions</h1>
      <div className="mb-3">
        On your smartphone, install Google Authenticator. Then, add Intus Care to the app by
        scanning the QR code below.
      </div>
      <div className="d-flex flex-row justify-content-center">
        {generateResult?.data?.uri && (
          <QRCodeImage
            url={generateResult.data.uri}
            style={{ width: '12rem', height: '12rem' }}
            className="border rounded mt-2"
          />
        )}
      </div>
      <h1 style={{ fontSize: '1rem' }} className="mt-3">
        Confirmation
      </h1>
      <div>
        Once you have added Intus Care to Google Authenticator, enter the six-digit code you see.
        Doing so will turn on two-factor authentication.
      </div>
      <div className="mt-3">
        <TwoFactorCode
          disabled={confirmResult.isSuccess}
          onAllDigitsEntered={(code) => {
            confirm({ jwt, token: code });
            setHideError(false);
          }}
          onChange={() => setHideError(true)}
        />
        {message && <div className={`${messageClass} mt-3`}>{message}</div>}
        {confirmResult.isSuccess && (
          <div className="mt-3">
            <Button onClick={() => history.push('/dashboard')} style={{ width: '100%' }}>
              Go To Dashboard
            </Button>
          </div>
        )}
      </div>
    </>
  );

  return (
    <BoxOnBlue logo>
      <div className="d-flex flex-column">
        {!pressedContinue && preContinue()}
        {pressedContinue && postContinue()}
      </div>
    </BoxOnBlue>
  );
};

export default TwoFactorSetup;

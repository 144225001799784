import React, { useMemo } from 'react';
import { useDispatch, useSelector } from '@global-state';
import '@intus-ui/styles/Login.scss';
import '@intus-ui/styles/WelcomeFlow.scss';
import { updateUserSettings, setPage } from '@global-state/redux';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import { Input, Text, useParticipantFilters } from '@intus-ui';
import { setDefaultFacility, setDefaultTeam } from '@global-state/redux/filtersSlice';
import { getStringReplacement } from '@util/stringReplacements';
import WelcomeBox from './WelcomeBox';
import ModalButtons from './ModalButtons';

const ChooseTeamDayCenter = () => {
  const dispatch = useDispatch();

  const userSettings = useSelector((state) => state.userSettings);
  const page = useSelector((state) => state.welcome.page);
  const defaultTeam = useSelector((state) => state.filters.defaultTeam);
  const defaultFacility = useSelector((state) => state.filters.defaultFacility);

  const { teams, facilities, setTeams, setFacilities } = useParticipantFilters();

  const teamsWithAll = useMemo(() => ['All', ...teams], [teams]);
  const facilitiesWithAll = useMemo(() => ['All', ...facilities], [facilities]);

  function onChangeTeam(team) {
    dispatch(setDefaultTeam(team));

    if (team === 'All') {
      setTeams(teams);
    } else {
      setTeams([team]);
    }
  }

  function onChangeFacility(facility) {
    dispatch(setDefaultFacility(facility));

    if (facility === 'All') {
      setFacilities(facilities);
    } else {
      setFacilities([facility]);
    }
  }

  const { profile } = userSettings;
  if (!profile) {
    return <SpinnerOrError error="Could not load the page. Please refresh and try again. " />;
  }
  const { setting } = profile;
  const updated = { ...setting, team: defaultTeam, facility: defaultFacility };

  const modalButtons = (
    <ModalButtons
      onClick={() => {
        dispatch(updateUserSettings({ ...profile, settings: updated }));
        dispatch(setPage(page + 1));
      }}
    />
  );

  const teamReplacement = getStringReplacement('Team');
  const centerReplacement = getStringReplacement('Center');

  return (
    <WelcomeBox buttons={modalButtons}>
      <div className="d-flex flex-column align-items-center justify-content-between py-4 w-100">
        <Text type="title" color="navy">
          Now, let&apos;s get started customizing your account.
        </Text>
        <Text type="body" className="pt-3">
          {`Please select a default ${teamReplacement.toLowerCase()} and ${centerReplacement.toLowerCase()} for your display.`}
        </Text>
        <div className="d-flex flex-row align-items-center justify-content-center py-5">
          <div className="text-align-left">
            <Text wrapper="div" type="subtitle">
              {teamReplacement}
            </Text>
            <Input
              id="Team"
              type="select"
              rightIcon="caret-down"
              name={teamReplacement}
              options={teamsWithAll}
              value={defaultTeam}
              onChange={(value) => onChangeTeam(value)}
              style={{
                ...dropdownStyles.input,
                marginRight: 10,
              }}
            />
          </div>
          <div className="text-align-left">
            <Text type="subtitle">{centerReplacement}</Text>
            <Input
              id="Facility"
              type="select"
              rightIcon="caret-down"
              name={centerReplacement}
              options={facilitiesWithAll}
              value={defaultFacility}
              onChange={(value) => onChangeFacility(value)}
              style={{
                ...dropdownStyles.input,
              }}
            />
          </div>
        </div>
      </div>
    </WelcomeBox>
  );
};

const dropdownStyles = {
  input: {
    cursor: 'pointer',
    textAlign: 'left',
  },
};

export default ChooseTeamDayCenter;

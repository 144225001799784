import { getYearOfDate } from '@util/dateFunctions';

/**
 * Maps a patient from the API to a patient for the <ParticipantList> component
 */
export function mapPatient(patient) {
  return {
    ...patient,
    id: patient.id,
    firstName: patient.firstName,
    lastName: patient.lastName,
    memberId: patient.memberId,
    acuityScore: (patient.acuityLevels[0] || {}).acuityScore,
    riskGroup: (patient.acuityLevels[0] || {}).riskGroup,
    team: patient.team,
    facility: patient.facility,
    livingSituation: (patient.acuityLevels[0] || {}).livingSituation,
    age: getYearOfDate(patient.dateOfBirth),
    instanceCount: patient.instanceCount,
    organizationId: patient.organizationId,
  };
}

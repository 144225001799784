import { whitelist } from './constants';

export const override = {
  /**
   * JavaScript built-in functions and objects overrides
   */
  localStorage: () => {
    /**
     *
     * This function overrides/extends window.localStorage.clear() to preserve
     * localStorage keys listed in the whitelist.localStorage array.
     * Use case: We want to permanently preserve the 'surveys' localStorage entry.
     *
     */
    const clearStorage = Storage.prototype.clear;
    Storage.prototype.clear = function clear(...args) {
      const localStorageItems = { ...localStorage };
      clearStorage.apply(this, args);
      whitelist.localStorage.forEach((item) => {
        if (localStorageItems[item]) {
          window.localStorage.setItem(item, localStorageItems[item]);
        }
      });
    };
  },
};

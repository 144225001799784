import React, { useEffect, useRef, useState } from 'react';
import * as pbi from 'powerbi-client';
import * as userTracking from '@util/userTracking';
import { getPowerBiEmbedData } from '@api';
import './PowerBIEmbed.css';
import LoadingScreen from '@intus-ui/components/LoadingScreen';
import { useHistory, useParams } from 'react-router';

// Instantiate the PowerBI service
const powerbi = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory
);

const PowerBIEmbed = () => {
  const history = useHistory();
  // get report id from the url params
  const { reportNameParam } = useParams();
  const [loading, setLoading] = useState(true);
  const [reportInstance, setReportInstance] = useState(null); // Store the PowerBI report instance
  const reportContainerRef = useRef(null);

  useEffect(() => {
    // Ensure the container reference is available

    const embedReport = async () => {
      setLoading(true);
      const response = await getPowerBiEmbedData(reportNameParam);
      const embedData = await response.json();
      if (reportInstance) {
        powerbi.reset(reportContainerRef.current); // Reset the container
        setReportInstance(null); // Clear the stored instance
      }

      const reportLoadConfig = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: embedData.accessToken,
        embedUrl: embedData.embedUrl[0].embedUrl,
        // Optional: settings for report appearance
        settings: {
          hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent,
          background: pbi.models.BackgroundType.Transparent,
          border: {
            show: false,
          },
          borderRadius: 0,
        },
      };

      // Embedding the report
      const report = powerbi.embed(reportContainerRef.current, reportLoadConfig);
      setReportInstance(report); // Store the new report instance

      // Event handlers for the report lifecycle
      report.off('loaded');
      report.on('loaded', () => {
        setLoading(false);
      });

      report.off('dataHyperlinkClicked');
      report.on('dataHyperlinkClicked', (clickDetails) => {
        // open the url in this window and preserve history with back button
        history.push(new URL(clickDetails?.detail?.url).pathname, '_self');
      });

      report.off('rendered');
      report.on('rendered', () => userTracking.logEvent('Report render successful'));

      report.off('error');
      report.on('error', (event) => {
        const errorMsg = event.detail;
        userTracking.logEvent(`Error embedding the report: ${errorMsg}`);
      });
    };

    const currentReportContainerRef = reportContainerRef.current;

    if (reportContainerRef.current) {
      embedReport();
    }

    return () => {
      if (reportInstance) {
        powerbi.reset(currentReportContainerRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, reportNameParam]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {loading && <LoadingScreen />}

      <div
        className="powerbi-container"
        ref={reportContainerRef}
        style={{ height: '100%', width: '100%', display: loading ? 'none' : 'block' }}
      />
    </div>
  );
};

export default PowerBIEmbed;

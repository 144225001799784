import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { stringToClassname } from '@util/utilFunctions';

import { setPage } from '@global-state/redux/profileSlice';

import { Button } from 'react-bootstrap';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import '@intus-ui/styles/Profile.scss';
import './StatBar.scss';

const StatBar = ({ statData, linkTo, setProfilePage }) => (
  <Button
    id={`recentStat-${stringToClassname(statData.name)}`}
    className="stat-bar- mt-1 mb-2 py-2 border-0 hover w-100 d-flex align-items-center justify-content-between flex-row"
    onClick={() => {
      if (linkTo) setProfilePage(linkTo);
    }}
  >
    <h6 className="bg-white px-1 rounded-sm text-secondary font-weight-normal w-25">
      {statData.count}
    </h6>
    <p className="w-100 text-xs-p text-dark d-flex flex-row align-items-start pl-2 text-align-left">
      {statData.name}
    </p>
    {linkTo ? (
      <div>
        <ArrowForwardIosIcon
          style={{
            fontSize: 'large',
          }}
          className="justify-content-center text-secondary pb-1"
        />
      </div>
    ) : null}
  </Button>
);

StatBar.propTypes = {
  statData: PropTypes.shape({ name: PropTypes.string, count: PropTypes.number }),
  setProfilePage: PropTypes.func.isRequired,
  linkTo: PropTypes.string,
};

StatBar.defaultProps = {
  statData: null,
  linkTo: null,
};

const mapDispatch = (dispatch) => ({
  setProfilePage: (page) => dispatch(setPage(page)),
});

export default withRouter(connect(null, mapDispatch)(StatBar));

import PropTypes from 'prop-types';

export const HospitalizationsModalPropType = PropTypes.shape({
  erInpatient: PropTypes.shape({
    er: {
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    },
    inpatient: {
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    },
  }),

  inpatientReadmission: PropTypes.shape({
    val: PropTypes.number,
    ids: PropTypes.arrayOf(PropTypes.number),
  }),

  totalLengthInpatientStay: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.number,
      avgLength: PropTypes.number,
    })
  ),

  admissions: PropTypes.shape({
    er: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.number,
        amount: PropTypes.number,
      })
    ),
    inpatient: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.number,
        amount: PropTypes.number,
      })
    ),
  }),
});

export default HospitalizationsModalPropType;

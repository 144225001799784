import { Tag } from '@intus-ui/components/Tags';
import TooltipComponent from '@intus-ui/components/TooltipComponent';

export const ParticipantCohortTags = ({ participant }) => {
  const cohorts = participant?.cohorts ?? [];

  const firstTwoCohorts = cohorts.slice(0, 2);
  const otherCohorts = cohorts.slice(2);

  const tooltipAnchorId = `initiativeCohortTag-${participant.id}`;

  return (
    <div style={{ display: 'flex', flex: 1, gap: 10 }}>
      {firstTwoCohorts.map((cohort, index) => {
        const key = `cohort-${index}`;
        return (
          <Tag key={key} style={{ maxWidth: 150 }} color={cohort.color} ellipsis>
            {cohort.name}
          </Tag>
        );
      })}
      {!!otherCohorts.length && (
        <TooltipComponent
          anchorId={tooltipAnchorId}
          content={
            <div style={{ display: 'flex', gap: 5, flexDirection: 'column', overflow: 'hidden' }}>
              {otherCohorts.map((cohort, index) => {
                const key = `cohort-${index}`;
                return (
                  <Tag key={key} style={{ maxWidth: 350, height: 'auto' }} color={cohort.color}>
                    {cohort.name}
                  </Tag>
                );
              })}
            </div>
          }
        >
          <Tag
            id={tooltipAnchorId}
            style={{ maxWidth: 150, cursor: 'pointer' }}
            type="intusMade"
            color="gray"
            hoverColor="highlight"
            ellipsis
          >
            {`+${otherCohorts.length} more`}
          </Tag>
        </TooltipComponent>
      )}
    </div>
  );
};

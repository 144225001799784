import { post } from '@api/apiMethods';
import { IPolypharmacyFilters } from '@api/polypharmacy/types/IPolypharmacyFilters';
import { TagProps } from '@intus-ui/components/Tags/Tag';
import { IAllStarsMeasures } from 'Polypharmacy/types/STARSMeasures';
import { IPriority } from 'Polypharmacy/types/Priority';

export type IRisingStarsData = {
  patientAdherence: IPatientRisingStarsData[];
};

export type IPatientRisingStarsData = {
  id: number;
  name: string;
  age: number;
  hospice: boolean;
  risingStarsData: IRisingStarMeasureData[];
};

export type IRisingStarMeasureData = {
  measure: IAllStarsMeasures;
  fillCount: number;
  fillDate: string;
  percentageDaysCovered: number;
  fillDueDate: string | null;
  gapDays: number;
  minCoverageDays: number;
  adherenceComplianceDate: string | null;
  actionDate: string | null;
  status: IPatientFirstFillStatus | null;
  medClasses: string[];
  priority: IPriority;
};

export const allPatientFirstFillStatus = [
  // Note that needs_review is special in that it is the default status.
  // If the patient does not have a status they are considered to be in `needs_review`
  'needs_review',
  'continued',
  'stopped',
  'under_review',
] as const;

export type IAllPatientFirstFillStatus = (typeof allPatientFirstFillStatus)[number];

export type IPatientFirstFillStatus = {
  status: IAllPatientFirstFillStatus;
};

export const firstFillStatusToDescription: Record<IAllPatientFirstFillStatus, string> = {
  needs_review: 'Needs review',
  continued: 'Continued',
  stopped: 'Stopped',
  under_review: 'Under review',
};

export const firstFillStatusToColor: Record<IAllPatientFirstFillStatus, TagProps['color']> = {
  needs_review: 'gray', // color not used for needs_review
  continued: 'green',
  stopped: 'orange',
  under_review: 'blue',
};

export function getRisingStars(
  filters: IPolypharmacyFilters,
  patientIds?: number[],
  ignoreSingleFillRequirement?: boolean
) {
  return post<IRisingStarsData>(`polypharmacy/rising-stars`, {
    filters,
    patientIds,
    ignoreSingleFillRequirement,
  });
}

/* eslint-disable no-nested-ternary */

import { useRef, useState, useEffect } from 'react';
import { useOutsideClick, useHover } from '@intus-ui/hooks';
import { textColors, input } from '@intus-ui/styles/SecondaryColors';
import { searchFilter } from '@util/utilFunctions';
import DropdownList from '@intus-ui/components/Dropdown/DropdownList';
import DropdownItem from '@intus-ui/components/Dropdown/DropdownItem';
import Text from '@intus-ui/components/Text';
import Icon from '../Icon';
import './input.css';

const SearchDropdown = ({
  id,
  leftIcon,
  rightIcon,
  name,
  suggestionLabel,
  noMatchLabel,
  value,
  pill,
  options,
  onChange,
  fluid,
  width,
  style,
  disabled,
  children,
  placeholder,
  ellipsis,
  dataType: _dataType,
  ...rest
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [hoverRef, isHovered] = useHover();
  const dropDownRef = useRef(null);
  const inputRef = useRef(null);

  const handleTriggerDropdown = () => {
    if (disabled) return;
    inputRef.current.focus();
    setIsCollapsed(!isCollapsed);
  };

  // Closes dropdown when clicked outside
  useOutsideClick(dropDownRef, () => {
    setIsCollapsed(false);
  });

  const handleOnChange = (e) => {
    const keyword = e.target.value;
    setIsCollapsed(true);
    const filterResults = searchFilter(keyword, options);
    const match = filterResults.find((el) => el.toLowerCase() === keyword.toLowerCase());
    setFilteredOptions(filterResults);
    setSearchKeyword(match);
    onChange(match);
  };

  const handleOnFocus = () => {
    setIsCollapsed(true);
  };

  useEffect(() => {
    setSearchKeyword(value);
  }, [value]);

  useEffect(() => {
    if (searchKeyword === '') {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(filteredOptions);
    }
  }, [filteredOptions, options]);

  return (
    <div
      id={id || name}
      name={name}
      ref={dropDownRef}
      style={{
        width: fluid ? 'max-content' : width,
        inlineSize: fluid && 'max-content',
        blockSize: fluid && 'max-content',
        whiteSpace: fluid && 'nowrap',
        position: 'relative',
        ...style,
      }}
      {...rest}
    >
      <div
        ref={hoverRef}
        style={{
          borderRadius: 5,
          border: '1px solid',
          borderColor: isCollapsed ? textColors.link : disabled ? '#C8C8C8' : textColors.caption,
          display: 'flex',
          alignItems: 'center',
          backgroundColor:
            !disabled && isHovered ? input.hover : disabled ? textColors.disabled : input.default,
          height: '35px',
        }}
      >
        {leftIcon && (
          <div
            onClick={handleTriggerDropdown}
            onKeyDown={handleTriggerDropdown}
            role="none"
            style={{ padding: '0 10px' }}
            disabled={disabled}
          >
            <Icon
              name={leftIcon}
              alt={leftIcon}
              style={{
                color: disabled ? '#C8C8C8' : isCollapsed ? input.active : input.collapsed,
              }}
            />
          </div>
        )}

        <div style={{ display: 'flex', alignItems: 'center', gap: 5, flex: 1 }}>
          {pill && <Text type="body">{pill}: </Text>}
          <input
            className="inputComponent"
            autoComplete="off"
            ref={inputRef}
            style={{
              width: '100%',
              flex: !leftIcon && 1,
              height: '100%',
              border: 'none',
              outlineWidth: 0,
              backgroundColor: disabled
                ? textColors.disabled
                : isHovered
                ? input.hover
                : input.default,
              textOverflow: ellipsis ? 'ellipsis' : 'clip',
            }}
            placeholder={placeholder}
            type="text"
            value={searchKeyword}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            disabled={disabled}
          />
        </div>
        {rightIcon && (
          <div
            onClick={handleTriggerDropdown}
            onKeyDown={handleTriggerDropdown}
            role="none"
            style={{ padding: '0 10px' }}
            disabled={disabled}
          >
            <Icon
              alt={rightIcon}
              name={rightIcon}
              style={{
                color: disabled ? '#C8C8C8' : isCollapsed ? input.active : input.collapsed,
              }}
            />
          </div>
        )}
      </div>

      {isCollapsed && (
        <DropdownList styles={{ width: fluid ? '100%' : width, overflowY: 'auto' }}>
          {filteredOptions && filteredOptions.length === 0 ? (
            <DropdownItem empty suggestionLabel={suggestionLabel} noMatchLabel={noMatchLabel} />
          ) : (
            filteredOptions.map((option, i) => {
              const key = i;

              return (
                <DropdownItem
                  key={key}
                  index={i}
                  suggestionLabel=""
                  option={option}
                  onSelect={onChange}
                  setIsCollapsed={setIsCollapsed}
                />
              );
            })
          )}
        </DropdownList>
      )}

      {isCollapsed && <DropdownList styles={{ width }}>{children}</DropdownList>}
    </div>
  );
};

export default SearchDropdown;

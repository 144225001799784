import { colorPalette } from './colors';

export const alertColors = { danger: '#cc6262', warning: '#e4c20f', success: '#7ace46' };

export const riskColors = {
  low: 'rgb(148, 200, 249)',
  moderate: 'rgb(25, 148, 187)',
  high: 'rgb(234, 197, 5)',
  highest: 'rgb(204, 98, 98)',
};

export const riskColorsMuted = {
  low: 'rgb(148, 200, 249, 0.16)',
  moderate: 'rgb(25, 148, 187, 0.16)',
  high: 'rgb(234, 197, 5, 0.16)',
  highest: 'rgb(204, 98, 98, 0.16)',
};

export const shadesOfBlue = {
  intusNavy: '#052d8f',
  hoverBlue: '#4D7EF8',
  hoverBlue16: 'rgba(77, 126, 248, .16)',
  hoverBlue32: 'rgba(77, 126, 248, .32)',
  intusNavy40: 'rgba(5, 45, 143, 0.4)',
  intusNavy60: 'rgba(5, 45, 143, 0.6)',
};

export const defaultChartColors = {
  skyBlue: '#4DBAF8',
  tealBlue: '#60D1D9',
  lightLilac: '#B9A1EB',
  deepBlue: '#2A3CDD',
  mediumLilac: '#6E7BEF',
  fadedSkyBlue: '#479ECE',
  fadedTealBlue: '#8BC8CC',
};

export const initiativeChartColors = {
  blue: colorPalette.blue['100'],
  orange: colorPalette.orange['100'],
  purple: colorPalette.purple['100'],
  green: colorPalette.green['100'],
  pink: colorPalette.pink['100'],
  maroon: colorPalette.maroon['100'],
  teal: colorPalette.teal['100'],
  pine: colorPalette.pine['100'],
  indigo: colorPalette.indigo['100'],
  brown: colorPalette.brown['100'],
  gray: colorPalette.gray['100'],
};

export const tagColors = {
  ...initiativeChartColors,
  white: '#BACEFF',
  white40: 'white',
  blue40: colorPalette.blue['40'],
  orange40: colorPalette.orange['40'],
  purple40: colorPalette.purple['40'],
  green40: colorPalette.green['40'],
  pink40: colorPalette.pink['40'],
  maroon40: colorPalette.maroon['40'],
  teal40: colorPalette.teal['40'],
  pine40: colorPalette.pine['40'],
  indigo40: colorPalette.indigo['40'],
  brown40: colorPalette.brown['40'],
  gray40: colorPalette.gray['40'],
};

export const cohortChangesColors = {
  disabled: '#C8C8C8',
  red: '#CC6262',
  blue: '#2E62E7',
};

export const textColors = {
  black: '#000000',
  white: '#FFFFFF',
  body: '#404040',
  caption: '#9D9D9D',
  navy: shadesOfBlue.intusNavy,
  link: '#2E62E7',
  error: '#CD3B3B',
  disabled: '#F6F7FA',
  success: '#4DC3CA',
  gray50: '#282828',
  countRed: cohortChangesColors.red,
  countBlue: cohortChangesColors.blue,
  countGreen: colorPalette.green['100'],
  countDisabled: cohortChangesColors.disabled,
  danger: alertColors.danger,
};

export const quadColors = { disabled: '#C8C8C8', active: '#2E62E7' };
export const quadBackgroundColors = { default: '#FFFFFF', secondary: '#E4ECFF', grey: '#F6F7FA' };
export const containerColors = { default: '#FFFFFF' };
export const input = {
  boxShadow: 'rgba(0, 0, 0, 0.2)',
  default: '#FFFFFF',
  hover: '#e3eafe',
  collapsed: '#9D9D9D',
  disabled: '#EFF0F3',
  active: '#2E62E7',
};
export default riskColors;

import PropTypes from 'prop-types';

import { Divider } from '..';

// A wrapper component that renders a set of buttons, with an optional divider above them
// Buttons (or any component really) are specified as children components within
// this ButtonGroup component, this is merely a wrapper to put them in a flex-box
const ButtonGroup = ({ align, showDivider, children, ...rest }) => {
  let justifyContent;
  switch (align) {
    case 'left':
      justifyContent = 'flex-start';
      break;
    case 'center':
      justifyContent = 'flex-center';
      break;
    case 'right':
    default:
      justifyContent = 'flex-end';
      break;
  }

  return (
    <div style={{ width: '100%' }} {...rest}>
      {showDivider && <Divider />}
      {children && <div style={{ ...styles.container, justifyContent }}>{children}</div>}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    paddingTop: 15,
    alignItems: 'center',
  },
};

ButtonGroup.propTypes = {
  align: PropTypes.string,
  showDivider: PropTypes.bool,
  children: PropTypes.node,
};

ButtonGroup.defaultProps = {
  align: 'right',
  showDivider: true,
  children: undefined,
};

export default ButtonGroup;

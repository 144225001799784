import { Text } from '@intus-ui';
import useEventListener from '@intus-ui/components/useEventListener';
import { formatDate, isSameDate, newDate, newDateInFormat } from '@util/dateFunctions';
import { useCallback, useMemo, useState } from 'react';
import { getCohortLineChartColorValue } from 'Initiatives/MetricChart/cohortLineChartColors';
import { Tooltip } from 'react-tooltip';

/**
 * Helper hook to display a tooltip over the hovered point in the trial metric chart.
 *
 * This hook handles rendering the tooltip and positioning it correctly on the chart.
 *
 * Returns a function that can be called in the chart.js `tooltips.custom` option and the
 * JSX to render in the UI.
 */
export function useTrialMetricChartTooltip(trial, metric) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [metricIndex, setMetricIndex] = useState(0);

  const [chartElement, setChartElement] = useState(null);
  const [scrollParent, setScrollParent] = useState(null);

  const hideTooltip = useCallback(() => {
    setIsTooltipVisible(false);
  }, []);

  useEventListener('mouseleave', hideTooltip, chartElement);
  useEventListener('scroll', hideTooltip, scrollParent);

  const tooltipComponent = (
    <TrialMetricChartTooltip
      trial={trial}
      metric={metric}
      tooltipMetricIndex={metricIndex}
      isVisible={isTooltipVisible}
      tooltipPosition={tooltipPosition}
    />
  );

  /**
   * @type {(chart: import('chart.js').Chart<'line'>, tooltipModel: import('chart.js').TooltipModel<'line'>) => void}}
   */
  const setTooltipModel = useCallback((chart, tooltipModel) => {
    // When we first hover the chart, add a mouseleave event to hide the tooltip.
    // We do this as the chart.js tooltip event does not always fire when scrolling or moving the mouse quickly
    // and we end up with 2 tooltips displayed.
    if (!chartElement) {
      setChartElement(chart.canvas);

      const chartScrollParent = getScrollParent(chart.canvas);
      setScrollParent(chartScrollParent);
    }

    // Hide the tooltip if we're hovering an area outside the points.
    if (
      tooltipModel.opacity === 0 ||
      tooltipModel.dataPoints == null ||
      tooltipModel.dataPoints.length === 0
    ) {
      setIsTooltipVisible(false);
      return;
    }

    // There will be multiple dataPoints if there are overlapping points in the graph.
    // Since all cohorts have data over the same time period, we can just use the first index.
    setMetricIndex(tooltipModel.dataPoints[0].dataIndex);
    setIsTooltipVisible(true);

    // See: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html#external-custom-tooltips
    // The x and y are relative to the entire window.
    const position = chart.canvas.getBoundingClientRect();

    const x = position.left + window.pageXOffset + tooltipModel.caretX;
    const y = position.top + window.pageYOffset + tooltipModel.caretY;
    setTooltipPosition({ x, y });
  }, []);

  return {
    tooltipComponent,
    setTooltipModel,
  };
}

const TrialMetricChartTooltip = ({
  trial,
  metric,
  tooltipMetricIndex,
  isVisible,
  tooltipPosition,
}) => {
  const anchorId = `trialMetricChartTooltip-${trial.id}`;

  /** This is the key of the hovered result, in a format like `04/23` */
  const hoveredResultKey = useMemo(() => {
    const cohort = metric.cohorts[0];

    // This should never happen but bail out if it does.
    if (cohort == null || cohort.results.length === 0) {
      return null;
    }

    const resultMonthString = Object.keys(cohort.results)[tooltipMetricIndex];
    if (resultMonthString == null) {
      return null;
    }

    return resultMonthString;
  }, [metric, tooltipMetricIndex]);

  /** The date the user hovered on the tooltip.  */
  const date = useMemo(() => {
    if (hoveredResultKey == null) {
      return null;
    }

    const month = newDateInFormat(hoveredResultKey, 'MM/YY');

    return month.toDate();
  }, [hoveredResultKey]);

  /** An array of objects, each of which represents a single cohort and its data on the tooltip */
  const cohortTooltipRow = useMemo(() => {
    if (hoveredResultKey == null) {
      return [];
    }
    /** @type {{ id: number, name: string, color: string, value: number }[]} */
    const cohortsAndData = [];

    for (let i = 0; i < metric.cohorts.length; ++i) {
      const cohort = metric.cohorts[i];

      const result = cohort.results[hoveredResultKey]?.value;
      if (result == null) {
        continue;
      }

      cohortsAndData.push({
        id: cohort.id,
        name: cohort.name,
        color: getCohortLineChartColorValue(cohort.color),
        value: result,
      });
    }

    return cohortsAndData;
  }, [hoveredResultKey]);

  const dateText = !isSameDate(newDate(), date, 'month')
    ? formatDate(date, "MMM 'YY")
    : `Data so far in ${formatDate(date, "MMM 'YY")} (MTD)`;

  return (
    <>
      <div
        id={anchorId}
        style={{
          display: isVisible ? 'block' : 'none',
          position: 'absolute',
          left: tooltipPosition.x,
          top: tooltipPosition.y,
        }}
      />
      <Tooltip
        anchorId={anchorId}
        style={tooltipStyles.tooltipContainer}
        content={
          <div>
            <div>
              <Text type="caption-bold">{dateText}</Text>
            </div>
            <div>
              <Text type="caption">{metric.name}</Text>
            </div>
            {cohortTooltipRow.map((cohort) => {
              return (
                <div
                  key={cohort.id}
                  style={{ display: 'flex', alignItems: 'center', paddingTop: 5 }}
                >
                  <div style={{ backgroundColor: cohort.color, width: 12, height: 12 }} />
                  <Text type="caption-bold" style={{ paddingLeft: 4 }}>
                    {cohort.name}:
                  </Text>
                  <Text type="caption" style={{ paddingLeft: 4 }}>
                    {Number(cohort.value.toFixed(1))}
                  </Text>
                </div>
              );
            })}
          </div>
        }
        isOpen={isVisible}
      />
    </>
  );
};

const tooltipStyles = {
  tooltipContainer: {
    color: '#222',
    background: '#ffffff',
    borderRadius: '5px',
    padding: '15px',
    zIndex: '100000',
    filter: 'drop-shadow(2px 2px 16px rgba(157, 157, 157, 0.22))',
    transition: 'none',
    opacity: '100%',
  },
};

function getScrollParent(node) {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }
  return getScrollParent(node.parentNode);
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import userSettingsPropType from 'Settings/userSettingsPropType';
import { getCardPreview } from '@global-state/redux/homepageSlice';
import { SpinnerOrError } from '@intus-ui';
import { getSessionUser } from '@util/session';
import CustomListPreview from './CustomListPreview';
import '@intus-ui/styles/Utilities.scss';
import PreviewQuad from './PreviewQuad';

class HomePageQuadContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardSelections: undefined,
      profile: undefined,
    };
  }

  componentDidMount() {
    const { userSettings, selectedFilters, doGetCardPreviews, startDate, endDate } = this.props;
    const user = getSessionUser();
    const { profile } = userSettings;
    const { setting } = user;
    // selected card options (array of 3 strings)
    let { homePageBoxes } = setting;
    if (!homePageBoxes) {
      homePageBoxes = ['riskAcuity', 'hospitalizations', 'falls'];
    }
    this.setState({
      cardSelections: homePageBoxes,
      profile,
    });
    // use relative past date function from pptRedesign focus view PR
    doGetCardPreviews(homePageBoxes, startDate, endDate, selectedFilters);
  }

  componentDidUpdate(prevProps) {
    const { selectedFilters, doGetCardPreviews, startDate, endDate } = this.props;
    const { cardSelections } = this.state;
    if (selectedFilters !== prevProps.selectedFilters) {
      doGetCardPreviews(cardSelections, startDate, endDate, selectedFilters);
    }
  }

  render() {
    const { previewA, previewB, previewC, error } = this.props;
    const { profile, cardSelections } = this.state;
    if (!profile) {
      return (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <SpinnerOrError error={error} />
        </div>
      );
    }
    return (
      <div className="h-100 w-100">
        <div className="h-50 w-100 d-flex flex-row">
          <PreviewQuad boxName={cardSelections[0]} content={previewA?.content} error={error} />
          <PreviewQuad boxName={cardSelections[1]} content={previewB?.content} error={error} />
        </div>
        <div className="h-50 w-100 d-flex flex-row">
          <PreviewQuad
            boxName={cardSelections[2]}
            content={previewC?.content}
            growNum={8}
            error={error}
          />
          <div style={{ flexGrow: 4 }} className="d-flex flex-basis-0 p-3 overflow-hidden">
            <CustomListPreview />
          </div>
        </div>
      </div>
    );
  }
}

HomePageQuadContent.propTypes = {
  userSettings: userSettingsPropType,
  doGetCardPreviews: PropTypes.func.isRequired,
  previewA: PropTypes.shape({
    boxName: PropTypes.string,
    content: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
  }),
  previewB: PropTypes.shape({
    boxName: PropTypes.string,
    content: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
  }),
  previewC: PropTypes.shape({
    boxName: PropTypes.string,
    content: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
  }),
  error: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

HomePageQuadContent.defaultProps = {
  userSettings: undefined,
  previewA: undefined,
  previewB: undefined,
  previewC: undefined,
  error: undefined,
};

const mapState = (state) => ({
  userSettings: state.userSettings,
  previews: state.homepage.previews,
  previewA: state.homepage.previewA,
  previewB: state.homepage.previewB,
  previewC: state.homepage.previewC,
  error: state.homepage.homeError,
  startDate: state.filters.startDate,
  endDate: state.filters.endDate,
});

const mapDispatch = (dispatch) => ({
  doGetCardPreviews: (cardSelections, startDate, endDate, selectedFilters) => {
    dispatch(getCardPreview(cardSelections[0], startDate, endDate, 1, selectedFilters));
    dispatch(getCardPreview(cardSelections[1], startDate, endDate, 2, selectedFilters));
    dispatch(getCardPreview(cardSelections[2], startDate, endDate, 3, selectedFilters));
  },
});

export default withRouter(connect(mapState, mapDispatch)(HomePageQuadContent));

/* eslint-disable camelcase */
import React from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import Text from '@intus-ui/components/Text';
import { formatDate } from '@util/dateFunctions';
import defaultTableConfig from './defaultTableConfig';

type TableRow = {
  key: string;
  header: string;
};
interface DatagridProps {
  data: any[];
  firstRow: TableRow;
  lastRow: TableRow;
  handleOnClickCell?: (value: React.ReactNode, row: React.ReactNode, column: any) => void;
}

const Datagrid: React.FC<DatagridProps> = ({ data, firstRow, lastRow, handleOnClickCell }) => {
  const firstRowColumnConfig = React.useMemo(
    () => ({
      accessorKey: firstRow.key,
      size: 200,
      header: firstRow.header,
      Cell: ({ renderedCellValue }: { renderedCellValue: React.ReactNode }) => (
        <Text type="caption-bold"> {renderedCellValue?.toLocaleString()}</Text>
      ),
    }),
    [firstRow.key, firstRow.header]
  );

  const lastRowColumnConfig = React.useMemo(
    () => ({
      accessorKey: lastRow.key,
      header: lastRow.header,
      Cell: ({
        renderedCellValue,
        row,
        column,
      }: {
        renderedCellValue: React.ReactNode;
        row: any;
        column: any;
      }) => (
        <Text
          onClick={() => handleOnClickCell && handleOnClickCell(renderedCellValue, row, column)}
          type="caption-bold"
          color="link"
        >
          {renderedCellValue?.toLocaleString()}
        </Text>
      ),
    }),
    [lastRow.key, lastRow.header, handleOnClickCell]
  );

  const generateDataColumns = React.useCallback(
    (data: any[]) => {
      const columns: MRT_ColumnDef<TableRow>[] = [];
      Object.keys(data[0]).forEach((key: string) => {
        if (key !== firstRow.key && key !== lastRow.key) {
          columns.push({
            accessorKey: key,
            header: '',
            Header: () => (
              <Text type="caption-bold" color="gray50">
                {formatDate(key, 'M/DD')}
              </Text>
            ),
            Cell: ({ renderedCellValue, row, column }) => (
              <Text
                onClick={() =>
                  handleOnClickCell && handleOnClickCell(renderedCellValue, row, column)
                }
                type="caption-bold"
                color="link"
              >
                {renderedCellValue?.toLocaleString()}
              </Text>
            ),
          });
        }
      });
      return columns;
    },
    [firstRow.key, handleOnClickCell, lastRow.key]
  );

  const columns = React.useMemo<MRT_ColumnDef<TableRow>[]>(() => {
    const columnData: MRT_ColumnDef<TableRow>[] = [
      firstRowColumnConfig,
      lastRowColumnConfig,
      ...generateDataColumns(data),
    ];

    return columnData;
  }, [data, firstRowColumnConfig, generateDataColumns, lastRowColumnConfig]);

  const table = useMaterialReactTable({
    columns: columns as MRT_ColumnDef<any, any>[],
    data,
    initialState: {
      columnPinning: { left: [String(firstRow.key)], right: [String(lastRow.key)] },
    },
    defaultColumn: {
      minSize: 25,
      size: 50,
      maxSize: 200,
    },
    ...defaultTableConfig,
  });

  return (
    <div
      style={{
        width: '100%',
        maxHeight: '500px',
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <MaterialReactTable table={table} />
    </div>
  );
};

export default Datagrid;
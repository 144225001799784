import { Multiselect } from '@intus-ui/components/Multiselect/Multiselect';
import { useParticipantFilters } from '@intus-ui/components/filters/ParticipantFiltersContext';
import { useMemo } from 'react';
/**
 * Displays a list of extra patient filters that can be defined in the OrganizationSettings.
 *
 * If the org doesn't have any extra filters this renders nothing.
 */
export const PatientGroupFilterList = ({ ...multiselectProps }) => {
  const { groupFilters } = useParticipantFilters();
  const groupFilterTypes = groupFilters.map((item) => item.type);
  const ignoreTypes = ['Team', 'Facility', 'Cohort'];

  // If we have no filters do nothing here.
  if (groupFilters == null || groupFilters.length === 0) {
    return null;
  }
  const filteredGroupFilters = groupFilters.filter((item) => !ignoreTypes.includes(item.type)); // only render custom patient group filters

  return (
    <>
      {filteredGroupFilters.map((filter) => {
        return (
          <PatientGroupDropdown
            {...multiselectProps}
            key={filter.type}
            filter={filter}
            order={groupFilterTypes.findIndex((item) => item === filter.type)}
          />
        );
      })}
    </>
  );
};

/**
 * @param {{ filter: ReturnType<typeof useParticipantFilters>["groupFilters"][number] }} props
 */
const PatientGroupDropdown = ({ filter, order, ...multiselectProps }) => {
  const { selectedFilters, setGroupFilter } = useParticipantFilters();
  const { type } = filter;

  const selectedGroupNames = selectedFilters.groups[type]?.map((g) => g.name);

  const allGroupNames = useMemo(() => {
    return filter.groups.map((g) => g.name);
  }, [filter.groups]);

  const onChange = (newGroupNames) => {
    const groups = filter.groups.filter((g) => newGroupNames.includes(g.name));
    setGroupFilter(
      type,
      groups.map((g) => g.id)
    );
  };

  return (
    <Multiselect
      id={type}
      label={filter.name}
      allowSelectAll
      items={allGroupNames}
      style={{ order }}
      // selectedGroupNames being null means all groups are selected
      // empty array means the user unchecked all the groups.
      selectedItems={selectedGroupNames || allGroupNames}
      onChange={(value) => onChange(value)}
      {...multiselectProps}
    />
  );
};

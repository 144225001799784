import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { editCohort, createCohort, getActivePatients } from '@api';
import { USER_ACCESS_ADMIN, USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import { useQuery, useLazyQuery } from '@api/useQuery';
import { useGetSessionUser } from '@util/session';
import { useForm, Controller } from '@util/forms';
import { useFieldArray } from 'react-hook-form';
import { getStringReplacement } from '@util/stringReplacements';
import { searchFilter } from '@util';

import { ParticipantList, Button, Text, Icon, Input, Checkbox } from '@intus-ui';
import UnsavedChangesModal from 'Initiatives/Trials/TrialsCreateOrEditView/UnsavedChangesModal';
import CohortSearchWrapper from '../CohortSearchWrapper';
import { ListWrapper } from './ListWrapper';
import {
  appendNameToData,
  isCreatingCohortFromInitiative,
  renderRemoveButton,
  renderSharedCheckbox,
  returnToInitiativeFromCohortCreation,
} from './helpers';

import { styles } from './styles';

const CreateFixedCohort = ({ cohortToEdit }) => {
  // USER ACCESS
  const { organizationId, id: userId, access } = useGetSessionUser();
  const canCreateShared = access === USER_ACCESS_ADMIN || access === USER_ACCESS_HEALTH_SYS_ADMIN;

  // STATE SETUP
  const history = useHistory();
  const existingCohort = history?.location?.state;
  const [searchState, setSearchState] = useState(existingCohort?.searchState ?? {});

  // Stores if we've saved successfully or not.
  // Used to stop the alert about unsaved changes;
  const [isSaved, setIsSaved] = useState(false);
  const [view, setView] = useState('list'); // 2 view options: list or search
  const [searchTerm, setSearchTerm] = useState('');
  // A user can only convert a shared cohort to personal if they created the cohort.
  const isSharedCheckboxEnabled =
    canCreateShared && (cohortToEdit == null || cohortToEdit.createdByCurrentUser === true);

  /**
   * FORM LOGIC -------->
   */

  // FORM SETUP
  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      shared: false,
      participants: [],
      searchState: existingCohort?.searchState ?? { view: 'SEARCH_CREATE' },
      ...cohortToEdit,
      ...existingCohort,
    },
  });

  // VALIDATION: MAKE SURE THERE IS AT LEAST ONE PPT SELECTED IN ORDER TO MAKE A COHORT
  const { replace: setSelectedParticipants } = useFieldArray({
    name: 'participants',
    control,
    rules: {
      required: 'Please select one or more participants',
    },
  });

  // GETTING VALUES FROM FORM TO DISPLAY
  const selectedParticipants = getValues('participants');
  const [showCohortDescription, setShowCohortDescription] = useState(
    getValues('description').length > 0
  );

  // WATCHING CHANGES IN THE FORM TO SAVE TO ROUTER STATE
  useEffect(() => {
    const subscription = watch((cohortToSave) => {
      history.replace({
        state: {
          ...cohortToSave,
          searchState: { ...searchState },
        },
      });
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, searchState]);

  // WATCHING CHANGES IN THE SEARCH STATE (FILTERS) TO SAVE TO ROUTER STATE
  useEffect(() => {
    const cohortToSave = getValues();
    history.replace({ state: { ...cohortToSave, searchState } });
  }, [searchState]);

  /**
   * API CALLS -------->
   */

  // API CALL TO GET ALL DATA
  const { data, loading, error } = useQuery(() => getActivePatients());
  const dataWithName = appendNameToData(data);

  // API CALLS TO CREATE + EDIT COHORTS
  const {
    error: createCohortError,
    runQuery,
    loading: loadingCreateCohort,
  } = useLazyQuery(
    (cohortToSave) => {
      if (cohortToEdit)
        return editCohort(cohortToSave.id, { ...cohortToSave, userId, organizationId });
      return createCohort({ ...cohortToSave, userId, organizationId });
    },
    {
      onSuccess: async (cohortToSave) => {
        setIsSaved(true);

        // Wait one event loop tick to allow React to re-render.
        // We need the UnsavedChangesModal component to update with the new state.
        await new Promise((resolve) => {
          setTimeout(resolve, 0);
        });

        const cohortToSaveBody = {
          ...cohortToSave,
          isNew: true,
          count: cohortToSave.patientIds?.length,
          fixed: true,
        };
        if (!cohortToEdit) {
          cohortToSaveBody.highlightCohortId = cohortToSave.id;
          cohortToSaveBody.scrollIntoView = true;
        }

        // Check if the user came from creating a new initiative or editing an existing one
        if (isCreatingCohortFromInitiative()) {
          returnToInitiativeFromCohortCreation(history, cohortToSave);
          return;
        }

        if (cohortToEdit) {
          history.goBack();
        } else {
          const url = cohortToSave.shared ? '/cohorts/shared' : '/cohorts/personal';
          history.replace(url, cohortToSaveBody);
        }
      },
    }
  );

  // CREATE FIXED COHORT USING VALUES FROM THE FORM
  const handleCreateFixedCohort = (cohort) => {
    // hard code ppt list into cohort object
    const cohortToSave = {
      id: cohort.id,
      name: cohort.name,
      description: cohort.description,
      count: cohort.participants?.length,
      searchCriteria: {
        groupsOperator: 'AND',
        groups: [
          {
            operator: 'OR',
            conditions: [{ type: 'patientId', logic: 'in', values: cohort.participants }],
          },
        ],
      },
      shared: cohort.shared,
      fixed: true,
    };
    runQuery(cohortToSave);
  };

  /**
   * ADDING, REMOVING, FILTERING PPTS LOGIC ----->
   */

  // FILTERING DATA BY SELECTED COHORT FILTERS AND SELECTED PARTICIPANTS
  const results = useMemo(() => {
    if (data == null) return [];
    const uniqueIds = new Set(selectedParticipants);
    const resultsIds = new Set(searchState?.data?.patientIds ?? []);
    const hasFilter = searchState?.data?.patientIds != null;
    return dataWithName.filter((ppt) => {
      return !uniqueIds.has(ppt.id) && (!hasFilter || resultsIds.has(ppt.id));
    });
  }, [selectedParticipants, searchState, data]);

  // DATA THAT HAS BEEN FILTERED BY SEARCH TERM
  const searchFilteredData = useMemo(() => {
    if (results == null) return [];
    return searchFilter(searchTerm, results, 'name');
  }, [results, searchTerm]);

  // ADDING BACK PPT NAME + INFO TO THE DATA
  const selectedParticipantsWithInfo = useMemo(() => {
    if (data == null) return [];
    const uniqueIds = new Set(selectedParticipants);
    return dataWithName.filter((ppt) => uniqueIds.has(ppt.id));
  }, [selectedParticipants, data]);

  // MAPPING ALL SELECTED PPTS TO THEIR IDS
  const setSelectedParticipantsWrapper = (participantList) => {
    setSelectedParticipants(participantList.map((ppt) => ppt.id));
  };

  // HANDLE ADDING PPTS TO THE SELECTED PARTICIPANTS COLUMN
  const handleAddPpts = (rowData, all = false) => {
    if (all) {
      setSelectedParticipantsWrapper([...searchFilteredData, ...selectedParticipantsWithInfo]);
    } else {
      setSelectedParticipantsWrapper([...selectedParticipantsWithInfo, rowData]);
    }
  };

  // HANDLE REMOVING PPTS TO THE SELECTED PARTICIPANTS COLUMN
  const handleRemovePpts = (rowData, all = false) => {
    if (all) {
      setSelectedParticipantsWrapper([]);
    } else {
      setSelectedParticipantsWrapper(
        selectedParticipantsWithInfo.filter((item) => item.name !== rowData?.name)
      );
    }
  };

  // If no patients match the search, change the message.
  const emptyListMessage =
    searchState?.data?.patientIds?.length === 0
      ? 'No participants match the filter criteria.'
      : undefined;

  return (
    <form onSubmit={handleSubmit(handleCreateFixedCohort)} style={styles.container}>
      {/* HEADER  */}
      <div style={styles.header}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            gap: 10,
          }}
        >
          <UnsavedChangesModal
            when={!isSaved && (loadingCreateCohort || isDirty)}
            message="Are you sure you want to leave this page? If you leave, your progress will not be saved."
            shouldAllowNavigation={(location) => {
              return (
                location.pathname?.startsWith('/cohorts/fixed/create') ||
                location.pathname?.startsWith('/patient/') ||
                location.pathname?.endsWith('/edit')
              );
            }}
          />
          <Button
            secondary
            onClick={() => {
              history.go(-1);
            }}
          >
            <Icon name="caret-left" />
            Back
          </Button>
          {/* if there's a cohort to edit change header */}
          <Text type="headline" color="navy" style={{ fontSize: '18px', fontWeight: '700' }}>
            {cohortToEdit != null ? `Edit Fixed Cohort` : `Create Fixed Cohort`}
          </Text>
        </div>
        {createCohortError && (
          <Text color="error" type="caption">
            One error occurred.
          </Text>
        )}
        <Button disabled={!isValid || loadingCreateCohort} type="submit">
          {cohortToEdit != null ? `Save Cohort` : `Create Cohort`}
        </Button>
      </div>

      <div style={styles.body}>
        {/* COHORT FILTER VIEW WRAPPER */}
        {view === 'search' && (
          <div style={{ ...styles.section, flex: 1, border: 'solid #2E62E7 2px' }}>
            <CohortSearchWrapper
              setView={setView}
              setSearchState={setSearchState}
              searchState={searchState}
            />
          </div>
        )}
        {/* ALL FILTERED PARTICIPANTS, NOT SELECTED */}
        {view === 'list' && (
          <ListWrapper
            setView={setView}
            setSearchTerm={setSearchTerm}
            loading={loading}
            error={error}
            data={data}
            searchFilteredData={searchFilteredData}
            history={history}
            handleAddPpts={handleAddPpts}
            control={control}
            createCohortError={createCohortError}
            showCohortDescription={showCohortDescription}
            setShowCohortDescription={setShowCohortDescription}
            selectedParticipantsWithInfo={selectedParticipantsWithInfo}
            selectedParticipants={selectedParticipants}
            handleRemovePpts={handleRemovePpts}
            searchState={searchState}
            emptyListMessage={emptyListMessage}
          />
        )}

        {/* FORM */}
        <div style={{ ...styles.section, width: '360px' }}>
          <div style={styles.cohortForm}>
            <Text type="headline" color="body" style={{ fontSize: '18px', fontWeight: '700' }}>
              Cohort Details
            </Text>
            <div>
              <Text type="caption" color="body">
                Cohort Name
              </Text>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Cohort name is required' }}
                render={({ field }) => {
                  return (
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      style={{ width: 325 }}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      error={!!createCohortError}
                    />
                  );
                }}
              />
              {createCohortError && (
                <Text type="caption" color="error">
                  This name has been used. Please try another one.
                </Text>
              )}
            </div>
            {showCohortDescription === false && (
              <div
                style={{
                  padding: '10px 0px 10px 0px',
                }}
              >
                <Button textOnly onClick={() => setShowCohortDescription(true)}>
                  <Icon name="add" color="navy" style={{ height: '17px', width: '17px' }} />
                  Cohort Description
                </Button>
              </div>
            )}
            {showCohortDescription === true && (
              <>
                <Text type="caption" color="body">
                  Cohort Description
                </Text>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        id="description"
                        name="description"
                        type="textarea"
                        style={{ width: 325, height: '90px' }}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      />
                    );
                  }}
                />
              </>
            )}

            <Controller
              name="shared"
              control={control}
              render={({ field }) => {
                return renderSharedCheckbox(
                  <div
                    anchorId="sharedCheckbox"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Checkbox
                      onChange={field.onChange}
                      aria-labelledby="ongoing-text"
                      checked={field.value}
                      disabled={isSharedCheckboxEnabled === false}
                      style={{ cursor: isSharedCheckboxEnabled ? 'pointer' : 'default' }}
                    />
                    <Text color={isSharedCheckboxEnabled ? 'body' : 'caption'}>
                      Share this Cohort within my organization.
                    </Text>
                  </div>,
                  isSharedCheckboxEnabled,
                  cohortToEdit && (!cohortToEdit.createdByCurrentUser || !canCreateShared)
                    ? 'Only the person who created this cohort can change the shared setting of this Cohort.'
                    : 'Only Admin users can create shared Cohorts.'
                );
              }}
            />
          </div>
          {/* SELECTED PARTICIPANTS */}
          <ParticipantList
            participantList={selectedParticipantsWithInfo}
            searchable={false}
            onClickItem={(ppt) => history.push(`/patient/${ppt.id}/current-acuity`)}
            customColumnConfig={{ default: { columns: ['name'] } }}
            emptyListMessage={`You have not selected any ${getStringReplacement('Participant', {lower: true, plural: true})}.`}
            titleRowElement={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                }}
              >
                <Text type="headline" color="body" style={{ fontSize: '18px', fontWeight: '700' }}>
                  Selected {getStringReplacement('Participant', {plural: true})}
                </Text>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                    alignItems: 'center',
                  }}
                >
                  <Icon name="people" color="caption" />
                  <Text color="caption">{selectedParticipants.length}</Text>
                </div>
              </div>
            }
            extraRenders={{
              afterRow: (rowData) => renderRemoveButton(rowData, handleRemovePpts),
              afterHeader: (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'start',
                    maxWidth: '70px',
                    padding: '0px',
                    margin: '0px 8px 0px 0px',
                  }}
                >
                  <Button
                    textOnly
                    onClick={() => handleRemovePpts(null, true)}
                    style={{
                      minWidth: 'fit-content',
                    }}
                  >
                    Remove all
                  </Button>
                </div>
              ),
            }}
          />
        </div>
      </div>
    </form>
  );
};

export default CreateFixedCohort;

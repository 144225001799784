import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const TwoFactorCode = ({ disabled, onChange, onAllDigitsEntered, className, numDigits }) => {
  const [code, setCode] = useState('');

  // Validate that codes are strings with up to 6 digits.
  const setCodeWithValidation = (newCode) => {
    if (newCode.length <= numDigits && (/^\d+$/.test(newCode) || newCode === '')) {
      setCode(newCode);
      onChange();
      if (newCode.length === numDigits) {
        onAllDigitsEntered(newCode);
      }
    }
  };

  return (
    <Form.Control
      style={{ fontSize: '1.5rem', textAlign: 'center' }}
      placeholder={`${numDigits}-digit code`}
      className={className}
      value={code}
      onChange={(e) => setCodeWithValidation(e.target.value)}
      disabled={disabled}
    />
  );
};

TwoFactorCode.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onAllDigitsEntered: PropTypes.func,
  className: PropTypes.string,
  numDigits: PropTypes.number,
};

TwoFactorCode.defaultProps = {
  disabled: false,
  onChange: () => {},
  onAllDigitsEntered: () => {},
  className: '',
  numDigits: 6,
};

export default TwoFactorCode;

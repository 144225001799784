import { getCurrentConditions } from '@api/patients/getCurrentConditions';
import { useQuery } from '@api/useQuery';
import { SpinnerOrError } from '@intus-ui';
import { ICDCodeList } from 'Participants/acuity/diagnoses/ICDCodeList';
import React, { FC } from 'react';
import { useParams } from 'react-router';

export const ICDCodeDiagnoses: FC = () => {
  const params = useParams<{ patientId: string }>();
  const patientId = parseInt(params.patientId);

  const { data, loading, error } = useQuery(() => getCurrentConditions(patientId));

  if (error) {
    return <SpinnerOrError error="An error ocurred loading the diagnoses for the patient" />;
  }

  if (loading || !data) {
    return <SpinnerOrError />;
  }

  return <ICDCodeList icdCodes={data} />;
};

import PropTypes from 'prop-types';

const SessionListPropType = PropTypes.shape({
  sessionId: PropTypes.number,
  sessionTime: PropTypes.string,
  userId: PropTypes.number,
  orgId: PropTypes.number,
});

export default SessionListPropType;

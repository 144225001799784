import { deleteInitiative } from '@api/initiatives';
import { useLazyQuery } from '@api/useQuery';
import { ConfirmationModal, Text } from '@intus-ui';
import { useHistory } from 'react-router-dom';
import * as userTracking from '@util/userTracking';

export const InitiativeDeleteModal = ({ initiative, isOpen, setIsOpen }) => {
  const history = useHistory();

  const { loading, error, runQuery } = useLazyQuery(() => deleteInitiative(initiative.id), {
    onSuccess: () => {
      history.push('/initiatives');
    },
  });

  return (
    <ConfirmationModal
      open={isOpen}
      onClose={() => {
        userTracking.logEvent('Initiatives: Closed Initiative Delete Modal');
        setIsOpen(false);
      }}
      title="Delete Initiative"
      description={
        <div>
          <div style={styles.confirmText}>
            <Text>Are you sure you want to delete {initiative.name}?</Text>
          </div>
          {!!error && (
            <div style={styles.confirmText}>
              <Text color="error">An error occurred deleting the initiative.</Text>
            </div>
          )}
        </div>
      }
      confirmText="Delete"
      onClickConfirm={() => {
        userTracking.logEvent("Initiatives: Clicked 'Confirm' Button in Initiative Delete Modal");
        runQuery();
      }}
      confirmButtonProps={{ disabled: loading === true ? 'true' : undefined }}
    />
  );
};

const styles = {
  confirmText: {
    width: '100%',
  },
};

import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@intus-ui/components/Buttons/Button';
import Popup from '@intus-ui/components/Popups/Popup';
import PopupSecondary from '@intus-ui/components/Popups/PopupSecondary';

// triggers a popup from popups folder that renders the body of the popup in popupSecondary
const PopupButton = ({ name, popupTitle, popupBody, popupSecondary, children, ...rest }) => {
  const [PopupOpen, setPopupOpen] = useState(false);
  const onClickExt = () => {
    setPopupOpen(true);
  };

  const buttonComponent = (
    <Button
      aria-haspopup="dialog"
      title={`Click this button to trigger the ${name} popup`}
      aria-label={`Click this button to trigger the ${name} popup`}
      name={name}
      onClick={onClickExt}
      onKeyPress={onClickExt}
      {...rest}
    >
      {children}
    </Button>
  );

  return (
    <>
      {buttonComponent}
      {popupSecondary ? (
        <PopupSecondary
          open={PopupOpen}
          setOpen={setPopupOpen}
          title={popupTitle}
          body={popupBody}
        />
      ) : (
        <Popup open={PopupOpen} setOpen={setPopupOpen} title={popupTitle} body={popupBody} />
      )}
    </>
  );
};

PopupButton.propTypes = {
  name: PropTypes.string,
  // TODO: consider allowing title to also be a render function for dash popups for ex
  popupTitle: PropTypes.string.isRequired,
  popupBody: PropTypes.func.isRequired,
  popupSecondary: PropTypes.bool,
  children: PropTypes.node,
};

PopupButton.defaultProps = {
  name: null,
  popupSecondary: false,
  children: undefined,
};

export default PopupButton;

import { Input, Text } from '@intus-ui';
import DeleteFilterRowButton from 'AdvancedCohort/SearchCreate/CreateFilter/FilterRow/FilterComponents/DeleteFilterRowButton';
import { MedicationClassDropdown } from 'Polypharmacy/components/MedicationClassDropdown';
import React, { useEffect, useState } from 'react';

const MedicationClassDropdownWrapper = ({
  filterOptions,
  handleChangeSearchTerm,
  searchTerm,
  isSingleFilterRow,
  isSecond,
  operator,
  operators,
  handleChangeOperator,
  isFirst,
  displayRange,
  filter,
  parentIndex,
  handleDeleteFilter,
}) => {
  const { medicationClasses } = filterOptions;
  const { options } = medicationClasses;
  const [selectedMedClass, setSelectedMedClass] = useState(searchTerm || null);

  useEffect(() => {
    if (selectedMedClass) {
      handleChangeSearchTerm(selectedMedClass);
    }
  }, [selectedMedClass, handleChangeSearchTerm]);

  return (
    <div id="secondFilterRow" style={styles.secondFilterRow}>
      {!isSingleFilterRow && (
        <div id="secondFilterRowSpacer" style={styles.filterRowSpacer}>
          {isSecond && (
            <Input
              id="operator"
              name="operator"
              type="select"
              rightIcon="caret-down"
              options={operators.reverse()}
              value={operator}
              onChange={handleChangeOperator}
              style={{ width: 90 }}
            />
          )}
          {!isFirst && !isSecond && (
            <div style={{ paddingLeft: 5 }}>
              <Text type="body">{operator}</Text>
            </div>
          )}
        </div>
      )}
      <MedicationClassDropdown
        allMedClasses={options ?? []}
        selectedMedClass={selectedMedClass}
        setSelectedMedClass={setSelectedMedClass}
        sx={{
          width: '300px',
          height: '35px',
          '.MuiTextField-root, .MuiInputBase-root, input': {
            height: '35px',
            padding: '0px 0px 0px 0px !important',
            margin: '0px 0px 0px 0px !important',
          },
          '.MuiOutlinedInput-root, input': {
            padding: '0px 15px 0px 15px !important',
          },
        }}
      />
      {!displayRange && filter.groups[parentIndex].conditions.length > 1 && (
        <DeleteFilterRowButton handleDeleteFilter={handleDeleteFilter} style={{ marginLeft: 10 }} />
      )}
    </div>
  );
};

const styles = {
  secondFilterRow: {
    display: 'flex',
  },
  filterRowSpacer: {
    width: 100,
  },
  deleteFilterRowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default MedicationClassDropdownWrapper;

import PropTypes from 'prop-types';

const ListDataPropType = PropTypes.shape({
  list: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    acuityScore: PropTypes.number,
    riskGroup: PropTypes.string,
  }),
  title: PropTypes.string,
  notClickable: PropTypes.bool,
});

export default ListDataPropType;

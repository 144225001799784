import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '@intus-ui/styles/Utilities.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextData from 'Dashboard/QuadContent/TextData';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import BigTitle from 'Dashboard/QuadContent/BigTitle';
import { setSelected } from '@global-state/redux/dashboardSlice';
import { useFeatureFlag } from '@util';

const HospitalizationAnalyticsPreview = ({ content, error }) => {
  const  hospitalization90isActive = useFeatureFlag('90-days-hospitalization-analytics').featureIsActive;

  const bigTitle = hospitalization90isActive ? 'Status over past 90 days' : 'Status over past 30 days';

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100 overflow-hidden flex-column py-3">
      <BigTitle title={bigTitle} />
      <Row className="w-100 h-100 overflow-scroll py-3 d-flex justify-content-center align-items-center">
        <Col
          lg={6}
          className="d-flex flex-column justify-content-center align-items-center border-right"
        >
          <div className="d-flex flex-column">
            <BigTitle title="ER Visits" secondary />
            {content ? (
              <TextData data={content.erVisits.value} />
            ) : (
              <SpinnerOrError error={error} />
            )}
          </div>
        </Col>
        <Col lg={6} className="d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex flex-column">
            <BigTitle title="Inpatient Admissions" secondary />
            {content ? (
              <TextData data={content.inpatientAdmissions.value} />
            ) : (
              <SpinnerOrError error={error} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

HospitalizationAnalyticsPreview.propTypes = {
  content: PropTypes.shape({
    erVisits: PropTypes.shape({
      value: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
    inpatientAdmissions: PropTypes.shape({
      value: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  error: PropTypes.string,
};

HospitalizationAnalyticsPreview.defaultProps = {
  // content: undefined,
  error: undefined,
};

const mapState = (state) => ({
  error: state.homepage.homeError,
});

const mapDispatch = (dispatch) => ({
  doSetSelected: (item, name) => dispatch(setSelected(item, name)),
});

export default connect(mapState, mapDispatch)(HospitalizationAnalyticsPreview);

import React from 'react';
import { Row } from 'react-bootstrap';
import { useSelector, useDispatch } from '@global-state';
import { setPage } from '@global-state/redux';

import { SpinnerOrError } from '@intus-ui';
import ModalButtons from './ModalButtons';
import WelcomeBox from './WelcomeBox';
import '@intus-ui/styles/Utilities.scss';

const WelcomeBegin = () => {
  const dispatch = useDispatch();
  const userSettings = useSelector((state) => state.userSettings);

  const page = useSelector((state) => state.welcome.page);

  const { profile } = userSettings;
  if (!profile) {
    return <SpinnerOrError error="Could not load the page. Please refresh and try again. " />;
  }
  return (
    <WelcomeBox minWidth="w-50">
      <div className="text-dark h-100 w-100 d-flex flex-column justify-content-between align-items-center py-4 px-5">
        <Row className="d-flex flex-column justify-content-center h-100 w-100 pb-5">
          <h2 className="text-center font-weight-bold text-primary med-lg-text">
            Welcome to IntusCare
            <br />
            <span className="pt-1">
              {profile.firstName} {profile.lastName}!
            </span>
          </h2>
        </Row>
        <ModalButtons
          noBack
          nextText="Start"
          onClick={() => {
            dispatch(setPage(page + 1));
          }}
        />
      </div>
    </WelcomeBox>
  );
};

export default WelcomeBegin;

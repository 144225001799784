import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getStringReplacement } from '@util/stringReplacements';
import { getModalPatientList } from '@global-state/redux/dashboardSlice';

import RiskAcuityParticipantList from 'Dashboard/RiskAcuity/RiskAcuityParticipantList';

import { Container, Row, Col } from 'react-bootstrap';
import { SpinnerOrError } from '@intus-ui';
import DonutChart from '@intus-ui/components/graphs/DonutChart';
import { alertColors, riskColors } from '@intus-ui/styles/SecondaryColors';
import Quad from 'Dashboard/Quad';
import TextData from 'Dashboard/QuadContent/TextData';
import BigTitle from 'Dashboard/QuadContent/BigTitle';
import { acuityModalPropType } from 'Homes/modalPropTypes/acuityModalPropType';
import PercentageRow from '../PercentageRow';
import PercentageCol from '../PercentageCol';

import '@intus-ui/styles/Utilities.scss';
import { getFeatureFlag } from '@config';

export const createAcuityGraph = (card) => {
  let acuityScoreBreakdownData;
  let acuityGraphOptions;

  const acuityChartColors = [
    riskColors.low,
    riskColors.moderate,
    riskColors.high,
    riskColors.highest,
  ];
  const acuityChartLabels = [];
  const acuityScoreChartData = [];
  if (card !== undefined && card.acuityScoreGroupBreakdown !== undefined) {
    acuityScoreBreakdownData = card.acuityScoreGroupBreakdown;
    Object.entries(acuityScoreBreakdownData).forEach(([key, val]) => {
      acuityChartLabels.push(key);
      acuityScoreChartData.push(val.value);
    });

    acuityGraphOptions = {
      radius: '90%',
      cutout: '40%',
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'right',
      },
    };
  }

  return (
    <DonutChart
      expand
      options={acuityGraphOptions}
      info={acuityScoreChartData}
      label={acuityChartLabels}
      colors={acuityChartColors}
    />
  );
};

class RiskAcuityAnalytics extends React.Component {
  componentDidMount() {}

  isActive(route) {
    const { location } = this.props;
    return location.pathname.startsWith(route);
  }

  render() {
    const { error, subcards, getPatientList } = this.props;
    const isUnrealiableAcuitySubmitter = getFeatureFlag(
      'unreliable-acuity-submitter'
    ).featureIsActive;
    const timeFrame = isUnrealiableAcuitySubmitter ? 'Past Month' : 'Past Week';

    if (error || subcards === undefined || subcards.acuityChanges === undefined) {
      return <SpinnerOrError error={error} />;
    }
    return (
      <Container
        fluid
        className="bg-light m-0 h-100 w-100 d-flex flex-column overflow-hidden font-weight-normal pt-2 pb-3"
      >
        <div className="w-100 h-100 d-flex flex-column">
          {/* First row of popup */}
          <PercentageRow isDashboard>
            <PercentageCol isDashboard>
              <Quad
                title="Acuity Score Group Breakdown (Current)"
                padding="justify-content-center align-items-center"
                scrollable
              >
                {createAcuityGraph(subcards)}
              </Quad>
            </PercentageCol>
            <PercentageCol isDashboard>
              <Quad
                title={`Net Acuity Changes (${timeFrame})`}
                onClick={
                  subcards?.acuityChanges.decreased.ids.length > 0 ||
                  subcards?.acuityChanges.increased.ids.length > 0
                    ? () => {
                        getPatientList(
                          [
                            {
                              patientList: {
                                patientList: subcards.acuityChanges.decreased.ids,
                              },
                              listTitle: 'Risk Decreasers',
                            },
                            {
                              patientList: {
                                patientList: subcards.acuityChanges.increased.ids,
                              },
                              listTitle: 'Risk Increasers',
                            },
                          ],
                          'acuityChanges',
                          `Net Acuity Changes(${timeFrame})`,
                          this.isActive('/home') ? 'home' : 'dashboard'
                        );
                      }
                    : null
                }
                padding="p-3"
              >
                <Row className="w-100 h-100 pb-4">
                  <Col
                    lg={6}
                    className="d-flex flex-column justify-content-center align-items-center border-right h-100"
                  >
                    <BigTitle title="Risk Decrease:" />
                    <TextData
                      data={
                        subcards !== undefined ? (
                          <Row className="w-100 h-100 m-0">
                            <Col
                              lg={6}
                              className="pr-0 d-flex justify-content-start h-100 w-100 flex-grow-1"
                            >
                              {subcards.acuityChanges.decreased.value.toString()}
                            </Col>
                          </Row>
                        ) : (
                          <SpinnerOrError error={error} />
                        )
                      }
                      subtext={{
                        text: getStringReplacement('Participant', { lower: true, plural: true }),
                        vertical: true,
                      }}
                      arrow="down"
                    />
                  </Col>
                  <Col
                    lg={6}
                    className="d-flex flex-column justify-content-center align-items-center h-100"
                  >
                    <BigTitle title="Risk Increase:" />
                    <TextData
                      data={
                        subcards !== undefined ? (
                          <Row className="w-100 h-100 m-0 text-danger">
                            <Col
                              lg={6}
                              className="pr-0 d-flex justify-content-start h-100 w-100 flex-grow-1"
                            >
                              {subcards.acuityChanges.increased.value.toString()}
                            </Col>
                          </Row>
                        ) : (
                          <SpinnerOrError error={error} />
                        )
                      }
                      subtext={{
                        text: getStringReplacement('Participant', { lower: true, plural: true }),
                        vertical: true,
                      }}
                      arrow="up"
                      textColor={alertColors.danger}
                    />
                  </Col>
                </Row>
              </Quad>
            </PercentageCol>
          </PercentageRow>
          {/* Second row of popup */}
          <PercentageRow isDashboard>
            <PercentageCol isDashboard>
              <Quad
                title={`Highest-Acuity ${getStringReplacement('Participant', { plural: true })}`}
                padding="px-0 py-0"
              >
                {subcards ? (
                  <RiskAcuityParticipantList data={subcards.currentHighestAcuityParticipantList} />
                ) : (
                  <SpinnerOrError error={error} />
                )}
              </Quad>
            </PercentageCol>
            <PercentageCol isDashboard>
              <Quad
                title="High Risk for Onset of (Current)"
                onClick={
                  subcards?.currentHighRiskOfDiseaseParticipantList.CKD.ids.length > 0 ||
                  subcards?.currentHighRiskOfDiseaseParticipantList.DIABETES.ids.length > 0
                    ? () => {
                        getPatientList(
                          [
                            {
                              patientList: {
                                patientList:
                                  subcards.currentHighRiskOfDiseaseParticipantList.CKD.ids,
                              },
                              listTitle: 'CKD',
                            },
                            {
                              patientList: {
                                patientList:
                                  subcards.currentHighRiskOfDiseaseParticipantList.DIABETES.ids,
                              },
                              listTitle: 'DIABETES',
                            },
                          ],
                          'currentHighRiskOfDisease',
                          'High Risk for Onset of (Current)',
                          this.isActive('/home') ? 'home' : 'dashboard'
                        );
                      }
                    : null
                }
                padding="p-3"
              >
                <Row className="w-100 h-100 m-0 pb-4">
                  <Col
                    lg={6}
                    className="d-flex flex-column justify-content-center align-items-center border-right h-100"
                  >
                    <BigTitle title="CKD:" />
                    <TextData
                      data={
                        subcards !== undefined ? (
                          subcards.currentHighRiskOfDiseaseParticipantList.CKD.value.toString()
                        ) : (
                          <SpinnerOrError error={error} />
                        )
                      }
                      subtext={{
                        text: getStringReplacement('Participant', { lower: true, plural: true }),
                        vertical: true,
                      }}
                    />
                  </Col>
                  <Col
                    lg={6}
                    className="d-flex flex-column justify-content-center align-items-center h-100"
                  >
                    <BigTitle title="Diabetes:" />
                    <TextData
                      data={
                        subcards !== undefined ? (
                          subcards.currentHighRiskOfDiseaseParticipantList.DIABETES.value.toString()
                        ) : (
                          <SpinnerOrError error={error} />
                        )
                      }
                      subtext={{
                        text: getStringReplacement('Participant', { lower: true, plural: true }),
                        vertical: true,
                      }}
                    />
                  </Col>
                </Row>
              </Quad>
            </PercentageCol>
            <PercentageCol isDashboard>
              <Quad
                title="Living Situation Changes (Past Week)"
                onClick={
                  subcards?.livingSituationChangesPastWeek.ids.length > 0
                    ? () => {
                        getPatientList(
                          [
                            {
                              patientList: {
                                patientList: subcards.livingSituationChangesPastWeek.ids,
                              },
                            },
                          ],
                          'livingSituationChanges',
                          'Living Situation Changes (Past Week)',
                          this.isActive('/home') ? 'home' : 'dashboard'
                        );
                      }
                    : null
                }
                padding="pt-1 pb-4"
              >
                <Row className="w-100 h-100 pl-4 justify-content-center">
                  <Col className="d-flex h-100 flex-column justify-content-center align-items-center">
                    <TextData
                      data={
                        subcards !== undefined ? (
                          subcards.livingSituationChangesPastWeek.value.toString()
                        ) : (
                          <SpinnerOrError error={error} />
                        )
                      }
                      subtext={{
                        text: getStringReplacement('Participant', { lower: true, plural: true }),
                        vertical: true,
                      }}
                    />
                  </Col>
                </Row>
              </Quad>
            </PercentageCol>
          </PercentageRow>
        </div>
      </Container>
    );
  }
}

RiskAcuityAnalytics.propTypes = {
  subcards: acuityModalPropType,
  error: PropTypes.string,
  location: PropTypes.instanceOf(Object).isRequired,
};

RiskAcuityAnalytics.defaultProps = {
  subcards: undefined,
  error: undefined,
};

const mapState = (state) => ({
  subcards: state.homepage.modalContent,
  error: state.homepage.homeError,
});

const mapDispatch = (dispatch) => ({
  getPatientList: (list, item, modalTitle, backLoc) =>
    dispatch(getModalPatientList(list, item, modalTitle, backLoc)),
});

export default withRouter(connect(mapState, mapDispatch)(RiskAcuityAnalytics));

import React, { useState, useEffect } from 'react';

import { getStringReplacement } from '@util/stringReplacements';

import { List } from '@intus-ui';

import { defaultColumnConfig } from './list/columnConfigs';
import { format } from './list/format';
import { formatEvents, EventListItem } from './helpers';

const EventList = ({
  events,
  onClickItem,
  defaultSort = { field: 'dateValue', direction: 'desc' },
  loading,
}) => {
  const [filteredData, setFilteredData] = useState(formatEvents(events));
  useEffect(() => {
    setFilteredData(formatEvents(events));
  }, [events]);

  return (
    <List
      data={filteredData}
      searchable={false}
      format={format}
      columnConfig={defaultColumnConfig.default}
      onClickItem={onClickItem}
      defaultSort={defaultSort}
      emptyListMessage={`There are no ${getStringReplacement('Participant', {
        lower: true,
        plural: true,
      })} matching your selected notifications.`}
      showColumnHeaders
      loading={loading}
      customListItem={EventListItem}
    />
  );
};

export default EventList;

import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import Centered from './Centered';

type CenteredSpinnerProps = {
  className?: string;
  style?: React.CSSProperties;
};

const CenteredSpinner: FC<CenteredSpinnerProps> = ({ className, style }) => (
  <Centered className={className}>
    <Spinner animation="grow" role="status" variant="primary" style={style}>
      <span className="sr-only">Loading...</span>
    </Spinner>
  </Centered>
);

CenteredSpinner.propTypes = {
  className: PropTypes.string,
};

CenteredSpinner.defaultProps = {
  className: '',
};

export default CenteredSpinner;

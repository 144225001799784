import { RadioGroup } from '@intus-ui';
import { useMemo } from 'react';

const metricTypesToText = {
  count: 'Count',
  per100mm: '100MM',
  per1000mm: '1000MM',
  per1000pa: '1000PA',
};

/** Shows the possible options for the metric types as radio buttons */
export const MetricTypeRadioButtons = ({ metricConfig, value, onChange }) => {
  const metricTypeTextToKeyMap = useMemo(() => {
    if (metricConfig.metricTypes == null) return null;

    return new Map(metricConfig.metricTypes.map((m) => [metricTypesToText[m], m]));
  }, [metricConfig.metricTypes]);

  return (
    <RadioGroup
      options={Array.from(metricTypeTextToKeyMap.keys())}
      selectedOption={metricTypesToText[value]}
      onChange={(value) => onChange(metricTypeTextToKeyMap.get(value))}
    />
  );
};

export function buildFixedCohortSearchCriteria(searchState) {
  // For a fixed cohort, we ignore the chosen filters since they are only used to limit the participants that can be selected.
  // We make a special search criteria that just lists out the patientIds the user has selected.
  return {
    groupsOperator: 'AND',
    groups: [
      {
        operator: 'OR',
        conditions: [{ type: 'patientId', logic: 'in', values: searchState.pinnedPatientIds }],
      },
    ],
  };
}

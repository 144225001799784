import dayjs from 'dayjs';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// This date module is used to wrap third party dayjs functions in a consolidated
// file so that if we ever want to move to another date library, we can easily swap.

/* 
Units are case insensitive, and support plural and short forms.
LIST OF AVAILABLE DAYJS UNITS:

year	y	January 1st, 00:00 this year
quarter	Q	beginning of the current quarter, 1st day of months, 00:00 ( dependent QuarterOfYear plugin )
month	M	the first day of this month, 00:00
week	w	the first day of this week, 00:00
isoWeek		the first day of this week according to ISO 8601, 00:00 ( dependent IsoWeek plugin )
date	D	00:00 today
day	d	00:00 today
hour	h	now, but with 0 mins, 0 secs, and 0 ms
minute	m	now, but with 0 seconds and 0 milliseconds
second	s	now, but with 0 milliseconds
*/

/**
 * Creates a new dayjs date object
 * Takes in an optional date parameter to allow for creating a dayjs object of a specific date
 */
export const newDate = (date?: dayjs.ConfigType) => dayjs(date);

/**
 * Creates a new dayjs date object with a given format string
 * Useful for those cases where the auto-parse doesn't work quite right (such as MM/YY date labels)
 * (in that case, newDate('03/22') would interpret this as "March 22nd of the current year", not "March 2022")
 */
export const newDateInFormat = (date: dayjs.ConfigType, format: dayjs.OptionType) => {
  dayjs.extend(customParseFormat);
  return dayjs(date, format);
};

/**
 * Takes in a date string, converts it to a dayjs object, and returns the date at the end of that unit
 */
export const endOfDate = (date: dayjs.ConfigType, unit: dayjs.OpUnitType) => {
  dayjs.extend(quarterOfYear);
  return dayjs(date).endOf(unit);
};

/**
 * Takes in a date string, converts it to a dayjs object, and returns the date at the start of that unit
 * @param date - date to be converted
 * @param unit - unit to determine the start of (ex. 'day', 'week', etc.)
 */
export const startOfDate = (date: dayjs.ConfigType, unit: dayjs.OpUnitType) => {
  dayjs.extend(quarterOfYear);
  return dayjs(date).startOf(unit);
};

/**
 * Takes in a date string, converts it to a dayjs object, and returns the formatted date
 * @param date - date to be converted
 * @param format - format of returned date
 */
export const formatDate = (date: dayjs.ConfigType, format: string) => {
  return dayjs(date).format(format);
};

/**
 * Validates a date string.
 * @param  date - The date to be validated.
 */
export const isValidDate = (date: dayjs.ConfigType) => dayjs(date).isValid();

export const toISOString = (date: dayjs.ConfigType, includeTime = false) => {
  if (!date) {
    return null;
  }
  if (includeTime) {
    return dayjs(date).toISOString();
  }
  return formatDate(date, 'YYYY-MM-DD');
};

/**
 * Takes in two date strings, converts them to dayjs objects, and determines whether or not the two dates
 * are the same, which is based on the unit. If no unit is supplied, it will default to milliseconds, which
 * means that the two dates would have to be exactly the same for the boolean return value to be true
 * @param  date1 - first date
 * @param date2 - second date
 * @param unit - level of granularity to determine equality
 */
export const isSameDate = (
  date1: dayjs.ConfigType,
  date2: dayjs.ConfigType,
  unit?: dayjs.OpUnitType
) => {
  return dayjs(date1).isSame(dayjs(date2), unit);
};

/**
 * Takes in two date strings, converts them to dayjs objects, and determines whether or not the first date
 * is after the second date. Returns a boolean.
 * @param date1 - first date
 * @param date2 - second date
 * @param unit - level of granularity for comparison, defaults to milliseconds
 */
export const isAfterDate = (
  date1: dayjs.ConfigType,
  date2: dayjs.ConfigType,
  unit?: dayjs.OpUnitType
) => {
  return dayjs(date1).isAfter(dayjs(date2), unit);
};

/**
 * Takes in two date strings, converts them to dayjs objects, and determines whether or not the first date
 * is before the second date. Returns a boolean.
 * @param date1 - first date
 * @param date2 - second date
 * @param unit - level of granularity for comparison, defaults to milliseconds
 */
export const isBeforeDate = (
  date1: dayjs.ConfigType,
  date2: dayjs.ConfigType,
  unit?: dayjs.OpUnitType
) => {
  return dayjs(date1).isBefore(dayjs(date2), unit);
};

/**
 * Takes in two date strings, converts them to dayjs objects, and determines whether or not the first date
 * is the same or after the second date. Returns a boolean.
 * @param date1 - first date
 * @param date2 - second date
 * @param unit - level of granularity for comparison, defaults to milliseconds
 */
export const isSameOrAfterDate = (
  date1: dayjs.ConfigType,
  date2: dayjs.ConfigType,
  unit?: dayjs.OpUnitType
) => {
  dayjs.extend(isSameOrAfter);
  return dayjs(date1).isSameOrAfter(dayjs(date2), unit);
};

/**
 * Takes in two date strings, converts them to dayjs objects, and determines whether or not the first date
 * is the same or before the second date. Returns a boolean.
 * @param date1 - first date
 * @param date2 - second date
 * @param unit - level of granularity for comparison, defaults to milliseconds
 */
export const isSameOrBeforeDate = (
  date1: dayjs.ConfigType,
  date2: dayjs.ConfigType,
  unit?: dayjs.OpUnitType
) => {
  dayjs.extend(isSameOrBefore);
  return dayjs(date1).isSameOrBefore(dayjs(date2), unit);
};

// TODO: Implement more getters for day, month, year. But not sure if these are actually needed.
/**
 * Takes in a date string, converts it to a dayjs object, and returns the day of that date
 * @param date - input date
 */
export const getDayOfDate = (date: dayjs.ConfigType) => {
  return dayjs(date).date();
};

/**
 * Takes in a date string, converts it to a dayjs object, and returns the month of that date
 * Months are zero indexed
 * @param date - input date
 */
export const getMonthOfDate = (date: dayjs.ConfigType) => {
  return dayjs(date).month();
};

/**
 * Takes in a date string, converts it to a dayjs object, and returns the year of that date
 * @param date - input date
 */
export const getYearOfDate = (date: dayjs.ConfigType) => {
  return dayjs(date).year();
};

/**
 * Takes in a date string, converts it to a dayjs object, and returns the hour of that date
 * @param date - input date
 */
export const getHourOfDate = (date: dayjs.ConfigType) => {
  return dayjs(date).hour();
};

/**
 * Takes in a date string, converts it to a dayjs object, and returns the minute of that date
 * @param date - input date
 */
export const getMinuteOfDate = (date: dayjs.ConfigType) => {
  return dayjs(date).minute();
};

/**
 * Takes in a date string, converts it to a dayjs object, and returns the date plus the number of units
 * @param date - date to be converted
 * @param quantity - number of units to be added
 * @param unit - unit to add (ex. 'day', 'week', etc.)
 */
export const addDate = (date: dayjs.ConfigType, quantity: number, unit: dayjs.OpUnitType) => {
  return dayjs(date).add(quantity, unit);
};

/**
 * Takes in a date string, converts it to a dayjs object, and returns the date minus the number of units
 * @param date - date to be converted
 * @param quantity - number of units to be subtracted
 * @param unit - unit to subtract (ex. 'day', 'week', etc.)
 */
export const subtractDate = (date: dayjs.ConfigType, quantity: number, unit: dayjs.OpUnitType) => {
  return dayjs(date).subtract(quantity, unit);
};

/**
 * Takes in two date strings, converts them to dayjs objects, and determines the difference between the
 * first and second dates based on the supplied unit. Returns an integer.
 * @param - first date
 * @param - second date
 * @param - level of granularity for comparison, defaults to milliseconds
 * @param - True to return a decimal instead of a whole number.
 */
export const dateDifference = (
  date1: dayjs.ConfigType,
  date2: dayjs.ConfigType,
  unit?: dayjs.OpUnitType,
  float?: boolean
) => {
  return dayjs(date1).diff(dayjs(date2), unit, float);
};

/**
 * returns a date based on relative time in the past, to today's date
 * @param timeRange in past - the string indicating how long in past
 * @param format - the format to convert it to
 */
export const relativePast = (
  timeRange: 'Past 30 days' | 'Past 90 days' | 'Past 6 months' | 'Past 1 year',
  format: string
) => {
  switch (timeRange) {
    case 'Past 30 days':
      return dayjs().subtract(30, 'days').format(format);
    case 'Past 90 days':
      return dayjs().subtract(90, 'days').format(format);
    case 'Past 6 months':
      return dayjs().subtract(6, 'months').format(format);
    case 'Past 1 year':
      return dayjs().subtract(1, 'years').format(format);
    default:
      // default to 3 months
      return dayjs().subtract(3, 'months').format(format);
  }
};

/**
 * returns a date that is read as utc time and then converted to the user's local timezone
 * @param time - date to be converted
 * @param format - the format to convert it to
 */
export const toUTCtoLocal = (time: dayjs.ConfigType, format: string) => {
  dayjs.extend(utc);
  return dayjs.utc(time).local().format(format);
};

/**
 * returns a date converted to UTC time
 * @param time - date to be converted
 */
export const toUTC = (time: dayjs.ConfigType) => {
  dayjs.extend(utc);
  return dayjs.utc(time);
};

/**
 *
 * @param date Optional Date prop
 * @returns the current month range or the month range of the provided date
 */

export const getCurrentMonthRange = (date?: Date) => {
  const dayjsDate = date ? dayjs(date) : dayjs();
  const firstDayOfMonth = dayjsDate.startOf('month').toDate();
  const lastDayOfMonth = dayjsDate.endOf('month').toDate();
  return { firstDayOfMonth, lastDayOfMonth };
};

/**
 *
 * @param date Date
 * @returns the end of the month for the provided date
 */
export const getEndOfMonth = (date: Date) => {
  const dayjsDate = date ? dayjs(date) : dayjs();
  return dayjsDate.endOf('month').toDate();
};

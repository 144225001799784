import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { getFeatureFlag } from '@config';
import { getSessionUser } from '@util/session';

import { getCaregivers } from '@global-state/redux/caregiverListSlice';
import caregiverListCaregiverPropType from 'Users/caregiver/caregiverList/caregiverListCaregiverPropType';
import { USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';

import { Button } from 'react-bootstrap';
import { Text, layout } from '@intus-ui';
import UserList from '@intus-ui/components/UserList';
import Centered from '@intus-ui/components/Centered';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';

import AddUserModal from 'Users/caregiver/caregiverList/AddUserModal';

class Caregivers extends React.Component {
  componentDidMount() {
    const { doGetCaregivers } = this.props;
    doGetCaregivers();
  }

  render() {
    const { caregivers, error, doGetCaregivers } = this.props;
    // Check if user is an HSA and has the feature flag.
    const user = getSessionUser();
    const isHealthSystemAdmin = user.access === USER_ACCESS_HEALTH_SYS_ADMIN;
    const { featureIsActive: hsaUserManagementFlag } = getFeatureFlag('hsa-user-management');
    const hsaUserManagementisActive = isHealthSystemAdmin && hsaUserManagementFlag;

    // If user is HSA without the flag, redirect to home
    if (isHealthSystemAdmin && !hsaUserManagementFlag) {
      return <Redirect to="/" />;
    }

    let content;

    // If there's an error, it's displayed.
    if (error) {
      content = (
        <Centered className="d-flex flex-grow-1">
          <div className="d-flex flex-column align-items-center m-5">
            <div className="m-1">{error}</div>
            <div className="m-1">
              <Button onClick={doGetCaregivers}>Try Again</Button>
            </div>
          </div>
        </Centered>
      );
    } else if (!Array.isArray(caregivers)) {
      // If caregivers is undefined, shows a loading spinner.
      content = <CenteredSpinner className="d-flex flex-grow-1" />;
    } else {
      // If there are caregivers, the list is shown.
      content = <UserList data={caregivers} hsaUser={hsaUserManagementisActive} />;
    }

    // having to add margins in order to not cut off box shadow
    return (
      <div
        style={{
          ...layout.container,
          gap: '10px',
          padding: '20px 10px 20px 10px',
          width: '100%',
          height: '100%',
        }}
      >
        <Text type="title" color="navy" style={{ margin: '0px 10px 0px 30px' }}>
          Manage Users
        </Text>
        <div style={{ display: 'flex', flex: 1, margin: '0px 10px 10px 10px' }}>{content}</div>
        <AddUserModal hsaUser={hsaUserManagementisActive} />
      </div>
    );
  }
}

Caregivers.propTypes = {
  doGetCaregivers: PropTypes.func.isRequired,
  caregivers: PropTypes.arrayOf(caregiverListCaregiverPropType.isRequired),
  error: PropTypes.string,
};

Caregivers.defaultProps = {
  caregivers: undefined,
  error: undefined,
};

const mapState = (state) => ({
  caregivers: state.caregiverList.caregivers,
  error: state.caregiverList.error,
});

const mapDispatch = (dispatch) => ({
  doGetCaregivers: () => dispatch(getCaregivers()),
});

export default connect(mapState, mapDispatch)(Caregivers);

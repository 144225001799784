import React, { useState } from 'react';
import { get } from 'lodash';

import { logEvent } from '@util/userTracking';
import { formatDate } from '@util/dateFunctions';

import {
  AdmitRecord,
  TableFormatItem,
  UtilizationBreakdown,
  UtilizationBreakdownItem,
  UtilizationBreakdownTotals,
  UtilizationCategoryData,
} from 'Utilization/types';

import { Modal, Text } from '@intus-ui';
import { useUtilizationContext } from 'Utilization/Context';
import OrgViewTable from './OrgViewTable';
import DetailsModal from '../DetailsModal';

type UtilizationData = {
  utilizationBreakdown: UtilizationBreakdown;
  utilizationBreakdownTotals: UtilizationBreakdownTotals;
  admits: AdmitRecord[];
};

type AdmitTypeStringToKey = {
  [key: string]: string;
};

const typeMap: AdmitTypeStringToKey = {
  Starting: 'starting',
  Admissions: 'admissions',
  Discharges: 'discharges',
  Ending: 'ending',
  'LOS Days': 'los',
  'Ppts w/LOS >= 10 Days': 'daysGTE10',
};

const OrgView = ({ utilizationData }: { utilizationData: UtilizationData }) => {
  const { utilizationBreakdown, utilizationBreakdownTotals } = utilizationData;

  const data = createTable(utilizationBreakdown, utilizationBreakdownTotals);
  const [openModal, setOpenModal] = useState(false);
  const [modalAdmits, setModalAdmits] = useState<AdmitRecord[]>([]);
  const [modalTitle, setModalTitle] = useState<string>('');
  const { admitsType } = useUtilizationContext();

  const handleOnClickCell = (renderedCellValue: any, row: any, column: any) => {
    let admitIds: number[] = [];
    const { label } = row.original;

    // HANDLE CLICKS ON TOTAL COLUMN
    if (column.id === 'total') {
      setModalTitle(`Total - ${label}`);
      const key = typeMap[label];
      admitIds = get(utilizationBreakdownTotals[key], 'admits');

      // HANDLE CLICKS ON DATE COLUMNS
    } else {
      // SELECTED DATE
      const date = column.id;
      // MODAL TITLE
      setModalTitle(`${formatDate(date, 'M/D/YYYY')} - ${label}`);
      // METRIC KEY
      const key: string = typeMap[label];
      // DATA FOR DATE
      admitIds = get(
        utilizationBreakdown.find((day) => day.date === date),
        [key, 'admits']
      );
    }

    // PULL ADMITS
    const admits = admitIds?.length
      ? utilizationData.admits.filter((admit) => admitIds.includes(admit.id))
      : [];

    logEvent('Utilization: Organization View: Clicked Cell', { column: column.id, label });

    setModalAdmits(admits);

    setOpenModal(true);
    return { renderedCellValue, row };
  };

  return (
    <>
      <Text type="subtitle">Organization View</Text>
      <div
        style={{
          paddingTop: 20,
          width: '100%',
          maxHeight: '500px',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
        <Modal
          header={{ title: modalTitle, centered: true }}
          type="large"
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DetailsModal admits={modalAdmits} admitsType={admitsType} />
        </Modal>
        <OrgViewTable data={data} handleOnClickCell={handleOnClickCell} />
      </div>
    </>
  );
};

export default OrgView;

function createTable(
  utilizationBreakdown: UtilizationBreakdown,
  utilizationBreakdownTotals: UtilizationBreakdownTotals
): TableFormatItem[] {
  const table: TableFormatItem[] = [];

  const labels = Object.keys(typeMap);
  const keys = Object.values(typeMap);

  labels.forEach((label, index) => {
    const key = keys[index];
    const tableItem: TableFormatItem = { label, total: utilizationBreakdownTotals[key].count };

    utilizationBreakdown.forEach((day) => {
      const categoryData = day[key as keyof UtilizationBreakdownItem] as UtilizationCategoryData;
      tableItem[day.date] = categoryData.count;
    });

    table.push(tableItem);
  });

  return table;
}

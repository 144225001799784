/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  postAcuityAPI,
  getAcuityQuestionsAPI,
  getPatientAcuity,
  getAcuityLevelChanges,
} from '@api/api';
import { getLogger } from '@util/logger';
import { addAcuityToPatient } from './patientDetailSlice';

const log = getLogger('acuitySlice');

const acuitySlice = createSlice({
  name: 'acuity',
  initialState: {
    confirmationAcuity: undefined,
    acuityQuestions: undefined,
    error: undefined,
    acuityInfo: undefined,
    selectedAcuity: 0,
    acuityLevelChanges: undefined,
    icdCodeChanges: undefined,
  },
  reducers: {
    // Shows the modal that appears after the acuity form has been submitted.
    confirmAcuity(state, action) {
      state.confirmationAcuity = action.payload;
    },

    // Hides the mdoal that appears after the acuity form has been submitted.
    hideConfirmation(state) {
      state.confirmationAcuity = undefined;
    },

    setError(state, action) {
      state.error = action.payload;
    },

    setAcuityQuestions(state, action) {
      state.acuityQuestions = action.payload;
    },

    clearAcuityQuestionsAndError(state) {
      state.error = undefined;
      state.acuityQuestions = undefined;
    },

    setAcuityInfo(state, action) {
      state.acuityInfo = action.payload;
    },

    clearAcuity(state) {
      state.acuityInfo = undefined;
      state.selectedAcuity = 0;
    },

    // Indicates which acuity we are selecting/viewing, based on array index
    setSelectedAcuity(state, action) {
      state.selectedAcuity = action.payload;
    },
    setAcuityLevelChanges(state, action) {
      state.acuityLevelChanges = action.payload;
    },
    setIcdCodeChanges(state, action) {
      state.icdCodeChanges = action.payload;
    },
  },
});

export default acuitySlice.reducer;
export const {
  confirmAcuity,
  hideConfirmation,
  setError,
  setAcuityQuestions,
  clearAcuityQuestionsAndError,
  setAcuityInfo,
  clearAcuity,
  setSelectedAcuity,
  setAcuityLevelChanges,
  setIcdCodeChanges,
} = acuitySlice.actions;

export const addAcuity = (body) => async (dispatch) => {
  try {
    const result = await postAcuityAPI(body);
    if (result.ok) {
      const json = await result.json();
      dispatch(confirmAcuity(json));
      dispatch(addAcuityToPatient(json));
    }
  } catch (err) {
    log.error(err);
  }
};

export const getAcuityQuestions = () => async (dispatch) => {
  const errorMessage = 'Could not fetch acuity questions.';
  try {
    dispatch(clearAcuityQuestionsAndError());
    const result = await getAcuityQuestionsAPI();
    if (result.ok) {
      const json = await result.json();
      dispatch(setAcuityQuestions(json));
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    log.error(err);
    dispatch(setError(errorMessage));
  }
};

export const getPatientAcuityInfo = (id) => async (dispatch) => {
  dispatch(clearAcuity());
  const errorMessage = 'Could not fetch Participant Acuity Info.';
  try {
    const res = await getPatientAcuity(id);
    if (res.ok) {
      const json = await res.json();
      dispatch(setAcuityInfo(json));
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    log.error(err);
  }

  try {
    const changes = await getAcuityLevelChanges(id, 1);
    if (changes.ok) {
      const json = await changes.json();
      const icdCodeChanges = json.map((assessment) => assessment.icdCodes);
      dispatch(setIcdCodeChanges(icdCodeChanges));
      const levelChanges = json.map((assessment) => ({
        ...assessment.intusLabels,
      }));
      dispatch(setAcuityLevelChanges(levelChanges));
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    log.error(err);
  }
};

/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import nivoColors from '@intus-ui/styles/NivoColors';
import { moneyWithCommas } from '@util/utilFunctions';
import { formatDate } from '@util/dateFunctions';
import * as userTracking from '@util/userTracking';

import {
  setSelected,
  resetModalContent,
  setSelectedMonth,
  addModalContent,
} from '@global-state/redux/dashboardSlice';

import { Modal, Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { Chip } from '@nivo/tooltip';
import LineChart from '@intus-ui/components/graphs/LineChart';
import { SpinnerOrError } from '@intus-ui';
import ModalLineGraph from './modalComponents/LineGraph';
import ModalBarChart from './modalComponents/BarChart';
import ModalTable from './modalComponents/Table';
import DashboardPatientList from './modalComponents/DashboardPatientList';
import LineDataPropType from './modalComponents/LineDataPropType';
import ListDataPropType from './modalComponents/ListDataPropType';
import BarTableDataPropType from './modalComponents/BarDataPropType';
import DataBubble from './modalComponents/DataBubble';
import Switch from './modalComponents/Switch';

import '@intus-ui/styles/Utilities.scss';

const DashboardModal = ({
  doSetSelected,
  selected,
  selectedMonth,
  doResetModalContent,
  doSetSelectedMonth,
  modalContent,
  monthMap,
  error,
  doSetModalContent,
}) => {
  if (!selected) return null;
  userTracking.logEvent(`Dashboards: clicked quad`, { name: selected.item });

  const hide = () => {
    doSetSelected(undefined);
    doResetModalContent([]);
    doSetSelectedMonth(0);
  };

  const setFilter = (event) => {
    doSetSelectedMonth(monthMap.indexOf(event.target.id));
  };

  const monthFilter = (
    <span key="enroll-filter" className="dropdownMenu-scrollable">
      {monthMap ? (
        <DropdownButton
          size="lg"
          onClick={(event) =>
            event.target.id === 'dropdown-button-selected-month' || event.target.id === ''
              ? null
              : setFilter(event)
          }
          id="dropdown-button-selected-month"
          title={formatDate(monthMap[selectedMonth], 'MMM YYYY')}
          variant="outline-primary"
          bsPrefix="m-0 mb-3 btn btn-outline-primary"
        >
          {monthMap.map((month) => (
            <Dropdown.Item key={`dropdown-item-${month}`} id={month} className="cursor-pointer">
              {formatDate(month, 'MMM YYYY')}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      ) : null}
    </span>
  );

  // sets the click function for a point on the external cost line chart.
  // this will set the modal content to be the the cost breakdown table and chart
  const setCostBreakdown = (item) => {
    const onClickFunc = (data) => {
      doSetSelectedMonth(data ? data.index + item.monthsShifted : selectedMonth);
      doResetModalContent([]);
      doSetSelected({ item: 'costBreakdown', modalTitle: 'Cost Breakdown' });
      // taking only the values of item.onClickData because we don't need to feed the date
      // into the bar chart or table
      doSetModalContent([
        {
          display: 'table',
          content: Object.values(item.onClickData),
          filter: 'month',
        },
        {
          display: 'dataBubble',
          content: Object.values(item.onClickData),
        },
      ]);
    };
    return onClickFunc;
  };

  const nivoOrder = [
    nivoColors[0],
    nivoColors[4],
    nivoColors[5],
    nivoColors[6],
    nivoColors[2],
    nivoColors[7],
    nivoColors[1],
    nivoColors[9],
    nivoColors[3],
    nivoColors[8],
  ];

  const barData = (data) =>
    Object.entries(data || {})
      .slice(0, 12)
      .map(([key, value], index) => ({
        label: key,
        count: value,
        countColor: nivoOrder[index],
      }));

  const switchFunc = () => {
    const onClickFunc = () => {
      doSetSelectedMonth(selectedMonth === 0 ? 1 : 0);
    };
    return onClickFunc;
  };

  const toolTipElement = (item) => {
    if (selected.item === 'admissions') {
      return (
        <div className="px-3 py-2 rounded-lg to-front bg-transparent-90 box-shadow-on-gray">
          <strong>{formatDate(item.data.label, 'MMMM YYYY')}</strong>
          <hr className="m-0" />
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center pr-2">
                <Chip size={12} color={item.data.admissionsColor} />
                <span className="pl-2">Admissions</span>
              </div>
              <strong>{item.data.admissions}</strong>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-row align-items-center pr-2">
                <Chip size={12} color={item.data.dischargesColor} />
                <span className="pl-2">Discharges</span>
              </div>
              <strong>{-item.data.discharges}</strong>
            </div>
          </div>
        </div>
      );
    }
    // selected.item === 'enrollments'
    return (
      <div className="px-3 py-2 rounded-lg to-front bg-transparent-90 box-shadow-on-gray">
        <strong>{formatDate(item.data.label, 'MMMM YYYY')}</strong>
        <hr className="m-0" />
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center pr-2">
              <Chip size={12} color={item.data.enrollmentsColor} />
              <span className="pl-2">Enrollments</span>
            </div>
            <strong>{item.data.enrollments || 0}</strong>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row align-items-center pr-2">
              <Chip size={12} color={item.data.deathsColor} />
              <span className="pl-2">Deaths</span>
            </div>
            <strong>{-item.data.deaths || 0}</strong>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row align-items-center pr-2">
              <Chip size={12} color={item.data.disenrollmentsColor} />
              <span className="pl-2">Disenrollments</span>
            </div>
            <strong>{-item.data.disenrollments || 0}</strong>
          </div>
        </div>
      </div>
    );
  };

  let dialogClassName = 'min-w-50 zindex-1040';
  if (modalContent) {
    const display = modalContent.length ? modalContent[0].display : undefined;
    const showFullWidth = display !== undefined && display !== 'list';
    if (modalContent.length > 1 || showFullWidth) dialogClassName = 'min-w-90 zindex-1040';
  }

  return (
    <Modal
      dialogClassName={dialogClassName}
      show={!!selected.modalTitle}
      onHide={hide}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>{selected.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 h-100 d-flex">
          {modalContent.length ? (
            <Row className="w-100 flex-grow-1 overflow-hidden">
              {modalContent.map((item) => {
                let content;
                if (item.display === 'list') {
                  content = <DashboardPatientList data={item.content} />;
                } else if (item.display === 'lineChart') {
                  content = (
                    <ModalLineGraph
                      data={item.content}
                      onClick={
                        item.content?.onClickData ? setCostBreakdown(item.content) : undefined
                      }
                    />
                  );
                } else if (item.display === 'trendline') {
                  content = (
                    <div className="d-flex h-100 w-100 p-5">
                      <LineChart
                        data={{
                          labels: item.content.labels,
                          datasets: [
                            {
                              label: item.content.setTitles[0],
                              data: item.content.data,
                              fill: true,
                              borderColor: 'rgb(75, 192, 192)',
                              backgroundColor: 'rgba(75, 192, 192, 0.3)',
                              tension: 0.1,
                            },
                          ],
                        }}
                        aspectRatio={false}
                        responsive
                        xLabel={item.content.xLabel}
                        yLabel={item.content.yLabel}
                        maxXTicks={item.content.maxXTicks}
                        maxYTicks={item.content.maxYTicks}
                      />
                    </div>
                  );
                } else if (item.display === 'barChart') {
                  content = (
                    <>
                      {item.filter === 'month' ? monthFilter : null}
                      <ModalBarChart
                        data={{ data: barData(item.content[selectedMonth]) }}
                        format={(value) => moneyWithCommas(value)}
                        margin={{
                          top: 50,
                          right: 0,
                          bottom: 250,
                          left: 60,
                        }}
                        customTooltip={({ color, indexValue, value }) => (
                          <div
                            className="px-3 py-2 rounded-lg box-shadow-on-gray m-0"
                            style={{ backgroundColor: 'rgba(255,255,255,.93)' }}
                          >
                            <div className="d-flex justify-content-start align-items-center pr-2">
                              <Chip size={12} color={color} />
                              <span className="pl-2">{indexValue}</span>
                              <span className="pl-1 font-weight-bold">
                                {moneyWithCommas(value)}
                              </span>
                            </div>
                          </div>
                        )}
                      />
                    </>
                  );
                } else if (item.display === 'timeLapseBarChart') {
                  content = (
                    <ModalBarChart
                      data={{
                        data: item.content.data[selectedMonth],
                        title: item.content.title,
                        xTitle: item.content.xTitle,
                        yTitle: item.content.yTitle,
                        minValue: item.content.minValue,
                        maxValue: item.content.maxValue,
                      }}
                      format={item.content.format ? item.content.format : (value) => value}
                      xFormat={item.content.xFormat ? item.content.xFormat : (value) => value}
                      legend={item.content.legend}
                      margin={{
                        top: 50,
                        right: 0,
                        bottom: 200,
                        left: 80,
                      }}
                      toggle={
                        <Switch onClickFunc={switchFunc(item)} labels={item.content.toggleLabels} />
                      }
                      customTooltip={selectedMonth === 0 ? toolTipElement : null}
                    />
                  );
                } else if (item.display === 'table') {
                  content = <ModalTable data={item.content[selectedMonth]} />;
                } else if (item.display === 'dataBubble') {
                  content = <DataBubble data={item.content[selectedMonth]} />;
                } else {
                  content = <SpinnerOrError error={error} />;
                }
                return (
                  <Col
                    key={`item-${item.display}-${item.content?.title}`}
                    lg={modalContent.length > 1 ? 12 / modalContent.length : 12}
                    className="w-100 h-100 d-flex flex-column overflow-hidden"
                  >
                    <Row className="m-0 d-flex w-100 flex-shrink-1">
                      {item.filter === 'month' ? monthFilter : null}
                    </Row>
                    <Row className="m-0 d-flex w-100 flex-grow-1 overflow-hidden">{content}</Row>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <SpinnerOrError error={error} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

DashboardModal.propTypes = {
  error: PropTypes.string,
  doSetSelected: PropTypes.func.isRequired,
  doSetSelectedMonth: PropTypes.func.isRequired,
  doSetModalContent: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    item: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
  }),
  expenseHistory: PropTypes.shape({
    max: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  modalContent: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      content: PropTypes.oneOfType([
        ListDataPropType,
        LineDataPropType,
        PropTypes.arrayOf(BarTableDataPropType),
      ]),
      filter: PropTypes.string,
    })
  ),
  doResetModalContent: PropTypes.func.isRequired,
  selectedMonth: PropTypes.number,
  monthMap: PropTypes.arrayOf(PropTypes.string),
};

DashboardModal.defaultProps = {
  selected: undefined,
  error: null,
  expenseHistory: undefined,
  modalContent: [],
  selectedMonth: undefined,
  monthMap: undefined,
};

const mapState = (state) => ({
  error: state.dashboard.error,
  selected: state.dashboard.selected,
  listTitles: state.dashboard.listTitles,
  lineChart: state.dashboard.lineChart,
  expenseHistory: state.dashboard.externalCostHistory,
  modalContent: state.dashboard.modalContent,
  selectedMonth: state.dashboard.selectedMonth,
  monthMap: state.dashboard.monthIndexMap,
});

const mapDispatch = (dispatch) => ({
  doSetSelected: (val) => dispatch(setSelected(val)),
  doResetModalContent: (val) => dispatch(resetModalContent(val)),
  doSetSelectedMonth: (val) => dispatch(setSelectedMonth(val)),
  doSetModalContent: (val) => dispatch(addModalContent(val)),
});

export default withRouter(connect(mapState, mapDispatch)(DashboardModal));

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setReporting } from '@global-state/redux/helpSlice';

import FeedbackIcon from '@mui/icons-material/Feedback';

import '@intus-ui/styles/IntusNavBar.scss';

const HelpButton = ({ reporting, startReporting, stopReporting, currWalkthrough }) => {
  if (currWalkthrough || currWalkthrough === 0) {
    return null;
  }
  return (
    <button
      type="button"
      className="help-icon rounded-circle box-shadow-on-gray"
      onClick={reporting ? stopReporting : startReporting}
    >
      <FeedbackIcon style={{ fontSize: '1.75em' }} />
    </button>
  );
};

HelpButton.propTypes = {
  reporting: PropTypes.bool.isRequired,
  startReporting: PropTypes.func.isRequired,
  stopReporting: PropTypes.func.isRequired,
  currWalkthrough: PropTypes.number,
};

HelpButton.defaultProps = {
  currWalkthrough: undefined,
};

const mapState = (state) => ({
  reporting: state.help.reporting,
  currWalkthrough: state.help.walkthrough,
});

const mapDispatch = (dispatch) => ({
  startReporting: () => dispatch(setReporting(true)),
  stopReporting: () => dispatch(setReporting(false)),
});

export default connect(mapState, mapDispatch)(HelpButton);

import { getSessionUser } from '@util/session';

interface FeatureFlag {
    name: string;
    featureIsActive: boolean;
}

/**
 * Retrieves the feature flag information for a given feature name.
 * @param {string} featureName - The name of the feature flag to retrieve.
 * @returns {FeatureFlag} - An object containing the feature flag information.
 */
export const getFeatureFlag = (featureName: string): FeatureFlag => {
    const userActiveFeatureFlags: string[] = getSessionUser()?.flags || [];
    const activeFeatureName: string | undefined = userActiveFeatureFlags.find(
        (featureflag) => featureflag === featureName
    );

    if (!activeFeatureName) return { name: featureName, featureIsActive: false };
    return { name: featureName, featureIsActive: true };
};

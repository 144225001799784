import produce from 'immer';
import { Divider, Text, SingleDateSelect, Checkbox, Button, Icon } from '@intus-ui';
import { isString } from 'lodash';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { isSameOrBeforeDate } from '@util/dateFunctions';
import { FormErrorMessage } from '@intus-ui/components/forms/errors/FormErrorMessage';

const ListTitles = ({ titles = [] }) => (
  <div style={styles.listTitlesContainer}>
    <div style={styles.listTitles}>
      {titles.map((title) => (
        <div key={title} style={styles.listTitlesItem}>
          {isString(title) ? <Text color="caption">{title}</Text> : title}
        </div>
      ))}
    </div>
    <Divider />
  </div>
);

const CohortListItem = ({ cohort, currentIndex, form }) => {
  const { trigger, getValues, setValue, control, formState } = form;

  const cohorts = getValues('cohorts');

  const useFirstDateForAllCohorts = getValues('useFirstDateForAllCohorts');

  const isOnGoing = getValues(`cohorts[${currentIndex}].isOnGoing`);

  const isUsingFirstDateRangeForAll = currentIndex !== 0 && useFirstDateForAllCohorts;

  const handleChangeStartDate = (date) => {
    setValue(`cohorts[${currentIndex}].startDate`, date, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });

    if (useFirstDateForAllCohorts) {
      cohorts.forEach((_c, index) => {
        setValue(`cohorts[${index}].startDate`, date);
      });
    }
    if (cohorts[currentIndex]?.endDate) {
      trigger(`cohorts[${currentIndex}].endDate`);
    }
  };

  const handleChangeEndDate = (date) => {
    setValue(`cohorts[${currentIndex}].endDate`, date, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });

    if (useFirstDateForAllCohorts) {
      cohorts.forEach((_c, index) => {
        setValue(`cohorts[${index}].endDate`, date);
        setValue(`cohorts[${index}].isOnGoing`, date == null);
      });
    }
    if (cohorts[currentIndex]?.startDate) {
      trigger(`cohorts[${currentIndex}].startDate`);
    }
  };

  const handleSetIsOnGoing = (value) => {
    setValue(`cohorts[${currentIndex}].isOnGoing`, value);

    if (value) {
      handleChangeEndDate(null);
    }

    if (useFirstDateForAllCohorts) {
      cohorts.forEach((_c, index) => {
        setValue(`cohorts[${index}].isOnGoing`, value);
      });
    }

    trigger(cohorts.map((_c, index) => `cohorts[${index}].endDate`));
  };

  const showRemoveButton = false;

  return (
    <div style={styles.cohortListItem}>
      <div style={styles.listItem}>
        <Text>{cohort?.name}</Text>
      </div>
      <div style={styles.listItem}>
        {!isUsingFirstDateRangeForAll && (
          <>
            <Controller
              control={control}
              name={`cohorts[${currentIndex}].startDate`}
              rules={{ required: 'Start Date is required.' }}
              render={({ field: { value } }) => {
                return (
                  <SingleDateSelect
                    props={{ style: { flex: 1 } }}
                    onSelectDate={handleChangeStartDate}
                    existingDate={value}
                    disabled={isUsingFirstDateRangeForAll}
                  />
                );
              }}
            />
            <Text type="body" style={{ margin: '0px 10px 0px 20px' }}>
              -
            </Text>
          </>
        )}
      </div>
      <div style={styles.listItem}>
        {!isUsingFirstDateRangeForAll && (
          <>
            <Checkbox
              onChange={handleSetIsOnGoing}
              aria-labelledby="ongoing-text"
              checked={isOnGoing}
              disabled={isUsingFirstDateRangeForAll}
              style={{ cursor: 'pointer' }}
            />
            <Text
              disabled={isUsingFirstDateRangeForAll}
              style={{ marginRight: 10 }}
              onClick={() => handleSetIsOnGoing(!isOnGoing)}
            >
              Ongoing
            </Text>
            <div
              style={{
                display: 'flex',
                flex: 1,
                width: '100%',
                height: 37.5,
              }}
            >
              {!isOnGoing && (
                <Controller
                  control={control}
                  name={`cohorts[${currentIndex}].endDate`}
                  rules={{
                    validate: (value, formValues) => {
                      if (
                        formValues.cohorts[currentIndex].isOnGoing ||
                        isSameOrBeforeDate(cohorts[currentIndex].startDate, value, 'day')
                      ) {
                        return true;
                      }
                      return 'Error: End date must be after start date.';
                    },
                  }}
                  render={({ field: { value } }) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          flex: 1,
                        }}
                      >
                        <SingleDateSelect
                          disabled={isUsingFirstDateRangeForAll}
                          props={{ style: { width: '100%' } }}
                          onSelectDate={handleChangeEndDate}
                          existingDate={value}
                        />
                        <FormErrorMessage
                          error={formState?.errors?.cohorts?.[currentIndex]?.endDate}
                        />
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </>
        )}

        {showRemoveButton && (
          <Button
            compact
            hoverText=""
            name=""
            onClick={() => {
              const cohortsList = produce(cohorts, (draft) => {
                draft.splice(currentIndex, 1);
              });
              setValue('cohorts', cohortsList, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
            style={{ marginLeft: 'auto' }}
            tabbed
          >
            <Icon color="#9D9D9D" name="Remove" />
          </Button>
        )}
      </div>
    </div>
  );
};

const CohortsList = ({ form }) => {
  const { control, setValue, getValues, trigger, watch } = form;

  const cohorts = getValues('cohorts');

  const handleUseFirstDateRangeForAll = (formValues) => {
    const { startDate, endDate, isOnGoing } = formValues.cohorts[0];

    // Updates data
    if (formValues.useFirstDateForAllCohorts) {
      formValues.cohorts.forEach((_c, index) => {
        setValue(`cohorts[${index}].startDate`, startDate);
        setValue(`cohorts[${index}].endDate`, endDate);
        setValue(`cohorts[${index}].isOnGoing`, isOnGoing);
      });
    }
    trigger(formValues.cohorts.map((_c, index) => `cohorts[${index}].startDate`));
    if (endDate) {
      trigger(formValues.cohorts.map((_c, index) => `cohorts[${index}].endDate`));
    }
  };

  useEffect(() => {
    const subscription = watch((formValues, { name, type }) => {
      if (name === 'useFirstDateForAllCohorts' && type === 'change') {
        handleUseFirstDateRangeForAll(formValues);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  function toggleUseFirstDateForAll() {
    setValue('useFirstDateForAllCohorts', !getValues('useFirstDateForAllCohorts'), {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }

  const titles = [
    'Cohort name',
    'Time Range of Initiative Participation *',
    <div style={styles.listTitlesItem}>
      <Controller
        control={control}
        name="useFirstDateForAllCohorts"
        render={({ field: { value, onChange } }) => (
          <Checkbox
            aria-labelledby="ongoing-text"
            checked={value}
            onChange={onChange}
            disabled={false}
            style={{ cursor: 'pointer' }}
          />
        )}
      />
      <Text color="caption" onClick={() => toggleUseFirstDateForAll()}>
        Use first date range for all
      </Text>
    </div>,
  ];

  return (
    <div style={styles.container}>
      <ListTitles titles={titles} />
      <div style={styles.cohortListItems}>
        {cohorts.map((cohort, index) => (
          <CohortListItem key={cohort.id} currentIndex={index} cohort={cohort} form={form} />
        ))}
      </div>
    </div>
  );
};

export default CohortsList;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: 5,
    padding: 5,
    gap: 10,
  },
  listTitlesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  listTitles: {
    display: 'flex',
    flex: 1,
    gap: 5,
  },
  listTitlesItem: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: 'fit-content',
  },
  listItem: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: 40,
  },
  cohortListItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  cohortListItem: {
    display: 'flex',
    flex: 1,
    gap: 5,
  },
};

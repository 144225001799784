/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { updateUserAPI } from '@api/api';
import { getLogger } from '@util/logger';
import { setSessionUser } from '@util/session';

const log = getLogger('notificationSlice');

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notificationBody: undefined,
    showUpdateMessage: false,
  },
  reducers: {
    setShowNotification(state, { payload }) {
      state.notificationBody = payload;
    },
    setShowUpdateMessage(state, { payload }) {
      state.showUpdateMessage = payload;
    },
  },
});

export default notificationSlice.reducer;
export const { setShowNotification, setShowUpdateMessage } = notificationSlice.actions;

/**
 * deletes any browser caches for the app and hard refreshes.
 */
export const deleteCachesAndRefresh = async () => {
  if (caches) {
    const names = await caches.keys();
    await Promise.all(names.map((name) => caches.delete(name)));
  }
  window.location.reload();
};

export const updateUserUpdatedAtAndRefresh = (user) => async () => {
  try {
    const res = await updateUserAPI({ updatedAt: user.updatedAt }, user.id);
    if (res.ok) {
      const resJ = await res.json();
      setSessionUser(resJ);
      deleteCachesAndRefresh();
    }
  } catch (e) {
    log.error(e);
  }
};

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as userTracking from '@util/userTracking';

import { setSelected, resetModal } from '@global-state/redux/homepageSlice';

import { Modal } from 'react-bootstrap';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { getCard } from 'Homes/cardMap';

import '@intus-ui/styles/Utilities.scss';
import '@intus-ui/styles/Home.scss';
import { premiumDashboards } from 'Dashboard/premiumDashNames';

const HomeModal = ({ selected, doResetModalContent, history }) => {
  if (!selected) return null;

  const hide = () => {
    doResetModalContent([]);
  };
  userTracking.logEvent(`Home: clicked ${selected.title} quad`);

  return (
    <Modal
      dialogClassName="home-modal min-w-90 overflow-hidden"
      show={!!selected}
      onHide={hide}
      scrollable
      aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 1040 }}
    >
      <Modal.Header className="py-0 pl-4 pt-3 m-0" closeButton>
        <Modal.Title className=" d-flex flex-row justify-content-between align-items-center text-align-left text-secondary bg-light w-100 border-bottom border-secondary mb-0 p-2">
          <div>
            {selected.title} Analytics
            <button
              type="button"
              title="Expand to full dash view"
              onClick={() => {
                const { route } = premiumDashboards.find(
                  (dash) => dash.name === getCard(selected.boxName).dashName
                );
                history.push(route);
              }}
              className="outline-none border-0 hover-light-blue text-primary rounded bg-transparent ml-1"
            >
              <AspectRatioIcon />
            </button>
          </div>
          {selected.title === 'Financial' && (
            <p className="text-dark font-size-xs">
              * Financials data is not filtered by team or center
            </p>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="my-0 mx-2" style={{ height: '100vh' }}>
        <div className="w-100 h-100">{getCard(selected.boxName).content}</div>
      </Modal.Body>
    </Modal>
  );
};

HomeModal.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.shape({ boxName: PropTypes.string, title: PropTypes.string }),
  doResetModalContent: PropTypes.func.isRequired,
};

HomeModal.defaultProps = {
  selected: undefined,
};

const mapState = (state) => ({
  error: state.homepage.error,
  selected: state.homepage.selectedTitle,
  modalContent: state.homepage.modalContent,
});

const mapDispatch = (dispatch) => ({
  doSetSelected: (val) => dispatch(setSelected(val)),
  doResetModalContent: () => dispatch(resetModal()),
});

export default withRouter(connect(mapState, mapDispatch)(HomeModal));

/* eslint-disable prefer-arrow-callback */
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { setReporting, sendFeedbackForm } from '@global-state/redux/helpSlice';

import { Modal, Form, Col } from 'react-bootstrap';
import { Button } from '@intus-ui';
import FormikFormControl from '@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';

import '@intus-ui/styles/Profile.scss';
import '@intus-ui/styles/Utilities.scss';
import { getSessionUser } from '@util/session';

const phoneRegExp = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
const emailRegExp = /\S+@\S+\.\S+/;

const schema = Yup.object().shape({
  contact: Yup.string()
    .test(
      'test-name',
      'Please enter a valid phone number (xxx-xxx-xxxx) or email address (example@email.com)',
      (value) => {
        if (!new RegExp(emailRegExp).test(value) && !new RegExp(phoneRegExp).test(value)) {
          return false;
        }
        return true;
      }
    )
    .typeError('Required')
    .required('Required'),
  type: Yup.string().typeError('Required').required('Required'),
  summary: Yup.string().typeError('Required').required('Required'),
  description: Yup.string().typeError('Required').required('Required'),
});

const HelpModal = ({ reporting, stopReporting, sendReport }) => {
  if (!reporting) {
    return null;
  }
  return (
    <Modal show onHide={stopReporting}>
      <Modal.Header closeButton>
        <Modal.Title>Submit Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            type: 'General inquiry',
            contact: getSessionUser().email,
          }}
          onSubmit={(data) => {
            sendReport(data);
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, errors, touched, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <FormikFormControl
                  as={Col}
                  md="12"
                  label="Your Email Address"
                  type="text"
                  name="contact"
                  placeholder="Your Email Address"
                  disabled
                />
              </Form.Row>
              <Form.Row>
                <FormikFormControl
                  as={Col}
                  md="6"
                  label="Feedback type"
                  controlAs="select"
                  name="type"
                >
                  <option value="none" defaultValue disabled hidden>
                    Select an Option
                  </option>
                  <option value="General inquiry">General inquiry</option>
                  <option value="Data issue">Data issue</option>
                  <option value="Platform feature issue">Platform feature issue</option>
                  <option value="Product feedback">Product feedback</option>
                  <option value="Other">Other</option>
                </FormikFormControl>
                <FormikFormControl
                  as={Col}
                  md="6"
                  rows={1}
                  label="Subject"
                  placeholder="Enter summary"
                  controlAs="textarea"
                  name="summary"
                />
              </Form.Row>
              <Form.Row>
                <FormikFormControl
                  as={Col}
                  md="12"
                  label="Description"
                  controlAs="textarea"
                  rows={7}
                  name="description"
                  placeholder="Enter a description"
                />
              </Form.Row>
              <Form.Row>
                <Col>
                  <Button
                    name="Submit Feedback"
                    type="submit"
                    fluid
                    busy={isSubmitting}
                    disabled={
                      Object.values(errors).length > 0 || Object.values(touched).length === 0
                    }
                  />
                </Col>
              </Form.Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

HelpModal.propTypes = {
  reporting: PropTypes.bool.isRequired,
  stopReporting: PropTypes.func.isRequired,
  sendReport: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  reporting: state.help.reporting,
  currentPage: state.help.page,
});

const mapDispatch = (dispatch) => ({
  stopReporting: () => dispatch(setReporting(false)),
  sendReport: (data) => dispatch(sendFeedbackForm(data)),
});

export default connect(mapState, mapDispatch)(HelpModal);

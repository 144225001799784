import useEventListener from '@intus-ui/components/useEventListener';
import { Tooltip } from '@mui/material';
import { Chart, TooltipModel, TooltipOptions } from 'chart.js';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { VirtualElement } from '@popperjs/core';

/**
 * Helper hook to display a React tooltip over the hovered point in a chart.js graph.
 *
 * This hook handles rendering the tooltip and positioning it correctly on the chart.
 *
 * Usage:
 *
 * ```jsx
 *
 *  const MyComponent = () => {
 *
 *  const { tooltipComponent, tooltipOptions } = useReactChartTooltipV2((dataIndex) => {
 *    return <div>{myData[dataIndex].value}</div>;
 *  });
 *
 * return (
 *  <div>
 *    {tooltipComponent}
 *    <Bar
 *      data={...}
 *      options={{
 *        tooltips: tooltipOptions,
 *      }}
 *     />
 *   );
 * }
 * ```
 */
export function useReactChartTooltipV2(
  renderTooltipContents: (dataIndex: number) => ReactNode,
  isHorizontalBar = false
) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [dataIndex, setDataIndex] = useState(0);

  const [chartElement, setChartElement] = useState<HTMLElement | null>(null);

  const [tooltipX, setTooltipX] = useState(0);
  const [tooltipY, setTooltipY] = useState(0);

  // See https://mui.com/material-ui/react-tooltip/#virtual-element
  const virtualElement = useMemo<VirtualElement>(() => {
    return {
      getBoundingClientRect: () => {
        if (chartElement == null) return new DOMRect(0, 0, 0, 0);

        const rect = DOMRect.fromRect(chartElement.getBoundingClientRect());

        rect.x += tooltipX;
        rect.width = 1;
        rect.y += tooltipY;
        rect.height = 1;

        return rect;
      },
      contextElement: chartElement ?? undefined,
    };
  }, [chartElement, tooltipX, tooltipY]);

  const [scrollParent, setScrollParent] = useState<HTMLElement | null>(null);

  const hideTooltip = useCallback(() => {
    setIsTooltipVisible(false);
  }, []);

  useEventListener(
    'mouseleave',
    (event: any) => {
      // Ignore mouse leave events if the mouse is moving over the tooltip.
      let { relatedTarget } = event;
      while (relatedTarget != null) {
        if (relatedTarget.classList?.contains('MuiTooltip-popper')) {
          return;
        }
        relatedTarget = relatedTarget.parentElement;
      }

      hideTooltip();
    },
    chartElement as any
  );
  useEventListener('scroll', hideTooltip, scrollParent as any);

  // See https://mui.com/material-ui/react-tooltip/#virtual-element
  const tooltipComponent = (
    <Tooltip
      title={renderTooltipContents(dataIndex)}
      arrow
      open={isTooltipVisible}
      onOpen={() => {
        // no op
      }}
      onClose={() => {
        setIsTooltipVisible(false);
      }}
      placement={isHorizontalBar ? 'right' : 'top'}
      PopperProps={{
        anchorEl: virtualElement,
      }}
      componentsProps={{
        tooltip: {
          sx: {
            padding: '10px',
          },
        },
      }}
    >
      {/* This invisible div does nothing but material-ui blows up without it. */}
      <div style={{ display: 'none' }} />
    </Tooltip>
  );

  const setTooltipModel = useCallback(
    (chart: Chart, tooltipModel: TooltipModel<'bar'>) => {
      // When we first hover the chart, add a mouseleave event to hide the tooltip.
      // We do this as the chart.js tooltip event does not always fire when scrolling or moving the mouse quickly
      // and we end up with 2 tooltips displayed.
      if (!chartElement) {
        setChartElement(chart.canvas);

        const chartScrollParent = getScrollParent(chart.canvas);
        setScrollParent(chartScrollParent);
      }

      // Hide the tooltip if we're hovering an area outside the points.
      if (
        tooltipModel.opacity === 0 ||
        tooltipModel.dataPoints == null ||
        tooltipModel.dataPoints.length === 0
      ) {
        setIsTooltipVisible(false);
        return;
      }

      // There will be multiple dataPoints if there are overlapping points in the graph.
      // We assume we want to show a single tooltip for each point on the x-axis, so we just use the x-axis index
      setDataIndex(tooltipModel.dataPoints[0].dataIndex);
      setIsTooltipVisible(true);

      setTooltipX(tooltipModel.caretX);
      setTooltipY(tooltipModel.caretY);
    },
    [chartElement]
  );

  const tooltipOptions = useMemo(() => getChartOptions(setTooltipModel), [setTooltipModel]);

  return {
    tooltipComponent,
    tooltipOptions,
    chartElement,
  };
}

function getChartOptions(
  setTooltipModel: (chart: Chart, tooltipModel: TooltipModel<'bar'>) => void
) {
  // We're on an old version of chart.js, here's the docs on configuring tooltips.
  // See: https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html

  const tooltipOptions: Partial<TooltipOptions<'bar'>> = {
    mode: 'point',
    intersect: false,
    // Disable the on-canvas tooltip
    enabled: false,

    external({ tooltip }) {
      setTooltipModel(this.chart, tooltip);
    },
  };

  return tooltipOptions;
}

function getScrollParent(node: HTMLElement | null): HTMLElement | null {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }
  return getScrollParent(node.parentNode as HTMLElement);
}

import Text from '../Text';

/* eslint-disable no-nested-ternary */
const DropdownItem = ({
  onSelect,
  hasSuggestion,
  index,
  setIsCollapsed,
  option,
  empty,
  suggestionLabel,
  noMatchLabel,
  renderListItem,
  textStyle,
}) => {
  const handleOnSelect = () => {
    if (empty) {
      return;
    }
    onSelect(option);
    setIsCollapsed(false);
  };
  const isUndefined = option === '';

  const styles = {
    wrapper: {
      padding: 5,
      width: '100%',
      height: empty ? 40 : '100%',
    },
    itemStyles: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
    },
  };

  function renderListItemContent() {
    if (renderListItem) {
      return renderListItem(option);
    }
    const { pill, label } = option;
    return (
      <div style={styles.itemStyles}>
        {pill && <Text type="body">{pill}: </Text>}
        <Text {...textStyle}>{label || option}</Text>
      </div>
    );
  }

  return (
    <div
      className="dd-item"
      style={styles.wrapper}
      onClick={handleOnSelect}
      onKeyDown={handleOnSelect}
      role="none"
    >
      {empty ? noMatchLabel : renderListItemContent()}
      {index === 0 && !isUndefined && hasSuggestion && suggestionLabel}
    </div>
  );
};

export default DropdownItem;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { logEvent } from '@util/userTracking';

import { setCurrPatient, setPatientList } from '@global-state/redux';
import {
  AdmitRecord,
  UtilizationData,
  UtilizationFrequentFlyers,
  UtilizationsAnalytics,
} from 'Utilization/types';

import { formatDate } from '@util/dateFunctions';

import { useUtilizationContext } from 'Utilization/Context';

import { DetailsButton, Text, List, Modal } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import DetailsModal from '../DetailsModal';

import { styles } from './styles';

const General = ({ data }: { data: any }) => {
  const { admitsType, startDate, endDate, orgName } = useUtilizationContext();
  const { totalAdmits } = data;
  return (
    <>
      <Text type="subtitle">General</Text>
      <div style={styles.generalAnalysisItems}>
        <Text type="headline" style={{ paddingRight: '10px' }}>
          {totalAdmits.toLocaleString()}
        </Text>
        <Text>
          {admitsType} admits between {formatDate(startDate, 'M/D/YYYY')} and{' '}
          {formatDate(endDate, 'M/D/YYYY')} in {orgName}
        </Text>
      </div>
    </>
  );
};

const READMIT_INCLUDE_LIST = ['IP', 'ED', 'ED/IP', 'ED/OBS', 'IP PSYCH', 'OBS', 'SNF'];
const READMIT_TOOLTIP: any = {
  IP: 'The readmission period for acute inpatient admissions is set at 30 days.',
  ED: 'The readmission period for ED admissions is set at 90 days.',
  'ED/IP': 'The readmission period for ED to Inpatient is set at 30 days.',
  'ED/OBS': 'The readmission period for ED to Observation is set at 30 days.',
  'IP PSYCH': 'The readmission period for Inpatient Psychiatric admissions is set at 30 days.',
  OBS: 'The readmission period for Observation stays is set at 30 days.',
  SNF: 'The readmission period for Skilled Nursing Facility admissions is set at 6 months.',
};

const Trends = ({ data, admits }: { data: UtilizationsAnalytics; admits: AdmitRecord[] }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalAdmits, setModalAdmits] = useState<AdmitRecord[]>([]);
  const [modalTitle, setModalTitle] = useState<string>();

  const { admitsType } = useUtilizationContext();
  const { readmits, losGTE10days, percentToAdmitTarget } = data;

  const tooltipContent = READMIT_TOOLTIP[admitsType];

  const onClick = (title: string, admitIds: number[]) => {
    const filteredAdmits = admits.filter((admit) => admitIds.includes(admit.id));
    setModalTitle(title);
    setModalAdmits(filteredAdmits);
    setOpenModal(true);
  };

  return (
    <>
      <Modal
        header={{ title: modalTitle, centered: true }}
        type="large"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <DetailsModal admits={modalAdmits} admitsType={admitsType} />
      </Modal>

      <div style={styles.analyticsContainer}>
        <Text type="subtitle">Trends</Text>

        {READMIT_INCLUDE_LIST.includes(admitsType) && (
          <div style={styles.analyticsItem}>
            <div style={styles.analyticsStat}>
              <Text type="title">{readmits.value}%</Text>
              <Text wrapper="span">of these admits were readmissions</Text>
            </div>
            <div>
              <Text type="caption">{tooltipContent}</Text>
            </div>
            <div style={styles.seeDetails}>
              <DetailsButton
                onClick={() => {
                  logEvent('Utilization: Analytics Sidebar: Readmissions See More Clicked');
                  onClick('Readmissions', readmits.admits);
                }}
              />
            </div>
          </div>
        )}

        <div style={styles.analyticsItem}>
          <div style={styles.analyticsStat}>
            <Text type="title">{losGTE10days.value}%</Text>
            <Text wrapper="span">of these admit stays were longer than 10 days</Text>
          </div>
          <div style={styles.seeDetails}>
            <DetailsButton
              onClick={() => {
                logEvent('Utilization: Analytics Sidebar: LOS See More Clicked');
                onClick('Long Length of Stay', losGTE10days.admits);
              }}
            />
          </div>
        </div>

        {percentToAdmitTarget.value > 0 && (
          <div style={styles.analyticsItem}>
            <div style={styles.analyticsStat}>
              <Text type="title">{percentToAdmitTarget.value}%</Text>
              <Text wrapper="span">
                to {admitsType} count target of {percentToAdmitTarget.target}
              </Text>
            </div>
            <div style={styles.seeDetails}>
              <DetailsButton
                onClick={() => {
                  logEvent('Utilization: Analytics Sidebar: Target Admits See More Clicked');
                  onClick('All Admits', percentToAdmitTarget.admits);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const format = [
  {
    field: 'name',
    name: 'Participant Name',
    flex: 2,
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
    customStyle: {
      textOverflow: 'ellipses',
    },
  },
  {
    field: 'count',
    name: 'Admits',
    flex: 1,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="count" id="count" /> }],
  },
];

const columnConfig = {
  default: {
    columns: ['name', 'count'],
  },
};

const FrequentFlyers = ({ data }: { data: UtilizationFrequentFlyers[] }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div>
      <Text type="subtitle">High Utilizers</Text>
      <List
        data={data}
        condensed
        format={format}
        columnConfig={columnConfig.default}
        defaultSort={{ field: 'count', direction: 'desc' }}
        onClickItem={(item) => {
          const currIndex = data?.findIndex((ppt: UtilizationFrequentFlyers) => ppt.id === item.id);
          dispatch(
            setPatientList(
              data.map((obj: UtilizationFrequentFlyers) => {
                return {
                  id: obj.id,
                  name: obj.name,
                };
              })
            )
          );
          dispatch(setCurrPatient(currIndex));
          history.push(`/patient/${item?.id}/admits`);
        }}
      />
    </div>
  );
};

const AnalyticsTab = ({ data }: { data: UtilizationData }) => {
  return data ? (
    <div style={styles.sidebarContentContainer}>
      <General data={data.analytics} />
      <Trends data={data.analytics} admits={data.admits} />
      <FrequentFlyers data={data.frequentFlyers} />
    </div>
  ) : null;
};

export default AnalyticsTab;

import { clsx } from 'clsx';
import { getTextProperties } from './helpers';
import Text from '../Text';
import './style.scss';

const ListItem = ({
  item,
  columns,
  format,
  onClickItem,
  itemIndex,
  listStyle,
  extraRenders,
  islowlightItem,
  style,
  clickable,
}) => {
  // format each item by the desired columns set in the parent
  const formattedItem = Object.fromEntries(
    Object.entries(item).filter((property) => {
      return columns?.includes(property[0]);
    })
  );

  const isStriped = listStyle === 'striped';
  // styling is different for single column

  // applies styling for striped listStyle
  let listItemClass = 'list-item';
  if (isStriped && Math.abs(itemIndex % 2) === 1) listItemClass = 'striped-list-item';
  if (isStriped && Math.abs(itemIndex % 2) !== 1) listItemClass = 'striped-gray-list-item';
  if (isStriped && Math.abs(itemIndex % 2) === 1 && clickable)
    listItemClass = 'striped-list-item-clickable';
  if (isStriped && Math.abs(itemIndex % 2) !== 1 && clickable)
    listItemClass = 'striped-gray-list-item-clickable';
  if (islowlightItem) listItemClass += ' lowlight-list-item';

  const handleOnClick = () => {
    onClickItem(item);
  };

  return (
    <div
      className="listItem-div listItem-div1"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '30px',
        alignItems: 'center',
        ...style,
      }}
    >
      {extraRenders?.beforeRow != null && (
        <div className="extraRenders">{extraRenders?.beforeRow(item)}</div>
      )}
      <div
        className={clsx('listItem-div', 'listItem-div2', listItemClass)}
        onClick={handleOnClick}
        role="none"
      >
        {columns.map((column) => {
          const columnItem = formattedItem[column];
          const field = format.find((formatItem) => formatItem.field === column);
          // the style of each property as determined by the format object
          const textProperties = getTextProperties(field, islowlightItem);

          const textStyles = isStriped
            ? { padding: '5px 0px 5px 0px', overflowWrap: 'normal' }
            : { padding: '5px 0px 5px 0px', overflowWrap: 'normal' };

          return (
            <div
              key={column}
              className={clsx('listItem-div', 'listItem-div3', field?.className)}
              style={{
                display: 'flex',
                flex: `${field?.flex}`,
                justifyContent: `${field?.align}`,
                paddingRight: `${field?.paddingRight}`,
                minWidth: 0, // minWidth has to be 0 for text truncating to work
                ...field?.customStyles,
              }}
            >
              <div
                className="listItem-div"
                // truncate text on overflow
                style={
                  typeof columnItem === 'string'
                    ? textStyles
                    : { padding: isStriped && '5px 0px 5px 0px' }
                }
              >
                {field.render != null && field.render({ item })}
                {field.render == null && typeof columnItem === 'string' && (
                  <Text {...textProperties}>{columnItem}</Text>
                )}
                {field.render == null && typeof columnItem !== 'string' && columnItem}
              </div>
            </div>
          );
        })}
      </div>
      {extraRenders?.afterRow != null && (
        <div className="extraRenders">{extraRenders?.afterRow(item)}</div>
      )}
    </div>
  );
};

export default ListItem;

import { useEffect, useState } from 'react';

import List from '@intus-ui/components/List/';

import { MedicationsListProvider } from './Context';
import { formatMedications } from './helpers';
import { defaultColumnConfig } from './list/columnConfigs';
import { format } from './list/format';

const MedicationsListContents = ({
  data,
  customColumnConfig,
  customFormat,
  searchable,
  defaultSort,
  titleRowElement,
  emptyListMessage,
  showColumnHeaders,
  loading,
  onClickItem,
}) => {
  // TO DO: Add back in when adding filter
  //   const { setFilterOptions } = useMedicationsListContext();

  // columnConfig will change based off of value of external filters
  const [columnConfig, setColumnConfig] = useState(customColumnConfig?.default);
  const [medications, setMedications] = useState(formatMedications(data));

  // actually update columns if they change dynamically
  useEffect(() => {
    setColumnConfig(customColumnConfig?.default);
  }, [customColumnConfig]);

  useEffect(() => {
    setMedications(formatMedications(data));
  }, [data]);

  // TO DO: Once we have risk factor type, add this back in and use that data for filter options
  // setting filter dropdown options based on available data
  //   useEffect(() => {
  //     let riskFactorOptions = ['All', 'Psychotropic'];

  //     data.forEach((item) => {
  //       const { fallsRiskFactors } = item;
  //       if (fallsRiskFactors && fallsRiskFactors.length > 0) {
  //         fallsRiskFactors.forEach((factor) => riskFactorOptions.push(factor));
  //       }
  //     });

  //     riskFactorOptions = [
  //       ...uniq(
  //         riskFactorOptions.filter((item) => item !== undefined && item !== null && item !== '')
  //       ),
  //     ];
  //     setFilterOptions(riskFactorOptions);
  //   }, [data]);

  return (
    <List
      listStyle="striped"
      columnConfig={columnConfig}
      format={customFormat}
      data={medications}
      searchable={searchable}
      defaultSort={defaultSort}
      titleRowElement={titleRowElement}
      emptyListMessage={emptyListMessage}
      showColumnHeaders={showColumnHeaders}
      loading={loading}
      onClickItem={onClickItem}
    />
  );
};

const MedicationsList = ({
  data,
  customFormat = format(),
  customColumnConfig = defaultColumnConfig,
  searchable = false,
  defaultSort = { field: 'lastOrdered', direction: 'desc' },
  titleRowElement,
  emptyListMessage = 'No medications in this list',
  showColumnHeaders,
  loading,
  onClickItem,
}) => {
  return (
    <MedicationsListProvider>
      <MedicationsListContents
        data={data}
        customColumnConfig={customColumnConfig}
        customFormat={customFormat}
        searchable={searchable}
        defaultSort={defaultSort}
        titleRowElement={titleRowElement}
        emptyListMessage={emptyListMessage}
        showColumnHeaders={showColumnHeaders}
        loading={loading}
        onClickItem={onClickItem}
      />
    </MedicationsListProvider>
  );
};

export default MedicationsList;

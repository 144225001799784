import { MenuItem, Select } from '@mui/material';
import React, { FC } from 'react';

export const allHospiceStatuses = ['All', 'Yes', 'No'] as const;
export type IAllHospiceStatuses = (typeof allHospiceStatuses)[number];

export type HospiceSelectProps = {
  hospiceStatus: string;
  setHospiceStatus: (status: string) => void;
};

export const HospiceFilter: FC<HospiceSelectProps> = ({ hospiceStatus, setHospiceStatus }) => {
  return (
    <Select
      label="Adherence Status"
      value={hospiceStatus}
      onChange={(e) => {
        setHospiceStatus(e.target.value.toString() as IAllHospiceStatuses);
      }}
      sx={{ width: 100 }}
    >
      {allHospiceStatuses.map((status) => {
        return (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        );
      })}
    </Select>
  );
};

import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Text from '@intus-ui/components/Text';
import Icon from '@intus-ui/components/Icon';
import { input } from '@intus-ui/styles/SecondaryColors';
import PropTypes from 'prop-types';

const CustomInput = forwardRef(({ dateValue, onClick }, ref) => {
  // Format the date to a readable string
  const formattedDate = dateValue
    ? `${dateValue.toLocaleString('default', { month: 'short' })} ${dateValue.getFullYear()}`
    : '';

  return (
    <div
      style={styles.container}
      onClick={onClick}
      onKeyDown={onClick}
      ref={ref}
      tabIndex={-1}
      role="button"
    >
      <div style={styles.wrapper} tabIndex={0} role="button">
        <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
          <Icon name="calendar" color="#9D9D9D" hoverColor="#9D9D9D" />
          <Text color="body">{formattedDate}</Text>
        </div>
        <Icon name="CaretDown" color="#9D9D9D" hoverColor="#9D9D9D" />
      </div>
    </div>
  );
});

const MonthPicker = ({ onChange, dateValue, minDate, maxDate, onFocus }) => {
  const handleOnFocus = () => {
    if (onFocus) onFocus();
  };

  return (
    <div style={styles.datePickerContainer} onFocus={() => handleOnFocus()}>
      <DatePicker
        selected={dateValue}
        onChange={onChange}
        customInput={<CustomInput dateValue={dateValue} />}
        showMonthYearPicker
        minDate={minDate}
        maxDate={maxDate}
        popperPlacement="bottom-start"
      />
    </div>
  );
};

MonthPicker.propTypes = {
  onFocus: PropTypes.func,
};

const styles = {
  container: {
    height: '35px',
    width: '210px',
  },
  wrapper: {
    border: '1px solid',
    borderColor: input.collapsed,
    backgroundColor: 'white',
    padding: '2px 10px',
    width: '210px',
    height: '35px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
  },
  datePickerContainer: {
    position: 'relative',
    width: '210px',
  },
};

export default MonthPicker;

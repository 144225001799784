import { Link } from 'react-router-dom';

import { Card, Text } from '@intus-ui';
import { containerColors, textColors } from '@intus-ui/styles/SecondaryColors';

import { InitiativesListTrial } from 'Initiatives/InitiativesListView/InitiativesListTrial';

import './InitiativesListCard.scss';

export const InitiativesListCard = ({ initiative }) => {
  // We only show 1 trial on the index view and we only short the chart for first metric of that trial.
  const trial = initiative.trials[0];

  // We should always have a trial.
  // If we don't just bail out and display nothing for now.
  if (trial == null) {
    return null;
  }
  return (
    <Link
      className="initiativesListCard-link"
      style={styles.link}
      to={`/initiatives/${initiative.id}/trials/${trial.id}`}
    >
      <Card dropshadow fluid style={styles.card}>
        <div style={styles.panelContainer}>
          <div style={styles.leftPanel}>
            <div style={styles.nameAndDescription}>
              <Text color="navy" type="title">
                {initiative.name}
              </Text>
              <Text type="body">{initiative.description}</Text>
            </div>
          </div>
          <div style={styles.rightPanel}>
            <InitiativesListTrial trial={trial} />
          </div>
        </div>
      </Card>
    </Link>
  );
};

const styles = {
  link: {
    maxWidth: 1250,
  },
  card: {
    backgroundColor: containerColors.default,
    color: textColors.black,
    padding: '25px 30px 30px 30px',
  },
  panelContainer: {
    display: 'flex',
    gap: 20,
    alignItems: 'stretch',
  },
  leftPanel: {
    flex: '1 1 30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 20,
  },
  nameAndDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  rightPanel: {
    flex: '1 1 100%',
  },
};

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getLogger } from '@util/logger';
import { toUTCtoLocal } from '@util/dateFunctions';
import { getSessionUser, setSessionUser } from '@util/session';

import {
  getOrgs,
  getUsersByOrg,
  updateOrganizationAPI,
  updateUserAPI,
  postOrganizationAPI,
  postUserAPI,
  postResetPassword,
  getRecentOrgStatus,
  getAllTrackingEvents,
  getAllTrackingSessions,
  getTrackingEventsNoOrg,
} from '@api/api';

const log = getLogger('AdminSlice');

const organizationsSlice = createSlice({
  name: 'organizationsSlice',
  initialState: {
    profile: {},
    trackingOrg: undefined,
    orgs: undefined,
    admins: undefined,
    currOrgId: undefined,
    recentChangeDates: undefined,
    trackingEvents: undefined,
    trackingSessions: undefined,
    trackingUser: undefined,
  },
  reducers: {
    setProfile(state, action) {
      state.profile = {
        ...(state.profile || {}),
        ...action.payload,
      };
    },
    setOrgs(state, action) {
      state.orgs = action.payload;
    },
    setTrackingOrg(state, action) {
      state.trackingOrg = action.payload;
    },
    setAdmins(state, action) {
      state.admins = action.payload;
    },
    setCurrOrgId(state, action) {
      state.currOrgId = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setRecentChangeDates(state, action) {
      state.recentChangeDates = action.payload;
    },
    setTrackingEvents(state, action) {
      state.trackingEvents = action.payload;
    },
    setAllTrackingEvents(state, action) {
      state.allTrackingEvents = action.payload;
    },
    setTrackingSessions(state, action) {
      state.trackingSessions = action.payload;
    },
    setTrackingUser(state, action) {
      state.trackingUser = action.payload;
    },
  },
});

export default organizationsSlice.reducer;

export const {
  setProfile,
  setOrgs,
  setAdmins,
  setCurrOrgId,
  setError,
  setRecentChangeDates,
  setTrackingEvents,
  setAllTrackingEvents,
  setTrackingSessions,
  setTrackingUser,
  setTrackingOrg,
} = organizationsSlice.actions;

export const getUserInfo = () => async (dispatch) => {
  const user = getSessionUser();
  dispatch(setProfile(user));
};

export const getOrganizations = () => async (dispatch) => {
  const orgs = await getOrgs();
  dispatch(setOrgs(orgs));
};

export const getAdmins = (id) => async (dispatch) => {
  const users = await getUsersByOrg(id);
  const admins = users.filter((user) => user.access === 'ADMIN');
  dispatch(setAdmins(admins));
};

export const resetAdminPassword = (body) => async (dispatch) => {
  const errorMessage = 'Error reseting password';
  try {
    const res = await postResetPassword(body);
    if (!res.ok) {
      const json = await res.json();
      dispatch(setError(errorMessage));
      log.error(errorMessage, json);
    }
  } catch (err) {
    dispatch(setError(errorMessage));
    log.error(errorMessage, err);
  }
};

export const updateOrg = (body, id) => async (dispatch) => {
  const errorMessage = 'Unsuccessful updating organization';
  try {
    const response = await updateOrganizationAPI(body, id);
    if (response.ok) {
      const resJ = await response.json();
      const user = { ...getSessionUser() };
      user.organization.subscriptions = resJ.subscriptions;
      setSessionUser(user);
      const orgs = await getOrgs();
      dispatch(setOrgs(orgs));
    } else {
      dispatch(setError(errorMessage));
      log.error(errorMessage);
    }
  } catch (err) {
    dispatch(setError(errorMessage));
    log.error(errorMessage, err);
  }
};

export const updateUser = (body, id, orgId) => async (dispatch) => {
  const errorMessage = 'Unsuccessful updating admin';
  try {
    const response = await updateUserAPI(body, id);
    if (response.ok) {
      const users = await getUsersByOrg(orgId);
      const admins = users.filter((user) => user.access === 'ADMIN');
      dispatch(setAdmins(admins));
    } else {
      dispatch(setError(errorMessage));
      log.error(errorMessage);
    }
  } catch (err) {
    dispatch(setError(errorMessage));
    log.error(errorMessage, err);
  }
};

export const addOrg = (newOrg) => async (dispatch) => {
  const errorMessage = 'Error adding new organization';
  try {
    const response = await postOrganizationAPI(newOrg);
    if (response.ok) {
      const orgs = await getOrgs();
      dispatch(setOrgs(orgs));
    } else {
      dispatch(setError(errorMessage));
      log.error(errorMessage);
    }
  } catch (err) {
    dispatch(setError(errorMessage));
    log.error(errorMessage, err);
  }
};

export const getRecentUpdate = (orgId) => async (dispatch) => {
  try {
    const res = await getRecentOrgStatus(orgId);
    if (res.ok) {
      const resJ = await res.json();
      dispatch(setRecentChangeDates(resJ));
    } else {
      log.error('error fetching recent dates');
    }
  } catch (error) {
    log.error(error);
  }
};

export const addAdmin = (newUser) => async (dispatch) => {
  const errorMessage = 'Error adding new admin';
  try {
    const res = await postUserAPI(newUser);
    if (res.ok) {
      const users = await getUsersByOrg(newUser.organizationId);
      const admins = users.filter((user) => user.access === 'ADMIN');
      dispatch(setAdmins(admins));
      const { id } = await res.json();
      const body = { id };
      dispatch(resetAdminPassword(body));
    } else {
      dispatch(setError(errorMessage));
      log.error(errorMessage);
    }
  } catch (err) {
    dispatch(setError(errorMessage));
    log.error(errorMessage, err);
  }
};

export const getTrackingEvents = (orgID, userId) => async (dispatch) => {
  try {
    const res = await getAllTrackingEvents(orgID, userId);
    const json = await res.json();

    json.forEach((item) => {
      if (item.dashIndicators) {
        item.dashIndicators = item.dashIndicators.join(',\n');
      }
    });

    dispatch(
      setTrackingEvents(
        json.map((data) => ({
          id: data.id,
          time: toUTCtoLocal(data.time, 'YYYY-MM-DD HH:mm:ss'),
          page: data.page,
          action: data.action,
          dashIndicators: data.dashIndicators,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          trackingSessionId: data.trackingSessionId,
        }))
      )
    );
  } catch (error) {
    log.error(error);
  }
};

export const getTrackingEventsAll = () => async (dispatch) => {
  try {
    const res = await getTrackingEventsNoOrg();
    const json = await res.json();

    // Ternary operator is used for page field in order to remove the patientId values from drop down list
    dispatch(
      setAllTrackingEvents(
        json.map((data) => ({
          id: data.id,
          time: toUTCtoLocal(data.time, 'YYYY-MM-DD HH:mm:ss'),
          page: data.page.includes('Patient') ? 'Patient Details' : data.page,
          action: data.action,
          dashIndicators: data.dashIndicators,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          trackingSessionId: data.trackingSessionId,
        }))
      )
    );
  } catch (error) {
    log.error(error);
  }
};

export const getTrackingSession = () => async (dispatch) => {
  try {
    const res = await getAllTrackingSessions();
    if (res.ok) {
      const result = await res.json();
      /* eslint-disable no-param-reassign */
      result.forEach((session) => {
        if (session.eventCount > -1 && session.eventCount < 4) {
          session.eventActivity = '0-3';
        } else if (session.eventCount > 3 && session.eventCount < 8) {
          session.eventActivity = '4-7';
        } else {
          session.eventActivity = '8+';
        }
      });
      const sessions = result
        .map((session) => ({
          id: session.id,
          orgName: session.orgName,
          userFirstName: session.firstName,
          userLastName: session.lastName,
          access: session.access,
          orgId: session.organizationId,
          userId: session.userId,
          time: session.time,
          eventCount: session.eventCount,
          eventActivity: session.eventActivity,
        }))
        .filter((session) => session.access !== 'GOD');
      dispatch(setTrackingSessions(sessions));
    }
  } catch (error) {
    log.error(error);
  }
};

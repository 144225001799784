import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { postPatientListAPI } from '@api';

import { ParticipantList, RiskBadge } from '@intus-ui/components';
import { highestRiskPptsConfig } from '@intus-ui/components/ParticipantList/list/columnConfigs';
import { customFormat } from './customFormat';

const RiskAcuityParticipantList = ({ data }) => {
  const history = useHistory();
  const [participantList, setParticipantList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pptIds = data.map((ppt) => ppt.id);
    postPatientListAPI({ patientList: pptIds }).then(async (result) => {
      if (result.ok) {
        const json = await result.json();
        const participants = json.map((patient) => {
          return {
            ...patient,
            id: patient.id,
            name: `${patient.firstName} ${patient.lastName}`,
            memberId: patient.memberId,
            acuityScore: patient.acuityLevels[0].acuityScore || 0,
            /* eslint-disable  */
            riskBadge: <RiskBadge riskGroup={patient.acuityLevels[0].riskGroup} />,
            team: `${patient.team || ''}`,
            facility: `${patient.facility || ''}`,
          };
        });
        setParticipantList(participants);
        setLoading(false);
      }
    });
  }, [data]);

  return (
    <ParticipantList
      searchable={false}
      participantList={participantList}
      customColumnConfig={highestRiskPptsConfig}
      onClickItem={(ppt) => history.push(`/patient/${ppt.id}/current-acuity`)}
      customFormat={customFormat()}
      loading={loading}
    />
  );
};

export default RiskAcuityParticipantList;

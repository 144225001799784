import InfoIcon from '@mui/icons-material/InfoOutlined';

import { Icon, Text, TooltipComponent } from '@intus-ui';
import { tagColors } from '@intus-ui/styles/SecondaryColors';
import { formatDate, isAfterDate, newDate } from '@util/dateFunctions';
import { getCohortLineChartColorValue } from 'Initiatives/MetricChart/cohortLineChartColors';

export const MetricsCurrentSummary = ({ metric }) => {
  if (!metric) {
    return null;
  }

  const color = getTextColor(metric);

  const summary = metric.currentSummary;

  if (metric.cohorts.every((c) => isAfterDate(c.startDate, newDate()))) {
    const noDataText = `No initiative data available yet. Initiative has not started for ${
      metric.cohorts.length > 1 ? 'these cohorts' : 'this cohort'
    }.`;

    return (
      <div style={styles.metricSummaryContainer}>
        <Text type="body">{noDataText}</Text>
      </div>
    );
  }

  if (!summary.showPercentageIncrease && !summary.showCurrentEventCount) {
    return (
      <div style={styles.metricSummaryContainer}>
        <Text type="body">Not enough data available to calculate percent change</Text>
      </div>
    );
  }

  const { percentageChange, changeText } = getChangeInfo(metric);

  return (
    <div style={styles.metricSummaryContainer}>
      {summary.showCurrentEventCount && (
        <div style={styles.widget}>
          <Text type="display" style={{ color }}>
            {summary.eventCount}
          </Text>
          <Text type="body">
            {metric.strategyNameLower} so far in {formatDate(newDate(), 'MMMM')}
          </Text>
        </div>
      )}
      {summary.showPercentageIncrease && (
        <div style={styles.widget}>
          <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
            {percentageChange !== 0 && (
              <Icon
                name={percentageChange >= 0 ? 'arrow-up' : 'arrow-down'}
                size="extra-large"
                style={{ color }}
              />
            )}
            <Text type="display" style={{ color }}>
              {Math.abs(percentageChange)}%
            </Text>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Text style={{ display: 'inline' }}>{changeText}</Text>
            <div style={{ display: 'inline' }}>
              <TooltipComponent
                anchorId="changeReasonIcon"
                style={{ maxWidth: 280 }}
                divStyles={{ display: 'inline' }}
                content={
                  <div style={{ textAlign: 'left' }}>
                    {summary.isFirstDataPointNonZero === false && (
                      <div style={{ marginBottom: 12 }}>
                        {formatDate(summary.percentageChangeStartDate, "MMM 'YY")} is the first
                        month within the initiative with a non-zero {metric.tooltipStrategyName}{' '}
                        count.
                      </div>
                    )}
                    <div>
                      {formatDate(summary.percentageChangeEndDate, "MMM 'YY")} is the most recent
                      month with a complete {metric.tooltipStrategyName} count
                    </div>
                  </div>
                }
              >
                <InfoIcon
                  variant="success"
                  className="info-icon cursor-pointer"
                  style={{ marginTop: -3 }}
                />
              </TooltipComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function getChangeInfo(metric) {
  const summary = metric.currentSummary;

  const percentageChange = summary.percentageChange ?? 0;

  const changeDirection = percentageChange >= 0 ? 'increase' : 'decrease';

  const dateText = `between ${formatDate(
    summary.percentageChangeStartDate,
    "MMM 'YY"
  )} and ${formatDate(summary.percentageChangeEndDate, "MMM 'YY")}.`;

  let changeText = '';

  if (percentageChange === 0) {
    changeText = `change during participation in initiative ${dateText}`;
  } else if (metric.cohorts.length > 1) {
    changeText = `total ${changeDirection} for all cohorts participating in initiative ${dateText}`;
  } else {
    changeText = `${changeDirection} during participation in initiative ${dateText}`;
  }

  return {
    percentageChange,
    changeDirection,
    changeText,
  };
}

const styles = {
  metricSummaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 20,
    alignItems: 'stretch',
    justifyContent: 'center',
    // Keep this roughly centered with the chart.
    marginTop: -80,
    marginLeft: 40,
  },
  widget: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    textAlign: 'center',
  },
  numberStyles: {
    color: tagColors.teal,
  },
};

function getTextColor(metric) {
  if (metric.cohorts.length > 1) {
    return '#9D9D9D';
  }
  return getCohortLineChartColorValue(metric.cohorts[0].color);
}

import { input } from '@intus-ui/styles/SecondaryColors';
import { createTheme } from '@mui/material/styles';

// Global styles that override material-ui defaults.
// See: https://mui.com/material-ui/customization/theme-components/
export const intusTheme = createTheme({
  // Use 1px for spacing so things like `sx={{ padding: 1 }}` just use pixels.
  spacing: 1,

  components: {
    MuiButton: {
      defaultProps: {
        // Disable the ripple effect by default on the buttons.
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:focus': {
            // Disable the normal focus outline when clicking.
            outline: 'none',
          },
          '&:focus-visible': {
            // Use the default material-ui focus styling but only for tabbing.
            outline: ['1px dotted', '5px auto -webkit-focus-ring-color'],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:not(.MuiAutocomplete-inputRoot)': {
            // The :not is here to avoid breaking the material-ui Autocomplete component.
            '.MuiOutlinedInput-input': {
              border: '1px solid rgb(157, 157, 157)',
              borderRadius: '5px',
              paddingTop: 6,
              paddingBottom: 6,

              '&:hover': {
                backgroundColor: input.hover,
              },
            },
            '&.Mui-focused': {
              '.MuiOutlinedInput-input': {
                border: `1px solid ${input.active}`,
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // Styles for the dropdown menu.
          '&.MuiPopover-paper': {
            border: '1px solid #C8C8C8',
            boxShadow: '2px 2px 16px rgba(157, 157, 157, 0.16)',
            borderRadius: 0,

            '& .MuiMenu-list': {
              padding: 0,
            },

            // Styles for each item in the dropdown menu.
            '& .MuiMenuItem-root': {
              padding: '6px 18px',
              whiteSpace: 'normal',

              // Disable the blue color when the item is selected.
              '&.Mui-selected': {
                backgroundColor: 'transparent',
                '&:hover, &:focus-visible': {
                  backgroundColor: input.hover,
                },
              },
              '&:hover, &:focus-visible': {
                backgroundColor: input.hover,
              },
            },
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: '#222',
          background: '#ffffff',
          maxWidth: '400px',
          borderRadius: '5px',
          padding: '15px',
          zIndex: '100000',
          filter: 'drop-shadow(2px 2px 16px rgba(157, 157, 157, 0.22))',
          transition: 'none',
          opacity: '100%',
          fontSize: 12,
          fontFamily: 'Inter',
        },
        arrow: {
          color: '#ffffff',
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          // Reduce the padding
          padding: '0px 5px 0px 7px',
          // Shrink the radio button SVG
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
          },
        },
      },
    },
  },
});

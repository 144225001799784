import React, { ButtonHTMLAttributes, FC, ReactElement, useState } from 'react';

import Badge from '../Badge';
import Icon from '../Icon';
import Spinner from '../Spinner';

import './Button.scss';

export type ButtonProps = {
  hoverText?: string;
  secondary?: boolean;
  busy?: boolean;
  compact?: boolean;
  fluid?: boolean;
  tabbed?: boolean;
  active?: boolean;
  textOnly?: boolean;
  badge?: string;
  badgeBgColor?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

// New general-purpose Button component.
// Text label is handled through the `name` prop.
// Icons are handled by including an Icon component as a child.
// Basically equivalent to the old GeneralButton and TextButton components.
// (TextButton uses the textOnly prop)
// - tabbed (takes an additional "active" prop, used to indicate currently selected tab)
// Other legacy *Button components that are not (yet?) covered by this component include:
// - RoundButton (used only in feedback form, structure/style is a fair bit different)
// - ToggleButton (previous implementation is unused, app uses react-boostrap's ToggleButton + ToggleButtonGroup)
// -- ToggleButtonGroup necessitates some additional logic that'll need to be ported over
const Button: FC<ButtonProps> = ({
  name,
  hoverText,
  onClick,
  busy,
  children,
  secondary = false,
  compact = false,
  fluid,
  tabbed = false,
  active = false,
  disabled = false,
  textOnly = false,
  className: extraClassName = '',
  style,
  badge,
  badgeBgColor,
  ...rest
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const onClickExt = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsPressed(!isPressed);
    if (onClick && !busy) onClick(event);
    if (busy === true) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  let className = 'default-button';
  if (compact) className += ` compact`;
  if (fluid) className += ` fluid`;

  if (!tabbed && !textOnly) {
    className += ` ${secondary ? 'secondary-button' : 'primary-button'}`;
  }
  if (tabbed) {
    className += ` tabButton${active ? ' active' : ''}`;
  }
  if (textOnly) {
    className += ` ${secondary ? 'textButton-secondary' : 'textButton'}`;
  }

  if (React.Children.count(children) > 0) {
    const childTypes = React.Children.map(children, (child) => (child as ReactElement)?.type);
    if (childTypes?.includes(Icon)) className += ' withIcon';
  }

  return (
    <button
      type="button"
      aria-pressed={isPressed}
      title={hoverText}
      aria-label={hoverText}
      name={name}
      disabled={disabled}
      className={`${className} ${extraClassName}`}
      onClick={onClickExt}
      style={{ ...style, position: 'relative' }}
      {...rest}
    >
      {busy ? (
        <Spinner />
      ) : (
        <>
          {children}
          {name ? <span>{name}</span> : null}
          {badge && (
            <Badge
              text={badge}
              bgColor={badgeBgColor}
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
              }}
            />
          )}
        </>
      )}
    </button>
  );
};

export default Button;

import { validateSession, getSessionToken } from "@util/session";
import { ChatAppRequest } from "./models";


const BACKEND_URI = process.env.REACT_APP_AI_API_URL

const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
  };

const getAuthHeaders = () => {
    validateSession();
    return {
      'x-auth-token': `Bearer ${getSessionToken()}`,
      ...DEFAULT_HEADERS,
    };
  };

export async function ragChatApi(request: ChatAppRequest): Promise<Response> {
    // eslint-disable-next-line no-return-await
    return await fetch(`${BACKEND_URI}/ragchat`, {
        method: "POST",
        headers: getAuthHeaders(),
        body: JSON.stringify(request)
    });
}

export async function sourcelessChatApi(request: ChatAppRequest): Promise<Response> {
  // eslint-disable-next-line no-return-await
  return await fetch(`${BACKEND_URI}/chatwithoutsources`, {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify(request)
  });
}


export function getCitationFilePath(citation: string): string {
  validateSession();
  const token = getSessionToken()
    return `${BACKEND_URI}/content/${token}/${citation}`;
}

import * as FS from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import SentryFullStory from '@sentry/fullstory';

export const init = () => {
  FS.init({ orgId: '14Z0D7' });
  Sentry.init({
    dsn: 'https://1b6f0bb7fba339630b62e6950b77b3db@o1342291.ingest.us.sentry.io/6619623',
    integrations: [new SentryFullStory('intuscare', { client: FS })],
  });
};

export const identifyUser = (payload) => {
  FS.identify(payload.email, payload);
};

export const logEvent = (eventType, meta) => {
  const fullstoryIsActive = isUsingFullstory();
  if (fullstoryIsActive) {
    FS.event(eventType, meta);
  }
};

export const isUsingFullstory = () => {
  if (process.env.NODE_ENV === 'production') {
    return true;
  }
  return false;
};
// A method to set custom user properties in fullstory
// arguments: fieldValuePair is a JSON object of key/value pairs of field name/value you want to set
// ex. {fieldName: value}
export const setUserVars = (obj) => {
  FS.setUserVars(obj);
};

import { useEffect } from 'react';
import { useLazyQuery } from '@api/useQuery';
import { getPolypharmacySummary } from '@api';
import { SpinnerOrError } from '@intus-ui';
import { getAllVariantsForColors } from '@intus-ui/styles/colors';
import { PieChart } from './components';

import styles from './styles';

const Summary = ({ filters }) => {
  const { data, error, loading, runQuery } = useLazyQuery(() => getPolypharmacySummary(filters));

  useEffect(() => {
    runQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  if (loading || error || !data)
    return <SpinnerOrError loading={loading} error={error && 'An error occured'} />;

  const chartContainer = {
    display: 'flex',
    gap: 30,
    margin: 10,
    justifyContent: 'center',
    width: '100%',
  };

  return (
    <div style={{ ...styles.grid, padding: 10 }}>
      <div style={chartContainer}>
        <PieChart
          title="Top 10 Filled Meds By Volume"
          data={data?.top10FilledMedsByVolume}
          colors={getAllVariantsForColors(['blue','orange'])}
        />
        <PieChart
          title="Polypharmacy by Age Breakdown"
          data={data?.tenPlusMedsByAge}
          colors={getAllVariantsForColors(['purple'])}
        />
      </div>
      <div style={chartContainer}>
        <PieChart
          title="Polypharmacy by Gender"
          data={data?.tenPlusMedsByGender}
          colors={getAllVariantsForColors(['green'])}
        />
        <PieChart
          title="Unique Med Count by Pt Breakdown"
          data={data?.uniqueMedCount}
          colors={getAllVariantsForColors(['pink'])}
        />
      </div>
    </div>
  );
};

export default Summary;

import { useHistory } from 'react-router-dom';

import Button from '@intus-ui/components/Buttons/Button';
import Icon from '@intus-ui/components/Icon';

export const BackButton = ({ onClick, style, secondary = true }) => {
  const history = useHistory();
  return (
    <Button
      secondary={secondary}
      onClick={() => {
        if (onClick != null) {
          onClick();
        } else {
          history.go(-1);
        }
      }}
      style={style}
    >
      <Icon name="caret-left" />
      Back
    </Button>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormikFormControl from '../../@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';
import { addAdmin } from '../UserTracking/AdminSlice';
import SubmitButton from '../../@intus-ui/components/SubmitButton';

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Email must be valid').required('Required'),
  phoneNumber: Yup.number()
    .default(1)
    .typeError('Must be a phone number')
    .test(
      'len',
      'Phone numbers must be either 10 or 11 characters long',
      (val) =>
        val &&
        (val.toString().length === 10 ||
          val.toString().length === 11 ||
          val.toString().length === 1)
    ),
});

const AddAdminModal = ({ show, hide, doAddAdmin, orgId }) => {
  const hideModal = () => hide();

  const tempPassword =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  return (
    <Modal show={show} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center font-weight-bold">New Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            access: 'ADMIN',
            password: tempPassword,
            organizationId: orgId,
          }}
          onSubmit={(values) => {
            doAddAdmin(values);
            hideModal();
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, errors, touched, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <FormikFormControl
                  as={Col}
                  md="6"
                  label="First Name"
                  type="text"
                  name="firstName"
                />
                <FormikFormControl as={Col} md="6" label="Last Name" type="text" name="lastName" />
              </Form.Row>
              <Form.Row>
                <FormikFormControl as={Col} md="12" label="Email" type="text" name="email" />
              </Form.Row>
              <Form.Row>
                <FormikFormControl
                  as={Col}
                  md="12"
                  label="Phone Number"
                  type="tel"
                  name="phoneNumber"
                />
              </Form.Row>
              <Form.Row>
                <Col>
                  <SubmitButton
                    text="Create Admin"
                    isSubmitting={isSubmitting}
                    formNotComplete={
                      Object.values(errors).length > 0 || Object.values(touched).length === 0
                    }
                  />
                </Col>
              </Form.Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

AddAdminModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  doAddAdmin: PropTypes.func.isRequired,
  orgId: PropTypes.number,
};

AddAdminModal.defaultProps = {
  orgId: -1,
};

const mapState = (state) => ({
  orgId: state.admin.currOrgId,
});

const mapDispatch = (dispatch) => ({
  doAddAdmin: (admin) => dispatch(addAdmin(admin)),
});

export default connect(mapState, mapDispatch)(AddAdminModal);

import { difference } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';

import { getSessionUser } from '@util/session';
import { removeReactElementsFromObj } from '@util/utilFunctions';
import { SpinnerOrError, Text } from '@intus-ui';

import { FixedSizeList } from 'react-window';
import { clsx } from 'clsx';
import ListHeader from './ListHeader';
import ListItem from './ListItem';

import { filterData, sortData } from './helpers';
import { ListProvider, useListContext } from './Context';

const ListContents = ({
  // Check PropTypes below for deeper understanding + defaults of these props
  onClickItem, // function
  searchable, // boolean
  defaultSort, // object
  emptyListMessage, // string
  showColumnHeaders, // boolean
  topRightElements, // jsx element
  titleRowElement, // jsx element
  data, // required
  format, // required
  columnConfig, // required
  defaultFilter,
  loading, // optional boolean
  sortedListPassthrough, // function, typically a useState()'s setter from a parent component
  listStyle, // optional styling
  className,
  customListItem,
  extraRenders, // optional beforeRow beforeHeader afterRow afterHeader renders
  lowlightItems, // optional function that lowlights items that matches a certain criteria
  virtualizedOptions,
  noPadding, // true to remove the padding around the list
  condensed, // true to remove padding and gap between list items
  customStyle,
  clickable,
  customSearchField,
}) => {
  const { searchTerm, sorting, setSorting, filters, columns, replaceColumns } = useListContext();
  const listRef = useRef();
  const listItemsRef = useRef();
  const isStripedList = listStyle === 'striped';

  const [filteredSortedData, setFilteredSortedData] = useState(data);

  const handleResize = () => {
    const newSize = { width: listRef.current.clientWidth, height: listRef.current.clientHeight };
    const newConfig = columnConfig.breakpoints.find((config) => newSize.width >= config.minWidth);
    if (newConfig) replaceColumns(newConfig.columns);
  };

  // setting the columns context variable to the columns from the columnConfig
  useEffect(() => {
    setSorting(defaultSort);

    // CHECK SESSION USER FOR LIST OVERRIDES
    const { overrides } = getSessionUser() || {};
    const listOverrides = overrides?.listColumns?.omitFields || [];

    let { columns } = columnConfig;

    // CHECK FOR BREAKPOINTS
    if (columnConfig.breakpoints != null) {
      columnConfig.breakpoints.sort((a, b) => b.minWidth - a.minWidth);
      const newSize = { width: listRef.current.clientWidth, height: listRef.current.clientHeight };
      const newConfig = columnConfig.breakpoints.find((config) => newSize.width >= config.minWidth);
      if (newConfig) columns = newConfig.columns;
    }

    replaceColumns(difference(columns, listOverrides));
  }, [columnConfig]);

  // Getting the width of the container on resize, and updating local state
  useEffect(() => {
    // only add event listener if there are breakpoints
    if (columnConfig.breakpoints != null) {
      window.addEventListener('resize', handleResize);
    }
    return function cleanupListener() {
      window.removeEventListener('resize', handleResize);
    };
  }, [columnConfig]);

  useEffect(() => {
    if (
      defaultFilter &&
      filters?.find((filter) => filter?.type === defaultFilter?.type) === undefined
    )
      filters.push(defaultFilter);
    const filteredData = filterData({
      data,
      searchTerm,
      searchField: customSearchField || 'name',
      filters,
    }); // filter by search term and filters
    const sortedData = sortData(filteredData, sorting, format); // sorting by the field and direction given by sorting state, setting context variable
    setFilteredSortedData(sortedData);
    // to be used by a parent component for sharing state
    if (sortedListPassthrough) {
      sortedListPassthrough(sortedData);
    }
  }, [searchTerm, sorting, filters, data]);

  // only using fields from the format that match the columns provided
  const formattedByColumns = format.filter((formatItem) => {
    return columns.includes(formatItem.field);
  });

  function renderListItem(index, style) {
    const item = filteredSortedData[index];

    const key = `${JSON.stringify(removeReactElementsFromObj(item))}-${index}`;

    if (customListItem) {
      const CustomListItem = customListItem;
      return (
        <CustomListItem
          item={item}
          onClick={() => onClickItem(item)}
          key={key}
          extraRenders={extraRenders}
          style={style}
        />
      );
    }

    return (
      <ListItem
        key={key}
        itemIndex={index}
        item={item}
        columns={columns}
        format={format}
        onClickItem={onClickItem}
        listStyle={listStyle}
        extraRenders={extraRenders}
        islowlightItem={lowlightItems && lowlightItems(item)}
        style={style}
        customStyle={customStyle}
        clickable={clickable}
      />
    );
  }

  function renderList() {
    // If using a virtualized list,
    if (virtualizedOptions != null) {
      if (virtualizedOptions.height == null) {
        throw new Error('virtualizedOptions.height is required when using a virtualized list');
      }
      if (virtualizedOptions.itemSize == null) {
        throw new Error('virtualizedOptions.itemSize is required when using a virtualized list.');
      }
      return (
        <FixedSizeList
          height={virtualizedOptions.height}
          itemCount={filteredSortedData.length}
          itemSize={virtualizedOptions.itemSize}
          width="100%"
        >
          {({ index, style }) => {
            return renderListItem(index, style);
          }}
        </FixedSizeList>
      );
    }

    return filteredSortedData.map((_item, index) => {
      return renderListItem(index);
    });
  }

  const headerStyles = {
    ...styles.headerContainer,
  };

  const listStyles = {
    ...styles.list,
  };

  if (noPadding) {
    headerStyles.padding = '20px 0px 0px 0px';
    listStyles.padding = '0';
  }

  if (condensed) {
    headerStyles.padding = '0px';
    listStyles.padding = '10px 0px 0px 0px';
  }

  let listItemGap = isStripedList ? 0 : 10;
  if (condensed) listItemGap = 5;

  let listItemPaddingTop = isStripedList ? 0 : 20;
  if (condensed) listItemPaddingTop = 10;

  let listItemPaddingRight = isStripedList ? 0 : 20;
  if (condensed) listItemPaddingRight = 0;

  return (
    <div className={clsx('list-component', className)} style={styles.container} ref={listRef}>
      <div style={headerStyles}>
        <ListHeader
          searchable={searchable}
          format={formattedByColumns}
          topRightElements={topRightElements}
          titleRowElement={titleRowElement}
          showColumnHeaders={showColumnHeaders}
          extraRenders={extraRenders}
          columns={columns}
          isStripedList={isStripedList}
          condensed={condensed}
        />
      </div>

      {loading ? (
        <div style={styles.loading}>
          <SpinnerOrError error="" />
        </div>
      ) : (
        <div
          style={{
            ...listStyles,
            gap: listItemGap,
            paddingTop: listItemPaddingTop,
            paddingRight: listItemPaddingRight,
          }}
          ref={listItemsRef}
        >
          {filteredSortedData != null && filteredSortedData.length ? (
            renderList()
          ) : (
            <Text style={{ paddingTop: '10px' }}>{emptyListMessage}</Text>
          )}
        </div>
      )}
    </div>
  );
};

const List = (props) => {
  const {
    data,
    searchable,
    format,
    onClickItem,
    columnConfig,
    topRightElements,
    defaultSort,
    titleRowElement,
    emptyListMessage,
    showColumnHeaders,
    defaultFilter,
    loading,
    sortedListPassthrough,
    className,
    listStyle,
    customListItem,
    extraRenders,
    lowlightItems,
    // If set, makes the list virtualized so it only renders what is displayed on the screen.
    // These are options for react-window's FixedSizeList component. See https://react-window.now.sh/#/api/FixedSizeList
    // Height and itemSize are required. itemSize is the height of each row including padding/margin.
    virtualizedOptions,
    noPadding, // true to remove the padding around the list
    condensed, // true to remove padding and gap between list items
    clickable,
    customSearchField,
  } = props;

  return (
    <ListProvider>
      <ListContents
        data={data}
        loading={loading}
        onClickItem={onClickItem}
        searchable={searchable}
        format={format}
        columnConfig={columnConfig}
        topRightElements={topRightElements}
        defaultSort={defaultSort}
        titleRowElement={titleRowElement}
        emptyListMessage={emptyListMessage}
        showColumnHeaders={showColumnHeaders}
        defaultFilter={defaultFilter}
        sortedListPassthrough={sortedListPassthrough}
        className={className}
        listStyle={listStyle}
        customListItem={customListItem}
        extraRenders={extraRenders}
        lowlightItems={lowlightItems}
        virtualizedOptions={virtualizedOptions}
        noPadding={noPadding}
        condensed={condensed}
        clickable={clickable}
        customSearchField={customSearchField}
      />
    </ListProvider>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: '#FFFFFF',
    alignItems: 'start',
    maxWidth: '100%',
    overflowX: 'auto',
    height: '100%',
    // borderRadius: '10px',
  },
  headerContainer: {
    padding: '20px 20px 0px 20px',
    display: 'flex',
    width: '100%',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    padding: '15px 20px 20px 20px',
  },
  loading: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
  },
  emptyList: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickItem: PropTypes.func,
  searchable: PropTypes.bool,
  format: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      name: PropTypes.string,
      flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      align: PropTypes.string,
      addOns: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          element: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        })
      ),
    })
  ).isRequired,
  columnConfig: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    breakpoints: PropTypes.arrayOf(
      PropTypes.shape({
        minWidth: PropTypes.number,
        columns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      })
    ),
  }).isRequired,
  topRightElements: PropTypes.node,
  defaultSort: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.string.isRequired,
  }),
  emptyListMessage: PropTypes.string,
  showColumnHeaders: PropTypes.bool,
  sortedListPassthrough: PropTypes.func,
  listStyle: PropTypes.string,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  noPadding: PropTypes.bool,
  condensed: PropTypes.bool,
};

List.defaultProps = {
  onClickItem: () => {
    return null;
  },
  searchable: false,
  // eslint-disable-next-line react/jsx-no-useless-fragment
  topRightElements: undefined,
  defaultSort: { field: undefined, direction: 'none' },
  emptyListMessage: 'There are no items in this list',
  showColumnHeaders: true,
  sortedListPassthrough: () => {
    return null;
  },
};

export default List;

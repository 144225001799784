export const sampleRollingCohort = {
  id: 6,
  name: "Dementia/Alzheimer's Falls",
  description: '',
  essentialNeeds: [],
  searchCriteria: {
    groupsOperator: 'AND',
    groups: [
      {
        operator: 'OR',
        conditions: [
          {
            type: 'diagnosis',
            searchTerm: 'Dementia',
            logic: '=',
          },
          {
            type: 'diagnosis',
            searchTerm: "Alzheimer's Disease",
            logic: '=',
          },
        ],
      },
      {
        operator: 'OR',
        conditions: [
          {
            type: 'incidents',
            searchTerm: 'Falls',
            logic: '=',
            frequency: {
              gte: 1,
            },
            dateRange: {
              weeks: 0,
              days: 30,
            },
          },
        ],
      },
    ],
  },
  shared: false,
  count: 6,
  patientIds: [],
  addedPatientIds: [10, 11],
  removedPatientIds: [],
  isShared: false,
};

export const sampleFixedCohort = {
  id: 6,
  name: 'Night light program for frequent fallers',
  description: 'Watchlist for participants who have fallen at least 1 time during night time.',
  essentialNeeds: [],
  searchCriteria: {},
  shared: false,
  count: 6,
  patientIds: [],
  addedPatientIds: [10, 11],
  removedPatientIds: [],
  isShared: false,
};

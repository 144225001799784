import { useHover } from '@intus-ui/hooks';
import { initiativeChartColors, tagColors } from '@intus-ui/styles/SecondaryColors';
import { Text } from '@intus-ui';
import React, { FC, ReactNode } from 'react';
import { TextProps } from '@intus-ui/components/Text';
import { Box, SxProps } from '@mui/material';

export type TagProps = {
  id?: string;
  title?: string;
  color: keyof typeof initiativeChartColors;
  hoverColor?: keyof typeof initiativeChartColors;
  type?: 'intusMade';
  textProps?: Partial<TextProps<'span'>>;
  children: ReactNode;
  style?: SxProps;
  ellipsis?: boolean;
};

// A tag component that renders a tag with the children as the text.
// Props allow you to specify color and type.
// Color options for tags: blue, orange, purple, green, pink, maroon, teal
// Type options for tags: UserMade, IntusMade
const Tag: FC<TagProps> = ({
  id,
  title,
  color,
  hoverColor,
  type,
  textProps,
  children,
  style,
  ellipsis,
}) => {
  const [hoverRef, isHovered] = useHover();

  let textType: TextProps<any>['type'];
  if (type === 'intusMade') {
    textType = 'caption-bold';
  } else {
    textType = 'caption';
  }
  return (
    <Box
      component="div"
      id={id}
      ref={hoverRef}
      title={title}
      sx={{
        ...styles.wrapper,
        ...style,
        backgroundColor:
          hoverColor && isHovered ? tagColors[`${hoverColor}40`] : tagColors[`${color}40`],
        border: `1px solid ${tagColors[color]}`,
        textTransform: type === 'intusMade' ? 'uppercase' : undefined,
      }}
    >
      <Text id={id} type={textType} color="body" ellipsis={ellipsis} {...textProps}>
        {children}
      </Text>
    </Box>
  );
};

const styles = {
  wrapper: {
    padding: '1px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '3px',
    height: 20,
  },
} satisfies InlineCss;

export default Tag;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Col, FormCheck, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EHR_COGNIFY, EHR_TRUCHART, EHR_RTZ } from 'Settings/userSettingsPropType';
import FormikFormControl from '../../@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';
import { addOrg } from '../UserTracking/AdminSlice';
import SubmitButton from '../../@intus-ui/components/SubmitButton';

const schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  status: Yup.string().typeError('Required').required('Required'),
  careAdminContactNumber: Yup.number()
    .default(1)
    .typeError('Must be a phone number')
    .test(
      'len',
      'Phone numbers must be either 10 or 11 characters long',
      (val) =>
        val &&
        (val.toString().length === 10 ||
          val.toString().length === 11 ||
          val.toString().length === 1)
    ),
});

const AddOrganizationModal = ({ show, hide, doAddOrg }) => {
  const [acuityCheckbox, setAcuityCheckbox] = useState(false);
  const [dashboardCheckbox, setDashboardCheckbox] = useState(false);
  const [subscriptionsArray, setSubscriptionsArray] = useState([]);

  const acuityChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      const subs = subscriptionsArray;
      subs.push('ACUITY');
      setSubscriptionsArray(subs);
    } else {
      const index = subscriptionsArray.indexOf('ACUITY');
      const subs = subscriptionsArray;
      subs.splice(index, 1);
      setSubscriptionsArray(subs);
    }
    setAcuityCheckbox(checked);
  };
  const dashboardChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      const subs = subscriptionsArray;
      subs.push('DASHBOARD');
      setSubscriptionsArray(subs);
    } else {
      const index = subscriptionsArray.indexOf('DASHBOARD');
      const subs = subscriptionsArray;
      subs.splice(index, 1);
      setSubscriptionsArray(subs);
    }
    setDashboardCheckbox(checked);
  };

  const hideModal = () => {
    setSubscriptionsArray([]);
    setAcuityCheckbox(false);
    setDashboardCheckbox(false);
    hide();
  };
  return (
    <Modal show={show} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center font-weight-bold">New Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ subscriptions: [], ehr: EHR_COGNIFY }}
          onSubmit={(values) => {
            // eslint-disable-next-line no-param-reassign
            values.subscriptions = subscriptionsArray.concat(values.ehr);
            doAddOrg(values);
            hideModal();
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, errors, touched, isSubmitting, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <FormikFormControl
                  as={Col}
                  md="12"
                  label="Organization Name"
                  type="text"
                  name="name"
                />
              </Form.Row>
              <Form.Row>
                <FormikFormControl as={Col} md="12" label="Status" controlAs="select" name="status">
                  <option value="">Status</option>
                  <option value="INACTIVE">INACTIVE</option>
                  <option value="ACTIVE">ACTIVE</option>
                </FormikFormControl>
              </Form.Row>
              Subscriptions
              <Form.Row className="mb-2">
                <FormCheck
                  id="acuity-checkbox"
                  custom
                  label="ACUITY"
                  checked={acuityCheckbox}
                  onChange={acuityChange}
                  className="m-2"
                />
                <FormCheck
                  id="dashboard-checkbox"
                  custom
                  label="DASHBOARD"
                  checked={dashboardCheckbox}
                  onChange={dashboardChange}
                  className="m-2"
                />
              </Form.Row>
              EHR
              <Form.Row className="mb-2">
                <FormCheck
                  id="ehr-cognify"
                  custom
                  label="Cognify"
                  type="radio"
                  name="ehr-radio"
                  className="m-2"
                  checked={values.ehr === EHR_COGNIFY}
                  onChange={() => setFieldValue('ehr', EHR_COGNIFY)}
                />
                <FormCheck
                  id="ehr-truchart"
                  custom
                  label="TruChart"
                  type="radio"
                  name="ehr-radio"
                  className="m-2"
                  checked={values.ehr === EHR_TRUCHART}
                  onChange={() => setFieldValue('ehr', EHR_TRUCHART)}
                />
                <FormCheck
                  id="ehr-rtz"
                  custom
                  label="RTZ"
                  type="radio"
                  name="ehr-radio"
                  className="m-2"
                  checked={values.ehr === EHR_RTZ}
                  onChange={() => setFieldValue('ehr', EHR_RTZ)}
                />
              </Form.Row>
              <Form.Row>
                <FormikFormControl
                  as={Col}
                  md="12"
                  label="Care Admin Contact Number (optional)"
                  type="tel"
                  name="careAdminContactNumber"
                />
              </Form.Row>
              <Form.Row>
                <Col>
                  <SubmitButton
                    text="Create Organization"
                    isSubmitting={isSubmitting}
                    formNotComplete={
                      Object.values(errors).length > 0 || Object.values(touched).length === 0
                    }
                  />
                </Col>
              </Form.Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

AddOrganizationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  doAddOrg: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch) => ({
  doAddOrg: (body) => dispatch(addOrg(body)),
});

export default connect(null, mapDispatch)(AddOrganizationModal);

import { get } from 'lodash';

export const hospitalizationLabels = {
  ER: 'ER visit count',
  Inpatient: 'Inpatient count',
};

export const incidentLabels = {
  Falls: 'Fall Count',
  Burns: 'Burn Count',
  Infections: 'Infection Count',
  MedErrors: 'Medication Error Count',
  GeneralIncidents: 'General Incident Count',
};

const labels = {
  hospitalization: hospitalizationLabels,
  incident: incidentLabels,
};

/**
 * Returns the label for a given searchTerm
 * @param {String} type - the type of search term: 'hospitalization', 'incident'
 * @param {String} searchTerm - the search term (eg: 'ER', 'Falls')
 * @param {String} defaultValue - the default value to return if no label is found
 */
export const getLabel = (type, searchTerm, defaultValue) => {
  return get(labels, [type, searchTerm], defaultValue || searchTerm);
};

import { ControlledCheckboxGroup, DateRangeDropdown, Input, Text } from '@intus-ui';

import { useRiskTrendlineContext } from './Context';

const addOrRemoveFromList = (target, list) => {
  if (list.includes(target)) {
    // removing existing item
    return list.filter((item) => item !== target);
  }
  // add new item
  return [...list, target].sort();
};

export const EventFilter = ({ eventFilter }) => {
  const { setEventFilterValue } = useRiskTrendlineContext();

  if (!eventFilter) return null;

  const {
    key,
    title,
    values: { types, enabled },
  } = eventFilter;

  return (
    <Input value={title} rightIcon="caret-down" type="select" style={{ width: 150 }}>
      <div style={{ padding: 5 }}>
        {!types.length && (
          <Text type="caption">No {title} for this participant in the selected time range</Text>
        )}
        {types.length > 0 && (
          <ControlledCheckboxGroup
            className="form-check-fluid"
            alignRight
            checkboxes={types.map((x) => ({
              node: x,
              value: x,
            }))}
            checked={enabled}
            valueChanged={(item) => {
              const newEnabled = addOrRemoveFromList(item, enabled);
              setEventFilterValue(key, { types, enabled: newEnabled }, true);
            }}
            disabled={false}
          />
        )}
      </div>
    </Input>
  );
};

export const DateFilter = () => {
  const { dateFilter, setDateFilter } = useRiskTrendlineContext();

  return (
    <DateRangeDropdown
      range={[3, 6, 12, 'all']}
      value={dateFilter.label}
      onChange={(val) => {
        if (!val.isRange) setDateFilter(val);
      }}
      onApplyRange={(val) => {
        setDateFilter(val);
      }}
      dateRangeDirection="left"
    />
  );
};

export const PolypharmacyFilters = () => {
  const { polypharmacyFilters, setPolypharmacyFilterValue } = useRiskTrendlineContext();
  const fillsOptions = polypharmacyFilters.fills.options;

  return (
    <>
      <Input
        value={polypharmacyFilters.drugClass.selected}
        options={polypharmacyFilters.drugClass.options}
        rightIcon="caret-down"
        type="select"
        style={{ width: 250 }}
        onChange={(val) => {
          setPolypharmacyFilterValue('drugClass', val);
        }}
      />
      <Input
        value={fillsOptions.find((x) => x.value === polypharmacyFilters.fills.selected)?.label}
        options={fillsOptions}
        rightIcon="caret-down"
        type="select"
        style={{ width: 150 }}
        onChange={(val) => {
          setPolypharmacyFilterValue('fills', val.value);
        }}
      />
    </>
  );
};

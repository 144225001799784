import { Text, Icon, RadioButtonV2, Input } from '@intus-ui';
import { filterRowSchemas } from '../../filterRowConfig';

const DeleteFilterRowButton = ({ handleDeleteFilter }) => (
  <div style={styles.deleteFilterRowButton}>
    <Icon
      name="Remove"
      color="#9D9D9D"
      height="13px"
      width="13px"
      hoverColor="#052d8f"
      onClick={handleDeleteFilter}
    />
  </div>
);

const FilterBetweenRange = ({
  isSingleFilterRow,
  range,
  handleChangeFrequencyType,
  frequency,
  frequencyBetweenLTE,
  handleChangeFrequencyValue,
  filter,
  parentIndex,
  handleDeleteFilter,
  operators,
  operator,
  handleChangeOperator,
  isFirst,
  isSecond,
  type,
}) => {
  const { inputLabel } = filterRowSchemas(type)[type].components.FilterBetweenRange;

  return (
    <div style={styles.thirdFilterRow}>
      {!isSingleFilterRow && (
        <div id="secondFilterRowSpacer" style={styles.filterRowSpacer}>
          {isSecond && (
            <Input
              id="operator"
              name="operator"
              type="select"
              rightIcon="caret-down"
              options={operators.reverse()}
              value={operator}
              onChange={handleChangeOperator}
              style={{ width: 90 }}
            />
          )}
          {!isFirst && !isSecond && (
            <div style={{ paddingLeft: 5 }}>
              <Text type="body">{operator}</Text>
            </div>
          )}
        </div>
      )}
      <Text>is</Text>
      <RadioButtonV2
        option="At least"
        size="small"
        labelPosition="horizontal"
        selected={range}
        setSelected={handleChangeFrequencyType}
      />
      <RadioButtonV2
        option="At most"
        size="small"
        labelPosition="horizontal"
        selected={range}
        setSelected={handleChangeFrequencyType}
      />
      <RadioButtonV2
        option="Between"
        size="small"
        labelPosition="horizontal"
        selected={range}
        setSelected={handleChangeFrequencyType}
      />
      <input
        min="0"
        type="number"
        className="hover-input-light-blue"
        value={frequency.lte || frequency.gte}
        onChange={handleChangeFrequencyValue}
        style={{
          height: 35,
          width: 60,
          paddingLeft: 15,
          backgroundColor: 'white',
          border: '1px solid #9D9D9D',
          borderRadius: 5,
        }}
      />
      {range === 'Between' && (
        <>
          <Text>-</Text>
          <input
            min="0"
            type="number"
            className="hover-input-light-blue"
            value={frequencyBetweenLTE?.lte}
            onChange={(value) => handleChangeFrequencyValue(value, true)}
            style={{
              height: 35,
              width: 60,
              paddingLeft: 15,
              backgroundColor: 'white',
              border: '1px solid #9D9D9D',
              borderRadius: 5,
            }}
          />
        </>
      )}
      <Text>{inputLabel}</Text>
      {filter.groups[parentIndex].conditions.length > 1 && (
        <DeleteFilterRowButton handleDeleteFilter={handleDeleteFilter} />
      )}
    </div>
  );
};

const styles = {
  filterRowWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  radioButtonWrapper: {
    display: 'flex',
    gap: 10,
  },
  secondFilterRow: {
    display: 'flex',
  },
  filterRowSpacer: {
    width: 100,
  },
  thirdFilterRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  deleteFilterRowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export default FilterBetweenRange;

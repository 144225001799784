import React, { createContext, useContext, useMemo, useState } from 'react';

import { startOfDate, endOfDate, newDate } from '@util/dateFunctions';
import { getSessionUser } from '@util/session';

import { AdmitsContextType, AdmitsFeedData } from './types';

const startingDate = new Date(startOfDate(newDate(), 'month').toISOString());
const endingDate = new Date(endOfDate(newDate(), 'month').toISOString());

const AdmitsContext = createContext<AdmitsContextType>({
  admitsContextData: { currentlyAdmitted: [], discharged: [] },
  setAdmitsContextData: () => {},
  startDate: startingDate,
  setStartDate: () => {},
  endDate: endingDate,
  setEndDate: () => {},
  admitTypesList: [],
  admitsType: '',
  setAdmitsType: () => {},
});

const admitTypeMap = {
  'Acute Inpatient': 'IP',
  'Assisted Living Facility': 'ALF',
  'ED to Inpatient': 'ED/IP',
  'ED to Observation': 'ED/OBS',
  'ED Treat-and-Release': 'ED',
  Hospice: 'HOSP',
  'Inpatient Psychiatric': 'IP PSYCH',
  'Inpatient Rehabilitation': 'REHAB',
  'Long Term Care': 'LTC',
  'Long Term Acute Care': 'LTAC',
  Observation: 'OBS',
  Other: 'U',
  Respite: 'RES',
  'Skilled Nursing Facility': 'SNF',
};

export const Provider = ({ children }: any) => {
  const { admitTypes } = getSessionUser();
  const admitTypesList = admitTypes ? Object.keys(admitTypes) : Object.keys(admitTypeMap);

  const [admitsContextData, setAdmitsContextData] = useState<AdmitsFeedData>({
    currentlyAdmitted: [],
    discharged: [],
  });
  const [startDate, setStartDateValue] = useState<Date>(startingDate);
  const setStartDate = (date: Date) => {
    const formattedDate = new Date(startOfDate(date, 'month').toISOString());
    if (formattedDate <= endDate) setStartDateValue(formattedDate);
  };

  const [endDate, setEndDateValue] = useState<Date>(endingDate);
  const setEndDate = (date: Date) => {
    const formattedDate = new Date(endOfDate(date, 'month').toISOString());
    if (formattedDate >= startDate) setEndDateValue(formattedDate);
  };

  const [admitsType, setAdmitsType] = useState<string>(admitTypesList[0]);

  const contextValue = useMemo(() => {
    return {
      admitsContextData,
      setAdmitsContextData,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      admitTypesList,
      admitsType,
      setAdmitsType,
      admitTypeMap,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, admitsType, endDate, admitsContextData]);

  return <AdmitsContext.Provider value={contextValue}>{children}</AdmitsContext.Provider>;
};

export const useAdmitsContext = () => {
  return useContext(AdmitsContext);
};

import React from 'react';
import { layout } from '@intus-ui';

const ChartWrapper = ({ label, children, id }) => {
  return (
    <div style={{ ...layout.container }}>
      <div style={{ ...layout.centeredContentContainer, flex: 0, fontWeight: 'bold' }}>{label}</div>
      <div id={id} style={{ ...layout.content, padding: 0, overflow: 'hidden' }}>
        {children}
      </div>
    </div>
  );
};

export default ChartWrapper;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from '@global-state';

import { updateUserSettings, setPage } from '@global-state/redux';

import { Input, Text } from '@intus-ui';

import WelcomeBox from './WelcomeBox';
import ModalButtons from './ModalButtons';

import '@intus-ui/styles/Utilities.scss';

const ChooseJobTypes = () => {
  const dispatch = useDispatch();
  const userSettings = useSelector((state) => state.userSettings);
  const page = useSelector((state) => state.welcome.page);
  const jobTypes = useSelector((state) => state.welcome.jobTypes);
  const isHealthSysAdmin = useSelector((state) => state.filters.isHealthSysAdmin);

  const [selected, setSelected] = useState(
    userSettings.profile.jobType ? userSettings.profile.jobType : ''
  );
  const [profile] = useState(userSettings.profile);
  if (!profile) {
    return null;
  }
  const history = useHistory();

  const modalButtons = (
    <ModalButtons
      padding="pl-5"
      onClick={() => {
        if (isHealthSysAdmin) {
          const updatedSetting = {
            ...updatedProfile.setting,
            welcomeFlowCompleted: true,
          };
          dispatch(
            updateUserSettings({
              ...updatedProfile,
              settings: updatedSetting,
            })
          );
          history.push('/dashboard');
        } else {
          dispatch(updateUserSettings(updatedProfile));
          dispatch(setPage(page + 1));
        }
      }}
      disabled={selected === ''}
    />
  );
  const updatedProfile = { ...profile, jobType: selected };

  return (
    <WelcomeBox buttons={modalButtons} minWidth="w-50">
      <div className="w-100 d-flex flex-column justify-content-between align-items-center py-4">
        <Text type="headline" color="navy">
          What is your role at PACE?
        </Text>
        <Text type="body" className="text-center pt-3">
          We’d love to know who uses our app to
        </Text>
        <Text type="body">design future features better.</Text>
        <div className="d-flex justify-content-center text-left py-5 w-100">
          <Input
            width="fit-content"
            id="input-suggestions"
            name="input-suggestions"
            fluid
            type="text"
            placeholder="Your role at PACE"
            suggestionLabel=""
            suggestions
            searchable
            onKeyUp={(e) => {
              setSelected(e.target.value);
            }}
            onChange={(e) => {
              setSelected(e);
            }}
            value={selected}
            options={jobTypes}
          />
        </div>
      </div>
    </WelcomeBox>
  );
};

export default ChooseJobTypes;

import { useMemo, useState } from 'react';
import produce, { setAutoFreeze } from 'immer';
import Text from '@intus-ui/components/Text';
import { Button } from '@intus-ui/components/Buttons';
import Icon from '@intus-ui/components/Icon';
import { usePaginatedChart } from '@intus-ui/components/Widget/utils/usePaginatedChart';
import { max } from 'lodash';
import { useMetricDownload } from 'CustomDashboardV2/download/useMetricDownload';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import BarChart from './BarChart';
import { MetricParticipantList } from './MetricParticipantList';

export const BreakdownFocusViewWidget = ({ metric, metricData }) => {
  const { allParticipants, breakdownToParticipants } = metricData.data || {};
  const [metricDataState, setMetricDataState] = useState(metricData);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [participantsList, setParticipantsList] = useState(allParticipants);

  const {
    runQuery: runDownloadQuery,
    loading: isDownloadLoading,
    error: downloadError,
  } = useMetricDownload(metric, metricData);

  // Pagination set up
  const numberOfBarsPerPage = metricData?.data?.barsPerPage ?? 5;
  let isHorizontalBarChart = true;
  if (metricData?.data?.chartDirection === 'vertical') {
    isHorizontalBarChart = false;
  }

  let paginationLabel = '';
  // Attempting to set appropriate paginationLabel per metric
  if (metricDataState?.data?.title === 'Falls Breakdown by Living Situation')
    paginationLabel = 'locations of falls';
  const { page, totalPages, setPage, totalItems, paginatedChartData, startingItem, endingItem } =
    usePaginatedChart(metricDataState?.data?.chartData, numberOfBarsPerPage);

  const disableCaretLeft = page === 0;
  const disableCaretRight = page + 1 >= totalPages;
  const lengthOfPaginatedChartData =
    metricDataState?.data?.chartData?.datasets?.[0]?.data?.length ?? 0;
  const paginatedMetricData = useMemo(() => {
    if (!metricDataState?.data?.chartData) return metricDataState;

    try {
      // By default, immer freezes objects it makes to make them immutable.
      // chart.js directly mutates the data array, so we need to disable freezing or chart.js throws errors.
      setAutoFreeze(false);

      return produce(metricDataState, (draft) => {
        draft.data.chartData = paginatedChartData;
      });
    } finally {
      setAutoFreeze(true);
    }
  }, [metricDataState, paginatedChartData]);

  const handleOnClickCloseButton = () => {
    setSelectedLabel(null);
    // new copy triggers chart re-render
    const refreshData = { ...metricData };
    setMetricDataState(refreshData);
    setParticipantsList(allParticipants);
  };

  const handleOnClickBar = (clickedLabel) => {
    if (clickedLabel) {
      const cleanedLabel = clickedLabel.endsWith('*') ? clickedLabel.slice(0, -1) : clickedLabel;
      const participants = breakdownToParticipants[cleanedLabel];
      setParticipantsList(participants);
      setSelectedLabel(cleanedLabel);
    } else {
      setParticipantsList(allParticipants);
      setSelectedLabel(null);
    }
  };

  // The highest value in the bar, used as the suggested max for the chart so pagination doesn't change the numbers in the axis when changing pages.
  const maxBarValue = max(metricDataState?.data?.chartData?.datasets?.[0]?.data ?? []);

  return (
    <div style={styles.container}>
      <div style={styles.title}>
        <Text color="caption">
          {selectedLabel
            ? `Metric is filtered by: ${selectedLabel}`
            : 'Select a bar on the chart to view more details.'}
        </Text>

        <Button
          name="Download"
          busy={isDownloadLoading}
          hoverText="Download"
          secondary
          onClick={() => runDownloadQuery()}
          style={{
            minWidth: 120,
          }}
        >
          <Icon name="Download" color="#052D8F" />
        </Button>
      </div>

      {downloadError && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
          <span>
            <SpinnerOrError error="An error occurred downloading the metric" />
          </span>
        </div>
      )}

      <div style={styles.content}>
        <div style={styles.chartContainer}>
          <BarChart
            isHorizontalBarChart={isHorizontalBarChart}
            metricData={paginatedMetricData}
            onClickBar={handleOnClickBar}
            suggestedMaxValue={maxBarValue}
            inFocusView
          />

          {metricData?.data?.asteriskText && (
            <Text type="caption" color="caption">{`*${metricData?.data?.asteriskText}`}</Text>
          )}
          {lengthOfPaginatedChartData > numberOfBarsPerPage && (
            <div style={styles.pagination}>
              <Icon
                name="CaretLeft"
                color={disableCaretLeft ? '#C8C8C8' : '#2E62E7'}
                hoverColor={disableCaretLeft ? '#C8C8C8' : '#2E62E7'}
                onClick={disableCaretLeft ? null : () => setPage((page) => page - 1)}
              />
              <div style={styles.paginationText}>
                <Text type="subtitle">
                  {startingItem}-{endingItem}
                </Text>
                <Text>of</Text>
                <Text type="subtitle">{totalItems}</Text>
                <Text>{paginationLabel}</Text>
                <Text>categories</Text>
              </div>
              <Icon
                name="CaretRight"
                color={disableCaretRight ? '#C8C8C8' : '#2E62E7'}
                hoverColor={disableCaretRight ? '#C8C8C8' : '#2E62E7'}
                onClick={disableCaretRight ? null : () => setPage((page) => page + 1)}
              />
            </div>
          )}
        </div>
        <div style={styles.listContainer}>
          <MetricParticipantList
            metricData={metricData}
            participantsList={participantsList}
            selectedLabel={selectedLabel}
            handleOnClickCloseButton={handleOnClickCloseButton}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    margin: 15,
  },
  paginationText: { display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center' },
  title: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  content: {
    display: 'flex',
    gap: 20,
    padding: '10px 0px 0px 0px',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  chartContainer: {
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    flex: '0 0 450px',
  },
};

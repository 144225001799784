import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '@intus-ui/styles/Utilities.scss';
import { setSelected, getModalPatientList } from '@global-state/redux/dashboardSlice';
import userSettingsPropType from 'Settings/userSettingsPropType';
import '@intus-ui/styles/ListItem.scss';
import { updateUserSettings, getUserInfo } from '@global-state/redux/userSettingsSlice';
import NotificationQuad from 'Settings/NotificationQuad';

const RecentEventModal = ({ showModal, userSettings, hide }) => {
  const { profile } = userSettings;
  if (!profile) {
    return null;
  }

  const hideFunc = () => {
    hide(false);
  };

  return (
    <Modal
      dialogClassName="home-modal min-w-90 pr-0 pl-0 overflow-hidden rounded-lg pt-2"
      style={{ maxHeight: '95%' }}
      show={!!showModal}
      onHide={() => hide(false)}
    >
      <Modal.Header className="py-0 pl-4 pt-3 m-0" closeButton>
        <Modal.Title className="text-align-left text-secondary bg-light border-bottom border-secondary mb-0 pb-0">
          Edit Recent Events
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NotificationQuad editButton={false} hide={hideFunc} />
      </Modal.Body>
    </Modal>
  );
};

RecentEventModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  userSettings: userSettingsPropType.isRequired,
  hide: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  userSettings: state.userSettings,
});

const mapDispatch = (dispatch) => ({
  doGetPatientList: (list) => dispatch(getModalPatientList(list)),
  doSetSelected: (item, name) => dispatch(setSelected(item, name)),
  doUpdateUserSettings: (body) => dispatch(updateUserSettings(body)),
  doGetUserLocalData: () => dispatch(getUserInfo()),
});

export default connect(mapState, mapDispatch)(RecentEventModal);

import { useEffect, useState } from 'react';
import { useHover } from '@intus-ui/hooks';
import { Menu, MenuItem, Button as MuiButton } from '@mui/material';
import ConfirmationModal from '@intus-ui/components/ConfirmationModal';
import Icon from '@intus-ui/components/Icon';
import Text from '@intus-ui/components/Text';
import { useMetricDownload } from 'CustomDashboardV2/download/useMetricDownload';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import TooltipComponent from '../TooltipComponent';

const WidgetHeader = ({
  metric,
  metricData,
  canUserEdit,
  hideSeeMore,
  setDragIsEnabled,
  onEditMetric,
  deleteMetricById,
  setSnackbarErrorMessage,
}) => {
  const [hoverRef, isHovered] = useHover();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const title = metricData?.data?.title ?? metric.name ?? '';
  const tooltip = metricData?.data?.tooltip;

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const { runQuery: runDownloadQuery, loading: isDownloadLoading } = useMetricDownload(
    metric,
    metricData,
    {
      onSuccess: () => onCloseMenu(),
      onError: () => {
        setSnackbarErrorMessage('An error occurred downloading the metric');
        onCloseMenu();
      },
    }
  );

  useEffect(() => {
    if (isHovered) {
      if (setDragIsEnabled) setDragIsEnabled(true);
    } else {
      if (setDragIsEnabled) setDragIsEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered]);

  const isWidgetMenuOpen = anchorEl != null;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        padding: '0px 0px 5px 0px',
        cursor: isHovered ? 'grab' : 'pointer',
      }}
      ref={hoverRef}
    >
      <Text type="subtitle" color="navy">
        {title}
        {tooltip && (
          <TooltipComponent
            anchorId={`infoIcon-${metric.id}`}
            divStyles={{
              display: 'inline-block',
            }}
            content={<Text type="caption">{tooltip}</Text>}
          >
            <Icon anchorId="infoIcon" name="info" color="blue" size="small" />
          </TooltipComponent>
        )}
      </Text>

      {!hideSeeMore && (
        <div style={{ marginTop: -5 }}>
          <MuiButton
            variant="text"
            sx={{ margin: 0, marginRight: -5, padding: '0px 0px', minWidth: 0 }}
            onClick={onOpenMenu}
            aria-haspopup="true"
            aria-expanded={isWidgetMenuOpen ? 'true' : undefined}
          >
            <Icon color="navy" name="TripleVerticalDot" />
          </MuiButton>
          <ConfirmationModal
            open={showDeleteConfirmationModal}
            onClose={() => setShowDeleteConfirmationModal(false)}
            title="Delete Metric"
            description={
              <Text>
                Are you sure you want to delete the metric <b>{metric.name}</b>?
              </Text>
            }
            confirmText="Delete"
            onClickConfirm={() => {
              setShowDeleteConfirmationModal(false);
              deleteMetricById(metric.id);
            }}
          />
          <Menu anchorEl={anchorEl} open={isWidgetMenuOpen} onClose={onCloseMenu}>
            {canUserEdit && (
              <MenuItem
                onClick={() => {
                  onEditMetric(metric);
                  onCloseMenu();
                }}
              >
                <Text type="subtitle" color="navy">
                  Edit
                </Text>
              </MenuItem>
            )}

            {!isDownloadLoading && (
              <MenuItem
                onClick={() => {
                  runDownloadQuery();
                }}
              >
                <Text type="subtitle" color="navy">
                  Download
                </Text>
              </MenuItem>
            )}
            {isDownloadLoading && <SpinnerOrError />}

            {canUserEdit && (
              <MenuItem
                onClick={() => {
                  setShowDeleteConfirmationModal(true);
                  onCloseMenu();
                }}
              >
                <Text type="subtitle" color="error">
                  Delete
                </Text>
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
    </div>
  );
};

export default WidgetHeader;

/* eslint-disable prefer-arrow-callback */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

import caregiverPropType from './caregiverListCaregiverPropType';
import { clearnoOfUsersCreated, setSuccess } from '../../../@global-state/redux/caregiverListSlice';

const SuccessModal = ({ successText, hide, selectedCaregiver, show, noOfUsers }) => {
  if (!show) {
    return null;
  }
  return (
    <Modal show onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title className="text-success">
          Success.
          <DoneOutlineIcon className="text-success ml-3" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center pb-5">
        {selectedCaregiver && (noOfUsers === 1 || noOfUsers === undefined) ? (
          <span className="text-secondary">
            {`${selectedCaregiver.firstName} ${selectedCaregiver.lastName}`}
          </span>
        ) : null}
        {successText}
      </Modal.Body>
    </Modal>
  );
};

SuccessModal.propTypes = {
  successText: PropTypes.string,
  hide: PropTypes.func.isRequired,
  selectedCaregiver: caregiverPropType,
  noOfUsers: PropTypes.number,
  show: PropTypes.bool.isRequired,
};

SuccessModal.defaultProps = {
  successText: undefined,
  noOfUsers: undefined,
  selectedCaregiver: undefined,
};

const mapState = (state) => ({
  successText: state.caregiverList.successModal,
  selectedCaregiver: state.caregiverList.selectedCaregiver,
  noOfUsers: state.caregiverList.noOfUsersCreated,
  show: !!state.caregiverList.successModal,
});

const mapDispatch = (dispatch) => ({
  hide: () => {
    dispatch(setSuccess(undefined));
    dispatch(clearnoOfUsersCreated());
  },
});

export default connect(mapState, mapDispatch)(SuccessModal);

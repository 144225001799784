import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getCaregiver } from '@global-state/redux/caregiverDetailSlice';
import { getPatients } from '@global-state/redux/patientListSlice';
import { getCaregivers } from '@global-state/redux/caregiverListSlice';

import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import Profile from 'Participants/Profile/Profile';

import caregiverDetailCaregiverPropType from 'Users/caregiver/caregiverDetail/caregiverDetailCaregiverPropType';

class Caregiver extends React.Component {
  componentDidMount() {
    const { userId, doGetCaregiver, doGetPatients, doGetCaregivers } = this.props;
    doGetCaregiver(userId);
    doGetPatients();
    doGetCaregivers();
  }

  render() {
    const { caregiver, error, history } = this.props;
    // Shows the error message if there was an error.
    if (error) {
      return (
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <div className="m-5">{error}</div>
        </div>
      );
    }

    // Shows the loading spinner if the caregiver isn't yet loaded.
    if (!caregiver) {
      return <CenteredSpinner className="w-100 h-100" />;
    }

    // Configures the pages shown in the profile.
    return <Profile subject={caregiver} onBack={() => history.push('/caregivers')} />;
  }
}

Caregiver.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  userId: PropTypes.string,
  caregiver: caregiverDetailCaregiverPropType,
  error: PropTypes.string,
  doGetCaregiver: PropTypes.func.isRequired,
  doGetPatients: PropTypes.func.isRequired,
  doGetCaregivers: PropTypes.func.isRequired,
};

Caregiver.defaultProps = {
  userId: undefined,
  caregiver: undefined,
  error: undefined,
};

const mapState = (state) => ({
  caregiver: state.caregiverDetail.caregiver,
  error: state.caregiverDetail.error,
});

const mapDispatch = (dispatch) => ({
  doGetCaregiver: (id) => dispatch(getCaregiver(id)),
  doGetPatients: () => dispatch(getPatients()),
  doGetCaregivers: () => dispatch(getCaregivers()),
});

export default withRouter(connect(mapState, mapDispatch)(Caregiver));

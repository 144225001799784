import { useEffect, useCallback, useState } from 'react';

/**
 * @description
 * Provides browser data persistence methods.
 *
 * Uses local storage.
 */
export const isString = (value) => typeof value === 'string' || value instanceof String;

// Store objects in local storage
export const createLocalData = (key, value) => {
  const stringValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringValue);

  // The storage event doesn't fire for the same page changing local storage, it only fires on other tabs.
  // To work around this, we fire the storage event programmatically on the same page.
  // This has the slight drawback of firing the event twice in other tabs.
  // See: https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
  const event = new StorageEvent('storage', {
    key,
    oldValue: '',
    newValue: JSON.stringify(stringValue),
  });

  window.dispatchEvent(event);
};

// Fetch objects from local storage > Should return all keys if no key is passed
export function findLocalData(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch {
    throw new Error(`Could not parse property ${key} from localStorage`);
  }
}

// Try to find an entry in local storage or create a new entry when nothing is found
export function findOrCreateLocalData(key, value) {
  const result = window.localStorage.getItem(key);
  if (!result) {
    createLocalData(key, value);
  }
  return findLocalData(key);
}

// Remove object from local storage
export function removeLocalData(key) {
  return window.localStorage.removeItem(key);
}

// Remove multiple objects from local storage
export function removeMultipleLocalData(keys) {
  keys.forEach((key) => removeLocalData(key));
}

// Clear local storage
export function clearLocalData() {
  // Clear everything except for the rememberMeToken, which should persist even after logging out.
  const keptKeys = Object.keys(localStorage).filter((key) => key.includes('rememberMeToken_'));
  const keptValues = keptKeys.map((key) => findLocalData(key));
  window.localStorage.clear();
  keptKeys.forEach((key, index) => createLocalData(key, keptValues[index]));
}

/**
 * Re-usable hook that watches an object in local storage for changes.
 *
 * If the object changes in local storage then the component using this hook will re-render.
 *
 * @param {string} key - The local storage key
 * @returns - The object stored in local storage.
 */
export function useLocalStorage(key) {
  // Store the object in state so we can trigger re-renders.
  const [object, setObject] = useState(() => findLocalData(key));

  // Update the stored object in our useState
  // It is very important that this is a `useCallback` so the useEffect below can remove the event listener when the local storage object changes.
  const onLocalStorageUpdated = useCallback(
    (event) => {
      // We only care about changes to the single key we're watching in local storage.
      if (event?.key === key) {
        const updatedObject = findLocalData(key);

        // To prevent unnecessary re-renders, make sure the object actually changed before we update.
        if (JSON.stringify(updatedObject) !== JSON.stringify(object)) {
          setObject(updatedObject);
        }
      }
    },
    [key, object]
  );

  useEffect(() => {
    // Listen for the storage event that fires when local storage changes.
    // We need to remove and recreate this event when the object in local storage changes.
    window.addEventListener('storage', onLocalStorageUpdated, false);
    return () => {
      window.removeEventListener('storage', onLocalStorageUpdated);
    };
  }, [onLocalStorageUpdated]);

  // This is intentionally returning `findLocalData` instead of `object` here to ensure that the data is up to date even if
  // the event does not fire for whatever reason.
  // The `useState` just allows us to trigger a re-render.
  return findLocalData(key);
}

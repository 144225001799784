import PropTypes from 'prop-types';

import { Container } from 'react-bootstrap';
import ListItemPinned from 'Homes/HomePage/ListItemPinned';
import ListItem from './ListItem';

import '@intus-ui/styles/Utilities.scss';
import SpinnerOrError from './SpinnerOrError';

const List = ({
  items,
  format,
  onClick,
  emptyText,
  className,
  containerClass,
  selected,
  pinned,
  favoriteList,
  favorite,
  currentlyPinnedIds,
  loading,
}) => {
  return (
    <Container fluid className={containerClass}>
      {loading && <SpinnerOrError />}
      {!loading && items.length > 0 ? (
        items.map((item, index) => {
          const key = `${Object.values(item).join('//')} ${index}`;
          if (favorite !== undefined) {
            return (
              <ListItemPinned
                key={key}
                item={item}
                format={format}
                className={className}
                favoriteList={favoriteList}
                pinned={pinned}
                favorite={favorite}
                currentlyPinnedIds={currentlyPinnedIds}
              />
            );
          }

          return (
            <ListItem
              key={Object.values(item).join('//')}
              item={item}
              format={format}
              onClick={onClick}
              className={className}
              selected={selected}
              pinned={pinned}
            />
          );
        })
      ) : (
        <div className="p-4">
          <p className="text-center text-dark">{emptyText}</p>
        </div>
      )}
    </Container>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  format: PropTypes.arrayOf(
    PropTypes.shape({
      valueField: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])
        .isRequired,
      nodeField: PropTypes.node.isRequired,
      md: PropTypes.number,
    }).isRequired
  ),
  onClick: PropTypes.func,
  emptyText: PropTypes.string,
  className: PropTypes.string,
  containerClass: PropTypes.string,
  pinned: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.object),
  favorite: PropTypes.bool,
  favoriteList: PropTypes.arrayOf(PropTypes.number),
  currentlyPinnedIds: PropTypes.func,
};

List.defaultProps = {
  items: [],
  emptyText: '',
  format: [],
  onClick: () => {},
  className: '',
  containerClass: '',
  pinned: false,
  selected: undefined,
  favorite: undefined,
  favoriteList: undefined,
  currentlyPinnedIds: () => {},
};

export default List;

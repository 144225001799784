import * as FullStory from './fullstory';
import * as AppCues from './appcues';

export const identifyUser = (user) => {
  const payload = {
    displayName: `${user.firstName} ${user.lastName}`,
    email: user.email,
    access: user.access,
    organizationId: user.organizationId,
    organizationName: user.organization.name,
    jobType: user.jobType,
    flags: user.flags || [],
    welcomeFlowFirstCompleted: user.welcomeFlowFirstCompleted,
  };

  FullStory.identifyUser(payload);
  AppCues.identifyUser(payload);
};

export const logEvent = (eventType, meta) => {
  FullStory.logEvent(eventType, meta);
  AppCues.logEvent(eventType, meta);
};

import React from 'react';
import PropTypes from 'prop-types';

const PercentageCol = ({ isDashboard, isHalf, children, style }) => (
  <div
    className={`p-3 d-flex flex-grow-1 ${isDashboard ? 'ignore-content' : ''}  ${
      isHalf ? 'w-50' : ''
    } overflow-y-hidden`}
    style={style}
  >
    {children}
  </div>
);

PercentageCol.propTypes = {
  isDashboard: PropTypes.bool,
  isHalf: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

PercentageCol.defaultProps = {
  isDashboard: false,
  isHalf: false,
};

export default PercentageCol;

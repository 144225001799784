import React, { createContext, useState, useMemo, useContext } from 'react';

export const ParticipantListContext = createContext(undefined);

export const ParticipantListProvider = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState({});

  const participantListContext = useMemo(() => {
    return {
      filterOptions,
      setFilterOptions,
    };
  });

  return (
    <ParticipantListContext.Provider value={participantListContext}>
      {children}
    </ParticipantListContext.Provider>
  );
};

export const useParticipantListContext = () => {
  return useContext(ParticipantListContext);
};

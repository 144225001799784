import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import Centered from '@intus-ui/components/Centered';
import AdminList from 'Admin/admin/AdminList';
import { getAdmins, setCurrOrgId } from 'Admin/UserTracking/AdminSlice';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import AdminListPropType from 'Admin/admin/AdminListPropType';
import AddAdminModal from 'Admin/admin/AddAdminModal';

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    const { orgId, doGetAdmins, doSetCurrOrgId } = this.props;
    doSetCurrOrgId(parseInt(orgId, 10));
    doGetAdmins(orgId);
  }

  render() {
    const { admins, history } = this.props;
    const { show } = this.state;
    let content;
    if (!admins) {
      // If organizations array is undefined, shows a loading spinner.
      content = <CenteredSpinner className="d-flex flex-grow-1" />;
    } else if (!admins.length) {
      const { goBack } = history;
      content = (
        <Centered className="d-flex text-center flex-grow-1">
          <AddAdminModal show={show} hide={() => this.setState({ show: false })} />
          <p>There are no Admins.</p>
          <Button onClick={() => this.setState({ show: true })} className="rounded-lg">
            Add an Admin
          </Button>
          <br />
          <Button onClick={goBack} variant="muted" className="my-3 rounded-lg">
            Back to Organizations
          </Button>
        </Centered>
      );
    } else {
      content = <AdminList />;
    }
    return <div className="w-100 h-100 d-flex flex-column bg-light overflow-hidden">{content}</div>;
  }
}

Organization.propTypes = {
  doGetAdmins: PropTypes.func.isRequired,
  doSetCurrOrgId: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  admins: PropTypes.arrayOf(AdminListPropType),
  history: PropTypes.instanceOf(Object).isRequired,
};

Organization.defaultProps = {
  admins: undefined,
};

const mapState = (state) => ({
  admins: state.admin.admins,
});

const mapDispatch = (dispatch) => ({
  doGetAdmins: (id) => dispatch(getAdmins(id)),
  doSetCurrOrgId: (id) => dispatch(setCurrOrgId(id)),
});

export default withRouter(connect(mapState, mapDispatch)(Organization));

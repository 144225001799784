import React from 'react';
import PropTypes from 'prop-types';

import { Col } from 'react-bootstrap';
import IntusBarChart from '@intus-ui/components/graphs/IntusBarChart';

const ModalBarChart = ({ data, format, xFormat, margin, toggle, customTooltip, legend }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {data ? (
      <Col className="w-100 h-100">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h5>{data.title}</h5>
          {toggle}
        </div>
        <IntusBarChart
          data={data.data}
          margin={margin}
          format={format}
          xFormat={xFormat}
          xTitle={data.xTitle}
          yTitle={data.yTitle}
          minValue={data.minValue}
          maxValue={data.maxValue}
          customTooltip={customTooltip}
          legend={legend}
        />
      </Col>
    ) : null}
  </>
);

ModalBarChart.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  format: PropTypes.func,
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  toggle: PropTypes.node,
  legend: PropTypes.bool,
  customTooltip: PropTypes.func,
};

ModalBarChart.defaultProps = {
  format: undefined,
  margin: {
    top: 50,
    right: 0,
    bottom: 250,
    left: 60,
  },
  toggle: null,
  legend: false,
  customTooltip: undefined,
};

export default ModalBarChart;

import { useState, useEffect } from 'react';
import { Calendar } from 'react-date-range';
import { formatDate, newDate } from '@util/dateFunctions';

import { quadColors, textColors } from '@intus-ui/styles/SecondaryColors';
import Input from '@intus-ui/components/Input';

const SingleDateSelect = ({
  onSelect = () => {},
  onSelectDate = () => {},
  disabled,
  existingDate,
  props = {},
  calendarProps = {},
}) => {
  const dateFormat = 'MM/DD/YY';
  const [selectedDate, setSelectedDate] = useState(undefined);
  const onSelectExt = (date) => {
    setSelectedDate(new Date(date));
    onSelectDate(new Date(date));
    onSelect(formatDate(date, dateFormat));
  };

  useEffect(() => {
    if (existingDate) {
      setSelectedDate(newDate(existingDate).toDate());
    }
  }, [existingDate]);

  return (
    <Input
      {...props}
      fluid
      id="single-date-select"
      name="single-date-select"
      type="select"
      leftIcon="calendar"
      rightIcon="caret-down"
      value={selectedDate && formatDate(selectedDate, dateFormat)}
      placeholder="Select date"
      disabled={disabled}
      isCustomDropdownList
      style={{ width: 'max-content', ...props.style }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          border: 'solid 1px',
          borderColor: quadColors.disabled,
          backgroundColor: 'white',
        }}
      >
        <Calendar
          color={textColors.navy}
          date={selectedDate}
          onChange={onSelectExt}
          {...calendarProps}
        />
      </div>
    </Input>
  );
};

export default SingleDateSelect;

export const wrapTextForXAxis = (text, lineLimit) => {
  const lineBreakup = [];
  let breakIndex = lineLimit;
  let hyphen = true;
  const formattedText = text.trim();
  // Check if there is a space -> " " -> within an appropriate range of the line limit, break there and remove hyphen
  for (let i = lineLimit - 5; i <= lineLimit + 3; i += 1) {
    if (formattedText[i] === ' ' || formattedText.length < lineLimit) {
      hyphen = false;
      breakIndex = i;
    }
  }
  // If the text does not have a space to use as a break point, hyphenate
  if (hyphen === true) {
    lineBreakup.push(`${formattedText.slice(0, breakIndex)}-`);
  } else {
    lineBreakup.push(formattedText.slice(0, breakIndex));
  }

  // If the second line of text is still too long, truncate at line limit and add ellipses
  if (formattedText.slice(breakIndex, formattedText.length).length > lineLimit) {
    lineBreakup.push(`${formattedText.slice(breakIndex, breakIndex + lineLimit)}...`);
  } else {
    lineBreakup.push(formattedText.slice(breakIndex, formattedText.length));
  }

  return lineBreakup;
};

export const formatTooltip = (data, point, el) => {
  let position = {
    marginBottom: '-10px',
  };

  const onLeft = {
    marginLeft: '-100%',
    marginRight: 'calc(100% + 10px)',
    marginBottom: '-100%',
  };

  const onRight = {
    marginLeft: 10,
    marginBottom: '-100%',
  };

  if (el) {
    const elem = document.getElementById(el);
    if (elem) {
      const rect = elem.getBoundingClientRect();

      if (point.absX < rect.width * 0.5) position = onRight;
      else if (point.absX > rect.width * 0.5) position = onLeft;
    }
  }

  return (
    <div
      style={{
        background: 'white',
        padding: '5px 10px',
        border: '1px solid #ccc',
        textWrap: 'normal',
        ...position,
      }}
    >
      {data}
    </div>
  );
};

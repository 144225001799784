import React from 'react';
import Text from '../Text';

import styles from './styles';

const Tab = ({ isActive, onClick, title, disabled }) => {
  const handleOnClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  };

  return (
    <div
      style={{
        ...styles.tab,
        backgroundColor: isActive ? '#EFF0F3' : '#FFFF',
        cursor: disabled ? 'default' : 'pointer',
      }}
      onClick={handleOnClick}
      onKeyDown={handleOnClick}
      role="none"
    >
      <Text type="subtitle" color={isActive ? 'navy' : 'link'} disabled={disabled}>
        {title}
      </Text>
    </div>
  );
};

export default Tab;

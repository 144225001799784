import { useState, useEffect } from 'react';
import { layout, Text } from '@intus-ui';
import { groupBy } from 'lodash';
import payload from './digestPayload';
import ParticipantChangesCard from './ParticipantChangesCard';

const DigestView = () => {
  const [dataGroupedByDate, setDataGroupedByDate] = useState({});
  const [eventDates, setEventDates] = useState([]);

  useEffect(() => {
    const groupedByDate = groupBy(payload, 'date');
    setDataGroupedByDate(groupedByDate);
    setEventDates(Object.keys(groupedByDate));
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.subHeader}>
        <Text type="headline" color="navy">
          Digest
        </Text>
      </div>
      <div style={styles.content}>
        {eventDates.map((date, index) => {
          const key = index;
          const changesPerParticipant = dataGroupedByDate[date];
          return (
            <div key={key} style={styles.dateGroup}>
              <Text type="subtitle" color="navy">
                {date}
              </Text>
              {changesPerParticipant.map((change, i) => {
                const changesKey = i;
                return <ParticipantChangesCard key={changesKey} change={change} />;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  container: {
    ...layout.container,
    padding: 15,
    gap: 20,
    height: '100%',
    overflowY: 'auto',
  },
  subHeader: { display: 'flex' },
  content: {
    ...layout.container,
    overflowY: 'auto',
    gap: 20,
  },
  dateGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    borderBottom: 'solid 2px #052d8f',
    paddingBottom: 20,
  },
};

export default DigestView;

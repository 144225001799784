import { ButtonBase } from '@mui/material';
import { Icon, Text } from '@intus-ui';

const DetailsButton = ({ onClick, text = 'See Details' }) => {
  return (
    <ButtonBase
      type="button"
      sx={{
        '&:hover': {
          span: {
            color: '#052d8f !important',
          },
          svg: {
            color: '#052d8f !important',
          },
        },
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: 'flex',
          gap: '3px',
          justifyContent: 'center',
          color: '#2E62E7',
        }}
      >
        <Text color="link" type="subtitle">
          {text}
        </Text>
        <Icon name="ArrowRight" size="medium" />
      </div>
    </ButtonBase>
  );
};

export default DetailsButton;

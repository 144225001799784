import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { setSelected } from '@global-state/redux/homepageSlice';

import { Row, Col } from 'react-bootstrap';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import DonutChart from '@intus-ui/components/graphs/DonutChart';
import { alertColors, riskColors } from '@intus-ui/styles/SecondaryColors';
import TextData from 'Dashboard/QuadContent/TextData';

import '@intus-ui/styles/Utilities.scss';

const RiskAcuityPreview = ({ content, error }) => {
  const createAcuityGraph = () => {
    // acuityChartColors was taken from the RiskAcuityAnalytics pie chart.
    // We should be using constants for these, but the constants are not updated with
    // the correct colors.
    // The constants are commented out for future use when they are updated globally.
    const acuityChartColors = [
      riskColors.low,
      riskColors.moderate,
      riskColors.high,
      riskColors.highest,
    ];
    const acuityChartLabels = [];
    const acuityScoreChartData = [];
    const acuityScoreBreakdownData = content.acuityBreakdown;
    Object.entries(acuityScoreBreakdownData).forEach(([key, val]) => {
      acuityChartLabels.push(key);
      acuityScoreChartData.push(val.value);
    });

    /** @type {import('chart.js').ChartOptions<'bar'>} */
    const acuityGraphOptions = {
      radius: '90%',
      cutout: '40%',
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    return (
      <DonutChart
        expand
        options={acuityGraphOptions}
        info={acuityScoreChartData}
        label={acuityChartLabels}
        colors={acuityChartColors}
      />
    );
  };

  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center p-0 m-0">
      {content ? (
        <Row className="w-100 h-100 d-flex flex-column justify-content-center align-items-center overflow-scroll">
          <Col
            lg={5}
            className="h-100 w-50 d-flex justify-content-center"
            style={{ flex: '1 1 100%' }}
          >
            {createAcuityGraph()}
          </Col>
          <Col lg={7} className="h-100 w-50 d-flex justify-content-start">
            <Row className="w-100 h-100 d-flex flex-column justify-content-center">
              <Row className="d-flex flex-row align-items-center">
                <TextData
                  data={
                    content !== undefined ? (
                      <>{content.netAcuityChanges.increased.value.toString()}</>
                    ) : (
                      <SpinnerOrError error={error} />
                    )
                  }
                  size={3}
                  subtext={{ text: 'increased' }}
                  arrow="up"
                  textColor={alertColors.danger}
                />
              </Row>
              <Row className="d-flex flex-row align-items-center pt-2">
                <TextData
                  data={
                    content !== undefined ? (
                      <>{content.netAcuityChanges.decreased.value.toString()}</>
                    ) : (
                      <SpinnerOrError error={error} />
                    )
                  }
                  size={3}
                  arrow="down"
                  subtext={{ text: 'decreased' }}
                />
              </Row>
            </Row>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

RiskAcuityPreview.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
  error: PropTypes.string,
};

RiskAcuityPreview.defaultProps = {
  error: '',
};

const mapState = (state) => ({
  error: state.homepage.homeError,
});

const mapDispatch = (dispatch) => ({
  doSetSelected: (item, name) => dispatch(setSelected(item, name)),
});

export default connect(mapState, mapDispatch)(RiskAcuityPreview);

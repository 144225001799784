import React, { useState } from 'react';
import { useDispatch, useSelector } from '@global-state';

import { dateDifference } from '@util/dateFunctions';
import { useFeatureFlag } from '@util';
import { useGetSessionUser } from '@util/session';

import { getPptRecentStats } from '@global-state/redux/profileSlice';

import { Dropdown, DropdownButton } from 'react-bootstrap';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Text } from '@intus-ui';
import { PatientCCSRDiagnoses } from 'Participants/acuity/diagnoses/CCSRDiagnoses';
import { PatientStarsAdherence } from 'Participants/acuity/ProfileCurrentAcuities/PatientStarsAdherence';
import {
  IntusLabelDiagnosesTooltip,
  PatientIntusLabelDiagnoses,
} from 'Participants/acuity/diagnoses/IntusLabelDiagnoses';
import { ICDCodeDiagnoses } from 'Participants/acuity/diagnoses/ICDCodeDiagnoses';
import ProfileStatsSummary from './ProfileStatsSummary';

import BreakdownConditionsTabs from './BreakdownConditionsTabs';

import '@intus-ui/styles/Profile.scss';
import '@intus-ui/styles/Utilities.scss';

const allSubPages = ['CCSR', 'Intus Labels', 'All Conditions'] as const;

type AllPages = (typeof allSubPages)[number];

const ProfileCurrentAcuities = () => {
  const patient = useSelector((state: any) => state.patientDetail.patient);
  const pptId = useSelector((state: any) => state.patientDetail.pptId);
  const recentStats = useSelector((state: any) => state.profile.stats);
  const statTime = useSelector((state: any) => state.profile.statTime);

  const { organizationId } = useGetSessionUser();

  const mySubPages = allSubPages.filter((page) => {
    if (process.env.NODE_ENV === 'development') {
      return true;
    }
    // Hide CCSR for non-SCAN orgs for now.
    if (organizationId !== 200) {
      return page !== 'CCSR';
    }
    return true;
  });

  const [currentPage, setCurrentPage] = useState<AllPages>(
    organizationId === 200 ? 'CCSR' : 'Intus Labels'
  );

  const { featureIsActive: polypharmacyIsActive } = useFeatureFlag('polypharmacy');

  const dispatch = useDispatch();

  const statsTimeFilter = (
    <span key="enroll-filter">
      <DropdownButton
        size="sm"
        onClick={(event) => {
          const target = event.target as HTMLElement;
          if (target.id !== statTime) {
            dispatch(getPptRecentStats(pptId, target.id));
          }
        }}
        id={statTime}
        title={statTime}
      >
        <Dropdown.Item id="Past 30 days">Past 30 days</Dropdown.Item>
        <Dropdown.Item id="Past 3 months">Past 3 months</Dropdown.Item>
        <Dropdown.Item id="Past 6 months">Past 6 months</Dropdown.Item>
        <Dropdown.Item id="Past 1 year">Past 1 year</Dropdown.Item>
      </DropdownButton>
    </span>
  );

  const acuityLevels = [...patient.acuityLevels];
  acuityLevels.sort((a, b) => dateDifference(b.createdAt, a.createdAt));

  return (
    <div id="fluidContentContainer" style={styles.container}>
      <ProfileStatsSummary
        acuityLevels={acuityLevels}
        patient={patient}
        statsTimeFilter={statsTimeFilter}
        recentStats={recentStats}
      />
      <div style={styles.fluidContainer}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, height: '100%' }}>
          {/* Title and radio buttons to switch page */}
          <div
            style={{
              flex: '0 0 auto',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text type="subtitle" color="navy">
              Diagnoses
            </Text>

            <RadioGroup
              row
              aria-label="Change Diagnosis Classification System"
              value={currentPage}
              onChange={(_event, value) => setCurrentPage(value as AllPages)}
            >
              {mySubPages.map((page) => (
                <FormControlLabel
                  key={page}
                  value={page}
                  control={<Radio />}
                  label={
                    <span style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'top' }}>
                      <Text style={{ whiteSpace: 'nowrap' }}>{page}</Text>
                      {page === 'Intus Labels' && <IntusLabelDiagnosesTooltip />}
                    </span>
                  }
                />
              ))}
            </RadioGroup>
          </div>

          {/* Diagnosis Classification page based on chosen radio button */}
          <div style={styles.pageContainer}>
            {currentPage === 'CCSR' && <PatientCCSRDiagnoses />}
            {currentPage === 'All Conditions' && <ICDCodeDiagnoses />}
            {currentPage === 'Intus Labels' && <PatientIntusLabelDiagnoses />}
          </div>
        </div>
      </div>
      {polypharmacyIsActive && (
        <div style={{ flex: '0 0 295px' }}>
          <PatientStarsAdherence />
        </div>
      )}
      {!polypharmacyIsActive && <BreakdownConditionsTabs />}
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    gap: 20,
    minHeight: 0,
    paddingTop: 10,
  },
  fluidContainer: {
    flex: 1,
    height: '100%',
  },
  pageContainer: {
    flex: '1 1 100%',
    maxHeight: '100%',
    overflowY: 'auto',
  },
} satisfies InlineCss;

export default ProfileCurrentAcuities;

import React from 'react';
import PropTypes from 'prop-types';
import '@intus-ui/styles/Dashboard.scss';
import '@intus-ui/styles/Utilities.scss';
import { Row } from 'react-bootstrap';
import QuadHeader from './QuadContent/QuadHeader';

const Quad = ({ title, children, onClick, padding, scrollable, bodyStyle, disabled }) => {
  if (!onClick || title === 'Coming Soon') {
    return (
      <div
        className={`quad h-100 ${title !== 'Coming Soon' ? 'bg-white' : 'bg-off-white'} ${
          scrollable ? 'overflow-scroll' : 'overflow-hidden'
        } non-clickable box-shadow-on-gray rounded-xlg bg-white d-flex flex-column align-items-center`}
      >
        {/* background needs to be white and z-index needs to be > 1
        to ensure that items in scrollable quads do not overlap headers */}
        <Row className="w-100 m-0 px-4 sticky bg-white" style={{ zIndex: 2 }}>
          {title ? <QuadHeader title={title} disabled={disabled} /> : null}
        </Row>
        {disabled ? (
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80%',
              width: '90%',
              textAlign: 'center',
              ...bodyStyle,
            }}
          >
            {children}
          </Row>
        ) : (
          <Row
            className={`${padding} m-0 w-100 h-100 ${
              scrollable ? 'overflow-scroll' : 'overflow-hidden'
            }`}
            style={{ ...bodyStyle }}
          >
            {children}
          </Row>
        )}
      </div>
    );
  }
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={onClick}
      className={`hover-light quad h-100 m-0 p-0 b-0 clickable ${
        scrollable ? 'overflow-scroll' : 'overflow-hidden'
      } box-shadow-on-gray rounded-xlg  d-flex flex-column align-items-center`}
    >
      <Row className="w-100 m-0 px-4 sticky" style={{ zIndex: 2 }}>
        {title ? <QuadHeader title={title} /> : null}
      </Row>
      <Row
        className={`${padding} p-0 m-0 w-100 height-90 ${
          scrollable ? 'overflow-scroll' : 'overflow-hidden'
        }`}
      >
        {children}
      </Row>
    </div>
  );
};
Quad.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  scrollable: PropTypes.bool,
};

Quad.defaultProps = {
  title: null,
  children: null,
  onClick: undefined,
  scrollable: false,
  padding: 'px-4 py-1',
};

export default Quad;

import Text from '../Text/index';
import './style.scss';

export const formatEvents = (events) => {
  return events.map((event) => {
    const { enrollment, enrollmentValue, name, date, dateValue, ...rest } = event;
    return {
      enrollment,
      enrollmentValue,
      name,
      date,
      dateValue,
      ...rest,
    };
  });
};

export const EventListItem = ({ item, onClick }) => {
  const { name, enrollment, date } = item;
  return (
    <div
      className="event-list-item"
      role="button"
      tabIndex="0"
      onKeyPress={onClick}
      onClick={onClick}
    >
      <div style={styles.nameEnrollmentColumn}>
        <Text>{name}</Text>
        <div style={styles.eventBadges}>{enrollment}</div>
      </div>
      <div style={styles.dateColumn}>
        <Text>{date}</Text>
      </div>
    </div>
  );
};

const styles = {
  nameEnrollmentColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'start',
    textOverflow: 'wrap',
    gap: '5px',
  },
  eventBadges: { display: 'flex', flexDirection: 'row', gap: '5px' },
  dateColumn: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'start',
  },
};

export const recentEventCategoriesToEventMap = {
  Enrolled: 'enrolled',
  Disenrolled: 'disenrolled',
  'Inpatient Admits': 'inpatientAdmits',
  'Inpatient Discharges': 'inpatientDischarges',
  'ER Visits': 'erVisits',
};

import React from 'react';
import { Text } from '@intus-ui';

const TimeSeries = () => {
  return (
    <div style={styles.timeSeries}>
      <Text type="headline" color="navy">
        Time series (graphs)
      </Text>
    </div>
  );
};

const styles = {
  timeSeries: { flex: 1, border: '1px solid green', padding: 5 },
};

export default TimeSeries;

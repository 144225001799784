import React from 'react';
import { connect } from 'react-redux';

import { dateDifference } from '@util/dateFunctions';

import { Row, Col } from 'react-bootstrap';
import { SpinnerOrError } from '@intus-ui';
import ProfileCarePlanTable from './ProfileCarePlanTable';

import '@intus-ui/styles/Profile.scss';
import '@intus-ui/styles/Utilities.scss';

const ProfileCurrentCarePlan = ({ carePlanInfo }) => {
  if (!carePlanInfo) return <SpinnerOrError />;

  let carePlanInfoCopy = carePlanInfo;

  // if carePlanInfo is not a list, put it in a list. Needed for new careplans api
  try {
    [...carePlanInfoCopy].sort((a, b) => dateDifference(b.createdAt, a.createdAt));
  } catch (error) {
    carePlanInfoCopy = [carePlanInfo];
  }

  return (
    <Row className="w-100 h-100 m-0 overflow-scroll bg-white px-5">
      <Col lg={12} className="h-75 pb-5">
        {carePlanInfoCopy.length ? (
          <ProfileCarePlanTable problems={carePlanInfoCopy[0].problems} />
        ) : (
          <SpinnerOrError error="No care plans available for this participant." />
        )}
      </Col>
    </Row>
  );
};

const mapState = (state) => ({
  carePlanInfo: state.carePlan.carePlanInfo,
});

export default connect(mapState)(ProfileCurrentCarePlan);

import { orderBy } from 'lodash';
import { useState, useEffect } from 'react';

import { capitalizeFirst } from '@util';

import { Input, Icon, RadioButtonV2 } from '@intus-ui';
import produce from 'immer';
import * as FilterComponents from './FilterComponents';

import useFilter from '../useFilter';
import { filterRowSchemas } from '../filterRowConfig';
import {
  getDaysFromDisplayTitle,
  displayTitles,
  getNewConditionFromGroupType,
  getDisplayTitleFromDays,
} from '../helpers';

const DeleteFilterRowButton = ({ handleDeleteFilter }) => (
  <div style={styles.deleteFilterRowButton}>
    <Icon
      name="Remove"
      color="#9D9D9D"
      height="13px"
      width="13px"
      hoverColor="#052d8f"
      onClick={handleDeleteFilter}
    />
  </div>
);

const FilterRow = ({
  index,
  parentIndex,
  condition,
  handleChangeOperator,
  setFilterRowLabel,
  isSingleFilterRow,
  operator,
  setFilter,
  filter,
  filterGroupSchema,
  setFilterGroupSchema,
}) => {
  const isFirst = index === 0;
  const isSecond = index === 1;
  const { filterOptions, operators, isReady } = useFilter();

  const [type, setType] = useState('');
  const [searchTerm, setSearchTerm] = useState();
  const [frequency, setFrequency] = useState({ gte: 1 });
  const [frequencyBetweenLTE, setFrequencyBetweenLTE] = useState({ lte: 2 });

  const [dateRange, setDateRange] = useState(displayTitles[0]);
  const [range, setRange] = useState('At least');

  const logicOptions = filterRowSchemas(type)[type]?.logicOptions;
  const [groupLogicOption, setgroupLogicOption] = useState({ label: 'equals', value: '=' });
  const [filterLogicOption, setFilterLogicOption] = useState(undefined);
  const [pastMonth, setPastMonth] = useState(12);

  const displayRange = type === 'incidents' || type === 'hospitalizations';

  useEffect(() => {
    const {
      type: initialType,
      searchTerm: existingSearchTerm,
      betweenLte: defaultBetweenLte,
    } = condition;

    // sets "add {condition}" label
    const label =
      filterOptions[initialType]?.singular || filterOptions[initialType]?.label || 'filter';
    setFilterRowLabel(`+ add ${label.toLowerCase()}`);

    if (initialType) {
      // set existing type
      setType(initialType);

      // Set filterGroupSchema
      const arr = [];
      const newFilterRow = [...Object.keys(filterRowSchemas(initialType)[initialType]?.components)];
      arr.push(newFilterRow);
      setFilterGroupSchema(arr);
    }

    if (existingSearchTerm) {
      setSearchTerm(existingSearchTerm);
    }

    if (condition?.frequency) {
      if (!condition.frequency.lte >= 0 && defaultBetweenLte)
        setFrequencyBetweenLTE({ lte: defaultBetweenLte });

      if (condition.frequency.lte >= 0 && condition.frequency.gte >= 0) {
        setRange('Between');
        setFrequency({ gte: condition.frequency.gte, unit: condition.frequency.unit });
        setFrequencyBetweenLTE({ lte: condition.frequency.lte, unit: condition.frequency.unit });
      } else {
        setRange(condition.frequency.lte >= 0 ? 'At most' : 'At least');
        setFrequency(condition.frequency);
      }
    }

    if (condition?.dateRange) {
      setDateRange(getDisplayTitleFromDays(condition.dateRange.days) || displayTitles[0]);
    }

    if (condition?.logic) {
      const typeLogicOptions = filterRowSchemas(condition.type)[condition.type]?.logicOptions;
      if (typeLogicOptions) {
        const existingLogic = typeLogicOptions.find((option) => option.value === condition.logic);
        if (existingLogic) {
          setgroupLogicOption(existingLogic);
        } else {
          setgroupLogicOption(typeLogicOptions[0]);
        }
      }
    }

    if (condition.type === 'document') {
      // set existing logic for documentation
      if (condition?.logic) {
        const componentLogicOptions = filterRowSchemas(condition.type)[condition.type]?.components
          .FilterInputSelectWithOptions.logicOptions;
        const existingLogic = componentLogicOptions.find(
          (option) => option.value === condition.logic
        );
        setFilterLogicOption(existingLogic);
      }

      // set existing date range for documentation
      if (condition?.dateRange) {
        setPastMonth(condition.dateRange.months);
      }
    }

    // when user clears filter
    if (!initialType) {
      setType('');
      setFilterGroupSchema([]);
    }
  }, [isReady, condition]);

  const handleTypeChange = (val) => {
    if (filter.groups[parentIndex].conditions.length > 1) {
      return;
    }
    const value = val.option ? val.option : val;
    const label = val?.label || value;

    // Updates UI
    setType(value);
    setFilterRowLabel(`+ add ${label}`);
    setSearchTerm('');

    // Set filterGroupSchema
    const arr = [];
    const newFilterRow = Object.keys(filterRowSchemas(value)[value]?.components);
    arr.push(newFilterRow);
    setFilterGroupSchema(arr);

    // Updates Data
    const newCondition = getNewConditionFromGroupType(value);
    const newFilter = produce(filter, (draft) => {
      draft.groups[parentIndex].conditions[index] = newCondition;
    });
    setFilter(newFilter);
  };

  const handleChangeSearchTerm = (option) => {
    const value = option?.value ? option.value : option;
    // Updates UI
    setSearchTerm(value);
    // Updates Data
    const newFilter = produce(filter, (draft) => {
      draft.groups[parentIndex].conditions[index].searchTerm = value;
    });

    setFilter(newFilter);
  };

  const handleChangeFrequencyType = (val) => {
    const isBetween = val === 'Between';
    const currentFrequency = filter.groups[parentIndex].conditions[index].frequency;
    // Updates UI
    setRange(val);

    // Updates Data
    const frequencyVal = Object.values(frequency)[0];

    let newValue;

    if (isBetween) {
      // PULL DEFAULT METRIC SETTING IF LTE IS NOT SET?

      const frequencyGTEVal = Object.values(frequencyBetweenLTE)[0];

      newValue = {
        gte: frequencyVal,
        lte: frequencyGTEVal || frequencyVal,
        unit: currentFrequency?.unit,
      };
      setFrequencyBetweenLTE({ lte: frequencyGTEVal || frequencyVal });
    } else {
      newValue =
        val === 'At least'
          ? { gte: frequencyVal, unit: currentFrequency?.unit }
          : { lte: frequencyVal, unit: currentFrequency?.unit };
    }
    const newFilter = produce(filter, (draft) => {
      draft.groups[parentIndex].conditions[index].frequency = newValue;
    });
    setFilter(newFilter);
  };

  const handleChangeFrequencyValue = (e, isBetweenLTE, isUnitSelectValue) => {
    const currentFrequency = filter.groups[parentIndex].conditions[index].frequency;
    let newValue;
    const isBetween = range === 'Between';

    if (isUnitSelectValue) {
      newValue = { ...currentFrequency, unit: e };
      const newFilter = produce(filter, (draft) => {
        draft.groups[parentIndex].conditions[index].frequency = newValue;
      });
      setFrequency({ gte: newValue.gte, unit: newValue.unit });
      setFilter(newFilter);
      return;
    }

    const value = parseInt(e.target.value, 10);

    if (isBetween) {
      newValue = {
        gte: isBetweenLTE ? currentFrequency.gte : value,
        lte: isBetweenLTE ? value : currentFrequency.lte,
        unit: currentFrequency.unit,
      };
      setFrequency({ gte: newValue.gte });
      setFrequencyBetweenLTE({ lte: newValue.lte });
    } else {
      const { lte } = currentFrequency;
      newValue =
        lte === undefined
          ? { gte: value, unit: currentFrequency.unit }
          : { lte: value, unit: currentFrequency.unit };
      setFrequency(newValue);
    }

    // Updates Data
    const newFilter = produce(filter, (draft) => {
      draft.groups[parentIndex].conditions[index].frequency = newValue;
    });

    if (e.target.value !== '') setFilter(newFilter);
  };

  const changeDateRange = (value) => {
    // Updates UI
    setDateRange(value);
    // Updates Data
    const newFilter = produce(filter, (draft) => {
      draft.groups[parentIndex].conditions[index].dateRange.days = getDaysFromDisplayTitle(value);
    });
    setFilter(newFilter);
  };

  const handleChangedPastMonths = (e) => {
    const { value } = e.target;
    // Updates UI
    setPastMonth(value);
    // Updates Data
    const newFilter = produce(filter, (draft) => {
      draft.groups[parentIndex].conditions[index].dateRange.months = value;
    });
    setFilter(newFilter);
  };

  const handleDeleteFilter = () => {
    const newFilter = produce(filter, (draft) => {
      draft.groups[parentIndex].conditions.splice(index, 1);
    });
    setFilter(newFilter);
  };

  const radioTypeSelector = false;

  const handleChangeLogicOptions = (option) => {
    // Updates UI
    setgroupLogicOption(option);
    // Updates Data
    const newFilter = produce(filter, (draft) => {
      draft.groups[parentIndex].conditions.forEach((cdt, i) => {
        draft.groups[parentIndex].conditions[i].logic = option.value;
      });
    });
    setFilter(newFilter);
  };

  const handleChangeFilterRowLogic = (option) => {
    const noDateRangeLogics = ['completed', 'neverCompleted'].includes(option?.value);

    // Updates UI
    setFilterLogicOption(option);
    // Updates Data

    const newFilter = produce(filter, (draft) => {
      // remove dateRange if logic is completed or neverCompleted
      // add dateRange if logic is not completed or neverCompleted
      if (noDateRangeLogics && draft.groups[parentIndex].conditions[index].dateRange) {
        delete draft.groups[parentIndex].conditions[index].dateRange;
      } else if (
        !noDateRangeLogics &&
        draft.groups[parentIndex].conditions[index].dateRange === undefined
      ) {
        draft.groups[parentIndex].conditions[index].dateRange = {
          months: 12,
        };
      }
      draft.groups[parentIndex].conditions[index].logic = option.value;
    });
    setFilter(newFilter);
  };

  const sortedOptions = orderBy(Object.values(filterOptions), ['label'], ['asc']);

  return (
    <div id="filterRowWrapper" style={styles.filterRowWrapper}>
      {isFirst &&
        (radioTypeSelector ? (
          <div id="radioButtonWrapper" style={styles.radioButtonWrapper}>
            {Object.values(filterOptions).map((filterType) => {
              return (
                <RadioButtonV2
                  key={JSON.stringify(filterType)}
                  option={filterType.option}
                  label={filterType.label}
                  size="small"
                  labelPosition="horizontal"
                  selected={capitalizeFirst(type)}
                  setSelected={handleTypeChange}
                  inputGroupUnset={!type}
                />
              );
            })}
          </div>
        ) : (
          <div style={{ display: 'flex', gap: 10 }}>
            <Input
              fluid
              id="search-type-input"
              leftIcon="search"
              name="search-type-input"
              onChange={handleTypeChange}
              options={sortedOptions}
              placeholder="Pick an option"
              rightIcon="caret-down"
              value={type ? filterOptions[type]?.label : ''}
              type="input"
              noMatchLabel="no match"
              searchable
              style={{ minWidth: 300 }}
              disabled={filter.groups[parentIndex].conditions.length > 1}
            />

            {type && logicOptions && (
              <Input
                fluid
                id="logicOptions"
                name="logicOptions"
                type="select"
                rightIcon="caret-down"
                options={logicOptions}
                value={groupLogicOption?.label}
                onChange={handleChangeLogicOptions}
                style={{ minWidth: 120 }}
              />
            )}
          </div>
        ))}
      {type &&
        filterGroupSchema.map((schemaArray) => {
          return schemaArray.map((schemaType) => {
            const WrapperTag = FilterComponents[schemaType];
            return (
              <WrapperTag
                key={JSON.stringify(schemaType)}
                operator={operator}
                isFirst={isFirst}
                isSecond={isSecond}
                operators={operators}
                handleChangeOperator={handleChangeOperator}
                filterOptions={filterOptions}
                type={type}
                searchTerm={searchTerm}
                handleChangeSearchTerm={handleChangeSearchTerm}
                handleDeleteFilter={handleDeleteFilter}
                parentIndex={parentIndex}
                filter={filter}
                DeleteFilterRowButton={DeleteFilterRowButton}
                isSingleFilterRow={isSingleFilterRow}
                displayRange={displayRange}
                range={range}
                handleChangeFrequencyType={handleChangeFrequencyType}
                frequency={frequency}
                frequencyBetweenLTE={frequencyBetweenLTE}
                handleChangeFrequencyValue={handleChangeFrequencyValue}
                displayTitles={displayTitles}
                dateRange={dateRange}
                changeDateRange={changeDateRange}
                handleChangeFilterRowLogic={handleChangeFilterRowLogic}
                filterLogicOption={filterLogicOption}
                pastMonth={pastMonth}
                handleChangedPastMonths={handleChangedPastMonths}
              />
            );
          });
        })}
    </div>
  );
};

const styles = {
  filterRowWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  radioButtonWrapper: {
    display: 'flex',
    gap: 10,
  },
  secondFilterRow: {
    display: 'flex',
  },
  filterRowSpacer: {
    width: 100,
  },
  thirdFilterRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  deleteFilterRowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export default FilterRow;

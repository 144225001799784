import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import * as userTracking from '@util/userTracking';
import { toUTC, newDate, formatDate } from '@util/dateFunctions';

import { getAllCustomDashboards } from '@api/dashboard';
import { useQuery } from '@api/useQuery';
import { getTrackingEvent } from '@api/api';

import { Col, Nav } from 'react-bootstrap';
import { Icon, SpinnerOrError } from '@intus-ui';
import Quad from 'Dashboard/Quad';

import '@intus-ui/styles/Home.scss';
import '@intus-ui/styles/Utilities.scss';

const CustomListPreview = () => {
  const { data: dashboardsList } = useQuery(() => getAllCustomDashboards());

  if (!dashboardsList) {
    return (
      <Quad title="Custom Dashboards" padding="py-0 px-3" scrollable>
        <SpinnerOrError />
      </Quad>
    );
  }

  return (
    <Quad title="Custom Dashboards" padding="py-0 px-3" scrollable>
      <Col className="h-100 w-100 pb-2 px-0">
        <div className="pt-1 m-0 w-100 align-items-start">
          <div className="m-0 w-100 h-100">
            <Nav.Link
              key="create-new-dashboard"
              as={Link}
              className="list-nav-link"
              onClick={() => {}}
              to="/customdashboards/create"
            >
              <div
                className={`py-2 w-100 d-flex flex-row align-items-center justify-content-start ${
                  !dashboardsList.length ? 'border-bottom' : 'pb-4'
                }`}
              >
                <Icon name="add" size="medium" className="mx-2" />
                Create New Dashboard
              </div>
            </Nav.Link>
            {dashboardsList?.customDashboards?.length > 0 ? (
              dashboardsList.customDashboards?.map(({ id, name }, i) => (
                <Nav.Link
                  key={`${id}-${name}`}
                  as={Link}
                  className="list-nav-link"
                  onClick={() => {
                    getTrackingEvent({
                      time: formatDate(toUTC(newDate())),
                      page: 'Home Page',
                      action: 'Home page loaded',
                    });
                    userTracking.logEvent(`Dashboard: Opened dashboard`, {
                      dashboardId: id,
                      dashboardName: name,
                      isShared: false,
                      from: 'Home',
                    });
                  }}
                  to={`/customdashboards/${id}`}
                >
                  <div
                    className={`py-2 w-100 d-flex flex-row align-items-center justify-content-start ${
                      i !== dashboardsList.length - 1 ? 'border-bottom' : 'pb-4'
                    }`}
                  >
                    <Icon name="dashboard" size="medium" className="mx-2" />
                    {name}
                  </div>
                </Nav.Link>
              ))
            ) : (
              <div className="d-flex flex-row align-items-center justify-content-center">
                <h5 className="text-muted">No custom dashboards. Start by creating one.</h5>
              </div>
            )}
          </div>
        </div>
      </Col>
    </Quad>
  );
};

export default withRouter(connect()(CustomListPreview));

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import '@intus-ui/components/Buttons/Button.scss';

// A button that is used for tabs throughout our app
const TabButton = ({ name, hoverText, onClick, active }) => {
  const [isPressed, setIsPressed] = useState(active);
  const onClickExt = () => {
    setIsPressed(!isPressed);
    onClick();
  };

  return (
    <button
      type="button"
      aria-pressed={isPressed}
      title={hoverText}
      aria-label={hoverText}
      name={name}
      className={`default-button tabButton ${active ? ' active' : ' '}`}
      onClick={onClickExt}
      onKeyPress={onClickExt}
    >
      <span className="font-weight-bold">{name}</span>
    </button>
  );
};

TabButton.propTypes = {
  name: PropTypes.string.isRequired,
  hoverText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

TabButton.defaultProps = {
  active: false,
};

export default TabButton;

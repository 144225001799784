/* eslint-disable @typescript-eslint/ban-types */
import {
  Button,
  CollapsibleTabsDrawer,
  Input,
  MonthPicker,
  SpinnerOrError,
  Text,
  layout,
  useParticipantFilters,
  MoreActionsMenu,
} from '@intus-ui';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { getAdmitsFeedData, getAdmitsInsightsData } from '@api/admits';
import { useLazyQuery } from '@api/useQuery';
import { formatDate, getEndOfMonth } from '@util/dateFunctions';
import AnalyticsTab from 'Admits/Insights/AnalyticsTab';
import FrequentFlyerTab from 'Admits/Insights/FrequentFlyerTab';
import { logEvent } from '@util/fullstory';
import { Provider, useAdmitsContext } from './Context';
import { CurrentlyAdmittedTable, DischargedTable } from './tables';
import { TeamFacilityFilterFragmented } from '../@intus-ui/components/filters/TeamFacilityFilterFragmented';
import downloadAdmitsExcelData from './downloadAdmitsExcelData';

const AdmitsContents = () => {
  const {
    admitTypesList,
    admitsType,
    setAdmitsType,
    endDate,
    setEndDate,
    startDate,
    setStartDate,
    setAdmitsContextData,
    admitsContextData,
  } = useAdmitsContext();
  const history = useHistory();
  const { path } = useRouteMatch();

  const { selectedFilters } = useParticipantFilters();
  const [collapsibleTabsDrawerState, setCollapsibleTabsDrawerState] = useState({
    open: false,
    minimized: false,
  });

  const { runQuery, data, loading, error } = useLazyQuery(
    ({ startDate, endDate, admitsType, selectedFilters }) =>
      getAdmitsFeedData({
        startDate: formatDate(startDate, 'YYYY-MM-DD'),
        endDate: formatDate(endDate, 'YYYY-MM-DD'),
        admitsType,
        selectedFilters,
      })
  );

  const {
    runQuery: runInsightsQuery,
    data: insigthsData,
    loading: insigthsLoading,
    error: insigthsError,
  } = useLazyQuery(({ startDate, endDate, admitsType, selectedFilters }) =>
    getAdmitsInsightsData({
      startDate: formatDate(startDate, 'YYYY-MM-DD'),
      endDate: formatDate(endDate, 'YYYY-MM-DD'),
      admitsType,
      selectedFilters,
    })
  );

  useEffect(() => {
    runQuery({ startDate, endDate, admitsType, selectedFilters });
    runInsightsQuery({ startDate, endDate, admitsType, selectedFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, startDate, endDate, admitsType]);

  useEffect(() => {
    if (data) {
      setAdmitsContextData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleMenuItemClick = (item: string) => {
    logEvent(`Admits Feed: clicked ${item} option in inpatient type filter.`);
    setAdmitsType(item);
    history.push(`${path}/${item}`);
  };

  const handleChangeEndDate = (date: Date) => {
    setEndDate(date);
  };

  const handleChangeStartDate = (date: Date) => {
    setStartDate(date);
  };

  const moreActionsOptions = [
    {
      option: 'Download',
      onClick: () => {
        downloadAdmitsExcelData(admitsContextData.currentlyAdmitted, admitsContextData.discharged, {
          startDate: formatDate(startDate, 'YYYY-MM-DD'),
          endDate: formatDate(endDate, 'YYYY-MM-DD'),
          admitsType,
          selectedFilters,
        });
        logEvent('Admits Feed: clicked on the Download option.');
      },
    },
  ];

  return (
    <>
      <CollapsibleTabsDrawer
        open={collapsibleTabsDrawerState.open}
        onClickTab={(tab: string) => {
          logEvent(`Admits Feed: Insights: Clicked on ${tab} Tab.`);
        }}
        onClose={() =>
          setCollapsibleTabsDrawerState({ ...collapsibleTabsDrawerState, open: false })
        }
        minimized={collapsibleTabsDrawerState.minimized}
        onMinimize={() =>
          setCollapsibleTabsDrawerState({
            ...collapsibleTabsDrawerState,
            minimized: !collapsibleTabsDrawerState.minimized,
          })
        }
        tabs={[
          {
            tab: 'Analytics',
            content: (
              <AnalyticsTab data={insigthsData} loading={insigthsLoading} error={insigthsError} />
            ),
          },
          {
            tab: 'High Utilizers',
            content: (
              <FrequentFlyerTab
                data={insigthsData}
                loading={insigthsLoading}
                error={insigthsError}
              />
            ),
          },
        ]}
        height="80vh"
        header={null}
        // eslint-disable-next-line react/no-children-prop
        children={null}
      />

      <div
        style={{
          ...layout.container,
          height: '100%',
        }}
      >
        {loading || error ? (
          <SpinnerOrError error={error ? 'An error occured' : undefined} />
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: 'auto',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                padding: '10px 20px 0 20px',
              }}
            >
              <Text
                type="title"
                style={{ height: 70, marginTop: 5, marginRight: 10, flexShrink: 0 }}
              >
                {admitsType}
              </Text>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  backgroundColor: 'white',
                  flexGrow: 1,
                  justifyContent: 'end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'end',
                    alignItems: 'start',
                    flexWrap: 'wrap',
                    marginBottom: -10,
                  }}
                >
                  <Input
                    fluid
                    innerLabel="Type:"
                    id="typeSelector"
                    type="select"
                    rightIcon="caret-down"
                    name="typeSelector"
                    options={admitTypesList}
                    value={admitsType}
                    onChange={handleMenuItemClick}
                    disabled={false}
                    style={{ order: -1 }}
                  />
                  <TeamFacilityFilterFragmented
                    onClickDropdown={(filterType: any) => {
                      logEvent(`Admits Feed: clicked on the ${filterType} filter dropdown.`);
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                      alignItems: 'center',
                      justifyContent: 'end',
                      zIndex: 50,
                      order: 1,
                    }}
                  >
                    <MonthPicker
                      dateValue={startDate}
                      minDate={new Date('2010-03-01')}
                      maxDate={endDate}
                      onChange={handleChangeStartDate}
                      onFocus={() => {
                        logEvent(`Admits Feed: clicked on the start date filter dropdown.`);
                      }}
                    />
                    <Text>to</Text>
                    <MonthPicker
                      dateValue={endDate}
                      minDate={startDate}
                      maxDate={getEndOfMonth(new Date())}
                      onChange={handleChangeEndDate}
                      onFocus={() =>
                        logEvent(`Admits Feed: clicked on the end date filter dropdown.`)
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', gap: '10px', margin: '0px 10px 0px 10px' }}>
                <Button
                  onClick={() => {
                    logEvent(`Admits Feed: clicked on the 'Insights' button.`);
                    setCollapsibleTabsDrawerState({
                      ...collapsibleTabsDrawerState,
                      open: !collapsibleTabsDrawerState.open,
                    });
                  }}
                >
                  Insights
                </Button>
                <MoreActionsMenu options={moreActionsOptions} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '0px 20px',
                flex: 1,
                gap: 5,
              }}
            >
              <Text type="subtitle">Currently Admitted</Text>
              <CurrentlyAdmittedTable data={admitsContextData.currentlyAdmitted} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '0px 20px',
                flex: 1,
                gap: 5,
              }}
            >
              <Text type="subtitle">Discharged</Text>
              <DischargedTable data={admitsContextData.discharged} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Admits = () => {
  return (
    <Provider>
      <AdmitsContents />
    </Provider>
  );
};
export default Admits;

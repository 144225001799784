import { chain } from 'lodash';
import { useState } from 'react';

import { formatDate } from '@util/dateFunctions';

import { Text, Tag, Icon, SingleSelect, Modal } from '@intus-ui';

const WarningSignalsTab = () => {
  const data = [
    {
      id: 1,
      eventType: 'warningSignal',
      date: '9/18/2023',
      patient: {
        id: 1,
        name: 'Jamil Harding',
      },
      status: 'Open',
      tag: {
        text: 'CRASH',
        color: 'orange',
      },
      state: {
        current: 'COPD',
        change: 'new diagnosis of',
        changeValue: 'hypoxia',
      },
    },
    {
      id: 2,
      eventType: 'warningSignal',
      date: '9/18/2023',
      patient: {
        id: 1,
        name: 'Lilly Schmidt',
      },
      status: 'Open',
      tag: {
        text: 'CAPP',
        color: 'purple',
      },
      state: {
        current: 'CHF',
        change: 'new onset',
        changeValue: 'incontinence',
      },
    },
    {
      id: 3,
      eventType: 'warningSignal',
      date: '9/15/2023',
      patient: {
        id: 1,
        name: 'Timothy McMahon',
      },
      status: 'Closed',
      tag: {
        text: 'CRASH',
        color: 'orange',
      },
      state: {
        current: 'COPD',
        change: 'new diagnosis of',
        changeValue: 'dyspnea at rest',
      },
    },
    {
      id: 4,
      eventType: 'warningSignal',
      date: '9/13/2023',
      patient: {
        id: 1,
        name: 'Otto Cordova',
      },
      status: 'Closed',
      tag: {
        text: 'CAPP',
        color: 'purple',
      },
      state: {
        current: 'COPD',
        change: 'new order for',
        changeValue: '24 hour oxygen',
      },
    },
    {
      id: 5,
      eventType: 'warningSignal',
      date: '9/13/2023',
      patient: {
        id: 1,
        name: 'Kelvin Sweeney',
      },
      status: 'Open',
      tag: {
        text: 'CRASH',
        color: 'orange',
      },
      state: {
        current: 'CHF',
        change: 'new diagnosis of',
        changeValue: 'hypoxia',
      },
    },
  ];

  const eventGroups = chain(data)
    .groupBy('date')
    .values()
    // .orderBy((a) => new Date(a[0].date), 'desc')
    .value();

  return (
    <div style={styles.box}>
      {eventGroups.map((eventGroup) => {
        return <EventGroup key={JSON.stringify(eventGroup)} eventGroup={eventGroup} />;
      })}
      {/* <DisenrollmentPopup /> */}
    </div>
  );
};

const EventGroup = ({ eventGroup }) => {
  const { date } = eventGroup[0];

  return (
    <div
      key={`${JSON.stringify(eventGroup)}`}
      data-group-date={formatDate(date, 'YYYY-MM-DD')}
      style={{ marginBottom: 10 }}
    >
      <div style={styles.groupHeaderContainer}>
        <Text color="black">{formatDate(date, 'MM/DD/YYYY')}</Text>
        <hr style={styles.hr} />
      </div>
      <div style={styles.eventsContainer}>
        {eventGroup.map((event) => {
          return <EventCard item={event} key={JSON.stringify(event)} />;
        })}
      </div>
    </div>
  );
};

const EventCard = ({ item }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div className="acuity-sidebar-item" style={styles.card}>
      {/* MODAL */}
      <Modal
        header={{
          title: item.patient.name,
          centered: true,
        }}
        type="small"
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <div style={styles.description}>
            <Text>has</Text>
            <Text type="subtitle">&nbsp;{item.state.current}&nbsp;</Text>
            <Text>and {item.state.change}</Text>
            <Text type="subtitle">&nbsp;{item.state.changeValue}</Text>
          </div>
          <div style={styles.description}>
            <Text type="subtitle">Recommendation: Rapid clinical re-evaluation</Text>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              width: '100%',
              borderRadius: 10,
              backgroundColor: '#E4ECFF',
              padding: 10,
              border: '1px solid #BACEFF',
            }}
          >
            <Text type="caption">Read more:</Text>
            <Text type="caption">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" target="_blank">
                Potentially avoidable hospitalizations of dually eligible Medicare and Medicaid
                beneficiaries from nursing facility and Home- and Community-Based Services waiver
                programs - NIH
              </a>
            </Text>
            <Text type="caption">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" target="_blank">
                AHRQ Guide to Prevention Quality Indicators: Hospital Admission for Ambulatory Care
                Sensitive Conditions - AHRQ
              </a>
            </Text>
          </div>
          <div style={{ ...styles.footer, justifyContent: 'flex-end' }} role="none">
            <div onClick={() => {}} role="none">
              <Text type="subtitle" color="link">
                See more on {item.patient.name.split(' ')[0]}
              </Text>
              <Icon size="small" name="arrow-right" color="link" style={{ fontWeight: 'bold' }} />
            </div>
          </div>
        </div>
      </Modal>

      {/* CARD */}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 5 }}>
        <div style={styles.cardTitle}>
          <Text type="subtitle">{item.patient.name}</Text>
          <Tag type="intusMade" color={item.tag.color}>
            {item.tag.text}
          </Tag>
        </div>
        <div style={styles.description}>
          <Text>has</Text>
          <Text type="subtitle">&nbsp;{item.state.current}&nbsp;</Text>
          <Text>and {item.state.change}</Text>
          <Text type="subtitle">&nbsp;{item.state.changeValue}</Text>
        </div>
        <div style={styles.footer} role="none">
          <div>
            <SingleSelect
              rightIcon
              options={['All', 'Open', 'Closed']}
              defaultValue={item.status}
            />
          </div>
          <div onClick={() => setModalIsOpen(true)} role="none">
            <Text type="subtitle" color="link">
              See Details
            </Text>
            <Icon size="small" name="arrow-right" color="link" style={{ fontWeight: 'bold' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  box: {
    padding: '10px 15px 0 15px',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  groupHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'between',
    alignItems: 'center',
    marginBottom: 10,
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    marginBottom: 20,
  },
  hr: {
    height: 0,
    width: '100%',
    margin: '0 0 0 15px',
    backgroundColor: '#C8C8C8',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    cursor: 'pointer',
    padding: '10px 15px',
    borderRadius: 5,
  },
  acuityCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    cursor: 'pointer',
    padding: '10px 15px',
    borderRadius: 5,
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    flex: 1,
    width: '100%',
  },
  listItem: {
    display: 'flex',
    alignItems: 'start',
  },
  listIcon: {
    color: '#97999b',
    width: 22,
    height: 22,
  },
  listText: {
    marginLeft: 10,
    lineHeight: '19.5px',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  listChanges: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    width: '100%',
  },
  bulletText: {
    lineHeight: '19.5px',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  description: {
    // display: 'flex',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default WarningSignalsTab;

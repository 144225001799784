import React, { useEffect, useState } from 'react';
import { useSelector } from '@global-state';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { dateDifference } from '@util/dateFunctions';
import { getLogger } from '@util/logger';

import { getAcuityLevelChanges } from '@api';

import { layout, Text, SpinnerOrError, Icon } from '@intus-ui';

import '@intus-ui/styles/Profile.scss';
import '@intus-ui/styles/Utilities.scss';
import SimpleTooltip from '@intus-ui/components/SimpleTooltip';
import ProfileAcuityDetails from '../ProfileAcuityDetails';

const log = getLogger('ProfileCurrentAcuities');

export const PatientIntusLabelDiagnoses = () => {
  const patient = useSelector((state: any) => state.patientDetail.patient);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [acuityLevelChanges, setAcuityLevelChanges] = useState([]);
  // fetch acuityLevelChanges
  useEffect(() => {
    const ac = new AbortController();
    let { aborted } = ac.signal;
    // Past 5 acuities for profile breakdown
    getAcuityLevelChanges(patient.id, 5)
      .then(async (result) => {
        if (result.ok) {
          const json = await result.json();
          const levelChanges = json.map((assessment: any) => ({
            ...assessment.intusLabels,
          }));
          aborted = ac.signal.aborted;
          if (aborted === false) {
            setAcuityLevelChanges(levelChanges);
          }
        } else {
          setError('Unable to fetch Acuity data available for this participant.');
        }
        setLoading(false);
      })
      .catch((e) => {
        setError('Unable to fetch Acuity data available for this participant.');
        setLoading(false);
        log.error(e);
      });
    return () => {
      ac.abort();
      setAcuityLevelChanges([]);
    };
  }, [patient.id]);

  const acuityLevels = [...patient.acuityLevels];
  acuityLevels.sort((a, b) => dateDifference(b.createdAt, a.createdAt));

  if (loading) return <SpinnerOrError />;

  if (error || acuityLevelChanges.length === 0) {
    return (
      <div style={styles.centerNoData}>
        <Text type="caption">{error || 'No Acuity data available for this participant.'}</Text>
      </div>
    );
  }

  return (
    <ProfileAcuityDetails acuityLevelChanges={acuityLevelChanges} acuityLevel={acuityLevels[0]} />
  );
};

const styles = {
  centerNoData: { ...layout.centeredContentContainer, alignItems: 'flex-start' },
} satisfies InlineCss;

export const IntusLabelDiagnosesTooltip = () => {
  const simpleTooltip = () => (
    <div className="d-flex flex-column justify-content-center align-items-start rounded bg-white p-2 small">
      <h6 className="text-primary pt-1 pb-3">Key:</h6>
      <div className="text-black d-flex flex-column align-items-start pb-2">
        <p className="py-1 pr-3" style={{ backgroundColor: 'rgba(148,200,249,.17)' }}>
          <Icon name="Bullet" className="text-muted mx-2" />
          Newly Added
        </p>
        <p>
          <Icon name="Bullet" className="text-muted mx-2" />
          Currently active
        </p>
        <p className="py-1 ">
          <Icon name="Bullet" className="text-muted mx-2" />
          <del>Removed</del>
        </p>
      </div>
    </div>
  );
  const keyTooltip = (
    <div className="d-flex flex-row align-items-center justify-content-start w-100">
      <SimpleTooltip content={simpleTooltip()}>
        <InfoIcon className="info-icon cursor-pointer m-0" />
      </SimpleTooltip>
    </div>
  );

  return keyTooltip;
};

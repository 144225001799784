import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import { TeamFacilityFilter } from '@intus-ui/components/filters/TeamFacilityFilter';
import Text from '@intus-ui/components/Text';
import { useMediaQuery } from '@mui/material';

// Do not add feature-specific code to this component. It should remain as general as possible.

// TabPageHeader contains the styling and layout for the headers on the tab pages (ex. Ppt, Cohorts, Dashboards)
// May need to be altered for added button/icon extensibility for Cohorts and Dashboard in future.
const TabPageHeader = ({ title, children }) => {
  const isScreenBreak = useMediaQuery('(max-width:743px)');

  return (
    <Row className="w-100 bg-light align-items-center justify-content-between align-items-center pt-3 px-3 m-0">
      <div
        className="d-flex flex-row justify-content-center align-items-center pl-3"
        style={{ gap: '10px', marginBottom: isScreenBreak ? '10px' : '0', marginRight: '10px' }}
      >
        <Text type="title" color="navy">
          {title}
        </Text>
        {children}
      </div>
      <div className="d-flex flex-row pr-1">
        <TeamFacilityFilter />
      </div>
    </Row>
  );
};

TabPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

TabPageHeader.defaultProps = {
  children: undefined,
};

export default TabPageHeader;

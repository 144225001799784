import { useEffect } from 'react';

const useOutsideClick = (ref, callback) => {
  const handleClick = (event) => {
    let refArray = ref;
    if (!Array.isArray(ref)) {
      refArray = [ref];
    }

    if (refArray.every((refItem) => didClickOutsideRef(refItem, event))) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

function didClickOutsideRef(ref, event) {
  if (ref.current && !ref.current.contains(event.target)) {
    return true;
  }
  return false;
}

export default useOutsideClick;

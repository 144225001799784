const payload = {
  events: [
    {
      type: 'progressNote',
      title: 'New progress note',
      keywords: ['chest tightness', 'shortness of breath'],
      date: { latest: '01/10/2023', lastChange: '01/5/2023' },
    },
    {
      type: 'CRI',
      title: 'Sitting Blood Pressure',
      code: 'BP',
      current: {
        value: '140/74',
        severity: 'high',
        date: '01/10/2023',
      },
      previous: {
        value: '140/74',
        severity: 'low',
        date: '01/09/2023',
      },
    },
    {
      title: 'Hemoglobin',
      code: 'HA1C',

      current: {
        value: 6.8,
        severity: 'low',
        date: '01/10/2023',
      },
      previous: {
        value: 5.9,
        severity: 'low',
        date: '01/09/2023',
      },
    },
    {
      title: 'BMI',
      code: 'BMI',
      current: {
        value: 18.5,
        severity: 'low',
        date: '01/10/2023',
      },
      previous: {
        value: 24.9,
        severity: 'low',
        date: '01/09/2023',
      },
    },
  ],
  timeSeries: {
    BMI: {
      title: 'Body Mass Index',
      data: [
        { date: '01/10/2023', value: 16.4 },
        { date: '01/10/2023', value: 16.4 },
      ],
    },
  },
};

export default payload;

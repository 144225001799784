import React from 'react';
import Input from '@intus-ui/components/Input';
import { dateDifference, formatDate, isSameDate, newDate } from '@util/dateFunctions';
import { monthsOptions } from '@intus-ui/components/DateRange/config';

const DateRangeDropdown = (props) => {
  const { onApplyRange, onCancelRange, options, range, value, ...rest } = props;

  return (
    <Input
      id="input-daterange-picker"
      name="input-daterange-picker"
      type="select"
      rightIcon="caret-down"
      options={options}
      range={range}
      value={value}
      onChange={onApplyRange}
      onApplyRange={onApplyRange}
      onCancelRange={onCancelRange}
      withCustomDateRanges
      style={{ width: 210 }}
      {...rest}
    />
  );
};

export default DateRangeDropdown;

/** Converts the selected dates into text to display in the <DateRangeDropdown /> component. */
export function formatDateRangeText(range, startDate, endDate) {
  const daysDifference = dateDifference(endDate, startDate, 'days');

  if (isSameDate(newDate(), endDate, 'day')) {
    for (const rangeOption of range) {
      const possibleRange = monthsOptions[rangeOption];

      const rangeDaysDifference = dateDifference(possibleRange.end, possibleRange.start, 'days');

      if (daysDifference === rangeDaysDifference) {
        return possibleRange.label;
      }
    }
  }

  return `${formatDate(startDate, 'MM/DD/YY')} - ${formatDate(endDate, 'MM/DD/YY')}`;
}

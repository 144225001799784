import { uniqueId } from 'lodash';
import { Pill, Text, Icon } from '../index';
import TooltipComponent from '../TooltipComponent/index';

export const formatMedications = (data) => {
  const formattedMedications = data.map((item) => {
    const {
      name,
      pharmClassEpc,
      dosage,
      dateOrdered,
      dateFilled,
      sig,
      fallsRiskFactors,
      changedValues,
    } = item;

    return {
      name,
      fdaClass: pharmClassEpc?.length > 0 ? pharmClassEpc.join(', ') : '-',
      dosage:
        dosage?.length > 0 ? (
          <ValueWithTooltip
            value={dosage}
            previousValue={changedValues?.dosage}
            anchorId={uniqueId()}
            textAlign="right"
          />
        ) : (
          '-'
        ),
      sig: (
        <ValueWithTooltip
          value={sig}
          previousValue={changedValues?.sig}
          anchorId={uniqueId()}
          textAlign="left"
        />
      ),
      dateOrdered,
      dateFilled,
      riskFactors: fallsRiskFactors?.map((riskFactor) => (
        <Pill id={JSON.stringify(item + riskFactor)}>
          <Text type="subtitle">{`Falls - ${riskFactor}`}</Text>
        </Pill>
      )),
    };
  });
  return formattedMedications;
};

const ValueWithTooltip = ({ value, previousValue, anchorId, textAlign }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        alignItems: 'start',
        textAlign,
        gap: '5px',
      }}
    >
      <Text>{value}</Text>
      {/* Adding a tooltip if there was a change in sig */}
      {previousValue && (
        <TooltipComponent
          anchorId={anchorId}
          content={
            <>
              <Text type="subtitle"> Previously:</Text> <Text type="body"> {previousValue}</Text>{' '}
            </>
          }
        >
          <Icon name="info" color="link" />
        </TooltipComponent>
      )}
    </div>
  );
};

import { isEmpty } from 'lodash';
import pluralize from 'pluralize';

import { toSentenceCase } from '@util';
import { getSessionUser } from '@util/session';

type ReplacementOptions = {
  /** Convert the string to plural with the pluralize library. */
  plural?: boolean;
  /** Convert the string to lowercase. */
  lower?: boolean;
  /** Convert the string to sentence case. */
  sentenceCase?: boolean;
};

type ReplaceKeys = 'Team' | 'Center' | 'Participant';

/**
 * Module that allows users to replace certain strings (like Team or Facility) with terms that are specific
 * to their organization.
 *
 * E.x. a customer can replace the word Team with Nurse Practitioner or the word Participant with Patient.
 */
export function getStringReplacement(key: ReplaceKeys, options: ReplacementOptions = {}) {
  const str = findStringToDisplay(key);

  return applyStringReplacementOptions(str, options);
}

/**
 * Checks if the user has a string replacement and if they do returns that string.
 * If they do not this just sends the unaltered defaultText.
 *
 * @param key - The unique key that identifies this string (Team, Center, Participant). Used as the default text if there is no replacement for this user.
 */
function findStringToDisplay(key: ReplaceKeys) {
  const user = getSessionUser();

  if (!user) {
    return key;
  }

  if (!user.overrides?.stringReplacements) {
    return key;
  }

  const replacementString = user.overrides.stringReplacements[key];

  if (isEmpty(replacementString)) {
    return key;
  }

  return replacementString;
}

/**
 * @param replacementString - The string to modify.
 * @param options
 */
function applyStringReplacementOptions(
  replacementString: string,
  options: ReplacementOptions = {}
) {
  if (!options || !replacementString) {
    return replacementString;
  }

  let str = replacementString;

  if (options.lower === true) {
    str = str.toLowerCase();
  }

  if (options.sentenceCase === true) {
    str = toSentenceCase(str);
  }

  if (options.plural === true) {
    str = pluralize(str);
  }

  return str;
}

import { useState } from 'react';

import { useQuery } from '@api/useQuery';
import { getTrialMetrics } from '@api/initiatives';
import { Modal, layout, SpinnerOrError, Text, ButtonGroup, Button } from '@intus-ui';
import produce from 'immer';

const AddEditMetricsModal = ({ open, onClose, handleAddMetrics, metrics }) => {
  const { data = [], loading, error } = useQuery(() => getTrialMetrics());
  const [selectedMetrics, setSelectedMetrics] = useState([...metrics]);

  // CHECKING IF AN OPTION IS SELECTED
  const isSelectedMetric = (metric) => {
    return !!selectedMetrics.find((m) => m.id === metric.id);
  };

  //  MARKING AN OPTION AS SELECTED OR UNSELECTED
  const selectMetric = (checked, metric) => {
    if (checked) {
      const newMetrics = produce(selectedMetrics, (draft) => {
        const index = draft.findIndex((m) => m.id === metric.id);
        draft.splice(index, 1);
      });

      setSelectedMetrics(newMetrics);
    } else {
      setSelectedMetrics([...selectedMetrics, metric]);
    }
  };

  // ADDING METRICS TO THE FORM
  const addMetrics = (selected) => {
    handleAddMetrics(selected);
    onClose();
  };

  return (
    <Modal
      header={{ title: 'Select Metrics', centered: true }}
      open={open}
      onClose={onClose}
      type="small"
      style={styles.container}
    >
      {loading && <SpinnerOrError />}
      {error && <Text color="error"> There was an error getting metrics. </Text>}
      {!loading && !error && (
        <div style={styles.content}>
          <Text>Select metrics that can help you monitor the impact of your initiatives.</Text>
          {data?.map((metric) => (
            <div
              key={metric.id}
              onClick={() => selectMetric(isSelectedMetric(metric), metric)}
              role="none"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItem: 'center',
                gap: '5px',
                cursor: 'pointer',
                width: 'fit-content',
              }}
            >
              <input
                type="checkbox"
                aria-label={`${metric.name}`}
                checked={isSelectedMetric(metric)}
                onChange={() => {
                  selectMetric(isSelectedMetric(metric), metric);
                }}
                id={`initiatives-add-metric-checkbox-${metric.id}`}
              />
              {metric.name}
            </div>
          ))}
        </div>
      )}
      <ButtonGroup>
        <Button secondary onClick={onClose} id="initiatives-cancel-add-metrics-button">
          Cancel
        </Button>
        <Button
          disabled={selectedMetrics.length === 0}
          onClick={() => addMetrics(selectedMetrics)}
          id="initiatives-update-metrics-button"
        >
          Update Metrics
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

const styles = {
  container: {
    ...layout.container,
    height: '100%',
  },
  content: { ...layout.container, overflowY: 'auto', padding: '20px 15px 20px 15px', gap: 20 },
};

export default AddEditMetricsModal;

import React, { useState, useEffect, useRef } from 'react';

import { searchFilter } from '@util/utilFunctions';
import { useOutsideClick } from '@intus-ui/hooks';

import DropdownList from '@intus-ui/components/Dropdown/DropdownList';
import DropdownItem from '@intus-ui/components/Dropdown/DropdownItem';

import SearchInput from '../SearchInput';

const SearchInputDropdown = (props) => {
  const {
    style,
    suggestionLabel,
    noMatchLabel = '',
    searchTerm,
    onChange,
    options,
    onSearch,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const dropDownRef = useRef(null);
  // Closes dropdown when clicked outside
  useOutsideClick(dropDownRef, () => {
    setIsCollapsed(false);
  });

  useEffect(() => {
    if (searchKeyword === '') {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(filteredOptions);
    }
  }, [filteredOptions, options]);

  useEffect(() => {
    setSearchKeyword(searchTerm);
  }, [searchTerm]);

  const handleOnChange = (e) => {
    const keyword = e.target.value;
    setIsCollapsed(true);
    const filterResults = searchFilter(keyword, options);
    const match = filterResults.find((el) => el.toLowerCase() === keyword.toLowerCase());
    setFilteredOptions(filterResults);
    setSearchKeyword(match);
    onSearch(keyword);
  };

  const handleOnCloseExt = () => {
    onSearch('');
    setFilteredOptions(options);
  };
  const onFocus = () => {
    setIsCollapsed(true);
  };

  return (
    <div
      id="search-input-dropdown"
      name="search-input-dropdown"
      ref={dropDownRef}
      style={{
        width: 'max-content',
        inlineSize: 'max-content',
        blockSize: 'max-content',
        whiteSpace: 'nowrap',
        position: 'relative',
        ...style,
      }}
    >
      <SearchInput
        {...props}
        value={searchKeyword}
        handleSearch={handleOnChange}
        onClose={handleOnCloseExt}
        caption={undefined}
        onFocus={onFocus}
        closeIcon
      />

      {isCollapsed && (
        <DropdownList styles={{ marginTop: 4, width: '100%', overflowY: 'auto' }}>
          {filteredOptions.length === 0 ? (
            <DropdownItem empty suggestionLabel={suggestionLabel} noMatchLabel={noMatchLabel} />
          ) : (
            filteredOptions.map((option, i) => {
              const key = i;

              return (
                <DropdownItem
                  key={key}
                  index={i}
                  suggestionLabel=""
                  option={option}
                  onSelect={onChange}
                  setIsCollapsed={setIsCollapsed}
                />
              );
            })
          )}
        </DropdownList>
      )}
    </div>
  );
};

export default SearchInputDropdown;

/*
*****
data formats for each filter row component
*****
*/

export const FilterInputSelect = {
  type: undefined,
  searchTerm: undefined,
  logic: '=',
};

export const FilterSearchDropdown = {
  type: undefined,
  searchTerm: undefined,
  logic: '=',
};

export const MedicationClassDropdown = {
  type: undefined,
  searchTerm: undefined,
  logic: '=',
};

export const FilterInputText = {
  type: undefined,
  searchTerm: undefined,
  logic: 'contains',
};

export const FilterTimeRange = {
  logic: '=',
  frequency: {
    gte: 1,
  },
  dateRange: {
    weeks: 0,
    days: 30,
  },
};

export const FilterBetweenRange = {
  frequency: {
    gte: 1,
    // when both lte and gte are present the operator is between
  },
};

export const FilterBetweenSelectRange = {
  logic: '=',
  frequency: {
    gte: 1,
    unit: 'years',
  },
};

export const FilterInputSelectWithOptions = {
  type: undefined,
  searchTerm: undefined,
  logic: undefined,
  dateRange: {
    months: 12,
  },
};

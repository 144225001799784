import React from 'react';
import { Modal } from 'react-bootstrap';

// This is the modal with the white background, Header
const IntusPopup = ({ open, setOpen, title, body }) => {
  if (!open) return null;

  const hide = () => {
    setOpen(false);
  };
  // TODO: decide on if we want to keep the styling for this default modal in bootstrap.scss (currently under comment  Overriding bootstrap modal default styling' in Bootstrap.scss)
  return (
    <Modal
      dialogClassName="min-w-50 zindex-1040"
      role="dialog"
      show={!!open}
      onHide={hide}
      scrollable
      aria-labelledby={`${title} dialog`}
    >
      <Modal.Header className="py-0 pl-4 pt-3 m-0" closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="my-0 mx-2" style={{ height: '100vh' }}>
        <div className="w-100 h-100">{body()}</div>
      </Modal.Body>
    </Modal>
  );
};

export default IntusPopup;

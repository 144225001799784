import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { getStringReplacement } from '@util/stringReplacements';
import { toUTC, newDate, formatDate } from '@util/dateFunctions';
import { useGetSessionUser } from '@util/session';

import { getTrackingEvent, postPatientListAPI } from '@api/api';
import { mapPatient } from '@api/patients/mapPatient';
import { useQuery } from '@api/useQuery';

import { SpinnerOrError, useParticipantFilters } from '@intus-ui';
import ParticipantList from '@intus-ui/components/ParticipantList';
import {
  pinnedPptCollapsedConfig,
  pinnedPptConfig,
} from '@intus-ui/components/ParticipantList/list/columnConfigs';

import '@intus-ui/styles/FilteredList.scss';
import '@intus-ui/styles/Utilities.scss';
import '@intus-ui/styles/Login.scss';
import '@intus-ui/styles/WelcomeFlow.scss';

const PinnedParticipantsTab = ({ compactView }) => {
  const history = useHistory();

  const user = useGetSessionUser();

  const pinnedPptIds = user?.setting?.pinnedParticipants ?? [];

  const { selectedFilters } = useParticipantFilters();

  const { data, loading, runQuery } = useQuery(() =>
    postPatientListAPI({ patientList: pinnedPptIds, filters: selectedFilters })
  );

  useEffect(() => {
    runQuery();
  }, [pinnedPptIds.join(','), selectedFilters]);

  const pinnedPatients = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((patient) => mapPatient(patient));
  }, [data, selectedFilters]);

  const onClickItem = (item) => {
    getTrackingEvent({
      time: formatDate(toUTC(newDate())),
      page: 'HomePage',
      action: 'Viewing participant detail from Pinned participant tab',
    });
    history.push(`/patient/${item.id}/current-acuity`);
  };

  if (loading || !user) {
    return <SpinnerOrError />;
  }

  let emptyListMessage = `You have not pinned any ${getStringReplacement('Participant', {
    lower: true,
    plural: true,
  })}, pin by clicking the edit icon above.`;
  if (pinnedPptIds?.length !== 0) {
    emptyListMessage = `There are no pinned ${getStringReplacement('Participant', {
      lower: true,
      plural: true,
    })} that match the filter criteria.`;
  }

  return (
    <div style={styles.box}>
      <ParticipantList
        style={{ backgroundColor: 'none' }}
        customColumnConfig={compactView ? pinnedPptCollapsedConfig : pinnedPptConfig}
        participantList={pinnedPatients}
        onClickItem={onClickItem}
        searchable={false}
        titleRowElement={null}
        topRightElementsList={null}
        emptyListMessage={emptyListMessage}
      />
    </div>
  );
};

const styles = {
  box: {
    width: '100%',
    height: '100%',
  },
};

PinnedParticipantsTab.propTypes = {
  compactView: PropTypes.bool,
};

PinnedParticipantsTab.defaultProps = {
  compactView: false,
};

export default PinnedParticipantsTab;

import { useState } from 'react';
import PropTypes from 'prop-types';

import Text from '../Text';

import './Button.scss';

// A button that takes in text and and optional icon
// has primary and secondary default styling according to style guide
const GeneralButton = ({ name, hoverText, onClick, children, disabled, secondary, ...rest }) => {
  const [isPressed, setIsPressed] = useState(false);

  const onClickExt = () => {
    setIsPressed(!isPressed);
    // eslint-disable-next-line no-unused-expressions
    onClick && onClick();
  };

  return (
    <button
      type="button"
      aria-pressed={isPressed}
      title={hoverText}
      aria-label={hoverText}
      name={name}
      className={`default-button ${secondary ? 'secondary-button' : 'primary-button'}`}
      onClick={() => (disabled ? null : onClickExt())}
      onKeyPress={() => (disabled ? null : onClickExt())}
      disabled={disabled}
      {...rest}
    >
      <Text type="body" {...rest.textprops}>
        {name || children}
      </Text>
    </button>
  );
};

GeneralButton.propTypes = {
  name: PropTypes.string.isRequired,
  hoverText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

GeneralButton.defaultProps = {
  children: undefined,
};

export default GeneralButton;

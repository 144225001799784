import React from 'react';
import { toPascalCase } from '@util/utilFunctions';

import * as icons from '@intus-ui/icons';
import { textColors } from '@intus-ui/styles/SecondaryColors';
import useHover from '@intus-ui/hooks/useHover';

type IconProps = {
  name: keyof typeof icons;
  color?: keyof typeof textColors | string;
  hoverColor?: string;
  onClick?: () => void;
  size?: 'extra-large' | 'large' | 'medium' | 'small' | 'tiny';
  style?: React.CSSProperties;
  className?: string;
};

const Icon = (props: IconProps) => {
  const { name, color, hoverColor, onClick, size, style = {}, ...rest } = props;
  const [hoverRef, isHovered] = useHover();

  let scale = 1;
  if (size === 'extra-large') scale = 3.0;
  if (size === 'medium') scale = 0.85;
  if (size === 'small') scale = 0.7;
  if (size === 'tiny') scale = 0.55;

  const all = {
    ...rest,
    onClick,
  };

  const colorStyle = style.color || textColors[color as keyof typeof textColors] || color;

  const styles = {
    transform: `scale(${scale})`,
    ...style,
    color: isHovered && hoverColor ? hoverColor : colorStyle,
    cursor: onClick && 'pointer',
    marginTop: scale < 1 ? -1 : null,
  };

  const IconWrapper = (icons as any)[toPascalCase(name)];

  return <IconWrapper ref={hoverRef} {...all} style={styles} />;
};

export default Icon;

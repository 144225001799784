import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '@global-state';
import { Modal } from 'react-bootstrap';

import { updateUserSettings, getJobTypes } from '@global-state/redux/userSettingsSlice';

import { Centered, GeneralButton, Text, Input } from '@intus-ui';
import { textColors } from '@intus-ui/styles/SecondaryColors';

const Form = ({ onChange, onClick, jobTypes, jobType }) => {
  return (
    <>
      <Centered>
        <Text type="title" style={{ color: textColors.navy }}>
          What is your role at PACE?
        </Text>
      </Centered>

      <Centered className="my-3">
        <Text>We&apos;d love to know who uses our app to design future features better.</Text>
      </Centered>

      <Centered>
        <Input
          id="input-suggestions"
          name="input-suggestions"
          type="text"
          placeholder="Your role at PACE"
          suggestionLabel=""
          value={jobType}
          onChange={onChange}
          onKeyUp={(e) => {
            onChange(e.target.value);
          }}
          options={jobTypes}
          searchable
          style={{ textAlign: 'left' }}
        />
      </Centered>

      <Centered>
        <GeneralButton name="Submit" onClick={onClick} />
      </Centered>
    </>
  );
};

const FormSubmitted = ({ onClick }) => {
  return (
    <>
      <Centered>
        <Text type="title" style={{ color: textColors.navy }}>
          Thank you!
        </Text>
      </Centered>
      <Centered className="my-3">
        <Text>
          Your response has been submitted.
          <br />
          We appreciate it!
        </Text>
      </Centered>

      <Centered>
        <GeneralButton name="Done" onClick={onClick} />
      </Centered>
    </>
  );
};

const UserSettingPromptsModal = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState();
  const [submitted, setSubmitted] = useState();
  const [jobType, setJobType] = useState();

  const profile = useSelector((state) => state.userSettings.profile);
  const jobTypes = useSelector((state) => state.userSettings.jobTypes);

  if (!jobTypes) dispatch(getJobTypes());

  useEffect(() => {
    const needsJobType = profile && !profile.jobType;
    if (!showModal && needsJobType) setShowModal(true);
  }, []);

  const hideModal = () => {
    setShowModal(false);
    if (!submitted) {
      submitForm('UNSELECTED');
    }
  };

  const handleChange = (value) => {
    setJobType(value);
  };

  const submitForm = (value = jobType) => {
    dispatch(updateUserSettings({ ...profile, jobType: value }));
    setSubmitted(value);
  };

  return (
    <Modal show={showModal} onHide={hideModal} centered>
      <Modal.Header closeButton className="pb-0" />
      <Modal.Body style={{ textAlign: 'center' }}>
        {!submitted && (
          <Form
            onChange={handleChange}
            onClick={submitForm}
            jobTypes={jobTypes}
            jobType={jobType}
          />
        )}
        {submitted && <FormSubmitted onClick={hideModal} />}
      </Modal.Body>
    </Modal>
  );
};

export default UserSettingPromptsModal;

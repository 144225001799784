import React from 'react';

// Define a type for the layout styles
type LayoutStyles = {
  [key: string]: React.CSSProperties;
};

const layouts: LayoutStyles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  rowContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
  },
  navbar: { height: '40px', padding: 10, backgroundColor: 'lightblue' },
  content: {
    flex: 1,
    display: 'flex',
    padding: '20px',
    gap: '10px',
    overflowY: 'auto',
  },
  centeredContentContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  // sidebar
  fixedsidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    backgroundColor: 'white',
  },
  sidebarcontent: { overflowY: 'auto' },

  // thinner side drawer
  sideDrawer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '200px',
    height: 'calc(100% - 20px)',
    padding: '20px',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    boxShadow: '0px 0.4px 0.4rem 0.06rem rgba(0, 0, 0, 0.2)',
    backgroundColor: 'white',
  },

  // grid layouts
  gridContainer: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gap: '10px',
    overflowY: 'auto',
  },
  gridItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
  },
  boxShadow: { boxShadow: '0px 0.4px 0.4rem 0.06rem rgba(0, 0, 0, 0.2)' },

  // ideally, we'd like to just spread/base on layouts.container
  // instead of copying the flex basics
  quad: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    backgroundColor: 'white',
    padding: '30px',
    gap: '10px',
    borderRadius: '20px',
    boxShadow: '0px 0.4px 0.4rem 0.06rem rgba(0, 0, 0, 0.2)',
  },
};

export default layouts;

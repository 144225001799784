/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { range } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { chartColors } from './styles';
import { wrapTextForXAxis, formatTooltip } from './helpers';

const ActivityChart = ({ activityData = [], id, maxValue = 'auto' }) => {
  if (!activityData[0].length) return null;

  return (
    <ResponsiveBar
      animate={false}
      margin={{ top: 20, bottom: 30, left: 45 }}
      colors={chartColors[0]}
      label={(label) => {
        if (label.value === 0) return '';
        return label.formattedValue;
      }}
      colorBy="id"
      tooltip={(point) => {
        let labelString = point.indexValue;
        if (point.indexValue !== point.id && point.id !== 'undefined') {
          labelString += `- ${point.id}`;
        }
        labelString += `: ${point.value}`;
        return formatTooltip(labelString, point, id);
      }}
      padding={0.5}
      data={activityData[0]}
      keys={activityData[1]}
      maxValue={maxValue}
      axisLeft={{
        legend: 'Number of Falls',
        legendPosition: 'middle',
        legendOffset: -30,
        enable: true,
        tickSize: 0,
        format: (e) => Math.floor(e) === e && e,
      }}
      gridYValues={range(1, maxValue + 1)}
      innerPadding={1}
      axisBottom={{
        tickSize: 0,
        format: (v) => {
          let lineLimit = 15;
          if (activityData[0].length <= 3) {
            lineLimit = 25;
          }
          if (activityData[0].length === 1) {
            lineLimit = 50;
          }
          const textBreakup = wrapTextForXAxis(v, lineLimit);
          return (
            <>
              <tspan x={0} y={0}>
                {textBreakup[0]}
              </tspan>
              <tspan x={0} y={10}>
                {textBreakup[1]}
              </tspan>
            </>
          );
        },
      }}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: 'rgba(0, 0, 0, .1)',
              strokeWidth: 1,
            },
          },
        },
      }}
    />
  );
};

export default ActivityChart;

/**
 * columnConfigs must have an object with a 'columns' field
 * participant list must have a 'default' key
 */
export const defaultColumnConfig = {
  default: {
    columns: ['name', 'date'],
    breakpoints: [{ minWidth: 0, columns: ['name', 'date'] }],
  },
};

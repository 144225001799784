import React from 'react';
import PropTypes from 'prop-types';

import lg from 'assets/logo.svg';

import '@intus-ui/styles/Login.scss';
import '@intus-ui/styles/Utilities.scss';

/**
 * This component is for a white card on an Intus blue background.
 * It's used for the log in and sign up pages, for example.
 * The name is kind of whacky, but what else would you call it?
 */
const BoxOnBlue = ({ logo, children }) => (
  <div className="blue-background w-100 h-100">
    <div className="d-flex align-items-center justify-content-center blue-background w-100">
      <div className="bg-white p-5 hover-box mx-3 my-5" style={{ width: 537 }}>
        {logo && (
          <div className="d-flex w-100 flex-column align-items-center justify-content-center pb-4">
            <img
              style={{ marginBottom: '5px', height: '100px' }}
              src={lg}
              alt="IntusCare logo"
              className="logo"
            />
            <span>
              <h1 className="text-dark font-weight-normal" style={{ fontSize: '49px' }}>
                Intus
              </h1>
              <h1
                className="text-primary"
                style={{ fontSize: '49px', fontWeight: '600', letterSpacing: 'calc(15px * -0.02)' }}
              >
                Care
              </h1>
            </span>
          </div>
        )}
        {children}
      </div>
    </div>
  </div>
);

BoxOnBlue.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BoxOnBlue;

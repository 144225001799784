import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { editSelectedCaregiver } from '@global-state/redux/caregiverListSlice';

import { Button, Icon } from '@intus-ui';

// eslint-disable-next-line react/prop-types
const CaregiverSettings = ({ caregiverID, editCaregiver }) => (
  <Button
    secondary
    onClick={() => {
      editCaregiver(caregiverID);
    }}
  >
    <Icon name="gear" />
  </Button>
);

CaregiverSettings.propTypes = {
  editCaregiver: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  setEditingCaregiver: state.caregiverList.editCaregiver,
});

const mapDispatch = (dispatch) => ({
  editCaregiver: (id) => dispatch(editSelectedCaregiver(id)),
});

export default withRouter(connect(mapState, mapDispatch)(CaregiverSettings));

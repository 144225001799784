import React from 'react';
import PropTypes from 'prop-types';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const arrowRender = (direction, size, textColor) => {
  if (direction === 'up') {
    return <ArrowUpwardIcon style={{ fontSize: `${size}em`, color: textColor }} />;
  }
  return <ArrowDownwardIcon style={{ fontSize: `${size}em`, color: textColor }} />;
};

const TextData = ({ data, size, subtext, arrow, className, textColor }) => (
  <div className={`d-flex flex-row align-items-center p-0 ${className}`}>
    <div
      className={`d-flex align-items-center ${
        subtext?.vertical ? 'flex-column align-items-center' : 'flex-row align-items-end'
      } justify-content-center`}
    >
      <div className="d-flex flex-row align-items-center justify-content-center">
        {arrow && arrowRender(arrow, size, textColor)}
        {/* <h1
          className="d-flex font-weight-bold text-center p-1 align-items-center justify-content-center"
          style={{
            // fontSize: `calc(${size}em)`,
            color: textColor,
          }}
        > */}
        <h1 style={{ color: textColor }}>{data}</h1>
        {/* </h1> */}
      </div>
      {subtext && <h6 className="p-2 text-dark"> {subtext.text} </h6>}
    </div>
  </div>
);

TextData.propTypes = {
  data: PropTypes.node.isRequired,
  size: PropTypes.number,
  subtext: PropTypes.shape({
    text: PropTypes.string,
    vertical: PropTypes.bool,
  }),
  textColor: PropTypes.string,
  arrow: PropTypes.string,
  className: PropTypes.string,
};

TextData.defaultProps = {
  size: 4.5,
  subtext: { text: undefined, vertical: false },
  textColor: '#404040', // formerly '#626275'
  arrow: '',
  className: 'justify-content-center w-100',
};

export default TextData;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '@util/dateFunctions';
import { useGetSessionUser } from '@util/session';

import { setPatientList, setCurrPatient } from '@global-state/redux/profileSlice';
import { setDisenrollmentPopup, getRecentEventsByType } from '@global-state/redux/updatesSlice';

import EventList from '@intus-ui/components/EventList';
import EventBadge from '@intus-ui/components/Badges/EventBadge';
import DisenrollmentPopup from 'Updates/updates/DisenrollmentPopup';

import '@intus-ui/styles/FilteredList.scss';
import '@intus-ui/styles/Utilities.scss';
import { recentEventCategoriesToEventMap } from '@intus-ui/components/EventList/helpers';
import { useParticipantFilters } from '@intus-ui';

const RecentEvents = () => {
  const dispatch = useDispatch();
  const enrolled = useSelector((state) => state.updates.enrolled);
  const disenrolled = useSelector((state) => state.updates.disenrolled);
  const inpatientAdmits = useSelector((state) => state.updates.inpatientAdmits);
  const inpatientDischarges = useSelector((state) => state.updates.inpatientDischarges);
  const erVisits = useSelector((state) => state.updates.erVisits);
  const timeSpan = useSelector((state) => state.updates.timeSpan);

  const { selectedFilters } = useParticipantFilters();

  // Constructs the patient data that's given to FilteredList.
  const user = useGetSessionUser();
  const { setting } = user;
  const categories = setting.defaultRecentUpdatesFilter || [];

  const selectedEventTypes = categories.map(
    (eventType) => recentEventCategoriesToEventMap[eventType]
  );

  // we cannot use selectedEventTypes[] as a dependency directly, or it will infinitely loop
  // using a string representation of the array instead via .join()
  useEffect(() => {
    dispatch(getRecentEventsByType(selectedEventTypes, timeSpan, selectedFilters));
  }, [selectedFilters, selectedEventTypes.join()]);

  const events = [];
  enrolled?.forEach((patient) => {
    const event = {
      id: patient.id,
      name: `${patient.firstName} ${patient.lastName} (${patient.memberId})`,
      enrollment: <EventBadge event="enrolled" />,
      enrollmentValue: 'ENROLLED',
      date: formatDate(patient.enrollmentDate, 'M/D/YYYY'),
      dateValue: formatDate(patient.enrollmentDate, 'M/D/YYYY'),
      disenrollmentReason: patient.disenrollmentReason,
    };
    events.push(event);
  });

  disenrolled?.forEach((patient) => {
    const event = {
      id: patient.id,
      name: `${patient.firstName} ${patient.lastName} (${patient.memberId})`,
      enrollment: <EventBadge event="disenrolled" />,
      enrollmentValue: 'DISENROLLED',
      date: formatDate(patient.disenrollmentDate, 'M/D/YYYY'),
      dateValue: formatDate(patient.disenrollmentDate, 'M/D/YYYY'),
      disenrollmentReason: patient.disenrollmentReason,
    };
    events.push(event);
  });

  inpatientAdmits?.forEach((admit) => {
    const event = {
      id: admit.patientId,
      name: `${admit.patient.firstName} ${admit.patient.lastName} (${admit.patient.memberId})`,
      enrollment: <EventBadge event="admit" />,
      enrollmentValue: 'ADMIT',
      date: formatDate(admit.admitDate, 'M/D/YYYY'),
      dateValue: formatDate(admit.admitDate, 'M/D/YYYY'),
      startDate: admit.admitDate,
      principalDX: admit.principalDx.toString(),
      endDate: admit.dischargeDate ? formatDate(admit.dischargeDate, 'M/D/YYYY') : null,
    };
    events.push(event);
  });

  inpatientDischarges?.forEach((admit) => {
    const event = {
      id: admit.patientId,
      name: `${admit.patient.firstName} ${admit.patient.lastName} (${admit.patient.memberId})`,
      enrollment: (
        <>
          <EventBadge event="admit" />
          <EventBadge event="discharge" />
        </>
      ),
      enrollmentValue: 'DISCHARGED',
      date: (
        <div>
          {formatDate(admit.admitDate, 'M/D/YYYY')}-{formatDate(admit.dischargeDate, 'M/D/YYYY')}
        </div>
      ),
      dateValue: formatDate(admit.admitDate, 'M/D/YYYY'),
      startDate: admit.admitDate,
      principalDX: admit.principalDx.toString(),
      endDate: admit.dischargeDate ? formatDate(admit.dischargeDate, 'M/D/YYYY') : null,
    };
    events.push(event);
  });

  erVisits?.forEach((admit) => {
    const event = {
      id: admit.patientId,
      name: `${admit.patient.firstName} ${admit.patient.lastName} (${admit.patient.memberId})`,
      enrollment: <EventBadge event="er" />,
      enrollmentValue: 'ER VISIT',
      date: formatDate(admit.admitDate, 'M/D/YYYY'),
      dateValue: formatDate(admit.admitDate, 'M/D/YYYY'),
      startDate: admit.admitDate,
      principalDX: admit.principalDx.toString(),
      endDate: admit.dischargeDate ? formatDate(admit.dischargeDate, 'M/D/YYYY') : null,
    };
    events.push(event);
  });

  const prepareDisenrollmentPopup = (patient) => {
    const {
      id,
      name,
      date,
      disenrollmentReason,
      enrollmentValue,
      principalDX,
      startDate,
      endDate,
      placeOfStay,
    } = patient;
    const newPatients = events.filter((item) => !item.disenrollmentReason);
    const currIndex = newPatients.indexOf(patient);
    const patientList = newPatients.map((newPatient) => ({
      id: newPatient.id,
      name: newPatient.name,
    }));
    dispatch(setCurrPatient(currIndex));
    dispatch(setPatientList(patientList));
    dispatch(
      setDisenrollmentPopup({
        show: true,
        id,
        type: enrollmentValue,
        name,
        date,
        disenrollmentReason,
        principalDX,
        startDate,
        endDate,
        placeOfStay,
        patientList,
      })
    );
  };

  return (
    <div style={styles.box}>
      <EventList events={events} onClickItem={prepareDisenrollmentPopup} />
      <DisenrollmentPopup />
    </div>
  );
};

const styles = {
  box: {
    width: '100%',
    height: '100%',
    // boxShadow: '0px 0.4px 0.4rem 0.06rem rgb(0 0 0 / 5%)',
  },
};

export default RecentEvents;

import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useLazyQuery, useQuery } from '@api/useQuery';
import { getPatientMedicationsWithFilters } from '@api/patients/getPatientMedications';
import {
  IMedicationClass,
  getAllMedicationClasses,
} from '@api/polypharmacy/medicationClass/getAllMedicationClasses';

import { formatDate, newDate } from '@util/dateFunctions';

import { DateRangeDropdown, Input, List, RadioGroup, SpinnerOrError, Text } from '@intus-ui';
import { format } from '@intus-ui/components/MedicationsList/list/format';
import { getMedicationsTabColumnConfig } from '@intus-ui/components/MedicationsList/list/columnConfigs';

import { MedicationClassDropdown } from 'Polypharmacy/components/MedicationClassDropdown';

export const Medications = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const [claimsOrdersFilter, setClaimsOrdersFilter] = useState('Medication fills (from Claims)');
  const [selectedMedClass, setSelectedMedClass] = useState<IMedicationClass | null>(null);
  const [activeMedsFilter, setActiveMedsFilter] = useState('Active Medications');

  const { data: allClasses } = useQuery(getAllMedicationClasses);

  const startDate = null;
  const endDate = newDate();
  const [timeFilterVal, setTimeFilterVal] = useState({ startDate, endDate });
  const {
    data: medicationsData,
    loading,
    error,
    runQuery,
  } = useLazyQuery(() =>
    getPatientMedicationsWithFilters({
      patientId: parseInt(patientId),
      startDate: formatDate(timeFilterVal.startDate, 'YYYY-MM-DD'),
      endDate: formatDate(timeFilterVal.endDate, 'YYYY-MM-DDT23:59:59'),
      active: activeMedsFilter === 'Active Medications',
      medClass: selectedMedClass,
    })
  );

  useEffect(() => {
    if (patientId) runQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, timeFilterVal, activeMedsFilter, selectedMedClass]);

  const listFormat = format();

  if (error) {
    return <SpinnerOrError error={"An error occurred loading the patient's medications"} />;
  }

  if (loading || !medicationsData) {
    return <SpinnerOrError />;
  }

  const isClaims = claimsOrdersFilter === 'Medication fills (from Claims)';

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%', gap: '10px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <RadioGroup
          options={['Medication fills (from Claims)', 'Medication orders (from Canvas)']}
          selectedOption={claimsOrdersFilter}
          onChange={(value: string) => setClaimsOrdersFilter(value)}
        />
      </div>

      <List
        key={claimsOrdersFilter === 'Medication fills (from Claims)' ? 'claims' : 'orders'}
        noPadding
        data={
          isClaims
            ? medicationsData.filter((item) => item.source === 'CLAIM')
            : medicationsData.filter((item) => item.source === 'ORDER')
        }
        format={listFormat}
        defaultSort={
          isClaims
            ? { field: 'dateFilled', direction: 'desc' }
            : { field: 'dateOrdered', direction: 'desc' }
        }
        columnConfig={
          claimsOrdersFilter === 'Medication fills (from Claims)'
            ? getMedicationsTabColumnConfig(false).claimsConfig
            : getMedicationsTabColumnConfig(false).default
        }
        searchable
        topRightElements={[
          <MedicationClassDropdown
            allMedClasses={allClasses ?? []}
            selectedMedClass={selectedMedClass}
            setSelectedMedClass={setSelectedMedClass}
            autocompleteProps={{
              disableClearable: false,
            }}
            sx={{
              minWidth: '350px',
              height: '35px',
              padding: '0 !important',
              margin: '0 !important',
              '.MuiTextField-root, .MuiInputBase-root, input': {
                height: '35px',
                padding: '0px 10px 0px 10px !important',
                margin: '0 !important',
              },
            }}
          />,
          <ActiveMedicationsDropdown
            activeMedsFilter={activeMedsFilter}
            setActiveMedsFilter={setActiveMedsFilter}
          />,
          <div
            style={{
              marginTop: '-22px',
              width: '100%',
            }}
          >
            <Text type="caption">
              Filtered by{' '}
              {claimsOrdersFilter === 'Medication fills (from Claims)'
                ? 'date filled'
                : 'date ordered'}
            </Text>
            <DateRangeDropdown
              range={[1, 3, 6, 12, 'all']}
              disabled={activeMedsFilter === 'Active Medications'}
              value={
                timeFilterVal.startDate == null || activeMedsFilter === 'Active Medications'
                  ? 'All Time'
                  : `${formatDate(timeFilterVal.startDate, 'MM/DD/YY')} - ${formatDate(
                      timeFilterVal.endDate,
                      'MM/DD/YY'
                    )}`
              }
              dateRangeDirection="left"
              onChange={(val: any) => {
                if (!val.isRange)
                  setTimeFilterVal({
                    startDate: val.start,
                    endDate: val.end,
                  });
                return null;
              }}
              onApplyRange={(val: any) => {
                setTimeFilterVal({ startDate: val.start, endDate: val.end });
              }}
              style={{ minWidth: 'fit-content' }}
            />
          </div>,
        ]}
        listStyle="striped"
      />
    </div>
  );
};

type ActiveMedicationsDropdownProps = {
  activeMedsFilter: string;
  setActiveMedsFilter: (val: string) => void;
};

const ActiveMedicationsDropdown: FC<ActiveMedicationsDropdownProps> = ({
  activeMedsFilter,
  setActiveMedsFilter,
}) => {
  return (
    <Input
      rightIcon="caret-down"
      type="select"
      options={['Active Medications', 'All Medications']}
      onChange={(val: string) => setActiveMedsFilter(val)}
      value={activeMedsFilter}
      placeholder="Active Medications"
      style={{ minWidth: '220px' }}
    />
  );
};

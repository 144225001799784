import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

const ControlledCheckboxGroup = ({ checkboxes, checked, valueChanged, disabled, className = '' }) =>
  checkboxes.map((checkbox) => (
    <Form.Check
      className={className}
      key={checkbox.value}
      id={checkbox.value}
      type="checkbox"
      label={checkbox.node}
      checked={checked.includes(checkbox.value)}
      onChange={() => valueChanged(checkbox.value)}
      disabled={disabled}
    />
  ));

ControlledCheckboxGroup.propTypes = {
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      node: PropTypes.node.isRequired,
    }).isRequired
  ),
  checked: PropTypes.arrayOf(PropTypes.string.isRequired),
  valueChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

ControlledCheckboxGroup.defaultProps = {
  checkboxes: [],
  checked: [],
  valueChanged: () => {},
  disabled: false,
};

export default ControlledCheckboxGroup;

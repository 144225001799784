import React from 'react';
import PropTypes from 'prop-types';

import IntusLabelsPropType from './IntusLabelsPropType';
import ScoreBreakDown from './ScoreBreakDown';
import AcuityHeader from './ProfileAcuityHeader';

const ProfileAcuityDetails = ({ acuityLevelChanges, acuityLevel }) => {
  return (
    <div style={styles.container}>
      <AcuityHeader acuityLevel={acuityLevel} riskGroup={acuityLevel.riskGroup} />
      <div style={styles.content}>
        <ScoreBreakDown
          acuityLevelChanges={acuityLevelChanges}
          acuityLevels={acuityLevel}
          selected={0}
        />
      </div>
    </div>
  );
};
const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    gap: 20,
    overflowY: 'auto',
  },
  content: { flex: '1 1 100%' },
};
ProfileAcuityDetails.propTypes = {
  acuityLevelChanges: PropTypes.arrayOf(IntusLabelsPropType),
};

ProfileAcuityDetails.defaultProps = {
  acuityLevelChanges: [],
};
export default ProfileAcuityDetails;

import React, { useEffect, useState } from 'react';

import ExecutiveViewChart from 'Utilization/ExecutiveView/ExecutiveViewChart';
import AdmitsParticipantList from 'Utilization/ExecutiveView/ExecutiveViewChartDetails/AdmitsParticipantList';
import { getLabelGroup } from '../helperFunctions';

interface ExecutiveViewChartDetailsProps {
  chartData: {
    labels: string[];
    dataPoints: number[];
    targets: number[];
  };
  admittedParticipants: any;
  xAxisLabel: string;
  top5PrincipalDxData?: [] | undefined;
}
/* See More > list admits from admits where AdmitIds are in the list of admits from the org  */

const ExecutiveViewChartDetails: React.FC<ExecutiveViewChartDetailsProps> = ({
  chartData,
  admittedParticipants,
  xAxisLabel,
  top5PrincipalDxData,
}) => {
  // tealBlue is a key in defaultChartColors (secondaryColors.ts)
  const chartColor = 'tealBlue';
  const [participantsList, setParticipantsList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('all');

  useEffect(() => {
    setParticipantsList(admittedParticipants.all);
    setSelectedGroup('all');
  }, [admittedParticipants]);

  const onClickBar = (label: string | null) => {
    if (label === null) {
      setParticipantsList(admittedParticipants.all);
      setSelectedGroup('all');
    } else {
      setParticipantsList(admittedParticipants[getLabelGroup(label)]);
      setSelectedGroup(getLabelGroup(label));
    }
  };

  const handleOnClickCloseButton = () => {
    setParticipantsList(admittedParticipants.all);
    setSelectedGroup('all');
  };

  return (
    <div style={styles.container}>
      <div style={styles.chartContainer}>
        <ExecutiveViewChart
          chartColor={chartColor}
          labels={chartData.labels}
          dataPoints={chartData.dataPoints}
          targets={chartData.targets}
          xAxisLabel={xAxisLabel}
          onClickBar={onClickBar}
          top5PrincipalDxData={top5PrincipalDxData}
          detailViewMode
        />
      </div>

      <div style={styles.listContainer}>
        <AdmitsParticipantList
          handleOnClickCloseButton={handleOnClickCloseButton}
          chartColor={chartColor}
          participantList={participantsList}
          selectedGroup={selectedGroup}
        />
      </div>
    </div>
  );
};
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: 'flex',
    height: '80vh',
    gap: 20,
    overflowY: 'auto',
    padding: '20px 10px 10px 10px',
    width: '100%',
  },
  chartContainer: {
    flex: 2,
    height: '100%',
    width: '100%',
  },
  listContainer: {
    flex: 1,
    width: '100%',
  },
};

export default ExecutiveViewChartDetails;

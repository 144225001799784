import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@api/useQuery';
import { Card, List, Text, SpinnerOrError } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import {
  IPatientWithMedInClass,
  getPatientsWithMedicationInClass,
} from '@api/polypharmacy/medicationClass/getPatientsWithMedicationInClass';
import {
  IMedicationClass,
  getAllMedicationClasses,
} from '@api/polypharmacy/medicationClass/getAllMedicationClasses';
import { IPolypharmacyFilters } from '@api/polypharmacy/types/IPolypharmacyFilters';
import { TextField } from '@mui/material';
import { MedicationClassDropdown } from 'Polypharmacy/components/MedicationClassDropdown';
import { newDate } from '@util/dateFunctions';

type MedClassProps = {
  filters: IPolypharmacyFilters;
};

export const MedClass: FC<MedClassProps> = ({ filters }) => {
  const [fillCount, setFillCount] = useState<number | null>(1);
  const [selectedMedClass, setSelectedMedClass] = useState<IMedicationClass | null>(null);

  const realFillCount = fillCount ?? 1;

  const {
    data: allClasses,
    loading: loadingAllClasses,
    error: allClassesError,
  } = useQuery(getAllMedicationClasses);

  const { data, loading, error, runQuery } = useLazyQuery(getPatientsWithMedicationInClass);

  useEffect(() => {
    if (selectedMedClass != null) {
      runQuery(selectedMedClass.gpi, selectedMedClass.gpiType, filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, selectedMedClass]);

  if (allClassesError) {
    return <SpinnerOrError error="An error occurred loading patients." />;
  }

  if (loadingAllClasses || !allClasses) {
    return <SpinnerOrError />;
  }

  let patients = data?.patients ?? [];

  if (realFillCount > 1) {
    patients = patients.filter((p) => p.medicationCount >= realFillCount);
  }

  return (
    <div>
      <MedClassCards
        allMedClasses={allClasses}
        loading={loading}
        selectedMedClass={selectedMedClass}
        setSelectedMedClass={setSelectedMedClass}
        fillCount={fillCount}
        setFillCount={setFillCount}
        filteredPatients={patients}
      />
      {loading && <SpinnerOrError />}
      {error && <SpinnerOrError error="An error occurred loading patients." />}
      {!loading && !error && patients && (
        <TableContainer
          filters={filters}
          selectedMedClass={selectedMedClass}
          fillCount={realFillCount}
          filteredPatients={patients}
        />
      )}
    </div>
  );
};

type MedClassCardsProps = {
  allMedClasses: IMedicationClass[];
  loading: boolean;
  selectedMedClass: IMedicationClass | null;
  setSelectedMedClass: (medClass: IMedicationClass | null) => void;
  fillCount: number | null;
  setFillCount: (fillCount: number | null) => void;
  filteredPatients: IPatientWithMedInClass[];
};

const MedClassCards: FC<MedClassCardsProps> = ({
  allMedClasses,
  loading,
  selectedMedClass,
  setSelectedMedClass,
  fillCount,
  setFillCount,
  filteredPatients,
}) => {
  const realFillCount = fillCount ?? 1;

  return (
    <div style={{ display: 'flex', alignItems: 'stretch', gap: 30 }}>
      <Card style={{ textAlign: 'left', width: 400, height: '100%' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            alignItems: 'stretch',
            gap: 10,
          }}
        >
          <Text type="subtitle">Medication Group/Class</Text>
          <MedicationClassDropdown
            allMedClasses={allMedClasses}
            selectedMedClass={selectedMedClass}
            setSelectedMedClass={setSelectedMedClass}
          />
          <Text type="caption" color="caption">
            *Medication classification system:{' '}
            <a
              href="https://www.wolterskluwer.com/en/solutions/medi-span/about/gpi"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: 'inherit',
                textDecoration: 'underline',
              }}
            >
              Medi-Span GPI
            </a>
          </Text>
        </div>
        <div>
          <Text type="subtitle" style={{ paddingTop: 20, paddingBottom: 10, display: 'block' }}>
            # of Fills in Medication Class
          </Text>
          <Text type="caption" style={{ paddingRight: 10 }}>
            greater than or equal to
          </Text>
          <TextField
            type="number"
            value={fillCount}
            sx={{
              width: 75,
            }}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              if (!Number.isNaN(value) && value > 0) {
                setFillCount(value);
              } else if (value < 0) {
                setFillCount(1);
              } else {
                setFillCount(null);
              }
            }}
          />
        </div>
      </Card>
      {selectedMedClass && (
        <Card contentStyle={{ height: '100%', width: 250 }}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <Text wrapper="div" type="subtitle">
                Patients on {realFillCount}+ {selectedMedClass.groupName}
              </Text>
            </div>
            <div>
              {loading && <SpinnerOrError />}
              {!loading && filteredPatients && (
                <Text wrapper="div" type="display">
                  {filteredPatients.length}
                </Text>
              )}
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

const columnConfig = {
  default: {
    columns: ['name', 'memberId', 'age', 'gender', 'medicationCount', 'fillCount'],
  },
};

const format = [
  {
    field: 'name',
    name: 'Patient Full Name',
    flex: '0 0 300px',
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
  },
  {
    field: 'memberId',
    name: 'Member ID',
    flex: '0 0 140px',
    align: 'start',
  },
  {
    field: 'age',
    name: 'Age',
    flex: '0 0 50px',
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIcon key="age" id="age" /> }],
  },
  {
    field: 'medicationCount',
    name: '# Medications',
    flex: '0 0 120px',
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="medicationCount" id="medicationCount" /> }],
  },
  {
    field: 'fillCount',
    name: '# Fills',
    flex: '0 0 120px',
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="fillCount" id="fillCount" /> }],
  },
  {
    field: 'gender',
    name: 'Gender',
    flex: '0 0 75px',
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIcon key="gender" id="gender" /> }],
  },
];

type TableProps = {
  filters: IPolypharmacyFilters;
  fillCount: number;
  selectedMedClass: IMedicationClass | null;
  filteredPatients: IPatientWithMedInClass[];
};

const Table: FC<TableProps> = ({ filters, fillCount, selectedMedClass, filteredPatients }) => {
  return (
    <div style={{ width: '100%' }}>
      <List
        data={filteredPatients}
        format={format}
        columnConfig={columnConfig.default}
        onClickItem={(ppt) => {
          window.open(`/patient/${ppt.id}/medications`, '_blank');
        }}
        listStyle="striped"
        emptyListMessage={
          selectedMedClass != null
            ? `No patients with ${fillCount}+ fills between ${newDate(filters.startDate).format(
                'M/D/YYYY'
              )} and ${newDate(filters.endDate).format('M/D/YYYY')}.`
            : 'Select a medication group or class to view patients on those medications.'
        }
        defaultSort={{ field: 'medicationCount', direction: 'desc' }}
        clickable
      />
    </div>
  );
};

type TableContainerProps = {
  filters: IPolypharmacyFilters;
  selectedMedClass: IMedicationClass | null;
  fillCount: number;
  filteredPatients: IPatientWithMedInClass[];
};

const TableContainer: FC<TableContainerProps> = ({
  filters,
  selectedMedClass,
  fillCount,
  filteredPatients,
}) => {
  if (!selectedMedClass || !filteredPatients) {
    filteredPatients = [];
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Text type="headline" style={{ marginBottom: 10 }}>
        {selectedMedClass && (
          <>
            Patients with {fillCount}+ {selectedMedClass.groupName}
          </>
        )}
        {!selectedMedClass && <>Patients with ...</>}
      </Text>
      <Table
        filters={filters}
        fillCount={fillCount}
        selectedMedClass={selectedMedClass}
        filteredPatients={filteredPatients}
      />
    </div>
  );
};

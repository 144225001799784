import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getLogger } from '@util/logger';

import { editCohort } from '@api';

import { useDispatch, useSelector } from '@global-state';
import { updateSearchState } from '@global-state/redux';

import { Text, Button, Icon, layout } from '@intus-ui';

import CreateFilter from './CreateFilter';
import ResultView from './ResultView';
import CreateCohort from './CreateCohort';

const log = getLogger('SearchCreate/index');

const SearchCreate = ({ setView, searchState, setSearchState }) => {
  const { editing, existingCohort, cohortType, cohortId } = searchState;
  const history = useHistory();
  const dispatch = useDispatch();
  const globalSearchState = useSelector((state) => state.advancedCohorts.searchState);

  const handleUpdateCohort = () => {
    const payload = {
      name: existingCohort.name,
      description: existingCohort.description,
      essentialNeeds: existingCohort.essentialNeeds,
      searchCriteria: searchState.filter,
    };
    editCohort(cohortId, payload)
      .then((res) => res.json())
      .then(() => {
        history.push(`/cohorts/${cohortType}/${cohortId}/participants`);
      })
      .catch((e) => {
        // TODO: PRESENT ERROR MESSAGE TO USER
        log.error(e);
      });
  };

  useEffect(() => {
    if (globalSearchState) {
      const existingSearchState = JSON.parse(JSON.stringify(globalSearchState));
      setSearchState(existingSearchState);
      dispatch(updateSearchState(null));
    }
  }, []);

  useEffect(() => {
    if (editing && !existingCohort)
      history.replace(`/cohorts/${cohortType}/${cohortId}/participants`);
  });

  return (
    <div style={styles.outerContainer}>
      <div style={styles.headerRow}>
        {searchState.view === 'SEARCH_CREATE' &&
          (existingCohort ? (
            <div style={styles.headerEditFilterContent}>
              <div style={styles.headerEditFilterButton}>
                <Button
                  hoverText="Back Button"
                  name="Back"
                  onClick={() => {
                    if (existingCohort) {
                      history.push(`/cohorts/${cohortType}/${cohortId}/participants`);
                    } else {
                      setSearchState({ ...searchState, view: 'SEARCH_PREVIEW' });
                    }
                  }}
                  style={styles.backButton}
                >
                  <Icon name="caret-left" />
                </Button>

                <Text type="header" color="navy">
                  Edit Filters for {existingCohort.name}
                </Text>
              </div>
            </div>
          ) : (
            <Text type="header" color="navy">
              Search & Create
            </Text>
          ))}

        {searchState.view === 'SEARCH_PREVIEW' && (
          <div style={styles.headerEditFilterContent}>
            <div style={styles.headerEditFilterButton}>
              <Button
                hoverText="Edit Filters"
                name="Edit Filters"
                onClick={() => {
                  setSearchState({ ...searchState, view: 'SEARCH_CREATE' });
                }}
                style={styles.backButton}
              >
                <Icon name="caret-left" />
              </Button>

              <Text type="headline" color="navy">
                Filter Results {existingCohort && `for ${existingCohort.name}`}
              </Text>
            </div>

            <Button
              name={existingCohort ? 'Save Cohort' : 'Create Cohort'}
              style={styles.rightButton}
              onClick={
                existingCohort
                  ? handleUpdateCohort
                  : () => setSearchState({ ...searchState, fixed: false, view: 'SEARCH_DETAILS' })
              }
            />
          </div>
        )}

        {searchState.view === 'SEARCH_DETAILS' && (
          <div style={styles.headerEditFilterButton}>
            <Button
              hoverText={existingCohort ? 'Back' : 'Filter Results'}
              name={existingCohort ? 'Back' : 'Filter Results'}
              onClick={() => {
                if (existingCohort) {
                  history.push(`/cohorts/${cohortType}/${cohortId}/details`);
                } else {
                  setSearchState({ ...searchState, fixed: false, view: 'SEARCH_PREVIEW' });
                }
              }}
              style={styles.backButton}
            >
              <Icon name="caret-left" />
            </Button>

            <Text type="headline" color="navy">
              {existingCohort ? 'Edit Details' : 'Cohort Details'}
            </Text>
          </div>
        )}
      </div>
      <div style={styles.mainContent}>
        {searchState.view === 'SEARCH_CREATE' && (
          <div style={styles.createFilterWrapper}>
            <CreateFilter searchState={searchState} setSearchState={setSearchState} />
          </div>
        )}
        {searchState.view === 'SEARCH_PREVIEW' && (
          <ResultView searchState={searchState} setSearchState={setSearchState} />
        )}
        {searchState.view === 'SEARCH_DETAILS' && (
          <CreateCohort
            setView={setView}
            searchState={searchState}
            setSearchState={setSearchState}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  backButton: {
    width: 'auto',
    minWidth: 'unset',
    margin: 0,
  },
  rightButton: {
    whiteSpace: 'nowrap',
  },
  headerEditFilterContent: {
    ...layout.rowContainer,
    gap: 10,
    padding: '0px 20px 0px 0px',
  },
  headerEditFilterButton: {
    ...layout.rowContainer,
    gap: 10,
    alignItems: 'center',
  },
  headerRow: {
    ...layout.rowContainer,
    flex: 'none',
  },
  mainContent: {
    ...layout.container,
    padding: '5px 20px 20px 5px',
    gap: 30,
  },
  outerContainer: {
    ...layout.container,
    gap: 15,
    // paddingRight: 20,
  },
  createFilterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: '20px 30px 15px 30px',
    borderRadius: 20,
    backgroundColor: 'white',
    boxShadow: '0px 0.4px 0.4rem 0.06rem rgba(0, 0, 0, 0.2)',
  },
};
export default SearchCreate;

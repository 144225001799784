import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { getAllMetricsCategories, getMetricConfigById } from '@api/dashboard';
import { useLazyQuery, useQuery } from '@api/useQuery';
import { Button, ButtonGroup, Icon, Modal, SpinnerOrError, Text, Widget } from '@intus-ui';
import { Autocomplete, InputAdornment, TextField, createFilterOptions } from '@mui/material';
import Paper from '@mui/material/Paper';
import { isEmpty, orderBy } from 'lodash';
import { getMetricConfigComponent } from 'CustomDashboardV2/MetricLibrary/MetricConfigs/getMetricConfigComponent';
import WidgetEmptyState from '@intus-ui/components/Widget/WidgetEmptyState';
import { formatMetricCategoryList } from './helpers';

export const MetricLibraryModal = ({
  openMetricLibraryModal,
  setOpenMetricLibraryModal,
  onUpdateMetric,
  metricToEdit,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { data: configData, runQuery: runGetMetricConfig } = useLazyQuery((metricId) => {
    return getMetricConfigById(metricId);
  });

  const { data: metricsWithCategories, loading: loadingMetricCategories } = useQuery(() =>
    getAllMetricsCategories()
  );

  const myForm = useForm({
    defaultValues: {
      id: null,
      metricId: '',
      metricConfig: {},
      ...metricToEdit,
    },
  });
  const { control, setValue, handleSubmit, watch } = myForm;

  const selectedMetricId = watch('metricId');

  useEffect(() => {
    if (selectedMetricId) runGetMetricConfig(selectedMetricId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMetricId]);

  const metricCategoryList = useMemo(() => {
    if (metricsWithCategories != null) {
      const formattedList = formatMetricCategoryList(metricsWithCategories);
      return orderBy(formattedList, [(item) => item.category, (item) => item.name]);
    }

    return [];
  }, [metricsWithCategories]);

  const handleSaveMetric = (metricFormData) => {
    onUpdateMetric(metricFormData);
    setOpenMetricLibraryModal(false);
  };

  const ConfigurationComponent =
    selectedMetricId && configData ? getMetricConfigComponent(configData) : null;

  const filterOptions = createFilterOptions({
    stringify: ({ category, name }) => `${category} ${name}`,
  });

  return (
    <Modal
      header={{ title: 'Add Metric Card', centered: false }}
      open={openMetricLibraryModal}
      onClose={() => setOpenMetricLibraryModal(false)}
      type="regular"
    >
      {loadingMetricCategories && <SpinnerOrError />}
      {!loadingMetricCategories && (
        <form
          onSubmit={handleSubmit(handleSaveMetric)}
          id="metric-library-container"
          style={styles.container}
        >
          <div id="metric-menu-form" style={styles.menuContainer}>
            <div style={styles.headers}>
              <Text type="subtitle">Select your metric</Text>
              <Controller
                name="metricId"
                control={control}
                rules={{ required: 'Metric is required' }}
                render={({ field }) => {
                  return (
                    <Autocomplete
                      id="demo-simple-select"
                      open={openDropdown}
                      filterOptions={filterOptions}
                      onOpen={() => setOpenDropdown(true)}
                      onClose={() => setOpenDropdown(false)}
                      options={metricCategoryList}
                      groupBy={(metric) => metric?.category}
                      getOptionLabel={(metric) => metric?.name}
                      popupIcon={<Icon name="CaretDown" color="#9D9D9D" hoverColor="#9D9D9D" />}
                      PaperComponent={({ children }) => (
                        <Paper
                          style={{
                            marginTop: 10,
                            border: '1px solid #C8C8C8',
                            borderRadius: 0,
                          }}
                        >
                          {children}
                        </Paper> /* Styles dropdown */
                      )}
                      style={{
                        width: '500px',
                        marginLeft: '1px',
                        padding: '2px 10px 2px 5px',
                        border: '1px solid rgba(157, 157, 157, 1)',
                        borderRadius: '5px',
                      }}
                      onChange={(e, value) => {
                        // Clear out the metric config so we don't have
                        // a bad config when switching between a Stat metric and a Breakdown metric
                        setValue('metricConfig', {});
                        setValue('metricId', value.id);
                      }}
                      renderGroup={(params) => {
                        const sortedParams = params.children?.sort((a, b) => {
                          // if (a.key == null || b.key == null) return;
                          return a.key.toLowerCase() - b.key.toLowerCase();
                        });

                        return (
                          <div
                            key={`${params.key}-${params.group}`}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                            }}
                          >
                            <Text type="subtitle" style={{ paddingLeft: 10 }}>
                              {params.group}
                            </Text>
                            <Text type="body" style={{ width: '100%' }}>
                              {sortedParams}
                            </Text>
                          </div>
                        );
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Metric Type"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ gap: '3px' }}
                                  onClick={() => setOpenDropdown(!openDropdown)}
                                >
                                  <Icon name="Search" color="#9D9D9D" hoverColor="#9D9D9D" />
                                  {field.value != null && field.value !== '' && (
                                    <Text type="subtitle">
                                      {
                                        metricCategoryList.find((item) => item.id === field.value)
                                          ?.category
                                      }
                                      :{' '}
                                    </Text>
                                  )}
                                </InputAdornment>
                              ),
                              style: { color: 'rgba(64, 64, 64, 1)' },
                            }}
                          />
                        );
                      }}
                      value={metricCategoryList.find((item) => item.id === field.value)}
                      clearIcon={null}
                    />
                  );
                }}
              />

              {selectedMetricId && ConfigurationComponent && (
                <ConfigurationComponent
                  key={selectedMetricId}
                  form={myForm}
                  metricConfig={configData}
                />
              )}
            </div>
            <div id="metric-preview" style={styles.previewContainer}>
              <Text type="subtitle">Preview</Text>
              <div style={styles.widgetWrapper}>
                {isEmpty(selectedMetricId) ? (
                  <WidgetEmptyState />
                ) : (
                  <Widget
                    key={selectedMetricId}
                    metric={{
                      ...watch(),
                      ...metricCategoryList.find((metric) => metric.id === selectedMetricId),
                    }}
                    hideSeeMore
                  />
                )}
              </div>
            </div>
            <ButtonGroup showDivider={false}>
              <Button textOnly onClick={() => setOpenMetricLibraryModal(false)}>
                Cancel
              </Button>
              <Button type="submit">
                {metricToEdit ? 'Save Changes' : 'Add Card to Dashboard'}
              </Button>
            </ButtonGroup>
          </div>
        </form>
      )}
    </Modal>
  );
};

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    minHeight: 555,
  },
  menuContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    gap: '10px',
    padding: '10px 0px',
  },
  headers: { display: 'flex', backgroundColor: 'white', flex: 1, flexDirection: 'column', gap: 10 },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  widgetWrapper: { display: 'flex', justifyContent: 'center' },
};

import { useCallback, useEffect, useState } from 'react';
import { useRefresh } from 'muuri-react';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Card from '@intus-ui/components/Card';
import Icon from '@intus-ui/components/Icon';
import Text from '@intus-ui/components/Text';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import { useParticipantFilters } from '@intus-ui/components/filters';
import { getWidgetSize, widgetComponents } from '@intus-ui/components/Widget/components';
import { widgetSizes } from '@intus-ui/components/Widget/config';

import { useLazyQuery } from '@api/useQuery';
import layouts from '@intus-ui/layouts';
import { getMetricById } from '@api/dashboard';

import WidgetHeader from './WidgetHeader';
import WidgetModal from './WidgetModal';
import './styles.css';

const Widget = ({
  metric,
  canUserEdit,
  setDragIsEnabled,
  hideSeeMore,
  onEditMetric,
  deleteMetricById,
  newMetricId,
  setSnackbarErrorMessage,
}) => {
  const history = useHistory();

  let isNewMetric = false;
  if (newMetricId != null && metric.id === newMetricId) isNewMetric = true;

  const [isOpen, setIsOpen] = useState(() => {
    // If the user clicked a participant in a list in the focus view and hit the back button,
    // we re-open the modal when they go back.

    const params = new URLSearchParams(history.location.search);

    const openMetricId = params.get('visibleMetricId');

    if (openMetricId != null && parseInt(openMetricId, 10) === metric.id) {
      return true;
    }

    return false;
  });

  // Wrapper around setIsOpen that adds query params to the URL to
  // let us re-open the modal if the user clicks a participant and hits the back button.
  const setIsOpenWrapper = useCallback(
    (isOpen) => {
      const params = new URLSearchParams(history.location.search);

      setIsOpen(isOpen);

      // Add a query param to the URL to indicate that the metric library is open.
      // This is used in AppCues to show a tutorial if on the tutorial URL.
      if (isOpen) {
        params.set('metricFocusViewOpen', 'true');
        if (metric.id != null) {
          params.set('visibleMetricId', metric.id);
        }

        history.replace({
          search: `?${params.toString()}`,
        });
      } else {
        params.delete('metricFocusViewOpen');
        params.delete('visibleMetricId');

        history.replace({
          search: `?${params.toString()}`,
        });
      }
    },
    [history, metric.id]
  );

  const { selectedFilters } = useParticipantFilters();

  const timeFilter = useSelector((state) => state.filters.customDashTimeFilter) || {
    startDate: new Date(),
    endDate: new Date(),
  };

  // Refresh the Muuri grid when a metric is changed. If the size changes by adding a chart the size gets messed up
  // unless we do this.
  //
  // The if statement is here to avoid calling refresh in the create/edit modal.
  // There is no Muuri there and it will error out.
  // This is technically a violation of the rules of react hooks but it doesn't
  // matter but we always either call useRefresh or do not in a single component.
  if (canUserEdit) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRefresh([metric]);
  }

  const {
    data: metricData,
    loading: metricDataLoading,
    error: metricDataError,
    runQuery,
  } = useLazyQuery(() =>
    getMetricById(
      metric.metricId,
      timeFilter.startDate,
      timeFilter.endDate,
      metric.metricConfig,
      selectedFilters
    )
  );

  // We need to do a deep diff on the fields in the metric config.
  // If any of the fields change re-run the query.
  const metricConfigJSON = JSON.stringify(metric.metricConfig);

  useEffect(() => {
    if (metric.metricId) {
      runQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metric.metricId, timeFilter, metricConfigJSON, selectedFilters]);

  const onClickSeeMore = () => {
    setIsOpenWrapper(true);
  };

  const componentName = metric?.component ?? metricData?.component;

  // to do: add download function to download the data
  const WrapperComponent = componentName != null ? widgetComponents[componentName] : null;
  const widgetSize =
    componentName != null ? getWidgetSize(componentName, metric?.metricConfig) : widgetSizes.small;
  const cardContentStyle = { ...styles.content, width: '100%', height: '100%' };
  const containerStyle = { ...styles.container, ...widgetSize };

  return (
    <div
      className={`muuri-item ${metric.metricId}`}
      data-id={metric.metricId}
      style={{ ...widgetSize }}
    >
      <div className="muuri-item-content">
        {isOpen && (
          <WidgetModal
            metric={metric}
            isOpen={isOpen}
            setIsOpen={setIsOpenWrapper}
            selectedFilters={selectedFilters}
          />
        )}
        <Card
          fluid
          dropshadow
          highlighted={isNewMetric}
          error={!!metricDataError}
          loading={metricDataLoading}
          hideHeaderDivider
          header={
            <WidgetHeader
              metric={metric}
              metricData={metricData}
              canUserEdit={canUserEdit}
              setDragIsEnabled={setDragIsEnabled}
              onEditMetric={onEditMetric}
              deleteMetricById={deleteMetricById}
              hideSeeMore={hideSeeMore}
              setSnackbarErrorMessage={setSnackbarErrorMessage}
            />
          }
          contentStyle={cardContentStyle}
          style={containerStyle}
        >
          {metricDataLoading && <SpinnerOrError loading={metricDataLoading} />}

          {metricDataError && (
            <SpinnerOrError error={!!metricDataError && 'An error occurred loading this metric'} />
          )}

          {WrapperComponent && (
            <div style={styles.widgetContent}>
              {metricData?.data?.dateRange && (
                <Text type="caption" color="caption">
                  {metricData?.data?.dateRange}
                </Text>
              )}
              <WrapperComponent metric={metric} metricData={metricData} />
              {metricData?.modal && !hideSeeMore && (
                <div style={styles.seeMoreButton}>
                  <Text type="subtitle" color="link" onClick={metricData?.modal && onClickSeeMore}>
                    See more
                  </Text>
                  <Icon
                    onClick={metricData?.modal && onClickSeeMore}
                    name="ArrowRight"
                    color="#2E62E7"
                    hoverColor="#2E62E7"
                  />
                </div>
              )}
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: 'white',
  },
  content: {
    ...layouts.container,
    ...layouts.centeredContentContainer,
    gap: 10,
    height: '100%',
  },
  widgetContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    paddingTop: 4,
    justifyContent: 'center',
    flex: 1,
    overflowY: 'auto',
  },
  seeMoreButton: { alignSelf: 'end', display: 'flex', gap: 5, backgroundColor: 'white' },
};
export default Widget;

import ProtectedRoute from '@router/ProtectedRoute';
import { Switch, useRouteMatch } from 'react-router-dom';
import { layout } from '@intus-ui';
import { CustomDashboardListView } from 'CustomDashboardV2/ListView/CustomDashboardListView';
import { CustomDashboardV2 } from 'CustomDashboardV2/CustomDashboardV2';

/**
 * This file contains all the routes for the Custom Dashboard
 */
export const CustomDashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <div style={styles.container}>
      <Switch>
        <ProtectedRoute exact path={path} component={CustomDashboardListView} />

        {/* Create a Custom Dashboard */}
        <ProtectedRoute exact path={`${path}/create`} component={CustomDashboardV2} />

        {/* Special Tutorial route that allows the AppCues tutorial */}
        <ProtectedRoute exact path={`${path}/tutorial`} component={CustomDashboardV2} />

        {/* View and edit a Custom Dashboard */}
        <ProtectedRoute exact path={`${path}/:customDashId`} component={CustomDashboardV2} />

        {/* <Route render={() => <Redirect to="/" />} /> */}
      </Switch>
    </div>
  );
};

const styles = {
  container: {
    ...layout.container,
    height: '100%',
  },
};

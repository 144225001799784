import { setCurrPatient, setPatientList } from '@global-state/redux';
import { List, SpinnerOrError } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { logEvent } from '@util/userTracking';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const FrequentFlyerTab = ({ data, loading, error }) => {
  const { frequentFlyers } = data;

  const dispatch = useDispatch();
  const history = useHistory();

  if (loading || error) {
    return <SpinnerOrError error={error && 'An error Occured'} />;
  }

  return (
    <div style={{ padding: 20 }}>
      <List
        data={frequentFlyers}
        condensed
        format={format}
        columnConfig={columnConfig.default}
        defaultSort={{ field: 'count', direction: 'desc' }}
        onClickItem={(item) => {
          logEvent(`Admits Feed, High Utilizers Tab: Clicked on participant.`);
          const currIndex = frequentFlyers?.findIndex((ppt) => ppt.id === item.id);
          dispatch(
            setPatientList(
              frequentFlyers.map((obj) => {
                return {
                  id: obj.id,
                  name: obj.name,
                };
              })
            )
          );
          dispatch(setCurrPatient(currIndex));
          history.push(`/patient/${item?.id}/admits`);
        }}
      />
    </div>
  );
};

export default FrequentFlyerTab;

const format = [
  {
    field: 'name',
    name: 'Participant Name',
    flex: 2,
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
    customStyle: {
      textOverflow: 'ellipses',
    },
  },
  {
    field: 'count',
    name: 'Admits',
    flex: 1,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="count" id="count" /> }],
  },
];

const columnConfig = {
  default: {
    columns: ['name', 'count'],
  },
};

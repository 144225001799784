/* eslint-disable prefer-arrow-callback */
import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setPasswordReset, setEditingCaregiver } from '@global-state/redux/caregiverListSlice';

import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { Icon } from '@intus-ui';
import caregiverPropType from './caregiverListCaregiverPropType';
import ManualReset from './passwordResetModal/ManualReset';
import SendEmail from './passwordResetModal/SendEmail';
import ResetTwoFactor from './passwordResetModal/ResetTwoFactor';

const PasswordResetModal = ({ show, hide, selectedCaregiver }) => {
  const options = ['Send email', 'Manually reset'];
  const resetOptions = options.map((jobType) => ({
    value: jobType,
    label: jobType,
  }));
  const [selected, setSelected] = useState(resetOptions[0].label);

  const twoFactorEnabled = localStorage.getItem('user')?.twoFactorAuthenticationEnabled === 'true';

  return (
    <Modal show={show} onHide={hide}>
      {selectedCaregiver ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <Icon name="key" className="mr-3" />
              Reset {`${selectedCaregiver.firstName} ${selectedCaregiver.lastName}'s`} Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pb-3 d-flex flex-column">
              {twoFactorEnabled && (
                <>
                  <h1 style={{ fontSize: '1rem' }}>Reset Two-factor Authentication</h1>
                  <div className="d-flex flex-column p-3 border rounded mt-2 mb-5">
                    <ResetTwoFactor />
                  </div>
                </>
              )}
              <h1 style={{ fontSize: '1rem' }}>Reset Password</h1>
              <div className="d-flex flex-column p-3 border rounded mt-2">
                <span className="text-muted">Password Reset Method</span>
                <Select
                  options={resetOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  noOptionsMessage={() => 'No Results'}
                  placeholder={selected}
                  isSearchable={false}
                  onChange={(e) => {
                    setSelected(e.value);
                  }}
                />
                <div className="pt-4">
                  {selected === resetOptions[0].label ? <SendEmail /> : <ManualReset />}
                </div>
              </div>
            </div>
          </Modal.Body>
        </>
      ) : null}
    </Modal>
  );
};

PasswordResetModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  selectedCaregiver: caregiverPropType,
};

PasswordResetModal.defaultProps = {
  selectedCaregiver: undefined,
};

const mapState = (state) => ({
  show: state.caregiverList.passwordReset,
  selectedCaregiver: state.caregiverList.selectedCaregiver,
});

const mapDispatch = (dispatch) => ({
  hide: () => {
    dispatch(setPasswordReset(false));
    dispatch(setEditingCaregiver(false));
  },
});

export default connect(mapState, mapDispatch)(PasswordResetModal);

import { getPatientAdmits } from '@api';
import { useQuery } from '@api/useQuery';
import { SpinnerOrError } from '@intus-ui';
import { useParams } from 'react-router';

import AdmitsList from './AdmitsList';

const Admits = ({ setArnAdmit, setArnIsOpen, arnIsOpen }) => {
  const { patientId } = useParams();

  const {
    data: admitsData,
    loading: admitsDataLoading,
    error: admitsDataError,
  } = useQuery(() => getPatientAdmits(patientId));

  const loadingOrError = admitsDataLoading || admitsDataError;

  return (
    <div style={{ overflow: 'hidden' }}>
      {loadingOrError && <SpinnerOrError />}
      {!loadingOrError && (
        <AdmitsList
          admitsData={admitsData}
          setArnAdmit={setArnAdmit}
          setArnIsOpen={(val) => {
            setArnIsOpen(val);
          }}
          arnIsOpen={arnIsOpen}
        />
      )}
    </div>
  );
};

export default Admits;

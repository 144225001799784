import { get } from '@api/apiMethods';
import { IMedicationClass } from '@api/polypharmacy/medicationClass/getAllMedicationClasses';
import { IAllStarsMeasures } from 'Polypharmacy/types/STARSMeasures';

type GetPatientMedicationsWithFiltersParam = {
  patientId: number;
  active?: boolean | null;
  startDate?: string | null;
  endDate?: string | null;
  medClass?: IMedicationClass | null;
  starsMeasure?: 'all' | IAllStarsMeasures | null;
  starsMeasureYear?: number | null;
  isRisingStars?: boolean | null;
};

type Medication = {
  id: number;
  name: string;
  genericName: string | null;
  dosage: string | null;
  physician: string | null;
  sig: string | null;
  startDate: string;
  endDate: string | null;
  pharmClassEpc: string[];
  refillNumber: number | null;
  refillsAllowed: number | null;
  daysSupply: number | null;
  isGeneric: boolean | null;
  source: 'ORDER' | 'CLAIM';
  ndc: string | null;
  dateOrdered: string | null;
  dateFilled: string | null;
};

export const getPatientMedicationsWithFilters = async (
  params: GetPatientMedicationsWithFiltersParam
) => {
  const {
    patientId,
    startDate,
    endDate,
    active,
    medClass,
    starsMeasure,
    starsMeasureYear,
    isRisingStars,
  } = params;

  const searchParams = new URLSearchParams();
  if (startDate != null) searchParams.set('startDate', startDate);
  if (endDate != null) searchParams.set('endDate', endDate);
  if (active != null) searchParams.set('active', active.toString());
  if (starsMeasure != null) searchParams.set('starsMeasure', starsMeasure);
  if (starsMeasureYear != null) searchParams.set('starsMeasureYear', starsMeasureYear.toString());
  if (isRisingStars != null) searchParams.set('isRisingStars', isRisingStars.toString());

  if (medClass != null) {
    searchParams.set('gpi', medClass.gpi);
    searchParams.set('gpiType', medClass.gpiType);
  }

  const url = `patients/${patientId}/medicationsWithFilters?${searchParams.toString()}`;

  return get<Medication[]>(url);
};

import { formatDate, newDate } from '@util/dateFunctions';

export function getTrialDateText(trial) {
  const endDateText =
    trial?.endDate !== null && trial?.endDate !== undefined
      ? formatDate(newDate(trial?.endDate), 'MM/DD/YY')
      : 'Ongoing';
  const startDateText = formatDate(newDate(trial?.startDate), 'MM/DD/YY');

  return `${startDateText} - ${endDateText}`;
}

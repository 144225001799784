import Text from './Text';

const Badge = ({ text, bgColor = '#052D8F', textColor = 'disabled', style }) => {
  const stringText = String(text).substring(0, 3);
  const is3characters = stringText.length === 3;
  return (
    <div style={{ ...styles.badge, backgroundColor: bgColor, ...style }}>
      <Text type={is3characters ? 'tiny-bold' : 'caption-bold'} color={textColor}>
        {stringText}
      </Text>
    </div>
  );
};

const styles = {
  badge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 22,
    height: 22,
  },
};
export default Badge;

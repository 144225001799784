/* eslint-disable import/named */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getSessionUser, clearSession } from '@util/session';

import { changeMyOrg } from '@api/api';

import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import FilteredList from '@intus-ui/components/FilteredList';
import StatusCircle from '@intus-ui/components/StatusCircle';
import { removeMultipleLocalData } from '@util/localStorage';
import OrganizationModal from './OrganizationModal';
import AddOrganizationModal from './AddOrganizationModal';

import OrganizationListPropType from './OrganizationListPropType';

const OrganizationList = ({ history, orgs }) => {
  const testOrg = {
    id: -1,
    name: 'Test Organization (you should never see this)',
    activeValue: 'INACTIVE',
    patientCount: 4,
    caregiverCount: 20,
    subscriptions: [],
    setCurrOrg: -1,
    setCurrOrgNode: <Button>switch to this org</Button>,
  };
  const [editMode, setEditMode] = useState(false);
  const [addOrg, setAddOrg] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(testOrg);
  const [activeOrgs, setActiveOrgs] = useState(true);

  const addOrganization = () => {
    setAddOrg(true);
  };
  const hideModal = () => {
    setEditMode(false);
    setAddOrg(false);
  };
  const selectOrg = (item) => {
    setSelectedOrg(item);
    setEditMode(true);
  };
  const button = (
    <Button className="ml-4 rounded-lg" onClick={addOrganization}>
      Add Organization
    </Button>
  );

  const items = orgs.map((org) => ({
    id: org.id,
    name: org.name,
    activeNode: <StatusCircle status={org.status} />,
    activeValue: org.status,
    adminCount: org.admins,
    patientCount: org.patients,
    caregiverCount: org.caregivers,
    subscriptions: org.subscriptions,
    updatedAt: org.updatedAt || 'never updated',
    setCurrOrg: org.id,
    setCurrOrgNode: (
      <Button
        disabled={org.status === 'INACTIVE'}
        onClick={async (e) => {
          e.stopPropagation();
          if (
            // eslint-disable-next-line no-alert, no-restricted-globals
            confirm(
              `Are you sure you want to change your organization to ${org.name}?\n\nYou will be automatically logged out.`
            )
          ) {
            const user = getSessionUser();
            user.organization = { id: org.id, subscriptions: org.subscriptions };
            user.organizationId = org.id;
            await changeMyOrg({ organizationId: org.id });

            removeMultipleLocalData(['teamFilter', 'facilityFilter', 'cohortFilter']);

            clearSession('/home');
            // setSessionUser(user);

            // // kinda wacky but doesn't really matter for admin portal
            // setTimeout(() => {
            //   setEditMode(false);
            // }, 0);

            // changeOrg(org.name);
          }
        }}
        variant="outline-primary"
      >
        Set Current Org
      </Button>
    ),
  }));

  const format = [
    {
      valueField: 'name',
      nodeField: 'name',
      md: 4,
      className: 'text-dark',
      sorting: 'lexographical',
      heading: 'Name',
    },
    {
      valueField: 'activeValue',
      nodeField: 'activeNode',
      md: 2,
      className: 'text-secondary',
      sorting: 'lexographical',
      heading: 'Active',
    },
    {
      valueField: 'patientCount',
      nodeField: 'patientCount',
      md: 2,
      sorting: 'numerical',
      heading: 'Participants',
    },
    {
      valueField: 'caregiverCount',
      nodeField: 'caregiverCount',
      md: 2,
      sorting: 'numerical',
      heading: 'Caregivers',
    },
    {
      valueField: 'setCurrOrg',
      nodeField: 'setCurrOrgNode',
      md: 2,
      sorting: 'none',
      heading: 'Set Current Org',
    },
  ];

  const titleNode = (
    <div className="d-flex justify-content-center align-items-center">
      <div className="mr-2">Organizations</div>
      <DropdownButton
        size="sm"
        onClick={(event) =>
          event.target.id === 'dropdown-button-active-org' ? null : setActiveOrgs(event.target.id)
        }
        id="dropdown-button-active-org"
        title={activeOrgs === 'Inactive' ? 'Inactive' : 'Active'}
        className="pl-2"
      >
        <Dropdown.Item id="Active">Active</Dropdown.Item>
        <Dropdown.Item id="Inactive">Inactive</Dropdown.Item>
      </DropdownButton>
    </div>
  );

  const filteredItems = items.filter((org) =>
    activeOrgs === 'Inactive' ? org.activeValue === 'INACTIVE' : org.activeValue === 'ACTIVE'
  );
  return (
    <div className="w-100 h-100 d-flex justify-content-start align-items-center flex-column px-3">
      <AddOrganizationModal show={addOrg} hide={hideModal} />
      <OrganizationModal history={history} show={editMode} hide={hideModal} info={selectedOrg} />
      <FilteredList
        title={titleNode}
        format={format}
        items={filteredItems}
        search={{
          valueField: 'name',
          name: 'Organization Name',
        }}
        toolbar={button}
        onClick={(item) => selectOrg(item)}
      />
    </div>
  );
};

OrganizationList.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  orgs: PropTypes.arrayOf(OrganizationListPropType.isRequired),
};

OrganizationList.defaultProps = {
  orgs: [],
};

const mapState = (state) => ({
  orgs: state.admin.orgs,
});

export default withRouter(connect(mapState)(OrganizationList));

import { MenuItem, Select } from '@mui/material';
import {
  IAllStarsMeasures,
  allStarsMeasures,
  starsMeasureToName,
} from 'Polypharmacy/types/STARSMeasures';
import React, { FC } from 'react';

export type IPossibleMeasures = 'all' | IAllStarsMeasures;

type StarsMeasureDropdownProps = {
  /** True to allow the All option */
  allowAll?: boolean;
  /** True to allow the 'Statins for primary prevention' measure as an option */
  allowMipsMeasure?: boolean;
  value: IPossibleMeasures;
  onChange: (measure: IPossibleMeasures) => void;
};

export const StarsMeasureDropdown: FC<StarsMeasureDropdownProps> = ({
  allowAll = true,
  allowMipsMeasure = false,
  value,
  onChange,
}) => {
  let starsMeasures = allStarsMeasures as readonly IAllStarsMeasures[];

  if (!allowMipsMeasure) {
    starsMeasures = starsMeasures.filter(
      (m) => m !== 'MIPS_PrimaryPreventionStatinMedicationAdherence'
    );
  }

  return (
    <Select
      label="Select a Measure"
      value={value}
      onChange={(e) => onChange(e.target.value as IPossibleMeasures)}
      sx={{ width: 300 }}
    >
      {allowAll && (
        <MenuItem value="all" key="all">
          All Measures
        </MenuItem>
      )}
      {starsMeasures.map((m) => {
        return (
          <MenuItem value={m} key={m}>
            {starsMeasureToName[m]}
          </MenuItem>
        );
      })}
    </Select>
  );
};

import { ICCSRCategory, IICDCode, getCurrentConditions } from '@api/patients/getCurrentConditions';
import { useQuery } from '@api/useQuery';
import { Icon, List, SpinnerOrError, Text } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { IListColumnConfig, IListFormat } from '@intus-ui/components/List/types/IListFormat';
import { ButtonBase } from '@mui/material';
import { ICDCodeModal } from 'Participants/acuity/diagnoses/ICDCodeModal';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';

type CCSRCategoryInfo = ICCSRCategory & {
  icdCodes: IICDCode[];
};

export const PatientCCSRDiagnoses: React.FC = () => {
  const params = useParams<{ patientId: string }>();
  const patientId = parseInt(params.patientId);

  const [selectedCCSR, setSelectedCCSR] = useState<CCSRCategoryInfo | null>(null);

  const { data, loading, error } = useQuery(() => getCurrentConditions(patientId));

  const format = useMemo(() => {
    return getFormat(setSelectedCCSR);
  }, []);

  const ccsrCategories = useMemo<CCSRCategoryInfo[]>(() => {
    if (!data) return [];

    const categoryToCodes = new Map<string, CCSRCategoryInfo>();
    for (const ccsrData of data) {
      for (const category of ccsrData.ccsrCategories) {
        const { ccsrCategory } = category;
        if (!categoryToCodes.has(ccsrCategory)) {
          categoryToCodes.set(ccsrCategory, {
            ccsrCategory,
            ccsrCategoryDescription: category.ccsrCategoryDescription,
            icdCodes: [],
          });
        }

        const categoryInfo = categoryToCodes.get(ccsrCategory)!;
        categoryInfo.icdCodes.push({
          icdCode: ccsrData.icdCode,
          icdCodeDescription: ccsrData.icdCodeDescription,
        });
      }
    }

    return Array.from(categoryToCodes.values());
  }, [data]);

  if (error) {
    return <SpinnerOrError error="An error ocurred loading the diagnoses for the patient" />;
  }

  if (loading || !data) {
    return <SpinnerOrError />;
  }

  return (
    <>
      <List
        listStyle="striped"
        clickable={false}
        data={ccsrCategories}
        format={format}
        columnConfig={columns}
        defaultSort={{ field: 'ccsrCategoryDescription', direction: 'asc' }}
      />
      {selectedCCSR && (
        <ICDCodeModal
          title={selectedCCSR.ccsrCategoryDescription}
          open={selectedCCSR != null}
          onClose={() => setSelectedCCSR(null)}
          icdCodes={selectedCCSR.icdCodes}
        />
      )}
    </>
  );
};

function getFormat(setSelectedCCSR: (ccsr: CCSRCategoryInfo | null) => void) {
  const format: IListFormat<CCSRCategoryInfo>[] = [
    {
      field: 'ccsrCategoryDescription',
      name: 'CCSR Description',
      render: ({ item }) => {
        return <Text style={{ whiteSpace: 'normal' }}>{item.ccsrCategoryDescription}</Text>;
      },
      addOns: [
        {
          type: 'sort',
          element: <SortIcon id="ccsrCategoryDescription" key="ccsrCategoryDescription" />,
        },
      ],
    },
    {
      // Not a real field, just used to custom render the ICD code link
      field: 'icdCodes',
      name: '',
      flex: '0 0 152px',
      render: ({ item }) => {
        return (
          <ButtonBase onClick={() => setSelectedCCSR(item)}>
            <Text type="body" color="link">
              <b>See ICD-10 codes</b>
              <Icon name="ArrowRight" color="link" size="medium" />
            </Text>
          </ButtonBase>
        );
      },
    },
  ];

  return format;
}

const columns: IListColumnConfig<CCSRCategoryInfo> = {
  columns: ['ccsrCategoryDescription', 'icdCodes'],
};

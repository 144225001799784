/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getAllIndicatorsFlat,
  getCustomDashIndicatorsAPI,
  getDashItem,
  getDashItemsByList,
  getOrgDashIndicatorsAPI,
} from '@api/api';
import { getLogger } from '@util/logger';
import { setShowNotification } from '@global-state/redux/notificationSlice';
import { getSessionUser } from '@util/session';
import { ORG_DASH } from '../dashDataPropTypes/currentDashPropType';

const log = getLogger('customDashIndicatorsSlice');

export const MAX_INDICATORS = 32;

const customDashIndicatorsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.position - b.position,
});

const customDashIndicatorSlice = createSlice({
  name: 'customDashIndicator',
  initialState: customDashIndicatorsAdapter.getInitialState({
    status: 'idle',
    // this is a copy of all the indicators to allow for easy editing and reverting
    initialIndicators: [],
    customDashItemsFlat: undefined,
  }),
  reducers: {
    indicatorAdded: customDashIndicatorsAdapter.addOne,
    indicatorsAdded: customDashIndicatorsAdapter.addMany,
    indicatorUpdated: customDashIndicatorsAdapter.updateOne,
    indicatorsUpdated: customDashIndicatorsAdapter.updateMany,
    indicatorDeleted: customDashIndicatorsAdapter.removeOne,
    indicatorStatusUpdate: (state, action) => {
      state.status = action.payload;
    },
    indicatorsReceived: (state, action) => {
      if (state.status === 'loading') {
        customDashIndicatorsAdapter.setAll(state, action.payload);
        state.initialIndicators = action.payload;
        state.status = 'idle';
      }
    },
    indicatorsReset: (state) => {
      customDashIndicatorsAdapter.setAll(state, state.initialIndicators);
    },
    updateInitialIndicators: (state) => {
      state.initialIndicators = Object.values(state.entities);
    },
    setCustomDashItemsFlat(state, action) {
      state.customDashItemsFlat = action.payload;
    },
  },
});

export default customDashIndicatorSlice.reducer;

export const {
  indicatorAdded,
  indicatorsAdded,
  indicatorUpdated,
  indicatorsUpdated,
  indicatorDeleted,
  indicatorStatusUpdate,
  indicatorsReceived,
  indicatorsReset,
  updateInitialIndicators,
  setCustomDashItemsFlat,
} = customDashIndicatorSlice.actions;

export const {
  selectById: selectDashIndicatorById,
  selectIds: selectDashIndicatorIds,
  selectEntities: selectDashIndicatorEntities,
  selectAll: selectDashIndicatorsAll,
  selectTotal: selectDashIndicatorsTotal,
} = customDashIndicatorsAdapter.getSelectors((state) => state.customDashIndicators);

export const selectIndicatorValByPos = (index) =>
  createSelector(selectDashIndicatorsAll, (allIndicators) => allIndicators[index]?.value);

/**
 * Will format a value based on the format.
 * @param {Object} res the value to format
 */
const formatValue = (res) => {
  if (res) {
    const { value, format } = res;
    let val = value;
    if (val !== 'N/A') {
      if (format === 'PERCENTAGE') val = `${val.toFixed(1)}%`;
      else if (format === 'MONEY') val = `$${val}`;
      else if (format === 'DOUBLE') val = val.toFixed(1);
      else if (format === 'INT') val = val.toFixed(0);
    }
    return val;
  }
  return null;
};

/**
 * Will get the values for the specified indicators and set the active indicators according to
 * the value received.
 * @param {Array} unparsedIndicators indicators to get values for
 * @param {Object} filters the filters applied to these indicators
 */
const getIndicatorValues = (unparsedIndicators, filters) => async (dispatch) => {
  const { dashboardIndicators = [], cohortDashboardIndicators } = unparsedIndicators;
  const dashboardIndicatorList = [];
  for (let i = 0; i < dashboardIndicators?.length; i += 1) {
    if (dashboardIndicators[i] !== null) {
      dashboardIndicators[i].fetchInfo = {
        ...dashboardIndicators[i].fetchInfo,
      };
      dashboardIndicatorList.push(dashboardIndicators[i]);
    }
  }

  const {
    patientFilters,
    timeFilter: { startDate, endDate },
  } = filters;
  const time = { start: startDate, end: endDate };

  const globalConstraints = {};
  globalConstraints.participants = { filters: patientFilters };

  try {
    const res = await getDashItemsByList({
      dashboardIndicatorList,
      cohortDashboardIndicators,
      time,
      globalConstraints,
    });
    if (res.ok) {
      const resJ = await res.json();
      const customIndicators = dashboardIndicators?.filter((indicator) => indicator.id);
      const customResJ = resJ?.filter((indicator) => !indicator?.id);
      // Make sure cohort information is retained and can be added again later
      const cohortResJ = resJ?.filter((indicator) => indicator?.id);

      // Adding important values to the dashboard indicators using the response above
      const indicatorsWithValues = customIndicators?.map((indicator, index) => {
        return {
          ...indicator,
          value: formatValue(customResJ[0][index]),
          position: index,
          participantIds: customResJ[0][index].payload?.participantIds,
          allowDuplicatePatients: customResJ[0][index].payload?.allowDuplicatePatients,
        };
      });

      // adding formatted cohorts back to indicators with values
      if (cohortResJ.length) {
        for (let i = 0; i < cohortResJ.length; i += 1) {
          indicatorsWithValues.push(cohortResJ[i]);
        }
      }

      dispatch(indicatorsReceived(indicatorsWithValues));
    } else {
      log.error('could not get indicator values');
      dispatch(
        setShowNotification(
          'Error fetching data. Please check your network connection and refresh.'
        )
      );
    }
  } catch (e) {
    log.error(e);
    dispatch(indicatorStatusUpdate('error'));
    dispatch(
      setShowNotification('Error fetching data. Please check your network connection and refresh.')
    );
  }
};

/**
 * Will retrieve the indicators in a dashboard and call getIndicatorValues.
 * @param {Object} dashInfo the info relating to the dashboard
 * @param {Object} filters the filters applied to this fetch
 */
export const getCustomDashIndicators = (dashInfo, filters) => async (dispatch) => {
  dispatch(indicatorStatusUpdate('loading'));
  try {
    const user = getSessionUser();
    const res =
      // Getting the indicators for the current custom dash based on the user id and the dash id
      dashInfo.type === ORG_DASH
        ? await getOrgDashIndicatorsAPI(user.organization.id, dashInfo.id)
        : await getCustomDashIndicatorsAPI(user.id, dashInfo.id);
    if (res.ok) {
      // The last element of the response should have a list of cohort IDs
      const resJ = await res.json();
      // This will format the cohort list as well as the other indicators
      dispatch(getIndicatorValues(resJ, filters));
    } else {
      // If there are no indicators we will still fetch, but with an empty array.
      const emptyRes = [];
      dispatch(getIndicatorValues(emptyRes, filters));
    }
  } catch (e) {
    dispatch(indicatorStatusUpdate('error'));
    log.error(e);
    dispatch(
      setShowNotification('Error fetching data. Please check your network connection and refresh.')
    );
  }
};

/**
 * Retrieves a single indicator. ie when a dash item moves from the list into the active board.
 * This also handles replacing an item that was previously there (if any).
 * @param {Object} indicator the indicator to fetch
 * @param {Object} filters the filters to be applied to the fetch
 * @param {Number} toDelete the indicator to delete (if we are replacing it)
 */
export const getSingleIndicator = (indicator, filters, toDelete) => async (dispatch) => {
  dispatch(indicatorStatusUpdate('loading'));
  const {
    patientFilters,
    timeFilter: { startDate, endDate },
  } = filters;
  const time = { start: startDate, end: endDate };

  try {
    let constraints;
    let res;
    if (
      indicator.fetchInfo.dashboardType !== 'action' &&
      indicator.fetchInfo.dashboardType !== 'shared' &&
      indicator.fetchInfo.dashboardType !== 'personal'
    ) {
      const participants = {
        filters: patientFilters,
      };

      constraints = {
        time,
        participants,
      };
      res = await getDashItem({
        fetchInfo: { ...indicator.fetchInfo },
        constraints,
      });
      if (res.ok) {
        const resJ = await res.json();
        const indicatorWithValue = {
          ...indicator,
          value: formatValue(resJ),
          participantIds: resJ.payload?.participantIds,
          allowDuplicatePatients: resJ.payload?.allowDuplicatePatients,
        };
        if (toDelete !== undefined) dispatch(indicatorDeleted(toDelete)); // case where we replace
        dispatch(indicatorAdded(indicatorWithValue));
        dispatch(indicatorStatusUpdate('idle'));
      }
    } else if (
      indicator.fetchInfo.dashboardType === 'action' ||
      indicator.fetchInfo.dashboardType === 'personal' ||
      indicator.fetchInfo.dashboardType === 'shared'
    ) {
      dispatch(indicatorAdded(indicator));
      dispatch(indicatorStatusUpdate('idle'));
    } else {
      log.error('could not fetch indicator');
      dispatch(indicatorStatusUpdate('error'));
      dispatch(
        setShowNotification(
          'Error fetching data. Please check your network connection and refresh.'
        )
      );
    }
  } catch (e) {
    log.error(e);
    dispatch(indicatorStatusUpdate('error'));
    dispatch(
      setShowNotification('Error fetching data. Please check your network connection and refresh.')
    );
  }
};

/**
 * gets all indicators by indicator name to info
 */
export const fetchFlatIndicators = () => async (dispatch) => {
  try {
    const res = await getAllIndicatorsFlat();
    if (res.ok) {
      const resJ = await res.json();
      dispatch(setCustomDashItemsFlat(resJ));
    } else {
      log.error('could not get flat indicators');
    }
  } catch (e) {
    log.error(e);
  }
};

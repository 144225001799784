import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getStringReplacement } from '@util/stringReplacements';
import { getLogger } from '@util/logger';
import { useDispatch } from '@global-state';
import { getAllPatients } from '@api';
import { SearchInputDropdown } from '@intus-ui';

import {
  setPage,
  setCurrPatient,
  getPptRecentStats,
  setPatientList,
} from '@global-state/redux/profileSlice';
import { getRecentHospitalizations } from '@global-state/redux/hospitalizationsSlice';
import { getPatientCarePlanInfo } from '@global-state/redux/carePlanSlice';
import { getPatientAcuityInfo } from '@global-state/redux/acuitySlice';

import { getPatient, getPatientIncidents } from '@global-state/redux/patientDetailSlice';

const log = getLogger('SearchBar');

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [patients, setPatients] = useState([]);
  const [patientNames, setPatientsNames] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnSearch = (value) => {
    setSearchTerm(value);
  };
  const handleOnSelect = (value) => {
    setSearchTerm(value);
    const patientId = patients[patientNames.indexOf(value)].id;
    const abbreviatedPpts = patients.map((patient) => ({
      id: patient.id,
      name: patient.name ? (`${patient.name} (${patient.memberId})`) : (`${patient.firstName} ${patient.lastName} (${patient.memberId})`),
    }));
    const currIndex = abbreviatedPpts?.findIndex((ppt) => ppt.id === patientId);
    dispatch(setPatientList(abbreviatedPpts));
    dispatch(setCurrPatient(currIndex));
    dispatch(getPatient(patientId));
    dispatch(getPatientAcuityInfo(patientId));
    dispatch(getPatientCarePlanInfo(patientId));
    dispatch(getRecentHospitalizations(patientId));
    dispatch(getPatientIncidents(patientId));
    dispatch(getPptRecentStats(patientId));
    dispatch(setPage());
    history.push(`/patient/${patientId}/current-acuity`);
    setSearchTerm('');
  };

  useEffect(() => {
    getAllPatients()
      .then((res) => res.json())
      .then((patientList) => {
        const enrolledPatients = patientList.filter((patient) => !patient?.disenrollmentDate);
        setPatients(enrolledPatients);
        const pNames = enrolledPatients.map(
          ({ firstName, lastName, memberId }) => `${firstName} ${lastName} (${memberId})`
        );
        setPatientsNames(pNames);
      })
      .catch((err) => {
        log.error(err);
      });
  }, []);

  const handleOnClose = () => {
    setSearchTerm('');
  };
  return (
    <SearchInputDropdown
      disabled={false}
      placeholder={`Search for ${getStringReplacement('Participant', { lower: true })}`}
      onSearch={handleOnSearch}
      onClose={handleOnClose}
      onChange={handleOnSelect}
      searchTerm={searchTerm}
      caption="Username available"
      error={searchTerm === 'error'}
      success={searchTerm === 'success'}
      options={patientNames}
      noMatchLabel="No match"
    />
  );
};

export default SearchBar;

import PropTypes from 'prop-types';

const AdminListPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  status: PropTypes.string.isRequired,
  access: PropTypes.string.isRequired,
  mandatoryPasswordChange: PropTypes.bool.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  organizationId: PropTypes.number.isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

export default AdminListPropType;

const payload = [
  {
    participant: { id: 14, name: 'Kirstin Hartmann' },
    date: '01/10/2023',
    events: [
      {
        type: 'progressNote',
        title: 'New progress note',
        keywords: ['chest tightness', 'shortness of breath'],
        date: { latest: '01/10/2023', lastChange: '01/5/2023' },
      },
      {
        type: 'CRI',
        title: 'Sitting Blood Pressure',
        code: 'BP',
        current: {
          value: '140/74',
          severity: 'high',
          date: '01/10/2023',
        },
        previous: {
          value: '140/74',
          severity: 'low',
          date: '01/09/2023',
        },
      },
      {
        title: 'Hemoglobin',
        code: 'HA1C',

        current: {
          value: 6.8,
          severity: 'low',
          date: '01/10/2023',
        },
        previous: {
          value: 5.9,
          severity: 'low',
          date: '01/09/2023',
        },
      },
      {
        title: 'BMI',
        code: 'BMI',
        current: {
          value: 18.5,
          severity: 'low',
          date: '01/10/2023',
        },
        previous: {
          value: 24.9,
          severity: 'low',
          date: '01/09/2023',
        },
      },
    ],
  },
  {
    participant: { id: 12, name: 'Keenan Schneider' },
    date: '01/10/2023',
    events: [
      {
        type: 'CRI',
        title: 'Sitting Blood Pressure',
        code: 'BP',
        current: {
          value: '140/74',
          severity: 'low',
          date: '01/10/2023',
        },
        previous: {
          value: '150/76',
          severity: 'low',
          date: '01/09/2023',
        },
      },
      {
        type: 'CRI',
        title: 'Hemoglobin',
        code: 'HA1C',

        current: {
          value: 6.8,
          severity: 'low',
          date: '01/10/2023',
        },
        previous: {
          value: 5.9, //
          severity: 'low',
          date: '01/09/2023',
        },
      },
      {
        type: 'CRI',
        title: 'Pulse oxygen',
        code: 'POx',

        current: {
          value: 96,
          severity: 'low',
          date: '01/10/2023',
        },
        previous: {
          value: 97,
          severity: 'low',
          date: '01/09/2023',
        },
      },
      {
        type: 'CRI',
        title: 'Blood glucose',
        code: 'BG',

        current: {
          value: 102,
          severity: 'low',
          date: '01/10/2023',
        },
        previous: {
          value: 125, //
          severity: 'low',
          date: '01/09/2023',
        },
      },
      {
        type: 'CRI',
        title: 'BMI',
        code: 'BMI',

        current: {
          value: 18.5,
          severity: 'low',
          date: '01/10/2023',
        },
        previous: {
          value: 24.9, //
          severity: 'low',
          date: '01/09/2023',
        },
      },
    ],
  },
  {
    participant: { id: 42, name: 'Dulce Block' },
    date: '01/10/2023',
    events: [
      {
        type: 'CRI',
        title: 'Sitting Blood Pressure',
        code: 'BP',

        current: {
          value: '140/74',
          severity: 'low',
          date: '01/10/2023',
        },
        previous: {
          value: '150/76',
          severity: 'low',
          date: '01/09/2023',
        },
      },
      {
        type: 'CRI',
        title: 'Hemoglobin',
        code: 'HA1C',

        current: {
          value: 6.8,
          severity: 'high',
          date: '01/10/2023',
        },
        previous: {
          value: 5.9,
          severity: 'low',
          date: '01/09/2023',
        },
      },
      {
        type: 'CRI',
        title: 'BMI',
        code: 'BMI',

        current: {
          value: 18.5,
          severity: 'low',
          date: '01/10/2023',
        },
        previous: {
          value: 24.9,
          severity: 'low',
          date: '01/09/2023',
        },
      },
    ],
  },
  {
    participant: { id: 12, name: 'Keenan Schneider' },
    date: '01/09/2023',
    events: [
      {
        type: 'CRI',
        title: 'Sitting Blood Pressure',
        code: 'BP',

        current: {
          value: '140/74',
          severity: 'low',
          date: '01/09/2023',
        },
        previous: {
          value: '150/76',
          severity: 'low',
          date: '01/08/2023',
        },
      },
      {
        type: 'CRI',
        title: 'Hemoglobin',
        code: 'HA1C',

        current: {
          value: 6.8,
          severity: 'low',
          date: '01/09/2023',
        },
        previous: {
          value: 5.9,
          severity: 'low',
          date: '01/08/2023',
        },
      },
      {
        type: 'CRI',
        title: 'BMI',
        code: 'BMI',

        current: {
          value: 18.5,
          severity: 'low',
          date: '01/09/2023',
        },
        previous: {
          value: 24.9,
          severity: 'low',
          date: '01/08/2023',
        },
      },
    ],
  },
  {
    participant: { id: 14, name: 'Kirstin Hartmann' },
    date: '01/09/2023',
    events: [
      {
        type: 'CRI',
        title: 'Sitting Blood Pressure',
        code: 'BP',

        current: {
          value: '140/74',
          severity: 'low',
          date: '01/09/2023',
        },
        previous: {
          value: '150/76',
          severity: 'low',
          date: '01/08/2023',
        },
      },
      {
        type: 'CRI',
        title: 'Hemoglobin',
        code: 'HA1C',

        current: {
          value: 6.8,
          severity: 'low',
          date: '01/09/2023',
        },
        previous: {
          value: 5.9,
          severity: 'low',
          date: '01/08/2023',
        },
      },
      {
        type: 'CRI',
        title: 'BMI',
        code: 'BMI',

        current: {
          value: 18.5,
          severity: 'low',
          date: '01/09/2023',
        },
        previous: {
          value: 24.9,
          severity: 'low',
          date: '01/08/2023',
        },
      },
    ],
  },
  {
    participant: { id: 44, name: 'Adolph Brekke' },
    date: '01/08/2023',
    events: [
      {
        type: 'CRI',
        title: 'Sitting Blood Pressure',
        code: 'BP',

        current: {
          value: '140/74',
          severity: 'low',
          date: '01/08/2023',
        },
        previous: {
          value: '150/76',
          severity: 'low',
          date: '01/07/2023',
        },
      },
      {
        type: 'CRI',
        title: 'Hemoglobin',
        code: 'HA1C',

        current: {
          value: 6.8,
          severity: 'low',
          date: '01/08/2023',
        },
        previous: {
          value: 5.9,
          severity: 'low',
          date: '01/07/2023',
        },
      },
      {
        type: 'CRI',
        title: 'BMI',
        code: 'BMI',

        current: {
          value: 18.5,
          severity: 'low',
          date: '01/08/2023',
        },
        previous: {
          value: 24.9,
          severity: 'low',
          date: '01/07/2023',
        },
      },
    ],
  },
  {
    participant: { id: 42, name: 'Dulce Block' },
    date: '01/08/2023',
    events: [
      {
        type: 'CRI',
        title: 'Sitting Blood Pressure',
        code: 'BP',

        current: {
          value: '140/74',
          severity: 'low',
          date: '01/08/2023',
        },
        previous: {
          value: '150/76',
          severity: 'low',
          date: '01/07/2023',
        },
      },
      {
        type: 'CRI',
        title: 'Hemoglobin',
        code: 'HA1C',

        current: {
          value: 6.8,
          severity: 'low',
          date: '01/08/2023',
        },
        previous: {
          value: 5.9,
          severity: 'low',
          date: '01/07/2023',
        },
      },
      {
        type: 'CRI',
        title: 'BMI',
        code: 'BMI',

        current: {
          value: 18.5,
          severity: 'low',
          date: '01/08/2023',
        },
        previous: {
          value: 24.9,
          severity: 'low',
          date: '01/07/2023',
        },
      },
    ],
  },
  {
    participant: { id: 14, name: 'Niall Bonner' },
    date: '01/08/2023',
    events: [
      {
        type: 'CRI',
        title: 'Sitting Blood Pressure',
        code: 'BP',

        current: {
          value: '140/74',
          severity: 'low',
          date: '01/08/2023',
        },
        previous: {
          value: '150/76',
          severity: 'low',
          date: '01/07/2023',
        },
      },
      {
        type: 'CRI',
        title: 'Hemoglobin',
        code: 'HA1C',

        current: {
          value: 6.8,
          severity: 'low',
          date: '01/08/2023',
        },
        previous: {
          value: 5.9,
          severity: 'low',
          date: '01/07/2023',
        },
      },
      {
        type: 'CRI',
        title: 'BMI',
        code: 'BMI',

        current: {
          value: 18.5,
          severity: 'high',
          date: '01/08/2023',
        },
        previous: {
          value: 24.9,
          severity: 'low',
          date: '01/07/2023',
        },
      },
    ],
  },
];

export default payload;

export const formatMetricCategoryList = (metricList) => {
  const formattedMetricList = [];
  metricList.forEach((item) => {
    item.metrics?.forEach((metric) => {
      const formattedMetric = {
        category: item.category,
        id: metric.id,
        name: metric.name,
        component: metric.component,
      };
      formattedMetricList.push(formattedMetric);
    });
  });
  return formattedMetricList;
};

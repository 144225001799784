import { useMemo } from 'react';
import { DateRangeDropdown, Text, Divider } from '@intus-ui';
import { findMonthOptionByLabel } from '@intus-ui/components/DateRange/config';
import { formatDateRangeText } from '@intus-ui/components/DateRangeDropdown';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import { CCSRAutocomplete } from 'Polypharmacy/components/CCSRAutocomplete';

const INITIAL_TIME_FILTER = 'Past 6 Months';
const TIME_FILTER_RANGES = [3, 6, 12];

const timeFilter = findMonthOptionByLabel(INITIAL_TIME_FILTER);

export const INITIAL_FILTERS = {
  startDate: timeFilter.start,
  endDate: timeFilter.end,
  medSupplyMin: undefined,
  medSupplyMax: undefined,
  team: 'All',
  facility: 'All',
  ccsr: null,
  erCount: undefined,
  ipCount: undefined,
  erAndIpCount: undefined,
};

const Filters = ({ filters, setFilters }) => {
  const { pathname } = useLocation();

  const dateRangeText = useMemo(() => {
    return formatDateRangeText(TIME_FILTER_RANGES, filters.startDate, filters.endDate);
  }, [filters.endDate, filters.startDate]);

  const disableDateFilled = pathname.includes('/adherence');

  const handleCCSRChange = (option) => {
    setFilters({ ...filters, ccsr: option });
  };

  return (
    <div style={styles.filters}>
      <div style={styles.filterSection}>
        <Text color="navy" type="subtitle">
          Adjust dataset by:
        </Text>
        <Divider />
        <div>
          <Text type="subtitle">Date Filled*</Text>
          <DateRangeDropdown
            disabled={disableDateFilled}
            dateRangeDirection="right"
            range={TIME_FILTER_RANGES}
            value={dateRangeText}
            onChange={async (val) => {
              setFilters({ ...filters, startDate: val.start, endDate: val.end });
            }}
            onApplyRange={(val) => {
              setFilters({ ...filters, startDate: val.start, endDate: val.end });
            }}
            style={{ width: 220 }}
          />
        </div>
        <div>
          <Text type="subtitle">Fill Days Supply*</Text>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', width: '220px', gap: 20 }}
          >
            <div>
              <Text type="caption" style={{ paddingRight: 10 }}>
                Min
              </Text>
              <input
                disabled={disableDateFilled}
                min="0"
                type="number"
                className="hover-input-light-blue"
                value={filters.medSupplyMin ?? ''}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    medSupplyMin: isEmpty(e.target.value) ? null : parseInt(e.target.value, 10),
                  })
                }
                style={{
                  height: 35,
                  width: 100,
                  paddingLeft: 15,
                  backgroundColor: 'white',
                  border: '1px solid #9D9D9D',
                  borderRadius: 5,
                }}
              />
            </div>
            <div>
              <Text type="caption" style={{ paddingRight: 10 }}>
                Max
              </Text>
              <input
                disabled={disableDateFilled}
                min="1"
                type="number"
                className="hover-input-light-blue"
                value={filters.medSupplyMax ?? ''}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    medSupplyMax: isEmpty(e.target.value) ? null : parseInt(e.target.value, 10),
                  })
                }
                style={{
                  height: 35,
                  width: 100,
                  paddingLeft: 15,
                  backgroundColor: 'white',
                  border: '1px solid #9D9D9D',
                  borderRadius: 5,
                }}
              />
            </div>
          </div>
          {disableDateFilled === true && (
            <div style={{ paddingTop: 10 }}>
              <Text type="caption" color="caption">
                * Filter does not apply to Adherence tab data
              </Text>
            </div>
          )}
        </div>
      </div>

      <Divider />

      <div style={styles.filterSection}>
        <div>
          <Text type="subtitle">CCSR</Text>
          <CCSRAutocomplete selectedCCSR={filters?.ccsr} setSelectedCCSR={handleCCSRChange} />
        </div>
      </div>

      <Divider />

      <div style={styles.filterSection}>
        <div>
          <Text type="subtitle">ER Count</Text>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Text type="caption" style={{ paddingRight: 10 }}>
                greater than or equal to
              </Text>
              <input
                min="0"
                type="number"
                className="hover-input-light-blue"
                value={filters.erCount ?? ''}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    erCount: isEmpty(e.target.value) ? null : parseInt(e.target.value, 10),
                  })
                }
                style={{
                  height: 35,
                  width: 80,
                  paddingLeft: 15,
                  backgroundColor: 'white',
                  border: '1px solid #9D9D9D',
                  borderRadius: 5,
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <Text type="subtitle">Inpatient Count</Text>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Text type="caption" style={{ paddingRight: 10 }}>
                greater than or equal to
              </Text>
              <input
                min="0"
                type="number"
                className="hover-input-light-blue"
                value={filters.ipCount ?? ''}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    ipCount: isEmpty(e.target.value) ? null : parseInt(e.target.value, 10),
                  })
                }
                style={{
                  height: 35,
                  width: 80,
                  paddingLeft: 15,
                  backgroundColor: 'white',
                  border: '1px solid #9D9D9D',
                  borderRadius: 5,
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <Text type="subtitle">ER & Inpatient Count</Text>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Text type="caption" style={{ paddingRight: 10 }}>
                greater than or equal to
              </Text>
              <input
                min="0"
                type="number"
                className="hover-input-light-blue"
                value={filters.erAndIpCount ?? ''}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    erAndIpCount: isEmpty(e.target.value) ? null : parseInt(e.target.value, 10),
                  })
                }
                style={{
                  height: 35,
                  width: 80,
                  paddingLeft: 15,
                  backgroundColor: 'white',
                  border: '1px solid #9D9D9D',
                  borderRadius: 5,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  filters: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
};

export default Filters;

import TrialCohortPreviewCard from 'Initiatives/Trials/TrialCohortPreviewCard';
import { TrialMetricChart } from 'Initiatives/MetricChart/TrialMetricChart';

export const InitiativesListTrial = ({ trial }) => {
  return (
    <div style={styles.container}>
      <div style={styles.scrollContainer}>
        <div style={styles.chartAndCohortsContainer}>
          <div style={styles.chartContainer}>
            <TrialMetricChart trial={trial} metric={trial.metrics[0]} hideLegend />
          </div>
          <div style={styles.cohortContainer}>
            {trial.cohorts.map((cohort) => {
              return <TrialCohortPreviewCard key={cohort.id} cohort={cohort} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  scrollContainer: {
    maxHeight: 260,
    overflow: 'auto',
  },
  trialHeader: {
    display: 'flex',
    gap: 5,
  },
  trialName: {
    paddingRight: 10,
  },
  chartAndCohortsContainer: {
    display: 'flex',
    gap: 20,
  },
  chartContainer: {
    maxWidth: 450,
    flex: '1 1 auto',
  },
  cohortContainer: {
    flex: '0 0 350px',

    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
};

import PropTypes from 'prop-types';

const IntusLabelsPropType = PropTypes.shape({
  behaviorSubscore: PropTypes.arrayOf(PropTypes.string),
  careManagementSubscore: PropTypes.arrayOf(PropTypes.string),
  diseaseSubscore: PropTypes.arrayOf(PropTypes.string),
  recurrentSubscore: PropTypes.arrayOf(PropTypes.string),
  socialSubscore: PropTypes.arrayOf(PropTypes.string),
  utilizationSubscore: PropTypes.arrayOf(PropTypes.string),
});

export default IntusLabelsPropType;

import Icon from '../Icon';

const DropdownItem = ({
  onSelect,
  hasSuggestion,
  index,
  setIsCollapsed,
  option,
  empty,
  suggestionLabel,
  noMatchLabel,
  withCustomDateRanges,
  showDateRange,
  setShowDateRange,
  dateRangeDirection,
}) => {
  const handleOnSelect = () => {
    if (empty) {
      return;
    }
    if (withCustomDateRanges) {
      setShowDateRange(!showDateRange);
      return;
    }
    onSelect(option);
    setIsCollapsed(false);
    setShowDateRange(false);
  };
  const isUndefined = option === undefined;

  if (withCustomDateRanges) {
    return (
      <div
        className="dd-item"
        style={{
          display: 'flex',
          justifyContent: dateRangeDirection !== 'left' && 'space-between',
          padding: 5,
          width: '100%',
          height: empty ? 40 : 'fit-content',
          alignSelf: 'end',
          justifySelf: 'end',
          backgroundColor: !!showDateRange && '#E3EAFE',
          gap: 5,
        }}
        onClick={handleOnSelect}
        onKeyDown={handleOnSelect}
        role="none"
      >
        {dateRangeDirection === 'left' && (
          <Icon name="CaretLeft" color="#9D9D9D" hoverColor="#9D9D9D" />
        )}
        {empty && noMatchLabel}
        {option?.label} {index === 0 && !isUndefined && hasSuggestion && suggestionLabel}
        {dateRangeDirection === 'right' && (
          <Icon name="CaretRight" color="#9D9D9D" hoverColor="#9D9D9D" />
        )}
      </div>
    );
  }
  return (
    <div
      className="dd-item"
      style={{
        padding: 5,
        width: '100%',
        height: empty ? 40 : 'fit-content',
      }}
      onClick={handleOnSelect}
      onKeyDown={handleOnSelect}
      role="none"
    >
      {empty && noMatchLabel}
      {option?.label} {index === 0 && !isUndefined && hasSuggestion && suggestionLabel}
    </div>
  );
};

export default DropdownItem;

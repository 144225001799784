import { useHistory } from 'react-router-dom';

import { Text, layout, Icon } from '@intus-ui';
import { cohortChangesColors } from '@intus-ui/styles/SecondaryColors';

const ActionCohortCard = ({ cohort }) => {
  const history = useHistory();
  const { id } = cohort;

  const handleCohortCardClick = () => {
    history.push({
      pathname: `/cohorts/action/${id}/participants`,
    });
  };

  const patientsAdded = cohort.addedPatientIds?.length || 0;
  const patientsRemoved = cohort.removedPatientIds?.length || 0;

  const splitDescription = cohort.description?.split('\n') ?? [];

  return (
    <div
      style={styles.cohortCardOuter}
      onClick={handleCohortCardClick}
      onKeyDown={handleCohortCardClick}
      role="none"
    >
      <div style={styles.cohortCardContent}>
        <Text type="title" color="body" style={{ fontWeight: 700 }}>
          {cohort.name}
        </Text>
        <Text type="body" style={styles.cohortDescription} color="caption">
          {splitDescription.map((desc) => {
            return <div>{desc}</div>;
          })}
        </Text>
      </div>
      <div style={styles.cohortCardFooter}>
        <div style={styles.cohortMemberTotal}>
          <Icon name="People" color="#9D9D9D" />
          <Text type="body" color="caption">
            {cohort.count}
          </Text>
        </div>
        <div style={styles.cohortCardChanges}>
          <div style={styles.numberWithArrow}>
            <Text type="subtitle" color={patientsAdded > 0 ? 'countRed' : 'countDisabled'}>
              {patientsAdded}
            </Text>
            <Icon
              name="IncreaseArrow"
              color={patientsAdded > 0 ? cohortChangesColors.red : cohortChangesColors.disabled}
              hoverColor={
                patientsAdded > 0 ? cohortChangesColors.red : cohortChangesColors.disabled
              }
              style={styles.arrowIcon}
            />
          </div>
          <div style={styles.numberWithArrow}>
            <Text type="subtitle" color={patientsRemoved > 0 ? 'countBlue' : 'countDisabled'}>
              {patientsRemoved}
            </Text>
            <Icon
              name="DecreaseArrow"
              color={patientsRemoved > 0 ? cohortChangesColors.blue : cohortChangesColors.disabled}
              hoverColor={
                patientsRemoved > 0 ? cohortChangesColors.blue : cohortChangesColors.disabled
              }
              style={styles.arrowIcon}
            />
          </div>
          <Text type="body" color="caption">
            past week
          </Text>
        </div>
      </div>
    </div>
  );
};

const styles = {
  cohortCardOuter: {
    ...layout.container,
    alignItems: 'start',
    overflowY: 'hidden',
    height: 280,
    padding: 20,
    borderRadius: 20,
    boxShadow: '0px 0.4px 0.4rem 0.06rem rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    backgroundColor: 'white',
    gap: 10,
  },
  cohortCardContent: {
    ...layout.container,
    flexGrow: 1,
    gap: 15,
  },
  cohortCardFooter: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'auto',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '100%',
  },
  cohortCardChanges: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    gap: 3,
    overflowY: 'auto',
  },
  cohortDescription: {
    flexGrow: 4,
    // Workaround for a weird phantom scrollbar showing up when the description is a bit long.
    overflow: 'hidden',
  },
  cohortMemberTotal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    overflowY: 'auto',
  },
  numberWithArrow: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
  arrowIcon: {
    width: 14,
    height: 14,
  },
};

export default ActionCohortCard;

import React from 'react';
import { Col } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getSessionUser } from '@util/session';

import {
  getUserInfo,
  getHomePageBox,
  getJobTypes,
  getTeams,
  getFacilities,
} from '@global-state/redux/userSettingsSlice';

import SideNavBar from '@intus-ui/components/menus/SideMenu/SideNavBar';
import SettingsGeneralTab from './SettingsGeneralTab';
import { menuMap, NAV_BAR_ITEM_NAME } from './userSettingsPropType';
import UserGuides from './Tutorials/UserGuides';
import QuadsHomepageSettingsBox from './QuadsHomepageSettingsBox';
import NotificationQuad from './NotificationQuad';

import '@intus-ui/styles/Settings.scss';

const SettingsMainViewSubroutesNavContainer = ({ tabs, isHealthSysAdmin }) => {
  const { tabName, subPage } = useParams();
  const root = 'settings';
  let paramLink = subPage ? `${tabName}/${subPage}` : tabName;
  // Read from redux state to access most recent "cached" tab name
  const mostRecentTabName = useSelector((state) => state.userSettings.navigation);
  const defaultTabName = mostRecentTabName ?? NAV_BAR_ITEM_NAME.GENERAL;
  // An array of menu items to render within the SideNavBar component
  // Attempting to access /settings (should be /settings/:defaultTabName)
  if (!paramLink) {
    return <Redirect to={`/${root}/${defaultTabName}`} />;
  }

  // If the param link does not correspond to its own page (e.g. Tutorials), then stay on existing page until next NavBarItem is selected
  if (!tabs[paramLink]) {
    paramLink = defaultTabName;
  }

  // If Health System Admin, remove homepage tab
  const settingsSideBarOptions = isHealthSysAdmin
    ? menuMap.filter((tab) => tab.name !== 'homepage')
    : menuMap;

  return (
    <div className="px-3 m-0 w-100 h-100 d-flex flex-row justify-content-center">
      <SideNavBar pageTitle="Personal Settings" menuMap={settingsSideBarOptions} root={root} />
      <Col lg={10} className="h-100 overflow-scroll pt-5">
        {tabs[paramLink]}
      </Col>
    </div>
  );
};

class Settings extends React.Component {
  componentDidMount() {
    const { doGetUserLocalData, doGetHomePageBoxes, doGetJobTypes, doGetTeams, doGetFacilities } =
      this.props;
    const { organizationId } = getSessionUser();
    doGetUserLocalData();
    doGetHomePageBoxes();
    doGetJobTypes();
    doGetTeams(organizationId);
    doGetFacilities(organizationId);
  }

  render() {
    const { isHealthSysAdmin } = this.props;
    const settingsTabs = {
      general: <SettingsGeneralTab />,
      'homepage/cardSelections': <QuadsHomepageSettingsBox />,
      'homepage/recentEventSelections': <NotificationQuad />,
      'tutorials/useCases': <UserGuides />,
    };

    return (
      <SettingsMainViewSubroutesNavContainer
        tabs={settingsTabs}
        isHealthSysAdmin={isHealthSysAdmin}
      />
    );
  }
}

Settings.propTypes = {
  doGetUserLocalData: PropTypes.func.isRequired,
  doGetHomePageBoxes: PropTypes.func.isRequired,
  doGetJobTypes: PropTypes.func.isRequired,
  doGetTeams: PropTypes.func.isRequired,
  doGetFacilities: PropTypes.func.isRequired,
  isHealthSysAdmin: PropTypes.bool.isRequired,
};

const mapDispatch = (dispatch) => ({
  doGetUserLocalData: () => dispatch(getUserInfo()),
  doGetHomePageBoxes: () => dispatch(getHomePageBox()),
  doGetJobTypes: () => dispatch(getJobTypes()),
  doGetTeams: (id) => dispatch(getTeams(id)),
  doGetFacilities: (id) => dispatch(getFacilities(id)),
});

const mapState = (state) => ({
  userSettings: state.userSettings,
  homePageBoxes: state.userSettings.homePageBox,
  jobTypes: state.userSettings.jobTypes,
  teams: state.userSettings.teams,
  isHealthSysAdmin: state.filters.isHealthSysAdmin,
});

export default connect(mapState, mapDispatch)(Settings);

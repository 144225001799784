import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import FilteredList from '@intus-ui/components/FilteredList';
import { toUTCtoLocal } from '@util/dateFunctions';
import { getTrackingEvents, getTrackingEventsAll, getTrackingSession } from './AdminSlice';
import UserTrackingEventList from './UserTrackingEventList';
import EventListPropType from './EventListPropType';
import SessionListPropType from './SessionListPropType';

const UserTrackingList = ({
  doGetEvents,
  doGetAllEvents,
  doGetSessions,
  trackingEvents,
  allTrackingEvents,
  trackingSessions,
}) => {
  const testOrg = {
    name: 'Test Organization (you should never see this)',
    user: 'Test User',
    time: '',
  };

  const [selectedOrg, setSelectedOrg] = useState(testOrg);
  const [userClick, setUserClick] = useState(false);
  const [orgNameFilter, setOrgNameFilter] = useState('All');
  const [eventCountFilter, setEventCountFilter] = useState('All');
  const [pageGlobalFilter, setPageGlobalFilter] = useState('Pages');

  useEffect(() => {
    doGetSessions();
    doGetAllEvents();
  }, [doGetSessions, doGetAllEvents]);

  const selectOrg = (item) => {
    setSelectedOrg(item);
    doGetEvents(item.orgId, item.userId);
  };

  // Need function that takes in the sessionId and returns list of pages accessed during that session
  const getPages = (sessionId) => {
    const pages = allTrackingEvents
      .filter((event) => event.trackingSessionId === sessionId)
      .map((event) => {
        return event.page;
      });
    return pages;
  };

  const items = trackingSessions
    .map((session) => ({
      sessionId: session.id,
      orgName: session.orgName,
      orgId: session.orgId,
      userId: session.userId,
      user: `${session.userFirstName} ${session.userLastName}`,
      sessiondate: toUTCtoLocal(session.time, 'YYYY-MM-DD HH:mm:ss'),
      eventCount: session.eventCount,
      eventActivity: session.eventActivity,
    }))
    .filter((session) => orgNameFilter === 'All' || session.orgName === orgNameFilter)
    .filter((session) => eventCountFilter === 'All' || session.eventActivity === eventCountFilter)
    .filter(
      (session) =>
        pageGlobalFilter === 'Pages' || getPages(session.sessionId).includes(pageGlobalFilter)
    );

  const format = [
    {
      valueField: 'orgName',
      nodeField: 'orgName',
      md: 3,
      className: 'text-dark',
      sorting: 'none',
      heading: 'Organization',
    },
    {
      valueField: 'user',
      nodeField: 'user',
      md: 3,
      className: 'text-dark',
      sorting: 'none',
      heading: 'User',
    },
    {
      valueField: 'eventCount',
      nodeField: 'eventCount',
      md: 3,
      className: 'text-secondary pl-5',
      sorting: 'none',
      heading: 'Event Count',
    },
    {
      valueField: 'sessiondate',
      nodeField: 'sessiondate',
      md: 3,
      value: true,
      sorting: 'lexographical',
      heading: 'Session Date',
    },
  ];

  const uniqueOrgName = [...new Map(trackingSessions.map((item) => [item.orgName, item])).values()];

  const orgNameFilterButton = (
    <span key="orgName-filter">
      <DropdownButton
        size="sm"
        onClick={(event) =>
          event.target.id === 'dropdown-button-orgName-filter' || event.target.id === ''
            ? null
            : setOrgNameFilter(event.target.id)
        }
        id="dropdown-button-orgName-filter"
        title={orgNameFilter}
      >
        <Dropdown.Item id="All">All</Dropdown.Item>
        {uniqueOrgName.map((session) => (
          <Dropdown.Item key={session.id} id={session.orgName}>
            {session.orgName}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </span>
  );

  const eventCountFilterButton = (
    <span key="eventCount-filter">
      <DropdownButton
        size="sm"
        onClick={(event) =>
          event.target.id === 'dropdown-button-eventCount-filter' || event.target.id === ''
            ? null
            : setEventCountFilter(event.target.id)
        }
        id="dropdown-button-eventCount-filter"
        title={eventCountFilter}
      >
        <Dropdown.Item id="All">All</Dropdown.Item>
        <Dropdown.Item id="0-3">0-3</Dropdown.Item>
        <Dropdown.Item id="4-7">4-7</Dropdown.Item>
        <Dropdown.Item id="8+">8+</Dropdown.Item>
      </DropdownButton>
    </span>
  );

  const unfilteredPages = [...new Map(allTrackingEvents.map((item) => [item.page, item])).values()];

  // Take out empty pages
  const uniqueGlobalPages = unfilteredPages.filter((event) => event.page !== '');

  const titleNode = (
    <div className="d-flex justify-content-center align-items-center">
      <DropdownButton
        size="sm"
        onClick={(event) =>
          event.target.id === 'dropdown-button-page-global-filter' || event.target.id === ''
            ? null
            : setPageGlobalFilter(event.target.id)
        }
        id="dropdown-button-page-global-filter"
        title={pageGlobalFilter}
      >
        <Dropdown.Item id="Pages">All</Dropdown.Item>
        {uniqueGlobalPages.map((event) => (
          <Dropdown.Item key={event.id} id={event.page}>
            {event.page}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );

  return (
    <div className="w-100 h-100 d-flex justify-content-start align-items-center flex-column px-3">
      {!userClick && (
        <FilteredList
          title={titleNode}
          items={items}
          format={format}
          fieldFilters={['Organization', 'Event Count']}
          filters={[orgNameFilterButton, eventCountFilterButton]}
          search={{
            valueField: 'user',
            name: 'User',
          }}
          emptyText="No users found matching your search"
          defaultSorting={{ valueField: 'sessiondate', value: false }}
          onClick={(item) => {
            selectOrg(item);
            setUserClick(true);
          }}
        />
      )}
      {userClick && (
        <UserTrackingEventList
          backAction={setUserClick}
          session={selectedOrg}
          trackingEvents={trackingEvents}
        />
      )}
    </div>
  );
};

UserTrackingList.propTypes = {
  doGetEvents: PropTypes.func.isRequired,
  trackingEvents: PropTypes.arrayOf(EventListPropType.isRequired),
  allTrackingEvents: PropTypes.arrayOf(EventListPropType.isRequired),
  doGetSessions: PropTypes.func.isRequired,
  trackingSessions: PropTypes.arrayOf(SessionListPropType.isRequired),
};

UserTrackingList.defaultProps = {
  trackingEvents: [],
  allTrackingEvents: [],
  trackingSessions: [],
};

const mapState = (state) => ({
  trackingOrg: state.admin.trackingOrg,
  trackingEvents: state.admin.trackingEvents,
  allTrackingEvents: state.admin.allTrackingEvents,
  trackingSessions: state.admin.trackingSessions,
  trackingUser: state.admin.user,
});

const mapDispatch = (dispatch) => ({
  doGetEvents: (orgID, userId) => dispatch(getTrackingEvents(orgID, userId)),
  doGetAllEvents: () => dispatch(getTrackingEventsAll()),
  doGetSessions: () => dispatch(getTrackingSession()),
});

export default withRouter(connect(mapState, mapDispatch)(UserTrackingList));

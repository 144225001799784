import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setCustomDashTimeFilter } from '@global-state/redux/filtersSlice';

import {
  Button,
  DateRangeDropdown,
  Icon,
  SpinnerOrError,
  TeamFacilityFilter,
  Text,
  useParticipantFilters,
} from '@intus-ui';
import * as userTracking from '@util/userTracking';
import { useEffect, useMemo, useState } from 'react';
import { formatDateRangeText } from '@intus-ui/components/DateRangeDropdown';
import { BackButton } from '@intus-ui/components/Buttons/BackButton';
import { Menu, MenuItem } from '@mui/material';
import { useLazyQuery } from '@api/useQuery';
import { deleteDashboardAPI, deleteOrgDashboardAPI } from '@api';
import { useGetSessionUser } from '@util/session';
import { useDownload } from '@api/useDownload';
import { postDownloadDashboard } from '@api/dashboard';
import { findMonthOptionByLabel } from '@intus-ui/components/DateRange/config';
import EditNameModal from './EditNameModal';
import ShareModal from './ShareModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';

// 30 days, 90 days, 6 months, 12 months.
const allowedRanges = [1, 3, 6, 12];

export const CustomDashboardHeader = ({
  dashboard,
  onAddNewMetric,
  toggleIsCompareOpen,
  isCompareOpen,
  runUpdateDashboardQuery,
  saveDashboardIfNotSaved,
  updateDashboardError,
  openEditNameModal,
  setOpenEditNameModal,
  openShareModal,
  setOpenShareModal,
  updateDashboardLoading,
  setSnackbarErrorMessage,
}) => {
  const history = useHistory();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const user = useGetSessionUser();

  const dispatch = useDispatch();

  useEffect(() => {
    if (dashboard?.timeFilter != null) {
      const dashTimeFilter = findMonthOptionByLabel(dashboard.timeFilter);
      dispatch(
        setCustomDashTimeFilter({
          startDate: dashTimeFilter.start,
          endDate: dashTimeFilter.end,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { selectedFilters } = useParticipantFilters();
  const timeFilter = useSelector((state) => state.filters.customDashTimeFilter);

  const [anchorEl, setAnchorEl] = useState(null);

  const dashboardName = dashboard?.name || 'Untitled Dashboard';

  const dateRangeText = useMemo(() => {
    return formatDateRangeText(allowedRanges, timeFilter.startDate, timeFilter.endDate);
  }, [timeFilter.endDate, timeFilter.startDate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { runQuery: runDeleteDashboardQuery } = useLazyQuery(
    () => {
      if (dashboard.isShared === true) {
        return deleteOrgDashboardAPI(user.organizationId, dashboard.id);
      }
      return deleteDashboardAPI(user.id, dashboard.id);
    },
    {
      onSuccess: () => {
        history.replace('/customdashboards');
        userTracking.logEvent('Dashboard: Deleted Dashboard', {
          dashboardName: dashboard.name,
          dashboardId: dashboard.id,
          isShared: dashboard.isShared,
        });
      },
    }
  );

  // Replace non alphanumeric characters in the dashboard name.
  const fileName = `${dashboardName.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.xlsx`;

  const { runQuery: runDownloadQuery, loading: isDownloadLoading } = useDownload(
    fileName,
    async () => {
      const myDashboard = await saveDashboardIfNotSaved();

      return postDownloadDashboard(
        myDashboard.id,
        timeFilter.startDate,
        timeFilter.endDate,
        selectedFilters
      );
    },
    {
      onSuccess: () => {
        handleClose();
      },
      onError: () => {
        setSnackbarErrorMessage('An error occurred downloading the dashboard');
      },
    }
  );

  const isMoreActionsMenuOpen = anchorEl != null;

  const canEdit = dashboard == null ? true : dashboard.canUserEdit;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
      {/* MODALS  */}
      <EditNameModal
        existingDashboard={dashboard}
        openEditNameModal={openEditNameModal}
        setOpenEditNameModal={setOpenEditNameModal}
        runUpdateDashboardQuery={runUpdateDashboardQuery}
        updateDashboardError={updateDashboardError}
        key={`editNameModal-${openEditNameModal.toString()}`}
        updateDashboardLoading={updateDashboardLoading}
      />
      <ShareModal
        existingDashboard={dashboard}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        runUpdateDashboardQuery={runUpdateDashboardQuery}
        key={`openShareModal-${openShareModal.toString()}`}
        updateDashboardError={updateDashboardError}
        updateDashboardLoading={updateDashboardLoading}
      />
      <DeleteConfirmationModal
        runDeleteDashboardQuery={runDeleteDashboardQuery}
        existingDashboard={dashboard}
        setOpenDeleteModal={setOpenDeleteModal}
        openDeleteModal={openDeleteModal}
        key={`openDeleteModal-${openDeleteModal.toString()}`}
      />

      {/* HEADER */}
      <div style={styles.header}>
        <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
          <BackButton
            secondary={false}
            onClick={() => {
              if (isCompareOpen) {
                toggleIsCompareOpen();
              } else {
                history.go(-1);
              }
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '35px',
              justifyContent: 'center',
            }}
          >
            <Text type="title" color="navy">
              {dashboardName}
            </Text>
            {dashboard?.isShared === true && (
              <Text type="caption">Shared with the organization</Text>
            )}
          </div>

          {canEdit && (
            <Button
              secondary
              style={{ padding: '0px 5px' }}
              onClick={() => setOpenEditNameModal(true)}
            >
              <Icon name="Edit" />
            </Button>
          )}
        </div>
        <div style={{ display: 'flex', gap: 8 }}>
          <Button secondary onClick={() => toggleIsCompareOpen()}>
            Break down by
          </Button>
          <Button
            id="more-actions-button"
            aria-controls={isMoreActionsMenuOpen ? 'more-actions-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMoreActionsMenuOpen ? 'true' : undefined}
            secondary
            onClick={(event) => handleClick(event)}
            style={{ paddingRight: 0, gap: '2px' }}
          >
            More actions
            <Icon style={{ marginLeft: -5 }} name="TripleVerticalDot" />
          </Button>
          <Menu
            id="more-actions-menu"
            anchorEl={anchorEl}
            open={isMoreActionsMenuOpen}
            onClose={handleClose}
            MenuListProps={{
              style: {
                minWidth: 170,
              },
              'aria-labelledby': 'more-actions-button',
            }}
            slotProps={{
              paper: {
                style: {
                  marginTop: 1,
                  marginLeft: -5,
                },
              },
            }}
          >
            {canEdit && (
              <MenuItem
                onClick={() => {
                  onAddNewMetric();
                  handleClose();
                }}
              >
                <Text type="subtitle" color="navy">
                  Add new metric
                </Text>
              </MenuItem>
            )}
            {canEdit && (
              <MenuItem
                onClick={() => {
                  setOpenEditNameModal(true);
                  handleClose();
                }}
              >
                <Text type="subtitle" color="navy">
                  Rename
                </Text>
              </MenuItem>
            )}
            {canEdit && user?.access === 'ADMIN' && (
              <MenuItem
                onClick={() => {
                  setOpenShareModal(true);
                  handleClose();
                }}
                disabled={dashboard == null}
              >
                <Text type="subtitle" color="navy">
                  {dashboard?.isShared ? 'Make Personal' : 'Share'}
                </Text>
              </MenuItem>
            )}
            {!isDownloadLoading && (
              <MenuItem
                onClick={() => runDownloadQuery()}
                disabled={dashboard == null || dashboard.metrics.length === 0}
              >
                <Text type="subtitle" color="navy">
                  Download
                </Text>
              </MenuItem>
            )}
            {isDownloadLoading && <SpinnerOrError />}
            {canEdit && (
              <MenuItem
                onClick={() => {
                  setOpenDeleteModal(true);
                  handleClose();
                }}
                disabled={dashboard == null}
              >
                <Text type="subtitle" color="error">
                  Delete
                </Text>
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
      <div id="custom-dash-filters" style={styles.filters}>
        <TeamFacilityFilter disabled={isCompareOpen ? true : undefined} />
        <DateRangeDropdown
          range={allowedRanges}
          value={dateRangeText}
          dateRangeDirection="left"
          onChange={async (val) => {
            if (!val.isRange) {
              dispatch(setCustomDashTimeFilter({ startDate: val?.start, endDate: val?.end })); // setting time filter in redux
              if (dashboard != null) {
                const updatedDashboard = {
                  ...dashboard,
                  timeFilter: val?.label,
                };
                await runUpdateDashboardQuery(updatedDashboard);
              }
            }
            return null;
          }}
          onApplyRange={(val) => {
            dispatch(setCustomDashTimeFilter({ startDate: val?.start, endDate: val?.end }));
          }}
        />
      </div>
    </div>
  );
};

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
};

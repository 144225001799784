import React from 'react';
import { Col } from 'react-bootstrap';
import Text from '@intus-ui/components/Text';
import NavBarItem from './NavBarItem';
import NavBarCat from './NavBarCat';
/**
 * @param { string } pageTitle display title of the entire page, sits above the side menu
 * @param { menuMapPropType } menuMap an array of menu items (menuCat or menuItem). See menuMapPropType for details
 * @param { string } root name of the root page we are on, e.g. Settings, Participants, etc.
 * @param { boolean } inverted if true, then the side menu is white instead of off-white like it is in settings page
 */
const SideNavBar = ({ pageTitle, menuMap, root, inverted }) => (
  <Col lg={2} className="h-100 pt-4">
    <Text type="title" color="navy">
      {pageTitle}
    </Text>
    <div className={`${inverted ? 'bg-white' : 'bg-light'} rounded-lg p-4 mt-3`}>
      {menuMap.map((item) => {
        if (item.type === 'item') {
          return (
            <NavBarItem
              key={item.name}
              displayTitle={item.displayTitle}
              name={item.name}
              root={root}
              // icon, needed for cohorts side menu?
            />
          );
        }
        return (
          <NavBarCat
            key={item.name}
            catName={item.name}
            catTitle={item.displayTitle}
            root={root}
            subPages={item.children}
          />
        );
      })}
    </div>
  </Col>
);

export default SideNavBar;

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { produce } from 'immer';
import { isEmpty, last } from 'lodash';

import { getPatientMedicationsWithFilters } from '@api/patients/getPatientMedications';
import {
  IAllPatientFirstFillStatus,
  IPatientRisingStarsData,
  IRisingStarMeasureData,
  IRisingStarsData,
  allPatientFirstFillStatus,
  getRisingStars,
} from '@api/polypharmacy/getRisingStars';
import {
  IPatientStarsAdherence,
  IPatientStarsMeasureData,
} from '@api/polypharmacy/getSTARSAdherence';
import { IPolypharmacyFilters } from '@api/polypharmacy/types/IPolypharmacyFilters';
import { useLazyQuery } from '@api/useQuery';
import { updatePatientHospice } from '@api';

import { Divider, List, Modal, SearchInput, SpinnerOrError, Text } from '@intus-ui';
import { IListColumnConfig } from '@intus-ui/components/List/types/IListFormat';
import MedicationsModalList from 'Polypharmacy/MedicationsModalList';
import {
  AdherenceStatusSelect,
  IAllAdherenceStatuses,
} from 'Polypharmacy/components/AdherenceStatusSelect';
import { PatientStatusFilter } from 'Polypharmacy/components/PatientStatusFilter';
import { StarsMeasureDropdown } from 'Polypharmacy/components/StarsMeasureDropdown';
import { IAllStarsMeasures, starsMeasuresPossibleYears } from 'Polypharmacy/types/STARSMeasures';
import { NextFillDueSelect } from 'Polypharmacy/components/NextFillDueSelect';
import {
  INextFillDueStatus,
  getNextFillDueStatus,
} from 'Polypharmacy/components/NextFillDueColumn';
import { getFormat, getSingleMeasureFormat } from './getFormat'; // eslint-disable-line import/no-cycle
import { HospiceFilter } from './components/HospiceFilter';
import { IPriority } from './types/Priority';
import { PrioritySelect } from './components/PrioritySelect';

type AdherenceRisingStarsProps = {
  filters: IPolypharmacyFilters;
};

type PossibleMeasures = 'all' | IAllStarsMeasures;

const columnConfig: IListColumnConfig = {
  columns: [
    'patientStatus',
    'name',
    'age',
    'hospice',
    'measureData',
    'medClasses',
    'fillCount',
    'percentageDaysCovered',
    'priority',
    'gapDays',
    'minCoverageDays',
    'adherenceComplianceDate',
    'actionDate',
    'fillDueDate',
    'seeDetails',
  ],
};

const measureColumnConfig: IListColumnConfig = {
  columns: [
    'name',
    'age',
    'hospice',
    'patientStatus',
    'measureData',
    'medClasses',
    'fillCount',
    'percentageDaysCovered',
    'gapDays',
    'minCoverageDays',
    'adherenceComplianceDate',
    'actionDate',
    'fillDueDate',
    'seeDetails',
  ],
};

export type FlatPatient = IRisingStarMeasureData & {
  id: number;
  age: number;
  name: string;
  patientStatus: string | null;
  measureData: IPatientStarsMeasureData[];
  seeDetails?: undefined;
};

export const AdherenceRisingStars: FC<AdherenceRisingStarsProps> = ({ filters }) => {
  const [selectedMeasure, setSelectedMeasure] = useState<PossibleMeasures>('all');
  const [adherentStatus, setAdherentStatus] = useState<IAllAdherenceStatuses>('All');
  const [nextFillDueStatus, setNextFillDueStatus] = useState<INextFillDueStatus>('All');
  const [patientStatusFilter, setPatientStatusFilter] = useState<IAllPatientFirstFillStatus[]>([
    ...allPatientFirstFillStatus,
  ]);
  const [priority, setPriority] = useState<'All' | IPriority>('All');
  const [hospiceFilter, setHospiceFilter] = useState('No');
  const [search, setSearch] = useState('');

  const [data, setData] = useState<IRisingStarsData | null>(null);

  // Hacky way to re-run the query in the background when a patient status is changed.
  // This prevents the list from disappearing and re-appearing when a patient status is changed.
  //
  // If the filters are changed we do want to show the loading spinner.
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);

  const { data: queryData, loading, error, runQuery } = useLazyQuery(() => getRisingStars(filters));

  useEffect(() => {
    if (queryData) {
      setData(queryData);
    }
  }, [queryData]);

  // const totalPatients = useMemo(() => {
  //   return (
  //     data?.patientAdherence.filter((p) =>
  //       p.risingStarsData.some((r) => r.measure === selectedMeasure)
  //     ).length ?? 0
  //   );
  // }, [data, selectedMeasure]);

  useEffect(() => {
    setShowLoadingSpinner(true);
    runQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  function updatePatientStatus(
    patientId: number,
    measure: string,
    status: IAllPatientFirstFillStatus | null
  ) {
    setData(() => {
      return produce(data, (data) => {
        const patient = data?.patientAdherence.find((p) => p.id === patientId);
        if (patient == null) return;

        const measureData = patient.risingStarsData.find((m) => m.measure === measure);
        if (measureData == null) return;

        if (measureData.status == null) {
          measureData.status = {
            status: status as IAllPatientFirstFillStatus,
          };
        } else {
          if (status != null) {
            measureData.status.status = status;
          } else {
            measureData.status = null;
          }
        }
      });
    });
  }

  const onChangeHospiceStatus = useCallback(
    (patientId: number, newHospiceStatus: boolean) => {
      updatePatientHospice({ patientId, newHospiceStatus });
      setData(() => {
        return produce(data, (d) => {
          const patient = d?.patientAdherence.find((p) => {
            return p.id === patientId;
          });
          if (patient == null) return;
          patient.hospice = newHospiceStatus;
        });
      });
    },
    [data]
  );

  const filteredPatientAdherence = useMemo(() => {
    if (data == null) return [];

    return produce(data.patientAdherence, (draft) => {
      let filteredPatients: IPatientRisingStarsData[] = [];
      for (const patient of draft) {
        // Patient Name search
        if (!isEmpty(search)) {
          if (!patient.name.toLowerCase().includes(search.toLowerCase())) continue;
        }

        // Filter by measure
        let filteredMeasures = patient.risingStarsData;
        if (selectedMeasure !== 'all') {
          filteredMeasures = patient.risingStarsData.filter((m) => m.measure === selectedMeasure);
        }

        // Filter by adherence or not adherent
        if (adherentStatus === 'Adherent (≥80%)') {
          filteredMeasures = filteredMeasures.filter((m) => m.percentageDaysCovered >= 0.8);
        } else if (adherentStatus === 'Not adherent (<80%)') {
          filteredMeasures = filteredMeasures.filter((m) => m.percentageDaysCovered < 0.8);
        }

        // Filter by the status tag for the measure.
        if (patientStatusFilter.length !== allPatientFirstFillStatus.length) {
          const patientStatusSet = new Set(patientStatusFilter);
          filteredMeasures = filteredMeasures.filter((m) => {
            if (patientStatusSet.has('needs_review') && m.status == null) return true;
            return m.status != null && patientStatusSet.has(m.status.status);
          });
        }

        // Filter by next fill due status
        if (nextFillDueStatus !== 'All') {
          filteredMeasures = filteredMeasures.filter((m) => {
            const status = getNextFillDueStatus(m.fillDueDate)?.status;
            if (nextFillDueStatus === 'Due in ≤14 days')
              return status === 'Due in ≤14 days' || status === 'Due in ≤7 days';
            return status === nextFillDueStatus;
          });
        }

        // Filter by priority
        if (priority !== 'All') {
          filteredMeasures = patient.risingStarsData.filter((m) => m.priority === priority);
        }

        if (filteredMeasures.length > 0) {
          filteredPatients.push({
            ...patient,
            risingStarsData: filteredMeasures,
          });
        }
      }
      if (hospiceFilter === 'No') {
        filteredPatients = filteredPatients.filter((patient) => {
          return patient.hospice === false;
        });
      } else if (hospiceFilter === 'Yes') {
        filteredPatients = filteredPatients.filter((patient) => patient.hospice === true);
      }
      return filteredPatients;
    });
  }, [
    data,
    search,
    selectedMeasure,
    adherentStatus,
    patientStatusFilter,
    nextFillDueStatus,
    hospiceFilter,
    priority,
  ]);

  if (error) return <SpinnerOrError error="An error occurred loading the rising stars" />;

  if (showLoadingSpinner && (loading || !data)) return <SpinnerOrError />;

  return (
    <div style={{ padding: 10, width: '100%' }}>
      <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
        <Text type="title">
          {filteredPatientAdherence != null ? filteredPatientAdherence.length : '~'} patients on
          first fill of
        </Text>
        <StarsMeasureDropdown
          allowMipsMeasure
          allowAll
          value={selectedMeasure}
          onChange={(measure) => setSelectedMeasure(measure)}
        />
      </div>
      <div style={{ padding: '10px 0px 15px 0px' }}>
        <Text type="caption" color="caption">
          Disclaimer: We provide insights into individual patient and population-level adherence to
          medications. The calculations are based on similar criteria but should not be considered
          an official representation of STARs Ratings. Exclusions that do not factor into
          calculations include: Hospice Enrollment, Dialysis Coverage Dates
        </Text>
        <Divider style={{ marginTop: 15 }} />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <SearchInput
            placeholder="Search by name"
            closeIcon
            onClose={() => setSearch('')}
            handleSearch={(event: any) => setSearch(event.target.value)}
            searchTerm={search}
            containerStyle={{
              width: 350,
            }}
          />
        </div>
        <div style={{ display: 'flex', gap: 15 }}>
          {/* Hospice status filter */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text type="caption-bold">In Hospice</Text>
            <HospiceFilter hospiceStatus={hospiceFilter} setHospiceStatus={setHospiceFilter} />
          </div>

          {/* Patient status filter */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text type="caption-bold">Patient Status</Text>
            <PatientStatusFilter
              patientStatusFilter={patientStatusFilter}
              setPatientStatusFilter={(items) => {
                setPatientStatusFilter(items);
              }}
              sx={{
                width: 220,
              }}
            />
          </div>

          {/* Adherence or Not Adherent patient selector */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text type="caption-bold">Adherence Status</Text>
            <AdherenceStatusSelect
              adherentStatus={adherentStatus}
              setAdherentStatus={setAdherentStatus}
            />
          </div>

          {/* Next Fill Due status filter */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text type="caption-bold">Action Date Status</Text>
            <NextFillDueSelect
              dueDateStatus={nextFillDueStatus}
              setDueDateStatus={setNextFillDueStatus}
            />
          </div>

          {/* Priority filter */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text type="caption-bold">Priority</Text>
            <PrioritySelect priority={priority} setPriority={setPriority} />
          </div>
        </div>
      </div>

      {/* Rising Stars Table */}
      <RisingStarsTable
        selectedMeasure={selectedMeasure}
        measureData={filteredPatientAdherence}
        onChangePatientStatus={(patientId, measure, status) => {
          setShowLoadingSpinner(false);
          updatePatientStatus(patientId, measure, status);
        }}
        onChangeHospiceStatus={onChangeHospiceStatus}
      />
    </div>
  );
};

type RisingStarsTableProps = {
  selectedMeasure: PossibleMeasures;
  measureData: IPatientRisingStarsData[];
  onChangePatientStatus: (
    patientId: number,
    measure: string,
    status: IAllPatientFirstFillStatus | null
  ) => void;
  onChangeHospiceStatus: (patientId: number, newHospiceStatus: boolean) => void;
};

const RisingStarsTable: FC<RisingStarsTableProps> = ({
  selectedMeasure,
  measureData,
  onChangePatientStatus,
  onChangeHospiceStatus,
}) => {
  const currentYear = new Date().getFullYear();
  const measureYear = starsMeasuresPossibleYears.includes(currentYear)
    ? currentYear
    : last(starsMeasuresPossibleYears)!;

  const [medicationsModalOpen, setMedicationsModalOpen] = useState(false);

  const {
    data: medicationsData,
    loading: medicationsDataLoading,
    error: medicationsDataError,
    runQuery: runGetMedicationsData,
  } = useLazyQuery((patientId) =>
    getPatientMedicationsWithFilters({
      patientId,
      starsMeasure: selectedMeasure,
      starsMeasureYear: measureYear,
      active: false,
      isRisingStars: true,
    })
  );

  const onClickSeeDetails = useCallback(
    (patientId: number) => {
      runGetMedicationsData(patientId);
      setMedicationsModalOpen(true);
    },
    [runGetMedicationsData]
  );

  const fullListFormat = useMemo(() => {
    return getFormat(onClickSeeDetails, onChangePatientStatus, onChangeHospiceStatus);
  }, [onClickSeeDetails, onChangePatientStatus, onChangeHospiceStatus]);

  const singleMeasureListFormat = useMemo(() => {
    return getSingleMeasureFormat(onClickSeeDetails, onChangePatientStatus, onChangeHospiceStatus);
  }, [onClickSeeDetails, onChangePatientStatus, onChangeHospiceStatus]);

  const allMeasurePatients = useMemo(() => {
    const patients: IPatientStarsAdherence[] = [];
    for (const patient of measureData) {
      patients.push({
        id: patient.id,
        name: patient.name,
        age: patient.age,
        measureData: patient.risingStarsData,
        hospice: patient.hospice,
      });
    }
    return patients;
  }, [measureData]);

  const flattenedPatients = useMemo(() => {
    const flatPatients: FlatPatient[] = [];

    for (const patient of measureData) {
      flatPatients.push({
        ...patient.risingStarsData[0],
        id: patient.id,
        name: patient.name,
        age: patient.age,
        patientStatus: patient.risingStarsData[0].status?.status ?? null,
        measureData: patient.risingStarsData,
      });
    }
    return flatPatients;
  }, [measureData]);

  return (
    <>
      <div
        style={{
          minWidth: 2275, // NOTE: MUST BE UPDATED IF COLUMNS ARE ADDED, REMOVED OR RESIZED
        }}
      >
        {selectedMeasure === 'all' && measureData ? (
          <List
            data={allMeasurePatients}
            format={fullListFormat}
            columnConfig={measureColumnConfig}
            defaultSort={{ field: 'name', direction: 'asc' }}
            listStyle="striped"
          />
        ) : (
          <List
            data={flattenedPatients}
            format={singleMeasureListFormat}
            columnConfig={columnConfig}
            defaultSort={{ field: 'name', direction: 'asc' }}
            listStyle="striped"
          />
        )}
      </div>
      <Modal
        open={medicationsModalOpen}
        onClose={() => setMedicationsModalOpen(false)}
        type="large"
        style={{ overflow: 'hidden' }}
        modalStyles={{ height: '90%' }}
        header={{ title: 'Medications', centered: true }}
      >
        <MedicationsModalList
          medicationsData={medicationsData}
          loading={medicationsDataLoading}
          error={medicationsDataError}
        />
      </Modal>
    </>
  );
};

import React, { useState, useEffect } from 'react';

import riskBreakup from '@util/riskGrouping';
import { toSentenceCase } from '@util/utilFunctions';

import SingleSelect from '@intus-ui/components/SingleSelect/index';
import { RiskBadge } from '@intus-ui';
import { useListContext } from '../../List/Context';

import '@intus-ui/styles/ListHeader.scss';

const RiskBreakupDropdown = () => {
  const { addFilter } = useListContext();
  const [selected, setSelected] = useState('All');

  useEffect(() => {
    addFilter({ type: 'riskLevel', value: toSentenceCase(selected.toLowerCase()) }); // add a riskLevel filter when something is selected
  }, [selected]);

  return (
    <SingleSelect
      type="badge"
      options={[
        <RiskBadge riskGroup={riskBreakup.ALL.enum} />,
        <RiskBadge riskGroup={riskBreakup.LOW.enum} />,
        <RiskBadge riskGroup={riskBreakup.MODERATE.enum} />,
        <RiskBadge riskGroup={riskBreakup.HIGH.enum} />,
        <RiskBadge riskGroup={riskBreakup.HIGHEST.enum} />,
      ]}
      rightIcon
      onClickItem={(val) => {
        setSelected(val.props.riskGroup);
      }}
      defaultValue="All"
      rightAligned
    />
  );
};

export default RiskBreakupDropdown;

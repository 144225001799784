import React from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

const DropdownTitleAboveValue = ({ title, value, icon, maxWidth = '250px' }) => {
  const titleText = title ? `${title}: ` : '';
  return (
    <div
      title={`${titleText}${value}`}
      className="d-flex justify-content-between align-items-center rounded filter-buttons bg-white"
    >
      <div style={{ maxWidth }} className="d-flex pl-2 justify-content-start align-items-center">
        <div className="text-muted">{icon}</div>
        <div className="small-text text-left pr-2 dropdown-title-label">{titleText}</div>
        <div className="small-text text-left font-weight-bold dropdown-title-value">{value}</div>
        <ArrowDropDownRoundedIcon fontSize="large" className="text-secondary" />
      </div>
    </div>
  );
};

DropdownTitleAboveValue.propTypes = {
  title: PropTypes.node,
  value: PropTypes.node.isRequired,
  icon: PropTypes.node,
};

DropdownTitleAboveValue.defaultProps = {
  title: null,
  icon: null,
};

export default DropdownTitleAboveValue;

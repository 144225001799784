import React from 'react';
import { Modal } from 'react-bootstrap';

// This is the modal that is almost full-screen, off-white background, navy text justified left and a header with a line underneath
const PopupSecondary = ({ open, setOpen, title, body }) => {
  if (!open) return null;

  const hide = () => {
    setOpen(false);
  };
  // TODO: should we move colors into a js file and have style controlled in jsx files, or should we maintain classname styling with more intus-ui scss files?
  // home-modal is a class defined in Home.scss, to modify background color
  return (
    <Modal
      dialogClassName="home-modal min-w-90 overflow-hidden zindex-1040"
      show={!!open}
      onHide={hide}
      scrollable
      // aria-labelledby="contained-modal-title-vcenter"
      style={{ zIndex: 1040 }}
    >
      <Modal.Header className="py-0 pl-4 pt-3 m-0" closeButton>
        <Modal.Title className="d-flex flex-row justify-content-between align-items-center text-align-left text-secondary bg-light w-100 border-bottom border-secondary mb-0 p-2">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="my-0 mx-2">
        <div className="w-100 h-100">{body()}</div>
      </Modal.Body>
    </Modal>
  );
};

export default PopupSecondary;

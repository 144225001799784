import React from 'react';
import { useSelector } from '@global-state';

import { SidebarwithTabs } from '@intus-ui';
import { useFeatureFlag } from '@util';

import Incidents from '../Incidents';
import CurrentMedications from '../CurrentMedications';

const BreakdownConditionsTabs = () => {
  const patientIncidents = useSelector((state) => state.patientDetail.patientIncidents) || [];
  const { featureIsActive: medicationsIsActive } = useFeatureFlag('medications');

  const incidentsTabs = [
    {
      tab: 'Incidents',
      content: <Incidents incidents={patientIncidents} />,
    },
  ];

  if (medicationsIsActive)
    incidentsTabs.push({
      tab: 'Active Medications',
      content: <CurrentMedications />,
    });

  return <SidebarwithTabs items={incidentsTabs} />;
};

export default BreakdownConditionsTabs;

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    height: '100%',
    justifyContent: 'start',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    justifyContent: 'space-between',
    padding: '0px 30px 0px 30px',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    height: '90%',
    padding: '15px 30px 20px 30px',
    gap: '20px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #9D9D9D',
    borderRadius: '10px',
    maxHeight: '100%',
    background: '#FFFFFF',
    padding: '10px 0px 0px 0px',
    overflow: 'auto',
  },
  cohortForm: {
    borderBottom: '1px solid #C8C8C8',
    margin: '0px 15px 10px 15px',
    padding: '0px 0px 10px 0px',
  },
};

import { get } from 'lodash';

const badgeStates = ['default', 'hover', 'active'];
const labels = ['ER', 'ED', 'IP', 'SNF', 'ALF', 'LTC', 'F', 'G', 'B', 'M', 'I', 'Dx', 'Rx', 'O'];
const acuityBadges = [
  { type: 'lowRisk', hoverColor: '#b8d7f5', activeColor: 'rgb(148, 200, 249)' },
  { type: 'moderateRisk', hoverColor: '#96c5d4', activeColor: 'rgb(25, 148, 187)' },
  { type: 'highRisk', hoverColor: '#f5e8ab', activeColor: 'rgb(234, 197, 5' },
  { type: 'highestRisk', hoverColor: '#d17979', activeColor: '#CD3B3B' },
];

const generateBadges = () => {
  const badges = {};

  badgeStates.forEach((state) => {
    badges[state] = {
      infoIcon: generateInfoIcon({ state }),
      unknown: generateBadge({ label: '?', state }),
    };
  });

  labels.forEach((label) => {
    badgeStates.forEach((state) => {
      badges[state][label] = generateBadge({ label, state });
    });
  });

  acuityBadges.forEach((acuity) => {
    badgeStates.forEach((state) => {
      const { type } = acuity;
      badges[state][type] = generateDot({ ...acuity, state });
    });
  });

  return badges;
};

const generateBadge = ({ label, width, state }) => {
  const defaultWidth = 25 + (label.length - 1) * 10;
  let fill = '#C8C8C8';
  if (state === 'hover') fill = '#BACEFF';
  if (state === 'active') fill = '#052D8F';

  const stroke = '#404040';
  const strokeWidth = 1;
  const borderRadius = 3;
  const textFill = state === 'active' ? '#FFFFFF' : '#404040';

  const badgeWidth = width || defaultWidth;
  const badgeHeight = 20;

  const svg = `<?xml version="1.0" encoding="utf-8"?>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${badgeWidth}" height="${badgeHeight}" xml:space="preserve">
      <rect
        rx="${borderRadius}"
        x="${strokeWidth}"
        y="${strokeWidth}"
        width="${badgeWidth - 2 * strokeWidth}"
        height="${badgeHeight - 2 * strokeWidth}"
        fill="${fill}"
        stroke-width="${strokeWidth}"
        stroke="${stroke}"
      />
      <text
        fill="${textFill}"
        x="50%"
        y="${badgeHeight / 2 + strokeWidth}"
        dominant-baseline="middle"
        text-anchor="middle"
        font-weight="bold"
        font-family="Inter, Arial, sans-serif"
        font-size="13"
      >${label}</text>
    </svg>`;

  const badge = new Image(badgeWidth, badgeHeight);
  badge.src = URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
  return badge;
};

const generateDot = ({ defaultColor = '#FFFFFF', hoverColor, activeColor, state }) => {
  const stroke = '#404040';
  let fill = defaultColor;
  if (state === 'hover') fill = hoverColor;
  if (state === 'active') fill = activeColor;

  const svg = `<?xml version="1.0" encoding="utf-8"?>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7" fill="${fill}" stroke="${stroke}"/>
    </svg>`;

  const badge = new Image();
  badge.src = URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
  return badge;
};

const generateInfoIcon = ({ state }) => {
  const circleStroke = state === 'hover' ? '#052D8F' : '#4D7EF8';
  const circleFill = state === 'hover' ? '#BACEFF' : '#FFFFFF';
  const iconFill = state === 'hover' ? '#052D8F' : '#4D7EF8';

  const svg = `<?xml version="1.0" encoding="utf-8"?>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.5" cy="7.5" r="7" fill="${circleFill}" stroke="${circleStroke}"/>
      <path d="M6.888 12V5.952H8.1V12H6.888ZM6.792 4.692V3.432H8.196V4.692H6.792Z" fill="${iconFill}"/>
    </svg>`;

  const badge = new Image();
  badge.src = URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
  return badge;
};

export const badges = generateBadges();

/**
 * Match icon gives you the corrrect svg icon from the params of incident/admit type, and select/hover state
 * @param {string} activeType string that indicates incident or admit type
 * @param {boolean} select boolean that is true if you want the select svg and false if you want the hover svg
 * @returns an image component with the corresponding svg, handled by generateXBadges method
 */
export const getBadge = (label, state = 'default') => {
  if (!label) return badges.default.unknown;
  return get(badges, [state, label], get(badges, [state, 'unknown'], badges.default.unknown));
};

/**
 * Matches the correct acuity point svg icon based on a y pixel value comparison of the params (defined below)
 * @param {number} acuityPointY the y pixel value of the acuity point we want to find the right icon for based on acuity group
 * @param {object of numbers} acuityLimitsPxYValues an object with MODERATE, HIGH, and HIGHEST keys that give you the y pixel value of each risk group's lower limit
 * @param {boolean} select a boolean that is true if you want the select state and false if you want the hover state
 * @returns an image component with the corresponding svg,  handled by generateClickBadges method
 */
export const matchAcuityPoint = (acuityPointY, acuityLimitsPxYValues, active) => {
  const state = active ? 'active' : 'hover';
  let icon;
  if (acuityPointY > acuityLimitsPxYValues.MODERATE) {
    icon = badges[state].lowRisk;
  } else if (acuityPointY > acuityLimitsPxYValues.HIGH) {
    icon = badges[state].moderateRisk;
  } else if (acuityPointY > acuityLimitsPxYValues.HIGHEST) {
    icon = badges[state].highRisk;
  } else {
    icon = badges[state].highestRisk;
  }

  return icon;
};

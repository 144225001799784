import { useHistory, useLocation, Link } from 'react-router-dom';

import { getFeatureFlag } from '@config';
import { useSubscriptions } from '@util';
import * as userTracking from '@util/userTracking';
import { Fragment } from 'react';

import { Divider, MenuItem } from '@mui/material';

import Text from '../Text';

const DashboardMenuOptions = ({ onCloseMenu }) => {
  const subscriptions = useSubscriptions();
  const financialIsActive = !subscriptions?.includes('EHR_PROVIDES_NO_FINANCIALS');
  const { featureIsActive: utilizationsIsActive } = getFeatureFlag('utilization');
  const benchmarkIsActive = getFeatureFlag('ehr-benchmark').featureIsActive;

  // adds user tracking to menu options
  const addUserTrackingToOptions = (options) => {
    return options.map((option) => {
      return {
        ...option,
        onClick: (e) => {
          onClickItem(e, option.route);
          userTracking.logEvent(`Dashboard: Opened ${option.displayName}`, {
            from: 'Nav',
          });
        },
      };
    });
  };

  const menuOptions = [
    {
      displayName: 'Custom Dashboards',
      route: '/customdashboards',
    },
    {
      displayName: 'Census',
      route: '/dashboard/census',
    },
    {
      displayName: 'Risk Acuity',
      route: '/dashboard/riskacuity',
    },
    {
      displayName: 'Falls',
      route: '/dashboard/falls',
    },
    {
      displayName: 'Hospitalizations',
      route: '/dashboard/hospitalizations',
    },
  ];

  if (financialIsActive)
    menuOptions.push({
      displayName: 'Financial',
      route: '/dashboard/financial',
    });

  if (benchmarkIsActive)
    menuOptions.push({
      displayName: 'Performance Benchmark',
      route: '/dashboard/performancebenchmark',
    });

  if (utilizationsIsActive)
    menuOptions.push({
      displayName: 'Utilization',
      route: '/dashboard/utilization',
    });

  const options = addUserTrackingToOptions(menuOptions);

  const history = useHistory();
  const location = useLocation();

  const onClickItem = (e, route) => {
    onCloseMenu();
    history.push(route);
  };

  return (
    <>
      {options.map((option) => {
        const isActive = location.pathname.startsWith(option.route);
        return (
          <Fragment key={option.displayName}>
            <MenuItem onClick={option.onClick} key={option.displayName} role="link">
              <Link to={option.route}>
                <Text type="subtitle" color={isActive ? 'navy' : 'link'}>
                  {option.displayName}
                </Text>
              </Link>
            </MenuItem>
            {option.displayName === 'Custom Dashboards' && (
              <Divider style={{ margin: '0px 15px' }} />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default DashboardMenuOptions;

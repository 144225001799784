import React from 'react';
import '@intus-ui/styles/Utilities.scss';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import TextData from 'Dashboard/QuadContent/TextData';
import BigTitle from 'Dashboard/QuadContent/BigTitle';
import { FallsPrevPropType } from 'Homes/modalPropTypes/fallsPreviewPropType';
import { abbreviateNumber } from '@util/utilFunctions';
import SimpleTooltip from '@intus-ui/components/SimpleTooltip';

const avgFallsTooltip = () => (
  <div className="p-2 d-flex flex-column align-items-start justify-content-start">
    <h6 className="text-primary">
      This is the average falls per quarter over the past three quarters.
    </h6>
  </div>
);

const AvgFalls = ({ content, error }) => (
  <Col lg={6} className="d-flex flex-column justify-content-center align-items-center border-right">
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center align-items-center">
        <BigTitle title="Avg Falls" secondary className="font-weight-bold" />
        <SimpleTooltip className="d-inline-block" content={avgFallsTooltip()}>
          <InfoIcon variant="success" className="info-icon cursor-pointer p-0 ml-1" />
        </SimpleTooltip>
      </div>
      <BigTitle title="per quarter" secondary className="font-weight-bold" />
      {content && content.averageFallsPerQuarter ? (
        <TextData
          data={abbreviateNumber(content.averageFallsPerQuarter, 0)}
          subtext={{ text: 'Falls', vertical: true }}
          className="justify-content-center"
        />
      ) : (
        <SpinnerOrError error={content.averageFallsPerQuarter ? error : '-'} />
      )}
    </div>
  </Col>
);

AvgFalls.propTypes = {
  content: FallsPrevPropType,
  error: PropTypes.string,
};

AvgFalls.defaultProps = {
  error: '',
  content: undefined,
};

const mapState = (state) => ({
  error: state.homepage.homeError,
});

const mapDispatch = () => ({});

export default connect(mapState, mapDispatch)(AvgFalls);

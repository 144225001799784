import React, { FC } from 'react';

import { Line } from 'react-chartjs-2';
import { SpinnerOrError } from '@intus-ui';
import { ChartData, ChartOptions, LayoutPosition, LegendOptions } from 'chart.js';

type LineChartProps = {
  data: ChartData<'line', number[], string>;
  options?: ChartOptions<'line'>;
  legend?: LegendOptions<'line'>;
  aspectRatio?: boolean;
  legendPosition?: LayoutPosition;
  xLabel?: string;
  yLabel?: string;
  title?: string;
  responsive?: boolean;
  noPadding?: boolean;
  maxXTicks?: number;
  maxYTicks?: number;
  loading?: boolean;
};

const LineChart: FC<LineChartProps> = (props) => {
  const {
    data,
    options,
    legend,
    title = '',
    xLabel = '',
    yLabel = '',
    aspectRatio = false,
    responsive = true,
    legendPosition = 'chartArea',
    noPadding = false,
    maxXTicks,
    maxYTicks = 8,
    loading,
  } = props;

  if (!data) return null;
  /*
    Finding the maximum point from all the data to find a suitable
    suggestedMax value for the y-axis.
    */
  const allData = data.datasets.map((dataset) => dataset.data);
  const allMaxData = allData.map((dataset) => Math.max(...dataset));
  const maxDataPoint = Math.max(...allMaxData);
  const paddedMax = maxDataPoint + Math.min(30, maxDataPoint / 5);

  // Default options settings
  const intusOptions: ChartOptions<'line'> = {
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: maxXTicks || data.labels?.length,
          font: {
            size: 12,
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        title: {
          display: true,
          text: xLabel,
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
      y: {
        suggestedMax: paddedMax,
        ticks: {
          font: {
            size: 12,
          },
          precision: 1,
          maxTicksLimit: maxYTicks,
        },
        border: {
          display: false,
        },
        title: {
          display: true,
          text: yLabel,
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: title,
        position: 'top',
        align: 'center',
      },
      tooltip: {
        callbacks: {
          label(tooltipItem) {
            let label = tooltipItem.dataset.label || '';

            if (label) label += ': ';
            label += Math.round(tooltipItem.parsed.y * 100) / 100;
            return label;
          },
        },
      },
      legend:
        legend != null
          ? legend
          : {
              position: legendPosition,
              align: 'center',
              title: {
                padding: 0,
              },
              labels: {
                padding: 8,
                boxWidth: 10,
              },
            },
    },
    maintainAspectRatio: aspectRatio,
    responsive,
    elements: { point: { hitRadius: 15 } },
  };

  return (
    <div className={`w-100 h-100 ${noPadding ? '' : 'mb-4 pb-3'}`}>
      {loading ? (
        <SpinnerOrError />
      ) : (
        <Line
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          data={data}
          options={options !== undefined ? options : intusOptions}
        />
      )}
    </div>
  );
};

export default LineChart;

import { IMedicationClass } from '@api/polypharmacy/medicationClass/getAllMedicationClasses';
import { Icon } from '@intus-ui';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { orderBy } from 'lodash';
import React, { CSSProperties, FC, useMemo, useState } from 'react';

type MedicationClassDropdownProps = {
  allMedClasses: IMedicationClass[];
  selectedMedClass: IMedicationClass | null;
  setSelectedMedClass: (medClass: IMedicationClass | null) => void;
  sx?: AutocompleteProps<any, any, any, any>['sx'];
  autocompleteProps?: Partial<AutocompleteProps<any, any, any, any>>;
};

export const MedicationClassDropdown: FC<MedicationClassDropdownProps> = ({
  allMedClasses,
  selectedMedClass,
  setSelectedMedClass,
  autocompleteProps,
  sx,
}) => {
  const [medClassInput, setMedClassInput] = useState('');
  const [isAutocompleteInFocus, setIsAutocompleteInFocus] = useState(false);

  const gpiToMedClass = useMemo(() => {
    return new Map(allMedClasses.map((m) => [m.gpi, m]));
  }, [allMedClasses]);

  const medClassesWithCategory = useMemo(() => {
    // Mapping of 2 digit GPI to the IMedicationClass record that matches the 2 digit GPI.
    const topLevelCategories = new Map(
      allMedClasses.filter((m) => m.gpi.length === 2).map((m) => [m.gpi, m])
    );

    return allMedClasses.map((medClass) => {
      if (medClass.gpi.length <= 2) {
        return {
          ...medClass,
          category: null,
        };
      }
      const twoDigitGPI = medClass.gpi.slice(0, 2);
      const topLevelClass = topLevelCategories.get(twoDigitGPI);

      if (topLevelClass == null) {
        return {
          ...medClass,
          category: null,
        };
      }

      return {
        ...medClass,
        category: topLevelClass,
      };
    });
  }, [allMedClasses]);

  const classesInSearch = useMemo(() => {
    if (medClassInput == null || medClassInput.trim().length === 0) {
      return orderBy(medClassesWithCategory, [
        (m) => m.category?.groupName ?? m.groupName,
        (m) => m.groupName,
      ]);
    }

    const matchingGPIs = new Set<string>();

    const lowerInput = medClassInput.toLowerCase().trim();

    for (const medClass of medClassesWithCategory) {
      if (
        medClass.category != null &&
        medClass.category.groupName.toLowerCase().includes(lowerInput)
      ) {
        matchingGPIs.add(medClass.category.gpi);
        matchingGPIs.add(medClass.gpi);
      }

      if (medClass.groupName.toLowerCase().includes(lowerInput)) {
        if (medClass.category != null) {
          matchingGPIs.add(medClass.category.gpi);
        }
        matchingGPIs.add(medClass.gpi);
      }
    }

    const matchingClasses = Array.from(matchingGPIs).map((gpi) => gpiToMedClass.get(gpi)!);

    return orderBy(matchingClasses, (m) => m.gpi);
  }, [gpiToMedClass, medClassInput, medClassesWithCategory]);

  return (
    <Autocomplete
      options={classesInSearch}
      disableClearable
      getOptionLabel={(option) => option.groupName}
      sx={{ width: '100%', ...sx }}
      {...autocompleteProps}
      // Disable the regular filtering, we do this ourselves to match category
      filterOptions={(x) => x}
      ListboxProps={{
        sx: {
          maxHeight: '450px',
        },
        ...autocompleteProps?.ListboxProps,
      }}
      value={selectedMedClass ?? undefined}
      onChange={(e, value, reason) => {
        setSelectedMedClass(value ?? null);
        setMedClassInput('');
        if (autocompleteProps?.onChange != null) {
          autocompleteProps.onChange(e, value, reason);
        }
      }}
      onInputChange={(e, newInputValue, reason) => {
        setMedClassInput(newInputValue);
        if (autocompleteProps?.onInputChange != null) {
          autocompleteProps.onInputChange(e, newInputValue, reason);
        }
      }}
      isOptionEqualToValue={(option, value) => {
        if (option == null || value == null) {
          return false;
        }
        return option.gpi === value.gpi && option.gpiType === value.gpiType;
      }}
      renderOption={(props, _option, state) => {
        const medClass = classesInSearch[state.index];
        let style: CSSProperties = {
          paddingLeft: 12,
          fontWeight: 'bold',
        };

        if (medClass.gpi.length >= 4) {
          style = {
            paddingLeft: 28,
          };
        }

        return (
          <li {...props} style={{ ...props.style, ...style }}>
            {classesInSearch[state.index].groupName}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onFocus={() => setIsAutocompleteInFocus(true)}
          onBlur={() => setIsAutocompleteInFocus(false)}
          aria-label="Medication Class"
          placeholder="Search by medication group or class"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Icon
                name="Search"
                color={isAutocompleteInFocus ? '#2E62E7' : 'rgba(157, 157, 157, 1)'}
                style={{ minWidth: '22px' }}
              />
            ),
          }}
        />
      )}
    />
  );
};

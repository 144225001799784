import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { formatDate } from '@util/dateFunctions';
import { getStringReplacement } from '@util/stringReplacements';

import {
  setSelected,
  addModalContent,
  setSelectedMonth,
  getModalPatientList,
} from '@global-state/redux/dashboardSlice';

import { Container, Row, Col } from 'react-bootstrap';
import { SpinnerOrError } from '@intus-ui';
import nivoColors from '@intus-ui/styles/NivoColors';
import Quad from 'Dashboard/Quad';
import TextData from 'Dashboard/QuadContent/TextData';
import { censusModalPropType } from 'Homes/modalPropTypes/censusModalPropType';
import PercentageRow from '../PercentageRow';
import PercentageCol from '../PercentageCol';
import TotalParticipantsTrendline from './CensusAnalytics/TotalParticipantsTrendline';
import YearsEnrolled from './CensusAnalytics/YearsEnrolled';
import Disenrolls from './CensusAnalytics/Disenrolls';

import '@intus-ui/styles/Utilities.scss';

class CensusAnalytics extends React.Component {
  isActive(route) {
    const { location } = this.props;
    return location.pathname.startsWith(route);
  }

  render() {
    const {
      error,
      subcards,
      getPatientList,
      doSetSelected,
      doSetSelectedMonth,
      doSetModalContent,
      isDashboard,
    } = this.props;

    const getEnrollmentData = () => {
      const minEnrolls = Math.min(subcards.monthlyEnrollmentChange.map((item) => item.enrolled));
      const maxEnrolls = Math.max(subcards.monthlyEnrollmentChange.map((item) => item.enrolled));
      const minDisenrolls = Math.min(
        subcards.monthlyEnrollmentChange.map((item) => item.disenrolled)
      );
      const maxDisenrolls = Math.max(
        subcards.monthlyEnrollmentChange.map((item) => item.disenrolled)
      );
      const minDeaths = Math.min(subcards.monthlyEnrollmentChange.map((item) => item.deaths));
      const maxDeaths = Math.max(subcards.monthlyEnrollmentChange.map((item) => item.deaths));
      const max = Math.max(maxEnrolls, maxDisenrolls, maxDeaths);
      const min = Math.max(minEnrolls, minDisenrolls, minDeaths);
      const paddedMax = max + 0.5 * max;
      const paddedMin = min + 0.5 * min;
      const monthlyEnrollsData = subcards.monthlyEnrollmentChange.map((item) => ({
        deaths: -item.deaths,
        deathsColor: nivoColors[2],
        disenrollments: -(item.disenrolled - item.deaths),
        // voluntary: -(item.disenrolled - item.deaths),
        disenrollmentsColor: nivoColors[1],
        enrollments: item.enrolled,
        enrollmentsColor: nivoColors[0],
        label: item.month,
      }));
      const monthlyNetData = subcards.monthlyEnrollmentChange.map((item) => ({
        net: item.enrolled - item.disenrolled,
        netColor: nivoColors[3],
        label: item.month,
      }));
      return {
        data: monthlyEnrollsData,
        netData: monthlyNetData,
        min: paddedMin,
        max: paddedMax,
      };
    };

    if (error || subcards === undefined || subcards.totalParticipantsTrendline === undefined) {
      return <SpinnerOrError error={error} />;
    }
    return (
      <Container
        fluid
        className="bg-light m-0 h-100 w-100 d-flex flex-column overflow-hidden font-weight-normal pt-2 pb-3"
      >
        <div className="w-100 h-100 d-flex flex-column">
          {/* First row of popup */}
          <PercentageRow isDashboard={isDashboard}>
            <PercentageCol isDashboard={isDashboard}>
              <TotalParticipantsTrendline subcards={subcards} error={error} />
            </PercentageCol>
            <PercentageCol isDashboard={isDashboard}>
              <Quad
                title="Living in Community (Current)"
                onClick={
                  subcards?.currentlyLivingInCommunity.ids.length > 0
                    ? () => {
                        getPatientList(
                          [
                            {
                              patientList: {
                                patientList: subcards.currentlyLivingInCommunity.ids,
                              },
                            },
                          ],
                          'livingSituationChanges',
                          'Living in Community (Current)',
                          this.isActive('/home') ? 'home' : 'dashboard'
                        );
                      }
                    : null
                }
                padding="pb-5 mt-0 pt-0"
              >
                <Row className="w-100 h-100 pl-4 justify-content-center">
                  <Col className="h-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="pt-5 pb-5 d-flex justify-content-center align-items-center flex-column">
                      <TextData
                        data={
                          subcards !== undefined ? (
                            subcards.currentlyLivingInCommunity.value.toString()
                          ) : (
                            <SpinnerOrError error={error} />
                          )
                        }
                        subtext={{
                          text: `${getStringReplacement('Participant', {
                            lower: true,
                            plural: true,
                          })}`,
                          vertical: true,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Quad>
            </PercentageCol>
          </PercentageRow>
          {/* Second row of popup */}
          <PercentageRow isDashboard={isDashboard}>
            <PercentageCol isDashboard={isDashboard}>
              <Quad
                scrollable
                title="Enrollment Changes (Monthly)"
                onClick={
                  subcards?.monthlyEnrollmentChange
                    ? () => {
                        doSetSelected({
                          item: 'enrollments',
                          modalTitle: 'Enrollment Changes',
                        });
                        doSetSelectedMonth(0);
                        doSetModalContent([
                          {
                            display: 'timeLapseBarChart',
                            content: {
                              data: [getEnrollmentData().data, getEnrollmentData().netData],
                              title: 'Click the toggle button to view different data displays',
                              xTitle: 'Month',
                              yTitle: '# of patients',
                              minValue: getEnrollmentData().min,
                              maxValue: getEnrollmentData().max,
                              toggleLabels: ['Enrollment Breakdown', 'Net Enrollment'],
                              legend: true,
                              xFormat: (value) => formatDate(value, 'MMMM D, YYYY'),
                            },
                          },
                        ]);
                      }
                    : undefined
                }
                padding="py-3 justify-content-center"
              >
                <Row className="w-100 h-100 d-flex flex-column justify-content-center">
                  <Col className="w-100 h-100 d-flex flex-column pl-4 align-items-center">
                    <Row className="w-100 h-100 d-flex flex-row align-items-center justify-content-center">
                      <div>
                        <TextData
                          data={
                            subcards !== undefined ? (
                              subcards.monthlyEnrollmentChange[11].enrolled.toString()
                            ) : (
                              <SpinnerOrError error={error} />
                            )
                          }
                          size={3}
                          subtext={{ text: 'Enrollments' }}
                          arrow="up"
                          className="justify-content-start"
                        />
                        <TextData
                          data={
                            subcards !== undefined ? (
                              subcards.monthlyEnrollmentChange[11].disenrolled.toString()
                            ) : (
                              <SpinnerOrError error={error} />
                            )
                          }
                          size={3}
                          subtext={{ text: 'Disenrollments' }}
                          arrow="down"
                          className="justify-content-start"
                        />
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Quad>
            </PercentageCol>
            <PercentageCol isDashboard={isDashboard}>
              <YearsEnrolled subcards={subcards} error={error} />
            </PercentageCol>
            <PercentageCol isDashboard={isDashboard}>
              <Disenrolls subcards={subcards} error={error} />
            </PercentageCol>
          </PercentageRow>
        </div>
      </Container>
    );
  }
}

CensusAnalytics.propTypes = {
  subcards: censusModalPropType,
  getPatientList: PropTypes.func.isRequired,
  error: PropTypes.string,
  doSetSelected: PropTypes.func.isRequired,
  doSetSelectedMonth: PropTypes.func.isRequired,
  doSetModalContent: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool,
  location: PropTypes.instanceOf(Object).isRequired,
};

CensusAnalytics.defaultProps = {
  subcards: undefined,
  error: undefined,
  isDashboard: undefined,
};

const mapState = (state) => ({
  subcards: state.homepage.modalContent,
  error: state.homepage.homeError,
});

const mapDispatch = (dispatch) => ({
  getPatientList: (list, item, modalTitle, backLoc) =>
    dispatch(getModalPatientList(list, item, modalTitle, backLoc)),
  doSetSelected: (item, name) => dispatch(setSelected(item, name)),
  doSetSelectedMonth: (val) => dispatch(setSelectedMonth(val)),
  doSetModalContent: (val) => dispatch(addModalContent(val)),
});

export default withRouter(connect(mapState, mapDispatch)(CensusAnalytics));

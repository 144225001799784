import { postDownloadMetric } from '@api/dashboard';
import { useDownload } from '@api/useDownload';
import { useParticipantFilters } from '@intus-ui';
import { useSelector } from 'react-redux';

export function useMetricDownload(metric, metricData, options) {
  const title = metricData?.data?.title ?? metric.name ?? '';

  const { selectedFilters } = useParticipantFilters();

  const timeFilter = useSelector((state) => state.filters.customDashTimeFilter);

  // Replace non alphanumeric characters in the metric name.
  const fileName = `${title
    .replace(/\s/gi, '_')
    .replace(/[^a-z0-9_]/gi, '')
    .toLowerCase()}.xlsx`;

  const { runQuery, loading, error } = useDownload(
    fileName,
    () =>
      postDownloadMetric(
        metric.metricId,
        timeFilter.startDate,
        timeFilter.endDate,
        metric.metricConfig,
        selectedFilters
      ),
    options
  );

  return {
    runQuery,
    loading,
    error,
  };
}

import { useState } from 'react';
import { isEqual, orderBy } from 'lodash';
import { MuuriComponent, ItemDrag, useDraggable } from 'muuri-react';
import { Box } from '@mui/material';
import { Widget, Icon, Text } from '@intus-ui';

const WidgetGrid = ({
  dashboard,
  onAddNewMetric,
  deleteMetricById,
  onEditMetric,
  runUpdateDashboardQuery,
  newMetricId,
  setSnackbarErrorMessage,
}) => {
  const canEdit = dashboard == null ? true : dashboard.canUserEdit;
  const [dragIsEnabled, setDragIsEnabled] = useState(false);

  const handleOnDragEnd = (item) => {
    const grid = item.getGrid();
    const items = grid.getItems();
    // Remove the first "add new button" element
    items.shift();

    // Extract metrics from each item
    const metricsInOrder = items.map(({ _component }) => {
      const { metric } = _component.props;
      return metric;
    });

    // Only update the dashboard if the order of the metrics has changed
    if (!isEqual(metricsInOrder, dashboard?.metrics)) {
      const updatedDashboard = {
        ...dashboard,
        metrics: metricsInOrder,
      };
      runUpdateDashboardQuery(updatedDashboard);
    }
  };

  const orderedMetrics = orderBy(dashboard?.metrics ?? [], (m) => m.order, 'asc');

  const children =
    orderedMetrics.map((metric) => {
      // Be careful with the key here.
      // In the tutorial dashboard workflow (the book button on the create page),
      // the metrics are not saved yet so only have a `metricId` field.
      //
      // If a user edits a metric and picks a different metric in the dropdown, we want the key to be the numeric `id` field
      // or Muuri has a bug where the metrics overlap the Add New Metric button.
      const myKey = metric?.id?.toString() ?? metric?.metricId;
      return (
        <Widget
          key={myKey}
          metric={metric}
          canUserEdit={dashboard.canUserEdit}
          dragIsEnabled={dragIsEnabled}
          setDragIsEnabled={setDragIsEnabled}
          deleteMetricById={deleteMetricById}
          onEditMetric={onEditMetric}
          newMetricId={newMetricId}
          setSnackbarErrorMessage={setSnackbarErrorMessage}
        />
      );
    }) || [];

  if (canEdit) {
    children.unshift(
      <div
        key="add-new-metric-button-container"
        id="add-new-metric-button-container"
        style={{ width: 250, height: 300 }}
      >
        <AddNewMetricButton onClick={onAddNewMetric} />
      </div>
    );
  }

  const numberOfMetrics = orderedMetrics.length;

  const widgetGridOptions = {
    dragEnabled: dragIsEnabled && canEdit,
  };

  return (
    <div style={styles.container}>
      <MuuriComponent
        // Destroy and recreate the grid when the number of metrics changes.
        // This prevents an issue where the new metric would added at the start of the grid
        // and overlap the Add New Metric button.
        key={numberOfMetrics.toString()}
        onDragEnd={handleOnDragEnd}
        {...widgetGridOptions}
        // Disable the animation when the grid is first created.
        // This makes it so we can destroy and recreate the grid to redraw it without the animation playing.
        layoutDuration={0}
        dragSortPredicate={(item) => {
          // See: https://paol-imi.github.io/muuri-react/docs/api-reference/muuricomponent/#dragsortpredicate
          // also: https://github.com/haltu/muuri/issues/260
          //
          // This disables dropping an item before the Add New Metric button.
          const result = ItemDrag.defaultSortPredicate(item);
          return result && result.index === 0 ? false : result;
        }}
      >
        {children}
      </MuuriComponent>
    </div>
  );
};

const AddNewMetricButton = ({ onClick }) => {
  const setDraggable = useDraggable();

  // Disable dragging for the Add New Metric button in Muuri
  setDraggable(false);

  function onEnterKey(e) {
    if (e.key === 'Enter') {
      onClick();
    }
  }
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={(event) => onEnterKey(event)}
      style={styles.addNewMetricButton}
    >
      <Box sx={styles.addNewMetricButtonInner}>
        <Icon name="AddHeavy" color="navy" />
        <Text type="subtitle" color="navy">
          add new metric
        </Text>
      </Box>
    </div>
  );
};

const styles = {
  addNewMetricButton: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    padding: 1,
    borderRadius: '10px',
    // This uses a generated SVG for a border.
    // Generated with: https://kovart.github.io/dashed-border-generator/
    // Only thing I changed was the stroke color.
    // FYI that the `%23` in stroke is a # symbol since you can't use # in SVGs, so after the %23 is the hex code for the color.
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%232E62E7' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
  addNewMetricButtonInner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E4ECFF',
    gap: 10,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#c5d6fc',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    height: '100%',
    width: '100vw',
  },
};
export default WidgetGrid;

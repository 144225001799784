import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { formatDate } from '@util/dateFunctions';

import { Modal, Button, Row, Col, FormControl, FormCheck, Form } from 'react-bootstrap';
import { EHR_COGNIFY, EHR_TRUCHART, EHR_RTZ } from 'Settings/userSettingsPropType';
import { updateOrg, getRecentUpdate } from '../UserTracking/AdminSlice';

const OrganizationModal = ({
  history,
  show,
  hide,
  info: {
    id,
    name,
    activeValue,
    caregiverCount,
    patientCount,
    adminCount,
    subscriptions,
    updatedAt,
  },
  doUpdateOrg,
  doGetRecentUpdate,
  recentChangeDates,
}) => {
  const [activation, setActivation] = useState('');
  const [acuityCheckbox, setAcuityCheckbox] = useState(false);
  const [dashboardCheckbox, setDashboardCheckbox] = useState(false);
  const [cohortCheckbox, setCohortCheckbox] = useState(false);
  const [ehrCheckbox, setEhrCheckbox] = useState(undefined);

  const activationChange = (e) => setActivation(e.target.value);
  const acuityChange = (e) => setAcuityCheckbox(e.target.checked);
  const dashboardChange = (e) => setDashboardCheckbox(e.target.checked);
  const cohortChange = (e) => setCohortCheckbox(e.target.checked);

  const ehrSubscription = (subscriptions || []).filter(
    (item) => item === EHR_TRUCHART || item === EHR_COGNIFY || item === EHR_RTZ
  );
  const checkboxButtonEnabled = () => {
    if (subscriptions) {
      return (
        subscriptions.includes('ACUITY') === acuityCheckbox &&
        subscriptions.includes('DASHBOARD') === dashboardCheckbox &&
        subscriptions.includes('COHORTS') === cohortCheckbox &&
        ehrSubscription[0] === ehrCheckbox
      );
    }
    return true;
  };

  useEffect(() => {
    // resets initial editable checkboxes whenever the modal is shown
    if (subscriptions && show) {
      doGetRecentUpdate(id);
      setAcuityCheckbox(subscriptions.includes('ACUITY'));
      setDashboardCheckbox(subscriptions.includes('DASHBOARD'));
      setCohortCheckbox(subscriptions.includes('COHORTS'));
      if (subscriptions.includes(EHR_COGNIFY)) setEhrCheckbox(EHR_COGNIFY);
      else if (subscriptions.includes(EHR_TRUCHART)) setEhrCheckbox(EHR_TRUCHART);
      else if (subscriptions.includes(EHR_RTZ)) setEhrCheckbox(EHR_RTZ);
    }
  }, [subscriptions, show, id, doGetRecentUpdate]);

  const hideModal = () => {
    setActivation('');
    setEhrCheckbox(undefined);
    hide();
  };

  const editSubscriptions = () => {
    const subs = [];
    if (acuityCheckbox) subs.push('ACUITY');
    if (dashboardCheckbox) subs.push('DASHBOARD');
    if (cohortCheckbox) subs.push('COHORTS');
    subs.push(ehrCheckbox);
    doUpdateOrg({ subscriptions: subs, ehr: ehrCheckbox }, id);
    hideModal();
  };

  const editStatus = () => {
    let newStatus = activeValue;
    if (activeValue === 'ACTIVE') {
      newStatus = 'INACTIVE';
    } else {
      newStatus = 'ACTIVE';
    }
    doUpdateOrg({ status: newStatus }, id);
    hideModal();
  };

  return (
    <Modal show={show} onHide={hideModal} scrollable>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center font-weight-bold">{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className=" font-weight-bold">Basic Info</h5>
        <hr />
        <Row className="mb-1">
          <Col md="5" className="font-weight-bold">
            ID:
          </Col>
          <Col>{id}</Col>
        </Row>
        <Row className="my-1">
          <Col md="5" className="font-weight-bold">
            Participants:
          </Col>
          <Col>{patientCount}</Col>
        </Row>
        <Row className="my-1">
          <Col md="5" className="font-weight-bold">
            Caregivers:
          </Col>
          <Col>{caregiverCount}</Col>
        </Row>
        <Row className="my-1">
          <Col md="5" className="font-weight-bold">
            Admins:
          </Col>
          <Col>{adminCount}</Col>
        </Row>
        <Row className="align-items-center my-1">
          <Col md="5" className="font-weight-bold">
            Status:{' '}
          </Col>
          <Col
            className={
              activeValue === 'ACTIVE'
                ? 'font-weight-bold text-primary'
                : 'font-weight-bold text-muted'
            }
          >
            {activeValue}
          </Col>
        </Row>
        {activeValue === 'INACTIVE' && (
          <Row className="my-1">
            <Col md="5" className="font-weight-bold">
              Inactive Since:
            </Col>
            <Col>{formatDate(updatedAt, 'MMM D, YYYY')}</Col>
          </Row>
        )}
        <Row className="my-1">
          <Col md="5" className="font-weight-bold">
            Subscriptions:
          </Col>
          <Col>{subscriptions && subscriptions.length ? subscriptions.join(', ') : 'None'}</Col>
        </Row>
        {/* <Row className="my-1">
          <Col md="5" className="font-weight-bold">EHR:</Col>
          <Col>{ehrSubscription.length ? ehrSubscription[0] : undefined}</Col>
        </Row> */}
        <Row>
          <Button
            onClick={() => history.push(`/admin/${id}`)}
            className="w-100 font-weight-bold p-2"
          >
            VIEW ADMINS
          </Button>
        </Row>
        <h5 className=" font-weight-bold">Edit Settings</h5>
        <hr />
        <Row className="align-items-center my-3">
          <Col>
            <h6>Subscriptions</h6>
            <div className="my-2 ml-2">
              <FormCheck
                id="acuity-checkbox"
                checked={acuityCheckbox}
                onChange={acuityChange}
                custom
                label="ACUITY"
              />
              <FormCheck
                id="dashboard-checkbox"
                checked={dashboardCheckbox}
                onChange={dashboardChange}
                custom
                label="DASHBOARD"
              />
              <FormCheck
                id="cohort-checkbox"
                checked={cohortCheckbox}
                onChange={cohortChange}
                custom
                label="COHORTS"
              />
            </div>
            <h6>EHR</h6>
            <div className="my-2 ml-2">
              <FormCheck
                id="ehr-cognify"
                custom
                label="Cognify"
                type="radio"
                name="ehr-radio"
                checked={ehrCheckbox === EHR_COGNIFY}
                onChange={() => setEhrCheckbox(EHR_COGNIFY)}
              />
              <FormCheck
                id="ehr-truchart"
                custom
                label="TruChart"
                type="radio"
                name="ehr-radio"
                checked={ehrCheckbox === EHR_TRUCHART}
                onChange={() => setEhrCheckbox(EHR_TRUCHART)}
              />
              <FormCheck
                id="ehr-rtz"
                custom
                label="RTZ"
                type="radio"
                name="ehr-radio"
                checked={ehrCheckbox === EHR_RTZ}
                onChange={() => setEhrCheckbox(EHR_RTZ)}
              />
            </div>
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Button className="m-0" disabled={checkboxButtonEnabled()} onClick={editSubscriptions}>
              Edit Subscriptions
            </Button>
          </Col>
        </Row>

        <h5 className=" font-weight-bold"> Recent Updates</h5>
        <hr />
        <Row className="mb-1">
          <Col md="5" className="font-weight-bold">
            Hospitalizations:
          </Col>
          <Col>{recentChangeDates?.hospitalizations}</Col>
        </Row>
        <Row className="mb-1">
          <Col md="5" className="font-weight-bold">
            Fall Entities:
          </Col>
          <Col>{recentChangeDates?.fallIncidents}</Col>
        </Row>
        <Row className="mb-1">
          <Col md="5" className="font-weight-bold">
            Acuity Levels:
          </Col>
          <Col>{recentChangeDates?.acuityLevels}</Col>
        </Row>
        <Row className="mb-1">
          <Col md="5" className="font-weight-bold">
            Enrollment Date:
          </Col>
          <Col>{recentChangeDates?.enrollmentDate}</Col>
        </Row>
        <Row className="mb-3">
          <Col md="5" className="font-weight-bold">
            Disenrollment Date:
          </Col>
          <Col>{recentChangeDates?.disenrollmentDate}</Col>
        </Row>

        <h5 className=" font-weight-bold">Danger Zone</h5>
        <hr />
        <Row>
          <Col>Type the organization name to activate/deactivate it.</Col>
        </Row>
        <Form>
          <Row className="align-items-center my-3">
            <Col>
              <FormControl className="rounded-lg" value={activation} onChange={activationChange} />
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              <Button
                variant={activeValue ? 'muted' : 'primary'}
                className="m-0"
                disabled={activation !== name}
                onClick={editStatus}
                type="submit"
              >
                {activeValue === 'ACTIVE' ? 'DEACTIVATE' : 'ACTIVATE'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

OrganizationModal.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  info: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    activeValue: PropTypes.string.isRequired,
    caregiverCount: PropTypes.number,
    patientCount: PropTypes.number,
    adminCount: PropTypes.number,
    subscriptions: PropTypes.arrayOf(PropTypes.string.isRequired),
    updatedAt: PropTypes.string,
  }).isRequired,
  doUpdateOrg: PropTypes.func.isRequired,
  doGetRecentUpdate: PropTypes.func.isRequired,
  recentChangeDates: PropTypes.instanceOf(Object),
};

OrganizationModal.defaultProps = {
  recentChangeDates: undefined,
};

const mapState = (state) => ({
  recentChangeDates: state.admin.recentChangeDates,
});

const mapDispatch = (dispatch) => ({
  doUpdateOrg: (body, id) => dispatch(updateOrg(body, id)),
  doGetRecentUpdate: (id) => dispatch(getRecentUpdate(id)),
});

export default connect(mapState, mapDispatch)(OrganizationModal);

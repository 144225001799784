import { Checkbox, Text } from '@intus-ui';
import { FormErrorMessage } from '@intus-ui/components/forms/errors/FormErrorMessage';
import { MetricTypeRadioButtons } from 'CustomDashboardV2/MetricLibrary/MetricConfigs/MetricTypeRadioButtons';

import { Controller } from 'react-hook-form';

export const StatMetricConfiguration = ({ form, metricConfig }) => {
  const { control, formState, getValues, setValue } = form;

  return (
    <>
      {metricConfig.canShowChart && (
        <>
          <Controller
            name="metricConfig.showChart"
            control={control}
            defaultValue={false}
            render={({ field }) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Checkbox
                    checked={field.value}
                    aria-labelledby="ongoing-text"
                    onChange={field.onChange}
                  />
                  <Text
                    onClick={() =>
                      setValue('metricConfig.showChart', !getValues('metricConfig.showChart'))
                    }
                  >
                    show trend over time
                  </Text>
                </div>
              );
            }}
          />
          <FormErrorMessage error={formState?.errors?.metricConfig?.showChart} />
        </>
      )}

      {metricConfig.metricTypes?.length > 0 && (
        <>
          <Controller
            name="metricConfig.metricType"
            control={control}
            defaultValue="count"
            render={({ field }) => {
              return (
                <MetricTypeRadioButtons
                  metricConfig={metricConfig}
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <FormErrorMessage error={formState?.errors?.metricConfig?.metricType} />
        </>
      )}
    </>
  );
};

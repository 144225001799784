import React, { CSSProperties } from 'react';
import { Tag } from '@intus-ui';
import { formatDate, newDate } from '@util/dateFunctions';
import { TagProps } from '@intus-ui/components/Tags/Tag';

type NextFillDueColumnProps = {
  fillDueDate: string | null;
  hideText?: boolean;
  style?: CSSProperties;
};

export const allNextFillDueStatus = [
  'All',
  'Overdue',
  'Due in ≤7 days',
  'Due in ≤14 days',
  'Okay',
] as const;
export type INextFillDueStatus = (typeof allNextFillDueStatus)[number];

export const NextFillDueColumn = (props: NextFillDueColumnProps) => {
  const { fillDueDate, hideText = false, style = {} } = props;

  if (!fillDueDate) return <span>&nbsp;</span>;

  const status = getNextFillDueStatus(fillDueDate);

  return (
    <div style={{ display: 'flex', gap: 10, ...style }}>
      {!hideText && <div style={{ width: 65 }}>{formatDate(fillDueDate, 'MM/DD/YY')}</div>}
      {status != null && status.status !== 'Okay' && (
        <Tag style={styles.tag} color={status.color}>
          {status.status}
        </Tag>
      )}
    </div>
  );
};

export function getNextFillDueStatus(fillDueDate: string | null): {
  status: INextFillDueStatus;
  color: TagProps['color'];
} | null {
  if (!fillDueDate) return null;

  const fillDueDateDayjs = newDate(fillDueDate);

  if (fillDueDateDayjs.isBefore(newDate())) {
    return { status: 'Overdue', color: 'orange' };
  }
  if (fillDueDateDayjs.isBefore(newDate().add(7, 'day'))) {
    return { status: 'Due in ≤7 days', color: 'pink' };
  }
  if (fillDueDateDayjs.isBefore(newDate().add(14, 'day'))) {
    return { status: 'Due in ≤14 days', color: 'purple' };
  }

  return { status: 'Okay', color: 'green' };
}

const styles: Record<string, CSSProperties> = {
  tag: {
    width: 'auto',
    whiteSpace: 'nowrap',
  },
};

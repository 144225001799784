import { postPatientListAPI, getCohorts, getActionCohorts } from '@api';

import { getLogger } from '@util/logger';

const log = getLogger('AdvancedCohortHelper');

export const formatPatient = (patient) => ({
  ...patient,
  id: patient.id,
  name: `${patient.firstName} ${patient.lastName}`,
  memberId: patient.memberId,
  riskGroup: (patient.acuityLevels[0] || {}).riskGroup,
  acuityScore: (patient.acuityLevels[0] || {}).acuityScore,
  team: patient.team,
  facility: patient.facility,
  acuity: { riskGroup: (patient.acuityLevels[0] || {}).riskGroup },
  livingSituation: (patient.acuityLevels[0] || {}).livingSituation,
});

export const getPatientsFromList = async (patientIds) => {
  try {
    // GET PATIENTS FROM LIST
    const getPatientsInfos = await postPatientListAPI({ patientList: patientIds });
    const patientsInfosJson = await getPatientsInfos.json();
    // FORMAT PATIENT LIST
    return patientsInfosJson.map((patient) => formatPatient(patient));
  } catch (e) {
    log.error(e);
    return null;
  }
};

export const fetchCohorts = async ({ cohortType, query, filters }) => {
  let fetchCohortFunction = getCohorts;
  if (cohortType === 'action') fetchCohortFunction = getActionCohorts;

  try {
    const fetch = await fetchCohortFunction({
      filters,
      query,
      fixed: cohortType === 'fixed',
    });
    const cohortsList = await fetch.json();
    return { cohorts: cohortsList };
  } catch (e) {
    log.error(e);
    return null;
  }
};

export const getCohortById = async ({ cohortId, cohortType, filters, isFixedCohort }) => {
  try {
    const { cohorts } = await fetchCohorts({
      cohortType,
      query: { id: cohortId, includeDisenrolled: isFixedCohort, includeInitiatives: true },
      filters,
    });
    const cohort = cohorts[0];
    const filter = cohort?.searchCriteria;
    const formattedPatientList = await getPatientsFromList(cohort.patientIds);

    return {
      ...cohort,
      filter,
      formattedPatientList,
    };
  } catch (e) {
    log.error(e);
    return null;
  }
};

export const checkFiltersUndefined = (searchState) => {
  const groups = searchState.filter?.groups;
  if (groups == null) return true;
  // special case for fixed cohorts: isValid when there are no filters
  const filtersUndefined =
    groups.length === 1 &&
    groups[0]?.conditions[0]?.type === undefined &&
    groups[0]?.conditions[0]?.searchTerm === undefined;

  if (filtersUndefined) return true;
  return false;
};

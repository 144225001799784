import Text from '@intus-ui/components/Text';
import { getLogger } from '@util/logger';

const log = getLogger('FormErrorMessage');

export const FormErrorMessage = ({ error }) => {
  if (error == null) {
    return null;
  }

  const message = getErrorMessage(error);

  if (message == null) {
    return null;
  }

  return (
    <Text type="caption" color="error" className="formErrorMessage">
      {message}
    </Text>
  );
};

function getErrorMessage(error) {
  if (typeof error === 'string') {
    return error;
  }

  if (error.message != null) {
    return error.message;
  }

  // We may get an array of errors in the case where:
  // 1. There is validation on an array of objects that requires at least one object in the array.
  // 2. The user added an object, but the object has an error on one of it's fields.
  //
  // We do not display an error in that case since we only care about the error on the field.
  if (Array.isArray(error)) {
    return null;
  }

  // We may get an object like this from `{ required: "This field is required." }`
  // If we do, grab the first message.
  const firstError = Object.values(error)?.[0];

  if (firstError != null && firstError.message != null) {
    return firstError.message;
  }

  // This shouldn't happen but if it does log something so we can debug it.
  log.error('Unknown error type passed to FormErrorMessage', error);

  return null;
}

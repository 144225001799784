import React, { FC } from 'react';
import { IICDCode } from '@api/patients/getCurrentConditions';
import { Modal } from '@intus-ui';
import { ICDCodeList } from 'Participants/acuity/diagnoses/ICDCodeList';

type ICDCodeModalProps = {
  title: string;
  icdCodes: IICDCode[];
  open: boolean;
  onClose: () => void;
};

export const ICDCodeModal: FC<ICDCodeModalProps> = ({ title, icdCodes, open, onClose }) => {
  return (
    <Modal
      key={open.toString()}
      open={open}
      onClose={onClose}
      header={{ title, centered: true }}
      type="large"
    >
      <ICDCodeList icdCodes={icdCodes} />
    </Modal>
  );
};

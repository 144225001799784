import { Button, Input, Modal, Text } from '@intus-ui';
import { FormErrorMessage } from '@intus-ui/components/forms/errors/FormErrorMessage';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const EditNameModal = ({
  existingDashboard,
  openEditNameModal,
  setOpenEditNameModal,
  runUpdateDashboardQuery,
  updateDashboardError,
  updateDashboardLoading,
}) => {
  const myForm = useForm({
    defaultValues: {
      name: existingDashboard?.name || 'Untitled Dashboard',
    },
    mode: 'onChange',
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = myForm;

  const [error, setError] = useState(null);

  useEffect(() => {
    if (updateDashboardError?.toString().includes('Validation error'))
      setError('Dashboard name already exists');
    else if (updateDashboardError) setError('An error occurred while saving this dashboard.');
    else setError(null);
  }, [updateDashboardError]);

  const handleSaveName = async (formData) => {
    const { name } = formData;
    let updatedDashboard;
    if (existingDashboard == null) {
      updatedDashboard = {
        name,
        isShared: false,
        metrics: [],
      };
    } else {
      updatedDashboard = {
        ...existingDashboard,
        name,
      };
    }
    await runUpdateDashboardQuery(updatedDashboard);
  };

  return (
    <Modal
      header={{ title: 'Rename Dashboard', centered: true }}
      type="small"
      open={openEditNameModal}
      onClose={() => setOpenEditNameModal(false)}
    >
      <form onSubmit={handleSubmit(handleSaveName)} id="edit-name-container">
        <div
          style={{
            display: 'flex',
            height: '70px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field }) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: '15px 10px',
                    gap: '5px',
                  }}
                >
                  <Input
                    onChange={(val) => {
                      setError(null);
                      field.onChange(val);
                    }}
                    style={{ width: 325, height: '90px' }}
                    value={field.value}
                    onBlur={field.onBlur}
                  />
                  <FormErrorMessage error={errors?.name} />
                  <Text type="caption" color="error">
                    {error}
                  </Text>
                </div>
              );
            }}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Button
              textOnly
              onClick={() => setOpenEditNameModal(false)}
              busy={updateDashboardLoading}
            >
              Cancel
            </Button>
            <Button type="submit" busy={updateDashboardLoading}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditNameModal;

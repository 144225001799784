import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { useLazyQuery } from '@api/useQuery';
import { getAdmitReviewNote } from '@api/admitReviewNotes';

// import { SpinnerOrError } from '@intus-ui';
import { AdmitsData } from 'Participants/Admits/AdmitsList';
import { ReadOnlyView } from './ReadOnlyView';
import { CreateEditView } from './CreateEditView';

import { ReviewNoteResponseData } from './types';

const emptyArnState = {
  preventionPoints: [],
  notes: '',
  requiredSteps: [],
  nextSteps: [],
  lastEditedBy: {
    id: 0,
    firstName: '',
    lastName: '',
  },
  lastEditedAt: '',
  checklist: { nextSteps: [], requiredSteps: [] },
  status: 'NOT STARTED',
  createdAt: '',
};

type AdmitReviewNoteProps = {
  arnAdmit: AdmitsData;
};
export const AdmitReviewNote = ({ arnAdmit }: AdmitReviewNoteProps) => {
  const [existingArn, setExistingArn] = useState<ReviewNoteResponseData>(emptyArnState);
  const [editMode, setEditMode] = useState(false);
  const [justSaved, setJustSaved] = useState(false); // this should be handled with a history.replace() in the future
  const {
    runQuery,
    // loading,
    //  error
  } = useLazyQuery(() => getAdmitReviewNote(arnAdmit?.compositeKey), {
    onSuccess: (data) => {
      setExistingArn(data as ReviewNoteResponseData);
    },
  });

  useEffect(() => {
    if (arnAdmit?.admitId) {
      runQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arnAdmit?.admitId]);

  useEffect(() => {
    const dataEmpty = isEqual(existingArn, emptyArnState);
    if (!justSaved) {
      const hasNextSteps = existingArn.nextSteps.length > 0;
      const hasPreventionPoints = existingArn.preventionPoints.length > 0;
      const newEditMode = dataEmpty || !hasNextSteps || !hasPreventionPoints;
      // Update editMode state
      setEditMode(newEditMode);
    }
    setJustSaved(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingArn, justSaved]);

  // if (loading || error) return <SpinnerOrError />;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '93%',
        overflowY: 'auto',
      }}
    >
      {!editMode && (
        <ReadOnlyView
          admit={arnAdmit}
          compositeKey={arnAdmit?.compositeKey}
          arnData={existingArn}
          setEditMode={setEditMode}
        />
      )}
      {editMode && (
        <CreateEditView
          admit={arnAdmit}
          existingArn={existingArn}
          setEditMode={setEditMode}
          setExistingArn={setExistingArn}
          setJustSaved={setJustSaved}
          editMode={editMode}
        />
      )}
    </div>
  );
};

import { Button, Modal, Text } from '@intus-ui';
import CohortCard from './CohortCard';

const SuccessModal = ({ cohort, isOpen, setIsOpen }) => {
  return (
    <Modal
      header={{ title: 'Success - Cohort Created!', centered: true }}
      open={isOpen}
      type="small"
      onClose={() => setIsOpen(false)}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          gap: '20px',
          flexDirection: 'column',
          padding: '15px 0px 15px 0px',
        }}
      >
        <Text>You have successfully created the following {cohort?.type} cohort.</Text>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <CohortCard
            cohort={cohort}
            style={{ height: '210px', maxWidth: '357px' }}
            config={{
              flat: true,
              clickable: false,
              patientCounts: true,
              cursor: 'default',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button type="primary" onClick={() => setIsOpen(false)} style={{ width: '120px' }}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default SuccessModal;

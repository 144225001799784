import React from 'react';
import PropTypes from 'prop-types';
import { admissionLength } from '@util/admissionType';
import '@intus-ui/styles/Utilities.scss';

const HospIcon = ({ stayLength }) => {
  if (stayLength === null) {
    return (
      <svg height="1em" width="1em">
        <rect rx="0" ry="0" width="16" height="16" fill={admissionLength.UNKNOWN.color} />
      </svg>
    );
  }
  if (stayLength <= admissionLength.SHORT.max_cutoff) {
    return (
      <svg height="1em" width="1em">
        <circle cx=".5em" cy=".5em" r="6" fill={admissionLength.SHORT.color} />
      </svg>
    );
  }
  if (
    admissionLength.MEDIUM.min_cutoff < stayLength &&
    stayLength <= admissionLength.MEDIUM.max_cutoff
  ) {
    return (
      <svg height="1em" width="1em">
        <rect rx="0" ry="0" width="18" height="18" fill={admissionLength.MEDIUM.color} />
      </svg>
    );
  }
  return (
    <svg height="1em" width="1em">
      <rect rx="0" ry="0" width="16" height="16" fill={admissionLength.LONG.color} />
    </svg>
  );
};

HospIcon.propTypes = {
  stayLength: PropTypes.number,
};

HospIcon.defaultProps = {
  stayLength: null,
};

export default HospIcon;

import React from 'react';
import Highlighter from 'react-highlight-words';
import { PropTypes } from 'prop-types';
import searchWords from './searchWords.json';

const CarePlanText = ({ type, text }) => (
  <div>
    <h5 className="text-secondary font-weight-bold">{type}</h5>
    <br />
    <h6 className="pl-4 mb-5">
      <Highlighter
        searchWords={searchWords.triggerWords}
        autoEscape
        textToHighlight={String(text)}
      />
    </h6>
    <br />
    <br />
  </div>
);

CarePlanText.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default CarePlanText;

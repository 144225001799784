/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import track from 'react-tracking';
import IdleTimer from 'react-idle-timer';

import { getStringReplacement } from '@util/stringReplacements';
import { clearSession, getSessionUser, setSessionUser } from '@util/session';

import {
  USER_ACCESS_ADMIN,
  SUBSCRIPTION_DASHBOARD,
  SUBSCRIPTION_ACUITY,
  USER_ACCESS_GOD,
  SUBSCRIPTION_COHORTS,
  USER_ACCESS_TRACKING_ADMIN,
  USER_ACCESS_HEALTH_SYS_ADMIN,
} from 'Settings/userSettingsPropType';

import { getFeatureFlag } from '@config';
import { renewAuthToken, getTrackingEvent, getPowerBiReportsList } from '@api/api';
import { resetModal } from '@global-state/redux/homepageSlice';
import { getUpdatedTime, resetModalContent } from '@global-state/redux/dashboardSlice';
import { setPatientList } from '@global-state/redux/profileSlice';

import { formatDate, newDate, toUTC } from '@util/dateFunctions';

import { Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import { Icon, Text } from '@intus-ui';
import { clearLocalData } from '@util/localStorage';

import alex from 'assets/alex.png';
import logo from 'assets/logo.svg';
import PowerBINavbarOptions from 'powerBI/PowerBINavbarOptions';

import SessionTimeoutPopup from '../SessionTimeoutPopup';
import SearchBar from './SearchBar';

import '@intus-ui/styles/IntusNavBar.scss';
import '@intus-ui/styles/Utilities.scss';
import '@intus-ui/styles/Settings.scss';
import MenuItemDropdown from '../MenuItemDropdown';
import DashboardMenuOptions from './DashboardMenuOptions';

const timeout = 1000 * 60 * 14; // timeout for popup (ms * sec * min)

/**
 * we want to renew the token before it expires (in 15 mins)
 *  */
const renewAuthTokenTime = 1000 * 60 * 14.5; // milliseconds * seconds * mins. can set to 15 mins

class IntusNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      pageHover: undefined,
      pbiIsActive: false,
    };
    this.idleTimer = React.createRef();
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    const { doGetUpdatedTime } = this.props;
    renewAuthToken();
    this.renewTokenInterval = setInterval(() => renewAuthToken(), renewAuthTokenTime);
    const {
      organization: { id },
    } = getSessionUser() || { organization: { id: undefined } };
    if (id) {
      doGetUpdatedTime(id);
    }
    getPowerBiReportsList().then((response) => {
      if (response.length > 0) {
        this.setState({ pbiIsActive: true });
      }
    });
  }

  componentWillUnmount() {
    if (this.renewTokenInterval) clearTimeout(this.renewTokenInterval);
    this.renewTokenInterval = 0;
  }

  logout() {
    const { history, resetRedux } = this.props;
    clearLocalData();
    resetRedux();
    history.push('/');
  }

  isActive(route) {
    const { location } = this.props;
    return location.pathname.startsWith(route);
  }

  render() {
    const { doResetHomeModal, doResetDashModal, updatedTime, history, doResetPatientList } =
      this.props;

    const session = getSessionUser() || clearSession();
    const access = session?.access;
    const subscriptions = session?.organization?.subscriptions;
    const isGod = access === USER_ACCESS_GOD;
    const isTrackingAdmin = access === USER_ACCESS_TRACKING_ADMIN;
    const isAdmin = access === USER_ACCESS_ADMIN;
    const isDashboardSubscriber = (subscriptions || []).includes(SUBSCRIPTION_DASHBOARD);
    const isAcuitySubscriber = (subscriptions || []).includes(SUBSCRIPTION_ACUITY);
    const isCohortSubscriber = (subscriptions || []).includes(SUBSCRIPTION_COHORTS);
    const { featureIsActive: intusCareOrgChanger } = getFeatureFlag('intus-care-org-changer');
    const { featureIsActive: polypharmacyIsActive } = getFeatureFlag('polypharmacy');
    const { featureIsActive: aiChatisActive } = getFeatureFlag('ai-chat');
    const { featureIsActive: admitsIsActive } = getFeatureFlag('admits');
    const { featureIsActive: initiativesIsActive } = getFeatureFlag('initiatives');

    // Check if user is an HSA and has the feature flag.
    const isHealthSystemAdmin = access === USER_ACCESS_HEALTH_SYS_ADMIN;
    const { featureIsActive: hsaUserManagementFlag } = getFeatureFlag('hsa-user-management');
    const hsaUserManagementisActive = isHealthSystemAdmin && hsaUserManagementFlag;

    const { showModal, pageHover, pbiIsActive } = this.state;

    const closeModal = () => {
      this.setState({ showModal: false });
      this.idleTimer.current.reset();
    };

    const pageTabNames = (internalName, displayName, onClick) => {
      const wrappedOnClick = (event) => {
        // Prevent the browser from doing server-side navigation.
        event.preventDefault();
        onClick(event);
      };
      return (
        <Text
          style={{ padding: '0px 20px', textDecoration: 'none' }}
          id={`tab-${internalName}`}
          color={this.isActive(`/${internalName}`) || pageHover === internalName ? 'navy' : 'link'}
          type="subtitle"
          wrapper="a"
          onMouseOver={() => this.setState({ pageHover: internalName })}
          onMouseOut={() => this.setState({ pageHover: undefined })}
          onClick={wrappedOnClick}
          href={`/${internalName}`}
        >
          {displayName}
        </Text>
      );
    };

    let godSwitchToAdminPage = '/admin';
    if (isCohortSubscriber) godSwitchToAdminPage = '/cohorts';
    if (isAcuitySubscriber) godSwitchToAdminPage = '/patients';
    if (isDashboardSubscriber) godSwitchToAdminPage = '/dashboard';

    return (
      <>
        <Navbar
          className="box-shadow-navbar d-flex justify-content-center py-1 px-3"
          style={{
            backgroundColor: 'white',
            zIndex: 98,
          }}
        >
          <div className="d-flex flex-grow-1">
            <Navbar.Brand className="d-flex flex-row align-items-center h-100 pl-2" href="/home">
              <img alt="IntusCare logo" src={logo} height="30px" />
              {isGod ? <img className="god-himself" src={alex} alt="God" /> : null}
              <div className="d-flex flex-row align-items-center pl-1">
                <h6 className="text-dark font-weight-normal">Intus</h6>
                <h6
                  className="text-primary"
                  style={{ fontWeight: '600', letterSpacing: 'calc(15px * -0.02)' }}
                >
                  {isGod ? 'God' : 'Care'}
                </h6>
                {isGod ? <img className="god-himself" src={alex} alt="God" /> : null}
                {updatedTime && (
                  <h6 className="m-0 pl-3 py-0 text-muted">
                    <b>Updated: </b>
                    {formatDate(updatedTime, ' MM/DD, h:mm a ')}
                  </h6>
                )}
              </div>
            </Navbar.Brand>
          </div>

          <div className="d-flex flex-row align-items-center">
            {!(isGod || isTrackingAdmin) ? (
              <>
                {/* HOME */}
                {pageTabNames('home', 'Home', () => {
                  doResetPatientList();
                  doResetHomeModal();
                  doResetDashModal();
                  getTrackingEvent({
                    time: formatDate(toUTC(newDate())),
                    page: 'Home Page',
                    action: 'Home page loaded',
                  });
                  history.push('/home');
                })}
                {/* ADMITS */}
                {admitsIsActive &&
                  pageTabNames('admits', 'Admits', () => {
                    getTrackingEvent({
                      time: formatDate(toUTC(newDate())),
                      page: 'Admits',
                      action: 'Admits page loaded',
                    });
                    history.push('/admits');
                  })}
                {/* INITIATIVES */}
                {initiativesIsActive &&
                  pageTabNames('initiatives', 'Initiatives', () => {
                    history.push('/initiatives');
                  })}
                {/* PBI */}
                {pbiIsActive && (
                  <div
                    onFocus={() => this.setState({ pageHover: 'reports' })}
                    onMouseOver={() => this.setState({ pageHover: 'reports' })}
                    onMouseOut={() => this.setState({ pageHover: undefined })}
                    onBlur={() => this.setState({ pageHover: undefined })}
                  >
                    <MenuItemDropdown
                      title="Reports"
                      id="tab-reports"
                      isActive={this.isActive('/reports')}
                      isHovered={pageHover === 'reports'}
                      menuItems={PowerBINavbarOptions}
                    />
                  </div>
                )}
                {/* COHORTS */}
                {pageTabNames('cohorts/personal', 'Cohorts', () => {
                  doResetPatientList();
                  getTrackingEvent({
                    time: formatDate(toUTC(newDate())),
                    page: 'Cohorts',
                    action: 'Cohorts page loaded',
                  });
                  history.push('/cohorts/personal');
                })}
                {/* PARTICIPANTS */}
                {pageTabNames(
                  'patients',
                  getStringReplacement('Participant', { plural: true }),
                  () => {
                    doResetPatientList();
                    getTrackingEvent({
                      time: formatDate(toUTC(newDate())),
                      page: 'Participants',
                      action: 'Participants page loaded',
                    });
                    history.push('/patients');
                  }
                )}
                {/* DASHBOARDS */}
                <div
                  onFocus={() => this.setState({ pageHover: 'dashboard' })}
                  onMouseOver={() => this.setState({ pageHover: 'dashboard' })}
                  onMouseOut={() => this.setState({ pageHover: undefined })}
                  onBlur={() => this.setState({ pageHover: undefined })}
                >
                  <MenuItemDropdown
                    title="Dashboards"
                    id="tab-dashboards"
                    isActive={this.isActive('/dashboard')}
                    isHovered={pageHover === 'dashboard'}
                    menuItems={DashboardMenuOptions}
                  />
                </div>
                {/* POLYPHARMACY */}
                {polypharmacyIsActive &&
                  pageTabNames('polypharmacy', 'Medications', () => {
                    getTrackingEvent({
                      time: formatDate(toUTC(newDate())),
                      page: 'Polypharmacy Page',
                      action: 'Polypharmacy page loaded',
                    });
                    history.push('/polypharmacy/first-fills');
                  })}
                {/* USERS */}
                {(isAdmin || hsaUserManagementisActive) && (
                  <>
                    {pageTabNames('caregivers', 'Users', () => {
                      doResetPatientList();
                      getTrackingEvent({
                        time: formatDate(toUTC(newDate())),
                        page: 'Users',
                        action: 'Users page loaded',
                      });
                      history.push('/caregivers');
                    })}
                  </>
                )}
                {/* AI CHAT */}
                {aiChatisActive &&
                  pageTabNames('chat', 'Chat', () => {
                    getTrackingEvent({
                      time: formatDate(toUTC(newDate())),
                      page: 'Chat',
                      action: 'Chat page loaded',
                    });
                    history.push('/chat');
                  })}
                <SearchBar />
                {/* SETTINGS */}
                <div className="dropdown-settings d-flex flex-column">
                  <NavDropdown
                    title={<Icon name="gear" />}
                    id="dropdown-settings"
                    onMouseEnter={() => document.getElementById('dropdown-settings').click()}
                    onMouseLeave={() => document.getElementById('dropdown-settings').click()}
                  >
                    <div className="d-flex flex-column align-items-start">
                      {/* INTUS USERS */}
                      {intusCareOrgChanger && (
                        <>
                          <div className="pt-2">
                            {pageTabNames('changeMyOrg', 'Change Org', () => {
                              history.push('/changeMyOrg');
                            })}
                          </div>
                          <div className="py-2">
                            {pageTabNames('changeMyFlags', 'Change Flags', () => {
                              history.push('/changeMyFlags');
                            })}
                          </div>
                        </>
                      )}
                      <div className="pt-2">
                        {pageTabNames('settings', 'Settings', () => {
                          doResetPatientList();
                          getTrackingEvent({
                            time: formatDate(toUTC(newDate())),
                            page: 'Settings',
                            action: 'Settings page loaded',
                          });
                          history.push('/settings');
                        })}
                      </div>
                      <div className="py-2">
                        {pageTabNames('logout', 'Log Out', () => {
                          getTrackingEvent({
                            time: formatDate(toUTC(newDate())),
                            page: '',
                            action: 'Logout action',
                          });
                          doResetPatientList();
                          this.logout();
                        })}
                      </div>
                    </div>
                  </NavDropdown>
                </div>
              </>
            ) : (
              // Navbar for internal intuscare portal
              <>
                <Nav.Link
                  to={godSwitchToAdminPage}
                  as={Link}
                  disabled={this.isActive('/patients')}
                  className={this.isActive('/patients') ? 'text-secondary' : null}
                >
                  <Button
                    className="text-primary bg-white border-0"
                    onClick={() => {
                      const user = getSessionUser();
                      user.access = 'ADMIN';
                      setSessionUser(user);
                    }}
                  >
                    <h5 className="font-weight-bold">Switch to Admin view</h5>
                  </Button>
                </Nav.Link>
                {isGod ? (
                  <Nav.Link
                    to="/admin"
                    as={Link}
                    disabled={this.isActive('/admin')}
                    className={this.isActive('/admin') ? 'text-secondary' : null}
                  >
                    <h5 className="font-weight-bold">Organizations</h5>
                  </Nav.Link>
                ) : null}
                <Nav.Link
                  to="/userTrack"
                  as={Link}
                  className={this.isActive('/userTrack') ? 'text-secondary' : null}
                >
                  <h5 className="font-weight-bold">Tracking</h5>
                </Nav.Link>
              </>
            )}
          </div>
        </Navbar>

        <IdleTimer
          ref={this.idleTimer}
          onAction={() => this.idleTimer.current.reset()}
          onIdle={() => this.setState({ showModal: true })}
          timeout={timeout}
          events={['mousedown']}
        />
        <SessionTimeoutPopup
          showModal={showModal}
          handleClose={closeModal}
          logout={this.logout}
          expired={() => clearSession()}
        />
      </>
    );
  }
}

IntusNavBar.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  doResetDashModal: PropTypes.func.isRequired,
  doResetHomeModal: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  resetRedux: PropTypes.func.isRequired,
  tracking: PropTypes.shape({
    // function to call to dispatch tracking events
    trackEvent: PropTypes.func,
    // function to call to grab contextual tracking data
    getTrackingData: PropTypes.func,
  }).isRequired,
  doGetUpdatedTime: PropTypes.func.isRequired,
  doResetPatientList: PropTypes.func.isRequired,
  updatedTime: PropTypes.string,
};
IntusNavBar.defaultProps = {
  updatedTime: formatDate(newDate(), 'MM/DD, h:mm a '),
};

IntusNavBar.defaultProps = {
  updatedTime: null,
};

const mapState = (state) => ({
  updatedTime: state.dashboard.updatedTime,
});

const mapDispatch = (dispatch) => ({
  resetRedux: () => dispatch({ type: 'USER_LOGOUT' }),
  doResetHomeModal: () => dispatch(resetModal()),
  doResetDashModal: () => dispatch(resetModalContent([])),
  doGetUpdatedTime: (organizationId) => dispatch(getUpdatedTime(organizationId)),
  doResetPatientList: () => dispatch(setPatientList(undefined)),
});

export default track({ page: 'navbar' })(withRouter(connect(mapState, mapDispatch)(IntusNavBar)));

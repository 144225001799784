import React, { FC, InputHTMLAttributes } from 'react';
import './style.scss';

type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange: (checked: boolean) => void;
};

const Checkbox: FC<CheckboxProps> = ({ checked, onChange, disabled, ...props }) => {
  const onChangeExt: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) =>
    onChange(e.target.checked);

  return (
    <div className="checkbox-wrapper">
      <input
        className={checked ? 'intusui-checkbox-checked' : ''}
        type="checkbox"
        checked={checked}
        onChange={onChangeExt}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default Checkbox;

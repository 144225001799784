import PropTypes from 'prop-types';
import riskBreakup from '@util/riskGrouping';

export const riskGroupPropType = PropTypes.oneOf([
  riskBreakup.INSUFF.enum,
  riskBreakup.ALL.enum,
  riskBreakup.LOW.enum,
  riskBreakup.MODERATE.enum,
  riskBreakup.HIGH.enum,
  riskBreakup.HIGHEST.enum,
]);

const acuityLevelPropType = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,

  // Score integer and Risk string associated with each acuityLevel model
  acuityScore: PropTypes.number.isRequired,
  diseaseSubscore: PropTypes.number.isRequired,
  neurologicalAndBehavioralSubscore: PropTypes.number.isRequired,
  socialSubscore: PropTypes.number.isRequired,
  externalCostSubscore: PropTypes.number.isRequired,
  coordinationComplexitySubscore: PropTypes.number.isRequired,
  riskGroup: riskGroupPropType,
});

export default acuityLevelPropType;

import { useOutsideClick } from '@intus-ui/hooks';
import { useState } from 'react';
import { createPortal } from 'react-dom';

import { usePopper } from 'react-popper';

/**
 * This component handles positioning of any children displayed in a dropdown.
 *
 * We portal the children to the <body> element to avoid issues with overflow.
 * The dropdown is positioned directly below the anchor element and stays there when the window is scrolled.
 */
export const PopperPortal = ({ anchorRef, onClickOutside, children }) => {
  // It's weird to store a ref in state like this, but we need to trigger a re-render
  // for the usePopper hook to work and useRef does not re-render.
  const [popperElement, setPopperElement] = useState(null);

  // See: https://popper.js.org/react-popper/v2/react-portals/
  const { styles, attributes } = usePopper(anchorRef?.current, popperElement, {
    placement: 'bottom-start',
  });

  useOutsideClick([{ current: popperElement }, anchorRef], onClickOutside);

  // Make sure we have a width for the popper div, otherwise we end up with 0 width children.
  const minWidth = anchorRef?.current?.getBoundingClientRect().width;

  return createPortal(
    <div
      ref={setPopperElement}
      // zIndex must be higher than the modal z-indexes.
      style={{ ...styles.popper, minWidth, zIndex: 2000 }}
      {...attributes.popper}
    >
      {children}
    </div>,
    document.body
  );
};

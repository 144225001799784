/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { postUserToken, updateUserAPI } from '@api/api';
import { getLogger } from '@util/logger';
import { getSessionUser, setSessionToken, setSessionUser } from '@util/session';

import { setShowNotification } from '../../@global-state/redux/notificationSlice';

const log = getLogger('resetPasswordSlice');

const resetPasswordSlice = createSlice({
  name: 'resetPasswordSlice',
  initialState: {
    profile: undefined,
  },
  reducers: {
    setProfile(state, action) {
      state.profile = {
        ...(state.profile || {}),
        ...action.payload,
      };
    },
  },
});

export default resetPasswordSlice.reducer;
export const { setProfile } = resetPasswordSlice.actions;

export const getUserInfo = () => async (dispatch) => {
  const user = getSessionUser();
  dispatch(setProfile(user));
  return user;
};

export const validateToken = (body) => async (dispatch) => {
  try {
    const res = await postUserToken(body);
    if (res.ok) {
      const json = await res.json();
      setSessionToken(json.token);
      delete json.token;
      setSessionUser(json);

      return dispatch(setProfile(json));
    }
    dispatch(
      setShowNotification(
        'Your token has expired. Please contact your IT administrator for a new one.'
      )
    );
    return null;
  } catch (err) {
    log.error(err);
  }
  return null;
};

export const updateUser = (body) => async () => {
  await updateUserAPI(body, body.id);
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { setPage } from '@global-state/redux/welcomeSlice';

import '@intus-ui/styles/WelcomeFlow.scss';

/**
 * This component is for the primary blue buttons used in the welcome flow
 */
const ModalButtons = ({
  noBack,
  onClick,
  skipFunc,
  padding,
  page,
  doSetPage,
  nextText,
  disabled,
}) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {noBack ? (
      <Row
        className={`${padding} w-100 d-flex flex-column h-75 justify-content-start align-items-center pt-3`}
      >
        <Button
          text="Start"
          style={{ width: '8em' }}
          className="text-center mb-5"
          onClick={onClick}
        >
          <div className="d-flex flex-row justify-content-center align-items-center">
            <h4 className="px-4 py-1">{nextText}</h4>
          </div>
        </Button>
      </Row>
    ) : (
      <Row
        className={`${padding} w-100 d-flex flex-row h-100 w-100 justify-content-center align-items-center`}
      >
        <Col
          lg={3}
          className="text-left d-flex flex-row justify-content-center align-items-center mb-4"
        >
          <Button
            text="Back"
            style={{ width: '8em' }}
            className="modal-back-button bg-transparent btn-link text-center mb-5}"
            onClick={() => {
              doSetPage(page - 1);
            }}
          >
            <div className="d-flex flex-row justify-content-between align-items-center">
              <ArrowBackIosIcon />
              <h6 className="pr-3 pl-2 py-1">Back</h6>
            </div>
          </Button>
        </Col>
        <Col lg={skipFunc ? 3 : 6} />
        <Col
          lg={skipFunc ? 6 : 3}
          className="d-flex flex-row justify-content-end align-items-center mb-4"
        >
          {skipFunc && (
            <Button
              className="btn-link bg-transparent border-0 m-0 py-0 px-3"
              text="Skip"
              onClick={skipFunc}
            >
              Skip for Now
            </Button>
          )}
          <Button
            text="Next"
            style={{ width: '8em' }}
            className="text-center"
            onClick={
              onClick ||
              (() => {
                doSetPage(page + 1);
              })
            }
            disabled={disabled}
          >
            <div className="d-flex flex-row justify-content-end align-items-center">
              <h6 className="pl-3 pr-2 py-1">{nextText}</h6>
              <ArrowForwardIosIcon className="small" />
            </div>
          </Button>
        </Col>
      </Row>
    )}
  </>
);

ModalButtons.propTypes = {
  noBack: PropTypes.bool,
  doSetPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  skipFunc: PropTypes.func,
  nextText: PropTypes.string,
  disabled: PropTypes.bool,
};

ModalButtons.defaultProps = {
  noBack: false,
  onClick: undefined,
  padding: '',
  skipFunc: undefined,
  nextText: 'Next',
  disabled: false,
};

const mapState = (state) => ({
  page: state.welcome.page,
});

const mapDispatch = (dispatch) => ({
  doSetPage: (page) => dispatch(setPage(page)),
});

export default connect(mapState, mapDispatch)(ModalButtons);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { Icon, Text, layout } from '@intus-ui';

import patientIncidentPropType from '../patientDetail/patientIncidentPropType';

import '@intus-ui/styles/Profile.scss';

export const createIncident = (item, narrowNotes, badgeToShow) => {
  const {
    incidentType,
    location,
    severity,
    burnDegree,
    infectionType,
    subType,
    activityAtTimeOfFall,
    date,
    time,
  } = item;

  let cardTitle;
  switch (incidentType) {
    case 'MedError':
      cardTitle = 'Medication Error';
      break;
    case 'General':
      cardTitle = 'General Incident';
      break;
    default:
      cardTitle = incidentType;
      break;
  }

  return (
    <div key={JSON.stringify(item)} style={styles.cardContent}>
      <div style={styles.cardHeader}>
        <Text type="subtitle">{cardTitle}</Text>
        {badgeToShow && <img src={badgeToShow.src} alt="badge" />}
      </div>
      <div style={styles.listItem}>
        <Icon name="calendar" style={styles.listIcon} />
        <Text style={styles.listText}>
          {date}
          {time && `, ${time}`}
        </Text>
      </div>
      {!infectionType && (
        <div style={styles.listItem}>
          <Icon name="location" style={styles.listIcon} />
          <Text style={styles.listText}>{location}</Text>
        </div>
      )}
      {subType && (
        <div style={styles.listItem}>
          <Icon name="note" style={styles.listIcon} />
          <Text style={styles.listText}>{subType}</Text>
        </div>
      )}
      {incidentType === 'Burn' && (
        <div style={styles.listItem}>
          <Icon name="burn-degree" style={styles.listIcon} />
          <Text style={styles.listText}>{burnDegree ?? '-'}</Text>
        </div>
      )}
      {(infectionType || severity) && (
        <div style={styles.listItem}>
          <Icon name="harm-type" style={styles.listIcon} />
          <Text style={styles.listText}>{infectionType || severity}</Text>
        </div>
      )}
      {incidentType === 'Fall' && (
        <div>
          <div style={styles.listItem}>
            <Icon name="note" style={styles.listIcon} />
            <Text style={styles.listText}>Notes: </Text>
          </div>
          <div>
            <ExpandableNotes content={activityAtTimeOfFall} narrowNotes={narrowNotes} />
          </div>
        </div>
      )}
    </div>
  );
};

// Expandable notes component that takes in different content.
export function ExpandableNotes(props) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Changes the expanded state of the dropdown depending on user input.
  const toggleIsExpanded = (expanded) => {
    setIsExpanded(expanded);
  };

  // Parsing notes content from the content object.
  const { content } = props;

  // Checks if the dropdown is already expanded or not, and renders accordingly.
  return (
    <div
      role="button"
      tabIndex={0}
      type="button"
      onKeyDown={() => toggleIsExpanded(!isExpanded)}
      onClick={() => toggleIsExpanded(!isExpanded)}
    >
      {isExpanded === false && (
        <div className="bg-light pl-2 pt-1 pb-1 d-flex flex-row justify-content-between">
          <Text className="d-inline-block text-dark text-truncate">{content}</Text>
          <div>
            <div
              role="button"
              tabIndex={0}
              type="button"
              onKeyDown={() => toggleIsExpanded(!isExpanded)}
              onClick={() => toggleIsExpanded(!isExpanded)}
              className="falls-notes-dropdown-button"
            >
              <div className="p-0">
                <ExpandMoreRoundedIcon
                  className="text-secondary p-0"
                  style={{ transform: 'scale(0.8)' }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isExpanded === true && (
        <div className="bg-light pl-2 pt-1 pb-1 d-flex flex-row justify-content-between">
          <Text className="d-inline-block text-dark">{content}</Text>
          <div>
            <div
              role="button"
              tabIndex={0}
              type="button"
              onKeyDown={() => toggleIsExpanded(!isExpanded)}
              onClick={() => toggleIsExpanded(!isExpanded)}
              className="falls-notes-dropdown-button"
            >
              <div className="p-0">
                <ExpandLessRoundedIcon
                  className="text-secondary p-0"
                  style={{ transform: 'scale(0.8)' }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const Incidents = ({ incidents }) => {
  if (!incidents.length)
    return (
      <div
        style={{
          ...layout.centeredContentContainer,
        }}
      >
        <Text color="caption">No Incidents</Text>
      </div>
    );
  return (
    <div
      style={{
        ...layout.container,
        gap: 20,
      }}
    >
      {incidents.map((incident) => createIncident(incident))}
    </div>
  );
};

Incidents.propTypes = {
  incidents: PropTypes.arrayOf(patientIncidentPropType).isRequired,
};

const styles = {
  acuityCard: {
    padding: '10px 15px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    width: '100%',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  listItem: {
    display: 'flex',
    alignItems: 'start',
  },
  listIcon: {
    color: '#97999b',
    width: 22,
    height: 22,
  },
  listText: {
    marginLeft: 10,
    lineHeight: '19.5px',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
};

export default Incidents;

import React, { useState } from 'react';
import RadioButton from '@intus-ui/components/RadioButton/RadioButtonV2';

const RadioGroup = (props) => {
  const { options, disabled, orientation, onChange, selectedOption, ...rest } = props;
  const [selected, setSelected] = useState(selectedOption);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: orientation,
        justifyContent: 'flex-start',
        alignItems: 'start',
        gap: '15px',
      }}
      {...rest}
    >
      {options.map((option, index) => {
        const key = index;
        return (
          <RadioButton
            labelPosition="horizontal"
            onChange={onChange}
            option={option}
            selected={selected}
            key={key}
            disabled={disabled}
            setSelected={setSelected}
            size="large"
          />
        );
      })}
    </div>
  );
};

export default RadioGroup;

import { Card, Text } from '@intus-ui';
import { getAllVariantsForColors } from '@intus-ui/styles/colors';

import { Pie } from 'react-chartjs-2';

const PieChart = ({ title, data, colors = getAllVariantsForColors(['blue','orange','purple'])  }) => {
  const adjustedLabels = data.labels.map((label) => {
    if (label.length > 14) {
      return `${label.slice(0, 14)}...`;
    }
    return label;
  });

  const chartData = {
    labels: adjustedLabels,
    datasets: [
      {
        data: data.datasets.map((dataset) => dataset.data[0]),
        backgroundColor: colors,
      },
    ],
  };
  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      position: 'right',
    },
    layout: {
      padding: {
        top: 5,
      },
    },
  };

  return (
    <Card dropshadow style={{ width: '550px' }}>
      <Text wrapper="div" type="subtitle">
        {title}
      </Text>
      <div style={{ width: '500px', height: '330px' }}>
        <Pie data={chartData} options={chartOptions} />
      </div>
    </Card>
  );
};

export default PieChart;

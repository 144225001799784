// import { acuityOverlay, acuityTooltip, riskTooltip } from '@intus-ui/tooltips';

import { getStringReplacement } from '@util/stringReplacements';

import { ParticipantCohortTags } from '@intus-ui/components/ParticipantList/columns/ParticipantCohortTags';
import { CohortDropdown } from '@intus-ui/components/ParticipantList/filters/CohortDropdown';
import LivingSituationDropdown from '../filters/LivingSituationDropdown';
import RiskBreakupDropdown from '../filters/RiskBreakupDropdown';
import SortIcon from '../../List/SortIcon';

/**
 * A list of all possible fields with their corresponding names, styles, and addOns
 * columnConfig selects specific fields from this to display
 * NOTE: *THIS* is where display order is controlled, not columnConfigs!
 */

export function getParticipantListColumns() {
  return [
    {
      field: 'pinnedIcon',
      name: 'Pin',
      flex: 1,
      align: 'start',
      addOns: [{ type: 'sort', element: <SortIcon key="pinned" id="pinned" /> }],
    },
    {
      field: 'name',
      name: `${getStringReplacement('Participant')} Name`,
      flex: 3,
      bg: 'lightpink',
      align: 'start',
      addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
    },
    {
      field: 'instanceCount',
      name: 'Count',
      flex: 1,
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="instanceCount" id="instanceCount" /> }],
    },
    {
      field: 'age',
      name: 'Age',
      flex: 1,
      bg: 'lightyellow',
      align: 'end',
      paddingRight: '10px', // use this if you need more space between columns than the set gap
      addOns: [{ type: 'sort', element: <SortIcon key="age" id="age" /> }],
    },
    {
      field: 'livingSituation',
      name: 'Living Situation',
      flex: 3,
      bg: 'lightgreen',
      align: 'start',
      addOns: [{ type: 'filter', element: <LivingSituationDropdown key="livingSituation" /> }],
    },
    {
      field: 'acuity',
      name: 'Acuity',
      flex: 1,
      bg: 'lightblue',
      align: 'end',
      addOns: [
        {
          type: 'sort',
          element: <SortIcon key="acuity" id="acuity" />,
        },
        // { type: 'info', element: acuityOverlay(acuityTooltip()) },
      ],
    },
    {
      field: 'scoreGroup',
      name: 'Score Group',
      flex: 3,
      bg: 'green',
      align: 'center',
      addOns: [
        { type: 'filter', element: <RiskBreakupDropdown key="riskBreakdownDropdown" /> },
        // { type: 'info', element: acuityOverlay(riskTooltip()) },
      ],
    },
    {
      field: 'disenrollDate',
      name: 'Disenroll Date',
      flex: 2,
      bg: 'green',
      align: 'start',
      addOns: [{ type: 'sort', element: <SortIcon key="disenrollDate" id="disenrollDate" /> }],
    },
    {
      field: 'disenrollReason',
      name: 'Disenroll Reason',
      flex: 4,
      bg: 'green',
      align: 'start',
      addOns: [{}],
    },
    {
      field: 'cohorts',
      name: 'Cohorts',
      flex: '1 1 450px',
      bg: 'lightpink',
      align: 'start',
      addOns: [{ type: 'filter', element: <CohortDropdown key="cohortFilter" /> }],
      render({ item }) {
        return <ParticipantCohortTags participant={item} />;
      },
    },
    {
      field: 'admissions',
      name: 'ER Visits',
      flex: '1 1 75px',
      bg: 'green',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="admissions" id="admissions" /> }],
    },
    {
      field: 'inpatientAdmissions',
      name: 'Admissions',
      flex: '1 1 75px',
      bg: 'green',
      align: 'end',
      addOns: [
        { type: 'sort', element: <SortIcon key="inpatientAdmissions" id="inpatientAdmissions" /> },
      ],
    },
    {
      field: 'falls',
      name: 'Falls',
      flex: '1 1 75px',
      bg: 'green',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="falls" id="falls" /> }],
    },
    {
      field: 'totalLOS',
      name: 'Length of stay',
      flex: '1 1 75px',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="stays" id="stays" /> }],
    },
    {
      field: 'infections',
      name: 'Infections',
      flex: '1 1 75px',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="infections" id="infections" /> }],
    },
    {
      field: 'medicineErrors',
      name: 'Medicine Errors',
      flex: '1 1 75px',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="medicineErrors" id="medicineErrors" /> }],
    },
    {
      field: 'emergencyroomReoccurrences',
      name: 'ER Reoccurrences within 30 days',
      flex: '1 1 75px',
      align: 'end',
      addOns: [
        {
          type: 'sort',
          element: <SortIcon key="emergencyroomReoccurrences" id="emergencyroomReoccurrences" />,
        },
      ],
    },
    {
      field: 'inpatientReoccurrences',
      name: 'Inpatient Reoccurrences within 30 days',
      flex: '1 1 75px',
      align: 'end',
      addOns: [
        {
          type: 'sort',
          element: <SortIcon key="inpatientReoccurrences" id="inpatientReoccurrences" />,
        },
      ],
    },
    {
      field: 'majorHarmOrDeathFallIncindents',
      name: 'Count of Falls Causing Major Harm/Death',
      flex: '1 1 75px',
      align: 'end',
      addOns: [
        {
          type: 'sort',
          element: (
            <SortIcon key="majorHarmOrDeathFallIncindents" id="majorHarmOrDeathFallIncindents" />
          ),
        },
      ],
    },
    {
      field: 'fallCount',
      name: 'Fall Count',
      flex: '1 1 75px',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="fallCount" id="fallCount" /> }],
    },
    {
      field: 'erVisitCount',
      name: 'ER Visit Count',
      flex: '1 1 75px',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="erVisitCount" id="erVisitCount" /> }],
    },
    {
      field: 'inpatientAdmissionsCount',
      name: 'Inpatient Admission Count',
      flex: '1 1 75px',
      align: 'end',
      addOns: [
        {
          type: 'sort',
          element: <SortIcon key="inpatientAdmissionsCount" id="inpatientAdmissionsCount" />,
        },
      ],
    },
  ];
}

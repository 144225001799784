import { layout } from '@intus-ui';

export const styles: { [key: string]: React.CSSProperties } = {
  container: {
    ...layout.container,
    height: '100%',
    overflow: 'auto',
    width: '100%',
    backgroundColor: '#F6F7FA',
  },
  submenuSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 25px 10px 25px',
    width: '100%',
  },
  filters: { display: 'flex', alignItems: 'center', gap: 20 },
  mainSection: {
    ...layout.rowContainer,
    padding: '10px 25px 15px 25px',
    gap: 20,
    maxWidth: '100%',
  },
  contentSection: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    flexGrow: 'inherit',
    maxWidth: '80%',
  },
  rightContent: {
    ...layout.container,
    height: '100%',
    flex: 2,
  },
  executiveView: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
  widget: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 20,
    border: '1px solid #9D9D9D',
    borderRadius: 10,
    backgroundColor: 'white',
    flex: 1,
  },
  widgetHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  widgetContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  orgView: {
    flex: 1,
    overflow: 'auto',
  },
};

import React, { useState } from 'react';

import { layout, monthsOptions, Text, DateRangeDropdown } from '@intus-ui';

const Header = ({ headerText, timeFilter = true, setDateFilter }) => {
  const [dateRangeLabel, setDateRangeLabel] = useState(monthsOptions['6'].label);

  return (
    <div
      id="contentHeader"
      style={{
        ...layout.centeredContentContainer,
        justifyContent: 'space-between',
        flex: 0,
        zIndex: 100,
      }}
    >
      <Text type="title" color="navy">
        {headerText}
      </Text>
      {timeFilter ? (
        <DateRangeDropdown
          range={[1, 3, 6, 12]}
          value={dateRangeLabel}
          onChange={(val) => {
            if (!val.isRange) {
              setDateRangeLabel(val.label);
              setDateFilter({
                start: val.start,
                end: val.end,
                displayText:
                  val.isRange === true
                    ? `between ${val.label}`
                    : `in the ${val.label.toLowerCase()}`,
              });
            }
            return null;
          }}
          onApplyRange={(val) => {
            setDateRangeLabel(val.label);
            setDateFilter({
              start: val.start,
              end: val.end,
              displayText:
                val.isRange === true ? `between ${val.label}` : `in the ${val.label.toLowerCase()}`,
            });
          }}
          dateRangeDirection="left"
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default Header;

import { post } from '@api/apiMethods';

type CheckForSSOParams = {
  email: string;
};

type CheckForSSOReturn = {
  isSSO: boolean;
  redirectUrl: string;
};

/**
 * Checks if the user is using SSO and returns the redirect URL if they are.
 * Called at the first step of the login flow, after the user enters their email.
 */
export async function checkForSSO(data: CheckForSSOParams) {
  return post<CheckForSSOReturn>('users/check-sso', data, true);
}

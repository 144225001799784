import React from 'react';
import { layout } from '@intus-ui';
import LocationChart from './LocationChart';
import SeverityChart from './SeverityChart';
import ActivityChart from './ActivityChart';
import TimeChart from './TimeChart';
import ChartWrapper from './ChartWrapper';

const FallsChartContainer = ({
  locationData,
  activityData,
  severityData,
  timeData,
  maxValue = 'auto',
}) => {
  return (
    <div id="chartContainer" style={{ ...layout.gridContainer, gap: 20 }}>
      <ChartWrapper id="LocationChart" label="Location">
        <LocationChart id="LocationChart" locationData={locationData} maxValue={maxValue} />
      </ChartWrapper>

      <ChartWrapper id="ActivityChart" label="Activity">
        <ActivityChart id="ActivityChart" activityData={activityData} maxValue={maxValue} />
      </ChartWrapper>

      <ChartWrapper id="SeverityChart" label="Severity">
        <SeverityChart id="SeverityChart" severityData={severityData} maxValue={maxValue} />
      </ChartWrapper>

      <ChartWrapper id="TimeChart" label="Time of Day">
        <TimeChart id="TimeChart" timeData={timeData} maxValue={maxValue} />
      </ChartWrapper>
    </div>
  );
};

export default FallsChartContainer;

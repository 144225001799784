import { Controller } from 'react-hook-form';
import { Input } from '@intus-ui';
import { FormErrorMessage } from '@intus-ui/components/forms/errors/FormErrorMessage';
import { MetricTypeRadioButtons } from 'CustomDashboardV2/MetricLibrary/MetricConfigs/MetricTypeRadioButtons';

export const BreakdownMetricConfiguration = ({ form, metricConfig }) => {
  const { breakdownTypes } = metricConfig;
  const { control, formState, setValue } = form;

  const defaultBreakdownType = breakdownTypes?.[0]?.key;

  return (
    <>
      {breakdownTypes?.length > 0 && (
        <Controller
          name="metricConfig.breakdownType"
          control={control}
          rules={{ required: 'Metric is required' }}
          defaultValue={defaultBreakdownType}
          render={({ field }) => {
            return (
              <Input
                style={{ width: '300px' }}
                options={breakdownTypes.map((item) => item.name)}
                rightIcon="caret-down"
                type="select"
                placeholder="Breakdown by"
                value={
                  breakdownTypes.find((item) => {
                    return item.key === field.value;
                  })?.name
                }
                onChange={(value) =>
                  setValue(
                    'metricConfig.breakdownType',
                    breakdownTypes.find((item) => item.name === value)?.key
                  )
                }
              />
            );
          }}
          clearIcon={null}
        />
      )}

      {metricConfig.metricTypes?.length > 0 && (
        <>
          <Controller
            name="metricConfig.metricType"
            control={control}
            defaultValue="count"
            rules={{ required: 'Metric type is required' }}
            render={({ field }) => {
              return (
                <MetricTypeRadioButtons
                  metricConfig={metricConfig}
                  value={field.value}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <FormErrorMessage error={formState?.errors?.metricConfig?.metricType} />
        </>
      )}
    </>
  );
};

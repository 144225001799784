/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { newDateInFormat, getHourOfDate } from '@util/dateFunctions';

export const formatTimeData = (filteredFalls) => {
  const timeData = {
    '12AM - 6AM': 0,
    '6AM - 12PM': 0,
    '12PM - 6PM': 0,
    '6PM - 12AM': 0,
    Unknown: 0,
  };

  const chartTimeData = [];

  for (let i = 0; i < filteredFalls?.length; i += 1) {
    const time = newDateInFormat(filteredFalls[i].time, 'h:mm A');
    const hour = getHourOfDate(time);

    if (!time) {
      timeData.Unknown += 1;
    } else if (hour >= 0 && hour < 6) {
      timeData['12AM - 6AM'] += 1;
    } else if (hour >= 6 && hour < 12) {
      timeData['6AM - 12PM'] += 1;
    } else if (hour >= 12 && hour < 18) {
      timeData['12PM - 6PM'] += 1;
    } else if (hour >= 18 && hour <= 23) {
      timeData['6PM - 12AM'] += 1;
    } else {
      timeData.Unknown += 1;
    }
  }
  if (timeData.Unknown === 0) {
    delete timeData.Unknown;
  }

  Object.keys(timeData).forEach((key) => {
    chartTimeData.push({
      id: key,
      value: timeData[key],
      property: 'time',
    });
  });

  return chartTimeData;
};

export const formatLocationData = (filteredFalls) => {
  // Location
  const locationData = {};
  const locationDataFlat = {};
  const chartLocationDataFlat = [];
  const chartLocationData = [];

  for (let i = 0; i < filteredFalls?.length; i += 1) {
    // for filtering later
    const primaryLocation = filteredFalls[i].location.split('-')[0].trim();
    if (!(primaryLocation in locationDataFlat)) {
      locationDataFlat[primaryLocation] = 1;
    } else {
      locationDataFlat[primaryLocation] += 1;
    }
  }

  for (const property in locationDataFlat) {
    chartLocationDataFlat.push({
      id: property,
      value: locationDataFlat[property],
      property: 'location',
    });
  }

  for (let i = 0; i < filteredFalls?.length; i += 1) {
    const currentLocation = filteredFalls[i].location.split('-');
    const location = currentLocation[0]?.trim();
    const subLocation = currentLocation[1]?.trim();
    if (!(location in locationData)) {
      locationData[location] = {};
    }

    if (location in locationData && !(subLocation in locationData[location])) {
      locationData[location][subLocation] = 1;
    } else if (location in locationData && subLocation in locationData[location]) {
      locationData[location][subLocation] += 1;
    }
  }

  // Creating array of keys for graph
  const keys = [];
  Object.entries(locationData).forEach(([key]) => {
    const formattedLocations = {};
    Object.entries(locationData[key]).forEach(([k, v]) => {
      if (keys.find((item) => item === k) === undefined) {
        keys.push(k);
      }
      formattedLocations[k] = v;
    });
    formattedLocations.id = key;

    chartLocationData.push({
      ...formattedLocations,
    });
  });
  return [chartLocationData, chartLocationDataFlat, keys];
};

export const formatActivityData = (filteredFalls) => {
  const activityData = {};
  const keys = [];
  const chartActivityDataFlat = [];

  for (let i = 0; i < filteredFalls?.length; i += 1) {
    // activity
    if (filteredFalls[i].notes === null || filteredFalls[i].notes === undefined) {
      if ('Unknown' in activityData) {
        activityData.Unknown += 1;
      } else {
        activityData.Unknown = 1;
      }
    } else if (!(filteredFalls[i].notes in activityData)) {
      activityData[filteredFalls[i].notes] = 1;
    } else {
      activityData[filteredFalls[i].notes] += 1;
    }
  }

  // For sidebar
  for (const property in activityData) {
    const item = {
      id: property,
      value: activityData[property],
      property: 'activity',
    };
    chartActivityDataFlat.push(item);
  }

  // For chart
  const sortedActivityData = chartActivityDataFlat.sort((a, b) => b.value - a.value);
  for (let i = 0; i < sortedActivityData.length; i += 1) {
    keys.push(sortedActivityData[i].id);
  }

  // MOVE ITEMS WITH 'OTHER' ACTIVITY TO END OF SORTED ARRAY
  const index = sortedActivityData.findIndex((obj) => obj.id?.toLowerCase() === 'other');
  if (index !== -1) {
    const movedObject = sortedActivityData.splice(index, 1)[0];
    sortedActivityData.push(movedObject);
  }
  const chartActivityDataFormatted = [sortedActivityData.slice(0, 3), sortedActivityData.slice(3)];

  const topThree = chartActivityDataFormatted[0];
  const topThreeFormatted = [];
  for (let i = 0; i < topThree.length; i += 1) {
    const property = topThree[i]?.id;
    const item = { id: property };
    item[property] = topThree[i]?.value;
    topThreeFormatted.push(item);
  }
  if (chartActivityDataFormatted[1].length) {
    const otherActivityData = { id: 'Other' };
    const otherActivityDataFormatted = chartActivityDataFormatted[1];
    for (let i = 0; i < otherActivityDataFormatted.length; i += 1) {
      otherActivityData[otherActivityDataFormatted[i].id] = otherActivityDataFormatted[i].value;
    }
    topThreeFormatted.push(otherActivityData);
  }
  return [topThreeFormatted, keys, chartActivityDataFlat];
};

export const formatSeverityData = (filteredFalls) => {
  const chartSeverityData1 = [
    {
      id: 'No Harm',
      value: 0,
      property: 'severity',
      order: 0,
    },
    {
      id: 'Minor Harm',
      value: 0,
      property: 'severity',
      order: 1,
    },
    {
      id: 'Moderate Harm',
      value: 0,
      property: 'severity',
      order: 2,
    },
    {
      id: 'Major Harm',
      value: 0,
      property: 'severity',
      order: 3,
    },
    {
      id: 'Unknown',
      value: 0,
      property: 'severity',
      order: 4,
    },
  ];

  for (let i = 0; i < filteredFalls?.length; i += 1) {
    // if severity (harmType) is null, add 1 to Unkown field
    if (!filteredFalls[i].harmType) {
      const unknownObject = chartSeverityData1.find((item) => item.id === 'Unknown');
      if (unknownObject) unknownObject.value += 1;
      // if there is a severity, update array accordingly
    } else {
      const objectToUpdate = chartSeverityData1.find(
        (item) => item.id === filteredFalls[i].harmType
      );
      if (objectToUpdate) objectToUpdate.value += 1;
    }
  }

  // If 'Unknown' value is zero, remove it from the array
  if (chartSeverityData1.find((item) => item.id === 'Unknown').value === 0)
    chartSeverityData1.pop();

  return chartSeverityData1;
};

const findValueMax = (data) => {
  let max = 0;
  let maxDataPoint = {};
  let secondMax = 0;
  let secondMaxDataPoint = {};
  for (let i = 0; i < data.length; i += 1) {
    if (data[i].value > max) {
      secondMax = max;
      max = data[i].value;
      maxDataPoint = data[i];
    } else if (data[i].value <= max && data[i].value > secondMax) {
      secondMax = data[i].value;
      secondMaxDataPoint = data[i];
    }
  }
  const allMax = { max, maxDataPoint, secondMax, secondMaxDataPoint };
  return allMax;
};

export const getSortedTrendData = (locationData, activityData, severityData, timeData) => {
  let locationDataMax;
  const allTrendData = [];
  let activityDataMax;
  let severityDataMax;
  let timeDataMax;

  if (locationData !== undefined) {
    const locationDataMaxItems = findValueMax(locationData);
    if (locationDataMaxItems.max - locationDataMaxItems.secondMax > 1) {
      locationDataMax = locationDataMaxItems.maxDataPoint;
      allTrendData.push(locationDataMax);
    }
  }
  if (activityData !== undefined) {
    const activityDataMaxItems = findValueMax(activityData);
    if (activityDataMaxItems.max - activityDataMaxItems.secondMax > 1) {
      activityDataMax = activityDataMaxItems.maxDataPoint;
      allTrendData.push(activityDataMax);
    }
  }

  if (severityData !== undefined) {
    const severityDataMaxItems = findValueMax(severityData);
    if (severityDataMaxItems.max - severityDataMaxItems.secondMax > 1) {
      severityDataMax = severityDataMaxItems.maxDataPoint;
      allTrendData.push(severityDataMax);
    }
  }

  if (timeData !== undefined) {
    const timeDataMaxItems = findValueMax(timeData);
    if (timeDataMaxItems.max - timeDataMaxItems.secondMax > 1) {
      timeDataMax = timeDataMaxItems.maxDataPoint;
      allTrendData.push(timeDataMax);
    }
  }
  const sortedData = allTrendData?.sort((a, b) => b.value - a.value);
  return sortedData;
};

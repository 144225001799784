import React from 'react';
import PropTypes from 'prop-types';

const PercentageRow = ({ isDashboard, isHalf, children, style }) => (
  <div
    className={`d-flex flex-grow-1 ${isDashboard ? 'ignore-content' : ''} ${
      isHalf ? 'h-50' : ''
    } overflow-y-hidden`}
    style={style}
  >
    {children}
  </div>
);

PercentageRow.propTypes = {
  isDashboard: PropTypes.bool,
  isHalf: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

PercentageRow.defaultProps = {
  isDashboard: false,
  isHalf: true,
};

export default PercentageRow;

/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { formatDate, isSameOrAfterDate, newDate, subtractDate } from '@util/dateFunctions';
import { moneyAbbreviate, moneyWithCommas } from '@util/utilFunctions';

import { Col, DropdownButton, Dropdown } from 'react-bootstrap';
import { Chip } from '@nivo/tooltip';
import IntusLineGraph from '@intus-ui/components/graphs/IntusLineChart';

const utilMap = {
  moneyAbbreviation: (val) => moneyAbbreviate(val, 2),
  money: (val) => moneyWithCommas(val),
  // 'Past 6 Months': { filterTitle: 'every 1 month', axisBottomFormat: '%b \'%y', months: 6 },
  '12 months': { filterTitle: 'every 1 month', axisBottomFormat: "%b '%y", months: 12 },
  'All Time': { filterTitle: 'every 1 year', axisBottomFormat: '%Y', months: undefined },
};

const ModalLineGraph = ({ data, onClick }) => {
  const [timeFilter, setTimeFilter] = useState('12 months');

  const timeFilterButton = (
    <span key="line-graph-time-filter">
      <DropdownButton
        size="sm"
        id="dropdown-button-line-graph-time"
        title={timeFilter}
        variant="outline-primary"
        onClick={(event) =>
          event.target.id === 'dropdown-button-line-graph-time' || event.target.id === ''
            ? null
            : setTimeFilter(event.target.id)
        }
        bsPrefix="m-0 btn btn-outline-primary"
      >
        {/* <Dropdown.Item id="Past 6 Months">Past 6 Months</Dropdown.Item> */}
        <Dropdown.Item id="12 months">12 months</Dropdown.Item>
        <Dropdown.Item id="All Time">All Time</Dropdown.Item>
      </DropdownButton>
    </span>
  );

  const filterData = () => {
    const fitleredData = [];
    data.data.forEach((item) => {
      const filteredItem = {};
      filteredItem.id = item.id;
      filteredItem.data = item.data.filter((point) => {
        if (utilMap[timeFilter].months) {
          return (
            // filters a point out if it is not in the past utilMap[timeFilter].months months
            isSameOrAfterDate(
              point.x,
              subtractDate(newDate(), utilMap[timeFilter].months, 'month'),
              'month'
            )
          );
        }
        return point;
      });

      fitleredData.push(filteredItem);
    });
    return fitleredData;
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {data ? (
        <Col className="w-100 h-100 pb-5 px-2">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="text-dark">{data.title}</h6>
            {timeFilterButton}
          </div>

          <IntusLineGraph
            xTitle={data.xTitle}
            yTitle={data.yTitle}
            data={filterData()}
            yFormat={utilMap[data.yFormat]}
            margin={{
              top: 10,
              right: 40,
              bottom: 80,
              left: 80,
            }}
            axisCustomization={{
              xTickValues: utilMap[timeFilter].filterTitle,
              xLegendOffset: 60,
              yTickValues: 5,
              yLegendOffset: -70,
              axisLeftFormat: utilMap[data.axisLeftFormat],
              axisBottomFormat: utilMap[timeFilter].axisBottomFormat,
            }}
            maxScore={data.max}
            minScore={data.min}
            onClick={
              onClick
                ? (point) => {
                    onClick(point);
                  }
                : undefined
            }
            sliceTooltip={
              data.sliceTooltip
                ? ({ slice }) => (
                    <div className="px-3 py-2 rounded-lg box-shadow-on-gray bg-transparent-90">
                      <strong>{formatDate(slice.points[0].data.x, 'MMMM DD, YYYY')}</strong>
                      <hr className="m-0" />
                      {slice.points.map((point) => (
                        <div key={point.id} className="pt-1">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-start align-items-center pr-2">
                              <Chip size={12} color={point.serieColor} />
                              <span className="pl-2">{point.serieId}</span>
                            </div>
                            <strong>{point.data.yFormatted}</strong>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                : null
            }
          />
        </Col>
      ) : null}
    </>
  );
};
ModalLineGraph.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    xTitle: PropTypes.string,
    yTitle: PropTypes.string,
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    data: PropTypes.arrayOf(PropTypes.object),
    onClickData: PropTypes.instanceOf(Object),
    sliceTooltip: PropTypes.bool,
    yFormat: PropTypes.string,
    axisLeftFormat: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

ModalLineGraph.defaultProps = {
  data: [],
  onClick: undefined,
};

export default ModalLineGraph;

/* eslint-disable prefer-arrow-callback */
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import {
  setPasswordReset,
  setEditingCaregiver,
  updateUserPassword,
} from '@global-state/redux/caregiverListSlice';

import { Form, Col } from 'react-bootstrap';
import SubmitButton from '@intus-ui/components/SubmitButton';
import FormikFormControl from '@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';

import caregiverPropType from '../caregiverListCaregiverPropType';

const schema = Yup.object().shape({
  newpass1: Yup.string()
    .required('New password must be at least 9 characters')
    .min(9, 'New password must be at least 9 characters'),
  newpass2: Yup.string()
    .oneOf([Yup.ref('newpass1'), null], "Passwords don't match")
    .required('Confirm Password is required'),
});

const PasswordResetModal = ({ selectedCaregiver, doUpdateUserPassword }) => (
  <Formik
    initialValues={{ ...selectedCaregiver }}
    validateOnChange
    onSubmit={(values, { setErrors, setTouched }) => {
      const includedFields = {
        password: values.newpass1,
      };
      setErrors({});
      setTouched({});
      doUpdateUserPassword({ ...selectedCaregiver, ...includedFields });
    }}
    validationSchema={schema}
  >
    {({ handleSubmit, isSubmitting, errors, touched }) => (
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <FormikFormControl
            as={Col}
            md="12"
            label="New Password"
            type="password"
            name="newpass1"
            placeholder=""
          />
        </Form.Row>
        <Form.Row>
          <FormikFormControl
            as={Col}
            md="12"
            label="Confirm New Password"
            type="password"
            name="newpass2"
            placeholder=""
          />
        </Form.Row>
        <Form.Row>
          <Col className="pt-3">
            <SubmitButton
              text="Manually Reset Password"
              isSubmitting={isSubmitting}
              formNotComplete={
                Object.values(errors).length > 0 || Object.values(touched).length === 0
              }
              className="my-0 w-100"
            />
          </Col>
        </Form.Row>
      </Form>
    )}
  </Formik>
);

PasswordResetModal.propTypes = {
  selectedCaregiver: caregiverPropType,
  doUpdateUserPassword: PropTypes.func.isRequired,
};

PasswordResetModal.defaultProps = {
  selectedCaregiver: undefined,
};

const mapState = (state) => ({
  show: state.caregiverList.passwordReset,
  selectedCaregiver: state.caregiverList.selectedCaregiver,
  passwordReset: state.caregiverList.passwordReset,
});

const mapDispatch = (dispatch) => ({
  goToSetting: () => {
    dispatch(setEditingCaregiver(true));
    dispatch(setPasswordReset(false));
  },
  doUpdateUserPassword: (body) => {
    dispatch(setEditingCaregiver(false));
    dispatch(updateUserPassword(body));
  },
});

export default connect(mapState, mapDispatch)(PasswordResetModal);

import React from 'react';
import Icon from '../Icon';
import Text from '../Text';

const RoundButton = ({ text, className, leftIcon, rightIcon, style, color, type, ...rest }) => {
  return (
    <div
      className={`box-shadow-on-gray ${className}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#e3ecff',
        color: '#062d8f',
        padding: 10,
        borderRadius: 30,
        ...style,
      }}
      {...rest}
    >
      {leftIcon && <Icon className="ml-2" name={leftIcon} />}
      <Text color={color} className="ml-3 mr-3" type={type || 'subtitle'}>
        {text}
      </Text>
      {rightIcon && <Icon className="mr-2" name={rightIcon} />}
    </div>
  );
};

export default RoundButton;

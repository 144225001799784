import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { addDate, formatDate } from '@util/dateFunctions';
import { logEvent } from '@util/userTracking';

import {
  Text,
  OrgFilter,
  SidebarwithTabs,
  layout,
  MonthPicker,
  Input,
  SpinnerOrError,
} from '@intus-ui';

import ContentSection from './ContentSection';
import AdmitsTab from './Sidebar/AdmitsTab';
import AnalyticsTab from './Sidebar/AnalyticsTab';

import { Provider, useUtilizationContext } from './Context';

import { styles } from './styles';

const UtilizationsContent = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const {
    data,
    loading,
    error,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    admitsType,
    setAdmitsType,
    admitTypesList,
    isHealthSystemAdmin,
    admitTypeMap,
  } = useUtilizationContext();

  const handleMenuItemClick = (item: string) => {
    logEvent('Utilization: Changed Admits Type', { admitsType: item });
    const admitType = admitTypeMap[item];
    setAdmitsType(admitType);
    history.push(`${path}/${admitType}`);
  };

  const handleChangeStartDate = (date: Date) => {
    logEvent('Utilization: Changed Start Date', { date });
    setStartDate(date);
  };

  const handleChangeEndDate = (date: Date) => {
    logEvent('Utilization: Changed End Date', { date });
    setEndDate(date);
  };

  // LISTEN FOR URL CHANGES
  useEffect(() => {
    const pathArray = history.location.pathname.split('utilization/');
    const activeTab = pathArray[pathArray.length - 1];

    if (admitTypesList.includes(activeTab)) {
      setAdmitsType(activeTab);
      history.push(`${path}/${activeTab}`);
    } else {
      setAdmitsType(admitsType);
      history.push(`${path}/${admitsType}`);
    }
  }, [history, path, admitTypesList, admitsType, setAdmitsType]);

  const utilizationsTabs = [
    {
      tab: 'Analytics',
      content: <AnalyticsTab data={data} />,
    },
    {
      tab: 'Admits',
      content: <AdmitsTab admits={data?.admits} />,
    },
  ];

  // Set max date for end date picker to a month in the future to prevent current month selection bug.
  const maxDate = formatDate(addDate(new Date(), 1, 'month'), 'YYYY-MM-DD');

  function getTypeByValue(value: string) {
    return Object.keys(admitTypeMap).find((key) => admitTypeMap[key] === value);
  }

  return (
    <div style={styles.container}>
      <div style={styles.submenuSection}>
        <Text type="title">{admitsType}</Text>
        <div style={styles.filters}>
          <Input
            id="typeSelector"
            type="select"
            rightIcon="caret-down"
            name="typeSelector"
            options={Object.keys(admitTypeMap)}
            value={getTypeByValue(admitsType)}
            onChange={handleMenuItemClick}
            disabled={false}
          />
          {isHealthSystemAdmin && <OrgFilter disabled={false} />}
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <MonthPicker
              dateValue={startDate}
              minDate={new Date('2010-03-01')}
              maxDate={endDate}
              onChange={handleChangeStartDate}
            />
            <Text>to</Text>
            <MonthPicker
              dateValue={endDate}
              minDate={startDate}
              maxDate={new Date(maxDate)}
              onChange={handleChangeEndDate}
            />
          </div>
        </div>
      </div>
      <div style={styles.mainSection}>
        {loading || error || !data ? (
          <div style={{ width: '100%', height: '100%' }}>
            <SpinnerOrError error={error && 'An Error occured'} />
          </div>
        ) : (
          <>
            <ContentSection path={path} utilizationData={data} />
            <div style={{ width: 350 }}>
              <SidebarwithTabs
                items={utilizationsTabs}
                defaultTabIndex={0}
                contentStyles={{ ...layout.container, flex: 1, height: '100%', padding: 0 }}
                onClickTab={(tab: string) => logEvent(`Utilization: Clicked on "${tab}" tab`)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const UtilizationsTracker = () => {
  return (
    <Provider>
      <UtilizationsContent />
    </Provider>
  );
};

export default UtilizationsTracker;

import { layout } from '@intus-ui';
import '@intus-ui/styles/Utilities.scss';
import { getSessionUser } from '@util/session';
import CohortWidget from './CohortWidget';

const CohortMetrics = ({ cohort }) => {
  const user = getSessionUser();
  // Use RATE_FORMAT org setting for clients who want other defaults than per100mm, otherwise default to per100mm
  const mmFormat = user?.overrides?.rateFormat?.format || 'per100mm';
  return (
    <div style={styles.graphGrid}>
      <div style={{ ...styles.row }}>
        <CohortWidget
          metric={{
            metricId: 'inpatient-admissions',
            metricConfig: {
              metricType: mmFormat,
              showChart: true,
            },
            modal: {
              component: 'StatFocusView',
            },
          }}
          cohort={cohort}
        />
        <CohortWidget
          metric={{
            metricId: 'inpatient-admissions-total-length-of-stay',
            metricConfig: {
              metricType: 'count',
              showChart: true,
            },
            modal: {
              component: 'StatFocusView',
            },
          }}
          cohort={cohort}
        />
      </div>
      <div style={{ ...styles.row }}>
        <CohortWidget
          metric={{
            metricId: 'falls',
            metricConfig: {
              metricType: mmFormat,
              showChart: true,
            },
            modal: {
              component: 'StatFocusView',
            },
          }}
          cohort={cohort}
        />
        <CohortWidget
          metric={{
            metricId: 'er-visits',
            metricConfig: {
              metricType: mmFormat,
              showChart: true,
            },
            modal: {
              component: 'StatFocusView',
            },
          }}
          cohort={cohort}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    ...layout.container,
    gap: 20,
  },
  graphGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: 10,
    flex: 1,
  },
  subHeader: {
    padding: '0px 10px 5px 10px',
    borderBottom: '1px solid black',
  },
  row: {
    display: 'flex',
    flex: 1,
    gap: 20,
    justifyContent: 'center',
    minHeight: '300px',
  },
};

export default CohortMetrics;

import { IICDCode } from '@api/patients/getCurrentConditions';
import { List, Text } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { IListColumnConfig, IListFormat } from '@intus-ui/components/List/types/IListFormat';
import { isEmpty } from 'lodash';
import React, { FC } from 'react';

type ICDCodeListProps = {
  icdCodes: IICDCode[];
};

export const ICDCodeList: FC<ICDCodeListProps> = ({ icdCodes }) => {
  return (
    <List
      clickable={false}
      listStyle="striped"
      data={icdCodes}
      format={format}
      columnConfig={columnConfig}
      defaultSort={{ field: 'icdCodeDescription', direction: 'asc' }}
    />
  );
};

const format: IListFormat<IICDCode>[] = [
  {
    field: 'icdCodeDescription',
    name: 'ICD-10 Description',
    flex: '1 1 auto',
    render: ({ item }) => {
      let description = item.icdCodeDescription;

      if (isEmpty(description)) {
        description = 'Invalid/nonspecific ICD-10 code';
      }
      return <Text style={{ whiteSpace: 'normal' }}>{description}</Text>;
    },
    addOns: [
      {
        type: 'sort',
        element: <SortIcon id="icdCodeDescription" key="icdCodeDescription" />,
      },
    ],
  },
  {
    field: 'icdCode',
    name: 'ICD-10 Code',
    flex: '0 0 150px',
    addOns: [
      {
        type: 'sort',
        element: <SortIcon id="icdCode" key="icdCode" />,
      },
    ],
  },
];

const columnConfig: IListColumnConfig<IICDCode> = {
  columns: ['icdCodeDescription', 'icdCode'],
};

import React, { createContext, useState, useMemo, useContext } from 'react';

export const ListContext = createContext(undefined);

export const ListProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sorting, setSorting] = useState({ field: undefined, direction: 'none' });

  // filters = [ {type: '', value: ''}, { type: '', value: ''} ]
  const [filters, setFilters] = useState([]);

  // filterOptions = {filterName: [array of options], filterName: [array of options]}
  const [filterOptions, setFilterOptions] = useState({});
  const [columns, setColumns] = useState([]);

  // cols is an array
  const replaceColumns = (cols) => {
    setColumns(cols);
  };

  const addFilter = (filterToAdd) => {
    const { type, value } = filterToAdd;
    // see if current filter exists in the filters list
    const filterToRemove = filters.find((filter) => filter?.type === type);
    let newFilters;
    // if filter doesn't exist add filterToAdd to filters list
    if (!filterToRemove) {
      newFilters = [...filters, { type, value }];

      // if filter exists, remove it then add filterToAdd to filters list
    } else if (filterToRemove) {
      const foundIndex = filters.findIndex((filter) => filter.type === type);
      filters?.splice(foundIndex, 1);
      newFilters = [...filters, { type, value }];
    }

    // set context variable with the new filters
    setFilters(newFilters);
  };

  const resetFilters = () => {
    setFilters([]);
  };

  const listContext = useMemo(() => {
    return {
      searchTerm,
      setSearchTerm,
      sorting,
      setSorting,
      filters,
      setFilters,
      addFilter,
      filterOptions,
      setFilterOptions,
      columns,
      replaceColumns,
      resetFilters,
    };
  });

  return <ListContext.Provider value={listContext}>{children}</ListContext.Provider>;
};

export const useListContext = () => {
  return useContext(ListContext);
};

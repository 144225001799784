import PropTypes from 'prop-types';

const caregiverDetailCaregiverPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
});

export default caregiverDetailCaregiverPropType;

import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import Chart from 'chart.js/auto';

import { logEvent } from '@util/userTracking';

import {
  adjustColorBrightness,
  generateHighlightColorArrayAtIndex,
} from '@intus-ui/components/Widget/components/chartConfig';
import { defaultChartColors } from '@intus-ui/styles/SecondaryColors';
import { useReactChartTooltipV2 } from '@intus-ui/components/graphs/useReactChartTooltipV2';

import BarChartTooltip from './ExecutiveViewChartTooltip';

Chart.register(annotationPlugin);

const ExecutiveViewChart = ({
  labels,
  dataPoints,
  targets,
  xAxisLabel,
  chartColor,
  detailViewMode,
  top5PrincipalDxData,
  onClickBar,
}: {
  labels: string[];
  dataPoints: number[];
  targets: any[];
  xAxisLabel: string;
  chartColor: string;
  detailViewMode?: boolean;
  top5PrincipalDxData?: [] | undefined;
  onClickBar?: (label: string | null) => void;
}) => {
  type ChartDataConfig = {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string | string[];
      borderColor: string;
      borderWidth: number;
    }[];
  };

  const defaultDataConfig: ChartDataConfig = {
    labels,
    datasets: [
      {
        label: xAxisLabel,
        data: dataPoints,
        backgroundColor: adjustColorBrightness(
          defaultChartColors[chartColor as keyof typeof defaultChartColors],
          0.5
        ),
        borderColor: adjustColorBrightness(
          defaultChartColors[chartColor as keyof typeof defaultChartColors],
          0.5
        ),
        borderWidth: 1,
      },
    ],
  };

  const [chartDataConfig, setChartDataConfig] = useState(defaultDataConfig);

  const onClickBarExt = (_event: any, elements: any) => {
    if (!onClickBar) return;

    const [firstElement] = elements || [];

    if (firstElement) {
      const { datasetIndex, index } = firstElement;
      const label = chartDataConfig.labels[index];

      // Update chart data for highlight
      const newChartData = {
        ...chartDataConfig,
        datasets: chartDataConfig.datasets.map((dataset, i) => ({
          ...dataset,
          backgroundColor:
            i === datasetIndex
              ? generateHighlightColorArrayAtIndex(chartColor, dataset.data.length, index)
              : dataset.backgroundColor,
        })),
      };

      logEvent('Utilization: Executive View Bar Clicked', { label });
      onClickBar(label);
      setChartDataConfig(newChartData);
    } else {
      // Reset chart data
      const resetChartData = { ...defaultDataConfig };
      setChartDataConfig({
        ...resetChartData,
        datasets: resetChartData.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: Array(dataset.data.length).fill(
            adjustColorBrightness(
              defaultChartColors[chartColor as keyof typeof defaultChartColors],
              0.5
            )
          ),
        })),
      });
      onClickBar(null);
    }
  };

  // Create annotations for each target line
  const targetAnnotations: AnnotationOptions<'box'>[] = targets.map(
    (target: any, index: number) => ({
      id: `targetLine-${index}`,
      type: 'box',
      xMin: target,
      xMax: target, // Adjust this to control the width of the box
      yMin: index - 0.4, // Adjust to align with the bar
      yMax: index + 0.4,
      borderColor: defaultChartColors.fadedSkyBlue,
      borderWidth: target > 0 ? 4 : 0,
      label: {
        content: `Target ${target}`,
        enabled: true,
        position: 'end',
      },
    })
  );

  const { tooltipOptions, tooltipComponent } = useReactChartTooltipV2(
    (dataIndex) => (
      <BarChartTooltip
        dataIndex={dataIndex}
        targets={targets}
        chartColor={chartColor}
        chartDataConfig={chartDataConfig}
        top5PrincipalDxData={top5PrincipalDxData}
      />
    ),
    true
  );

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    responsive: true,
    onHover(event, element) {
      if (element.length && detailViewMode) {
        if (event.native && event.native.target) {
          (event.native.target as HTMLElement).style.cursor = 'pointer';
        }
      } else {
        if (event.native && event.native.target) {
          (event.native.target as HTMLElement).style.cursor = 'default';
        }
      }
    },
    onClick: onClickBarExt,
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: xAxisLabel,
        },
        position: 'top',
        ticks: {
          callback(tick) {
            if (tick.toString().indexOf('.') !== -1) return null;
            return tick;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        ...tooltipOptions,
      },
      annotation: {
        annotations: {
          ...targetAnnotations,
        },
      },
    },
  };

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number } | null>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const rect = container.getBoundingClientRect();
      setDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={containerRef}>
      {tooltipComponent}
      {detailViewMode ? (
        dimensions && (
          <Bar
            width={dimensions.width}
            height={dimensions.height}
            data={chartDataConfig}
            options={options}
          />
        )
      ) : (
        <Bar data={chartDataConfig} options={options} />
      )}
    </div>
  );
};

export default ExecutiveViewChart;

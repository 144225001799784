import { newDate } from '@util/dateFunctions';
import { find, orderBy, sortBy } from 'lodash';

const matchField = (item, type, value) => {
  return item[type]?.toLowerCase() === value?.toLowerCase() || value?.toLowerCase() === 'all';
};

// Sorting by field and direction of the current sorting state
export const sortData = (data, sorting, format) => {
  const { field, direction } = sorting;
  let orderedData;

  // check for a sorting type in the column config
  const sortType = find(format, { field: sorting.field })?.sortType;

  // convert back to date object so sorting works
  if (sortType === 'date' || field?.toLowerCase().includes('date')) {
    orderedData = orderBy(
      data,
      [
        (item) => {
          return newDate(item[field]);
        },
      ],
      direction
    );
  } else {
    // order data by sorting field, ignore casing if item[field] is string
    orderedData = orderBy(
      data,
      [
        (item) => {
          if (typeof item[field] === 'string') {
            return item[field].toLowerCase();
          }
          return item[field];
        },
      ],
      direction
    );
  }

  // place null values at the end of the list always
  return sortBy(orderedData, [
    (d) => {
      return d[field] === null || d[field] === undefined ? 1 : 0;
    },
  ]);
};

// Filtering by selected filters, then by search term
export const filterData = (filterConfigs) => {
  const { data, searchTerm, searchField, filters } = filterConfigs;
  const itemsMatchingAllFilters = data?.filter((item) => {
    // used for checking all filters
    let matchesFilters = false;
    const matchesFiltersArray = [];

    for (let i = 0; i < filters.length; i += 1) {
      // value can either be a comparison function or a string
      // if it's a string, treat it as shorthand for a basic string comparison func
      const { type, value } = filters[i];
      const comparisonFunction =
        typeof value === 'function' ? () => value(item) : () => matchField(item, type, value);

      matchesFiltersArray.push(comparisonFunction());
    }

    // an item should only be shown if their properties match all the filters
    if (!matchesFiltersArray.includes(false)) matchesFilters = true;
    if (matchesFiltersArray.length === 0) matchesFilters = true;
    // return the item if they match the filters given
    return matchesFilters;
  });

  const bySearchTerm = (item) => {
    // returns a bool if a item's name includes the search term
    if (item[searchField] == null) return true;
    return item[searchField]?.toLowerCase().includes(searchTerm?.trim().toLowerCase());
  };

  return itemsMatchingAllFilters?.filter(bySearchTerm);
};

// renders the addOns for a given field in a format
export const renderAddOns = (headerNode) => {
  if (headerNode.addOns) {
    return headerNode.addOns.map((addOn) => {
      return addOn.element;
    });
  }
  return null;
};

export const getTextProperties = (field, islowlightItem) => {
  const defaultTextProperties = { type: 'body' };
  let textProperties = field?.textProperties ? field?.textProperties : defaultTextProperties;
  if (islowlightItem) textProperties = { ...textProperties, color: 'caption' };
  return textProperties;
};

/** @type {Record<string, import('react').CSSProperties>} */
export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    minHeight: '100%',
    backgroundColor: '#f5f5f5',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    padding: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  gridContainer: {
    width: '100%',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 15,
  },
  gridRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    gap: 10,
  },
};

import { useCallback, useState } from 'react';

/**
 * A hook to run an API call that returns a file and then download it in the client.
 * @returns {{file: any, loading: boolean, error: Error | null, runQuery: Function, statusCode: number | null}}
 */
export function useDownload(fileName, queryFunction, options) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusCode, setStatusCode] = useState(null);

  const runQuery = useCallback(
    async (...parameters) => {
      setError(null);
      setLoading(true);
      setStatusCode(null);

      try {
        const response = await queryFunction(...parameters);

        setStatusCode(response.status);

        if (!response.ok) {
          const errorJson = await response.json();
          setLoading(false);
          const fetchError = new Error(errorJson?.message ?? 'Unknown error');
          setError(fetchError);
          if (options?.onError != null) {
            options.onError(fetchError);
          }

          return { file: null, statusCode: response.status, error: fetchError };
        }

        const file = await response.blob();
        setError(null);
        setLoading(false);

        if (options?.onSuccess != null) {
          options.onSuccess(file);
        }

        downloadFileInBrowser(file, fileName);

        return { file, statusCode: response.status, error: null };
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        setLoading(false);
        setError(err);

        if (options?.onError != null) {
          options.onError(err);
        }

        return { file: null, error: err };
      }
    },
    [fileName, options, queryFunction]
  );

  return {
    loading,
    error,
    runQuery,
    statusCode,
  };
}

function downloadFileInBrowser(blob, fileName) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  // the filename, this can't be sent from the server.
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}

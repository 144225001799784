import { Spinner as BootstrapSpinner } from 'react-bootstrap';

const Spinner = () => (
  <BootstrapSpinner
    as="span"
    animation="grow"
    size="sm"
    role="status"
    aria-hidden="true"
    className="m-1"
  />
);

export default Spinner;

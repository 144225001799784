/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createNpsSurvey, getRecentTrackingSessions, sendFeedback } from '@api/api';
import { getLogger } from '@util/logger';
import * as FS from '@fullstory/browser';
import { clearSelectedCaregiver } from '@global-state/redux/caregiverListSlice';
import { setShowNotification } from '@global-state/redux/notificationSlice';
import {
  formatDate,
  isAfterDate,
  isBeforeDate,
  newDate,
  subtractDate,
  toUTC,
} from '@util/dateFunctions';
import { getSessionUser, setSessionUser } from '@util/session';

const log = getLogger('helpSlice');

const helpSlice = createSlice({
  name: 'subscription',
  initialState: {
    reporting: false,
    notificationBody: undefined,
    successModal: undefined,
    page: undefined,
    walkthrough: undefined,
    frame: 0,
    completeModal: undefined,
    surveyModal: false,
  },
  reducers: {
    setReporting(state, action) {
      state.reporting = action.payload;
    },

    setShowNotification(state, action) {
      state.notificationBody = action.payload;
    },

    clearReporting(state) {
      state.reporting = false;
      state.successModal = undefined;
      state.notificationBody = undefined;
    },

    setSuccess(state, { payload }) {
      state.successModal = payload;
    },

    setPage(state, action) {
      state.page = action.payload;
    },

    setWalkthrough(state, action) {
      state.walkthrough = action.payload;
      state.frame = 0;
      state.reporting = false;
    },

    nextFrame(state) {
      state.frame += 1;
    },

    prevFrame(state) {
      state.frame -= 1;
    },

    resetWalkthrough(state) {
      state.frame = 0;
      state.walkthrough = undefined;
    },

    setCompleteModal(state, action) {
      state.completeModal = action.payload;
    },

    setSurveyModal(state, action) {
      state.surveyModal = action.payload;
    },
  },
});

export default helpSlice.reducer;
export const {
  setReporting,
  clearReporting,
  setSuccess,
  setPage,
  setWalkthrough,
  nextFrame,
  resetWalkthrough,
  setCompleteModal,
  prevFrame,
  setSurveyModal,
} = helpSlice.actions;

export const sendFeedbackForm = (data) => async (dispatch) => {
  const errorMessage = 'There was a problem submitting feedback. Please try again.';
  try {
    dispatch(clearSelectedCaregiver());
    dispatch(clearReporting(false));

    const body = {
      contactMethod: data.contact,
      feedbackType: data.type,
      summary: data.summary,
      description: data.description,
      fullStoryUrl:
        process.env.NODE_ENV === 'production'
          ? FS.getCurrentSessionURL()
          : 'http://localdevnofullstory.com',
    };
    const result = await sendFeedback(body);
    if (!result.ok) {
      dispatch(setShowNotification(errorMessage));
    }
  } catch (err) {
    log.error(err);
    dispatch(setShowNotification(errorMessage));
  }
};

export const getRecentLoginsForSurvey = () => async (dispatch) => {
  try {
    // Number of logins AFTER a specified date
    // Change numLogins and daysBefore in order to change criteria for displaying survey modal
    // Also checks createdAt date of last completed npsSurvey, which is serialized in user
    const user = getSessionUser();

    // Right now just makes sure that it is not the users first login in the past 30 days
    const numLogins = 1;
    const daysBefore = 30;

    // We want all of the following dates to account for time zones so they must first be converted to UTC
    // if they are going to be compared to createdAt dates coming from backend, which uses UTC for createdAt
    // and updatedAt
    const dateAfter = formatDate(toUTC(subtractDate(newDate(), daysBefore, 'day')));
    const today = formatDate(toUTC(newDate()));

    // Min and max of timeframe for survey to be displayed - should change to Oct.15-30 for Shayam
    const minDate = formatDate(toUTC(newDate('10/18/2021')));
    const maxDate = formatDate(toUTC(newDate('11/02/2021')));

    const res = await getRecentTrackingSessions(dateAfter);
    if (res.ok) {
      const resJ = await res.json();
      const prevSurveyDate = user.npsSurveys.length ? user.npsSurveys[0].createdAt : null;
      const numLoginsCondition = resJ.count > numLogins;
      const todayWithinTimeFrame = isAfterDate(today, minDate) && isBeforeDate(today, maxDate);
      const completedSurveyWitinTimeFrame =
        isAfterDate(prevSurveyDate, minDate) && isBeforeDate(prevSurveyDate, maxDate);
      dispatch(
        setSurveyModal(numLoginsCondition && todayWithinTimeFrame && !completedSurveyWitinTimeFrame)
      );
    } else {
      log.error(res);
      dispatch(setSurveyModal(false));
    }
  } catch (err) {
    log.error(err);
    dispatch(setSurveyModal(false));
  }
};

export const sendNpsSurvey = (surveyScore) => async (dispatch) => {
  const body = {
    score: surveyScore,
  };
  const errorMessage =
    'There was a problem submitting your feedback survey. Thank you for your time.';
  try {
    const result = await createNpsSurvey(body);
    if (result.ok) {
      // Must clear and push new NPS survey to user local data, similar to custom dashboards,
      // such that the survey modal will not be displayed again in the same session
      const { score, createdAt } = await result.json();
      const newSurvey = { score, createdAt };
      const user = { ...getSessionUser() };
      user.npsSurveys = [newSurvey];
      setSessionUser(user);
    } else {
      dispatch(setShowNotification(errorMessage));
    }
  } catch (err) {
    log.error(err);
    dispatch(setShowNotification(errorMessage));
  }
};

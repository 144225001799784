import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getSessionToken } from '@util/session';
import CohortsApi from './Cohorts';
import ParticipantsApi from './Participants';
import OrganizationApi from './Organization';
import TwoFactorAuthenticationApi from './TwoFactorAuthentication';

export * from './api';
export * from './polypharmacy';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: (headers) => {
      const authToken = getSessionToken();

      // Two-factor authentication uses different (temporary token) authentication headers.
      // Prevent those headers from being overwritten if the user is already logged in.
      if (authToken && !headers.get('x-auth-token')) {
        headers.set('x-auth-token', `Bearer ${authToken}`);
      }

      return headers;
    },
  }),
  tagTypes: [...ParticipantsApi.tagTypes, ...CohortsApi.tagTypes],
  endpoints: (builder) => ({
    ...CohortsApi.endpoints(builder),
    ...ParticipantsApi.endpoints(builder),
    ...OrganizationApi.endpoints(builder),
    ...TwoFactorAuthenticationApi.endpoints(builder),
  }),
});

export const {
  /* Cohorts */
  useGetCohortSearchTermsQuery,
  useGetCohortsQuery,
  useGetCohortParticipantIdsQuery,
  useAddCohortMutation,
  useUpdateCohortMutation,
  useDeleteCohortMutation,
  useGetActionCohortsQuery,
  useGetHomeCohortsQuery,

  /* Participants */
  useGetParticipantQuery,
  useGetActiveParticipantsQuery,
  useGetParticipantsQuery,

  /* Organizations */
  useGetFacilitiesByOrganizationIdQuery,
  useGetTeamsByOrganizationIdQuery,

  /* Two-factor Authentication */
  useTwoFactorGenerateMutation,
  useTwoFactorConfirmMutation,
  useTwoFactorValidateMutation,
  useTwoFactorResetMutation,
} = apiSlice;

const OrganizationApi = {
  endpoints: (builder) => {
    return {
      getTeamsByOrganizationId: builder.query({
        query: (organizationId) => `organizations/${organizationId}/teams`,
      }),
      getFacilitiesByOrganizationId: builder.query({
        query: (organizationId) => `organizations/${organizationId}/facilities`,
      }),
    };
  },
};

export default OrganizationApi;

import { Icon, Text } from '@intus-ui';
import CreateFilter from './SearchCreate/CreateFilter';
import { checkFiltersUndefined } from './helpers';

const CohortSearchWrapper = ({
  searchState,
  setSearchState,
  setView,
  onApplyFilterLabel = 'All Results',
}) => {
  const handleOnApplyFilter = (results) => {
    const filtersAreEmpty = checkFiltersUndefined(results);
    if (filtersAreEmpty) {
      setSearchState({});
    } else {
      setSearchState(results);
    }
    setView('list');
  };

  return (
    <div style={styles.mainContent}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #C8C8C8',
          margin: '0px 0px 10px 0px',
          padding: '0px 0px 5px 0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text type="title" color="navy">
            Filters
          </Text>
        </div>
        <Icon name="Remove" color="#9D9D9D" hoverColor="#9D9D9D" onClick={() => setView('list')} />
      </div>

      <CreateFilter
        searchState={searchState}
        setSearchState={handleOnApplyFilter}
        onApplyFilterLabel={onApplyFilterLabel}
        isFixedCohortSearch
        isInWrapperComponent
      />
    </div>
  );
};

export default CohortSearchWrapper;
const styles = {
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 15px 15px 15px',
    flex: 1,
  },
};

import React from 'react';
import CenteredSpinnerWelcome from './CenteredSpinnerWelcome';

const LoadingWelcomeFlow = () => (
  <div
    className="w-100 h-100 position-absolute"
    style={{
      top: 0,
      left: 0,
      zIndex: 99,
      backgroundColor: 'rgba(255, 255, 255, .5)',
    }}
  >
    <CenteredSpinnerWelcome className="w-100 h-100" />
  </div>
);

export default LoadingWelcomeFlow;

import { useEffect, useState } from 'react';
import { getTrial, postTrial, putTrial } from '@api/initiatives';
import { useLazyQuery } from '@api/useQuery';
import { Button, Card, Divider, Icon, Input, SpinnerOrError, Text } from '@intus-ui';
import { FormErrorMessage } from '@intus-ui/components/forms/errors/FormErrorMessage';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as userTracking from '@util/userTracking';
import { getCohort } from '@api';
import AddEditCohortsModal from './AddEditCohortsModal';
import AddEditMetricsModal from './AddEditMetricsModal';
import MetricPill from './MetricPill';
import UnsavedChangesModal from './UnsavedChangesModal';
import CohortsList from './CohortsList';

/** Page for creating or editing an Initiative Trial */
export const TrialsCreateOrEditView = () => {
  const { initiativeId, trialId } = useParams();

  const formMode = getTrialFormMode(initiativeId, trialId);

  const { data: trial, error, runQuery } = useLazyQuery(() => getTrial(initiativeId, trialId));

  // Only try and load a trial if we're editing one, otherwise we're making a new trial.
  useEffect(() => {
    if (formMode === trialsFormMode.EditTrial) {
      runQuery();
    }
  }, []);

  if (error) {
    return <SpinnerOrError error="An error occurred loading the trial" />;
  }

  // If we're editing a trial and waiting for it to load, then show a spinner.
  if (formMode === trialsFormMode.EditTrial && !trial) {
    return <SpinnerOrError />;
  }

  return (
    <div style={styles.container}>
      <div style={styles.trialFormContainer}>
        <TrialsFormBody existingTrial={trial} formMode={formMode} />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    gap: 20,
    padding: '15px 20px 0px 20px',
    width: '100%',
    height: '100%',
    backgroundColor: '#F6F7FA',
  },
  initiativeInfoContainer: {
    flex: '0 0 240px',
  },
  trialFormContainer: {
    flex: '1 1 auto',
  },
  backButton: {
    width: 85,
    minWidth: 'unset',
    margin: 0,
  },
};

/**
 * The actual form for editing/creating a trial.
 * Render the header with the save button and the card with all the form fields in it.
 */
const TrialsFormBody = ({ existingTrial, formMode }) => {
  const history = useHistory();

  const [cohortsModalIsOpen, setCohortsModalIsOpen] = useState(false);
  const [metricsModalIsOpen, setMetricsModalIsOpen] = useState(false);

  // Stores if we've saved successfully or not.
  // Used to stop the alert about unsaved changes;
  const [isSaved, setIsSaved] = useState(false);

  const initiativeFromSessionStorage = getStoredInitiative(existingTrial);

  const form = useForm({
    defaultValues: initiativeFromSessionStorage ?? {
      name: '',
      initiative: {
        name: '',
        description: '',
      },
      useFirstDateForAllCohorts: false,
      metrics: [],
      ...existingTrial,
      cohorts:
        existingTrial?.cohorts.map((c) => {
          return {
            ...c,
            isOnGoing: c.endDate == null,
          };
        }) ?? [],
    },
    // Validate the fields when the user changes them, instead of only when they submit the form.
    mode: 'onChange',
  });

  const {
    control,
    formState: { isValid, errors: formErrors, isDirty },
    handleSubmit,
    getValues,
  } = form;

  // Validate that we have at least one cohort.
  const { append, remove } = useFieldArray({
    name: 'cohorts',
    control,
    rules: {
      required: 'Please select one or more cohorts',
    },
  });

  function addNewCohorts(cohorts) {
    const existingCohorts = getValues('cohorts');
    const useFirstDateForAllCohorts = getValues('useFirstDateForAllCohorts');

    const existingCohortIds = new Set(existingCohorts.map((c) => c.id));
    const cohortIds = new Set(cohorts.map((c) => c.id));

    const indexesToRemove = [];

    // Remove cohorts that are no longer in the list.
    existingCohorts.forEach((c, index) => {
      if (!cohortIds.has(c.id)) {
        indexesToRemove.push(index);
      }
    });
    remove(indexesToRemove);

    let defaultStartDate = null;
    let defaultEndDate = null;
    let defaultIsOnGoing = false;

    if (existingCohorts.length > 0 && useFirstDateForAllCohorts) {
      defaultStartDate = existingCohorts[0].startDate;
      defaultEndDate = existingCohorts[0].endDate;
      defaultIsOnGoing = existingCohorts[0].isOnGoing;
    }

    // Add new cohorts.
    cohorts.forEach((c) => {
      if (!existingCohortIds.has(c.id)) {
        append({
          ...c,
          startDate: defaultStartDate,
          endDate: defaultEndDate,
          isOnGoing: defaultIsOnGoing,
        });
      }
    });
  }

  // Validate that we have at least one metric.
  const { replace: setMetrics } = useFieldArray({
    name: 'metrics',
    control,
    rules: {
      required: 'Please select one or more metrics',
    },
  });

  // SETTING UP FIELDS FOR FORM
  const cohorts = getValues('cohorts');

  // Grab the cohortId query parameter from the URL
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const cohortId = queryParameters.get('cohortId');

  // Query to get the cohort if there is a cohortId query parameter
  const { runQuery: getCohortFromQueryParam } = useLazyQuery(() => getCohort(cohortId), {
    onSuccess: (cohort) => {
      const cohortToAdd = {
        ...cohort[0],
        startDate: null,
        endDate: null,
        isOnGoing: true,
      };
      const existingCohorts = getValues('cohorts');
      addNewCohorts([...existingCohorts, cohortToAdd]);
    },
  });

  // useEffect to run getCohortFromQueryParam when cohortId changes
  useEffect(() => {
    if (cohortId) {
      getCohortFromQueryParam();
    }
  }, [cohortId]);

  const metrics = getValues('metrics');

  const {
    loading,
    error: saveInitiativeError,
    statusCode: saveInitiativeStatusCode,
    runQuery,
  } = useLazyQuery((trial) => saveTrial(trial, formMode), {
    onSuccess: async (trial) => {
      // We get a different format for creating versus updating, check for both to get our IDs.
      const initiativeId = trial.initiativeId ?? trial.initiative.id;
      const trialId = trial.id ?? trial.trial.id;

      setIsSaved(true);

      // Wait one event loop tick to allow React to re-render.
      // We need the UnsavedChangesModal component to update with the new state.
      await new Promise((resolve) => {
        setTimeout(resolve, 0);
      });

      // Once the form has saved, send them to the trial focus view.
      // We use replace here so they don't go back and try to re-create the initiative.
      sessionStorage.removeItem('initiativeFormValues');
      history.replace(`/initiatives/${initiativeId}/trials/${trialId}`);
    },
  });

  const hasDuplicateNameError = saveInitiativeStatusCode === 409;

  const onSubmit = (trial) => {
    return runQuery(trial);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={formBodyStyles.container}>
      <AddEditCohortsModal
        open={cohortsModalIsOpen}
        key={cohortsModalIsOpen}
        handleAddCohorts={(newCohorts) => addNewCohorts(newCohorts)}
        cohorts={cohorts}
        onClose={() => setCohortsModalIsOpen(false)}
      />
      <AddEditMetricsModal
        key={metricsModalIsOpen.toString()}
        open={metricsModalIsOpen}
        onClose={() => setMetricsModalIsOpen(false)}
        handleAddMetrics={setMetrics}
        metrics={metrics}
      />
      <UnsavedChangesModal
        when={!isSaved && (loading || isDirty)}
        getMessage={() => {
          return `Are you sure you want to discard progress on ${
            getValues('initiative.name') || 'this initiative'
          }?`;
        }}
        shouldAllowNavigation={(location) => {
          return location.pathname?.startsWith('/cohorts/select');
        }}
      />
      <div style={formBodyStyles.headerContainer}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 25 }}>
          <Button
            type="button"
            hoverText="Back Button"
            name="Back"
            onClick={() => {
              userTracking.logEvent(
                'Initiatives: clicked "Back" button in Create/Edit Trial view '
              );

              history.goBack();
            }}
            style={styles.backButton}
          >
            <Icon name="caret-left" />
          </Button>
          <Text type="headline" color="navy">
            {`${existingTrial ? 'Edit' : 'Create'} Initiative`}
          </Text>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 25 }}>
          {saveInitiativeError != null && (
            <Text type="caption" color="error">
              One error ocurred.
            </Text>
          )}
          {saveInitiativeError == null && (
            <Text type="caption" color="gray50">
              {isValid ? 'Initiative is ready to save.' : '* Required.'}
            </Text>
          )}
          <Button
            type="submit"
            name="Save Initiative"
            disabled={!isValid || loading}
            onClick={() => {
              const savedInitiative = getValues();
              userTracking.logEvent('Initiatives: Clicked "Save Initiative" Button', {
                name: savedInitiative.name,
                metrics: savedInitiative.metrics.map((metric) => metric.name),
                cohorts: savedInitiative.cohorts.map((cohort) => cohort.name),
                isUpdate: existingTrial != null,
              });
            }}
          />
        </div>
      </div>

      <Card fluid dropshadow style={formBodyStyles.card}>
        <div style={formBodyStyles.cardInner}>
          <div style={formBodyStyles.formInputsContainer}>
            <Text type="title">Basic Info</Text>
            <div style={formBodyStyles.inputsContainer}>
              <Text type="caption">Initiative Name *</Text>
              <Controller
                control={control}
                name="initiative.name"
                rules={{ required: 'Initiative Name is required' }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    placeholder="Type Initiative name here"
                    type="text"
                    style={{ width: 481 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={hasDuplicateNameError}
                    id="initiative-name"
                  />
                )}
              />
              <FormErrorMessage
                error={
                  hasDuplicateNameError
                    ? '  This name has been used. Please try another one.'
                    : formErrors.name
                }
              />
            </div>

            {/* Initiative Description */}
            <div style={formBodyStyles.inputsContainer}>
              <Text type="caption">Description</Text>
              <Controller
                control={control}
                name="initiative.description"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    placeholder="Describe your Initiative here"
                    type="textarea"
                    style={{ width: '100%' }}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    id="initiative-description"
                  />
                )}
              />
              <FormErrorMessage error={formErrors.name} />
            </div>
          </div>

          <Divider />

          {/* Cohorts */}
          <div style={formBodyStyles.cohortContainer}>
            <div style={formBodyStyles.containerHeaderWithButton}>
              <Text type="title">Cohorts *</Text>
              <Button
                secondary
                onClick={() => {
                  userTracking.logEvent(
                    'Initiatives: Clicked "Select Existing/Remove Cohort" Button on Edit Trial page'
                  );
                  setCohortsModalIsOpen(true);
                }}
              >
                {`Select${cohorts.length === 0 ? ' Existing Cohort' : '/Remove Cohorts'}`}
              </Button>
              <Button
                secondary
                onClick={() => {
                  userTracking.logEvent(
                    'Initiatives: Clicked "Create New Cohort" Button on Edit Trial page'
                  );
                  sessionStorage.setItem('initiativeFormValues', JSON.stringify(getValues()));
                  history.push(`/cohorts/select`);
                }}
              >
                <Icon name="add" size="tiny" />
                Create New Cohort
              </Button>
            </div>
            <div
              style={{
                ...formBodyStyles.metricsContainer,
                ...formBodyStyles.inputsContainer,
                width: '100%',
              }}
            >
              {cohorts.length === 0 ? (
                <Text>Select cohorts and the dates of participation in the initiative.</Text>
              ) : (
                <div style={formBodyStyles.cohortsListContainer}>
                  <CohortsList form={form} />
                </div>
              )}
              <FormErrorMessage error={formErrors.cohorts} />
            </div>
          </div>

          <Divider />
          {/* Metrics */}
          <div style={formBodyStyles.metricsContainer}>
            <div style={formBodyStyles.containerHeaderWithButton}>
              <Text type="title">Metrics *</Text>
              <Button
                secondary
                onClick={() => {
                  userTracking.logEvent(
                    'Initiatives: Clicked "Add/Edit Metrics" Button on Edit Trial page'
                  );
                  setMetricsModalIsOpen(true);
                }}
              >
                {metrics.length === 0 ? <Icon name="add" size="tiny" /> : ''}
                {metrics.length === 0 ? (
                  <Text type="subtitle" color="navy">
                    Add Metrics
                  </Text>
                ) : (
                  <Text type="subtitle" color="navy">
                    Edit Metrics
                  </Text>
                )}
              </Button>
            </div>
            <div style={{ ...formBodyStyles.metricsContainer, ...formBodyStyles.inputsContainer }}>
              <Text>
                Please choose the metrics that can help track the success of your initiative.
              </Text>

              <div style={formBodyStyles.metricPills}>
                {metrics?.map((metric) => (
                  <MetricPill key={metric.name} metric={metric.name} />
                ))}
              </div>
              <FormErrorMessage error={formErrors.metrics} />
            </div>

            <FormErrorMessage error={formErrors.chartsConfig?.startDate} />
          </div>
        </div>
      </Card>
    </form>
  );
};

const formBodyStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    paddingBottom: 25,
    height: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  card: {
    backgroundColor: 'white',
    flex: '1 1 100%',
    overflow: 'auto',
    padding: '25px 20px',
  },
  cardInner: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  formInputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  inputsContainer: {
    paddingLeft: 20,
  },
  startDateContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  ongoingCheckboxContainer: {
    display: 'flex',
    gap: 5,
    marginTop: 20,
  },
  cohortContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 10,
  },
  cohortsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    flexWrap: 'wrap',
    width: '100%',
  },
  metricsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 10,
  },
  metricPills: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    gap: '10px',
  },
  containerHeaderWithButton: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
};

const trialsFormMode = {
  CreateInitiative: 'CreateInitiative',
  CreateTrial: 'CreateTrial',
  EditTrial: 'EditTrial',
};

/**
 * Returns an enum that determines what our form is doing.
 *
 * If we do not get an initiativeId, it means we're making a new initiative and a new trial.
 *
 * If we get an initiativeId and no trialId, it means we're making a new trial.
 *
 * If we get both an initiativeId and a trialId, it means we're editing a trial.
 */
function getTrialFormMode(initiativeId, trialId) {
  if (initiativeId == null) {
    return trialsFormMode.CreateInitiative;
  }

  if (trialId == null) {
    return trialsFormMode.CreateTrial;
  }

  return trialsFormMode.EditTrial;
}

function saveTrial(trial, formMode) {
  if (formMode === trialsFormMode.CreateInitiative || formMode === trialsFormMode.CreateTrial) {
    return postTrial(trial);
  }

  return putTrial(trial.initiativeId, trial);
}

function getStoredInitiative(existingTrial) {
  const storedInitiative = sessionStorage.getItem('initiativeFormValues');
  if (storedInitiative == null) {
    return null;
  }

  const parsedInitiative = JSON.parse(storedInitiative);

  if (parsedInitiative.initiativeId == null && existingTrial == null) {
    return parsedInitiative;
  }

  if (parsedInitiative.initiativeId === existingTrial?.initiativeId) {
    return parsedInitiative;
  }

  return null;
}

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';

import { getSessionUser } from '@util/session';
import { toSentenceCase } from '@util/utilFunctions';

import { resetProfile, updateUserSettings } from '@global-state/redux/userSettingsSlice';
import { resetPreviews, getCardPreview } from '@global-state/redux/homepageSlice';

import popup from 'assets/welcomeFlow_inserts/custom home left.gif';

import { Form, Col, Row, Button } from 'react-bootstrap';
import { useParticipantFilters } from '@intus-ui';
import SettingsBox from './SettingsBox';

const QuadsHomepageSettingsBox = ({ editButton, hide }) => {
  const dispatch = useDispatch();

  const userSettings = useSelector((state) => state.userSettings);
  const hPageBoxes = userSettings.homePageBox;
  const startDate = useSelector((state) => state.filters.startDate);
  const endDate = useSelector((state) => state.filters.endDate);

  const resetFormRef = useRef();
  const [error, setError] = useState(false);
  const { profile } = userSettings;

  if (!profile) {
    return null;
  }
  const { setting } = profile;
  const safeResetForm = () => {
    if (resetFormRef.current) {
      resetFormRef.current();
    }
  };

  const { selectedFilters } = useParticipantFilters();

  function doGetCardPreviews(cardSelections) {
    dispatch(getCardPreview(cardSelections[0], startDate, endDate, 1, selectedFilters));
    dispatch(getCardPreview(cardSelections[1], startDate, endDate, 2, selectedFilters));
    dispatch(getCardPreview(cardSelections[2], startDate, endDate, 3, selectedFilters));
  }

  // Will need to refetch on refresh, userSettings isn't populated on refresh
  const subscriptions = userSettings.subscriptions || getSessionUser().organization.subscriptions;
  const shouldNotIncludeFinancials = subscriptions?.includes('EHR_PROVIDES_NO_FINANCIALS');

  // Handle Customer Selection from Modal Component
  const onSelectCustomerHandler = (homeboxName, setFieldValue, valueList) => {
    const index = [...valueList.homePageBoxes].indexOf(homeboxName);
    if (index > -1) {
      setFieldValue(
        'homePageBoxes',
        valueList.homePageBoxes.filter((item) => item !== homeboxName)
      );
    } else {
      const updatedSelections = [...valueList.homePageBoxes];
      updatedSelections.push(homeboxName);
      setFieldValue('homePageBoxes', updatedSelections);
    }
  };

  return (
    <SettingsBox title="Card Selections" cancel={safeResetForm} editButton={editButton}>
      {({ editable, setEditable }) => (
        <Formik
          initialValues={{
            homePageBoxes: userSettings.profile.setting.homePageBoxes || [],
            ...profile,
            ...setting,
          }}
          onSubmit={(values) => {
            if (values.homePageBoxes.length === 3) {
              const updatedSetting = {
                ...setting,
                homePageBoxes: values.homePageBoxes,
              };
              setError(false);
              if (editButton) {
                setEditable(false);
              } else {
                hide();
              }
              dispatch(resetProfile());
              dispatch(resetPreviews());
              dispatch(updateUserSettings({ ...profile, settings: updatedSetting }));
              doGetCardPreviews(values.homePageBoxes);
            } else {
              setError(true);
            }
          }}
        >
          {({ handleSubmit, submitForm, resetForm, values, setFieldValue }) => {
            resetFormRef.current = resetForm;
            const disableCheckbox = (name) =>
              values.homePageBoxes.length === 3 && !values.homePageBoxes.includes(name);

            return (
              <Form onSubmit={handleSubmit}>
                <Row className="d-flex align-items-center justify-content-center">
                  <Col xl={7} className="d-flex align-items-center flex-column pt-3 pl-3">
                    <Row>
                      <Col>
                        <h5 className="font-weight-bold text-dark py-5">Clinical</h5>
                        <ul className="px-0">
                          {hPageBoxes?.slice(0, 3).map((homePageBox) => (
                            <li
                              key={`homePageBox-col${JSON.stringify(homePageBox)}`}
                              style={{ listStyleType: 'none' }}
                              className="d-flex flex-row align-items-center justify-content-start my-2"
                            >
                              <Field
                                type="checkbox"
                                name="homePageBoxes"
                                value={homePageBox}
                                style={{ width: '35px', height: '90px' }}
                                className={`${
                                  disableCheckbox(homePageBox) ? '' : 'cursor-pointer'
                                } mr-3 m-0 p-0`}
                                disabled={!editable || disableCheckbox(homePageBox)}
                              />
                              <Button
                                type="button"
                                htmlFor={homePageBox}
                                className="selection-button form-check-label px-3 box-shadow-on-gray rounded-xl border-0 text-left m-0"
                                style={{
                                  height: '50px',
                                  minWidth: '175px',
                                }}
                                onClick={() => {
                                  onSelectCustomerHandler(homePageBox, setFieldValue, values);
                                }}
                                disabled={!editable || disableCheckbox(homePageBox)}
                              >
                                <h6>{toSentenceCase(homePageBox)}</h6>
                              </Button>
                            </li>
                          ))}
                        </ul>
                      </Col>
                      <Col>
                        <h5 className="font-weight-bold text-dark py-5">Operational</h5>
                        <ul className="p-0">
                          {hPageBoxes
                            ?.slice(3)
                            .reverse()
                            .filter((item) => {
                              // currently hiding Financial dashboard if a "subscription" indicating it should be hidden
                              if (shouldNotIncludeFinancials) {
                                return item !== 'financial';
                              }
                              return true;
                            })
                            .map((homePageBox, index) => (
                              <li
                                // eslint-disable-next-line react/no-array-index-key
                                key={`homePageBox-col${index}`}
                                style={{ listStyleType: 'none' }}
                                className="d-flex flex-row align-items-center justify-content-start my-2"
                              >
                                <Row className="d-flex flex-row align-items-center justify-content-center rounded-xl py-1">
                                  <Field
                                    type="checkbox"
                                    name="homePageBoxes"
                                    value={homePageBox}
                                    style={{ width: '35px', height: '80px' }}
                                    className={`${
                                      disableCheckbox(homePageBox) ? '' : 'cursor-pointer'
                                    } mr-3 m-0 p-0`}
                                    disabled={!editable || disableCheckbox(homePageBox)}
                                  />
                                  <Button
                                    type="button"
                                    htmlFor={homePageBox}
                                    className="selection-button form-check-label px-3 box-shadow-on-gray rounded-xl border-0 text-left m-0"
                                    style={{
                                      height: '50px',
                                      minWidth: '175px',
                                    }}
                                    onClick={() => {
                                      onSelectCustomerHandler(homePageBox, setFieldValue, values);
                                    }}
                                    disabled={!editable || disableCheckbox(homePageBox)}
                                  >
                                    <h6>{toSentenceCase(homePageBox)}</h6>
                                  </Button>
                                </Row>
                              </li>
                            ))}
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={5} className="d-flex align-items-start flex-column text-muted">
                    <div className="d-flex flex-column">
                      <img className="m-0" alt="Example homepage" src={popup} width="420rem" />
                      <p className="pt-2 align-self-end">Example Card Selection</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {editable ? (
                    <SettingsBox.Buttons
                      disabled={values.homePageBoxes.length !== 3}
                      errorMessage={
                        error || values.homePageBoxes.length !== 3
                          ? 'Please select 3 categories to continue.'
                          : null
                      }
                      cancel={() => {
                        resetForm();
                        if (editButton) {
                          setEditable(false);
                        } else {
                          hide();
                        }
                      }}
                      submit={submitForm}
                    />
                  ) : null}
                </Row>
              </Form>
            );
          }}
        </Formik>
      )}
    </SettingsBox>
  );
};

QuadsHomepageSettingsBox.propTypes = {
  editButton: PropTypes.bool,
  hide: PropTypes.func,
};

QuadsHomepageSettingsBox.defaultProps = {
  editButton: true,
  hide: undefined,
};

export default QuadsHomepageSettingsBox;

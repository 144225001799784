/* eslint-disable camelcase */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { logEvent } from '@util/userTracking';
import { formatDate } from '@util/dateFunctions';

import { setCurrPatient, setPatientList } from '@global-state/redux';

import { Text, DetailsButton, TooltipComponent, Icon } from '@intus-ui';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

import { useUtilizationContext } from 'Utilization/Context';
import { AdmitRecord } from 'Utilization/types';
import tableConfig from '../table.config';

interface DetailsModalProps {
  admits: AdmitRecord[];
  tableTitle?: string;
  admitsType: string;
  parentPage?: string;
}

const DetailsModal: React.FC<DetailsModalProps> = ({
  tableTitle,
  admits,
  admitsType,
  parentPage,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const utilizationContext = useUtilizationContext();

  tableTitle = tableTitle || admitsType;

  const handleClickedSeeMore = (item: AdmitRecord) => {
    const patients = admits.map((admit: AdmitRecord) => ({
      ...admit.patient,
      name: `${admit.patient.firstName} ${admit.patient.lastName} (${admit.patient.memberId})`,
    }));
    const currIndex = patients?.findIndex((ppt: any) => ppt.id === item.patientId);
    // TODO: add prop to specify which page it's coming from
    if (parentPage === 'Admits Feed') {
      logEvent('Admits Feed: Trends Details Modal: Clicked See More', {
        patientId: item.patientId,
      });
    } else {
      logEvent('Utilization: Details Modal: Clicked See More', { patientId: item.patientId });
    }
    dispatch(setPatientList(patients));
    dispatch(setCurrPatient(currIndex));
    history.push(`/patient/${item.patientId}/admits`);
  };

  let columns: MRT_ColumnDef<AdmitRecord>[] = [
    {
      id: 'participantName',
      header: 'Participant Name',
      accessorFn: (row) =>
        `${row.patient.firstName} ${row.patient.lastName} (${row.patient.memberId})`,
    },
    {
      id: 'organizationName',
      header: 'Organization',
      accessorFn: (row) => `${row.organization.name}`,
    },
    {
      accessorKey: 'admitDate',
      header: 'Admit Date',
      Cell: ({ cell }) => formatDate(cell.getValue<string>(), 'M/D/YYYY'),
    },
    {
      accessorKey: 'dischargeDate',
      header: 'Discharge Date',
      Cell: ({ cell }) => {
        const dischargeDate = cell.getValue<string>();
        return dischargeDate ? formatDate(dischargeDate, 'M/D/YYYY') : '';
      },
    },
    {
      id: 'daysAdmitted',
      header: 'Total LOS',
      filterVariant: 'range-slider',
      filterFn: 'betweenInclusive',
      muiFilterSliderProps: {
        valueLabelFormat: (value) => value.toLocaleString(),
      },
      muiTableHeadCellProps: {
        align: 'right',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      accessorKey: 'los',
      Cell: ({ cell, row }) => {
        const isDischarged = row.original.dischargeDate;
        return `${cell.getValue<number>().toLocaleString()}${isDischarged ? '' : '+'}`;
      },
    },
    {
      id: 'daysAdmittedInRange',
      header: 'Admit Month LOS',
      filterVariant: 'range-slider',
      filterFn: 'betweenInclusive',
      muiFilterSliderProps: {
        valueLabelFormat: (value) => value.toLocaleString(),
      },
      muiTableHeadCellProps: {
        align: 'right',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      accessorKey: 'losInRange',
      Header: () => {
        return (
          <TooltipComponent
            anchorId="admitted_los_header"
            content="LoS days within the month of admission"
            style={{}}
          >
            Admit Month LOS <Icon name="Info" size="small" />
          </TooltipComponent>
        );
      },
      Cell: ({ cell, row }) => {
        const isDischarged = row.original.dischargeDate;
        return `${cell.getValue<number>().toLocaleString()}${isDischarged ? '' : '+'}`;
      },
    },
    {
      accessorKey: 'principalDx',
      header: 'Principal Dx',
      filterVariant: 'select',
      accessorFn: (row) => {
        return row.principalDx
          .map((dx) => {
            return `${dx.description} (${dx.code})`;
          })
          .join('\n');
      },
    },
    {
      accessorKey: 'admitFacility',
      header: 'Admit Facility',
      filterVariant: 'select',
    },
    {
      id: 'seeMore',
      header: '',
      muiTableBodyCellProps: {
        align: 'right',
        sx: {
          width: 110,
        },
      },
      Cell: ({ row }) => (
        <DetailsButton text="See More" onClick={() => handleClickedSeeMore(row.original)} />
      ),
    },
  ];

  // Don't show organizationName column if not a health system admin
  if (!utilizationContext?.isHealthSystemAdmin)
    columns = columns.filter((col) => col.id !== 'organizationName');

  const table = useMaterialReactTable({
    columns,
    data: admits,
    initialState: {
      // showColumnFilters: true,
      columnPinning: { left: ['participantName'], right: ['seeMore'] },
      sorting: [
        {
          id: 'admitDate',
          desc: true,
        },
        {
          id: 'dischargeDate',
          desc: false,
        },
      ],
    },
    ...tableConfig,
    enableSorting: true,
    // enableFacetedValues: true,
    // enableFilters: true,
    enableColumnFilters: true,
    defaultColumn: {
      minSize: 25,
      size: 50,
      maxSize: 250,
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: 'calc(100vh - 215px)',
      },
    },
  });

  return (
    <div
      style={{
        marginTop: 10,
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        width: '100%',
        gap: 10,
      }}
    >
      <Text type="subtitle" color="navy">
        {tableTitle}
      </Text>
      <MaterialReactTable table={table} />
    </div>
  );
};

export default DetailsModal;

import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';
import { Chip } from '@nivo/tooltip';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import { formatDate } from '@util/dateFunctions';

const toDate = (val) => {
  const date = formatDate(val, 'MMMM YYYY');
  if (date !== 'Invalid date') {
    return date;
  }
  return val;
};

const tooltipEle = (props) => (
  <div className="px-3 py-2 rounded-lg box-shadow-on-gray to-front bg-transparent-90">
    <strong>{toDate(props.indexValue)}</strong>
    <hr className="m-0" />
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center pr-2">
        <Chip size={12} color={props.color} />
        <span className="pl-2">{props.id}</span>
      </div>
      <strong>{props.value}</strong>
    </div>
  </div>
);

const IntusBarChart = ({
  data,
  xTitle,
  yTitle,
  margin,
  format,
  xFormat,
  minValue,
  maxValue,
  customTooltip,
  legend,
}) => {
  if (!data) return <CenteredSpinner className="w-100 h-100" />;
  const keyArray = Object.keys(data[0]).filter(
    (key) => key !== 'label' && key.toString().indexOf('Color') === -1
  );
  return (
    <ResponsiveBar
      theme={{
        fontFamily: 'Muli',
        axis: {
          legend: {
            text: {
              fontWeight: 'bold',
              fontSize: '.9em',
            },
          },
        },
        labels: {
          text: {
            fontSize: '.7em',
          },
        },
        tooltip: {
          container: {
            backgroundColor: 'transparent',
            border: 'none',
            borderWidth: 0,
            padding: 0,
            boxShadow: 0,
          },
        },
      }}
      labelFormat={format}
      data={data}
      keys={keyArray}
      indexBy="label"
      margin={margin}
      padding={0.3}
      // eslint-disable-next-line no-shadow
      colors={({ id, data }) => data[`${id}Color`]}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -55,
        legend: xTitle || null,
        legendPosition: 'middle',
        legendOffset: 100,
        format: xFormat,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yTitle || null,
        legendPosition: 'middle',
        legendOffset: -60,
        format,
      }}
      animate={false}
      minValue={minValue || 'auto'}
      maxValue={maxValue || 'auto'}
      tooltip={customTooltip || tooltipEle}
      legends={
        legend
          ? [
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'row',
                justify: false,
                translateX: 30,
                translateY: 110,
                itemsSpacing: 2,
                itemWidth: 130,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
              },
            ]
          : undefined
      }
      markers={[
        {
          axis: 'y',
          value: 0,
          lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2.5 },
        },
      ]}
    />
  );
};

tooltipEle.propTypes = {
  indexValue: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.number,
};

IntusBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  margin: PropTypes.shape({
    top: PropTypes.number.isRequired,
    right: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }),
  format: PropTypes.func,
  xFormat: PropTypes.func,
  xTitle: PropTypes.string,
  yTitle: PropTypes.string,
  minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customTooltip: PropTypes.func,
  legend: PropTypes.bool,
};

IntusBarChart.defaultProps = {
  data: undefined,
  legend: false,
  margin: {
    top: 50,
    right: 40,
    bottom: 50,
    left: 60,
  },
  format: undefined,
  xFormat: (value) => toDate(value),
  xTitle: '',
  yTitle: '',
  minValue: 'auto',
  maxValue: 'auto',
  customTooltip: undefined,
};

export default IntusBarChart;

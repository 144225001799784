import PropTypes from 'prop-types';

export const acuityModalPropType = PropTypes.shape({
  acuityChanges: PropTypes.shape({
    decreased: PropTypes.shape({
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
    increased: PropTypes.shape({
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
  }),

  acuityScoreGroupBreakdown: PropTypes.shape({
    HIGH: PropTypes.shape({
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
    HIGHEST: PropTypes.shape({
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
    LOW: PropTypes.shape({
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
    MODERATE: PropTypes.shape({
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
  }),

  currentHighRiskOfDisease: PropTypes.shape({
    CKD: PropTypes.shape({
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
    DIABETES: PropTypes.shape({
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
  }),

  currentHighestAcuity: PropTypes.arrayOf(
    PropTypes.shape({
      acuityScore: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),

  livingSituationChanges: PropTypes.shape({
    val: PropTypes.number,
    ids: PropTypes.arrayOf(PropTypes.number),
  }),
});

export default acuityModalPropType;

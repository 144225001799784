import { Icon, Text } from '@intus-ui';

const TrialCohortPreviewCard = ({ cohort, style }) => {
  return (
    <div style={{ ...styles.container, ...style }}>
      <div>
        <Text type="title">{cohort.name}</Text>
      </div>
      <div style={styles.participantCountContainer}>
        <Icon name="People" color="#9D9D9D" />
        <Text type="body" color="caption">
          {cohort.participantsCount}
        </Text>
      </div>
    </div>
  );
};

export default TrialCohortPreviewCard;

const styles = {
  container: {
    border: '1px solid #9D9D9D',
    borderRadius: 10,
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 20,
  },
  participantCountContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
};

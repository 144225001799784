import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Text } from '@intus-ui';
import TooltipComponent from '@intus-ui/components/TooltipComponent';
import { formatDate } from '@util/dateFunctions';
import { getCohortLineChartColorValue } from 'Initiatives/MetricChart/cohortLineChartColors';
import { ReactComponent as DashedLineSvg } from 'Initiatives/MetricChart/images/dashed-line.svg';
import { ReactComponent as DottedLineSvg } from 'Initiatives/MetricChart/images/dotted-line.svg';

/**
 * Renders the legend for the trial metric chart.
 *
 * Displays the name of each cohort, their start/end dates, and the color of their line in the chart.
 */
export const TrialMetricChartLegend = ({ trial, metric }) => {
  return (
    <div style={legendStyles.container}>
      {metric.cohorts.map((cohort) => {
        return <ChartLegendCohortBox key={cohort.id} cohort={cohort} />;
      })}

      <div style={{ flex: '0 0 auto' }}>
        <TooltipComponent
          anchorId={`trialMetricChartLegendMoreInfo-${trial.id}`}
          style={{ maxWidth: 400 }}
          content={<ChartLegendMoreInfoTooltip />}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
              // Negative margins since the SVG has some padding that can't easily be changed.
              marginTop: -3,
              marginLeft: -4,
              cursor: 'pointer',
            }}
          >
            <InfoIcon variant="success" className="info-icon cursor-pointer m-0" />

            <Text type="caption" style={{ paddingLeft: 2, paddingTop: 1 }}>
              More Info
            </Text>
          </div>
        </TooltipComponent>
      </div>
    </div>
  );
};

/** The More Info tooltip that displays info about the chart lines. */
const ChartLegendMoreInfoTooltip = () => {
  return (
    <div>
      <div style={legendStyles.tooltipTextContainer}>
        <DottedLineSvg />
        <Text type="caption">Cohort is participating in initiative</Text>
      </div>
      <div style={legendStyles.tooltipTextContainer}>
        <DashedLineSvg />
        <Text type="caption">Cohort is not participating in initiative</Text>
      </div>
    </div>
  );
};

const legendStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    columnGap: 24,
    rowGap: 4,
    marginLeft: 30,
  },
  tooltipTextContainer: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    paddingBottom: 4,
  },
};

/** Renders the colored box, cohort name, and start/end date text for the given cohort. */
const ChartLegendCohortBox = ({ cohort }) => {
  const color = getCohortLineChartColorValue(cohort.color);

  let timeText = `${formatDate(cohort.startDate, 'M/D/YY')}-`;

  if (cohort.endDate == null) {
    timeText += 'Ongoing';
  } else {
    timeText += formatDate(cohort.endDate, 'M/D/YY');
  }

  return (
    <div style={{ flex: '0 0 auto', display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
      <div style={{ backgroundColor: color, width: 12, height: 12, marginRight: 6 }}> </div>
      <Text type="caption-bold" style={{ paddingRight: 4 }}>
        {cohort.name}
      </Text>
      <Text type="caption">({timeText})</Text>
    </div>
  );
};

/* eslint-disable camelcase */
import React from 'react';
import { Datagrid } from '@intus-ui';

type AdmitMetricsByDay = Record<string, string | number | boolean | any>;

type OrgViewTableProps = {
  data: AdmitMetricsByDay[];
  handleOnClickCell: (value: React.ReactNode, row: React.ReactNode, column: any) => void;
};

const OrgViewTable: React.FC<OrgViewTableProps> = ({ data, handleOnClickCell }) => {
  return (
    <Datagrid
      data={data}
      firstRow={{
        key: 'label',
        header: '',
      }}
      lastRow={{
        key: 'total',
        header: 'Total',
      }}
      handleOnClickCell={handleOnClickCell}
    />
  );
};

export default OrgViewTable;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { formatDate } from '@util/dateFunctions';
import { logEvent } from '@util/userTracking';

import { setCurrPatient, setPatientList } from '@global-state/redux';
import { useUtilizationContext } from 'Utilization/Context';

import { List, layout } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';

import { AdmitRecord } from 'Utilization/types';

const format = [
  {
    field: 'name',
    name: 'Participant Name',
    flex: 2,
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
    customStyle: {
      textOverflow: 'ellipses',
    },
  },
  {
    field: 'admitDate',
    name: 'Admit Date',
    flex: 1,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="admitDate" id="admitDate" /> }],
    render: (row: any) => {
      const { item } = row;
      return <div>{formatDate(item.admitDate, 'M/D/YYYY')}</div>;
    },
  },
];

const columnConfig = {
  default: {
    columns: ['name', 'admitDate'],
  },
};

const AdmitsTab = ({ admits }: { admits: AdmitRecord[] }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { admitsType, orgId } = useUtilizationContext();

  const formattedData = admits
    // Filter admits by type and organization
    .filter((admit: AdmitRecord) => {
      return admit.admitTypeLookup === admitsType.toLowerCase() && orgId
        ? admit.organizationId === orgId
        : true;
    })
    .map((admit: AdmitRecord) => {
      return {
        ...admit,
        name: `${admit?.patient?.firstName} ${admit?.patient?.lastName} (${admit?.patient?.memberId})`,
      };
    });

  return (
    <div style={{ ...layout.container, backgroundColor: 'white' }}>
      <div style={{ padding: 10 }}>
        <List
          condensed
          data={formattedData}
          format={format}
          columnConfig={columnConfig.default}
          defaultSort={{ field: 'admitDate', direction: 'desc' }}
          onClickItem={(item) => {
            const patients = admits.map((admit: AdmitRecord) => ({
              ...admit.patient,
              name: `${admit.patient?.firstName} ${admit.patient?.lastName} (${admit.patient?.memberId})`,
            }));
            const currIndex = patients?.findIndex((ppt: any) => ppt.id === item.patientId);

            logEvent('Utilization: Admits Sidebar: Clicked Admit', { patientId: item.patientId });
            dispatch(setPatientList(patients));
            dispatch(setCurrPatient(currIndex));
            history.push(`/patient/${item.patientId}/admits`);
          }}
        />
      </div>
    </div>
  );
};

export default AdmitsTab;

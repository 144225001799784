import PropTypes from 'prop-types';

import { Row, Button } from 'react-bootstrap';
import { Icon } from '@intus-ui';
import AcuityCardHeader from './AcuityCardHeader';

import '@intus-ui/styles/Profile.scss';
import '@intus-ui/styles/Utilities.scss';

const removeExclamation = (str) => {
  if (str.includes('!') || str.includes('?')) return str.slice(1);
  return str;
};

// Sorting through items in acuity cards in the order of Newly Added, Removed, and not newly added.
const sortAcuityCards = (arr) => {
  return [
    ...arr.filter((item) => item.includes('!')).sort(), // new diagnoses
    ...arr.filter((item) => !item.includes('!') && !item.includes('?')).sort(), // active diagnoses
    ...arr.filter((item) => item.includes('?')).sort(), // inactive diagnoses
  ];
};

const AcuityCards = ({ title, score, children, onClick, animatedCard }) => (
  <Button
    className="card non-clickable bg-white d-flex box-shadow cursor-pointer p-0 w-100 h-100"
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
  >
    <Row className="m-0 pt-2 pb-1 w-100 bg-primary rounded-lg-top">
      {title ? <AcuityCardHeader title={title} score={score} /> : null}
    </Row>
    <div
      style={{
        height: animatedCard ? '100%' : 'calc(20vh - 1em)',
        width: animatedCard ? '20em' : '100%',
      }}
      className="overflow-scroll p-0 rounded-lg-bottom"
    >
      <div className="bg-white h-100 w-100 pb-3  text-dark">
        {children[1].length > 0 ? (
          sortAcuityCards(children[1]).map((item) => (
            <div
              className="d-flex align-items-start justify-content-start text-break border-bottom border-light pt-1 px-0"
              key={`score-div-${Math.random()}`}
              style={{ backgroundColor: item.includes('!') ? '#EDF6FE' : 'white' }}
            >
              <div style={{ margin: '0 7.5px', width: 22 }}>
                <Icon name="bullet" className="text-muted" />
              </div>
              <div className="text-align-left">
                {item.includes('?') ? (
                  <h6>
                    <del>{removeExclamation(item)}</del>
                  </h6>
                ) : (
                  <h6>{removeExclamation(item)}</h6>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="p-4 text-muted h-100 w-100 bg-white">
            <h6>No conditions in this subcategory</h6>
          </div>
        )}
      </div>
    </div>
  </Button>
);
AcuityCards.propTypes = {
  title: PropTypes.node,
  score: PropTypes.number,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired, // Used primarily for the animation
  animatedCard: PropTypes.bool, // If true, this render of card is in the middle
};

AcuityCards.defaultProps = {
  title: null,
  score: null,
  children: null,
  animatedCard: false,
};

export default AcuityCards;

import React from 'react';
import PropTypes from 'prop-types';
import '@intus-ui/styles/Utilities.scss';
import { toSentenceCase } from '@util/utilFunctions';
import { getFeatureFlag } from '@config';

const AcuityCardHeader = ({ title, score }) => {
  const { featureIsActive: AcuityV2isActive } = getFeatureFlag('AcuityV2');

  return (
    <div className="w-100 px-2 py-0 d-flex flex-row justify-content-between text-left flex-row rounded-lg">
      <h5 className="py-0 pr-2 text-white text-xs-p text-truncate">
        {toSentenceCase(title).replace('Subscore', '')}
      </h5>
      {!AcuityV2isActive && (
        <div className='"mr-1 px-1 py-0 rounded-sm bg-white text-primary text-xs-p"'>
          <h5>{score}</h5>
        </div>
      )}
    </div>
  );
};

AcuityCardHeader.propTypes = {
  title: PropTypes.node,
  score: PropTypes.number,
};

AcuityCardHeader.defaultProps = {
  title: '',
  score: '',
};

export default AcuityCardHeader;

import { toUTC, newDate, formatDate } from '@util/dateFunctions';
import { detect } from 'detect-browser';

const generateTrackingInfo = () => {
  const browserInfo = detect();
  return {
    time: formatDate(toUTC(newDate())),
    browser: browserInfo?.name,
    system: browserInfo?.os,
    browserVersion: browserInfo?.version,
    windowInnerHeight: window.screen.height,
    windowInnerWidth: window.screen.width,
  };
};

export default generateTrackingInfo;

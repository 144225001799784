import { Modal, ButtonGroup, Button } from '.';
import layout from '../layouts';

const ConfirmationModal = ({
  title = '',
  open,
  description = 'Confirmation Description',
  cancelText = 'Cancel',
  cancelButtonProps = {},
  confirmText = 'Confirm',
  confirmButtonProps = {},
  onClickConfirm = () => {},
  onClickCancel = () => {},
  onClose,
  ...rest
}) => {
  const onClickCancelExt = () => {
    onClickCancel();
    onClose();
  };
  return (
    <Modal header={{ title, centered: true }} open={open} onClose={onClose} type="small" {...rest}>
      <div style={styles.content}> {description}</div>
      <ButtonGroup showDivider={false}>
        <Button {...cancelButtonProps} secondary onClick={onClickCancelExt}>
          {cancelText}
        </Button>
        <Button {...confirmButtonProps} onClick={onClickConfirm}>
          {confirmText}
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

const styles = { content: { ...layout.centeredContentContainer, padding: 5 } };

export default ConfirmationModal;

import { get } from '@api/apiMethods';

export type ICCSRCategory = {
  ccsrCategory: string;
  ccsrCategoryDescription: string;
};

export type IICDCode = {
  icdCode: string;
  icdCodeDescription: string;
};

export type ICurrentCondition = IICDCode & {
  ccsrCategories: ICCSRCategory[];
};

export function getCurrentConditions(patientId: number) {
  return get<ICurrentCondition[]>(`patients/${patientId}/current-conditions`);
}

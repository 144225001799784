import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { bulkUpsertReportUsers } from '@api';
import { Button, Modal, layout } from '@intus-ui';

// make a functional component named AddUsersToReportModal which takes in the props show, onHide, reportId, and users
const AddUsersToReportModal = ({ show, onHide, report, users }) => {
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState(null);

  const handleAddUsers = (event) => {
    event.preventDefault();
    const userIds = selectedUsers.map((user) => user.value);
    // call the api function bulkUpsertReportUsers with the reportId, userIds, and the selectedRole
    bulkUpsertReportUsers(report.reportId, {
      usersToAdd: userIds,
      permissionType: selectedRole,
    }).then(() => {
      // call the onHide function to close the modal
      onHide();
    });
  };

  // return a modal component
  return (
    <Modal
      open={show}
      onClose={onHide}
      header={{
        title: `Adding ${report && report.reportName} users`,
      }}
      type="medium"
      style={{ padding: 20, height: '55vh' }}
    >
      <div style={layout.container}>
        <Form onSubmit={handleAddUsers}>
          <Form.Group>
            <Form.Label>Select Users</Form.Label>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={users
                .sort((a, b) => a.email.localeCompare(b.email))
                .map((user) => ({ value: user.userId, label: user.email }))}
              onChange={(selected) => setSelectedUsers(selected)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Select Role</Form.Label>
            <Select
              options={[
                { value: 'VIEWER', label: 'Viewer' },
                { value: 'ADMIN', label: 'Admin' },
              ]}
              onChange={(selected) => setSelectedRole(selected.value)}
            />
          </Form.Group>
          {/* make a submit button that is disabled if no permission type or no users chosen */}
          <Button type="submit" disabled={!selectedRole || selectedUsers.length === 0}>
            Add Users
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

// export the AddUsersToReportModal component
export default AddUsersToReportModal;

import ProtectedRoute from '@router/ProtectedRoute';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import { layout } from '@intus-ui';
import UtilizationsTracker from 'Utilization';
import RiskAcuityWrapper from './DashboardWrappers/RiskAcuityWrapper';
import CensusWrapper from './DashboardWrappers/CensusWrapper';
import FallsWrapper from './DashboardWrappers/FallsWrapper';
import HospitalizationWrapper from './DashboardWrappers/HospitalizationWrapper';
import FinancialWrapper from './DashboardWrappers/FinancialWrapper';
import PerformanceBenchmarkWrapper from './DashboardWrappers/PerformanceBenchmarkWrapper';
/**
 * This file contains all the routes for Analytics Dashboards
 */
export const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <div style={styles.container}>
      <Switch>
        <ProtectedRoute path={`${path}/riskacuity`} component={RiskAcuityWrapper} />
        <ProtectedRoute path={`${path}/census`} component={CensusWrapper} />
        <ProtectedRoute path={`${path}/falls`} component={FallsWrapper} />
        <ProtectedRoute path={`${path}/hospitalizations`} component={HospitalizationWrapper} />
        <ProtectedRoute path={`${path}/financial`} component={FinancialWrapper} />
        <ProtectedRoute
          path={`${path}/performancebenchmark`}
          component={PerformanceBenchmarkWrapper}
        />
        <ProtectedRoute path={`${path}/utilization`} component={UtilizationsTracker} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </div>
  );
};

const styles = {
  container: {
    ...layout.container,
    height: '100%',
  },
};

import React from 'react';

import { IPatientStarsAdherence } from '@api/polypharmacy/getSTARSAdherence';
import { IAllPatientFirstFillStatus } from '@api/polypharmacy/getRisingStars';

import { getStringReplacement } from '@util/stringReplacements';

import { Checkbox } from '@mui/material';
import { DetailsButton, Text } from '@intus-ui';
import { textColors } from '@intus-ui/styles/SecondaryColors';
import SortIcon from '@intus-ui/components/List/SortIcon';

import { NextFillDueColumn } from './components/NextFillDueColumn';
import { starsMeasureToName } from './types/STARSMeasures';
import { PatientMedicationStatus } from './components/PatientFirstFillStatus';
// eslint-disable-next-line import/no-cycle
import { FlatPatient } from './AdherenceRisingStars';

export function getSingleMeasureFormat(
  onClickSeeDetails: (patientId: number) => void,
  onChangeStatus: (
    patientId: number,
    measure: string,
    status: IAllPatientFirstFillStatus | null
  ) => void,
  onChangeHospiceStatus: (patientId: number, newHospiceStatus: boolean) => void
) {
  return [
    {
      field: 'hospice',
      name: 'Hospice',
      flex: '1 1 50px',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <Checkbox
            checked={patient.hospice}
            onChange={(event) => onChangeHospiceStatus(patient.id, event.target.checked)}
          />
        );
      },
    },
    {
      field: 'patientStatus',
      name: 'Status',
      flex: '0 0 100px',
      addOns: [{ type: 'sort', element: <SortIcon id="patientStatus" key="patientStatus" /> }],
      render: (row: { item: FlatPatient }) => {
        return (
          <PatientMedicationStatus
            patient={row.item}
            onChangeStatus={(status) => onChangeStatus(row.item.id, row.item.measure, status)}
            tab="first_fill"
          />
        );
      },
    },
    {
      field: 'name',
      name: `${getStringReplacement('Participant')} Name`,
      flex: '1 1 220px',
      addOns: [{ type: 'sort', element: <SortIcon id="name" key="name" /> }],
    },
    {
      field: 'age',
      name: `Age`,
      flex: '0 0 60px',
      addOns: [{ type: 'sort', element: <SortIcon id="age" key="age" /> }],
    },
    {
      field: 'gapDays',
      name: `Gap Days`,
      flex: '0 0 80px',
      align: 'end',
    },
    {
      field: 'minCoverageDays',
      name: `Min Coverage Days`,
      flex: '0 0 150px',
      align: 'end',
    },
    {
      field: 'adherenceComplianceDate',
      name: `Adherence Complicance Date`,
      flex: '0 0 140px',
    },
    {
      field: 'actionDate',
      name: `Action Date`,
      flex: '0 0 100px',
    },
    {
      field: 'measureData',
      name: 'Adherence Measure',
      flex: '1 1 220px',
      customStyles: {
        marginRight: '20px',
      },
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        return starsMeasureToName[patient.measure];
      },
    },
    {
      field: 'medClasses',
      name: 'Medication Classes',
      flex: '1 1 220px',
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={item.measure}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    // maxWidth must be the same as the column width and the column must use a fixed width with `flex: 0 0 nnnpx`
                    maxWidth: 220,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  title={item.medClasses.join(', ')}
                >
                  {item.medClasses.map((c) => {
                    return c;
                  })}
                  {item.medClasses.length === 0 && <Text>&nbsp;</Text>}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'fillCount',
      name: '# of Fills',
      flex: '1 1 120px',
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon id="fillCount" key="fillCount" /> }],
    },
    {
      field: 'percentageDaysCovered',
      name: 'PDC',
      flex: '1 1 120px',
      align: 'end',
      addOns: [
        {
          type: 'sort',
          element: <SortIcon id="percentageDaysCovered" key="percentageDaysCovered" />,
        },
      ],
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;

        return (
          <span
            style={{ color: patient.percentageDaysCovered < 0.8 ? textColors.error : undefined }}
          >
            {parseFloat((patient.percentageDaysCovered * 100).toFixed(0))}%
          </span>
        );
      },
    },
    {
      field: 'priority',
      name: 'Priority',
      flex: '0 0 100px',
    },
    {
      field: 'fillDueDate',
      name: 'Next fill due',
      flex: '1 1 250px',
      addOns: [{ type: 'sort', element: <SortIcon id="fillDueDate" key="fillDueDate" /> }],
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            <NextFillDueColumn fillDueDate={patient.fillDueDate} />
          </div>
        );
      },
    },
    {
      field: 'seeDetails',
      name: '',
      flex: '1 1 150px',
      customStyles: {
        width: '100%',
        height: '100%',
        justifyContent: 'end',
        alignItems: 'end',
      },
      render: (row: { item: FlatPatient }) => {
        const patient = row.item;
        return <DetailsButton onClick={() => onClickSeeDetails(patient.id)} text="See Details" />;
      },
    },
  ];
}

export function getFormat(
  onClickSeeDetails: (patientId: number) => void,
  onChangeStatus: (
    patientId: number,
    measure: string,
    status: IAllPatientFirstFillStatus | null
  ) => void,
  onChangeHospiceStatus: (patientId: number, newHospiceStatus: boolean) => void
) {
  // ALL MEASURES
  return [
    {
      field: 'name',
      name: `${getStringReplacement('Participant')} Name`,
      flex: '0 0 220px',
      addOns: [{ type: 'sort', element: <SortIcon id="name" key="name" /> }],
    },
    {
      field: 'age',
      name: `Age`,
      flex: '0 0 50px',
      addOns: [{ type: 'sort', element: <SortIcon id="age" key="age" /> }],
    },
    {
      field: 'hospice',
      name: 'Hospice',
      flex: '0 0 60px',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <Checkbox
            checked={patient.hospice}
            onChange={(event) => onChangeHospiceStatus(patient.id, event.target.checked)}
          />
        );
      },
    },
    {
      field: 'patientStatus',
      name: 'Status',
      flex: '0 0 100px',
      className: 'starsAdherence-measureColumn',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-status-${item.measure}-${item.status}`}
                  className="starsAdherence-measureColumn-subRow"
                >
                  <PatientMedicationStatus
                    patient={{ id: patient.id, measure: item.measure, status: item.status }}
                    onChangeStatus={(status) => onChangeStatus(row.item.id, item.measure, status)}
                    tab="first_fill"
                  />
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'measureData',
      name: 'Adherence Measures',
      flex: '0 0 240px',
      className: 'starsAdherence-measureColumn',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-measure-${item.measure}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    width: '275px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {starsMeasureToName[item.measure]}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'medClasses',
      name: 'Medication Classes',
      className: 'starsAdherence-measureColumn',
      flex: '0 0 240px',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-medclass-${item.measure}-${item.medClasses.join(',')}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    width: '275px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  title={item.medClasses.join(', ')}
                >
                  {item.medClasses.map((c) => {
                    return c;
                  })}
                  {item.medClasses.length === 0 && <Text>&nbsp;</Text>}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'fillCount',
      name: '# of Fills',
      flex: '0 0 65px',
      className: 'starsAdherence-measureColumn',
      align: 'end',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-fillcount-${item.measure}-${item.fillCount}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    textAlign: 'right',
                    width: '100px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                  }}
                >
                  {item.fillCount}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'percentageDaysCovered',
      name: 'PDC',
      flex: '0 0 40px',
      className: 'starsAdherence-measureColumn',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-pdc-${item.measure}-${item.percentageDaysCovered}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    width: '75px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                    color: item.percentageDaysCovered < 0.8 ? textColors.error : undefined,
                  }}
                >
                  {`${parseFloat((item.percentageDaysCovered * 100).toFixed(0))}%`}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'priority',
      name: 'Priority',
      flex: '0 0 100px',
      className: 'starsAdherence-measureColumn',
      customStyles: { marginRight: -20 },
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return <div className="starsAdherence-measureColumn-subRow">{item.priority}</div>;
            })}
          </div>
        );
      },
    },
    {
      field: 'gapDays',
      name: `Gap Days`,
      flex: '0 0 70px',
      align: 'end',
      className: 'starsAdherence-measureColumn',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-gapdays-${item.measure}-${item.gapDays}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    textAlign: 'right',
                    width: '105px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                  }}
                >
                  {item.gapDays}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'minCoverageDays',
      name: `Min Coverage Days`,
      flex: '0 0 140px',
      align: 'end',
      className: 'starsAdherence-measureColumn',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-mincoveragedays-${item.measure}-${item.minCoverageDays}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    textAlign: 'right',
                    width: '175px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.minCoverageDays}
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      field: 'adherenceComplianceDate',
      name: `Adherence Compliance Date`,
      className: 'starsAdherence-measureColumn',
      flex: '0 0 210px',
      align: 'end',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div
            style={{
              textAlign: 'right',
              width: '245px', // column width + negative margin
              marginLeft: '-35px', // move left for the border
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-compliancedate-${item.measure}-${item.adherenceComplianceDate}`}
                  className="starsAdherence-measureColumn-subRow"
                >
                  {item.adherenceComplianceDate}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'actionDate',
      name: `Action Date`,
      className: 'starsAdherence-measureColumn',
      flex: '0 0 100px',
      align: 'end',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div
            style={{
              textAlign: 'right',
              width: '135px', // column width + negative margin
              marginLeft: '-35px', // move left for the border
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-actiondate-${item.measure}-${item.actionDate}`}
                  className="starsAdherence-measureColumn-subRow"
                >
                  {item.actionDate}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'fillDueDate',
      name: 'Next fill due',
      className: 'starsAdherence-measureColumn',
      flex: '0 0 180px',
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;
        return (
          <div>
            {patient.measureData.map((item) => {
              return (
                <div
                  key={`${patient.id}-fillduedate-${item.measure}-${item.fillDueDate}`}
                  className="starsAdherence-measureColumn-subRow"
                  style={{
                    width: '215px', // column width + negative margin
                    marginLeft: '-35px', // move left for the border
                    paddingLeft: '35px', // adjust text position
                  }}
                >
                  <NextFillDueColumn fillDueDate={item.fillDueDate} />
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'seeDetails',
      name: '',
      flex: '0 0 150px',
      customStyles: {
        display: 'block',
        float: 'right',
        width: '100%',
        height: '100%',
        justifyContent: 'end',
        alignItems: 'end',
      },
      render: (row: { item: IPatientStarsAdherence }) => {
        const patient = row.item;
        if (!patient) return null;

        return <DetailsButton onClick={() => onClickSeeDetails(patient.id)} />;
      },
    },
  ];
}

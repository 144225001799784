import {
  IAllPatientFirstFillStatus,
  IPatientFirstFillStatus,
  allPatientFirstFillStatus,
  firstFillStatusToColor,
  firstFillStatusToDescription,
} from '@api/polypharmacy/getRisingStars';
import { updateFirstFillStatus } from '@api/polypharmacy/updateFirstFillStatus';
import { useLazyQuery } from '@api/useQuery';
import { Tag, Text } from '@intus-ui';
import { shadesOfBlue } from '@intus-ui/styles/SecondaryColors';
import { ButtonBase, ButtonBaseProps, Menu, MenuItem } from '@mui/material';
import { IAllStarsMeasures } from 'Polypharmacy/types/STARSMeasures';
import React, { FC } from 'react';

type PatientFirstFillStatusProps = {
  patient: {
    id: number;
    measure: IAllStarsMeasures;
    status: IPatientFirstFillStatus | null;
  };
  tab: 'first_fill' | 'adherence' | null;
  onChangeStatus: (newStatus: IAllPatientFirstFillStatus | null) => void;
};

export const PatientMedicationStatus: FC<PatientFirstFillStatusProps> = (props) => {
  const { patient, tab, onChangeStatus } = props;
  const { status } = patient;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = anchorEl != null;

  const onOpenMenu: ButtonBaseProps['onClick'] = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { runQuery } = useLazyQuery(updateFirstFillStatus);

  const updateStatus = (status: string) => {
    setAnchorEl(null);
    runQuery({
      measure: patient.measure,
      measureYear: new Date().getFullYear(),
      patientId: patient.id,
      status: status as IAllPatientFirstFillStatus,
      tab,
    });
    onChangeStatus(status as IAllPatientFirstFillStatus);
  };
  const clearStatus = () => {
    setAnchorEl(null);
    runQuery({
      measure: patient.measure,
      measureYear: new Date().getFullYear(),
      patientId: patient.id,
      status: null,
      tab,
    });
    onChangeStatus(null);
  };

  const text = status == null ? null : firstFillStatusToDescription[status.status];

  const color = status == null ? null : firstFillStatusToColor[status.status];

  let statusDropdownOptions = allPatientFirstFillStatus as readonly IAllPatientFirstFillStatus[];
  // Filter out the patient's current status and 'needs_review' from the dropdown options
  statusDropdownOptions = statusDropdownOptions.filter(
    (s) => s !== status?.status && s !== 'needs_review'
  ) as IAllPatientFirstFillStatus[];

  return (
    <div>
      {text != null && color != null && (
        <ButtonBase onClick={onOpenMenu}>
          <Tag style={styles.tag} color={color} type="intusMade">
            {text.toUpperCase()}
          </Tag>
        </ButtonBase>
      )}
      {status == null && (
        <ButtonBase sx={styles.button} onClick={onOpenMenu}>
          <Text type="caption-bold" style={{ color: '#2E62E7' }}>
            Needs review
          </Text>
        </ButtonBase>
      )}
      {isMenuOpen && (
        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={() => setAnchorEl(null)}>
          {statusDropdownOptions.map((s) => (
            <MenuItem key={s} onClick={() => updateStatus(s)}>
              {firstFillStatusToDescription[s]}
            </MenuItem>
          ))}
          {status != null && (
            <MenuItem key="Clear Status" onClick={clearStatus}>
              Needs review
            </MenuItem>
          )}
        </Menu>
      )}
    </div>
  );
};

const styles = {
  tag: {
    width: 100,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: shadesOfBlue.hoverBlue16,
      color: 'white',
    },
  },
  button: {
    width: 100,
    height: 20,
    whiteSpace: 'nowrap',
    backgroundColor: 'white',
    color: '#2E62E7',
    padding: '0px 8px',
    border: '1px solid #2E62E7',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: shadesOfBlue.hoverBlue16,
      color: 'white',
    },
  },
} satisfies InlineCss;

import React from 'react';
import { Table, Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import { formatDate } from '@util/dateFunctions';
import CarePlanText from './CarePlanText';

import '@intus-ui/styles/Profile.scss';

const ProfileCarePlanTable = ({ problems }) => (
  <div className="d-flex mb-5 pb-5">
    {problems?.length ? (
      <Table borderless className="table-fixed w-100 h-100 mb-5">
        <thead className="bg-white w-100 h-100 border-bottom">
          <tr className="bg-white w-100 h-100">
            <th className="pb-3 px-0 bg-white">
              <h2 className="text-secondary px-0">Care Plan</h2>
            </th>
            <th className="bg-white"> </th>
            <th className="bg-white"> </th>
            <th className="bg-white"> </th>
          </tr>
          <tr className="">
            <th
              className="position-sticky text-muted font-weight-normal py-0 px-0"
              style={{ width: '15%' }}
            >
              <div className="w-100 bg-white border-muted border-top border-bottom py-1">
                <h6 className="pl-2">Status</h6>
              </div>
            </th>
            <th
              className="position-sticky text-muted font-weight-normal py-0 px-0"
              style={{ width: '60%' }}
            >
              <div className="w-100 bg-white border-muted border-top border-bottom py-1">
                <h6 className="pl-2">Details</h6>
              </div>
            </th>
            <th
              className="position-sticky text-muted font-weight-normal py-0 px-0"
              style={{ width: '12.5%' }}
            >
              <div className="w-100 bg-white border-muted border-top border-bottom py-1">
                <h6 className="pl-2">Date</h6>
              </div>
            </th>
            <th
              className="position-sticky text-muted font-weight-normal py-0 px-0"
              style={{ width: '12.5%' }}
            >
              <div className="w-100 bg-white border-muted border-top border-bottom py-1">
                <h6 className="pl-2">Roles</h6>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="pb-5 mb-5 pt-0">
          {problems
            .map((problem) =>
              problem.interventions.map((intervention) => (
                <tr
                  key={`carePlan-${problem.carePlanId}`}
                  className={problems.length > 1 ? 'care-plan-row-border-top' : 'border-none'}
                >
                  <td style={{ padding: '0.75rem' }}>
                    {intervention.status === 'INCOMPLETE' ? (
                      <Badge className="care-plan-incomplete">
                        <h6 className="font-weight-bold">{intervention.status}</h6>
                      </Badge>
                    ) : (
                      <Badge className="care-plan-complete">
                        <h6 className="font-weight-bold">{intervention.status}</h6>
                      </Badge>
                    )}
                  </td>
                  <td style={{ padding: '0.75rem' }}>
                    <CarePlanText type="Problem:" text={problem.description} />
                    <CarePlanText type="Outcome:" text={intervention.outcome} />
                    <CarePlanText type="Intervention:" text={intervention.description} />
                  </td>
                  <td style={{ padding: '0.75rem' }}>
                    <h6 className="text-secondary">{`${formatDate(
                      intervention.date,
                      'MM/DD/YYYY'
                    )}`}</h6>
                  </td>
                  <td style={{ padding: '0.75rem' }}>
                    {intervention.roles.map((role, index) => (
                      <h6 key={`role-${problem.carePlanId}`} className="text-secondary mb-0">
                        {`${index !== 0 ? ', ' : ''}${role}`}
                      </h6>
                    ))}
                  </td>
                </tr>
              ))
            )
            .flat()}
        </tbody>
      </Table>
    ) : (
      <CenteredSpinner className="w-100 h-100" />
    )}
  </div>
);

ProfileCarePlanTable.propTypes = {
  problems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProfileCarePlanTable;

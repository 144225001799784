import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { getLogger } from '@util/logger';
import { useGetSessionUser } from '@util/session';

import { createCohort, editCohort } from '@api';

import { Text, Input, Icon, ButtonGroup, Button, layout } from '@intus-ui';
import { USER_ACCESS_ADMIN, USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import { buildFixedCohortSearchCriteria } from 'AdvancedCohort/SearchCreate/buildFixedCohortSearchCriteria';

const log = getLogger('SearchCreate/CreateCohort');

const CreateCohort = ({ searchState, setSearchState }) => {
  const [form, setForm] = useState({ shared: false });
  const [error, setError] = useState(null);
  const [validated, setValidated] = useState(false);
  const [interventions, setInterventions] = useState([]);
  const [lastInterventionBlank, setLastInterventionBlank] = useState(false);
  const { organizationId, id: userId, access } = useGetSessionUser();
  const { existingCohort, cohortId, fixed } = searchState;
  const history = useHistory();

  const canCreateShared = access === USER_ACCESS_ADMIN || access === USER_ACCESS_HEALTH_SYS_ADMIN;
  // A user can only convert a shared cohort to personal if they created the cohort.
  const isSharedCheckboxEnabled =
    canCreateShared && (existingCohort == null || existingCohort.createdByCurrentUser === true);

  const requiredFields = ['name'];
  const emptyIntervention = { id: uuidv4(), type: '', plan: '' };
  const interventionTypes = [
    'Physical Therapist',
    'Recreation Therapist',
    'Occupational Therapist',
    'Primary Care Provider',
    'Social Work',
    'Nursing',
    'Dietitian',
    'Personal Care Attendant',
    'PACE Center Manager',
    'Home Care Coordinator',
    'Transportation',
    'Other',
  ];

  useEffect(() => {
    if (existingCohort) setForm({ ...existingCohort });
    if (existingCohort?.name && existingCohort?.description) {
      setValidated(true);
    }
    if (existingCohort?.essentialNeeds && existingCohort?.essentialNeeds.length > 0) {
      setInterventions(
        existingCohort.essentialNeeds.map((intervention) => {
          return {
            id: uuidv4(),
            type: intervention.type,
            plan: intervention.plan,
          };
        })
      );
    }
  }, []);

  // VALIDATE FORM
  useEffect(() => {
    let isValid = true;
    for (let i = 0; i < requiredFields.length; i += 1) {
      const isFieldValid = form[requiredFields[i]] && form[requiredFields[i]] !== '';
      if (!isFieldValid) {
        isValid = false;
        break;
      }
    }

    for (let i = 0; i < form?.essentialNeeds?.length; i += 1) {
      const isInterventionValid =
        form.essentialNeeds[i].type &&
        form.essentialNeeds[i].type !== '' &&
        form.essentialNeeds[i].plan &&
        form.essentialNeeds[i].plan !== '';
      if (!isInterventionValid) {
        isValid = false;
        break;
      }
    }

    setValidated(isValid);
  }, [form]);

  // VALIDATE INTERVENTIONS
  useEffect(() => {
    const lastIntervention = interventions[interventions.length - 1];

    const valid =
      !lastIntervention ||
      ((lastIntervention?.type || '').trim().length &&
        (lastIntervention?.plan || '').trim().length);

    setLastInterventionBlank(!valid);
  }, [interventions]);

  const handleInputChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  function getSaveCohortPayload() {
    return {
      name: form.name,
      description: form.description,
      essentialNeeds: form.essentialNeeds,
      searchCriteria: getSearchCriteriaToSave(searchState),
      shared: form.shared,
      fixed: (searchState.fixed || existingCohort?.fixed) ?? false,
    };
  }

  /**
   * Reset the cohort state back to an empty state.
   * We need this or we end up accidentally persisting filters, pinned patients, etc.
   */
  function clearCohort() {
    setSearchState({
      filter: null,
      data: null,
      view: 'SEARCH_CREATE',
    });
  }

  function handleCohortResponseError(response) {
    if (response.status === 409) {
      setError('A cohort with this name already exists. Please choose a different name.');
    } else {
      setError('An error ocurred saving the cohort');
    }
  }

  const handleCreateCohort = async () => {
    const payload = getSaveCohortPayload();
    setError(null);

    let cohortListUrl = form.shared ? '/cohorts/shared' : '/cohorts/personal';
    if (fixed) {
      cohortListUrl = '/cohorts/fixed';
    }

    try {
      const newCohortResponse = await createCohort({ ...payload, userId, organizationId });

      if (!newCohortResponse.ok) {
        handleCohortResponseError(newCohortResponse);
        return;
      }

      const newCohort = await newCohortResponse.json();
      history.push(cohortListUrl, { highlightCohortId: newCohort.id, scrollIntoView: true });
      clearCohort();
    } catch (e) {
      log.error(e);
      setError('An error ocurred saving the cohort');
    }
  };

  const handleUpdateCohort = async () => {
    const payload = getSaveCohortPayload();
    setError(null);

    try {
      const response = await editCohort(cohortId, payload);

      if (!response.ok) {
        handleCohortResponseError(response);
        return;
      }

      const cohortListUrl = form.shared ? '/cohorts/shared' : '/cohorts/personal';
      history.push(`${cohortListUrl}/${cohortId}/details`);
      clearCohort();
    } catch (e) {
      log.error(e);
      setError('An error ocurred saving the cohort');
    }
  };

  const addInterventionRow = () => {
    const newInterventions = [...interventions, { ...emptyIntervention, id: uuidv4() }];
    setInterventions(newInterventions);
  };

  const removeInterventionRow = (targetId) => {
    const newInterventions = interventions.filter((intervention) => {
      return intervention.id !== targetId;
    });
    setInterventions(newInterventions);
    setForm({
      ...form,
      essentialNeeds: newInterventions,
    });
  };

  const handleInterventionChange = (targetId, field, value) => {
    const newInterventions = interventions.map((intervention) => {
      if (intervention.id === targetId) {
        return {
          ...intervention,
          [field]: value,
        };
      }

      return intervention;
    });
    setInterventions(newInterventions);
    setForm({
      ...form,
      essentialNeeds: newInterventions,
    });
  };

  return (
    <div id="adv-cohort-search-and-create-form">
      <div style={styles.quad}>
        <Text type="subtitle">Basic Information</Text>
        {error && (
          <Text type="subtitle" color="error" role="alert">
            {error}
          </Text>
        )}
        {/* Label + Text Field */}
        <div style={styles.formField}>
          <Text type="body" style={styles.formLabel}>
            Cohort Name
          </Text>
          <Input
            id="name"
            name="name"
            onChange={(value) => handleInputChange('name', value)}
            placeholder="Cohort Name"
            required
            type="text"
            value={form.name}
            style={styles.formTextInput}
          />
        </div>
        {/* Label + Text Field */}
        <div style={styles.formField}>
          <Text type="body" style={styles.formLabel}>
            Description (optional)
          </Text>
          <Input
            id="description"
            name="description"
            onChange={(value) => handleInputChange('description', value)}
            placeholder="Description  (optional)"
            required
            type="text"
            value={form.description}
            style={styles.formTextInput}
          />
        </div>
        {/* Checkbox + Label */}
        <div style={styles.formCheckboxField}>
          {/* NOTE: Checkboxes ignore borderRadius!! */}
          <input
            disabled={!isSharedCheckboxEnabled}
            type="checkbox"
            id="shared"
            name="shared"
            onChange={({ target }) => handleInputChange('shared', target.checked)}
            checked={form.shared}
            value={form.shared}
            style={styles.formCheckboxInput}
          />
          <Text
            disabled={!isSharedCheckboxEnabled}
            onClick={() => handleInputChange('shared', form.shared ? !form.shared : true)}
            type="body"
          >
            Make this a shared cohort
          </Text>
        </div>
        {/* Rows of Interventions */}
        <div style={styles.formSection}>
          <Text type="subtitle">Plan for Care</Text>
          {interventions.map((intervention, index) => (
            <div key={JSON.stringify(index)} style={styles.interventionRow}>
              <div style={{ ...styles.formField, ...styles.formFieldDropdown }}>
                <Text type="body" style={styles.formLabel}>
                  Role
                </Text>
                <Input
                  id={`iv-${intervention.id}-type`}
                  name={`iv-${intervention.id}-type`}
                  type="select"
                  options={interventionTypes}
                  placeholder="Role"
                  value={intervention.type}
                  onChange={(value) => handleInterventionChange(intervention.id, 'type', value)}
                  rightIcon="caret-down"
                  style={styles.formTextInput}
                />
              </div>
              <div style={{ ...styles.formField, flexGrow: 11, flexShrink: 11 }}>
                <Text type="body" style={styles.formLabel}>
                  Intervention
                </Text>
                <Input
                  id={`iv-${intervention.id}-plan`}
                  name={`iv-${intervention.id}-plan`}
                  type="text"
                  required
                  placeholder="Intervention"
                  value={intervention.plan}
                  onChange={(value) => handleInterventionChange(intervention.id, 'plan', value)}
                  style={styles.formTextInput}
                />
              </div>
              {interventions.length >= 1 && (
                <Button
                  onClick={() => removeInterventionRow(intervention.id)}
                  style={{ padding: 0 }}
                  textOnly
                >
                  <Icon name="remove" color="#9D9D9D" hoverColor="#9D9D9D" />
                </Button>
              )}
            </div>
          ))}
          <div style={styles.formSection}>
            <Button
              disabled={lastInterventionBlank}
              hoverText="Add Intervention"
              name="+ add intervention"
              onClick={addInterventionRow}
              style={styles.interventionAddButton}
              secondary
            />
          </div>
        </div>
        {/* Submit Button Row */}
        <ButtonGroup>
          <Button
            disabled={!validated}
            hoverText={existingCohort ? 'Save Changes' : 'Save Cohort'}
            name={existingCohort ? 'Save Changes' : 'Save Cohort'}
            onClick={existingCohort ? handleUpdateCohort : handleCreateCohort}
            style={{ margin: 0 }}
          />
        </ButtonGroup>
      </div>
    </div>
  );
};

const styles = {
  formCheckboxField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
  },
  formCheckboxInput: {
    width: '21px',
    height: '22px',
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  formFieldDropdown: {
    width: '225px',
  },
  formLabel: {
    fontSize: '80%',
  },
  formSection: {
    paddingTop: '10px',
  },
  formTextInput: {
    width: '100%',
  },
  interventionRow: {
    ...layout.rowContainer,
    gap: '10px',
    alignItems: 'end',
    paddingTop: '10px',
    overflow: 'visible',
  },
  interventionAddButton: {
    margin: 0,
  },
  quad: {
    ...layout.quad,
    overflow: 'visible',
    padding: '20px 30px 15px 30px',
  },
};

export default CreateCohort;

function getSearchCriteriaToSave(searchState) {
  // For a fixed cohort, we ignore the chosen filters since they are only used to limit the participants that can be selected.
  // We make a special search criteria that just lists out the patientIds the user has selected.
  if (searchState.fixed === true) {
    return buildFixedCohortSearchCriteria(searchState);
  }

  return searchState.searchCriteria;
}

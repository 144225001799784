import { buildPatientFilterQueryString } from '@api/patients/buildPatientFilterQueryString';
import { getSessionUser } from '@util/session';

class CohortMapper {
  static apiToDomain(apiCohort) {
    return {
      id: apiCohort?.id,
      name: apiCohort?.name,
      essentialNeeds: apiCohort?.essentialNeeds,
      description: apiCohort?.description,
      shared: apiCohort?.shared,
      searchCriteria: {
        operator: 'AND',
        conditions: makeSearchCriteriaConditions(apiCohort.searchCriteria),
      },
      isShared: apiCohort?.isShared,
      result: {
        count: apiCohort?.count,
        participantIds: apiCohort?.patientIds,
        participantIdsAdded: apiCohort?.addedPatientIds,
        participantIdsRemoved: apiCohort?.removedPatientIds,
      },
    };

    function makeSearchCriteriaConditions() {
      const isSingleCondition = !Array.isArray(apiCohort.searchCriteria.conditions);

      return isSingleCondition ? [apiCohort.searchCriteria] : apiCohort.searchCriteria.conditions;
    }
  }

  static domainToApi(domainCohort) {
    const { organizationId, id: userId } = getSessionUser();

    const result = {
      id: domainCohort?.id,
      name: domainCohort?.name,
      description: domainCohort?.description,
      essentialNeeds: domainCohort?.essentialNeeds,
      shared: domainCohort?.shared,
      organizationId,
      userId,
      searchCriteria: formatSearchCriteria(),
    };

    delete result.organizationId;

    return result;

    function formatSearchCriteria() {
      const sanitizedConditions = domainCohort.searchCriteria?.conditions.map((c) => {
        const r = { ...c };
        delete r.id;
        return r;
      });

      const isSingleCondition = sanitizedConditions.length <= 1;

      if (isSingleCondition) {
        return sanitizedConditions[0];
      }

      const sanitizedSearchCriteria = {
        operator: domainCohort.searchCriteria.operator,
        conditions: sanitizedConditions,
      };

      return sanitizedSearchCriteria;
    }
  }

  static formatFilterParams(args) {
    if (args?.filters == null) return '';

    return buildPatientFilterQueryString(args.filters);
  }
}

const CohortsApi = {
  tagTypes: ['Cohort'],
  endpoints: (builder) => ({
    getCohortSearchTerms: builder.query({
      query: () => 'cohorts/searchTerms',
    }),

    getCohorts: builder.query({
      query: (args) => {
        return `cohorts?${CohortMapper.formatFilterParams(args)}`;
      },
      transformResponse: (apiCohorts) => {
        return apiCohorts.map((apiCohort) => CohortMapper.apiToDomain(apiCohort));
      },
      providesTags: (result = []) => [
        'Cohort',
        ...result.map((cohort) => ({ type: 'Cohort', id: cohort.id })),
      ],
    }),

    getActionCohorts: builder.query({
      query: (args) => `cohorts/billingCohorts?${CohortMapper.formatFilterParams(args)}`,
      transformResponse: (apiCohorts) => {
        return apiCohorts.map((apiCohort) => CohortMapper.apiToDomain(apiCohort));
      },
      providesTags: (result = []) => [
        'Cohort',
        ...result.map((cohort) => ({ type: 'Cohort', id: cohort.id })),
      ],
    }),

    getHomeCohorts: builder.query({
      query: (args) => `cohorts/homeCohorts?${CohortMapper.formatFilterParams(args)}`,
      transformResponse: (apiCohorts) => {
        return apiCohorts.map((apiCohort) => CohortMapper.apiToDomain(apiCohort));
      },
      providesTags: (result = []) => [
        'Cohort',
        ...result.map((cohort) => ({ type: 'Cohort', id: cohort.id })),
      ],
    }),

    getCohortParticipantIds: builder.query({
      query: (args) => {
        const body = makeBody();

        return {
          url: `cohorts/search?${CohortMapper.formatFilterParams(args)}`,
          method: 'POST',
          body,
        };

        function makeBody() {
          // Different expected body shape for single criteria when calling API
          if (args.searchCriteria.length === 1) {
            return { searchCriteria: args.searchCriteria[0] };
          }
          return {
            searchCriteria: {
              operator: 'AND',
              conditions: args.searchCriteria,
            },
          };
        }
      },
      transformResponse: (res) => res.patientIds,
    }),

    addCohort: builder.mutation({
      query: (cohort) => {
        const url = `cohorts/`;
        return {
          url,
          method: 'POST',
          body: CohortMapper.domainToApi(cohort),
        };
      },
      invalidatesTags: ['Cohort'],
    }),

    updateCohort: builder.mutation({
      query: (cohort) => ({
        url: `cohorts/${cohort.id}`,
        method: 'PUT',
        body: CohortMapper.domainToApi(cohort),
      }),
      invalidatesTags: (_, __, arg) => [{ type: 'Cohort', id: arg.id }],
    }),

    deleteCohort: builder.mutation({
      query: (cohortId) => ({
        url: `cohorts/${cohortId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, arg) => [{ type: 'Cohort', id: arg.id }],
    }),
  }),
};

export default CohortsApi;

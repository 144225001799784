import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import RoomIcon from '@mui/icons-material/Room';
import { useFeatureFlag } from '@util/index';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import TextData from 'Dashboard/QuadContent/TextData';
import '@intus-ui/styles/Profile.scss';
import '@intus-ui/styles/Utilities.scss';
import Quad from 'Dashboard/Quad';
import { admissionLength } from '@util/admissionType';
import SimpleTooltip from '@intus-ui/components/SimpleTooltip';
import { formatDate } from '@util/dateFunctions';
import HospIcon from './lengthIcon';
import AdmitBadge from './admissionBadge';
import TruncList from './TruncList';
import { copyToClipAndMakeDoc } from './makeRCA';

const riskTooltip = () => (
  <div className="p-2 d-flex flex-column align-items-start justify-content-start">
    <h6 className="text-primary">
      The risk score is the participant&#39;s most recent acuity score before each hospitalization
    </h6>
  </div>
);

const RiskScoreInfo = (
  <div className="d-flex align-items-center justify-content-center">
    <span>Risk Score</span>
    <SimpleTooltip className="d-inline-block" content={riskTooltip()}>
      <InfoIcon variant="success" className="info-icon cursor-pointer p-0" />
    </SimpleTooltip>
  </div>
);

const lengthColor = (length) => {
  if (length === null) {
    return admissionLength.UNKNOWN.color;
  }
  if (length <= admissionLength.SHORT.max_cutoff) {
    return admissionLength.SHORT.color;
  }
  if (length > admissionLength.MEDIUM.min_cutoff && length <= admissionLength.MEDIUM.max_cutoff) {
    return admissionLength.MEDIUM.color;
  }
  return admissionLength.LONG.color;
};

const DetailCard = ({ info, patientDetail, className }) => {
  const { featureIsActive: RCAButtonIsActive } = useFeatureFlag('rca-button');
  return (
    <div>
      {info ? (
        <Container
          fluid
          className={`pb-5 px-0 m-0 d-flex w-100 flex-column justify-content-center align-items-center ${className}`}
        >
          <Row className="d-flex flex-row align-items-center justify-content-start py-3 w-100">
            <Col className="d-flex flex-row justify-content-start align-items-center">
              <HospIcon stayLength={info.lengthOfStay} />
              <h5 className="pl-2 py-0 font-weight-bold text-dark">
                {`${formatDate(info.startDate, 'MMMM DD, YYYY')}-${
                  info.endDate ? formatDate(info.endDate, 'MMMM DD, YYYY') : 'Unknown'
                }`}
              </h5>
            </Col>
            <Col className="d-flex flex-row justify-content-end align-items-center">
              {RCAButtonIsActive && (
                <button
                  type="button"
                  className="btn mx-l btn-primary justify-content-end align-items-end justify-content-right align-items-right"
                  onClick={() => copyToClipAndMakeDoc(info, patientDetail)}
                >
                  Download as RCA template
                </button>
              )}
            </Col>
          </Row>

          <Row className="d-flex flex-grow-1 flex-row justify-content-center align-content-center w-100">
            <Col md={3} className="h-100">
              <Quad title="Length of Stay">
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center pb-4">
                  <TextData
                    textColor={lengthColor(info.lengthOfStay)}
                    data={
                      ![null, undefined].includes(info.lengthOfStay) ? info.lengthOfStay : 'N/A'
                    }
                  />
                  <h5>days</h5>
                </div>
              </Quad>
            </Col>
            <Col md={3} className="h-100">
              <Quad title={RiskScoreInfo}>
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center pb-4">
                  {/* data is not there yet */}
                  <TextData
                    textColor="#052d8f"
                    data={info.riskScore || info.riskScore === 0 ? info.riskScore : 'N/A'}
                  />
                  <h5>Score</h5>
                </div>
              </Quad>
            </Col>
            <Col md={6}>
              <div className="h-100 bg-white box-shadow-on-gray px-5 rounded-lg py-4">
                <Row className="d-flex flew-row justify-content-between align-items-start pb-4">
                  <Col
                    xs={5}
                    className="p-0 d-flex flex-column justify-content-start align-items-start"
                  >
                    <div className="text-secondary font-weight-bold">Principal DX: </div>
                    <TruncList
                      strArr={info.formattedDxs}
                      num={1}
                      errorMessage="No Principal DX listed."
                    />
                  </Col>
                  <Col xs={4} className="p-0 d-flex justify-content-start align-items-start">
                    <div className="d-flex flex-row align-items-start justify-content-start">
                      <h6 className="text-secondary pr-2">
                        <RoomIcon fontSize="small" />
                      </h6>
                      <p className="text-secondary">
                        {info.placeOfStay ? info.placeOfStay : 'N/A'}
                      </p>
                    </div>
                  </Col>
                  <Col xs={3} className="p-0 d-flex justify-content-end align-items-start">
                    <AdmitBadge
                      admission={info.admissionTypeDesc ? info.admissionTypeDesc : 'N/A'}
                    />
                  </Col>
                </Row>
                <Row className="d-flex flex-column align-items-start">
                  <h6 className="text-secondary font-weight-bold">Discharge: </h6>
                  <h6>
                    {info.dischargeStatusDesc
                      ? info.dischargeStatusDesc
                      : ' Information not available'}
                  </h6>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <CenteredSpinner className="w-100 h-100" />
      )}
    </div>
  );
};

DetailCard.propTypes = {
  info: PropTypes.shape({
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    lengthOfStay: PropTypes.number,
    readmissionScore: PropTypes.number,
    principalDX: PropTypes.string,
    formattedDxs: PropTypes.arrayOf(PropTypes.string),
    dischargeStatusDesc: PropTypes.string,
    placeOfStay: PropTypes.string,
    admissionTypeDesc: PropTypes.string,
    riskScore: PropTypes.number,
    livingSituation: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

DetailCard.defaultProps = {
  className: '',
};

export default DetailCard;

import { quadColors } from '@intus-ui/styles/SecondaryColors';

const DropdownList = ({ styles, children }) => (
  <div
    className="dd-list"
    style={{
      position: 'absolute',
      backgroundColor: 'white',
      border: 'solid 1px',
      borderColor: quadColors.disabled,
      right: 0,
      overflowY: 'auto',
      maxHeight: 200,
      zIndex: 2,
      ...styles,
    }}
  >
    {children}
  </div>
);

export default DropdownList;

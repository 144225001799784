import { getIcdCodes } from '@api/api';
import { isEmpty } from 'lodash';

/**
 * Creates an alphabetically sorted list of description strings for provided icdCodes.
 * Invalid/Undefined codes will be indicated as such.
 * @param {Array<String>} codes - codes to retrieve descriptions for
 */
export const getIcdCodeDescriptions = async (codes) => {
  const validCodeEntries = await getIcdCodes(codes);
  const validCodes = validCodeEntries.map((o) => o.code);
  const validDescriptions = validCodeEntries.map((o) => o.description);

  const invalidCodes = codes.filter((code) => !validCodes.includes(code));
  const invalidDescriptions = invalidCodes.map((code) => `Invalid/nonspecific icd10 code: ${code}`);

  return validDescriptions.sort().concat(invalidDescriptions);
};

/**
 * Creates an array of code objects for provided icdCodes.
 * Fills in missing codes with blank meta data fields.
 * A default description indicating that missing codes are invalid is also added.
 * @param {Array<String>} codes - codes to retrieve
 */
export const getFilledInIcdCodes = async (codes) => {
  const emptyRow = {
    code: '',
    description: null,
    ccsrCategoryIP: null,
    ccsrCategoryIPDescription: null,
    ccsrCategoryOP: null,
    ccsrCategoryOPDescription: null,
    fallsRiskFactors: null,
    potentiallyAvoidableHospitalization: null,
    invalidated: 'false',
  };

  const validCodeEntries = await getIcdCodes(codes);
  const validCodes = validCodeEntries.map((o) => o.code);

  const invalidCodesTags = codes.filter((code) => !validCodes.includes(code));
  const invalidCodeEntries = invalidCodesTags.map((code) => {
    return {
      ...emptyRow,
      code,
      description: isEmpty(code)
        ? 'No diagnosis entered'
        : `Invalid/nonspecific icd10 code: ${code}`,
      invalidated: 'true',
    };
  });

  return validCodeEntries.concat(invalidCodeEntries);
};

import PropTypes from 'prop-types';

import Text from '@intus-ui/components/Text/index';
import { FallsIncidentCard } from './FallsIncidentCard';

import patientIncidentPropType from '../../patientDetail/patientIncidentPropType';

import * as styles from './styles';
import '@intus-ui/styles/Profile.scss';

const FallsTab = ({ incidents }) => {
  if (!incidents.length)
    return (
      <div style={styles.sidebarContentContainer}>
        <Text type="body" color="caption">
          No Incidents
        </Text>
      </div>
    );
  return (
    <div style={{ ...styles.sidebarContentContainer, gap: 20 }}>
      {incidents.map((incident, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FallsIncidentCard incident={incident} key={index} />
      ))}
    </div>
  );
};
FallsTab.propTypes = {
  incidents: PropTypes.arrayOf(patientIncidentPropType),
};

FallsTab.defaultProps = {
  incidents: [],
};

export default FallsTab;

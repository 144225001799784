/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';
import userSettingsPropType from 'Settings/userSettingsPropType';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import { FallsPrevPropType } from 'Homes/modalPropTypes/fallsPreviewPropType';
import AvgFalls from './FallsAB/AvgFalls';
import FallsCount from './FallsAB/FallsCount';

import '@intus-ui/styles/Utilities.scss';

const FallsPreview = ({ content, error, userSettings }) => (
  <div className="w-100 m-0 h-100 d-flex flex-column justify-content-center align-items-center">
    {userSettings?.profile && content ? (
      <>
        <Row className="w-100 h-100 overflow-scroll">
          <AvgFalls content={content} error={error} />
          <FallsCount content={content} error={error} />
        </Row>
        <Row
          className="w-100 d-flex flex-row justify-content-end bg-transparent"
          style={{ zIndex: 1 }}
        >
          <p className="text-secondary align-self-end px-2 py-0 rounded-lg mb-2 bg-info-tint-20 mr-2 mt-0">
            {content &&
            content.daysLeftInQuarter !== undefined &&
            content.daysLeftInQuarter !== null
              ? `${content.daysLeftInQuarter} days left in quarter`
              : 'Could not fetch data'}
          </p>
        </Row>
      </>
    ) : (
      <SpinnerOrError error={error} />
    )}
  </div>
);

FallsPreview.propTypes = {
  content: FallsPrevPropType,
  error: PropTypes.string,
  userSettings: userSettingsPropType,
};

FallsPreview.defaultProps = {
  error: '',
  content: undefined,
  userSettings: undefined,
};

const mapState = (state) => ({
  error: state.homepage.homeError,
  userSettings: state.userSettings,
});

const mapDispatch = () => ({});

export default connect(mapState, mapDispatch)(FallsPreview);

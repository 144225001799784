import { post } from '@api/apiMethods';
import generateTrackingInfo from 'Login/generateTrackingInfo';
import { LoggedInUser } from 'types/user/loggedInUser';

export function ssoRedirect(email: string, code: string, clientInfo: string) {
  return post<LoggedInUser>(
    'users/sso-redirect',
    { email, code, clientInfo, trackingInfo: generateTrackingInfo() },
    true
  );
}

import { textColors } from '@intus-ui/styles/SecondaryColors';
import Text from '../Text';

const Flag = ({ content, bgColor = textColors.link, textColor = 'white', style }) => {
  return (
    <div style={{ ...styles.flag, backgroundColor: bgColor, ...style }}>
      <Text type="caption-bold" color={textColor} style={{ ...styles.flagContent }}>
        {content}
      </Text>
    </div>
  );
};

const styles = {
  flag: {
    backgroundColor: textColors.link,
    padding: '5px 8px',
    borderRadius: 3,
    lineHeight: 0,
    width: 'fit-content',
  },
  flagContent: {
    fontWeight: 'bold',
    lineHeight: '1em',
  },
};

export default Flag;

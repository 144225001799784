import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, SpinnerOrError, Tag, Text } from '@intus-ui';
import { getNoteOptions, updateChecklist } from '@api/admitReviewNotes';
import { useLazyQuery, useQuery } from '@api/useQuery';
import { formatDate } from '@util/dateFunctions';
import { statusToColor } from 'Admits/tables';
import { logEvent } from '@util/userTracking';
import { ChecklistResponse, KeyLabel, NoteOptions, ReviewNoteResponseData } from './types';
import { NO_NEXT_STEPS_STRING } from './consts';
import { AdmitCard } from './AdmitCard';

type ReadOnlyViewProps = {
  admit: any;
  compositeKey: string;
  arnData: ReviewNoteResponseData;
  setEditMode: Dispatch<SetStateAction<boolean>>; // what is the correct way to type this ?
};

// TODO: add last edited by and time with types
export const ReadOnlyView = ({ admit, compositeKey, arnData, setEditMode }: ReadOnlyViewProps) => {
  const { nextSteps, preventionPoints, notes, checklist, status } = arnData;
  const [checklistState, setChecklistState] = useState<ChecklistResponse>({ checklist });
  const {
    data,
    // loading: noteOptionsLoading,
    // error: noteOptionsError,
  } = useQuery(() => getNoteOptions());

  const { data: checklistResponse, runQuery } = useLazyQuery((body) => {
    return updateChecklist({ compositeKey, updatedChecklist: body });
  });
  const noteOptions = data as NoteOptions;
  useEffect(() => {
    if (checklistResponse != null) setChecklistState(checklistResponse as ChecklistResponse);
  }, [checklistResponse]);

  const selectedNextSteps: KeyLabel[] = useMemo(() => {
    const nextStepsMapped = nextSteps.map((item) => {
      if (item.includes('other'))
        return {
          key: item,
          label: item
            .split('|')[1] // split at bar
            ?.trim(),
        };
      return noteOptions?.nextSteps.find((option) => option.key === item) as KeyLabel;
    });
    return nextStepsMapped;
  }, [noteOptions, nextSteps]);

  // Format any 'other' prevention points
  const selectedPreventionPoints = useMemo(() => {
    return preventionPoints.map((item) => {
      if (item.includes('other'))
        return item
          .split('|')[1] // split at bar
          ?.trim();
      return noteOptions?.preventionPoints?.find((option) => option.key === item)?.label;
    });
  }, [noteOptions, preventionPoints]);

  // check if a next step or required step is completed
  const isChecked = (field: 'nextSteps' | 'requiredSteps', key: string): true | false => {
    if (!checklistState || key == null) return false;

    const checklistItem =
      checklistState.checklist &&
      checklistState.checklist[field]?.find((item: any) => item.key === key);
    if (checklistItem?.completed === true) return true;
    return false;
  };

  if (selectedNextSteps == null) {
    return <SpinnerOrError />;
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        padding: ' 0px 0px 10px 15px',
        gap: 20,
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflowY: 'auto',
          gap: 20,
          paddingRight: '15px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, marginTop: '10px' }}>
          <Tag color={statusToColor[status! as keyof typeof statusToColor] || 'grey'}>{status}</Tag>
          <AdmitCard admit={admit} />
        </div>
        <div
          style={{
            display: 'flex',
            gap: 5,
            flexDirection: 'column',
            backgroundColor: '#FCD5C7',
            padding: '10px',
            border: '1px solid #D4420C',
            borderRadius: '10px',
          }}
        >
          <Text type="subtitle">Required Steps </Text>
          {noteOptions?.requiredSteps?.map((step) => (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Checkbox
                checked={isChecked('requiredSteps', step?.key)}
                onChange={(val) => {
                  const updatedCheckbox = { key: step?.key, checked: val };
                  if (updatedCheckbox.key) runQuery(updatedCheckbox);
                }}
              />
              <Text>{step?.label}</Text>
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              gap: 5,
              flexDirection: 'column',
            }}
          >
            <Text type="subtitle">Next Steps</Text>

            {selectedNextSteps.length > 0 &&
              selectedNextSteps.find((item) => item?.key === NO_NEXT_STEPS_STRING) && (
                <Text>No next steps.</Text>
              )}
            {selectedNextSteps.length > 0 &&
              !selectedNextSteps.find((item) => item?.key === NO_NEXT_STEPS_STRING) &&
              selectedNextSteps?.map((step) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Checkbox
                    checked={isChecked('nextSteps', step?.key)}
                    onChange={(val) => {
                      const updatedCheckbox = { key: step?.key, checked: val };
                      if (updatedCheckbox.key) runQuery(updatedCheckbox);
                    }}
                  />
                  <Text>{step?.label}</Text>
                </div>
              ))}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 5,
            flexDirection: 'column',
          }}
        >
          <Text type="subtitle">What was preventable about this admit? </Text>

          {selectedPreventionPoints.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#E4ECFF',
                border: '1px solid #BACEFF',
                borderRadius: '10px',
                padding: '10px 10px 0px 10px',
                gap: 2,
              }}
            >
              <ul style={{ padding: '0px 20px', height: 'fit-content', listStyleType: 'none' }}>
                {selectedPreventionPoints.map((point) => (
                  <li style={{ position: 'relative', marginTop: '2px' }}>
                    <span style={{ position: 'absolute', left: '-15px', top: 0 }}>•</span>
                    <Text type="caption">{point}</Text>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <Text>Please add prevention points to complete this note.</Text>
          )}
        </div>

        <div style={{ display: 'flex', width: '100%', overflow: 'wrap' }}>
          <Text style={{ maxWidth: '100%', wordWrap: 'break-word', hyphens: 'auto' }}>
            {notes.length ? notes : 'No notes were documented'}
          </Text>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingRight: '15px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text type="caption" color="caption">
            Last edited by {arnData.lastEditedBy.firstName} {arnData.lastEditedBy.lastName} at
          </Text>
          <Text type="caption" color="caption">
            {formatDate(arnData.lastEditedAt, 'h:mma MM/DD/YY')}
          </Text>
        </div>
        <Button
          onClick={() => {
            logEvent('PFV Admits: View ARN: Clicked "Edit" button.');
            setEditMode(true);
          }}
        >
          Edit
        </Button>
      </div>
    </div>
  );
};

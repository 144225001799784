import React from 'react';
import { containerColors } from '@intus-ui/styles/SecondaryColors';

const Container = ({ bgColor, children, style, ...props }) => {
  return (
    <div style={{ ...style, backgroundColor: containerColors[bgColor] }} {...props}>
      {children}
    </div>
  );
};

export default Container;

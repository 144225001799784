import { useTwoFactorResetMutation } from '@api/index';
import Button from '@intus-ui/components/Buttons/Button';
import React from 'react';
import { useSelector } from 'react-redux';

const ResetTwoFactor = () => {
  const selectedCaregiver = useSelector((state) => state.caregiverList.selectedCaregiver);
  const [reset, resetResult] = useTwoFactorResetMutation();
  const canReset = ['CAREGIVER', 'ADMIN'].includes(selectedCaregiver.access);
  return (
    <div className="d-flex flex-column">
      <Button
        onClick={() => reset(selectedCaregiver.id)}
        className="m-0"
        disabled={resetResult.isLoading || !canReset}
      >
        Reset Two-factor Authentication
      </Button>
      {!canReset && (
        <div className="mt-3 text-muted">
          Two-factor authentication can only be reset for caregivers and admins.
        </div>
      )}
      {resetResult.isSuccess && (
        <div className="mt-3 text-primary">Successfully reset two-factor authentication!</div>
      )}
    </div>
  );
};

export default ResetTwoFactor;

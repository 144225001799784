import { getLogger } from '@util/logger';
import {
  clearSession,
  getSessionToken,
  redirectToMaintenance,
  validateSession,
} from '@util/session';

const log = getLogger('@api/api');

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

const getAuthHeaders = () => {
  validateSession();
  return {
    'x-auth-token': `Bearer ${getSessionToken()}`,
    ...DEFAULT_HEADERS,
  };
};

// Fetch doesn't support generics to make `response.json()` strongly typed.
// Make a slightly different TypeScript type that supports this.
export type FetchResponse<TReturn> = Omit<Response, 'json'> & {
  json: () => Promise<TReturn>;
};

type ExtraFetchOptions = {
  /**
   * If true, do not call `encodeURI` on the URL which is bad practice.
   * This is critical to use if a text string in the URL can have an `&` symbol character in it since it's impossible to encode that with `encodeURI`.
   * E.x. if an org has a Team like `East&West` then the URL will become `/teams=East&West` instead of `/teams=East%26West` and break all query string parsing.
   */
  doNotEncodeURL?: boolean;
};

async function fetchFromAPI<TReturn>(
  url: string,
  options: RequestInit & ExtraFetchOptions
): Promise<FetchResponse<TReturn>> {
  let makeUrl: string;

  if (options?.doNotEncodeURL === true) {
    makeUrl = API_BASE_URL + url;
  } else {
    makeUrl = encodeURI(API_BASE_URL + url);
  }
  const result = await fetch(`${makeUrl}`, options);
  (log as any).http(result);

  if (result.status === 503) redirectToMaintenance(window.location.pathname);

  if (result.status === 403 || result.status === 401) {
    // This means the token has expired. Kick them out!
    if (url !== 'users/login' && url !== 'users/sso-redirect') {
      clearSession();
    }
  }
  return result;
}

/**
/**
 * Does not encode the URL in the GET request to allow the calling code to properly encode the URL.
 * This prevents bugs with Team names like `East&West` from breaking the URL since `encodeURI` cannot encode these correctly.
 */
export function get<TReturn>(url: string, noAuth = false) {
  return fetchFromAPI<TReturn>(url, {
    headers: !noAuth ? getAuthHeaders() : DEFAULT_HEADERS,
    method: 'GET',
    doNotEncodeURL: true,
  });
}

export function post<TReturn>(url: string, body: Record<string, any> = {}, noAuth = false) {
  return fetchFromAPI<TReturn>(url, {
    headers: !noAuth ? getAuthHeaders() : DEFAULT_HEADERS,
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function put<TReturn>(url: string, body: Record<string, any> = {}, noAuth = false) {
  return fetchFromAPI<TReturn>(url, {
    headers: !noAuth ? getAuthHeaders() : DEFAULT_HEADERS,
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export function deleteAPI<TReturn>(url: string, body: Record<string, any> = {}, noAuth = false) {
  return fetchFromAPI<TReturn>(url, {
    headers: !noAuth ? getAuthHeaders() : DEFAULT_HEADERS,
    method: 'DELETE',
    body: JSON.stringify(body),
  });
}

import { useRef } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { setSessionUser } from '@util/session';

import { getUserInfo } from '@global-state/redux/userSettingsSlice';
import { saveConnectionInfo } from '@api';

import { Form, Col } from 'react-bootstrap';
import { Text } from '@intus-ui';
import FormikFormControl from '@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';
import SettingsBox from './SettingsBox';

const schema = Yup.object().shape({
  connectionUsername: Yup.string().required('Username is required'),
  connectionPassword: Yup.string().required('Password is required'),
  // termsReadAndAgreedTo: Yup.boolean().oneOf([true], 'You must read and agree to the conditions'),
});

const ConnectionInfoBox = ({ profile }) => {
  const { connectionInfoSet } = profile;
  let hasConnectionInfo = connectionInfoSet;
  const resetFormRef = useRef();

  const safeResetForm = () => {
    if (resetFormRef.current) {
      resetFormRef.current();
    }
  };
  return (
    <SettingsBox title="EHR Connection Info" cancel={safeResetForm}>
      {({ editable, setEditable }) => (
        <Formik
          initialValues={{ termsReadAndAgreedTo: false }}
          validateOnChange
          onSubmit={(values) => {
            saveConnectionInfo({
              username: values.connectionUsername,
              password: values.connectionPassword,
              termsReadAndAgreedTo: values.termsReadAndAgreedTo,
            });
            if (!connectionInfoSet) {
              setSessionUser({ ...profile, connectionInfoSet: true });
              hasConnectionInfo = true;
            }
            safeResetForm();
            setEditable(false);
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, submitForm, resetForm, errors }) => {
            resetFormRef.current = resetForm;
            return (
              <Form onSubmit={handleSubmit}>
                {editable ? (
                  <>
                    <Form.Row>
                      <FormikFormControl
                        as={Col}
                        md="12"
                        label="Username"
                        type="text"
                        name="connectionUsername"
                        placeholder={
                          hasConnectionInfo
                            ? 'Change Connection Username'
                            : 'Set Connection Username'
                        }
                        disabled={!editable}
                      />
                    </Form.Row>
                    <Form.Row>
                      <FormikFormControl
                        as={Col}
                        md="12"
                        label="Password"
                        type="password"
                        name="connectionPassword"
                        placeholder={
                          hasConnectionInfo
                            ? 'Change Connection Password'
                            : 'Set Connection Password'
                        }
                        disabled={!editable}
                      />
                    </Form.Row>
                    {/* <Form.Row>
                      <Button textOnly style={{ paddingLeft: 5, marginBottom: 10 }}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://intuscareinc-my.sharepoint.com/:b:/g/personal/alex_intus_care/Ec7K7nSQFGFGnS7ctqLoFM8Boa_OiRvnb_iyXv_sCrvQwg?e=iq2R1A"
                        >
                          EHR Connection Terms & Conditions
                        </a>
                      </Button>
                    </Form.Row>
                    <Form.Row style={{ paddingLeft: 5 }}>
                      <FormikFormControl
                        as={Col}
                        md="12"
                        type="checkbox"
                        name="termsReadAndAgreedTo"
                        disabled={!editable}
                        label="I have read and accepted the EHR Connection Terms and Conditions"
                      />
                    </Form.Row> */}
                    <SettingsBox.Buttons
                      cancel={() => {
                        resetForm();
                        setEditable(false);
                      }}
                      submit={submitForm}
                      disabled={Object.values(errors).length > 0}
                    />
                  </>
                ) : (
                  <Text>
                    EHR Connection Info{' '}
                    <Text type="subtitle">{hasConnectionInfo ? 'set' : 'not set'}</Text>
                  </Text>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </SettingsBox>
  );
};

ConnectionInfoBox.propTypes = {
  profile: PropTypes.instanceOf(Object),
};

ConnectionInfoBox.defaultProps = {
  profile: undefined,
};

const mapState = (state) => ({
  userSettings: state.userSettings,
});

const mapDispatch = (dispatch) => ({
  doGetUserLocalData: () => dispatch(getUserInfo()),
});

export default connect(mapState, mapDispatch)(ConnectionInfoBox);

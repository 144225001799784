import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { getLogger } from 'loglevel';
import { logEvent } from '@util/userTracking';

import { useLazyQuery } from '@api/useQuery';
import { checkForSSO } from '@api/login/checkForSSO';
import { loginUser } from '@api/login/loginUser';
import { sendPasswordResetEmail } from '@api/login/forgotPassword';

import { TextField } from '@mui/material';
import { Button, Icon, Text } from '@intus-ui';
import BoxOnBlue from '@intus-ui/components/BoxOnBlue';
import { FormErrorMessage } from '@intus-ui/components/forms/errors/FormErrorMessage';

import generateTrackingInfo from 'Login/generateTrackingInfo';
import onSuccessfulLogin from 'Login/onSuccessfulLogin';
import ResendEmailCountdown from 'Login/ResendEmailCountdownProps';

const logger = getLogger('Login');

type FormPages = 'email' | 'password' | 'forget-password';

const Login: FC = () => {
  const queryParams = new URLSearchParams(window.location.search);

  // standard login/welcome form
  return (
    <BoxOnBlue logo>
      {queryParams.has('redirectReason') && (
        <div className="w-100 justify-content-center py-3">
          <h4 className="text-dark text-align-center">
            Please sign in to{' '}
            {queryParams.has('redirectReason') &&
            queryParams.get('redirectReason') === 'sessionExpired'
              ? 'view the requested url:'
              : 'resume session'}
          </h4>
          <br />
          {queryParams.has('redirectReason') && queryParams.get('redirectReason') && (
            <p className="text-secondary text-align-center mb-2">{`${
              window.location.origin
            }${queryParams.get('redirectTo')}`}</p>
          )}
        </div>
      )}
      <LoginForm />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          marginTop: 30,
          margin: 10,
          alignItems: 'center',
        }}
      >
        <Text type="caption">Need help? Email us at</Text>
        <Text wrapper="a" href="mailto: support@intus.care" type="subtitle" color="link">
          support@intus.care
        </Text>
      </div>
      <div className="d-flex justify-content-center align-self-center w-100 pt-1">
        <Text>Please contact your IT administrator to create a new account.</Text>
      </div>
    </BoxOnBlue>
  );
};

const LoginForm: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [page, setPage] = useState<FormPages>('email');
  const [canResend, setCanResend] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('errormessage')) {
      return urlParams.get('errormessage');
    }

    return null;
  });

  const queryParams = new URLSearchParams(window.location.search);

  type EmailFormData = {
    email: string;
    password: string;
  };

  const { handleSubmit, control, formState, setValue, getValues } = useForm<EmailFormData>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  // Step 1 - Check if the user is using SSO
  const { loading: isLoadingCheckSSO, runQuery: runCheckSSOQuery } = useLazyQuery(checkForSSO, {
    onSuccess: (data) => {
      // If a user is using SSO, we'll redirect them to their SSO page.
      // The SSO page will redirect back to us once they login.
      if (data.isSSO) {
        // Store the username is local storage.
        // We use this when they are redirected back to our site by their SSO provider.
        // We need to know what username they were trying to login with so we can determine their organization.
        window.localStorage.setItem('ssoUsername', getValues('email'));
        window.location.href = data.redirectUrl;
      } else {
        setPage('password');
      }
    },
    onError: (error) => {
      // If this fails, there's not much we can do.
      // Just log the error and send the user to the password page.
      logger.error(error);
      setPage('password');
    },
  });

  // Step 2 - If not using SSO, let them login with a username/password
  const {
    loading: isLoadingUser,
    runQuery: runLoginQuery,
    statusCode: loginStatusCode,
    error: loginError,
  } = useLazyQuery(loginUser, {
    onSuccess: (result) => {
      if ('mustConfigureTwoFactorAuthentication' in result) {
        if (result.mustConfigureTwoFactorAuthentication) {
          history.push('/twoFactorSetup', {
            email: getValues('email'),
            jwt: result.jwt,
          });
        }
      } else if ('requiresTwoFactorAuthentication' in result) {
        if (result.requiresTwoFactorAuthentication) {
          const { jwt, allowsRememberMe } = result;
          history.push('/twoFactorValidation', { jwt, allowsRememberMe });
        }
      } else {
        onSuccessfulLogin({
          dispatch,
          history,
          queryParams,
          result,
          mustConfigureTwoFactorAuthentication: false,
        });
      }
    },
    onError: (error) => {
      logger.error(error);
    },
  });

  useEffect(() => {
    let errorMessage: string | null = null;
    if (loginStatusCode === 401) {
      errorMessage = loginError?.message ?? 'Incorrect username or password';
    } else if (loginStatusCode === 429) {
      errorMessage = 'Too many failed attempts. Please use the button below to reset your password';
    } else if (loginStatusCode != null && loginStatusCode >= 400) {
      errorMessage = 'An error occurred, please try again';
    }

    if (errorMessage != null) {
      setErrorMessage(errorMessage);
    }
  }, [loginError, loginStatusCode]);

  async function tryLogin(formData: EmailFormData) {
    const email = formData.email.toLowerCase();
    setErrorMessage(null);
    if (page === 'email') {
      await runCheckSSOQuery({
        email,
      });
    } else if (page === 'password') {
      await runLoginQuery({
        email,
        password: formData.password,
        trackingInfo: generateTrackingInfo(),
      });
    }
  }

  const { loading: isLoadingForgotPassword, runQuery: queryForgotPassword } = useLazyQuery(
    sendPasswordResetEmail,
    {
      onSuccess: () => {
        setErrorMessage(null);
        // update UI
        setPage('forget-password');
      },
      onError: (error) => {
        setErrorMessage(`An Error occured:${error.message}`);
      },
    }
  );
  const handleClickForgetPassword = () => {
    logEvent('Clicked Forgot Password', { email: getValues('email') });
    setPage('forget-password');
    setCanResend(false);
    queryForgotPassword({
      email: getValues('email'),
    });
  };

  const handleCountdownComplete = () => {
    setCanResend(true);
  };

  function onBackButton() {
    setPage('email');
    setErrorMessage(null);
    // Clear out the password if they go back.
    setValue('password', '', {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  }

  return (
    <form onSubmit={handleSubmit(tryLogin)}>
      <div style={styles.inputsContainer}>
        {/* Email field, only shown on the first step */}
        {page === 'email' && (
          <div style={styles.inputRow}>
            <Text type="caption" wrapper="label" htmlFor="email-input">
              Email Address
            </Text>
            <Controller
              control={control}
              name="email"
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Email must be valid',
                },
              }}
              render={({ field }) => (
                <TextField
                  id="email-input"
                  autoFocus
                  placeholder="Email Address"
                  disabled={isLoadingCheckSSO}
                  type="text"
                  variant="outlined"
                  error={!!formState.errors.email}
                  {...field}
                />
              )}
            />
            <FormErrorMessage error={formState.errors.email} />
          </div>
        )}
        {/* Entered Email - Shows what email they entered and lets them go back to change it. */}
        {(page === 'password' || page === 'forget-password') && (
          <div style={styles.inputRow}>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 30 }}>
              <Button onClick={() => onBackButton()} disabled={isLoadingUser}>
                <Icon name="CaretLeft" color="white" />
              </Button>
              {page === 'password' && (
                <Text type="body" style={{ wordBreak: 'break-word' }}>
                  You are trying to login as {getValues('email')}
                </Text>
              )}
              {page === 'forget-password' && (
                <Text type="body" style={{ wordBreak: 'break-word' }}>
                  {`An email with a link to reset your password was just sent to ${getValues(
                    'email'
                  )}`}
                </Text>
              )}
            </div>
          </div>
        )}
        {/* Password field */}
        {page === 'password' && (
          <div style={styles.inputRow}>
            <Text type="caption" wrapper="label" id="passwordLabel" htmlFor="password-input">
              Password
            </Text>
            <Controller
              control={control}
              name="password"
              rules={{
                required: 'Password is required',
              }}
              render={({ field }) => (
                <TextField
                  id="password-input"
                  autoFocus
                  disabled={isLoadingUser}
                  type="password"
                  variant="outlined"
                  placeholder="Enter password"
                  error={!!formState.errors.password}
                  {...field}
                />
              )}
            />
            <FormErrorMessage error={formState.errors.password} />
          </div>
        )}

        {errorMessage != null && (
          <div style={styles.inputRow}>
            <Text type="body" color="danger">
              {errorMessage}
            </Text>
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
          marginTop: 10,
          gap: 30,
          // border: 'solid 1px black',
        }}
      >
        {page === 'password' && (
          <Text
            type="subtitle"
            color="link"
            onClick={handleClickForgetPassword}
            id="forgot-password"
          >
            Forgot Password?
          </Text>
        )}
        {page === 'forget-password' && !canResend && (
          <ResendEmailCountdown initialCount={30} onCountdownComplete={handleCountdownComplete} />
        )}
        {page === 'forget-password' && canResend && (
          <Button
            compact
            type="submit"
            busy={isLoadingCheckSSO || isLoadingUser || isLoadingForgotPassword}
            style={{ padding: 14 }}
            onClick={handleClickForgetPassword}
          >
            Resend Email
          </Button>
        )}

        {page !== 'forget-password' && (
          <Button
            compact
            type="submit"
            busy={isLoadingCheckSSO || isLoadingUser}
            style={{ padding: 14 }}
          >
            {page === 'password' ? 'Log in' : 'Next'}
          </Button>
        )}
      </div>
    </form>
  );
};

const styles = {
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 10,
    minHeight: '120px',
  } as CSSProperties,
  inputRow: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  } as CSSProperties,
};

export default Login;

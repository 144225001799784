import { layout } from '@intus-ui';
import ProtectedRoute from '@router/ProtectedRoute';
import InitiativesListView from 'Initiatives/InitiativesListView';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { TrialsCreateOrEditView } from 'Initiatives/Trials/TrialsCreateOrEditView';
import InitiativeFocusView from './InitiativeFocusView';

const Initiatives = () => {
  const { path } = useRouteMatch();

  return (
    <div style={styles.container}>
      <Switch>
        <ProtectedRoute exact path={path} component={InitiativesListView} />
        {/* Create a new initiative */}
        <ProtectedRoute exact path={`${path}/create`} component={TrialsCreateOrEditView} />
        {/* Add a trial to an existing initiative */}
        <ProtectedRoute
          exact
          path={`${path}/:initiativeId/trials/create`}
          component={TrialsCreateOrEditView}
        />
        {/* Edit an existing trial */}
        <ProtectedRoute
          exact
          path={`${path}/:initiativeId/trials/:trialId/edit`}
          component={TrialsCreateOrEditView}
        />
        {/* View a trial */}
        <ProtectedRoute
          path={`${path}/:initiativeId/trials/:trialId`}
          component={InitiativeFocusView}
          render={({ match }) => <InitiativeFocusView trialId={match.params.trialId} />}
        />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </div>
  );
};

const styles = {
  container: {
    ...layout.container,
    height: '100%',
  },
};

export default Initiatives;

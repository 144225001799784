import * as userTracking from '@util/userTracking';
import { getAllCustomDashboards } from '@api/dashboard';
import { useQuery } from '@api/useQuery';
import {
  Button,
  Card,
  Icon,
  List,
  SearchInput,
  SpinnerOrError,
  Tag,
  Text,
  TooltipComponent,
  layout,
} from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { formatDate } from '@util/dateFunctions';
import { isEmpty, take, takeRight } from 'lodash';
import { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

export function CustomDashboardListView() {
  const history = useHistory();

  const { data, loading, error } = useQuery(() => getAllCustomDashboards());

  const personalDashboards = useMemo(() => {
    if (!data) return [];

    return data.customDashboards.filter((dashboard) => dashboard.isShared === false);
  }, [data]);

  const sharedDashboards = useMemo(() => {
    if (!data) return [];

    return data.customDashboards.filter((dashboard) => dashboard.isShared === true);
  }, [data]);

  return (
    <div style={styles.container}>
      <div
        style={{
          flex: '0 0 auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 15,
        }}
      >
        <div style={{ display: 'flex', gap: 7 }}>
          <Text id="custom-dashboards-title" type="title" color="navy">
            Custom Dashboards
          </Text>
          <Link
            to="/customdashboards/tutorial?inCustomDashTutorial=true"
            title="View Custom Dashboard Tutorial"
            style={{ marginTop: -1 }}
            aria-label="View Custom Dashboard Tutorial"
          >
            <Icon style={{ width: 20 }} name="tutorialBook" />
          </Link>
        </div>
        <Button
          type="primary"
          size="small"
          role="link"
          onClick={() => {
            history.push('/customdashboards/create');
            userTracking.logEvent('Dashboard: Clicked Create Custom Dash');
          }}
        >
          Create Custom Dash
        </Button>
      </div>

      <div
        style={{
          flex: '1 1 100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          gap: 15,
        }}
      >
        {error && <SpinnerOrError error="An error ocurred loading the custom dashboards" />}

        {!error && (
          <>
            <DashboardList
              dashboards={personalDashboards}
              title="Personal Dashboards"
              loading={loading}
              columnConfig={personalDashboardColumnConfig}
              defaultSort={{ field: 'lastEditedDate', direction: 'desc' }}
            />

            <DashboardList
              dashboards={sharedDashboards}
              title="Shared Dashboards"
              loading={loading}
              columnConfig={sharedDashboardColumnConfig}
            />
          </>
        )}
      </div>
    </div>
  );
}

function DashboardList({ title, loading, dashboards, columnConfig, defaultSort }) {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredDashboards = useMemo(() => {
    if (!isEmpty(searchTerm?.trim())) {
      return dashboards.filter((d) =>
        d.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
      );
    }
    return dashboards;
  }, [searchTerm, dashboards]);

  return (
    <Card fluid dropshadow style={styles.card}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 15,
          }}
        >
          <div style={{ flex: '0 0 auto' }}>
            <Text type="title" color="navy">
              {title}
            </Text>
          </div>

          <div style={{ flex: '1 1 100%', maxWidth: 400 }}>
            <SearchInput placeholder="Search" handleSearch={handleSearch} searchTerm={searchTerm} />
          </div>
        </div>

        {loading && <SpinnerOrError />}

        {!loading && (
          <div>
            <List
              data={filteredDashboards}
              onClickItem={(dashboard) => {
                history.push(`/customdashboards/${dashboard.id}`);
                userTracking.logEvent(`Dashboard: Opened Dashboard`, {
                  dashboardId: dashboard.id,
                  dashboardName: dashboard.name,
                  isShared: dashboard.isShared,
                });
              }}
              defaultSort={defaultSort}
              format={dashboardListFormat}
              columnConfig={columnConfig}
              emptyListMessage="There are no dashboards in this list. Add a new dashboard using the button above."
              showColumnHeaders
              noPadding
            />
          </div>
        )}
      </div>
    </Card>
  );
}

function DashboardTagList({ dashboard }) {
  const showMoreTag = dashboard.metricCategories.length > 4;

  const categories = take(dashboard.metricCategories, 4);

  const otherCategories = showMoreTag
    ? takeRight(dashboard.metricCategories, dashboard.metricCategories.length - 4)
    : [];

  // Take up a minimum height so dashboards with no metrics do not make smaller rows
  return (
    <div style={{ display: 'flex', gap: 8, minHeight: 20 }}>
      {categories.map((c) => {
        return <DashboardTag key={`${dashboard.id}-${c.name}`} category={c} />;
      })}

      {showMoreTag && (
        <TooltipComponent
          anchorId={`${dashboard.id}`} // anchor ID must be unique
          content={
            <div style={{ display: 'flex', gap: 5, flexDirection: 'column', overflow: 'hidden' }}>
              {otherCategories.map((category) => {
                return (
                  <DashboardTag key={`${dashboard.id}-${category.name}`} category={category} />
                );
              })}
            </div>
          }
        >
          <Tag
            style={{ maxWidth: 150, cursor: 'pointer' }}
            type="intusMade"
            color="gray"
            hoverColor="highlight"
            ellipsis
          >
            {`+${otherCategories.length} more`}
          </Tag>
        </TooltipComponent>
      )}
    </div>
  );
}

function DashboardTag({ category }) {
  /** @type {{ name: string }} */
  let { name } = category;
  const hyphenIndex = name.indexOf('-');

  if (hyphenIndex !== -1) {
    // Remove the text like `Incidents - ` or `Hospitalizations - `
    // Index + 2 here to include the space after the hyphen.
    name = name.substring(hyphenIndex + 2);
  }

  return <Tag color={category.color}>{name}</Tag>;
}

const styles = {
  container: {
    ...layout.container,
    backgroundColor: '#EFF0F3',
    padding: '15px 20px 20px 20px',
    gap: 15,
  },
  card: {
    flex: '1 1 50%',
    backgroundColor: '#FFFFFF',
  },
};

const dashboardListFormat = [
  {
    field: 'name',
    name: 'Dashboard Name',
    flex: '1 1 100%',
    bg: 'lightpink',
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
    render: (row) => {
      const dashboard = row.item;
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Text type="subtitle">{dashboard.name}</Text>
          <DashboardTagList dashboard={dashboard} />
        </div>
      );
    },
  },
  {
    field: 'lastEditedDate',
    name: 'Last Edited',
    flex: '0 0 auto',
    bg: 'lightyellow',
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="lastEditedDate" id="lastEditedDate" /> }],
    sortType: 'date',
    render: (row) => {
      const dashboard = row.item;
      if (dashboard.lastEditedDate != null) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text style={{ minWidth: 76 }}>{formatDate(dashboard.lastEditedDate, 'M/D/YY')}</Text>
            {/* Creates space to align date and dashboard name */}
            <div style={{ minHeight: 20 }} />
          </div>
        );
      }
      return <Text />;
    },
  },
  {
    field: 'sharedByUser',
    name: 'Shared by',
    flex: '0 0 auto',
    bg: 'lightgreen',
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="sharedByUser" id="sharedByUser" /> }],
    render: (row) => {
      const dashboard = row.item;
      if (dashboard.sharedByUser != null) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text style={{ minWidth: 76 }}>{dashboard.sharedByUser}</Text>
            {/* Creates space to align date and dashboard name */}
            <div style={{ minHeight: 20 }} />
          </div>
        );
      }
      return <Text />;
    },
  },
];

const personalDashboardColumnConfig = {
  columns: ['name', 'lastEditedDate'],
};

const sharedDashboardColumnConfig = {
  columns: ['name', 'sharedByUser'],
};

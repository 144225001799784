// CHECK IF APPCUES IS DEFINED BEFORE TRYING TO USE
// ADBLOCKERS LIKE TO PREVENT THE SCRIPT FROM BEING LOADED
const canUseAppcues = () => {
  return typeof window.Appcues !== 'undefined';
};

export const identifyUser = (payload) => {
  if (canUseAppcues()) window.Appcues.identify(payload.email, payload);
};

export const logEvent = (eventType, meta) => {
  if (canUseAppcues()) window.Appcues.track(eventType, meta);
};

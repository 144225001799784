import { useState, useEffect } from 'react';

import { formatDate } from '@util/dateFunctions';

import { quadColors } from '@intus-ui/styles/SecondaryColors';

import { monthsOptions } from '@intus-ui/components/DateRange/config';
import Tab from './Tab';
import CalendarView from './CalendarView';
import ByQuarterView from './ByQuarterView';
import { Button } from '../Buttons';

import styles from './styles';

const DateRange = ({
  onChange,
  onApplyRange,
  onCancelRange = () => {},
  setShowDateRange,
  dateRangeDirection,
  existingLabel,
}) => {
  let existingValue = Object.values(monthsOptions).find((option) => option.label === existingLabel);

  if (!existingValue) {
    existingValue = {
      start: existingLabel?.split(' ')[0],
      end: existingLabel?.split(' ')[2],
    };
  }

  // updates the date range
  const initialState = [
    {
      startDate: new Date(existingValue?.start) || new Date(),
      endDate: new Date(existingValue?.end) || new Date(),
      key: 'selection',
    },
  ];
  const [state, setState] = useState(initialState);
  const formattedState = `${formatDate(state[0].startDate, 'MM/DD/YY')} - ${formatDate(
    state[0].endDate,
    'MM/DD/YY'
  )}`;

  const [activeTab, setActiveTab] = useState('By Date');
  const isViewByDate = activeTab === 'By Date';
  const isViewByYear = activeTab === 'By Year';
  const handleApplyRange = () => {
    onApplyRange({
      start: state[0].startDate,
      end: state[0].endDate,
      label: formattedState,
      isRange: true,
    });
    setShowDateRange(false);
  };

  const handleCancelRange = () => {
    setShowDateRange(false);
    onCancelRange();
  };

  const handleOnChange = (item) => setState([item.selection]);

  useEffect(() => {
    onChange({
      start: state[0].startDate,
      end: state[0].endDate,
      label: formattedState,
      isRange: true,
    });
  }, [state]);

  return (
    <div
      style={{
        ...styles.wrapper,
        borderRight: dateRangeDirection === 'left' && `solid 1px ${quadColors.disabled}`,
        borderLeft: dateRangeDirection !== 'left' && `solid 1px ${quadColors.disabled}`,
      }}
    >
      <div style={styles.tabs}>
        <Tab title="By Date" isActive={isViewByDate} onClick={() => setActiveTab('By Date')} />
        <Tab title="By Quarter" isActive={isViewByYear} onClick={() => setActiveTab('By Year')} />
      </div>
      {isViewByDate && <CalendarView handleOnChange={handleOnChange} state={state} />}
      {isViewByYear && (
        <ByQuarterView handleOnChange={handleOnChange} state={state} initialState={initialState} />
      )}
      <div style={styles.actionButtons}>
        <Button hoverText="Cancel" name="Cancel" onClick={handleCancelRange} textOnly />
        <Button hoverText="Apply Range" name="Apply Range" onClick={handleApplyRange} />
      </div>
    </div>
  );
};

export default DateRange;

import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import { moneyWithCommas } from '@util/utilFunctions';

const CostTable = ({ data, maxRows, colorKeys }) => {
  if (!data) {
    return <CenteredSpinner className="w-100 h-100" />;
  }
  const rows = [];
  const max = maxRows || data.length;
  const total = Math.round(data.map((item) => item[1]).reduce((a, b) => a + b, 0));
  const graphKey = (color) => (
    <svg height="1em" width="1em">
      <rect rx="0" ry="0" width="16" height="16" fill={color} />
    </svg>
  );
  data.forEach(([key, val], index) => {
    if (index < max) {
      rows.push(
        <tr className="" key={key}>
          <td>
            {colorKeys ? graphKey(colorKeys[index]) : null} {key}
          </td>
          <td>{moneyWithCommas(val)}</td>
          <td>{`${((100 * val) / total).toFixed(2)}%`}</td>
        </tr>
      );
    }
  });

  return (
    <div className="tableFixHead m-0 w-80 h-100">
      <Table borderless striped size="sm" style={{ margin: 0 }}>
        <thead>
          <tr>
            <th className=" pr-5">Category</th>
            <th className=" px-2">Amount</th>
            <th className=" pl-2">%</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};

CostTable.propTypes = {
  // This info will change from facility to facility so we kinda need this to be generic
  data: PropTypes.instanceOf(Array),
  maxRows: PropTypes.number,
  colorKeys: PropTypes.arrayOf(PropTypes.string),
};

CostTable.defaultProps = {
  data: undefined,
  maxRows: undefined,
  colorKeys: undefined,
};

export default CostTable;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, Button, Row, Col, FormControl, Form } from 'react-bootstrap';
import { updateUser, resetAdminPassword } from '../UserTracking/AdminSlice';

const AdminModal = ({
  show,
  hide,
  info: { id, name, email, phoneNumber, orgId },
  doUpdateUser,
  doResetPassword,
}) => {
  const [emailInput, setEmailInput] = useState('');
  const [passInput, setPassInput] = useState('');
  const [statusInput, setStatusInput] = useState('');

  useEffect(() => {
    // resets initial editable checkboxes whenever the modal is shown
    if (show) {
      setEmailInput(email);
    }
  }, [email, show]);

  const emailChange = (event) => setEmailInput(event.target.value);
  const passChange = (event) => setPassInput(event.target.value);
  const statusChange = (event) => setStatusInput(event.target.value);

  const hideModal = () => {
    setEmailInput('');
    setPassInput('');
    setStatusInput('');
    hide();
  };

  const editEmail = () => {
    doUpdateUser({ email: emailInput }, id, orgId);
    hideModal();
  };

  const editStatus = () => {
    doUpdateUser({ status: 'INACTIVE' }, id, orgId);
    hideModal();
  };

  return (
    <Modal show={show} onHide={hideModal} scrollable>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 font-weight-bold text-center">{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className=" font-weight-bold">Basic Info</h5>
        <hr />
        <Row className="mb-1">
          <Col md="4" className="font-weight-bold">
            ID:
          </Col>
          <Col>{id}</Col>
        </Row>
        <Row className="mb-1">
          <Col md="4" className="font-weight-bold">
            Email:
          </Col>
          <Col>{email}</Col>
        </Row>
        <Row className="mt-1 mb-5">
          <Col md="4" className="font-weight-bold">
            Phone Number:
          </Col>
          <Col>{phoneNumber || 'Unavailable'}</Col>
        </Row>

        <h5 className=" font-weight-bold">Edit Settings</h5>
        <hr />
        <Form>
          <Row className="align-items-center my-3">
            <Col>
              <FormControl className="rounded-lg" value={emailInput} onChange={emailChange} />
            </Col>
            <Col>
              <Button
                type="submit"
                onClick={editEmail}
                className="m-0"
                disabled={emailInput === email}
              >
                Change Email
              </Button>
            </Col>
          </Row>
        </Form>

        <Row className="align-items-center mt-4">
          <Col>Type the Admin&apos;s name to reset their password.</Col>
        </Row>
        <Form>
          <Row className="align-items-center mt-3 mb-5">
            <Col>
              <FormControl className="rounded-lg" value={passInput} onChange={passChange} />
            </Col>
            <Col>
              <Button
                onClick={() => doResetPassword({ id })}
                type="submit"
                className="m-0"
                disabled={passInput !== name}
              >
                Reset Password
              </Button>
            </Col>
          </Row>
        </Form>

        <h5 className=" font-weight-bold">Danger Zone</h5>
        <hr />
        <Row>
          <Col>Type the Admin&apos;s name to deactivate them.</Col>
        </Row>
        <Form>
          <Row className="align-items-center my-3">
            <Col>
              <FormControl className="rounded-lg" value={statusInput} onChange={statusChange} />
            </Col>
            <Col>
              <Button
                variant="muted"
                className="m-0"
                disabled={statusInput !== name}
                type="submit"
                onClick={editStatus}
              >
                DEACTIVATE
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

AdminModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  info: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string,
    orgId: PropTypes.number.isRequired,
  }).isRequired,
  doUpdateUser: PropTypes.func.isRequired,
  doResetPassword: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch) => ({
  doUpdateUser: (body, id, orgId) => dispatch(updateUser(body, id, orgId)),
  doResetPassword: (body) => dispatch(resetAdminPassword(body)),
});

export default connect(null, mapDispatch)(AdminModal);

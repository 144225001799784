import React from 'react';
import PropTypes from 'prop-types';
import { RiskBadge } from '@intus-ui';
import acuityLevelPropType from './acuityLevelPropType';

const AcuityHeader = ({ acuityLevel, riskGroup, children }) => {
  return (
    <div className="justify-content-between w-100 align-items-center d-flex flex-row px-3">
      <div className="d-flex align-items-center">
        <h6 className="text-navy-60 mt-0 pb-0">Acuity Score:</h6>
        <span className="font-weight-bold pl-1 mt-0 text-dark">{acuityLevel.acuityScore}</span>
        <span className="font-weight-bold float-right mx-3 ">
          <RiskBadge riskGroup={riskGroup} />
        </span>
      </div>
      <div>{children}</div>
    </div>
  );
};

AcuityHeader.propTypes = {
  acuityLevel: acuityLevelPropType.isRequired,
  riskGroup: PropTypes.string.isRequired,
};

export default AcuityHeader;

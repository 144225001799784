import { MenuItem, Select } from '@mui/material';
import { IPriority, allPriorities } from 'Polypharmacy/types/Priority';
import React, { FC } from 'react';

export type IPossiblePriority = 'All' | IPriority;

export type PrioritySelectProps = {
  priority: IPossiblePriority;
  setPriority: (priority: IPossiblePriority) => void;
};

export const PrioritySelect: FC<PrioritySelectProps> = ({ priority, setPriority }) => {
  return (
    <Select
      label="Priority"
      value={priority}
      onChange={(e) => {
        setPriority(e.target.value.toString() as IPossiblePriority);
      }}
      sx={{ width: 200 }}
    >
      <MenuItem value="All" key="All">
        All
      </MenuItem>
      {allPriorities.map((priority) => {
        return (
          <MenuItem key={priority} value={priority}>
            {priority}
          </MenuItem>
        );
      })}
    </Select>
  );
};

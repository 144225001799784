export const sidebarContentContainer = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: '10px',
};

export const generalAnalysisItems = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};
export const analyticsItem = {
  height: 'fit-content',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#C8C8C8',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '7px 7px 7px 14px',
  gap: '10px',
};

export const riskFactorItem = {
  height: 'fit-content',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#C8C8C8',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  padding: '7px 7px 7px 14px',
  width: '100%',
};

export const fallsAnalysisSidebar = {
  width: '350px',
};

export const hrLineStyle = {
  height: 0,
  width: '100%',
  margin: '0 0 0 15px',
  backgroundColor: '#C8C8C8',
};

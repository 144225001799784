import PropTypes from 'prop-types';

export const FallsPrevPropType = PropTypes.shape({
  averageFallsPerQuarter: PropTypes.number,
  daysLeftInQuarter: PropTypes.number,
  fallsCurrentQuarter: PropTypes.shape({
    value: PropTypes.number,
    format: PropTypes.string,
    payload: PropTypes.shape({
      participantIds: PropTypes.arrayOf(PropTypes.number),
      value: PropTypes.number,
    }),
  }),
});

export default FallsPrevPropType;

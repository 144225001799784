import React from 'react';
import PropTypes from 'prop-types';

import '@intus-ui/styles/Dashboard.scss';
import { Text } from '@intus-ui';

const QuadHeader = ({ title, currentStat, disabled }) => (
  <div
    style={{ borderBottom: disabled ? '1px solid #C8C8C8' : '1px solid black' }}
    className="w-100 pt-3 pb-1  d-flex flex-row align-items-center justify-content-between"
  >
    <Text type="title" color={disabled ? 'caption' : 'navy'}>
      {title}
    </Text>
    {currentStat ? (
      <div className="">
        <h6 className="text-muted">Current:</h6>
        <h6 className="text-secondary font-weight-bold pl-2">{currentStat}</h6>
      </div>
    ) : null}
  </div>
);

QuadHeader.propTypes = {
  title: PropTypes.node,
  currentStat: PropTypes.string,
};

QuadHeader.defaultProps = {
  title: '',
  currentStat: '',
};

export default QuadHeader;

import PropTypes from 'prop-types';

export const FallsModalPropType = PropTypes.shape({
  fallsBreakdown: PropTypes.shape({
    pptsWMultFalls: PropTypes.shape({
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
    pptsWFalls: PropTypes.shape({
      val: PropTypes.number,
      ids: PropTypes.arrayOf(PropTypes.number),
    }),
  }),
  freqFallers: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, count: PropTypes.number })
  ),
  fallsPer100MM: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, count: PropTypes.number })
  ),
  fallsBySeverity: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, count: PropTypes.number })
  ),
  fallsWInjury: PropTypes.shape({
    val: PropTypes.number,
    ids: PropTypes.arrayOf(PropTypes.number),
  }),
});

export default FallsModalPropType;

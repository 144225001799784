import { filterRowSchemas } from './filterRowConfig';
import * as filterComponentFormats from './FilterRow/FilterComponents/format';

// Smooth scroll when user add user group
export const scrollToDiv = (scrollableDiv) => {
  scrollableDiv.current.scrollTo({
    left: 0,
    top: scrollableDiv.current.scrollHeight,
    behavior: 'smooth',
  });
};

export const daysToDisplayTitle = {
  30: 'Past 30 Days',
  90: 'Past 90 Days',
  180: 'Past 180 Days',
  365: 'Past Year',
};

export const getDisplayTitleFromDays = (findItem) =>
  displayTitles[days.indexOf(days.find((item) => findItem === item))] || `Past ${findItem} days`;

export const days = Object.keys(daysToDisplayTitle).map((item) => parseInt(item, 10));
export const displayTitles = Object.values(daysToDisplayTitle).map((item) => item);

export const getDaysFromDisplayTitle = (findItem) =>
  days[displayTitles.indexOf(displayTitles.find((item) => item === findItem))];

// THIS METHOD LOOKS AT THE CONFIGURATION FOR THE CONDITION (i.e. medicationCount)
// AND INITIALIZES A NEW FILTER GROUP ROW FOR THE PROVIDED TYPE
// IT FIRST PULLS COMPONENT-LEVEL DEFAULT SETTINGS AND OVERRIDES WITH METRIC-SPECIFIC DEFAULTS
export const getNewConditionFromGroupType = (groupType) => {
  let dataFormat = {};
  const optionComponents = Object.keys(filterRowSchemas(groupType)[groupType]?.components);
  optionComponents.forEach((componentType) => {
    const componentDefaults =
      filterRowSchemas(groupType)[groupType].components[componentType].defaults || {};
    dataFormat = { ...dataFormat, ...filterComponentFormats[componentType], ...componentDefaults };
  });
  return { ...dataFormat, type: groupType };
};

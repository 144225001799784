import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Field, Formik } from 'formik';
import PropTypes from 'prop-types';

import { toSentenceCase } from '@util/utilFunctions';

import { setPage, setWelcomeFlowModal } from '@global-state/redux/welcomeSlice';
import { getUserInfo, updateUserSettings } from '@global-state/redux/userSettingsSlice';

import { Row, Col, Button } from 'react-bootstrap';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import userSettingsPropType from 'Settings/userSettingsPropType';
import WelcomeBox from './WelcomeBox';
import ModalButtons from './ModalButtons';

import '@intus-ui/styles/Login.scss';
import '@intus-ui/styles/WelcomeFlow.scss';

const ChooseCategories = ({
  userSettings,
  doUpdateUserSettings,
  hPageBoxes,
  doSetWelcomeFlowFinalModal,
  history,
}) => {
  const [error, setError] = useState(false);

  // This one doesn't need to check local state since it's part of the post-login welcome flow
  // which will always have it populated
  const shouldNotIncludeFinancials = userSettings?.subscriptions?.includes(
    'EHR_PROVIDES_NO_FINANCIALS'
  );

  const [profile] = useState(userSettings.profile);
  if (!profile) {
    return <SpinnerOrError error="Could not load the page. Please refresh and try again. " />;
  }
  const { setting } = profile;

  // Handle Customer Selection from Modal Component
  const onSelectCustomerHandler = (homeboxName, setFieldValue, valueList) => {
    const index = [...valueList.homePageBoxes].indexOf(homeboxName);
    if (index > -1) {
      setFieldValue(
        'homePageBoxes',
        valueList.homePageBoxes.filter((item) => item !== homeboxName)
      );
    } else {
      const updatedSelections = [...valueList.homePageBoxes];
      updatedSelections.push(homeboxName);
      setFieldValue('homePageBoxes', updatedSelections);
    }
  };

  const initializeHomePageBoxes = () => {
    if (userSettings.profile.setting) {
      return userSettings.profile.setting.homePageBoxes?.length > 3
        ? []
        : userSettings.profile.setting.homePageBoxes;
    }
    return [];
  };

  return (
    <Formik
      initialValues={{
        ...profile,
        ...setting,
        homePageBoxes: initializeHomePageBoxes(),
      }}
      onSubmit={(values) => {
        if (values.homePageBoxes.length === 3) {
          const updatedSetting = {
            ...setting,
            homePageBoxes: values.homePageBoxes,
            defaultRecentUpdatesFilter: [
              'Enrolled',
              'Disenrolled',
              'Inpatient Admits',
              'Inpatient Discharges',
              'ER Visits',
            ],
          };
          setError(false);
          doUpdateUserSettings({ ...profile, settings: updatedSetting });
          doSetWelcomeFlowFinalModal(true);
          history.push('/home');
        } else {
          setError(true);
        }
      }}
    >
      {({ handleSubmit, submitForm, values, setFieldValue }) => {
        const disableCheckbox = (name) => {
          return values.homePageBoxes?.length === 3 && !values.homePageBoxes.includes(name);
        };
        const modalButton = (
          <ModalButtons onClick={() => submitForm()} disabled={values.homePageBoxes?.length < 3} />
        );
        return (
          <WelcomeBox buttons={modalButton}>
            <div className="text-dark h-100 w-100 d-flex flex-column justify-content-between align-items-center">
              <div className="d-flex pb-4 flex-column justify-content-center">
                <h3 className="text-secondary font-weight-bold">
                  Choose 3 dashboards for your homepage:
                </h3>
                <h6 className="text-dark pt-3">
                  * Those not selected will still be accessible from the Dashboards tab.
                </h6>
              </div>

              <Form onSubmit={handleSubmit} className="w-100">
                <Row className="d-flex flex-row align-items-start justify-content-center px-0 py-2 m-0 w-100">
                  <Col
                    md={6}
                    className="p-0 display-flex flex-column justify-content-center align-items-end w-100 h-100 pr-5"
                  >
                    <ul className="p-0 text-left">
                      <h5 className="font-weight-bold text-dark p-0 m-0">Clinical</h5>
                      {hPageBoxes.slice(0, 3).map((homePageBox, index) => (
                        <li
                          // eslint-disable-next-line react/no-array-index-key
                          key={`homePageBox-col${index}`}
                          style={{ listStyleType: 'none' }}
                        >
                          <Row className="d-flex flex-row align-items-center justify-content-start rounded-lg py-1">
                            <Field
                              type="checkbox"
                              name="homePageBoxes"
                              value={homePageBox}
                              style={{ width: '2rem', height: '2rem' }}
                              className={`${
                                disableCheckbox(homePageBox) ? '' : 'cursor-pointer'
                              } mr-3 m-0 p-0`}
                              disabled={disableCheckbox(homePageBox)}
                            />
                            <Button
                              type="button"
                              htmlFor={homePageBox}
                              className="selection-button form-check-label py-2 px-3 box-shadow-on-gray rounded-xl border-0 text-left mt-1"
                              style={{ height: '50px', minWidth: '175px' }}
                              onClick={() => {
                                onSelectCustomerHandler(homePageBox, setFieldValue, values);
                              }}
                              disabled={disableCheckbox(homePageBox)}
                            >
                              <h6>{toSentenceCase(homePageBox)}</h6>
                            </Button>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </Col>
                  <Col
                    md={6}
                    className="p-0 display-flex flex-column justify-content-center align-items-start w-100 h-100 pl-5"
                  >
                    <ul className="p-0 text-left">
                      <h5 className="font-weight-bold text-dark p-0 m-0">Operational</h5>
                      {hPageBoxes
                        .slice(3)
                        .reverse()
                        .filter((item) => {
                          // currently hiding Financial dashboard if a "subscription" indicating it should be hidden
                          if (shouldNotIncludeFinancials) {
                            return item !== 'financial';
                          }
                          return true;
                        })
                        .map((homePageBox, index) => (
                          <li
                            // eslint-disable-next-line react/no-array-index-key
                            key={`homePageBox-col${index}`}
                            style={{ listStyleType: 'none' }}
                          >
                            <Row className="d-flex flex-row align-items-center justify-content-end rounded-xl py-1">
                              <Field
                                type="checkbox"
                                name="homePageBoxes"
                                value={homePageBox}
                                style={{ width: '2rem', height: '2rem' }}
                                className={`${
                                  disableCheckbox(homePageBox) ? '' : 'cursor-pointer'
                                } mr-3 m-0 p-0`}
                                disabled={disableCheckbox(homePageBox)}
                              />
                              <Button
                                type="button"
                                htmlFor={homePageBox}
                                className="selection-button form-check-label py-2 px-3 box-shadow-on-gray rounded-xl border-0 text-left mt-1"
                                style={{ height: '50px', minWidth: '175px' }}
                                onClick={() => {
                                  onSelectCustomerHandler(homePageBox, setFieldValue, values);
                                }}
                                disabled={disableCheckbox(homePageBox)}
                              >
                                <h6>{toSentenceCase(homePageBox)}</h6>
                              </Button>
                            </Row>
                          </li>
                        ))}
                    </ul>
                  </Col>
                </Row>
                <Row className="ml-5 pt-2">
                  {error && (
                    <Row className="text-danger align-items-center align-content-center pl-5 pb-3 ml-2 mb-2">
                      Please select 3 categories to continue.
                    </Row>
                  )}
                </Row>
              </Form>
            </div>
          </WelcomeBox>
        );
      }}
    </Formik>
  );
};

ChooseCategories.propTypes = {
  userSettings: userSettingsPropType,
  doUpdateUserSettings: PropTypes.func.isRequired,
  hPageBoxes: PropTypes.instanceOf(Array).isRequired,
};

ChooseCategories.defaultProps = {
  userSettings: undefined,
};

const mapState = (state) => ({
  userSettings: state.userSettings,
  hPageBoxes: state.welcome.homePageBoxes,
});

const mapDispatch = (dispatch) => ({
  doGetUserLocalData: () => dispatch(getUserInfo()),
  doUpdateUserSettings: (body) => dispatch(updateUserSettings(body)),
  doSetPage: (page) => dispatch(setPage(page)),
  doSetWelcomeFlowFinalModal: (value) => dispatch(setWelcomeFlowModal(value)),
});

export default withRouter(connect(mapState, mapDispatch)(ChooseCategories));

import { defaultOverlayStyle, defaultContentStyle } from './styles';

export const modalTypes = {
  tiny: {
    overlay: defaultOverlayStyle,
    content: {
      ...defaultContentStyle,
      width: '300px',
      maxWidth: '80%',
    },
  },
  small: {
    overlay: defaultOverlayStyle,
    content: {
      ...defaultContentStyle,
      width: '500px',
      maxWidth: '80%',
    },
  },
  regular: {
    overlay: defaultOverlayStyle,
    content: {
      ...defaultContentStyle,
      width: '600px',
      maxWidth: '85%',
    },
  },
  medium: {
    overlay: defaultOverlayStyle,
    content: {
      ...defaultContentStyle,
      width: '800px',
      maxWidth: '90%',
    },
  },
  large: {
    overlay: defaultOverlayStyle,
    content: {
      ...defaultContentStyle,
      width: '1280px',
      maxWidth: '90%',
    },
  },
};

import { useState } from 'react';
import Text from '@intus-ui/components/Text';
import { Button } from '@intus-ui/components/Buttons';
import Icon from '@intus-ui/components/Icon';
import { formatDate } from '@util/dateFunctions';
import { useMetricDownload } from 'CustomDashboardV2/download/useMetricDownload';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import { MetricParticipantList } from './MetricParticipantList';
import BarChart from './BarChart';

export const StatFocusViewWidget = ({ metric, metricData }) => {
  const { allParticipants, monthToParticipants } = metricData.data || {};
  const [metricDataState, setMetricDataState] = useState(metricData);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [participantsList, setParticipantsList] = useState(allParticipants);

  const handleOnClickCloseButton = () => {
    setSelectedLabel(null);
    // new copy triggers chart re-render
    const refreshData = { ...metricData };
    setMetricDataState(refreshData);
    setParticipantsList(allParticipants);
  };

  const handleOnClickBar = (clickedLabel) => {
    if (clickedLabel) {
      const participants = monthToParticipants[clickedLabel];
      setParticipantsList(participants);
      setSelectedLabel(`${formatDate(clickedLabel, "MMM 'YY")}`);
    } else {
      setSelectedLabel(null);
      setParticipantsList(allParticipants);
    }
  };

  const {
    runQuery: runDownloadQuery,
    loading: isDownloadLoading,
    error: downloadError,
  } = useMetricDownload(metric, metricData);

  return (
    <div style={styles.container}>
      <div style={styles.title}>
        <Text color="caption">
          {selectedLabel
            ? `Metric is filtered by: ${selectedLabel}`
            : 'Select a bar on the chart to view more details.'}
        </Text>
        <Button
          name="Download"
          busy={isDownloadLoading}
          hoverText="Download"
          secondary
          onClick={() => runDownloadQuery()}
          style={{
            minWidth: 120,
          }}
        >
          <Icon name="Download" color="#052D8F" />
        </Button>
      </div>

      {downloadError && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
          <span>
            <SpinnerOrError error="An error occurred downloading the metric" />
          </span>
        </div>
      )}

      <div style={styles.content}>
        <div style={styles.chartContainer}>
          <BarChart
            metricData={metricDataState}
            onClickBar={handleOnClickBar}
            isTimeAxis
            inFocusView
          />
        </div>

        <div style={styles.listContainer}>
          <MetricParticipantList
            metricData={metricData}
            participantsList={participantsList}
            selectedLabel={selectedLabel}
            handleOnClickCloseButton={handleOnClickCloseButton}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  title: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  content: {
    display: 'flex',
    gap: 20,
    padding: '10px 0px 0px 0px',
    height: '100%',
    overflowY: 'auto',
  },
  chartContainer: {
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    flex: '0 0 450px',
  },
  listTitle: {
    padding: '10px 20px 0px 20px',
    gap: 10,
    display: 'flex',
    alignItems: 'center',
  },
  listTitleIcon: {
    gap: 2,
    display: 'flex',
    alignItems: 'center',
  },
};

export const tableStyles = {
  shadedHeader: {
    backgroundColor: '#EFF0F3',
    padding: '10px',
  },
};

export const CSS_BORDER = '1px solid rgba(224, 224, 224, 1)';
export const CSS_PADDING = 10;

export const tableConfig = {
  enableStickyHeader: true,
  enablePagination: false,
  enableSorting: false, // Disable sorting for the entire table
  enableColumnOrdering: false, // Disable column ordering
  enableRowSelection: false, // Disable row selection
  enableRowActions: false, // Disable row actions
  enableColumnActions: false, // Disable column actions
  enableGlobalFilter: false, // Disable global filtering
  enableGrouping: false, // Disable grouping
  enablePinning: false, // Disable column pinning
  enableDensityToggle: false, // Disable density toggle
  enableFullScreenToggle: false, // Disable full-screen toggle
  enableTopToolbar: false, // Disable the top toolbar entirely
  enableBottomToolbar: false, // Disable the bottom toolbar entirely

  muiTablePaperProps: {
    // ADJUST BORDER RADIUS
    sx: () => ({
      borderRadius: '10px',
      border: CSS_BORDER,
      boxShadow: 'none',
    }),
  },
  muiTableProps: {
    sx: () => ({
      // REMOVE SHADOW FROM HEADER
      // '& thead>tr': {
      //   boxShadow: 'none',
      // },
      // TABLE HEADER BORDERS
      '& thead>tr>th': {
        lineHeight: 'inherit',
        borderRight: CSS_BORDER,
        padding: CSS_PADDING,
        opacity: 1,
      },
      // REMOVE BORDER FROM SECOND TO LAST CELL
      '& thead>tr>th:nth-last-of-type(2)': {
        borderLeft: 'none',
        borderRight: 'none',
      },
      // REMOVE RIGHT BORDER FROM LAST CELL AND ADD LEFT BORDER
      '& thead>tr>th:last-of-type': {
        borderLeft: `${CSS_BORDER} !important`,
        borderRight: 'none !important',
      },
    }),
  },
  muiTableBodyProps: {
    sx: () => ({
      // ZEBRA STRIPES
      '& tr:nth-of-type(odd)': {
        backgroundColor: 'rgb(239, 240, 243)',
      },
      // REMOVE BORDER FROM SECOND TO LAST CELL
      '& tr>td:nth-last-of-type(2)': {
        borderLeft: 'none',
        borderRight: 'none',
      },
      // REMOVE RIGHT BORDER FROM LAST CELL AND ADD LEFT BORDER
      '& tr>td:last-of-type': {
        borderLeft: CSS_BORDER,
        borderRight: 'none',
      },
      // REMOVE BOTTOM BORDER FROM LAST ROW
      // '& tr:last-of-type>td': {
      //   borderBottom: 'none',
      // },
    }),
  },
  muiTableBodyRowProps: {
    hover: false,
    sx: {
      backgroundColor: 'white',
    },
  },
  muiTableBodyCellProps: {
    sx: () => ({
      padding: CSS_PADDING,
      borderRight: CSS_BORDER,
      opacity: 1,
    }),
  },
};

export default tableConfig;

import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { getStringReplacement } from '@util/stringReplacements';
import { useGetSessionUser } from '@util/session';

import { setIsHealthSysAdmin } from '@global-state/redux/filtersSlice';
import { getEnrollments } from '@global-state/redux/updatesSlice';
import { getPatients } from '@global-state/redux/patientListSlice';
import { getUserInfo } from '@global-state/redux/userSettingsSlice';

import { layout, useParticipantFilters } from '@intus-ui';

import TabPageBody from '@intus-ui/components/layout/TabPageCore/TabPageBody';
import TabPageHeader from '@intus-ui/components/layout/TabPageCore/TabPageHeader';
import TabPageCol from '@intus-ui/components/layout/TabPageCore/TabPageCol';
import Centered from '@intus-ui/components/Centered';
import ParticipantList from '@intus-ui/components/ParticipantList/index';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';

import PinnedParticipantsTab from 'Homes/HomePage/PinnedParticipantsTab';
import HomeTabs from 'Homes/HomePage/HomeTabs';
import { USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import { useQuery } from '@api/useQuery';
import { getAllPatients } from '@api';
import { mapPatient } from '@api/patients/mapPatient';

const Participants = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedFilters } = useParticipantFilters();
  const [pinnedPpt, setPinnedPpt] = useState(true);
  const { access } = useGetSessionUser();
  const isHealthSysAdmin = access === USER_ACCESS_HEALTH_SYS_ADMIN;

  const { data: patientsData, error, runQuery } = useQuery(() => getAllPatients(selectedFilters));

  // If the filters change, update the participant list.
  useEffect(() => {
    runQuery();
  }, [selectedFilters]);

  const allPatients = useMemo(
    () => patientsData?.map((patient) => mapPatient(patient) ?? []),
    [patientsData]
  );

  useEffect(() => {
    dispatch(getEnrollments(365, 'All'));
    dispatch(getUserInfo(true));
    dispatch(setIsHealthSysAdmin(isHealthSysAdmin));
  }, []);

  const toggleRecentUpdates = () => {
    setPinnedPpt(!pinnedPpt);
  };

  const homeSideTabs = [
    {
      tab: 'Pinned',
      content: <PinnedParticipantsTab compactView={!pinnedPpt} />,
    },
  ];

  let content;
  // If there's an error, it's displayed.
  if (error) {
    content = (
      <Centered className="d-flex flex-grow-1">
        <div className="d-flex flex-column align-items-center m-5">
          <div className="m-1">{error}</div>
          <div className="m-1">
            <Button onClick={dispatch(getPatients)}>Try Again</Button>
          </div>
        </div>
      </Centered>
    );
  } else if (patientsData == null) {
    // If patients is undefined, shows a loading spinner.
    content = <CenteredSpinner className="d-flex flex-grow-1" />;
  } else {
    // If there are patients, the list is shown.
    content = (
      <ParticipantList
        onClickItem={(item) => {
          history.push(`/patient/${item.id}/current-acuity`);
        }}
        participantList={allPatients}
        topRightElementsList={['enrollFilter']}
      />
    );
  }

  return (
    <div className="bg-light h-100 w-100 d-flex align-items-stretch justify-content-center flex-column overflow-hidden">
      <TabPageHeader title={getStringReplacement('Participant', {plural: true})} />
      <TabPageBody>
        <TabPageCol colNum={pinnedPpt ? 8 : 10} padMarg="p-3 m-0">
          <div
            style={{
              ...layout.quad,
              gap: '0',
              padding: '4px 0px 0px 10px', // aligning to sidebar pinned list
              borderRadius: '10px', // replicating bootstrap
              boxShadow: '0px 0.4px 0.4rem 0.06rem rgb(0 0 0 / 5%)', // replicating bootstrap
            }}
          >
            {content}
          </div>
        </TabPageCol>
        <TabPageCol colNum={pinnedPpt ? 4 : 2} padMarg="py-3 m-0">
          <HomeTabs
            patients
            items={homeSideTabs}
            tabId="homepage-sideBar-tab"
            collapseOnClick={toggleRecentUpdates}
            toggleState={pinnedPpt}
          />
        </TabPageCol>
      </TabPageBody>
    </div>
  );
};

export default Participants;

import React from 'react';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveIcon from '@mui/icons-material/Remove';
import '@intus-ui/styles/Utilities.scss';

const FilteredListHeader = ({ format, sorting, onClick, filter, pinned }) => {
  const {
    // lg, sm, xs, md
    heading,
    additionalHeadingNode,
    valueField,
    headerClassName,
    headerStyle,
  } = format;

  let icon = <RemoveIcon className="select-border smaller-text text-primary" />;
  if (sorting.valueField === valueField) {
    if (sorting.value === true) {
      icon = <KeyboardArrowUpIcon className="select-border text-primary" />;
    } else if (sorting.value === false) {
      icon = <KeyboardArrowDownIcon className="select-border text-primary" />;
    }
  }
  if (pinned) {
    return (
      <Row>
        <div
          className={`d-flex flex-row${headerClassName ? ` ${headerClassName}` : ''}`}
          style={headerStyle}
        >
          {heading !== 'none' ? heading : null}
          {additionalHeadingNode}
          <div
            className="cursor-pointer"
            onClick={() => onClick(format)}
            tabIndex="0"
            role="button"
            onKeyDown={(e) => {
              if (e.keyCode === 32) {
                onClick(format);
              }
            }}
          >
            {format.heading === 'none' || format.sorting === 'none' ? null : icon}
          </div>
          <div>{filter}</div>
        </div>
      </Row>
    );
  }
  return (
    <div
      className={`d-flex flex-row${headerClassName ? ` ${headerClassName}` : ''}`}
      style={headerStyle}
    >
      {heading !== 'none' ? heading : null}
      {additionalHeadingNode}
      {format.heading === 'none' || format.sorting === 'none' || onClick === undefined ? null : (
        <div
          className="cursor-pointer pl-2"
          onClick={() => onClick(format)}
          tabIndex="0"
          role="button"
          onKeyDown={(e) => {
            if (e.keyCode === 32) {
              onClick(format);
            }
          }}
        >
          {icon}
        </div>
      )}
      <div className="pl-2">{filter}</div>
    </div>
  );
};

FilteredListHeader.propTypes = {
  format: PropTypes.shape({
    valueField: PropTypes.string.isRequired,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
    heading: PropTypes.string.isRequired,
    additionalHeadingNode: PropTypes.node,
    sorting: PropTypes.string.isRequired,
  }).isRequired,
  sorting: PropTypes.shape({
    valueField: PropTypes.string,
    value: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func,
  filter: PropTypes.node,
  pinned: PropTypes.bool,
};

FilteredListHeader.defaultProps = {
  filter: null,
  pinned: false,
  onClick: undefined,
};

export default FilteredListHeader;

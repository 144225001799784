import { post } from '@api/apiMethods';
import { IPolypharmacyFilters } from '@api/polypharmacy/types/IPolypharmacyFilters';
import { IAllStarsMeasures } from 'Polypharmacy/types/STARSMeasures';
import { IPriority } from 'Polypharmacy/types/Priority';
import { IPatientFirstFillStatus } from './getRisingStars';

export type IStarsAdherenceResponse = {
  diabetesAdherence: number;
  rasAdherence: number;
  statinsAdherence: number;
  patientAdherence: IPatientStarsAdherence[];
};

export type IPatientStarsAdherence = {
  id: number;
  name: string;
  age: number;
  hospice: boolean;
  measureData: IPatientStarsMeasureData[];
};

export type IPatientStarsMeasureData = {
  fillCount: number;
  measure: IAllStarsMeasures;
  medication?: string;
  percentageDaysCovered: number;
  fillDueDate: string | null;
  gapDays: number;
  minCoverageDays: number;
  adherenceComplianceDate: string | null;
  actionDate: string | null;
  medClasses: string[];
  status: IPatientFirstFillStatus | null;
  priority: IPriority;
};

export function getSTARSAdherence(
  measureYear: number,
  filters: IPolypharmacyFilters,
  tab: 'firstFills' | 'adherence',
  patientIds?: number[]
) {
  return post<IStarsAdherenceResponse>(`polypharmacy/adherence`, {
    measureYear,
    filters,
    patientIds,
    tab,
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { toUTC, newDate, formatDate } from '@util/dateFunctions';

import patientDetailPatientPropType from 'Participants/patientDetail/patientDetailPatientPropType';

import { getTrackingEvent } from '@api/api';
import { getPatient, getPatientIncidents } from '@global-state/redux/patientDetailSlice';
import { getPatients } from '@global-state/redux/patientListSlice';
import { getCaregivers } from '@global-state/redux/caregiverListSlice';
import { getPatientAcuityInfo } from '@global-state/redux/acuitySlice';
import { getPptRecentStats } from '@global-state/redux/profileSlice';
import { getRecentHospitalizations } from '@global-state/redux/hospitalizationsSlice';
import { getPatientCarePlanInfo } from '@global-state/redux/carePlanSlice';

import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import Profile from 'Participants/Profile/Profile';

class Patient extends React.Component {
  componentDidMount() {
    const {
      patientId,
      doGetPatient,
      doGetPatients,
      doGetCaregivers,
      doGetPatientAcuity,
      doGetPatientCarePlan,
      doGetPatientHosps,
      doGetPatientIncidents,
      doGetPatientStats,
    } = this.props;
    doGetPatient(patientId);
    doGetPatients();
    doGetCaregivers();
    doGetPatientAcuity(patientId);
    doGetPatientCarePlan(patientId);
    doGetPatientHosps(patientId);
    doGetPatientIncidents(patientId);
    doGetPatientStats(patientId, 'Past 3 months');
    getTrackingEvent({
      time: formatDate(toUTC(newDate())),
      page: `Patient ${patientId} details`,
      action: `Patient ${patientId} is being viewed`,
    });
  }

  render() {
    const { patient, location, error, history } = this.props;
    // Shows the error message if there was an error.
    if (error) {
      return (
        <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
          <div className="m-5">{error}</div>
        </div>
      );
    }

    // Shows the loading spinner if the patient isn't yet loaded.
    if (!patient) {
      return <CenteredSpinner className="w-100 h-100" />;
    }

    return (
      <Profile
        subject={patient}
        onBack={() => {
          history.go(-1);
        }}
        location={location}
        history={history}
      />
    );
  }
}

Patient.propTypes = {
  // This is from react-router-dom.
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.instanceOf(Object),
  }).isRequired,
  patientId: PropTypes.string,
  patient: patientDetailPatientPropType,
  error: PropTypes.string,
  doGetPatient: PropTypes.func.isRequired,
  doGetPatients: PropTypes.func.isRequired,
  doGetCaregivers: PropTypes.func.isRequired,
  doGetPatientAcuity: PropTypes.func.isRequired,
  doGetPatientCarePlan: PropTypes.func.isRequired,
  doGetPatientHosps: PropTypes.func.isRequired,
  doGetPatientIncidents: PropTypes.func.isRequired,
  doGetPatientStats: PropTypes.func.isRequired,
};

Patient.defaultProps = {
  patientId: undefined,
  patient: undefined,
  error: undefined,
};

const mapState = (state) => ({
  patient: state.patientDetail.patient,
  statTime: state.profile.statTime,
  error: state.patientDetail.error,
});

const mapDispatch = (dispatch) => ({
  doGetPatient: (id) => dispatch(getPatient(id)),
  doGetPatients: () => dispatch(getPatients()),
  doGetCaregivers: () => dispatch(getCaregivers()),
  doGetPatientAcuity: (id) => dispatch(getPatientAcuityInfo(id)),
  doGetPatientCarePlan: (id) => dispatch(getPatientCarePlanInfo(id)),
  doGetPatientHosps: (id) => dispatch(getRecentHospitalizations(id)),
  doGetPatientIncidents: (id) => dispatch(getPatientIncidents(id)),
  doGetPatientStats: (id, timeRange) => dispatch(getPptRecentStats(id, timeRange)),
});

export default withRouter(connect(mapState, mapDispatch)(Patient));

import { Input, Text, Icon, RadioButtonV2 } from '@intus-ui';

const DeleteFilterRowButton = ({ handleDeleteFilter }) => (
  <div style={styles.deleteFilterRowButton}>
    <Icon
      name="Remove"
      color="#9D9D9D"
      height="13px"
      width="13px"
      hoverColor="#052d8f"
      onClick={handleDeleteFilter}
    />
  </div>
);

const FilterTimeRange = ({
  isSingleFilterRow,
  displayRange,
  range,
  handleChangeFrequencyType,
  frequency,
  frequencyBetweenLTE,
  handleChangeFrequencyValue,
  displayTitles,
  dateRange,
  changeDateRange,
  filter,
  parentIndex,
  handleDeleteFilter,
}) => {
  return (
    <div style={styles.thirdFilterRow}>
      {!isSingleFilterRow && <div id="secondFilterRowSpacer" style={styles.filterRowSpacer} />}

      <Text>is</Text>
      <RadioButtonV2
        option="At least"
        size="small"
        labelPosition="horizontal"
        selected={range}
        setSelected={handleChangeFrequencyType}
      />

      <RadioButtonV2
        option="At most"
        size="small"
        labelPosition="horizontal"
        selected={range}
        setSelected={handleChangeFrequencyType}
      />
      <RadioButtonV2
        option="Between"
        size="small"
        labelPosition="horizontal"
        selected={range}
        setSelected={handleChangeFrequencyType}
      />
      <input
        min="0"
        type="number"
        className="hover-input-light-blue"
        value={frequency.lte || frequency.gte}
        onChange={handleChangeFrequencyValue}
        style={{
          height: 35,
          width: 60,
          paddingLeft: 15,
          backgroundColor: 'white',
          border: '1px solid #9D9D9D',
          borderRadius: 5,
        }}
      />
      {range === 'Between' && (
        <>
          <Text>-</Text>
          <input
            min="0"
            type="number"
            className="hover-input-light-blue"
            value={frequencyBetweenLTE?.lte}
            onChange={(value) => handleChangeFrequencyValue(value, true)}
            style={{
              height: 35,
              width: 60,
              paddingLeft: 15,
              backgroundColor: 'white',
              border: '1px solid #9D9D9D',
              borderRadius: 5,
            }}
          />
        </>
      )}
      <Text>times within the</Text>
      <Input
        id="Category"
        type="select"
        rightIcon="caret-down"
        name="Category"
        options={displayTitles}
        value={dateRange}
        onChange={changeDateRange}
        style={{
          cursor: 'pointer',
        }}
      />
      {displayRange && filter.groups[parentIndex].conditions.length > 1 && (
        <DeleteFilterRowButton handleDeleteFilter={handleDeleteFilter} />
      )}
    </div>
  );
};

const styles = {
  filterRowWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  radioButtonWrapper: {
    display: 'flex',
    gap: 10,
  },
  secondFilterRow: {
    display: 'flex',
  },
  filterRowSpacer: {
    width: 100,
  },
  thirdFilterRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  deleteFilterRowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export default FilterTimeRange;

import { Card, Text } from '@intus-ui';

const MetricPill = ({ metric }) => {
  return (
    <Card>
      <Text color="navy" type="subtitle">
        {metric}
      </Text>
    </Card>
  );
};

export default MetricPill;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { shadesOfBlue } from '@intus-ui/styles/SecondaryColors';

import Pin from 'assets/Icons/Pin';

import '@intus-ui/styles/Home.scss';
import '@intus-ui/styles/Utilities.scss';

const ListItemPinned = ({
  item,
  format,
  onClick,
  className,
  favorite,
  favoriteList,
  currentlyPinnedIds,
}) => {
  const [isHover, setIsHover] = useState(false);
  if (favorite) {
    return (
      <Row
        className={`d-flex cursor-pointer flex-row align-items-center justify-content-end bg-white rounded-lg py-1 mb-3 pin-item box-shadow-cards ${className}`}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => {
          currentlyPinnedIds(favoriteList.filter((select) => select !== item.id));
        }}
      >
        <Col
          md={12}
          className="w-100 h-100 d-flex flex-row align-items-start justify-content-start pl-2 pr-5 "
        >
          <Pin
            hoverColor="none"
            fillColor={isHover ? shadesOfBlue.intusNavy60 : shadesOfBlue.intusNavy}
          />
          <Row className="w-100 d-flex align-items-center ">
            {format.map((formatItem) => (
              <Col
                key={`format-${Object.values(item).join('//')}${Object.values(formatItem).join(
                  '//'
                )}`}
                className={formatItem.className}
                lg={formatItem.lg}
                md={formatItem.md}
                sm={formatItem.sm}
                xs={formatItem.xs}
              >
                <h4 className="font-weight-normal">{item[formatItem.nodeField]}</h4>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    );
  }
  return (
    <Row
      className={`d-flex flex-row align-items-center justify-content-end cursor-pointer bg-white rounded-lg py-1 mb-3 pin-item box-shadow-cards ${className}`}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => {
        currentlyPinnedIds([item.id, ...favoriteList]);
      }}
    >
      <Col
        lg={12}
        className={`w-100 d-flex flex-row align-items-center justify-content-center bg-white rounded-lg pl-2 ${
          onClick ? 'pr-4 bg-primary' : ''
        }`}
      >
        <Pin hoverColor="none" fillColor={isHover ? shadesOfBlue.intusNavy40 : 'none'} />
        <Row className={`w-100 d-flex align-items-center ml-0 ${onClick ? 'pr-4' : ''}`}>
          {format.map((formatItem) => (
            <Col
              key={`format-${Object.values(item).join('//')}${Object.values(formatItem).join(
                '//'
              )}`}
              className={formatItem.className}
              lg={formatItem.lg}
              md={formatItem.md}
              sm={formatItem.sm}
              xs={formatItem.xs}
            >
              <h4 className="font-weight-normal">{item[formatItem.nodeField]}</h4>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

ListItemPinned.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  format: PropTypes.arrayOf(
    PropTypes.shape({
      valueField: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])
        .isRequired,
      nodeField: PropTypes.node.isRequired,
      lg: PropTypes.number,
      md: PropTypes.number.isRequired,
      sm: PropTypes.number,
      xs: PropTypes.number,
    }).isRequired
  ).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  favorite: PropTypes.bool,
  favoriteList: PropTypes.arrayOf(PropTypes.number),
  currentlyPinnedIds: PropTypes.func,
};

ListItemPinned.defaultProps = {
  onClick: undefined,
  className: '',
  favorite: false,
  favoriteList: undefined,
  currentlyPinnedIds: undefined,
};

export default ListItemPinned;

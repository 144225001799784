import { formatDate, newDate } from '@util/dateFunctions';

const groupLabels: any = {
  all: 'All',
  er: 'ER',
  ltc: 'LTC',
  snf: 'SNF',
  inpatient: 'Inpatient',
  respite: 'Respite',
  alf: 'ALF',
  observation: 'Observation',
};

// Returns the formatted label for a given group type
export const getGroupLabel = (type: string) => {
  return groupLabels[type] || type;
};

// Returns the group type for a given label
export const getLabelGroup = (label: string) => {
  return Object.keys(groupLabels).find((key) => groupLabels[key] === label) || label;
};

export const getAdmissionsChartConfig = (
  admissionsBreakdown: any,
  utilizationTargetsByOrg: any,
  admitType: string,
  isHealthSysAdmin: boolean
) => {
  // Admissions by org config
  const sortedAdmissions = admissionsBreakdown.sort((a: any, b: any) => b.value - a.value);
  const admissionsLabels = isHealthSysAdmin
    ? sortedAdmissions.map((org: any) => org.name)
    : admissionsBreakdown.map((type: any) => type.type);
  const admissionsDataPoints = sortedAdmissions.map((org: any) => org.value);

  // Create a mapping of orgId or admitType to target for easy lookup
  const targetMap = new Map();
  utilizationTargetsByOrg
    .filter((target: any) => {
      if (isHealthSysAdmin) return target.type === 'admissions' && target.admitType === admitType;
      return target.type === 'admissions';
    })
    .forEach((target: any) => {
      if (isHealthSysAdmin) targetMap.set(target.id, target.target);
      else targetMap.set(target.admitType, target.target);
    });

  // Map each org to its target if it exists, otherwise 0
  const admissionsTargets = sortedAdmissions.map((obj: any) => {
    if (isHealthSysAdmin) return targetMap.has(obj.orgId) ? targetMap.get(obj.orgId) : '0';
    return targetMap.has(obj.type) ? targetMap.get(obj.type) : '0';
  });

  return {
    admissionsLabels: admissionsLabels.map((str: any) => getGroupLabel(str)),
    admissionsDataPoints,
    admissionsTargets,
  };
};

export const getLosChartConfig = (
  losBreakdown: any,
  utilizationTargetsByOrg: any,
  admitType: string,
  isHealthSysAdmin: boolean
) => {
  // LOS by org config
  const sortedLos = losBreakdown.sort((a: any, b: any) => b.value - a.value);
  const losLabels = isHealthSysAdmin
    ? sortedLos.map((org: any) => org.name)
    : losBreakdown.map((type: any) => type.type);
  const losDataPoints = sortedLos.map((org: any) => org.value);

  // Create a mapping of orgId or admitType to target for easy lookup
  const targetMap = new Map();
  utilizationTargetsByOrg
    .filter((target: any) => {
      if (isHealthSysAdmin) return target.type === 'los' && target.admitType === admitType;
      return target.type === 'los';
    })
    .forEach((target: any) => {
      if (isHealthSysAdmin) targetMap.set(target.id, target.target);
      else targetMap.set(target.admitType, target.target);
    });

  // Map each org to its target if it exists, otherwise 0
  const losTargets = sortedLos.map((obj: any) => {
    if (isHealthSysAdmin) return targetMap.has(obj.orgId) ? targetMap.get(obj.orgId) : '0';
    return targetMap.has(obj.type) ? targetMap.get(obj.type) : '0';
  });

  return {
    losLabels: losLabels.map((str: any) => getGroupLabel(str)),
    losDataPoints,
    losTargets,
  };
};

export const getAdmittedPatientsMappedByGroup = (admitsByGroup: any, allAdmits: any) => {
  const result: any = {
    all: [],
  };

  admitsByGroup.forEach((group: any) => {
    // Add all admits to the allOrgsAdmits array
    result.all = result.all.concat(group.admits);
    // Add admits for each organization
    result[group.name || group.type] = group.admits;
  });

  // Create a set for unique admit IDs in 'all'
  const allAdmitsSet = new Set(result.all);

  // Aggregate full admit objects for 'all'
  result.all = allAdmits.filter((admit: { id: string }) => allAdmitsSet.has(admit.id));
  // Filter and replace admits for each organization with full admit objects
  Object.keys(result).forEach((key) => {
    if (key !== 'all' && Object.prototype.hasOwnProperty.call(result, key)) {
      result[key] = allAdmits.filter((admit: { id: string }) => result[key].includes(admit.id));
    }
  });

  // Format each each group of admits
  Object.keys(result).forEach((org) => {
    result[org] = getAdmmittedParticipants(result[org]);
  });

  return result;
};

export const getAdmmittedParticipants = (selectedAdmissions: any) => {
  if (selectedAdmissions) {
    return selectedAdmissions.map(
      ({ patient, admitDate }: { id: any; patient: any; admitDate: any }) => ({
        id: patient.id,
        name: `${patient.firstName} ${patient.lastName}`,
        memberId: patient.memberId,
        admitDate: formatDate(newDate(admitDate), 'M/D/YYYY'),
      })
    );
  }
  return [];
};

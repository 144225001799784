import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useFeatureFlag } from '@util';
import { getSessionUser } from '@util/session';

import { setIsHealthSysAdmin } from '@global-state/redux/filtersSlice';
import { getRecentLoginsForSurvey } from '@global-state/redux/helpSlice';
import { setSelected, setEdit, resetModal } from '@global-state/redux/homepageSlice';
import { getPatients } from '@global-state/redux/patientListSlice';
import { getUserInfo, getHomePageBox } from '@global-state/redux/userSettingsSlice';

import { Button, Icon, ParticipantFiltersContext } from '@intus-ui';
import LoadingWelcomeFlow from '@intus-ui/components/LoadingWelcomeFlow';
import TabPageBody from '@intus-ui/components/layout/TabPageCore/TabPageBody';
import TabPageCol from '@intus-ui/components/layout/TabPageCore/TabPageCol';
import TabPageHeader from '@intus-ui/components/layout/TabPageCore/TabPageHeader';

import DashboardModal from 'Dashboard/DashboardModal';
import { initializeCustomDashboard } from 'Dashboard/normalizedDashRedux/customDashboardSlice';
import SurveyModal from 'Help/SurveyModal';
import EditHomeModal from 'Homes/HomePage/EditHomeModal';
import HomeModal from 'Homes/HomePage/HomeModal';
import HomePageQuadContent from 'Homes/HomePage/HomePageQuadContent';
import HomeTabs from 'Homes/HomePage/HomeTabs';
import PinnedParticipantsTab from 'Homes/HomePage/PinnedParticipantsTab';
import RecentEventsTab from 'Homes/HomePage/RecentEventsTab';
import WarningSignalsTab from 'Homes/HomePage/WarningSignalsTab';
import userSettingsPropType, { USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import CongratulationsModal from 'Welcome/CongratulationsModal';

import '@intus-ui/styles/Utilities.scss';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const {
      doGetUserLocalData,
      doGetPatients,
      doInitializeCustomDashboard,
      doGetHomePageBox,
      doSetSurveyModal,
      doSetIsHealthSysAdmin,
    } = this.props;
    const { access } = getSessionUser();
    doGetUserLocalData(true);
    const isHealthSysAdmin = access === USER_ACCESS_HEALTH_SYS_ADMIN;
    doSetIsHealthSysAdmin(isHealthSysAdmin);
    doInitializeCustomDashboard();
    doGetPatients();
    doGetHomePageBox();
    doSetSurveyModal();
  }

  render() {
    const { userSettings, doSetHomeEditModal } = this.props;
    const { selectedFilters } = this.context;

    const { loading } = this.state;

    const { profile } = userSettings;
    if (!profile) {
      return null;
    }
    const { setting } = profile;
    if (loading && !setting?.welcomeFlowCompleted) {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 3000);
      return <LoadingWelcomeFlow />;
    }

    const { featureIsActive: warningSignalsIsActive } = useFeatureFlag('warning-signals');

    const homeSideTabs = warningSignalsIsActive
      ? [{ tab: 'Warning Signals', content: <WarningSignalsTab /> }]
      : [
          { tab: 'Events', content: <RecentEventsTab /> },
          { tab: 'Pinned', content: <PinnedParticipantsTab /> },
        ];

    return (
      <div className="bg-light h-100 w-100 d-flex align-items-stretch justify-content-center flex-column overflow-hidden">
        {/* HomeModal is used all (first layer) popups, displays subcards when previews are
        clicked */}
        <HomeModal />
        <CongratulationsModal />
        <EditHomeModal />
        <SurveyModal />
        {/* DashboardModal is used for the second layer popup, when subcards can be clicked into
        for ppt list view */}
        <DashboardModal />
        <TabPageHeader title="Home Page">
          <Button
            secondary
            onClick={() => {
              doSetHomeEditModal();
            }}
          >
            <Icon name="edit" />
            Edit
          </Button>
        </TabPageHeader>
        <TabPageBody>
          <TabPageCol colNum={8}>
            <HomePageQuadContent userSettings={userSettings} selectedFilters={selectedFilters} />
          </TabPageCol>
          <TabPageCol colNum={4} padMarg="py-3">
            <HomeTabs items={homeSideTabs} tabId="homepage-sideBar-tab" />
          </TabPageCol>
        </TabPageBody>
      </div>
    );
  }
}

HomePage.contextType = ParticipantFiltersContext;

HomePage.propTypes = {
  userSettings: userSettingsPropType.isRequired,
  doGetUserLocalData: PropTypes.func.isRequired,
  doInitializeCustomDashboard: PropTypes.func.isRequired,
  doGetPatients: PropTypes.func.isRequired,
  doSetHomeEditModal: PropTypes.func.isRequired,
  doGetHomePageBox: PropTypes.func.isRequired,
  doSetSurveyModal: PropTypes.func.isRequired,
  doSetIsHealthSysAdmin: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  userSettings: state.userSettings,
  hPageBoxes: state.welcome.homePageBoxes,
});

const mapDispatch = (dispatch) => ({
  doGetUserLocalData: (setFilters) => dispatch(getUserInfo(setFilters)),
  doSetSelected: (val) => dispatch(setSelected(val)),
  doInitializeCustomDashboard: () => dispatch(initializeCustomDashboard()),
  doGetPatients: () => dispatch(getPatients()),
  doResetModal: () => dispatch(resetModal()),
  doSetHomeEditModal: () => dispatch(setEdit()),
  doGetHomePageBox: () => dispatch(getHomePageBox()),
  doSetSurveyModal: () => dispatch(getRecentLoginsForSurvey()),
  doSetIsHealthSysAdmin: (value) => dispatch(setIsHealthSysAdmin(value)),
});

export default connect(mapState, mapDispatch)(HomePage);

import { Text, layout } from '@intus-ui';

const MenuItem = ({ name, active, ...prop }) => (
  <Text type="subtitle" style={styles.menuItem} color={active ? 'navy' : 'link'} {...prop}>
    {name}
  </Text>
);

const SideMenu = ({ style, items, children }) => {
  return (
    <div style={{ ...layout.sideDrawer, ...style }}>
      {items.map(({ name, active, onClick }) => (
        <MenuItem key={name} name={name} active={active} onClick={onClick} />
      ))}
      {children}
    </div>
  );
};

const styles = {
  menuItem: {
    padding: '5.5px 15px',
  },
};

export default SideMenu;

import React from 'react';
import CenteredSpinner from './CenteredSpinner';

const LoadingScreen = () => (
  <div
    className="w-100 h-100 position-absolute"
    style={{
      top: 0,
      left: 0,
      zIndex: 99,
      backgroundColor: 'rgba(255, 255, 255, .5)',
    }}
  >
    <CenteredSpinner className="w-100 h-100" />
  </div>
);

export default LoadingScreen;

import { useState, useEffect } from 'react';

import { getPatientCurrentMedications } from '@api/api';
import { getLogger } from '@util/logger';

import { MedicationsList } from '@intus-ui';

const log = getLogger('CurrentMedicationsDetails');

const CurrentMedicationsDetails = ({ patientId }) => {
  const [medications, setMedications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPatientCurrentMedications(patientId)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setMedications(data);
          setLoading(false);
        }
      })
      .catch((err) => {
        log.error(err);
        setLoading(false);
      });
  }, []);

  return <MedicationsList data={medications} loading={loading} searchable />;
};

export default CurrentMedicationsDetails;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isSameOrAfterDate } from '@util/dateFunctions';

import { getUserInfo } from '@global-state/redux/userSettingsSlice';

import { SpinnerOrError } from '@intus-ui';

import { Container, Row, FormControl } from 'react-bootstrap';
import List from './List';
import FilteredListHeader from './FilteredListHeader';

import '@intus-ui/styles/FilteredList.scss';
import '@intus-ui/styles/Utilities.scss';

const FilteredList = ({
  items,
  format,
  onClick,
  title,
  toolbar,
  flat,
  search,
  sideList,
  filters,
  fieldFilters,
  defaultSorting,
  emptyText,
  selected,
  pinned,
  doGetUserLocalData,
  loading,
}) => {
  // Manages the sorting state.
  const [sorting, setSorting] = React.useState(defaultSorting);

  useEffect(() => {
    doGetUserLocalData();
  }, [doGetUserLocalData]);

  const updateFilter = (clickedFormat) => {
    let nextValue = true;
    if (sorting.valueField === clickedFormat.valueField) {
      // Goes to the next state.
      if (sorting.value === true) {
        nextValue = false;
      } else if (sorting.value === false) {
        nextValue = undefined;
      }
    }
    setSorting({
      valueField: clickedFormat.valueField,
      value: nextValue,
    });
  };

  // Manages the searching state.
  const [searchValue, setSearchValue] = React.useState('');
  // Does the actual sorting and searching.
  const sort = (sortItems) => {
    let filteredBySearch = sortItems;
    if (searchValue) {
      filteredBySearch = sortItems.filter((item) =>
        `${item[search.valueField]}`.toUpperCase().includes(searchValue.toUpperCase())
      );
    }
    if (sorting.value === undefined || !format) {
      return filteredBySearch;
    }
    return filteredBySearch.sort((a, b) => {
      let comparison = 0;
      const colFormat = format.find((f) => f.valueField === sorting.valueField);
      if (colFormat) {
        if (colFormat.sorting === 'lexographical') {
          comparison = a[sorting.valueField].localeCompare(b[sorting.valueField], undefined, {
            sensitivity: 'accent',
          });
        } else if (colFormat.sorting === 'numerical') {
          comparison = a[sorting.valueField] - b[sorting.valueField];
        } else if (colFormat.sorting === 'date') {
          comparison = isSameOrAfterDate(a[sorting.valueField], b[sorting.valueField]) ? 1 : -1;
        }
      }
      return sorting.value ? comparison : -comparison;
    });
  };

  return (
    <div
      className={`${
        flat ? '' : 'box-shadow-on-gray'
      } h-100 w-100 bg-white align-self-center overflow-hidden flex-column ${
        sideList ? '' : 'rounded-lg my-3'
      }`}
    >
      <Container className="sticky bg-white" fluid>
        <Row
          className={`d-flex justify-content-between align-items-start w-100 px-1 ${
            sideList ? 'm-0 pt-2' : 'mx-3 pb-2 pt-4'
          }`}
        >
          <div className="d-flex flex-row align-self-center">
            {search ? (
              <FormControl
                className="rounded-xlg"
                style={{ width: '20rem' }}
                placeholder={`Search by ${search.valueField}`}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            ) : null}
            {toolbar}
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center px-4">
            {!sideList && title}
          </div>
        </Row>
      </Container>
      <Row
        className={`mt-1 text-muted border-bottom align-items-center ${
          filters !== undefined ? ' justify-content-around pb-2' : 'pb-1 mx-4 '
        } ${sideList ? 'px-3' : 'px-5'}`}
      >
        {format.map((formatItem) => {
          const filterIndex = fieldFilters.indexOf(formatItem.heading);
          return (
            <FilteredListHeader
              format={formatItem}
              sorting={sorting}
              fieldFilters={fieldFilters}
              filter={filterIndex >= 0 ? filters[filterIndex] : null}
              onClick={updateFilter}
              key={JSON.stringify(formatItem)}
            />
          );
        })}
        {filters !== undefined && fieldFilters.length === 0 ? filters : null}
      </Row>
      <div className="w-100 overflow-scroll flex-grow pt-2 pb-4">
        {loading && <SpinnerOrError />}
        {!loading && (
          <List
            className={`${sideList ? 'mx-0 my-2' : 'mx-4 my-2'}`}
            onClick={onClick}
            items={sort(items)}
            format={format}
            emptyText={emptyText}
            selected={selected}
            pinned={pinned}
          />
        )}
      </div>
    </div>
  );
};

FilteredList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired),
  format: PropTypes.arrayOf(
    PropTypes.shape({
      valueField: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])
        .isRequired,
      nodeField: PropTypes.node.isRequired,
      lg: PropTypes.number,
      md: PropTypes.number,
      sm: PropTypes.number,
      xs: PropTypes.number,
      heading: PropTypes.string.isRequired,
      additionalHeadingNode: PropTypes.node,
      sorting: PropTypes.oneOf(['lexographical', 'numerical', 'none', 'date']),
    }).isRequired
  ).isRequired,
  onClick: PropTypes.func,
  title: PropTypes.node,
  toolbar: PropTypes.node,
  search: PropTypes.shape({
    valueField: PropTypes.string.isRequired,
    value: PropTypes.string,
  }),
  defaultSorting: PropTypes.shape({
    valueField: PropTypes.string,
    value: PropTypes.node,
  }),
  emptyText: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.object),
  sideList: PropTypes.bool,
  fieldFilters: PropTypes.arrayOf(PropTypes.string),
  pinned: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.object),
  doGetUserLocalData: PropTypes.func.isRequired,
};

FilteredList.defaultProps = {
  items: [],
  onClick: () => {},
  title: undefined,
  toolbar: undefined,
  search: undefined,
  defaultSorting: {},
  emptyText: '',
  filters: [],
  sideList: false,
  fieldFilters: [],
  pinned: false,
  selected: undefined,
};

const mapStateToProps = (state) => ({
  subscriptions: state.userSettings.subscriptions,
});

const mapDispatch = (dispatch) => ({
  doGetUserLocalData: () => dispatch(getUserInfo()),
});

export default connect(mapStateToProps, mapDispatch)(FilteredList);

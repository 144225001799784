import { CSSProperties } from 'react';

const fontWeight = { bold: 700, semiBold: 600, medium: 500, regular: 400 };

type AllFonts =
  | 'display'
  | 'headline'
  | 'title'
  | 'subtitle'
  | 'body'
  | 'caption-bold'
  | 'caption'
  | 'tiny-bold'
  | 'tiny'
  | 'all-caps';

export const fonts: Record<AllFonts, CSSProperties> = {
  display: {
    fontFamily: 'Inter',
    letterSpacing: '-0.01em', //  tracking
    lineHeight: '130%',
    fontWeight: fontWeight.bold,
    fontSize: '72px',
  },
  headline: {
    fontFamily: 'Inter',
    letterSpacing: '-0.01em', //  tracking
    lineHeight: '130%',
    fontWeight: fontWeight.bold,
    fontSize: '28px',
  },
  title: {
    fontFamily: 'Inter',
    letterSpacing: '-0.01em', //  tracking
    lineHeight: '130%',
    fontWeight: fontWeight.bold,
    fontSize: '18px',
  },
  subtitle: {
    fontFamily: 'Inter',
    letterSpacing: '-0.01em', //  tracking
    lineHeight: '130%',
    fontWeight: fontWeight.bold,
    fontSize: '15px',
  },
  body: {
    fontFamily: 'Inter',
    letterSpacing: '-0.01em', //  tracking
    lineHeight: '130%',
    fontWeight: fontWeight.regular,
    fontSize: '15px',
  },
  'caption-bold': {
    fontFamily: 'Inter',
    letterSpacing: '-0.01em', //  tracking
    lineHeight: '130%',
    fontWeight: fontWeight.bold,
    fontSize: '12px',
  },
  caption: {
    fontFamily: 'Inter',
    letterSpacing: '-0.01em', //  tracking
    lineHeight: '130%',
    fontWeight: fontWeight.regular,
    fontSize: '12px',
  },
  'tiny-bold': {
    fontFamily: 'Inter',
    letterSpacing: '-0.01em', //  tracking
    lineHeight: '130%',
    fontWeight: fontWeight.bold,
    fontSize: '10px',
  },
  tiny: {
    fontFamily: 'Inter',
    letterSpacing: '-0.01em', //  tracking
    lineHeight: '130%',
    fontWeight: fontWeight.regular,
    fontSize: '10px',
  },
  'all-caps': {
    fontFamily: 'Inter',
    letterSpacing: '-0.01em', //  tracking
    lineHeight: '130%',
    fontWeight: fontWeight.bold,
    fontSize: '12px',
    textTransform: 'uppercase',
  },
};

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';

import { useFeatureFlag } from '@util';
import { setSelectedTab } from '@global-state/redux/homepageSlice';

import { Button, Icon, SingleSelect } from '@intus-ui';
import PinnedModal from './PinnedModal';
import RecentEventModal from './RecentEventModal';

import '@intus-ui/styles/Utilities.scss';

const HomeTabs = ({
  items,
  tabId,
  selected,
  doSetSelectedTab,
  collapseOnClick,
  toggleState,
  patients,
}) => {
  const [openEditPinnedModal, setOpenEditPinnedModal] = useState(false);
  const [openEditRecentModal, setOpenEditRecentModal] = useState(false);

  const active = patients ? 0 : selected;
  const activeTab = active === 0 ? 'Events' : 'Pinned';

  const { featureIsActive: warningSignalsIsActive } = useFeatureFlag('warning-signals');

  return (
    <>
      <PinnedModal showModal={openEditPinnedModal} hide={setOpenEditPinnedModal} />
      <RecentEventModal showModal={openEditRecentModal} hide={setOpenEditRecentModal} />
      <div className="w-100 h-100 d-flex flex-column box-shadow rounded-lg overflow-hidden">
        <Tabs
          className="d-flex home-tabs"
          id={tabId}
          activeKey={active}
          onSelect={(tab) => doSetSelectedTab(tab)}
          transition={false}
        >
          {items.map(({ tab }, index) => (
            <Tab
              tabClassName="d-flex flex-row flex-grow-1 py-0 px-0 justify-content-center align-items-center rounded-lg-top box-shadow"
              key={`tab-${tab}`}
              eventKey={index}
              title={
                <div className="d-flex pl-2 py-0 flex-row align-items-center justify-content-between w-100 h-100 align-items-center text-secondary">
                  <div className="d-flex flex-row align-items-center pl-2">
                    {collapseOnClick && (
                      <Button
                        secondary
                        onClick={collapseOnClick}
                        style={{ margin: '7.5px 7.5px 7.5px 0' }}
                      >
                        <Icon name={toggleState ? 'expand-right' : 'expand-left'} />
                      </Button>
                    )}
                    <h3>{tab}</h3>
                  </div>

                  {warningSignalsIsActive && tab === 'Warning Signals' && (
                    <div style={{ margin: 10 }}>
                      <SingleSelect
                        rightIcon
                        rightAligned
                        options={['All', 'Open', 'Closed']}
                        defaultValue="All"
                      />
                    </div>
                  )}

                  {tab !== 'Warning Signals' &&
                    toggleState &&
                    (activeTab === tab || items.length === 1) && (
                      <Button
                        secondary
                        style={{ margin: 7.5 }}
                        onClick={
                          tab === 'Pinned'
                            ? () => {
                                setOpenEditPinnedModal(true);
                              }
                            : () => {
                                setOpenEditRecentModal(true);
                              }
                        }
                      >
                        <Icon name="edit" />
                        Edit
                      </Button>
                    )}
                </div>
              }
            />
          ))}
        </Tabs>
        <div className="d-flex flex-grow-1 bg-white justify-content-start align-items-start overflow-hidden rounded-lg-bottom mh-100">
          {items[active].content}
        </div>
      </div>
    </>
  );
};

HomeTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
  tabId: PropTypes.string.isRequired,
  doSetSelectedTab: PropTypes.func.isRequired,
  selected: PropTypes.number,
  collapseOnClick: PropTypes.func,
  toggleState: PropTypes.bool,
  patients: PropTypes.bool,
};

HomeTabs.defaultProps = {
  patients: false,
  selected: 0,
  collapseOnClick: undefined,
  toggleState: true,
};

const mapState = (state) => ({
  selected: state.homepage.selectedTab,
});

const mapDispatch = (dispatch) => ({
  doSetSelectedTab: (val) => dispatch(setSelectedTab(val)),
});

export default connect(mapState, mapDispatch)(HomeTabs);

import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';

import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import { brightColorsRGB } from '@intus-ui/styles/NivoColors';
import { ChartData, ChartOptions } from 'chart.js';

type VerticalBarProps = {
  legendDisplay?: boolean;
  bgColors?: string[];
  yData: number[];
  xData: string[];
  yLabel?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  numBars: number;
};

const VerticalBar: FC<VerticalBarProps> = ({
  yData,
  yLabel = '',
  xData,
  legendDisplay = false,
  bgColors = brightColorsRGB,
  xAxisLabel = '',
  yAxisLabel = '',
  numBars = 13,
}) => {
  if (!yData || !xData) {
    return <CenteredSpinner />;
  }

  const bgColorsArray = [];
  while (bgColorsArray.length < numBars) {
    bgColorsArray.push(...bgColors);
  }

  const data: ChartData<'bar', number[], string> = {
    labels: xData.slice(0, numBars - 1),
    datasets: [
      {
        label: yLabel,
        data: yData.slice(0, numBars - 1),
        backgroundColor: bgColorsArray,
        borderColor: bgColors,
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    responsive: true,
    datasets: {
      bar: {
        barPercentage: 1.0,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: yAxisLabel,
          font: {
            weight: 'bold',
          },
        },
        ticks: {
          precision: 1,
          maxTicksLimit: 8,
        },
        border: {
          display: false,
        },
      },
      x: {
        title: {
          display: true,
          text: xAxisLabel,
          font: {
            weight: 'bold',
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: legendDisplay,
        title: {
          display: legendDisplay,
        },
        labels: {
          padding: 0,
          boxWidth: 10,
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default VerticalBar;

import React, { useEffect } from 'react';
import '@intus-ui/styles/Utilities.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getModalContent } from '@global-state/redux/homepageSlice';
import { Icon, TeamFacilityFilter, Text, useParticipantFilters } from '@intus-ui';
import HospitalizationAnalytics from 'Homes/HomePage/ModalContent/HospitalizationAnalytics';
import DashboardModal from 'Dashboard/DashboardModal';

export const HospitalizationWrapper = () => {
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.filters.startDate);
  const endDate = useSelector((state) => state.filters.endDate);

  const { selectedFilters } = useParticipantFilters();

  useEffect(() => {
    dispatch(getModalContent('hospitalizations', startDate, endDate, selectedFilters));
  }, [startDate, endDate, selectedFilters]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        backgroundColor: '#f6f7fa',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          gap: 15,
          zIndex: 10,
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 30px 0px 30px',
        }}
      >
        <DashboardModal />
        <div id="header" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Text type="title" color="navy">
            Hospitalizations
          </Text>
          <Icon name="chart" size="medium" className="ml-2 text-muted" />
        </div>

        <div id="dropdowns" style={{ display: 'flex', flexDirection: 'row', gap: 15, zIndex: 10 }}>
          <TeamFacilityFilter />
        </div>
      </div>
      <HospitalizationAnalytics isDashboard />;
    </div>
  );
};

export default HospitalizationWrapper;

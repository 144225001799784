import { Bar } from 'react-chartjs-2';
import { useEffect, useRef, useState } from 'react';
import { isAllZeroOrEmpty } from '@util';
import { useReactChartTooltipV2 } from '@intus-ui/components/graphs/useReactChartTooltipV2';
import { initiativeChartColors } from '@intus-ui/styles/SecondaryColors';
import { formatDate, isValidDate, isSameDate, newDate } from '@util/dateFunctions';
import Text from '@intus-ui/components/Text';
import layouts from '@intus-ui/layouts';
import {
  adjustColorBrightness,
  createDefaultChartConfig,
  generateHighlightColorArrayAtIndex,
} from './chartConfig';

const BarChart = ({
  metricData,
  isHorizontalBarChart = false,
  isTimeAxis = false,
  onClickBar,
  suggestedMaxValue = null,
  inFocusView = false,
}) => {
  const [chartDataConfig, setChartDataConfig] = useState({});

  // The width and height to render the chart, calculated based on the size of the container.
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const containerRef = useRef();

  /** @type {import('chart.js').ChartOptions<'bar'>['onClick']} */
  const onClickBarExt = (_event, elements) => {
    if (!onClickBar) return;
    const [firstElement] = elements;

    if (firstElement) {
      const { datasetIndex, index } = firstElement;
      const label = chartDataConfig.labels[index];

      // Update chart data for highlight
      const newChartData = {
        ...chartDataConfig,
        datasets: chartDataConfig.datasets.map((dataset, i) => ({
          ...dataset,
          backgroundColor:
            i === datasetIndex
              ? generateHighlightColorArrayAtIndex(metricData?.color, dataset.data.length, index)
              : dataset.backgroundColor,
        })),
      };

      onClickBar(label);
      setChartDataConfig(newChartData);
    } else {
      // Reset chart data
      const resetChartData = createDefaultChartConfig(metricData, metricData.color);
      setChartDataConfig({
        ...resetChartData,
        datasets: resetChartData.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: Array(dataset.data.length).fill(dataset.backgroundColor),
        })),
      });
      onClickBar(null);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const rect = container.getBoundingClientRect();
      setWidth(rect.width);
      setHeight(rect.height);
    }
  }, []);

  useEffect(() => {
    if (metricData) {
      const chartConfig = createDefaultChartConfig(metricData, metricData.color);
      setChartDataConfig(chartConfig);
    }
  }, [metricData]);

  const { tooltipOptions, tooltipComponent } = useReactChartTooltipV2(
    (dataIndex) => (
      <BarChartTooltip dataIndex={dataIndex} metricData={metricData} chartData={chartDataConfig} />
    ),
    isHorizontalBarChart
  );
  const barChartAxisLabel = chartDataConfig.datasets && chartDataConfig.datasets[0]?.label;

  // Optional chart options
  /** @type {import('chart.js').ChartOptions<'bar'>} */
  const options = {
    indexAxis: isHorizontalBarChart ? 'y' : 'x',
    responsive: false,
    onHover(event, element) {
      if (element.length && inFocusView) {
        event.native.target.style.cursor = 'pointer';
      } else {
        event.native.target.style.cursor = 'default';
      }
    },
    maintainAspectRatio: false,
    // Turn off the animation since it doesn't work with the custom lines.
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        ...tooltipOptions,
        mode: 'nearest',
        intersect: false,
      },
    },
    scales: {
      x: {
        title: {
          display: isHorizontalBarChart,
          text: barChartAxisLabel,
        },
        position: isHorizontalBarChart ? 'top' : undefined,
        suggestedMax: isHorizontalBarChart && suggestedMaxValue ? suggestedMaxValue : undefined,
        suggestedMin: isHorizontalBarChart ? 0 : undefined,
        ticks: {
          maxTicksLimit: 24,
          callback: (value, tickIndex) => {
            // If it's a horizontal bar chart, we want to display the value on the chart.
            if (isHorizontalBarChart) return value;

            const label = chartDataConfig.labels[tickIndex];
            if (!isTimeAxis) {
              return label;
            }
            // If we're in the current month, display MTD on the chart.
            if (isSameDate(newDate(), label, 'month')) {
              return `${formatDate(label, "MMM 'YY")} (MTD)`;
            }
            return formatDate(label, "MMM 'YY");
          },
        },
        grid: {
          display: isHorizontalBarChart,
        },
      },
      y: {
        // The suggestedMax here is only used if all data points are below 5
        // We do this to prevent the graph from looking bad for small numbers.
        suggestedMax: !isHorizontalBarChart && suggestedMaxValue ? suggestedMaxValue : 5,
        suggestedMin: 0,
        title: {
          display: !isHorizontalBarChart,
          text: barChartAxisLabel,
        },
        ticks: {
          maxTicksLimit: 8,
          font: {
            size: 12,
          },
          precision: 0,
        },
        grid: {
          display: !isHorizontalBarChart,
        },
      },
    },
    onClick: onClickBarExt,
  };

  const dataset = chartDataConfig.datasets?.[0];
  const noDataAvailable = !dataset?.data || isAllZeroOrEmpty(dataset.data);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={containerRef}>
      {/* Do not render the chart until we have a width/height for the parent container */}
      {width && (
        <>
          {tooltipComponent}
          {noDataAvailable && <NoDataComponent />}

          {!noDataAvailable && (
            <Bar width={width} height={height} data={chartDataConfig} options={options} dir="" />
          )}
        </>
      )}
    </div>
  );
};

const NoDataComponent = () => (
  <div style={{ height: '100%', ...layouts.centeredContentContainer }}>
    <Text>There is no data in this time range</Text>
  </div>
);
const BarChartTooltip = ({ dataIndex = 0, chartData = {}, metricData }) => {
  const { labels = [], datasets = [] } = chartData;
  const { label: datasetLabel = 'N/A', data = [] } = datasets[0] || {};

  let barLabel = 'N/A';
  if (isValidDate(labels[dataIndex])) {
    barLabel = formatDate(labels[dataIndex], "MMM 'YY");
  } else {
    barLabel = labels[dataIndex];
  }

  let barValue = 'N/A';
  if (data[dataIndex] != null) barValue = data[dataIndex];
  const metricColor = initiativeChartColors[metricData?.color] || '#fff';
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <Text type="caption-bold">{barLabel}</Text>
      <div style={{ display: 'flex', gap: 5 }}>
        <div
          style={{
            width: '16px',
            height: '16px',
            backgroundColor: adjustColorBrightness(metricColor, 0.5),
            border: `1px solid ${metricColor}`,
          }}
        />
        <Text type="caption-bold">{datasetLabel}:</Text>
        <Text type="caption">{barValue}</Text>
      </div>
    </div>
  );
};

export default BarChart;

import { chunk } from 'lodash';
import { useMemo, useState } from 'react';

export function usePaginatedChart(chartData, numberOfItemsPerPage = 5) {
  const [page, setPage] = useState(0);

  const paginatedChartData = useMemo(() => {
    if (!chartData) return chartData;

    const { labels = [], datasets = [] } = chartData;

    if (datasets.length === 0) return chartData;

    const chunkedLabels = chunk(labels, numberOfItemsPerPage);

    return {
      ...chartData,
      labels: chunkedLabels[page],
      datasets: datasets.map((dataset) => {
        return {
          ...dataset,
          data: chunk(dataset.data, numberOfItemsPerPage)[page],
        };
      }),
    };
  }, [chartData, page, numberOfItemsPerPage]);

  const totalItems = chartData?.labels?.length ?? 0;
  const totalPages = Math.ceil(totalItems / numberOfItemsPerPage);

  // Calculate the range of items being displayed
  const startingItem = page * numberOfItemsPerPage + 1;
  const endingItem = Math.min((page + 1) * numberOfItemsPerPage, totalItems);

  return {
    paginatedChartData,
    page,
    setPage,
    totalItems,
    totalPages,
    startingItem,
    endingItem,
  };
}

import { get } from '@api/apiMethods';
import { buildPatientFilterQueryString } from '@api/patients/buildPatientFilterQueryString';
import { formatDate } from '@util/dateFunctions';
import dayjs from 'dayjs';

export type EHRBenchmarkChartData = {
  color: string;
  label: string;
  data: {
    month: string;
    value: number;
  }[];
};

type EHRBenchmarkCard = {
  chartTitle: string;
  yLabel: string;
  myOrg: EHRBenchmarkChartData;
  otherOrgs: EHRBenchmarkChartData;
};

export type EHRBenchmarkData = {
  averageLengthOfStay: {
    myOrg: {
      value: number;
    };
    otherOrgs: {
      value: number;
    };
  };
  ehr: string;
  ehrName: string;
  erVisits: EHRBenchmarkCard;
  falls: EHRBenchmarkCard;
  inpatientVisits: EHRBenchmarkCard;
  totalLengthOfStay: EHRBenchmarkCard;
};

export function getEHRBenchmark(
  startDate: string | Date | dayjs.Dayjs,
  endDate: string | Date | dayjs.Dayjs,
  filters: Record<string, unknown>
) {
  const patientFilters = buildPatientFilterQueryString(filters);

  const urlParams = new URLSearchParams(patientFilters);
  urlParams.set('startDate', formatDate(startDate, 'YYYY-MM-DD'));
  urlParams.set('endDate', formatDate(endDate, 'YYYY-MM-DD'));

  return get<EHRBenchmarkData>(`benchmark?${urlParams.toString()}`);
}

import React from 'react';
import { Link } from 'react-router-dom';
import BoxOnBlue from '../@intus-ui/components/BoxOnBlue';

const NotFound = () => (
  <BoxOnBlue logo>
    <div className="d-flex flex-column align-items-center text-dark">
      <h5 className="pt-4 pb-2">Sorry, this page does not exist</h5>
      <h5 className="font-weight-bold pb-5">
        Go back to <Link to="/">Login Page</Link>
      </h5>
    </div>
  </BoxOnBlue>
);

export default NotFound;

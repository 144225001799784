import { Text } from '@intus-ui';
import lg from 'assets/logo.svg';

import HelpButton from 'Help/HelpButton';

import '@intus-ui/styles/Login.scss';
import '@intus-ui/styles/Utilities.scss';
import { getUserByIdAPI } from '@api';
import { useQuery } from '@api/useQuery';
import { getSessionUser } from '@util/session';

const Maintenance = () => {
  const userId = getSessionUser()?.id;

  // if this API call succeeds, we know maintenance is over and we can redirect to the redirectTo URL if it exists or /home
  useQuery(() => getUserByIdAPI(userId), {
    onSuccess: () => {
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const redirectTo = queryParams.get('redirectTo');
      const targetUrl = `${window.location.origin}${redirectTo || '/home'}`;
      window.location.replace(targetUrl);
    },
  });

  return (
    <>
      <HelpButton />
      <div className="blue-background w-100 h-100">
        <div className="d-flex align-items-center justify-content-center blue-background h-100 w-100">
          <div
            className="bg-white hover-box"
            style={{ maxWidth: '30rem', padding: '10px 15px 15px 15px' }}
          >
            <div className="d-flex w-100 flex-column align-items-center justify-content-center pb-4">
              <img
                style={{ marginBottom: '5px', height: '100px' }}
                src={lg}
                alt="IntusCare logo"
                className="logo"
              />
            </div>
            <div className="d-flex flex-column align-items-center text-dark">
              <Text type="headline" color="navy">
                We&apos;ll Be Back Soon
              </Text>
              <Text style={{ textAlign: 'center' }}>
                We are working on getting your data up to date. We apologize for any inconvenience.
                Please try again later.
              </Text>
              <Text type="caption" color="caption" style={{ textAlign: 'center', marginTop: 40 }}>
                In the meantime, you can contact us using the button in the bottom right. We’d love
                to hear your feedback!
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maintenance;

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Quad from 'Dashboard/Quad';
import TextData from 'Dashboard/QuadContent/TextData';
import CostTable from 'Dashboard/Tables/CostTable';
import '@intus-ui/styles/Utilities.scss';
import {
  setSelected,
  addModalContent,
  resetModalContent,
  setSelectedMonth,
  setMonthIndexMap,
} from '@global-state/redux/dashboardSlice';
import { moneyAbbreviate, sumUp, truncate, toSentenceCase } from '@util/utilFunctions';
import { FinModalPropType } from 'Homes/modalPropTypes/finModalPropType';
import { brightColorsRGB } from '@intus-ui/styles/NivoColors';
import { defaultChartColors } from '@intus-ui/styles/SecondaryColors';
import BarChart from '@intus-ui/components/graphs/BarChart';
import DonutChart from '@intus-ui/components/graphs/DonutChart';
import { SpinnerOrError } from '@intus-ui';
import { formatDate } from '@util/dateFunctions';
import { getStringReplacement } from '@util/stringReplacements';
import PercentageRow from '../PercentageRow';
import PercentageCol from '../PercentageCol';

const nivoOrder = [
  brightColorsRGB[9],
  brightColorsRGB[3],
  brightColorsRGB[8],
  brightColorsRGB[7],
  brightColorsRGB[1],
  defaultChartColors.skyBlue,
  defaultChartColors.tealBlue,
  defaultChartColors.lightLilac,
  defaultChartColors.deepBlue,
  defaultChartColors.mediumLilac,
  defaultChartColors.fadedSkyBlue,
  defaultChartColors.fadedTealBlue,
];

class FinAnalytics extends React.Component {
  render() {
    const {
      error,
      subcards,
      doSetSelected,
      doSetModalContent,
      doResetModalContent,
      selectedMonth,
      isDashboard,
      doSetMonthMap,
    } = this.props;

    if (error || !subcards) {
      return <SpinnerOrError error={error} />;
    }

    // returns a populated validationErrors, a map of errors, that corresponds with subcards data structure
    // if the data is the wrong type of null, the value in validationErrors is an error message
    // if the data has no errors, the value in validationErrors is null
    const schema = {
      externalCostTimeLine: (value) => value?.length > 0,
      averageCostPerParticipantTimeline: (value) => value?.length > 0,
      reimbursementTypeBreakdown: () => true,
    };
    const validationErrors = {
      externalCostTimeLine: null,
      averageCostPerParticipantTimeline: null,
      reimbursementTypeBreakdown: null,
    };

    const validate = (object, schemaVar) =>
      Object.keys(schemaVar).forEach((key) => {
        if (!schemaVar[key](object[key])) {
          validationErrors[key] = `Could not fetch ${toSentenceCase(key)} data.`;
        }
      });
    validate(subcards, schema);

    // 1 - external cost timeline
    const renderExtTrendline = () => {
      if (validationErrors.externalCostTimeLine) {
        return (
          <Quad title="External Cost">
            <SpinnerOrError error={validationErrors.externalCostTimeLine} />
          </Quad>
        );
      }
      const showTrendlineExt = (title, data) => {
        const reversedData = [...data].reverse();
        const chartData = Object.assign(
          [],
          reversedData.map((item) => sumUp(Object.values(item.externalCost)))
        );
        const chartLabels = Object.assign(
          [],
          reversedData.map((item) => formatDate(item.timeStamp, 'MMMM YYYY'))
        );
        doResetModalContent([]);
        doSetSelected({ item: 'trendline', modalTitle: title });
        doSetModalContent([
          {
            display: 'trendline',
            content: {
              setTitles: ['Total cost'],
              data: chartData,
              labels: chartLabels,
              yLabel: 'Cost ($)',
              xLabel: 'Time (months)',
            },
          },
        ]);
      };

      return (
        <Quad
          title={`External Cost (${formatDate(
            subcards?.externalCostTimeLine[0].timeStamp,
            'MMM YYYY'
          )})`}
          onClick={() => showTrendlineExt('External Cost History', subcards?.externalCostTimeLine)}
        >
          <TextData
            data={moneyAbbreviate(
              sumUp(Object.values(subcards.externalCostTimeLine[0].externalCost))
            )}
          />
        </Quad>
      );
    };

    // 2 - average cost / ppt trendline
    const renderAvgTrendline = () => {
      if (validationErrors.averageCostPerParticipantTimeline) {
        return (
          <Quad title={`Avg Cost/${getStringReplacement('Participant')}`}>
            <SpinnerOrError error={validationErrors.averageCostPerParticipantTimeline} />
          </Quad>
        );
      }
      const showTrendlineAvg = (title, data) => {
        const reversedData = [...data].reverse();
        const chartData = Object.assign(
          [],
          reversedData.map((item) => item.averageCostPerParticipant)
        );
        const chartLabels = Object.assign(
          [],
          reversedData.map((item) => formatDate(item.timeStamp, 'MMMM YYYY'))
        );
        doResetModalContent([]);
        doSetSelected({ item: 'trendline', modalTitle: title });
        doSetModalContent([
          {
            display: 'trendline',
            content: {
              setTitles: ['Avg cost'],
              data: chartData,
              labels: chartLabels,
              yLabel: 'Average Cost ($)',
              xLabel: 'Time (months)',
            },
          },
        ]);
      };
      return (
        <Quad
          title={`Avg Cost/${getStringReplacement('Participant')} (${formatDate(
            subcards.externalCostTimeLine[0].timeStamp,
            'MMM YYYY'
          )})`}
          onClick={() =>
            showTrendlineAvg(
              `Avg Cost per ${getStringReplacement('Participant')}`,
              subcards.averageCostPerParticipantTimeline
            )
          }
        >
          <TextData
            data={moneyAbbreviate(
              subcards.averageCostPerParticipantTimeline[0].averageCostPerParticipant
            )}
          />
        </Quad>
      );
    };

    // 3 - reimbursement breakdown
    const renderReimbursement = () => {
      if (validationErrors.reimbursementTypeBreakdown) {
        return (
          <Quad title="Reimbursement Type (Current)">
            <SpinnerOrError error={validationErrors.reimbursementTypeBreakdown} />
          </Quad>
        );
      }

      const finsReimbursementColors = [
        defaultChartColors.skyBlue,
        defaultChartColors.tealBlue,
        defaultChartColors.lightLilac,
        defaultChartColors.deepBlue,
      ];
      const finsReimbursementLabels = [];
      const finsReimbursementChartData = [];
      const finsReimbursementData = subcards.reimbursementTypeBreakdown || {};
      Object.entries(finsReimbursementData).forEach(([key, val]) => {
        finsReimbursementLabels.push(key);
        finsReimbursementChartData.push(val.value);
      });

      /** @type {import('chart.js').ChartOptions<'doughnut'>} */
      const finsReimbursementChartOptions = {
        radius: '90%',
        cutout: '40%',
        responsive: true,
        maintainAspectRatio: false,
      };

      return (
        <Quad
          scrollable
          title="Reimbursement Type (Current)"
          padding="p-0 align-items-center justify-content-center"
        >
          <DonutChart
            options={finsReimbursementChartOptions}
            info={finsReimbursementChartData}
            label={finsReimbursementLabels}
            colors={finsReimbursementColors}
          />
        </Quad>
      );
    };

    // 4 - cost breakdown
    const renderCostBreakdown = () => {
      if (validationErrors.externalCostTimeLine) {
        return (
          <Quad title="Claims, Cost Breakdown by Category">
            <SpinnerOrError error={validationErrors.externalCostTimeLine} />
          </Quad>
        );
      }
      const sortCosts = (data) => {
        const dataToArray = Object.entries(data);
        dataToArray.sort((a, b) => {
          let comparison = 0;
          comparison = a[1] - b[1];
          return -comparison;
        });
        return dataToArray;
      };
      const sortedCostInfo = sortCosts(subcards.externalCostTimeLine[selectedMonth].externalCost);
      const showCostBreakdown = () => {
        doResetModalContent([]);
        doSetSelected({ item: 'costBreakdown', modalTitle: 'Cost Breakdown' });
        doSetMonthMap(subcards.externalCostTimeLine.map((data) => data.timeStamp));
        doSetModalContent([
          {
            display: 'table',
            content: subcards.externalCostTimeLine.map((data) => data.externalCost),
            filter: 'month',
          },
          {
            display: 'dataBubble',
            content: subcards.externalCostTimeLine.map((data) => data.externalCost),
          },
        ]);
      };
      return (
        <Quad title="Claims, Cost Breakdown by Category">
          <Row className="w-100 h-100 pb-3 px-0">
            <Col lg={6} className="p-1 d-flex justify-content-start h-100 w-100 flex-grow-1">
              {subcards?.externalCostTimeLine ? (
                <div className="height-100 w-100 d-flex flex-column justify-content-end align-items-end">
                  <CostTable data={sortedCostInfo} maxRows={5} colorKeys={nivoOrder} />
                  <Button
                    title="Full Breakdown"
                    variant="link"
                    className="acuity-nav border-0 m-0 p-0"
                    onClick={() => showCostBreakdown()}
                  >
                    Full Breakdown
                    <ChevronRightRoundedIcon />
                  </Button>
                </div>
              ) : (
                <SpinnerOrError error={error} />
              )}
            </Col>
            <Col lg={6} className="d-flex justify-content-start h-100 w-100 overflow-auto">
              {subcards?.externalCostTimeLine ? (
                <div className="h-100 w-75 pt-4">
                  <BarChart
                    xData={sortedCostInfo.map((item) => truncate(item[0], 18, false))}
                    yData={sortedCostInfo.map((item) => item[1])}
                    yLabel="Cost ($)"
                    legendDisplay={false}
                    bgColors={nivoOrder}
                    numBars={6}
                  />
                </div>
              ) : (
                <SpinnerOrError error={error} />
              )}
            </Col>
          </Row>
        </Quad>
      );
    };

    return (
      <Container
        fluid
        className="bg-light m-0 h-100 w-100 d-flex flex-column overflow-hidden font-weight-normal pt-2 pb-3"
      >
        <div className="w-100 h-100 d-flex flex-column">
          {/* First row of popup with External Cost, ... */}
          <PercentageRow isDashboard={isDashboard}>
            <PercentageCol isDashboard={isDashboard}>{renderExtTrendline()}</PercentageCol>
            <PercentageCol isDashboard={isDashboard}>{renderAvgTrendline()}</PercentageCol>
            <PercentageCol isDashboard={isDashboard}>{renderReimbursement()}</PercentageCol>
          </PercentageRow>

          {/* Second row of popup with External cost breakdown - table and barchart */}
          <PercentageRow isDashboard={isDashboard}>
            <PercentageCol isDashboard={isDashboard}>{renderCostBreakdown()}</PercentageCol>
          </PercentageRow>
        </div>
      </Container>
    );
  }
}

FinAnalytics.propTypes = {
  subcards: FinModalPropType,
  selectedMonth: PropTypes.number,
  error: PropTypes.string,
  doSetSelected: PropTypes.func.isRequired,
  doSetModalContent: PropTypes.func.isRequired,
  doResetModalContent: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool,
  doSetMonthMap: PropTypes.func.isRequired,
};

FinAnalytics.defaultProps = {
  subcards: undefined,
  selectedMonth: 0,
  error: undefined,
  isDashboard: undefined,
};

const mapState = (state) => ({
  subcards: state.homepage.modalContent,
  selectedMonth: state.dashboard.selectedMonth,
  error: state.homepage.homeError,
});

const mapDispatch = (dispatch) => ({
  doSetSelected: (item, name) => dispatch(setSelected(item, name)),
  doSetSelectedMonth: (data) => dispatch(setSelectedMonth(data)),
  doSetModalContent: (val) => dispatch(addModalContent(val)),
  doResetModalContent: (val) => dispatch(resetModalContent(val)),
  doSetMonthMap: (val) => dispatch(setMonthIndexMap(val)),
});

export default connect(mapState, mapDispatch)(FinAnalytics);

import { get } from 'lodash';
import { useState } from 'react';

import { Text, Icon, Container, Button } from '@intus-ui';

import produce from 'immer';
import FilterRow from './FilterRow';
import useFilter from './useFilter';
import { getNewConditionFromGroupType } from './helpers';

// TODO: Use Button component with left icon instead
const DeleteFilterGroup = ({ handleDeleteFilterGroup }) => (
  <Container
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'end',
      cursor: 'pointer',
      alignItems: 'baseline',
    }}
    onClick={handleDeleteFilterGroup}
  >
    <Icon name="remove" width="8.5px" height="8.5px" color="#9D9D9D" />
    <Text type="subtitle" color="caption" style={{ marginLeft: 5 }}>
      remove filter
    </Text>
  </Container>
);

const FilterGroup = ({
  index: parentIndex,
  operator: parentOperator,
  conditions: filterRows,
  isSingleFilterGroup,
  invalidFilters,
  setFilter,
  filter,
}) => {
  const { getFilterOptions, operators } = useFilter();
  const [operator, setOperator] = useState(parentOperator || operators[0]);
  const [filterRowLabel, setFilterRowLabel] = useState('+ add filter');
  const [filterGroupSchema, setFilterGroupSchema] = useState([]);

  const handleAddFilter = () => {
    const newFilter = produce(filter, (draft) => {
      const groupType = draft.groups[parentIndex].conditions[0].type;
      const existingGroupLogic = draft.groups[parentIndex].conditions[0].logic;
      const newCondition = getNewConditionFromGroupType(groupType);
      if (newCondition.logic && existingGroupLogic) {
        newCondition.logic = existingGroupLogic;
      }
      draft.groups[parentIndex].conditions.push(newCondition);
    });

    setFilter(newFilter);
  };

  const handleChangeOperator = (value) => {
    const newFilter = produce(filter, (draft) => {
      draft.groups[parentIndex].operator = value;
    });
    setFilter(newFilter);
    setOperator(value);
  };

  const handleDeleteFilterGroup = () => {
    const newFilter = produce(filter, (draft) => {
      draft.groups.splice(parentIndex, 1);
    });
    setFilter(newFilter);
  };

  const isInvalidFilterRow =
    invalidFilters.find(({ filterGroupIndex }) => filterGroupIndex === parentIndex) !== undefined;

  const filterType = filterRows?.[0]?.type;
  const showAddButton = !get(getFilterOptions(filterType), 'disableAddButton', false);

  return (
    <div style={styles.queryBuilder}>
      <div style={styles.flatQuad}>
        {filterRows.map((condition, i) => {
          const key = i;
          return (
            <FilterRow
              index={i}
              parentIndex={parentIndex}
              key={key}
              condition={condition}
              operator={operator}
              handleChangeOperator={handleChangeOperator}
              setFilter={setFilter}
              filter={filter}
              setFilterRowLabel={setFilterRowLabel}
              isSingleFilterRow={filterRows.length <= 1}
              filterGroupSchema={filterGroupSchema}
              setFilterGroupSchema={setFilterGroupSchema}
            />
          );
        })}

        {showAddButton && (
          <Button
            hoverText={filterRowLabel}
            name={filterRowLabel}
            style={{ width: 'fit-content' }}
            textprops={{ type: 'body-bold', color: 'navy' }}
            onClick={handleAddFilter}
            disabled={isInvalidFilterRow}
            secondary
          />
        )}
      </div>
      {!isSingleFilterGroup && (
        <DeleteFilterGroup handleDeleteFilterGroup={handleDeleteFilterGroup} />
      )}
    </div>
  );
};

const styles = {
  queryBuilder: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  flatQuad: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: '20px 25px',
    borderRadius: 10,
    backgroundColor: 'white',
    border: '1px solid #9D9D9D',
  },
};

export default FilterGroup;

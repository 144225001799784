import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '@intus-ui/components/Buttons/Button.scss';

// A button that takes in text and and optional icon
// has text button default styling according to style guide
const TextButton = ({ name, hoverText, onClick, secondary, ...rest }) => {
  const [isPressed, setIsPressed] = useState(false);
  const onClickExt = () => {
    onClick();
    setIsPressed(!isPressed);
  };

  return (
    <button
      type="button"
      aria-pressed={isPressed}
      title={hoverText}
      aria-label={hoverText}
      name={name}
      className={`default-button ${secondary ? 'textButton-secondary' : 'textButton'}`}
      onClick={onClickExt}
      onKeyPress={onClickExt}
      {...rest}
    >
      <p>{name}</p>
    </button>
  );
};

TextButton.propTypes = {
  name: PropTypes.string.isRequired,
  hoverText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  secondary: PropTypes.bool,
};

TextButton.defaultProps = {
  secondary: false,
};

export default TextButton;

import PropTypes from 'prop-types';

const pointPropType = PropTypes.shape({
  point: PropTypes.shape({
    borderColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    data: PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.number,
      xFormatted: PropTypes.string,
      yFormatted: PropTypes.number,
    }),
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    serieColor: PropTypes.string.isRequired,
    serieId: PropTypes.string.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
});

export default pointPropType;

import React, { CSSProperties, ComponentPropsWithoutRef, HTMLAttributes, ReactNode } from 'react';

import { textColors, cohortChangesColors } from '@intus-ui/styles/SecondaryColors';
import { fonts } from '../../fonts';

type PossibleWrapperComponents = 'span' | 'div' | 'p' | 'a' | 'label';

export type TextProps<T extends PossibleWrapperComponents> = {
  style?: CSSProperties;
  children: ReactNode;
  wrapper?: T;
  color?: keyof typeof textColors;
  type?: keyof typeof fonts;
  link?: boolean;
  disabled?: boolean;
  ellipsis?: boolean;
  // The & below allows us to pass in any other props that the wrapper component accepts
  // E.x. if using an `a` tag as the wrapper, typescript will know that `href` is a valid property.
  // See: https://stackoverflow.com/questions/55969769/typing-a-dynamic-tag-in-react-with-typescript/76178335#76178335
} & Omit<ComponentPropsWithoutRef<T> & HTMLAttributes<HTMLOrSVGElement>, 'ref' | 'type'>;

// TODO: All colors should be accessible via the color prop in the dash-case format > color='intus-navy'
function Text<T extends PossibleWrapperComponents = 'span'>(props: TextProps<T>) {
  const {
    style,
    children,
    wrapper = 'span',
    color = 'body',
    type = 'body',
    link,
    onClick,
    ellipsis,
    disabled,
    ...rest
  } = props;
  const clickable = typeof onClick === 'function';
  const WrapperTag = wrapper;
  const isDiv = wrapper === 'div';
  const isSpan = wrapper === 'span';
  let textStyles: CSSProperties = {
    display: isDiv || isSpan ? 'inline-block' : undefined,
    color: disabled ? cohortChangesColors.disabled : textColors[color],
    width: 'fit-content',
    cursor: (link || clickable) && !disabled ? 'pointer' : undefined,
    ...fonts[type],
    ...style,
  };
  const ellipsisStyles: CSSProperties = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };

  if (ellipsis) textStyles = { ...textStyles, ...ellipsisStyles };

  const onClickExt = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (disabled) return;
    if (clickable) {
      onClick(event);
    }
  };
  return (
    <WrapperTag
      className={link ? 'btn-link' : undefined}
      style={textStyles}
      {...rest}
      onClick={onClickExt}
    >
      {children}
    </WrapperTag>
  );
}

export default Text;

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';

import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { ParticipantFiltersProvider } from '@intus-ui';

import * as FS from '@util/fullstory';
import { isUsingFullstory } from '@util/fullstory';

// Required for chart.js to work
import 'chart.js/auto';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';

import { Provider, store } from '@global-state';

import { intusTheme } from '@intus-ui/theme/theme';

import App from './App';

const renderApp = () => {
  Modal.setAppElement('#root');

  if (isUsingFullstory() === true) {
    FS.init();
  }

  ReactDOM.render(
    <StrictMode>
      {/* Allows overriding styles in the theme in material-ui */}
      <EmotionThemeProvider theme={intusTheme}>
        {/* Allows setting things like component default props in the theme in material-ui */}
        <ThemeProvider theme={intusTheme}>
          <Provider store={store}>
            {/* drag and drop package */}
            <DndProvider options={HTML5toTouch}>
              <ParticipantFiltersProvider>
                <App />
              </ParticipantFiltersProvider>
            </DndProvider>
          </Provider>
        </ThemeProvider>
      </EmotionThemeProvider>
    </StrictMode>,
    document.getElementById('root')
  );
};

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp();

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Card, Icon } from '@intus-ui';
import { riskColors } from '@intus-ui/styles/SecondaryColors';
import ChangeEventCard from './ChangeEventCard';

const ParticipantChangesCard = ({ change }) => {
  const { participant = {}, events = [] } = change;
  // Indicates that participants has warnings
  const hasWarning = !!events.filter((event) => event.current?.severity === 'high').length;
  const history = useHistory();

  return (
    <Card
      onClick={() => {
        const { id } = participant;
        history.push(`/patient/${id}/timeline`);
      }}
    >
      <div style={styles.participantCard}>
        <div style={styles.changesGridTitle}>
          <Text type="subtitle" c>
            {participant.name}
          </Text>
          {hasWarning && (
            <Icon name="Alert" color={riskColors.highest} hoverColor={riskColors.highest} />
          )}
        </div>

        <div style={styles.changesGrid}>
          {events.map((event, index) => {
            const key = index;
            return <ChangeEventCard key={key} event={event} summary />;
          })}
        </div>
      </div>
    </Card>
  );
};

const styles = {
  participantCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  changesGridTitle: { display: 'flex', justifyContent: 'space-between' },
  changesGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px',
    overflowY: 'auto',
  },
};
export default ParticipantChangesCard;

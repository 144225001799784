import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';

import { getUserInfo, updateUserSettings } from '@global-state/redux/userSettingsSlice';

import { Form, Col, Row } from 'react-bootstrap';
import { Button } from '@intus-ui';
import recentevents from 'assets/welcomeFlow_inserts/custom home right.gif';
import SettingsBox from './SettingsBox';

import userSettingsPropType from './userSettingsPropType';

const NotificationQuad = ({ doUpdateUserSettings, userSettings, editButton, hide }) => {
  const resetFormRef = useRef();
  const [error, setError] = useState(false);

  const { profile } = userSettings;
  if (!profile) {
    return null;
  }
  const { setting } = profile;
  const safeResetForm = () => {
    if (resetFormRef.current) {
      resetFormRef.current();
    }
  };

  // Handle Customer Selection from Modal Component
  const onSelectCustomerHandler = (selectedName, setFieldValue, valueList) => {
    const index = [...valueList.defaultRecentUpdatesFilter].indexOf(selectedName);
    if (index > -1) {
      setFieldValue(
        'defaultRecentUpdatesFilter',
        valueList.defaultRecentUpdatesFilter.filter((item) => item !== selectedName)
      );
    } else {
      const updatedSelections = [...valueList.defaultRecentUpdatesFilter];
      updatedSelections.push(selectedName);
      setFieldValue('defaultRecentUpdatesFilter', updatedSelections);
    }
  };

  return (
    <SettingsBox title="Recent Event Selections" cancel={safeResetForm} editButton={editButton}>
      {({ editable, setEditable }) => (
        <Formik
          initialValues={{
            defaultRecentUpdatesFilter: setting.defaultRecentUpdatesFilter,
            ...setting,
          }}
          onSubmit={(values) => {
            if (values.defaultRecentUpdatesFilter.length < 1) {
              setError(true);
            }
            if (values.defaultRecentUpdatesFilter.length >= 1) {
              const updatedSetting = {
                ...setting,
                defaultRecentUpdatesFilter: values.defaultRecentUpdatesFilter,
              };
              setError(false);
              if (editButton) {
                setEditable(false);
              } else {
                hide();
              }
              doUpdateUserSettings({ ...profile, settings: updatedSetting });
            }
          }}
        >
          {({ handleSubmit, submitForm, resetForm, values, setFieldValue }) => {
            resetFormRef.current = resetForm;
            return (
              <Form onSubmit={handleSubmit}>
                <Row className="d-flex align-items-center justify-content-center">
                  <Col xl={7} className="d-flex align-items-center flex-column pt-3 pl-3">
                    <Row>
                      <Col>
                        <h5 className="font-weight-bold text-dark py-5">Enrollments</h5>
                        <ul className="px-0">
                          <li
                            // eslint-disable-next-line react/no-array-index-key
                            key="newEnrollment-col"
                            style={{ listStyleType: 'none' }}
                            className="d-flex flex-row align-items-center justify-content-start"
                          >
                            <Field
                              type="checkbox"
                              name="defaultRecentUpdatesFilter"
                              value="Enrolled"
                              style={{ width: '35px', height: '90px' }}
                              className="mr-3 m-0 p-0"
                              disabled={!editable}
                            />
                            <Button
                              type="button"
                              htmlFor="Enrolled"
                              className="selection-button form-check-label py-2 px-3 box-shadow-on-gray rounded-xl border-0 text-left m-0"
                              style={{
                                height: '50px',
                                minWidth: '175px',
                              }}
                              onClick={() => {
                                onSelectCustomerHandler('Enrolled', setFieldValue, values);
                              }}
                              disabled={!editable}
                            >
                              <h6>Enrolled</h6>
                            </Button>
                          </li>
                          <li
                            // eslint-disable-next-line react/no-array-index-key
                            key="disenrolled-col"
                            style={{ listStyleType: 'none' }}
                            className="d-flex flex-row align-items-center justify-content-start"
                          >
                            <Field
                              type="checkbox"
                              name="defaultRecentUpdatesFilter"
                              value="Disenrolled"
                              style={{ width: '35px', height: '90px' }}
                              className="mr-3 m-0 p-0"
                              disabled={!editable}
                            />
                            <Button
                              type="button"
                              htmlFor="Enrolled"
                              className="selection-button form-check-label py-2 px-3 box-shadow-on-gray rounded-xl border-0 text-left m-0"
                              style={{
                                height: '50px',
                                minWidth: '175px',
                              }}
                              onClick={() => {
                                onSelectCustomerHandler('Disenrolled', setFieldValue, values);
                              }}
                              disabled={!editable}
                            >
                              <h6>Disenrolled</h6>
                            </Button>
                          </li>
                        </ul>
                      </Col>
                      <Col>
                        <h5 className="font-weight-bold text-dark py-5">Utilization</h5>
                        <ul className="px-0">
                          <li
                            // eslint-disable-next-line react/no-array-index-key
                            key="inpatient-admits-col"
                            style={{ listStyleType: 'none' }}
                            className="d-flex flex-row align-items-center justify-content-start"
                          >
                            <Field
                              type="checkbox"
                              name="defaultRecentUpdatesFilter"
                              value="Inpatient Admits"
                              style={{ width: '35px', height: '90px' }}
                              className="mr-3 m-0 p-0"
                              disabled={!editable}
                            />
                            <Button
                              type="button"
                              htmlFor="Enrolled"
                              className="selection-button form-check-label py-2 px-3 box-shadow-on-gray rounded-xl border-0 text-left m-0"
                              style={{
                                height: '50px',
                                minWidth: '230px',
                              }}
                              onClick={() => {
                                onSelectCustomerHandler('Inpatient Admits', setFieldValue, values);
                              }}
                              disabled={!editable}
                            >
                              <h6>Inpatient Admits</h6>
                            </Button>
                          </li>
                          <li
                            // eslint-disable-next-line react/no-array-index-key
                            key="inpatient-discharges-col"
                            style={{ listStyleType: 'none' }}
                            className="d-flex flex-row align-items-center justify-content-start"
                          >
                            <Field
                              type="checkbox"
                              name="defaultRecentUpdatesFilter"
                              value="Inpatient Discharges"
                              style={{ width: '35px', height: '90px' }}
                              className="mr-3 m-0 p-0"
                              disabled={!editable}
                            />
                            <Button
                              type="button"
                              htmlFor="Enrolled"
                              className="selection-button form-check-label py-2 px-3 box-shadow-on-gray rounded-xl border-0 text-left m-0"
                              style={{
                                height: '50px',
                                minWidth: '230px',
                              }}
                              onClick={() => {
                                onSelectCustomerHandler(
                                  'Inpatient Discharges',
                                  setFieldValue,
                                  values
                                );
                              }}
                              disabled={!editable}
                            >
                              <h6>Inpatient Discharges</h6>
                            </Button>
                          </li>
                          <li
                            // eslint-disable-next-line react/no-array-index-key
                            key="er-visits-col"
                            style={{ listStyleType: 'none' }}
                            className="d-flex flex-row align-items-center justify-content-start"
                          >
                            <Field
                              type="checkbox"
                              name="defaultRecentUpdatesFilter"
                              value="ER Visits"
                              style={{ width: '35px', height: '90px' }}
                              className="mr-3 m-0 p-0"
                              disabled={!editable}
                            />
                            <Button
                              type="button"
                              htmlFor="Enrolled"
                              className="selection-button form-check-label py-2 px-3 box-shadow-on-gray rounded-xl border-0 text-left m-0"
                              style={{
                                height: '50px',
                                minWidth: '230px',
                              }}
                              onClick={() => {
                                onSelectCustomerHandler('ER Visits', setFieldValue, values);
                              }}
                              disabled={!editable}
                            >
                              <h6>ER Visits</h6>
                            </Button>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={5} className="d-flex align-items-start flex-column text-muted">
                    <img
                      className="m-0 pl-4 pr-4"
                      alt="Choosing notifications gif"
                      src={recentevents}
                      width="450rem"
                    />
                    <p className="pt-2 mr-5 pr-5 align-self-end">Example Recent Event Section</p>
                  </Col>
                </Row>
                <Row>
                  {editable ? (
                    <SettingsBox.Buttons
                      errorMessage={error ? 'Please select to continue.' : null}
                      cancel={() => {
                        resetForm();
                        if (editButton) {
                          setEditable(false);
                        } else {
                          hide();
                        }
                      }}
                      submit={submitForm}
                    />
                  ) : null}
                </Row>
              </Form>
            );
          }}
        </Formik>
      )}
    </SettingsBox>
  );
};

NotificationQuad.propTypes = {
  userSettings: userSettingsPropType,
  doUpdateUserSettings: PropTypes.func.isRequired,
  editButton: PropTypes.bool,
  hide: PropTypes.func,
};

NotificationQuad.defaultProps = {
  userSettings: undefined,
  editButton: true,
  hide: undefined,
};

const mapState = (state) => ({
  userSettings: state.userSettings,
});

const mapDispatch = (dispatch) => ({
  doGetUserLocalData: () => dispatch(getUserInfo()),
  doUpdateUserSettings: (body) => dispatch(updateUserSettings(body)),
});

export default connect(mapState, mapDispatch)(NotificationQuad);

import { useRef } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { getUserInfo, updateUserSettings } from '@global-state/redux/userSettingsSlice';

import { Form, Col } from 'react-bootstrap';
import FormikFormControl from '@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';
import SettingsBox from './SettingsBox';

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Email must be valid').required('Required'),
  phoneNumber: Yup.number()
    .typeError('Must be a phone number')
    .test('len', 'Phone numbers must be either 10 or 11 characters long', (val) => {
      if (val) {
        return val.toString().length === 11 || val.toString().length === 10;
      }
      return true;
    }),
});

const ProfileSettingsBox = ({ doUpdateUserSettings, profile }) => {
  const resetFormRef = useRef();

  const safeResetForm = () => {
    if (resetFormRef.current) {
      resetFormRef.current();
    }
  };
  return (
    <SettingsBox title="Profile Info" cancel={safeResetForm}>
      {({ editable, setEditable }) => (
        <Formik
          initialValues={{
            ...profile,
            passwordConfirmation: profile.password,
          }}
          validateOnChange
          onSubmit={(values) => {
            const includedFields = {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: values.phoneNumber,
              id: values.id,
              // jobType: selected,
            };
            setEditable(false);
            doUpdateUserSettings(includedFields);
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, submitForm, resetForm, errors }) => {
            resetFormRef.current = resetForm;
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Row>
                  <FormikFormControl
                    as={Col}
                    md="12"
                    label="First Name"
                    type="text"
                    name="firstName"
                    disabled={!editable}
                  />
                </Form.Row>
                <Form.Row>
                  <FormikFormControl
                    as={Col}
                    md="12"
                    label="Last Name"
                    type="text"
                    name="lastName"
                    disabled={!editable}
                  />
                </Form.Row>
                <Form.Row>
                  <FormikFormControl
                    as={Col}
                    md="12"
                    label="Email"
                    type="text"
                    name="email"
                    disabled={!editable}
                  />
                </Form.Row>
                <Form.Row>
                  <FormikFormControl
                    as={Col}
                    md="12"
                    label="Phone Number"
                    type="text"
                    name="phoneNumber"
                    disabled={!editable}
                  />
                </Form.Row>
                {editable ? (
                  <SettingsBox.Buttons
                    cancel={() => {
                      resetForm();
                      setEditable(false);
                    }}
                    submit={submitForm}
                    disabled={Object.values(errors).length > 0}
                  />
                ) : null}
              </Form>
            );
          }}
        </Formik>
      )}
    </SettingsBox>
  );
};

ProfileSettingsBox.propTypes = {
  doUpdateUserSettings: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Object),
};

ProfileSettingsBox.defaultProps = {
  profile: undefined,
};

const mapState = (state) => ({
  userSettings: state.userSettings,
});

const mapDispatch = (dispatch) => ({
  doGetUserLocalData: () => dispatch(getUserInfo()),
  doUpdateUserSettings: (body) => dispatch(updateUserSettings(body)),
});

export default connect(mapState, mapDispatch)(ProfileSettingsBox);

import Input from '@intus-ui/components/Input';
import { Multiselect } from '@intus-ui/components/Multiselect/Multiselect';
import { useParticipantFilters } from '@intus-ui/components/filters/ParticipantFiltersContext';
import { PatientGroupFilterList } from '@intus-ui/components/filters/patientGroups/PatientGroupFilterList';
import { getTextWidthInPixels } from '@util/getTextWidthInPixels';
import { useGetSessionUser } from '@util/session';
import { getStringReplacement } from '@util/stringReplacements';
import { USER_ACCESS_HEALTH_SYS_ADMIN } from 'Settings/userSettingsPropType';
import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as userTracking from '@util/userTracking';

/**
 * Displays the filters for team, facility, patient groups, and an organization dropdown if a user is a health system admin.
 *
 * @param {{ disabled?: boolean, onClickDropdown?:(string) => void }} props
 */
export const TeamFacilityFilter = (props) => {
  const { disabled, isCohortsPage, onClickDropdown } = props;
  const { groupFilters } = useParticipantFilters();
  const groupFilterTypes = groupFilters.map((item) => item.type);
  const user = useGetSessionUser();

  const isHealthSysAdmin = user.access === USER_ACCESS_HEALTH_SYS_ADMIN;

  return (
    <div style={styles.container}>
      {!isHealthSysAdmin && (
        <>
          <TeamFilter
            {...props}
            order={groupFilterTypes.findIndex((item) => item === 'Team')}
            onClickDropdown={onClickDropdown}
          />
          <FacilityFilter
            {...props}
            order={groupFilterTypes.findIndex((item) => item === 'Facility')}
            onClickDropdown={onClickDropdown}
          />
          {!isCohortsPage && (
            <CohortFilter
              {...props}
              order={groupFilterTypes.findIndex((item) => item === 'Cohort')}
              onClickDropdown={onClickDropdown}
            />
          )}
          <PatientGroupFilterList disabled={disabled} onClickDropdown={onClickDropdown} />
        </>
      )}
      {isHealthSysAdmin && <OrgFilter disabled={disabled} />}
    </div>
  );
};

TeamFacilityFilter.propTypes = {
  disabled: PropTypes.bool,
  isCohortsPage: PropTypes.bool,
  onClickDropdown: PropTypes.func,
};
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    // Wrap the filters if we have too many.
    // flexWrap: 'wrap',
    padding: '0px 10px 0px 0px',
    gap: 10,
  },
};

const TeamFilter = ({ disabled, order, onClickDropdown }) => {
  const { selectedFilters, teams, setTeams } = useParticipantFilters();
  const storedTeamFilter = JSON.parse(localStorage.getItem('teamFilter'));

  useEffect(() => {
    if (storedTeamFilter && !isEqual(storedTeamFilter, selectedFilters.teams)) {
      setTeams(storedTeamFilter);
    }
  }, [selectedFilters.teams, setTeams, storedTeamFilter]);

  function onChange(selectedTeams) {
    setTeams(selectedTeams);
    localStorage.setItem('teamFilter', JSON.stringify(selectedTeams)); // Update local storage
    userTracking.logEvent(`Global Filters`, {
      filter: 'Team',
      filterValue: selectedTeams,
    });
  }

  if (teams == null || teams.length === 0) {
    return null;
  }

  return (
    <Multiselect
      id="Team"
      label={getStringReplacement('Team')}
      allowSelectAll
      items={teams}
      style={{ order }}
      onFocus={() => onClickDropdown('Team')}
      // selectedFilters.teams being null means all teams are selected
      // empty array means the user unchecked all the teams.
      selectedItems={selectedFilters.teams || teams}
      onChange={(value) => onChange(value)}
      disabled={disabled}
    />
  );
};

const CohortFilter = ({ disabled, order }) => {
  const { selectedFilters, cohorts, setSelectedCohorts } = useParticipantFilters();
  const storedCohortFilter = JSON.parse(localStorage.getItem('cohortFilter'));

  useEffect(() => {
    if (storedCohortFilter && !isEqual(storedCohortFilter, selectedFilters.cohorts)) {
      setSelectedCohorts(storedCohortFilter);
    }
  }, [selectedFilters.cohorts, setSelectedCohorts, storedCohortFilter]);

  function onChange(selectedCohortsName) {
    const cohortName = selectedCohortsName.value || selectedCohortsName;

    const newlySelectedCohorts = cohorts.filter((cohort) => {
      return cohortName.includes(cohort.name);
    });
    setSelectedCohorts(newlySelectedCohorts);
    localStorage.setItem('cohortFilter', JSON.stringify(newlySelectedCohorts)); // Update local storage

    userTracking.logEvent(`Global Filters`, {
      filter: 'Cohort',
      filterValue: newlySelectedCohorts,
    });
  }

  if (cohorts == null || cohorts.length === 0) {
    return null;
  }

  const allCohortsValue = { value: 'All', label: 'All', category: 'All' };

  function getCohortsSortedByCategory(cohorts) {
    const sortedCohorts = cohorts
      .map((cohort) => {
        let category = '';
        if (cohort.isBilling) {
          category = 'Action';
        } else if (cohort.shared) {
          category = 'Shared';
        } else {
          category = 'Personal';
        }
        return {
          value: cohort.name,
          label: cohort.name,
          category,
        };
      })
      .sort((a, b) => {
        const order = ['Personal', 'Shared', 'Action'];
        return order.indexOf(a.category) - order.indexOf(b.category);
      });
    return sortedCohorts;
  }
  const cohortsOptions = [allCohortsValue, ...getCohortsSortedByCategory(cohorts)];
  return (
    <Input
      fluid
      id="Cohort"
      style={{
        minWidth: 200,
        cursor: 'pointer',
        order,
      }}
      name="Cohort Filters"
      type="select"
      options={cohortsOptions}
      value={getCohortsSortedByCategory(selectedFilters.cohorts)[0] || allCohortsValue}
      placeholder="Select Cohort"
      onChange={(value) => onChange(value)}
      rightIcon="caret-down"
      disabled={disabled}
      innerLabel="Cohort:"
      groupBy="category"
    />
  );
};

const FacilityFilter = ({ disabled, order }) => {
  const { selectedFilters, facilities, setFacilities } = useParticipantFilters();
  const storedFacilityFilter = JSON.parse(localStorage.getItem('facilityFilter'));

  useEffect(() => {
    if (storedFacilityFilter && !isEqual(storedFacilityFilter, selectedFilters.facilities)) {
      setFacilities(storedFacilityFilter);
    }
  }, [selectedFilters.facilities, setFacilities, storedFacilityFilter]);

  function onChange(selectedFacilities) {
    setFacilities(selectedFacilities);
    localStorage.setItem('facilityFilter', JSON.stringify(selectedFacilities)); // Update local storage
    userTracking.logEvent(`Global Filters`, {
      filter: 'Center',
      filterValue: selectedFacilities,
    });
  }

  if (facilities == null || facilities.length === 0) {
    return null;
  }

  return (
    <Multiselect
      id="Facility"
      label={getStringReplacement('Center')}
      allowSelectAll
      items={facilities}
      style={{ order }}
      // selectedFilters.facilities being null means all facilities are selected
      // empty array means the user unchecked all the facilities.
      selectedItems={selectedFilters.facilities || facilities}
      onChange={(value) => onChange(value)}
      disabled={disabled}
    />
  );
};

export const OrgFilter = ({ disabled }) => {
  const { selectedFilters, healthSystemAdminOrganizations, setSelectedOrg } =
    useParticipantFilters();

  const orgMap = useMemo(() => {
    const map = { All: 'All' };

    healthSystemAdminOrganizations.forEach(({ id, name }) => {
      map[id] = name;
    });

    return map;
  }, [healthSystemAdminOrganizations]);

  // Change the organization objects to use label as a field instead of name so
  // they can be used in the dropdown.
  const orgsForDropdown = useMemo(() => {
    return [
      'All',
      ...healthSystemAdminOrganizations.map((org) => {
        return { id: org.id, label: org.name };
      }),
    ];
  }, [healthSystemAdminOrganizations]);

  const selectedOrgText = orgMap[selectedFilters.organization] || 'All';

  // Calculate the width of the dropdown based on the current text.
  const width = useMemo(() => {
    const textWidth =
      getTextWidthInPixels(`Org: ${selectedOrgText}`, {
        fontSize: 15,
        fontWeight: 'bold',
      }) + 55;

    if (textWidth < 200) {
      return 180;
    }

    return textWidth;
  }, [selectedOrgText]);

  const onChange = (selectedOrg) => {
    setSelectedOrg(selectedOrg);
    userTracking.logEvent(`Global Filters`, {
      filter: 'Organization',
      filterValue: selectedOrg,
    });
  };

  return (
    <Input
      id="OrgSelector"
      type="select"
      rightIcon="caret-down"
      innerLabel="Org:"
      name="OrgSelector"
      options={orgsForDropdown}
      value={selectedOrgText}
      onChange={(value) => onChange(value)}
      style={{
        ...dropdownStyles.input,
        width,
        maxWidth: width,
      }}
      disabled={disabled}
    />
  );
};

const dropdownStyles = {
  input: {
    cursor: 'pointer',
    textAlign: 'left',
  },
};

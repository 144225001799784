import React from 'react';
import PropTypes from 'prop-types';

const BigTitle = ({ title, secondary, className }) => {
  const classes = `${className} text-dark text-center align-self-center ${
    secondary ? 'font-weight-normal' : 'font-weight-bold'
  }`;

  return <h4 className={classes}>{title}</h4>;
};

BigTitle.propTypes = {
  title: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
  className: PropTypes.string,
};

BigTitle.defaultProps = {
  secondary: false,
  className: '',
};

export default BigTitle;

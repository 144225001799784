import { connect } from 'react-redux';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import {
  setPasswordReset,
  sendPasswordReset,
  setEditingCaregiver,
  updateUserPassword,
} from '@global-state/redux/caregiverListSlice';

import { Form } from 'react-bootstrap';
import { Button, Icon } from '@intus-ui';
import caregiverPropType from '../caregiverListCaregiverPropType';

const PasswordResetModal = ({ sendReset, selectedCaregiver, goToSetting }) => (
  <Formik
    initialValues={{}}
    onSubmit={() => {
      sendReset(selectedCaregiver.id);
    }}
  >
    {({ handleSubmit, isSubmitting }) => (
      <Form onSubmit={handleSubmit}>
        <div className="text-muted">Email</div>
        <div className="d-flex flex-row justify-content-between py-2 px-3 align-items-center mb-3 border rounded-lg">
          <div className="text-muted">{selectedCaregiver.email}</div>
          <Icon name="gear" color="#4d7ef8" onClick={goToSetting} />
        </div>
        <div className="mb-3">
          A password reset link will be sent to the user&apos;s email. Please note that this link
          will only be valid for 24 hours.
        </div>
        <div>
          <Button type="submit" fluid busy={isSubmitting} className="m-0">
            Send Reset Email
          </Button>
        </div>
      </Form>
    )}
  </Formik>
);

PasswordResetModal.propTypes = {
  selectedCaregiver: caregiverPropType,
  sendReset: PropTypes.func.isRequired,
  goToSetting: PropTypes.func.isRequired,
};

PasswordResetModal.defaultProps = {
  selectedCaregiver: undefined,
};

const mapState = (state) => ({
  show: state.caregiverList.passwordReset,
  selectedCaregiver: state.caregiverList.selectedCaregiver,
  passwordReset: state.caregiverList.passwordReset,
});

const mapDispatch = (dispatch) => ({
  goToSetting: () => {
    dispatch(setEditingCaregiver(true));
    dispatch(setPasswordReset(false));
  },
  sendReset: (caregiverID) => {
    dispatch(setEditingCaregiver(false));
    dispatch(sendPasswordReset(caregiverID, "'s password has been reset successfully.", false));
  },
  doUpdateUserPassword: (body) => {
    dispatch(setEditingCaregiver(false));
    dispatch(updateUserPassword(body));
  },
});

export default connect(mapState, mapDispatch)(PasswordResetModal);

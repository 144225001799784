/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getUserByIdAPI } from '@api/api';
import { getLogger } from '@util/logger';

const log = getLogger('caregiverDetailSlice');

const caregiverDetailSlice = createSlice({
  name: 'caregiverDetailSlice',
  initialState: {
    caregiver: undefined,
    error: undefined,
  },
  reducers: {
    // The payload should be a caregiverDetailCaregiverPropType.
    setCaregiver(state, action) {
      state.caregiver = action.payload;
    },

    // This allows the caregiver page to display an error.
    setError(state, action) {
      state.error = action.payload;
    },

    // This is triggered together with the retry button.
    clearCaregiverAndError(state) {
      state.caregiver = undefined;
      state.error = undefined;
    },
  },
});

export default caregiverDetailSlice.reducer;
export const { setCaregiver, setError, clearCaregiverAndError } = caregiverDetailSlice.actions;

export const getCaregiver = (userId) => async (dispatch) => {
  dispatch(clearCaregiverAndError());
  const errorMessage = `There was a problem fetching caregiver ${userId}.`;
  try {
    const result = await getUserByIdAPI(userId);
    if (result.ok) {
      const { id, firstName, lastName, phoneNumber } = await result.json();
      dispatch(
        setCaregiver({
          id,
          firstName,
          lastName,
          phoneNumber,
        })
      );
    } else if (result.status === 404) {
      dispatch(setError(`Caregiver ${userId} does not exist.`));
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    log.error(err);
    dispatch(setError(errorMessage));
  }
};

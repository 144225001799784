import PropTypes from 'prop-types';

export const censusModalPropType = PropTypes.shape({
  averageYearsEnrolled: PropTypes.number,

  currentlyLivingInCommunity: PropTypes.shape({
    val: PropTypes.number,
    ids: PropTypes.arrayOf(PropTypes.number),
  }),

  maxYearsEnrolled: PropTypes.number,

  monthlyEnrollmentChange: PropTypes.arrayOf(
    PropTypes.shape({
      deaths: PropTypes.number,
      disenrolled: PropTypes.number,
      enrolled: PropTypes.number,
      month: PropTypes.string,
    })
  ),

  totalParticipantsTrendline: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string,
      val: PropTypes.number,
    })
  ),
});

export default censusModalPropType;

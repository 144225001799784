import PropTypes from 'prop-types';

const caregiverlistCaregiverPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  password: PropTypes.string,
  access: PropTypes.string,
  status: PropTypes.string,
});

export default caregiverlistCaregiverPropType;

import '@intus-ui/styles/Palette.scss';

export const admissionType = {
  EMERGENCY: {
    name: 'Emergency',
    enum: 'EMERGENCY',
    color: '#cc6262',
    badgeColor: 'danger',
  },
  EMERGENT_VISIT: {
    name: 'EmergentVisit',
    enum: 'EMERGENCY',
    color: '#cc6262',
    badgeColor: 'danger',
  },
  ED_VISIT: {
    name: 'ED',
    enum: 'EMERGENCY',
    color: '#cc6262',
    badgeColor: 'danger'
  },
  HOSPITALIZATION: {
    name: 'Hospitalization',
    enum: 'URGENT',
    color: '#e4c20f',
    badgeColor: 'warning',
  },
  INPATIENT_ADMIT: {
    name: 'inpatient',
    enum: 'URGENT',
    color: '#e4c20f',
    badgeColor: 'warning'
  },
  URGENT: {
    name: 'Urgent',
    enum: 'URGENT',
    color: '#e4c20f',
    badgeColor: 'warning',
  },
  ELECTIVE: {
    name: 'Elective',
    enum: 'ELECTIVE',
    color: '#4d7ef8',
    badgeColor: 'primary',
  },
  NEWBORN: {
    name: 'Newborn',
    enum: 'NEWBORN',
    color: '#4d7ef8',
    badgeColor: 'primary',
  },
  TRAUMA: {
    name: 'Trauma',
    enum: 'TRAUMA',
    color: '#4d7ef8',
    badgeColor: 'primary',
  },
  OTHER: {
    name: 'Other',
    enum: 'OTHER',
    color: '#4d7ef8',
    badgeColor: 'primary',
  },
  NO_INFO: {
    name: 'N/A',
    enum: 'NO_INFO',
    color: '#4d7ef8',
    badgeColor: 'muted',
  },
};

export const admissionLength = {
  LONG: {
    name: '7 or more days',
    min_cutoff: 7,
    max_cutoff: undefined,
    color: '#C1B2E1',
  },
  MEDIUM: {
    name: '2 - 6 days',
    min_cutoff: 1,
    max_cutoff: 7,
    color: '#8ABDCD',
  },
  SHORT: {
    name: '0 or 1 days',
    min_cutoff: 0,
    max_cutoff: 1,
    color: '#cc6262',
  },
  UNKNOWN: {
    name: 'Length unknown',
    min_cutoff: null,
    max_cutoff: null,
    color: '#97999b',
  },
};

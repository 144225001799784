import React from 'react';
import PropTypes from 'prop-types';

const Centered = ({ children, className }) => (
  <div className={`d-flex flex-column justify-content-center align-items-center ${className}`}>
    <div>{children}</div>
  </div>
);

Centered.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Centered.defaultProps = {
  children: null,
  className: '',
};

export default Centered;

import React, { useState, useEffect } from 'react';
import '@intus-ui/styles/Settings.scss';
import '@intus-ui/styles/Utilities.scss';
import { Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const GuidePreview = ({ previewImg, userType, setPdf, url, currPdf }) => {
  const [isHover, setIsHover] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    const element = document.getElementById('pdf-viewer');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isClicked]);
  return (
    <Col lg={4} className="d-flex flex-column w-100 align-items-center">
      <Button
        variant="link"
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        onClick={() => {
          setPdf(url);
          setIsClicked(true);
        }}
      >
        <img
          src={previewImg}
          className={`${!isHover ? 'box-shadow-on-gray border' : 'select-border'} w-100 rounded-lg`}
          alt={`Preview of guide for ${userType}`}
        />
        <div className={`d-flex flex-column ${currPdf === url ? 'text-primary' : 'text-dark'}`}>
          <h6 className={`pt-2 ${currPdf === url ? 'font-weight-bold' : 'font-weight-normal'}`}>
            Guide for
          </h6>
          <h5 className="pt-2 font-weight-bold">{userType}</h5>
        </div>
      </Button>
    </Col>
  );
};

GuidePreview.propTypes = {
  previewImg: PropTypes.node.isRequired,
  userType: PropTypes.string.isRequired,
  setPdf: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  currPdf: PropTypes.string.isRequired,
};

export default GuidePreview;

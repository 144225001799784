import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getFeatureFlag } from '@config';
import { getLogger } from '@util/logger';

import { getPatientTimeline } from '@api';

import { layout } from '@intus-ui';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import useEventListener from '@intus-ui/components/useEventListener';
import { Scatter } from 'react-chartjs-2';
import Sidebar from './Sidebar';
import {
  backgroundChartArea,
  drawDottedLines,
  removeYAxisLabelsAboveLimit,
  formatBadges,
} from './plugins';
import { chartOptions } from './chartOptions';
import { DateFilter, EventFilter, PolypharmacyFilters } from './filters';

import { Provider, useRiskTrendlineContext } from './Context';

const log = getLogger('RiskTrendline');

const RiskTrendlineContents = () => {
  const { patientId } = useParams();
  const [fetchError, setFetchError] = useState();

  const { featureIsActive: polypharmacyIsActive } = getFeatureFlag('polypharmacy');

  const {
    ready,
    setData,

    maxAcuity,
    earliestDate,
    chartData,

    dateFilter,
    eventFilters,

    hoveredPoint,
    setHoveredPoint,
    selectedPoint,
    setSelectedPoint,
  } = useRiskTrendlineContext();

  // FETCH FORMATTED TIMELINE DATA FROM API; SET TO CONTEXT
  useEffect(() => {
    const fetchData = async () => {
      const data = await getPatientTimeline(patientId);
      setData(data);
    };

    fetchData().catch((e) => {
      setFetchError('System error - unable to fetch data.');
      log.error(e);
    });
  }, []);

  // CHECKS WHETHER THE CLICK WAS EITHER IN THE CHART CANVAS OR AN ACUITY CARD
  const handleOutsideClick = (e) => {
    const paths = e.composedPath();
    const trendlineElementClicked = !!paths.find((path) => {
      const classNames = String(path.className);
      return classNames.includes('acuity-sidebar-item') || path.tagName === 'CANVAS';
    });
    if (!trendlineElementClicked) setSelectedPoint();
  };
  useEventListener('mousedown', handleOutsideClick);

  /** @type {import('chart.js').ChartOptions<'scatter'>['onClick']} */
  const handleChartClick = (event, activeElements) => {
    if (event.type === 'click') {
      const datasetIndex = activeElements[0]?.datasetIndex;
      const index = activeElements[0]?.index;
      const myEvent = chartData.datasets[datasetIndex]?.data?.[index];

      if (myEvent && myEvent.eventType) {
        setSelectedPoint(myEvent);
      } else {
        setSelectedPoint();
      }
    }
  };

  const titleString = dateFilter.isRange ? `- ${dateFilter.label}` : `in the ${dateFilter.label}`;

  return (
    <div id="tabContentContainer" style={{ ...layout.container, flexDirection: 'row', gap: 30 }}>
      <div id="fluidContentContainer" style={styles.fluidcontent}>
        <div id="contentHeader" style={styles.rtlHeader}>
          {!polypharmacyIsActive && (
            <h6 style={styles.headerStyle}>Risk Trendline {titleString}</h6>
          )}
          <div id="contentFilters" style={styles.rtlFilters}>
            {Object.values(eventFilters).map((eventFilter) => (
              <EventFilter key={eventFilter.key} eventFilter={eventFilter} />
            ))}
            {polypharmacyIsActive && <PolypharmacyFilters />}
            <DateFilter />
          </div>
        </div>
        <div style={styles.rtlBody}>
          {ready && !fetchError ? (
            <div style={styles.rtlChart}>
              <Scatter
                data={chartData}
                options={chartOptions({
                  maxAcuity,
                  earliestDate,
                  hoveredPoint,
                  setHoveredPoint,
                  selectedPoint,
                  dateFilter,
                  onClick: handleChartClick,
                })}
                plugins={[
                  formatBadges,
                  removeYAxisLabelsAboveLimit,
                  backgroundChartArea,
                  drawDottedLines,
                ]}
              />
            </div>
          ) : (
            <SpinnerOrError error={fetchError} />
          )}
        </div>
      </div>

      <Sidebar />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flex: 1,
    display: 'flex',
    padding: '10px 20px 20px 20px',
    gap: '10px',
    overflowY: 'auto',
  },
  fluidcontent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    gap: 5,
  },
  rtlHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rtlFilters: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '10px',
    padding: 5,
  },
  rtlBody: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    minWidth: 0,
  },
  rtlChart: {
    flex: 1,
    minWidth: 0,
  },
  headerStyle: {
    fontWeight: 'bold',
    color: ' #052d8f',
  },
};

export const RiskTrendline = () => {
  return (
    <Provider>
      <RiskTrendlineContents />
    </Provider>
  );
};

import React, { createContext, useState, useMemo, useContext } from 'react';

export const MedicationsListContext = createContext();

// We will use this later once we implement filtering
export const MedicationsListProvider = ({ children }) => {
  const [filterOptions, setFilterOptions] = useState({});

  const medicationsListContext = useMemo(() => {
    return {
      filterOptions,
      setFilterOptions,
    };
  });

  return (
    <MedicationsListContext.Provider value={medicationsListContext}>
      {children}
    </MedicationsListContext.Provider>
  );
};

export const useMedicationsListContext = () => {
  return useContext(MedicationsListContext);
};

import React from 'react';
import '@intus-ui/styles/Utilities.scss';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import SimpleTooltip from '@intus-ui/components/SimpleTooltip';
// eslint-disable-next-line import/no-cycle
import { setSelected } from '@global-state/redux/homepageSlice';
import TextData from 'Dashboard/QuadContent/TextData';
import BigTitle from 'Dashboard/QuadContent/BigTitle';
import { shadesOfBlue } from '@intus-ui/styles/SecondaryColors';
import { FallsPrevPropType } from 'Homes/modalPropTypes/fallsPreviewPropType';
import { abbreviateNumber } from '@util/utilFunctions';

const fallsThisQ = () => (
  <div className="p-2 d-flex flex-column align-items-start justify-content-start">
    <h6 className="text-primary">This is the total falls count to date for this quarter.</h6>
  </div>
);

const FallsCount = ({ content, error }) => (
  <Col lg={6} className="d-flex flex-column justify-content-center align-items-center">
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center align-items-center">
        <BigTitle title="Falls" secondary className="font-weight-bold" />
        <SimpleTooltip className="d-inline-block" content={fallsThisQ()}>
          <InfoIcon variant="success" className="info-icon cursor-pointer p-0 ml-1" />
        </SimpleTooltip>
      </div>
      <BigTitle title="this quarter" secondary className="font-weight-bold" />
      {content && content.fallsCurrentQuarter ? (
        <TextData
          data={abbreviateNumber(content.fallsCurrentQuarter.value, 0)}
          subtext={{ text: 'Falls', vertical: true }}
          className="justify-content-center"
          textColor={shadesOfBlue.intusNavy}
        />
      ) : (
        <SpinnerOrError error={content.projectedFallsCurrentQuarter ? error : '-'} />
      )}
    </div>
  </Col>
);

FallsCount.propTypes = {
  content: FallsPrevPropType,
  error: PropTypes.string,
};

FallsCount.defaultProps = {
  error: '',
  content: undefined,
};

const mapState = (state) => ({
  error: state.homepage.homeError,
});

const mapDispatch = (dispatch) => ({
  doSetSelected: (item, name) => dispatch(setSelected(item, name)),
});

export default connect(mapState, mapDispatch)(FallsCount);

import { get } from '@api/apiMethods';

/* *************** */
/* *** Utilization QUERIES *** */
/* *************** */

export type UtilizationParams = {
  startDate: string;
  endDate: string;
  type: string;
  orgIds: string;
};

export const getUtilizationData = ({ startDate, endDate, type, orgIds }: UtilizationParams) =>
  get(
    `analysis/utilization?startDate=${startDate}&endDate=${endDate}&orgIds=${orgIds}&type=${type}`
  );

import React, { FC } from 'react';
import {
  IAllPatientFirstFillStatus,
  allPatientFirstFillStatus,
  firstFillStatusToDescription,
} from '@api/polypharmacy/getRisingStars';
import { Multiselect, MultiselectProps } from '@intus-ui/components/Multiselect/Multiselect';

type PatientStatusFilterProps = {
  patientStatusFilter: IAllPatientFirstFillStatus[];
  setPatientStatusFilter: (status: IAllPatientFirstFillStatus[]) => void;
} & Pick<MultiselectProps<IAllPatientFirstFillStatus>, 'sx'>;

export const PatientStatusFilter: FC<PatientStatusFilterProps> = (props) => {
  const { patientStatusFilter, setPatientStatusFilter, ...otherProps } = props;

  const allItems: IAllPatientFirstFillStatus[] = [...allPatientFirstFillStatus].sort();

  return (
    <Multiselect
      allowSelectAll
      doNotSort
      items={allItems}
      selectedItems={patientStatusFilter}
      getItemText={(item) => {
        return firstFillStatusToDescription[item];
      }}
      onChange={(e) => {
        setPatientStatusFilter(e);
      }}
      {...otherProps}
    />
  );
};

import { useState, useEffect } from 'react';
import { last, orderBy } from 'lodash';
import produce from 'immer';
import { useParams, useHistory } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';

import { getStringReplacement } from '@util/stringReplacements';
import { getLogger } from '@util/logger';
import * as userTracking from '@util/userTracking';
import { useLazyQuery } from '@api/useQuery';
import { getDashboardById, saveCustomDashboardById } from '@api/dashboard';
import { SpinnerOrError, useParticipantFilters } from '@intus-ui';

import { MetricLibraryModal } from 'CustomDashboardV2/MetricLibrary/MetricLibraryModal';
import { CustomDashboardCompareView } from 'CustomDashboardV2/CompareView/CustomDashboardCompareView';
import { CustomDashboardHeader } from './MainView/CustomDashboardHeader';
import WidgetGrid from './WidgetGrid';

const log = getLogger('customDashboardV2');

export const CustomDashboardV2 = () => {
  const { customDashId } = useParams();
  const history = useHistory();
  const [openMetricLibraryModal, setOpenMetricLibraryModal] = useState(false);
  const [metricToEdit, setMetricToEdit] = useState(null);
  const [isCompareOpen, setIsCompareOpen] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openEditNameModal, setOpenEditNameModal] = useState(false);
  const [newMetricId, setNewMetricId] = useState(null);

  const { areFiltersLoaded } = useParticipantFilters();

  useEffect(() => {
    // If the metric library is closed, clear out the metric they were editing.
    if (!openMetricLibraryModal) {
      setMetricToEdit(null);
    }
    const params = new URLSearchParams(history.location.search);
    // Add a query param to the URL to indicate that the metric library is open.
    // This is used in AppCues to show a tutorial if on the tutorial URL.
    if (openMetricLibraryModal === true && metricToEdit == null) {
      params.set('createModalOpen', true);

      history.replace({
        search: `?${params.toString()}`,
      });
    } else {
      params.delete('createModalOpen');

      history.replace({
        search: `?${params.toString()}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMetricLibraryModal]);

  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState(null);

  const {
    data: originalData,
    loading,
    error,
    runQuery,
  } = useLazyQuery((customDashId) => getDashboardById(customDashId));

  const data =
    history.location.pathname.includes('tutorial') && originalData == null
      ? getTutorialDashboardData()
      : originalData;

  const {
    runQuery: runUpdateDashboardQuery,
    error: updateDashboardError,
    loading: updateDashboardLoading,
  } = useLazyQuery((updatedDashboard) => saveCustomDashboardById(customDashId, updatedDashboard), {
    onSuccess: (updatedDashboard) => {
      runQuery(updatedDashboard.id);
      if (customDashId == null) {
        const params = new URLSearchParams(history.location.search);
        history.replace(`/customdashboards/${updatedDashboard.id}?${params.toString()}`);
      }
      setOpenMetricLibraryModal(false);
      setOpenEditNameModal(false);
      setOpenShareModal(false);
    },
    onError: (error) => {
      log.error('Error updating dashboard', error);
      setSnackbarErrorMessage('An error occurred updating the dashboard');
    },
  });

  async function saveDashboardIfNotSaved() {
    // If the dashboard isn't saved yet, save it before running the compare query.
    if (data == null) {
      const updatedDashboard = {
        name: 'Untitled Dashboard',
        isShared: false,
        metrics: [],
      };
      const dashboard = await runUpdateDashboardQuery(updatedDashboard);
      return dashboard?.data;
    }
    if (data.id == null) {
      const dashboard = await runUpdateDashboardQuery(data);
      return dashboard?.data;
    }

    return data;
  }

  function deleteMetric(metricId) {
    // Should be impossible.
    if (!data) {
      throw new Error("Can't delete metric from dashboard that hasn't loaded yet");
    }
    const currentMetric = data.metrics.find((metric) => metric.id === metricId);
    const updatedDashboard = produce(data, (draft) => {
      const metricIndex = draft.metrics.findIndex((metric) => metric.id === metricId);
      draft.metrics.splice(metricIndex, 1);
    });
    runUpdateDashboardQuery(updatedDashboard);
    userTracking.logEvent('Dashboard: Deleted Metric', {
      id: currentMetric.id,
      metricId: currentMetric.metricId,
    });
  }

  function updateMetric(metric) {
    const isNew = metric.id == null;
    if (data == null) {
      const newDashboard = {
        name: 'Untitled Dashboard',
        isShared: false,
        metrics: [{ ...metric }],
      };
      runUpdateDashboardQuery(newDashboard);
      userTracking.logEvent('Dashboard: Created new Metric', {
        metricId: metric.metricId,
      });
    } else {
      const existingDashboard = { ...data };
      const dashboardToSave = produce(existingDashboard, (draft) => {
        if (metric.id != null) {
          const metricIndex = draft.metrics.findIndex((m) => m.id === metric.id);

          if (metricIndex === -1) {
            draft.metrics.push(metric);
          } else {
            draft.metrics[metricIndex] = metric;
          }
        } else {
          draft.metrics.unshift(metric);
        }
      });
      runUpdateDashboardQuery(dashboardToSave, metric).then((res) => {
        if (!isNew) return;
        if (res.data == null) return;
        const newestMetric = last(orderBy(res.data.metrics, (metric) => metric.id));
        if (newestMetric == null) return;
        setNewMetricId(newestMetric.id);
      });
      userTracking.logEvent('Dashboard: Edited existing Metric', {
        metricId: metric.metricId,
      });
    }

    setOpenMetricLibraryModal(false);
  }

  useEffect(() => {
    if (customDashId != null) {
      runQuery(customDashId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDashId]);

  function onAddNewMetric() {
    setOpenMetricLibraryModal(true);
    userTracking.logEvent('Dashboard: Clicked Add New Metric');
  }

  if (error) {
    return (
      <div style={styles.container}>
        <SpinnerOrError error="An error occurred loading the custom dashboard" />
      </div>
    );
  }

  if (!areFiltersLoaded) {
    return (
      <div style={styles.container}>
        <SpinnerOrError />
      </div>
    );
  }

  if (loading && !data) {
    return (
      <div style={styles.container}>
        <SpinnerOrError />
      </div>
    );
  }

  return (
    <div style={styles.container}>
      {/* Shows error messages if things like deleting a metric fails. */}
      <Snackbar
        open={snackbarErrorMessage != null}
        autoHideDuration={5000}
        onClose={() => setSnackbarErrorMessage(null)}
      >
        <Alert
          onClose={() => setSnackbarErrorMessage(null)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {snackbarErrorMessage}
        </Alert>
      </Snackbar>

      <CustomDashboardHeader
        dashboard={data}
        onAddNewMetric={() => onAddNewMetric()}
        toggleIsCompareOpen={() => setIsCompareOpen(!isCompareOpen)}
        isCompareOpen={isCompareOpen}
        runUpdateDashboardQuery={runUpdateDashboardQuery}
        saveDashboardIfNotSaved={() => saveDashboardIfNotSaved()}
        updateDashboardError={updateDashboardError}
        setOpenEditNameModal={setOpenEditNameModal}
        openEditNameModal={openEditNameModal}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        updateDashboardLoading={updateDashboardLoading}
        setSnackbarErrorMessage={setSnackbarErrorMessage}
      />
      <div id="custom-dash-content" style={styles.content}>
        <MetricLibraryModal
          key={openMetricLibraryModal.toString()}
          openMetricLibraryModal={openMetricLibraryModal}
          setOpenMetricLibraryModal={setOpenMetricLibraryModal}
          onUpdateMetric={(metric) => updateMetric(metric)}
          metricToEdit={metricToEdit}
        />

        {!isCompareOpen && (
          <WidgetGrid
            dashboard={data}
            onAddNewMetric={() => onAddNewMetric()}
            deleteMetricById={(metricId) => deleteMetric(metricId)}
            onEditMetric={(metric) => {
              setMetricToEdit(metric);
              setOpenMetricLibraryModal(true);
            }}
            runUpdateDashboardQuery={runUpdateDashboardQuery}
            newMetricId={newMetricId}
            setSnackbarErrorMessage={setSnackbarErrorMessage}
          />
        )}

        {isCompareOpen && (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <CustomDashboardCompareView
              dashboard={data}
              setIsCompareOpen={setIsCompareOpen}
              saveDashboardIfNotSaved={() => saveDashboardIfNotSaved()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flex: '1 1 100%',
    flexDirection: 'column',
    backgroundColor: '#F6F7FA',
    padding: '10px 20px',
    overflowY: 'auto',
  },
  content: {
    flex: '1 1 100%',
    display: 'flex',
  },
};

function getTutorialDashboardData() {
  return {
    name: 'Untitled Dashboard',
    canUserEdit: true,
    isShared: false,
    // The metrics were literally just copied from the response of the GET request to `/customdashboard/:id`.
    // To update them just make a dashboard with those metrics and copy and paste the response.
    metrics: [
      {
        id: null,
        metricId: 'er-breakdown',
        name: 'ER Visit Breakdown',
        metricConfig: {
          breakdownType: 'living-situation',
        },
        component: 'Breakdown',
        order: 1,
        modal: {
          component: 'BreakdownFocusView',
        },
      },
      {
        id: null,
        metricId: 'inpatient-admissions',
        name: 'Inpatient Admissions',
        metricConfig: {
          showChart: true,
          metricType: 'count',
        },
        component: 'Stat',
        order: 2,
        modal: {
          component: 'StatFocusView',
        },
      },
      {
        id: null,
        metricId: 'total-participants',
        name: `Total ${getStringReplacement('Participant', { plural: true })}`,
        metricConfig: {
          showChart: false,
        },
        component: 'Stat',
        order: 3,
        modal: {
          component: 'StatFocusView',
        },
      },
    ],
  };
}

export const matteColors = ['#AABDD7', '#B0C3F5', '#A3AAE0', '#A7CBD3', '#82A5BE', '#A999CB'];

export const brightColors = [
  '#60D1D9',
  '#479ECE',
  '#94B0F9',
  '#999CD1',
  '#4DBAF8',
  '#B9A1EB',
  '#A7DEEA',
  '#6E7BEF',
  '#8BC8CC',
  '#A7DEEA',
];

export const brightColorsRGB = [
  'rgba(96, 209, 217, 1.0)',
  'rgba(71, 158, 206)',
  'rgba(148, 176, 249, 1.0)',
  'rgba(153, 156, 209, 1.0)',
  'rgba(77, 186, 248, 1.0)',
  'rgba(185, 161, 235, 1.0)',
  'rgba(167, 222, 234, 1.0)',
  'rgba(110, 123, 239, 1.0)',
  'rgba(139, 200, 204, 1.0)',
  'rgba(167, 222, 234, 1.0)',
];

export default brightColors;

import { useEffect, useMemo, useState } from 'react';

import { getStringReplacement } from '@util/stringReplacements';
import { getTrial, getMetricForTrial, postDownloadInitiative } from '@api/initiatives';
import { useLazyQuery } from '@api/useQuery';
import { useHistory, useParams } from 'react-router-dom';

import { formatDate } from '@util/dateFunctions';

import {
  Text,
  Button,
  Icon,
  Input,
  SingleDateSelect,
  SpinnerOrError,
  ParticipantList,
} from '@intus-ui';

import SmallCohortCard from 'AdvancedCohort/SmallCohortCard';
import { TrialMetricChart } from 'Initiatives/MetricChart/TrialMetricChart';
import { InitiativeDeleteModal } from 'Initiatives/InitiativeDeleteModal';
import * as userTracking from '@util/userTracking';
import { MetricsCurrentSummary } from 'Initiatives/InitiativeFocusView/MetricsCurrentSummary';
import { getTrialDateText } from 'Initiatives/Trials/getTrialDateText';
import { createCustomColumns } from '@intus-ui/components/ParticipantList/helpers';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { CohortDropdown } from '@intus-ui/components/ParticipantList/filters/CohortDropdown';
import { ParticipantCohortTags } from '@intus-ui/components/ParticipantList/columns/ParticipantCohortTags';
import { useDownload } from '@api/useDownload';
import { MetricParticipantsDrillDownList } from 'Initiatives/InitiativeFocusView/MetricParticipantsDrillDownList';
import { styles } from './styles';

const MetricsView = ({ trial, selectedMetric, clickedChartMonth, onClickChartMonth, loading }) => {
  const history = useHistory();

  // let totalCount = 0;

  const participantsWithCohorts = useMemo(() => {
    if (trial == null || selectedMetric == null) return [];

    const patientIdToCohorts = new Map();

    for (const cohort of trial.cohorts ?? []) {
      for (const patientId of cohort.cohortPatients.patientIds) {
        if (!patientIdToCohorts.has(patientId)) {
          patientIdToCohorts.set(patientId, []);
        }
        patientIdToCohorts.get(patientId).push(cohort);
      }
    }
    return selectedMetric.participants.map((ppt) => {
      const cohorts = patientIdToCohorts.get(ppt.id);
      return {
        ...ppt,
        cohorts,
      };
    });
  }, [trial, selectedMetric]);

  return (
    <div style={styles.metricsContainer}>
      {loading && (
        <div style={styles.loadingMetricsContainer}>
          <SpinnerOrError />
        </div>
      )}
      {!loading && (
        <>
          <div style={styles.chartContainer}>
            <div style={styles.metric}>
              <div style={styles.metricContent}>
                <div style={styles.chart}>
                  <TrialMetricChart
                    trial={trial}
                    metric={selectedMetric}
                    onClickChart={(month) => onClickChartMonth(month)}
                  />
                </div>
                {clickedChartMonth == null && <MetricsCurrentSummary metric={selectedMetric} />}
                {clickedChartMonth != null && (
                  <MetricParticipantsDrillDownList
                    metric={selectedMetric}
                    clickedChartMonth={clickedChartMonth}
                    setClickedChartMonth={onClickChartMonth}
                  />
                )}
              </div>
            </div>
          </div>
          <div style={styles.pptList}>
            <ParticipantList
              participantList={participantsWithCohorts}
              customColumnConfig={addColumnsToDefault(
                columnConfig,
                selectedMetric?.columnFormats ?? []
              )}
              customFormat={convertColumnConfig(selectedMetric?.columnFormats ?? [])}
              searchable
              onClickItem={(ppt) => history.push(`/patient/${ppt.id}/current-acuity`)}
              defaultSort={{ field: 'count', direction: 'desc' }}
              titleRowElement={
                <>
                  <Text type="subtitle" color="navy">
                    Participant List
                  </Text>
                  {/* ADD BACK IN LATER IF NEEDED */}
                  {/* <Text>
                {totalCount} instances across {selectedMetric?.participants?.length} participants.
              </Text> */}
                </>
              }
              virtualizedOptions={{
                height: 320,
                // The height of each row (33.5px) plus the 10px gap between rows.
                itemSize: 43.5,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

const Sidebar = ({ data }) => {
  const history = useHistory();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <div style={styles.sidebar}>
      {data?.initiative && (
        <InitiativeDeleteModal
          // DO NOT REMOVE KEY
          // Use key to destroy the modal completely when it is closed. This prevents any error text from showing up if the user closes/re-opens the modal.
          key={`deleteModal-${isDeleteModalOpen}`}
          initiative={data?.initiative}
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
        />
      )}

      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Button
          style={{ width: '83px' }}
          name="Back"
          onClick={() => {
            sessionStorage.removeItem('initiativeFormValues');
            history.goBack();
          }}
        >
          <Icon name="caret-left" />
        </Button>
        <Text type="headline">{data?.initiative?.name}</Text>

        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <Button
            name="Edit"
            secondary
            onClick={() => {
              userTracking.logEvent('Initiatives: clicked "Edit Trial" button in Trial Focus View');
              sessionStorage.removeItem('initiativeFormValues');
              history.push(`/initiatives/${data?.initiative.id}/trials/${data?.id}/edit`);
            }}
          >
            <Icon name="Edit" color="#052D8F" />
          </Button>

          <Button
            name="Delete"
            secondary
            onClick={() => {
              sessionStorage.removeItem('initiativeFormValues');
              setIsDeleteModalOpen(true);
            }}
          >
            <Icon name="Trash" color="#052D8F" />
          </Button>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Text type="body" color="caption">
          Description
        </Text>
        <Text type="body">{data?.initiative?.description || 'No description provided.'}</Text>
      </div>

      <div style={styles.cohortsList}>
        <div style={styles.header}>
          <Text type="body" color="caption">
            Cohorts
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            paddingTop: '5px',
            gap: '5px',
          }}
        >
          <div style={styles.cohortCards}>
            {data?.cohorts?.map((cohort) => (
              <SmallCohortCard
                clickable
                key={cohort.id}
                cohort={cohort}
                style={{ width: '200px' }}
                dateText={<Text>{getTrialDateText(cohort)} </Text>}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

function convertColumnConfig(columnConfig) {
  const customColumns = createCustomColumns(columnConfig);
  return [
    {
      field: 'name',
      name: `${getStringReplacement('Participant')} Name`,
      flex: '1 1 275px',
      align: 'start',
      addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
    },
    {
      field: 'cohorts',
      name: 'Cohorts',
      flex: '1 1 450px',
      align: 'start',
      addOns: [{ type: 'filter', element: <CohortDropdown key="cohortFilter" /> }],
      render({ item }) {
        return <ParticipantCohortTags participant={item} />;
      },
    },
    ...customColumns,
  ];
}

function addColumnsToDefault(columnConfig, columnFormats) {
  const defaultColumns = columnConfig.default.columns;
  columnFormats.forEach((column) => {
    if (!defaultColumns.includes(column.field)) {
      defaultColumns.push(column.field);
    }
  });
  return { default: { columns: defaultColumns } };
}

const columnConfig = {
  default: {
    columns: ['name', 'cohorts'],
  },
};

const TrialHeader = ({ initiative, selectedDate }) => {
  const initiativeId = initiative?.id;

  // Replace non alphanumeric characters in the initiative name.
  const fileName = `${initiative?.name?.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.xlsx`;

  const { runQuery, loading, error } = useDownload(fileName, (initiativeId, selectedDate) =>
    postDownloadInitiative(initiativeId, selectedDate)
  );

  return (
    <div style={styles.header}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text type="title">Metrics</Text>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Button
            busy={loading}
            secondary
            name="Download"
            onClick={() => runQuery(initiativeId, selectedDate)}
          />
          {error && (
            <Text color="error">An error occurred downloading the initiatives spreadsheet.</Text>
          )}
        </div>
      </div>
    </div>
  );
};

const TrialContents = ({
  data,
  selectedMetric,
  setSelectedMetric,
  selectedDate,
  setSelectedDate,
}) => {
  const [clickedChartMonth, setClickedChartMonth] = useState(null);

  const {
    data: metricData = [],
    loading,
    runQuery,
  } = useLazyQuery((params) =>
    getMetricForTrial(data.initiativeId, data.id, selectedMetric.id, params.startDate)
  );

  const initiativeId = data?.initiativeId;

  useEffect(() => {
    if (initiativeId != null && selectedMetric != null) {
      runQuery({
        startDate: formatDate(selectedDate || data?.chartsConfig?.startDate, 'YYYY-MM-DD'),
      });
    }
  }, [selectedMetric, initiativeId]);

  return (
    <div style={styles.contents}>
      <div style={styles.title}>
        <div style={styles.metricSelectContainer}>
          <Text type="caption">Metric Type</Text>
          <Input
            id="select"
            name="select"
            type="select"
            rightIcon="caret-down"
            value={selectedMetric?.name}
            onChange={(val) => {
              setSelectedMetric(data?.metrics?.find((item) => item.name === val));
            }}
            style={{ width: '300px' }}
            options={data?.metrics?.map((metric) => metric.name)}
          />
        </div>
        <div style={styles.datePicker}>
          <Text type="caption">Metric Chart Start Date</Text>
          <SingleDateSelect
            calendarProps={{
              maxDate: new Date(),
              minDate: new Date(2013, 0, 1),
            }}
            onSelectDate={(date) => {
              setClickedChartMonth(null);
              setSelectedDate(formatDate(date, 'YYYY-MM-DD'));
              runQuery({ startDate: formatDate(date, 'YYYY-MM-DD') });
            }}
            existingDate={data?.chartsConfig?.startDate}
          />
        </div>
      </div>
      {!!data && !!selectedMetric && (
        <MetricsView
          trial={data}
          selectedMetric={metricData || selectedMetric}
          clickedChartMonth={clickedChartMonth}
          onClickChartMonth={setClickedChartMonth}
          loading={loading}
        />
      )}
    </div>
  );
};

const InitiativeFocusView = () => {
  const { initiativeId, trialId } = useParams();
  const [data, setData] = useState(undefined);
  const [selectedMetric, setSelectedMetric] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(undefined);

  const loadTrial = () => {
    getTrial(initiativeId, trialId).then(async (result) => {
      if (result.ok) {
        const json = await result.json();
        setData(json);
        setSelectedMetric(json?.metrics[0]);
        setSelectedDate(json?.chartsConfig?.startDate);
      }
    });
  };

  useEffect(() => {
    loadTrial();
  }, [trialId]);

  return (
    <div style={styles.container}>
      <Sidebar data={data} loadTrial={loadTrial} />
      <div style={styles.trialContainer}>
        <div style={styles.trial}>
          <TrialHeader initiative={data?.initiative} selectedDate={selectedDate} />
          <TrialContents
            data={data}
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>
      </div>
    </div>
  );
};

export default InitiativeFocusView;

import { get } from '@api/apiMethods';
import { buildPatientFilterQueryString } from '@api/patients/buildPatientFilterQueryString';

/* *************** */
/* *** Admits QUERIES *** */
/* *************** */

type CohortFilter = {
  name: string;
  isBilling: boolean;
  id: number;
};

const admitTypeMap = {
  'Acute Inpatient': 'IP',
  'Assisted Living Facility': 'ALF',
  'ED to Inpatient': 'ED/IP',
  'ED to Observation': 'ED/OBS',
  'ED Treat-and-Release': 'ED',
  Hospice: 'HOSP',
  'Inpatient Psychiatric': 'IP PSYCH',
  'Inpatient Rehabilitation': 'REHAB',
  'Long Term Care': 'LTC',
  'Long Term Acute Care': 'LTAC',
  Observation: 'OBS',
  Other: 'U',
  Respite: 'RES',
  'Skilled Nursing Facility': 'SNF',
};

export type AdmitsFeedParams = {
  selectedFilters: {
    cohorts: CohortFilter[];
    facilities: string[];
    groups: any; // TODO: How do we get which groups a user is able to see?
    organizations: number[] | null;
    teams: string[];
  };
  startDate: string;
  endDate: string;
  admitsType:
    | 'Assisted Living Facility'
    | 'ED Treat-and-Release'
    | 'ED to Observation'
    | 'ED to Inpatient'
    | 'Acute Inpatient'
    | 'Respite'
    | 'Hospice'
    | 'Skilled Nursing Facility'
    | 'Observation'
    | 'Inpatient Psychiatric'
    | 'Other'
    | 'Long Term Care'
    | 'Long Term Acute Care'
    | 'Inpatient Rehabilitation';
};

export const getAdmitsFeedData = ({
  startDate,
  endDate,
  admitsType,
  selectedFilters,
}: AdmitsFeedParams) => {
  const filters = buildPatientFilterQueryString(selectedFilters);
  const type = encodeURIComponent(admitTypeMap[admitsType]);
  return get(`admits/feed?startDate=${startDate}&endDate=${endDate}&type=${type}&${filters}`);
};

export const getAdmitsInsightsData = (params: AdmitsFeedParams) => {
  const filters = buildPatientFilterQueryString(params.selectedFilters);
  const type = encodeURIComponent(admitTypeMap[params.admitsType]);
  return get(
    `admits/insights?startDate=${params.startDate}&endDate=${params.endDate}&type=${type}&${filters}`
  );
};

import ReactModal from 'react-modal';

import React, { CSSProperties, FC } from 'react';
import { modalTypes } from './config';

import Divider from '../Divider';
import Text from '../Text';
import Icon from '../Icon';

type ModalProps = {
  open?: boolean;
  onClose?: () => void;
  afterOpenModal?: () => void;
  type?: keyof typeof modalTypes;
  header?: {
    title: string | React.ReactNode;
    centered?: boolean;
  };
  children?: React.ReactNode;
  style?: React.CSSProperties;
  modalStyles?: React.CSSProperties;
};

const Modal: FC<ModalProps> = (props) => {
  const {
    open = false,
    onClose,
    afterOpenModal,
    type = 'medium',
    header,
    children,
    style,
    modalStyles,
  } = props;
  return (
    <ReactModal
      isOpen={open}
      onAfterOpen={afterOpenModal}
      onRequestClose={onClose}
      style={
        {
          ...modalTypes[type],
          content: { ...modalTypes[type].content, ...modalStyles },
        } as any
      }
    >
      {header && (
        <div style={styles.modalHeaderWrapper}>
          <div style={styles.modalHeader}>
            <div style={{ ...styles.headerTitle, flex: header.centered ? 1 : undefined }}>
              {typeof header.title === 'string' ? (
                <Text type="title" color="navy">
                  {header.title}
                </Text>
              ) : (
                header.title
              )}
            </div>
            <div>
              <Icon name="Remove" color="#9D9D9D" hoverColor="#9D9D9D" onClick={onClose} />
            </div>
          </div>
          <Divider />
        </div>
      )}
      <div style={{ ...styles.modalContentWrapper, ...style }}>{children}</div>
    </ReactModal>
  );
};

export default Modal;

const styles: Record<string, CSSProperties> = {
  modalHeaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    overflowY: 'visible',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflowY: 'visible',
  },
  headerTitle: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
  },
  modalContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    padding: 0,
    // height: 'fit-content',
    overflowY: 'auto',
  },
};

// Type for color values (hex codes)
type ColorValue = string;

// Type for the color lightness variations
interface ColorVariations {
  100: ColorValue;
  80: ColorValue;
  60: ColorValue;
  40: ColorValue;
  20: ColorValue;
}

// Enum for lightness levels to avoid magic numbers
export enum LightnessLevel {
  Base = 100,
  Lighter80 = 80,
  Lighter60 = 60,
  Lighter40 = 40,
  Lighter20 = 20,
}
// Color palette
export const colorPalette: { [colorName: string]: ColorVariations } = {
  blue: {
    [LightnessLevel.Base]: '#007BC7',
    [LightnessLevel.Lighter80]: '#429FD8',
    [LightnessLevel.Lighter60]: '#7CBDD0',
    [LightnessLevel.Lighter40]: '#A4CEE7',
    [LightnessLevel.Lighter20]: '#C1E0F4',
  },
  orange: {
    [LightnessLevel.Base]: '#D4420C',
    [LightnessLevel.Lighter80]: '#E97145',
    [LightnessLevel.Lighter60]: '#EA8F6D',
    [LightnessLevel.Lighter40]: '#F3BCA7',
    [LightnessLevel.Lighter20]: '#FCD5C7',
  },
  purple: {
    [LightnessLevel.Base]: '#8C54A6',
    [LightnessLevel.Lighter80]: '#B17DC9',
    [LightnessLevel.Lighter60]: '#C69CD9',
    [LightnessLevel.Lighter40]: '#DEBEEE',
    [LightnessLevel.Lighter20]: '#F4DEFF',
  },
  green: {
    [LightnessLevel.Base]: '#3D823A',
    [LightnessLevel.Lighter80]: '#63A760',
    [LightnessLevel.Lighter60]: '#82C07F',
    [LightnessLevel.Lighter40]: '#B0E7AE',
    [LightnessLevel.Lighter20]: '#D4FFD2',
  },
  pink: {
    [LightnessLevel.Base]: '#DB2489',
    [LightnessLevel.Lighter80]: '#F35AAE',
    [LightnessLevel.Lighter60]: '#FF88C5',
    [LightnessLevel.Lighter40]: '#FFB1DC',
    [LightnessLevel.Lighter20]: '#FFD7ED',
  },
  maroon: {
    [LightnessLevel.Base]: '#8F1717',
    [LightnessLevel.Lighter80]: '#D04A4A',
    [LightnessLevel.Lighter60]: '#E27676',
    [LightnessLevel.Lighter40]: '#F5A2A2',
    [LightnessLevel.Lighter20]: '#FDC2C2',
  },
  teal: {
    [LightnessLevel.Base]: '#088191',
    [LightnessLevel.Lighter80]: '#57C2D1',
    [LightnessLevel.Lighter60]: '#8CDEE8',
    [LightnessLevel.Lighter40]: '#BCEDE8',
    [LightnessLevel.Lighter20]: '#ACF3FC',
  },
  pine: {
    [LightnessLevel.Base]: '#046246',
    [LightnessLevel.Lighter80]: '#27A884',
    [LightnessLevel.Lighter60]: '#64CAE0',
    [LightnessLevel.Lighter40]: '#8EE9CE',
    [LightnessLevel.Lighter20]: '#B6FBE6',
  },
  indigo: {
    [LightnessLevel.Base]: '#5B07D2',
    [LightnessLevel.Lighter80]: '#949FF5',
    [LightnessLevel.Lighter60]: '#B483F9',
    [LightnessLevel.Lighter40]: '#EBD7FF',
    [LightnessLevel.Lighter20]: '#D0AEFF',
  },
  brown: {
    [LightnessLevel.Base]: '#7C4925',
    [LightnessLevel.Lighter80]: '#C2865C',
    [LightnessLevel.Lighter60]: '#D8AA7F',
    [LightnessLevel.Lighter40]: '#EBC1A3',
    [LightnessLevel.Lighter20]: '#F5DAC9',
  },
  gray: {
    [LightnessLevel.Base]: '#282828',
    [LightnessLevel.Lighter80]: '#646464',
    [LightnessLevel.Lighter60]: '#A0A0A0',
    [LightnessLevel.Lighter40]: '#CFCFCF',
    [LightnessLevel.Lighter20]: '#E6E6E6',
  },
};



type BaseColorName = keyof typeof colorPalette;

export function getAllVariantsForColors(colorNames: BaseColorName[]): string[] {
  let colorVariants: string[] = [];

  colorNames.forEach((colorName) => {
    if (colorPalette[colorName]) {
      colorVariants = colorVariants.concat([
        colorPalette[colorName][LightnessLevel.Base], // 100
        colorPalette[colorName][LightnessLevel.Lighter80], // 80
        colorPalette[colorName][LightnessLevel.Lighter60], // 60
        colorPalette[colorName][LightnessLevel.Lighter40], // 40
        colorPalette[colorName][LightnessLevel.Lighter20], // 20
      ]);
    }
  });

  return colorVariants;
}

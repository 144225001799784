import { BreakdownWidget } from '@intus-ui/components/Widget/components/Breakdown';
import { widgetSizes } from '@intus-ui/components/Widget/config';
import { StatWidget, getStatWidgetSize } from '@intus-ui/components/Widget/components/Stat';
import { StatFocusViewWidget } from '@intus-ui/components/Widget/components/StatFocusView';
import { BreakdownFocusViewWidget } from '@intus-ui/components/Widget/components/BreakdownFocusView';
import { ParticipantListFocusView } from '@intus-ui/components/Widget/components/ParticipantListFocusView';

// widget components mapping
export const widgetComponents = {
  Stat: StatWidget,
  ParticipantList: ParticipantListFocusView,
  Breakdown: BreakdownWidget,
  StatFocusView: StatFocusViewWidget,
  BreakdownFocusView: BreakdownFocusViewWidget,
};

export function getWidgetSize(componentName, metricConfig) {
  // This shouldn't happen, if it does just default to the small size.
  if (componentName == null) {
    return widgetSizes.small;
  }

  switch (componentName) {
    case 'Stat':
      return getStatWidgetSize(metricConfig);
    case 'Breakdown':
      return widgetSizes.large;
    case 'ParticipantList':
      return widgetSizes.small;
    default:
      throw new Error(`Unrecognized widget component type = ${componentName}`);
  }
}

import { getStringReplacement } from '@util/stringReplacements';
import { ParticipantList, Button, SearchInput, SpinnerOrError } from '@intus-ui';
import FilterSummary from 'AdvancedCohort/SearchCreate/ResultView/FilterSummary';
import { format } from './customFormat';
import { renderAddButton, renderTitleElement } from './helpers';

export const ListWrapper = ({
  setView,
  setSearchTerm,
  loading,
  error,
  data,
  searchFilteredData,
  history,
  handleAddPpts,
  searchState,
  emptyListMessage,
}) => (
  <div style={{ ...styles.section, flex: 1 }}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '30px',
        paddingLeft: '15px',
      }}
    >
      <SearchInput
        containerStyle={{ maxWidth: '560px' }}
        placeholder="Search by name"
        handleSearch={(e) => {
          setSearchTerm(e.target.value);
        }}
      />
      <Button
        secondary
        onClick={() => setView('search')}
        badge={searchState?.filter?.groups?.length}
        badgeBgColor="#052D8F"
      >
        Filters
      </Button>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        gap: 15,
        padding: '15px 20px 0px 20px',
      }}
    >
      {renderTitleElement(
        searchFilteredData,
        searchState?.filter?.groups?.length > 0 ? 'Results' : getStringReplacement('Participant', { plural: true })
      )}
      <FilterSummary filter={searchState?.filter} />
    </div>
    {!loading && !error && data != null && (
      <ParticipantList
        participantList={searchFilteredData}
        searchable={false}
        customFormat={format()}
        defaultSort={{ field: 'acuity', direction: 'desc' }}
        onClickItem={(ppt) => history.push(`/patient/${ppt.id}/current-acuity`)}
        extraRenders={{
          afterRow: (rowData) => renderAddButton(rowData, handleAddPpts),
          afterHeader:
            searchFilteredData.length !== 0 ? (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'start',
                  maxWidth: '45px',
                  padding: '0px',
                  margin: '0px 8px 0px 0px',
                }}
              >
                <Button
                  textOnly
                  onClick={() => handleAddPpts(undefined, true)}
                  style={{
                    minWidth: 'fit-content',
                    maxWidth: '100px',
                  }}
                >
                  Add all
                </Button>
              </div>
            ) : null,
        }}
        emptyListMessage={emptyListMessage}
      />
    )}
    {(loading || error) && <SpinnerOrError />}
  </div>
);

const styles = {
  section: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #9D9D9D',
    borderRadius: '10px',
    maxHeight: '100%',
    background: '#FFFFFF',
    padding: '20px 0px 0px 0px',
    overflow: 'auto',
  },
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { stringToClassname } from '@util/utilFunctions';

import { setSelected, getModalContent } from '@global-state/redux/homepageSlice';

import { SpinnerOrError, Text, useParticipantFilters } from '@intus-ui';
import Quad from 'Dashboard/Quad';
import { getPreview, getCard } from 'Homes/cardMap';

const PreviewQuad = ({ boxName, content, growNum, error }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const startDate = useSelector((state) => state.filters.startDate);
  const endDate = useSelector((state) => state.filters.endDate);

  const { selectedFilters } = useParticipantFilters();

  const cardToReturn = () => {
    if (content === 0) {
      return (
        <Quad title={getCard(boxName)?.title} disabled>
          <Text>
            We currently don’t have data for the {getCard(boxName)?.title} Card.
            <Text type="subtitle">
              <a href="/settings/homepage/cardSelections"> Click here </a>
            </Text>{' '}
            to select a different option to display.
          </Text>
        </Quad>
      );
    }
    return (
      <Quad
        title={getCard(boxName).title}
        onClick={() => {
          dispatch(setSelected({ boxName, title: getCard(boxName).title }));
          dispatch(getModalContent(boxName, startDate, endDate, selectedFilters));
          history.push('/home');
        }}
      >
        {getPreview(boxName, content)}
      </Quad>
    );
  };

  return (
    <div
      id={`dashPopupPreview-${stringToClassname(getCard(boxName).title)}`}
      className="d-flex flex-basis-0 p-3 overflow-hidden"
      style={{ flexGrow: growNum }}
    >
      {content == null ? (
        <Quad padding="p-2" title={getCard(boxName).title}>
          <SpinnerOrError error={error} />
        </Quad>
      ) : (
        cardToReturn()
      )}
    </div>
  );
};

PreviewQuad.propTypes = {
  boxName: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  growNum: PropTypes.number,
  error: PropTypes.string,
};

PreviewQuad.defaultProps = {
  content: undefined,
  growNum: 1,
  error: undefined,
};

export default PreviewQuad;

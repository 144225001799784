import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLazyQuery, useQuery } from '@api/useQuery';
import { getNoteOptions, updateAdmitReviewNote } from '@api/admitReviewNotes';
import { Button, Checkbox, Input, SpinnerOrError, Tag, Text } from '@intus-ui';
import { Multiselect } from '@intus-ui/components/Multiselect/Multiselect';
import { Bullet } from '@intus-ui/icons';
import { statusToColor } from 'Admits/tables';
import { logEvent } from '@util/userTracking';
import { KeyLabel, NoteOptions, ReviewNoteFormData, ReviewNoteResponseData } from './types';
import {
  MED_RECONCILIATION,
  NOT_PREVENTABLE_STRING,
  NO_NEXT_STEPS_STRING,
  POST_DISCHARGE_EVAL,
} from './consts';
import { AdmitCard } from './AdmitCard';

export const CreateEditView = ({
  admit,
  existingArn,
  setEditMode,
  setExistingArn,
  setJustSaved,
  editMode,
}: any) => {
  const {
    data,
    loading: noteOptionsLoading,
    error: noteOptionsError,
  } = useQuery(() => getNoteOptions());
  const noteOptions = data as NoteOptions;

  const {
    control,
    // formState: { isValid, isDirty },
    reset,
    setValue,
    getValues,
    handleSubmit,
  } = useForm<ReviewNoteFormData>({
    defaultValues: {
      preventionPoints: existingArn.preventionPoints,
      notes: existingArn.notes,
      requiredSteps: ['evaluation_postdischarge', 'reconciliation_med'],
      nextSteps: existingArn.nextSteps,
    },
  });
  /**
   * ALL STATE VARIABLES FOR PREVENTION POINTS
   */
  // This is for rendering list underneath form input
  const [selectedPreventionPoints, setSelectedPreventionPoints] = useState(
    getValues('preventionPoints')
  );
  // Setting value of checkbox
  const [otherPreventionPointIsChecked, setOtherPreventionPointIsChecked] = useState(false);
  // Setting the value of the input component
  const [otherPreventionPoint, setOtherPreventionPoint] = useState(
    getValues('preventionPoints')
      .find((item) => item.includes('other')) // find 'other' item
      ?.split('|')[1] // split at bar
      ?.trim()
  );

  /**
   * ALL STATE VARIABLES FOR NEXT STEPS
   */
  const [selectedNextSteps, setSelectedNextSteps] = useState(getValues('nextSteps'));
  // Setting value of checkbox
  const [otherNextStepIsChecked, setOtherNextStepIsChecked] = useState(false);
  // Setting the value of the input component
  const [otherNextStep, setOtherNextStep] = useState(
    getValues('nextSteps')
      .find((item) => item.includes('other')) // find 'other' item
      ?.split('|')[1] // split at bar
      ?.trim()
  );

  useEffect(() => {
    if (existingArn && editMode === true) {
      // Use find method to find the 'other' prevention point
      const existingOtherPreventionPoint =
        existingArn.preventionPoints.find((item: string) => item.includes('other')) != null;
      setOtherPreventionPointIsChecked(existingOtherPreventionPoint); // Set to checked if prevention points contains other

      const existingOtherNextStep =
        existingArn.nextSteps.find((item: string) => item.includes('other')) != null;
      setOtherNextStepIsChecked(existingOtherNextStep); // Set to checked if prevention points contains other
    }
  }, [existingArn, editMode]);

  const { runQuery } = useLazyQuery(
    (arnData) => updateAdmitReviewNote({ compositeKey: admit.compositeKey, arnData }),
    {
      onSuccess: (response) => {
        setExistingArn(response as ReviewNoteResponseData);
        setJustSaved(true);
        setEditMode(false);
      },
    }
  );

  const submitFormData = (arnData: ReviewNoteFormData) => {
    logEvent('PFV Admits: Create/Edit ARN: Clicked "Save" button.');
    if (getValues('notes').length) {
      logEvent('PFV Admits: Create/Edit ARN: Saved a note to ARN.');
    }
    if (getValues('preventionPoints').length) {
      logEvent('PFV Admits: Create/Edit ARN: Saved prevention points to ARN.');
    }
    if (getValues('nextSteps').length) {
      logEvent('PFV Admits: Create/Edit ARN: Saved next steps to ARN.');
    }
    runQuery(arnData);
  };

  const handleSelectNextSteps = (value: string[]) => {
    const previousNextSteps = getValues('nextSteps');
    if (previousNextSteps.includes(NO_NEXT_STEPS_STRING)) {
      setValue('nextSteps', []);
      setSelectedNextSteps([]); // set local state to display in view.
      setOtherNextStep(undefined);
      setOtherNextStepIsChecked(false);
    } else {
      setValue('nextSteps', value);
      setSelectedNextSteps(value); // set local state to display in view.
    }
  };

  const handleSelectPreventionPoints = (value: string[]) => {
    const previousPreventionPoints = getValues('preventionPoints');
    if (previousPreventionPoints.includes(NOT_PREVENTABLE_STRING)) {
      logEvent('PFV Admits: Create/Edit ARN: Selected "Not Preventable" option.');
      setValue('preventionPoints', []);
      setSelectedPreventionPoints([]); // set local state to display in view.
      setOtherPreventionPoint(undefined);
      setOtherPreventionPointIsChecked(false);
    } else {
      setValue('preventionPoints', value);
      setSelectedPreventionPoints(value); // set local state to display in view.
    }
  };

  if (noteOptionsError || noteOptionsLoading) {
    return <SpinnerOrError />;
  }
  if (noteOptions == null) {
    return <Text color="error">Error getting data</Text>;
  }

  return (
    <form
      onSubmit={handleSubmit(submitFormData)}
      style={{
        display: 'flex',
        height: '100%',
        overflowY: 'auto',
        flexDirection: 'column',
        width: '100%',
        padding: '0px 0px 10px 15px',
        justifyContent: 'start',
        gap: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflowY: 'auto',
          gap: 15,
          padding: '0px 15px 0px 0px ',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, marginTop: '10px' }}>
          <Tag color={statusToColor[existingArn.status! as keyof typeof statusToColor] || 'grey'}>
            {existingArn.status}
          </Tag>

          <AdmitCard admit={admit} />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
        >
          <Text type="subtitle">
            What actions may have made this admission preventable?{' '}
            <Text
              type="caption"
              color={getValues('preventionPoints').length > 0 ? 'success' : 'error'}
            >
              (Required)
            </Text>
          </Text>

          <Controller
            control={control}
            name="preventionPoints"
            render={({ field }) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  {!getValues('preventionPoints').includes(NOT_PREVENTABLE_STRING) && (
                    <Multiselect
                      customInputDisplayText={
                        getValues('preventionPoints').length > 0 &&
                        !getValues('preventionPoints').includes(NOT_PREVENTABLE_STRING)
                          ? `${getValues('preventionPoints').length} prevention points`
                          : ''
                      }
                      id="preventionPoints"
                      // allowSelectAll
                      items={((noteOptions as NoteOptions).preventionPoints ?? [])
                        .map((option: KeyLabel) => option.key)
                        .filter((item) => item !== NOT_PREVENTABLE_STRING && item !== 'other')}
                      renderItem={(item) => {
                        return noteOptions.preventionPoints.find((p) => p.key === item)?.label;
                      }}
                      doNotSort
                      sx={{ minWidth: '100%' }}
                      selectedItems={field.value}
                      onChange={(value) => {
                        logEvent(
                          `PFV Admits: Create/Edit ARN: Clicked an option in "Prevention Points"`
                        );
                        handleSelectPreventionPoints(value);
                      }}
                    />
                  )}
                </div>
              );
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
              onChange={() => handleSelectPreventionPoints([NOT_PREVENTABLE_STRING])}
              checked={getValues('preventionPoints').includes(NOT_PREVENTABLE_STRING)}
            />
            <Text>Not Preventable</Text>
          </div>
          {!getValues('preventionPoints').includes(NOT_PREVENTABLE_STRING) && (
            <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Checkbox
                  checked={otherPreventionPointIsChecked}
                  onChange={(val) => {
                    if (val === true) {
                      logEvent(`PFV Admits: Create/Edit ARN: Selected "Other" prevention point`);
                      setOtherPreventionPointIsChecked(val);
                    } else if (val === false) {
                      handleSelectPreventionPoints(
                        getValues('preventionPoints').filter((item) => !item.includes('other'))
                      );
                      setOtherPreventionPointIsChecked(val);
                      setOtherPreventionPoint('');
                    }
                  }}
                />
                <Text>Other</Text>
              </div>

              {otherPreventionPointIsChecked && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                >
                  <Input
                    style={{ width: '100%' }}
                    value={otherPreventionPoint}
                    placeholder="Other prevention point"
                    onChange={(value: any) => {
                      setOtherPreventionPoint(value);
                    }}
                    onBlur={(event: any) => {
                      const val = event.target?.value;
                      if (val !== '' && val != null) {
                        handleSelectPreventionPoints([
                          ...getValues('preventionPoints').filter(
                            (item) => !item.includes('other')
                          ),
                          `other|${val}`,
                        ]);
                      }
                    }}
                  />
                </div>
              )}
            </>
          )}
          {selectedPreventionPoints.length &&
          !selectedPreventionPoints.includes(NOT_PREVENTABLE_STRING) &&
          !(
            selectedPreventionPoints.length === 1 &&
            selectedPreventionPoints.find((item) => item.includes('other'))
          ) ? (
            <div
              style={{
                backgroundColor: '#E4ECFF',
                border: '1px solid #BACEFF',
                borderRadius: '10px',
                padding: '10px 10px 0px 10px',
              }}
            >
              <ul style={{ padding: '0px 20px', height: 'fit-content', listStyleType: 'none' }}>
                {[...selectedPreventionPoints]
                  .filter((item) => !item.includes('other'))
                  ?.map((val) => {
                    return (
                      <li style={{ position: 'relative', marginTop: '2px' }}>
                        <span style={{ position: 'absolute', left: '-15px', top: 0 }}>•</span>
                        <Text type="caption">
                          {noteOptions?.preventionPoints.find((item) => item.key === val)?.label}
                        </Text>
                      </li>
                    );
                  })}
              </ul>
            </div>
          ) : (
            ''
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
        >
          <Text type="subtitle">Notes</Text>
          <Controller
            control={control}
            name="notes"
            render={({ field }) => {
              return (
                <Input
                  id="notes"
                  name="notes"
                  type="textarea"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  style={{ width: '100%' }}
                />
              );
            }}
          />
        </div>

        {/* TODO: Use data from api call to populate this list */}
        <div>
          <Text type="subtitle">Required Steps</Text>
          <div>
            <Bullet />
            <Text>{POST_DISCHARGE_EVAL}</Text>
          </div>
          <div>
            <Bullet />
            <Text>{MED_RECONCILIATION}</Text>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: 10,
          }}
        >
          <div>
            <Text type="subtitle">Next Steps</Text>{' '}
            <Text type="caption" color={getValues('nextSteps').length > 0 ? 'success' : 'error'}>
              (Required)
            </Text>
          </div>

          <Controller
            control={control}
            name="nextSteps"
            render={({ field }) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  {!getValues('nextSteps').includes(NO_NEXT_STEPS_STRING) && (
                    <Multiselect
                      id="nextSteps"
                      // allowSelectAll
                      customInputDisplayText={
                        getValues('nextSteps').length > 0 &&
                        !getValues('nextSteps').includes(NO_NEXT_STEPS_STRING)
                          ? `${getValues('nextSteps').length} next steps`
                          : ''
                      }
                      items={((noteOptions as NoteOptions).nextSteps ?? [])
                        .map((option: KeyLabel) => option.key)
                        .filter((item) => item !== NO_NEXT_STEPS_STRING && item !== 'other')}
                      renderItem={(item) => {
                        if (item === 'Select All' || item === 'Unselect All') return item;
                        return noteOptions.nextSteps.find((p) => p.key === item)?.label;
                      }}
                      doNotSort
                      sx={{ minWidth: '100%' }}
                      selectedItems={field.value}
                      onChange={(value) => {
                        logEvent('PFV Admits: Create/Edit ARN: Clicked an option in "Next Steps"');
                        handleSelectNextSteps(value);
                      }}
                    />
                  )}
                </div>
              );
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
              onChange={() => {
                logEvent('PFV Admits: Create/Edit ARN: Selected "No Next Steps"');
                handleSelectNextSteps([NO_NEXT_STEPS_STRING]);
              }}
              checked={getValues('nextSteps').includes(NO_NEXT_STEPS_STRING)}
            />
            <Text>No next steps</Text>
          </div>
          {!getValues('nextSteps').includes(NO_NEXT_STEPS_STRING) && (
            <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Checkbox
                  checked={otherNextStepIsChecked}
                  onChange={(val) => {
                    if (val === true) {
                      logEvent(
                        'PFV Admits: Create/Edit ARN: Selected "Other" option in "Next Steps"'
                      );
                      setOtherNextStepIsChecked(val);
                    } else if (val === false) {
                      handleSelectNextSteps(
                        getValues('nextSteps').filter((item) => !item.includes('other'))
                      );
                      setOtherNextStepIsChecked(val);
                      setOtherNextStep('');
                    }
                  }}
                />
                <Text>Other</Text>
              </div>

              {otherNextStepIsChecked && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                >
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Other next step"
                    value={otherNextStep}
                    onChange={(value: any) => {
                      setOtherNextStep(value);
                    }}
                    onBlur={(event: any) => {
                      const val = event.target?.value;
                      if (val !== '' && val != null) {
                        handleSelectNextSteps([
                          ...getValues('nextSteps').filter((item) => !item.includes('other')),
                          `other|${val}`,
                        ]);
                      }
                    }}
                  />
                </div>
              )}
            </>
          )}
          {selectedNextSteps.length > 0 &&
          !selectedNextSteps.includes(NO_NEXT_STEPS_STRING) &&
          !(
            selectedNextSteps.length === 1 &&
            selectedNextSteps.find((item) => item.includes('other'))
          ) ? (
            <div
              style={{
                backgroundColor: '#E4ECFF',
                border: '1px solid #BACEFF',
                borderRadius: '10px',
                padding: '10px 10px 0px 10px',
              }}
            >
              <ul style={{ padding: '0px 20px', height: 'fit-content', listStyleType: 'none' }}>
                {[...selectedNextSteps]
                  .filter((item) => !item.includes('other'))
                  ?.map((val) => {
                    return (
                      <li style={{ position: 'relative', marginTop: '2px' }}>
                        <span style={{ position: 'absolute', left: '-15px', top: 0 }}>•</span>
                        <Text type="caption">
                          {noteOptions?.nextSteps.find((item) => item.key === val)?.label}
                        </Text>
                      </li>
                    );
                  })}
              </ul>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div style={{ display: 'flex', gap: 10, justifyContent: 'end', paddingRight: '15px' }}>
        <Button
          secondary
          onClick={() => {
            setEditMode(false);
            reset();
          }}
        >
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

import React, { useState, useEffect } from 'react';
import { Text } from '@intus-ui';

interface ResendEmailCountdownProps {
  initialCount: number;
  onCountdownComplete: () => void;
}

const ResendEmailCountdown: React.FC<ResendEmailCountdownProps> = ({
  initialCount,
  onCountdownComplete,
}) => {
  const [count, setCount] = useState<number>(initialCount);

  useEffect(() => {
    if (count === 0) {
      onCountdownComplete();
      return;
    }

    const timer = setTimeout(() => setCount(count - 1), 1000);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [count, onCountdownComplete]);
  if (count > 0) return <Text type="subtitle" color="link">{`Resend Email (...${count}s)`}</Text>;
  return null;
};

export default ResendEmailCountdown;

import { post } from '@api/apiMethods';
import { IPolypharmacyFilters } from '@api/polypharmacy/types/IPolypharmacyFilters';

export type IPatientWithMedInClassResponse = {
  patients: IPatientWithMedInClass[];
  totalPatientCount: number;
};

export type IPatientWithMedInClass = {
  id: number;
  name: string;
  memberId: string;
  age: number | null;
  gender: string;
  medicationCount: number;
  frequentFaller: 'Yes' | 'No';
  advancedIllness: 'Yes' | 'No';
};

export function getPatientsWithMedicationInClass(
  gpi: string,
  gpiType: string,
  filters: IPolypharmacyFilters
) {
  return post<IPatientWithMedInClassResponse>(`polypharmacy/patients-with-med-in-class`, {
    gpi,
    gpiType,
    filters,
  });
}

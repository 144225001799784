import React from 'react';
import FallsAnalytics from 'Homes/HomePage/ModalContent/FallsAnalytics';
import HospitalizationAnalytics from 'Homes/HomePage/ModalContent/HospitalizationAnalytics';
import FinPreview from 'Homes/HomePage/PreviewContent/FinPreview';
import HospPreview from 'Homes/HomePage/PreviewContent/HospitalizationAnalyticsPreview';
// eslint-disable-next-line import/no-cycle
import FallsPreview from 'Homes/HomePage/PreviewContent/FallsPreview';
// eslint-disable-next-line import/no-cycle
import RiskAcuityPreview from 'Homes/HomePage/PreviewContent/RiskAcuityPreview';
import CensusPreview from 'Homes/HomePage/PreviewContent/CensusPreview';
import FinAnalytics from 'Homes/HomePage/ModalContent/FinAnalytics';
import CensusAnalytics from 'Homes/HomePage/ModalContent/CensusAnalytics';
import RiskAcuityAnalytics from 'Homes/HomePage/ModalContent/RiskAcuityAnalytics';
// enums for frontend internal use, enumerating the names given from backend
export const FALLS = 'falls';
export const ACUITY = 'riskAcuity';
export const HOSPS = 'hospitalizations';
export const FINS = 'financial';
export const CENS = 'census';
export const OTHER = 'Coming Soon';

export const getPreview = (boxName, data) => {
  if (boxName === FALLS) {
    return <FallsPreview content={data} />;
  }

  if (boxName === ACUITY) {
    return <RiskAcuityPreview content={data} />;
  }

  if (boxName === HOSPS) {
    return <HospPreview content={data} />;
  }

  if (boxName === FINS) {
    return <FinPreview content={data} />;
  }

  if (boxName === CENS) {
    return <CensusPreview content={data} />;
  }

  return <div content={data} />;
};

export const cardMap = {
  FALLS: {
    boxName: FALLS,
    title: 'Falls',
    content: <FallsAnalytics />,
    dashName: 'Falls',
  },

  ACUITY: {
    boxName: ACUITY,
    title: 'Risk Acuity',
    content: <RiskAcuityAnalytics />,
    dashName: 'Risk Acuity',
  },

  HOSPS: {
    boxName: HOSPS,
    title: 'Hospitalizations',
    content: <HospitalizationAnalytics />,
    dashName: 'Hospitalizations',
  },

  FINS: {
    boxName: FINS,
    title: 'Financial',
    content: <FinAnalytics />,
    dashName: 'Financial',
  },

  CENS: {
    boxName: CENS,
    title: 'Census: Enrolls/Disenrolls',
    content: <CensusAnalytics />,
    dashName: 'Census',
  },

  OTHER: {
    boxName: OTHER,
    title: 'Coming Soon',
    content: 'coming soon',
  },
};

export const getCard = (boxName) => {
  if (boxName === FALLS) {
    return cardMap.FALLS;
  }

  if (boxName === ACUITY) {
    return cardMap.ACUITY;
  }

  if (boxName === HOSPS) {
    return cardMap.HOSPS;
  }

  if (boxName === FINS) {
    return cardMap.FINS;
  }

  if (boxName === CENS) {
    return cardMap.CENS;
  }

  return cardMap.OTHER;
};

export default cardMap;

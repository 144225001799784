import { Input, Text } from '@intus-ui';
import useFilter from './useFilter';

const OperatorSelectInput = ({ index, operator, handleChangeGroupOperator }) => {
  const { operators } = useFilter();
  if (index > 1) {
    return (
      <Text className="mb-2" color="caption" type="subtitle" style={{ marginLeft: 5 }}>
        {operator}
      </Text>
    );
  }

  return (
    <Input
      id="operator"
      name="operator"
      type="select"
      rightIcon="caret-down"
      options={operators}
      value={operator}
      onChange={handleChangeGroupOperator}
      style={{ width: 100 }}
    />
  );
};

export default OperatorSelectInput;

import { sortBy } from 'lodash';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getPatientCurrentMedications } from '@api';
import { getLogger } from '@util/logger';

import { Icon, Text, SpinnerOrError, Modal } from '@intus-ui';

import { useFeatureFlag } from '@util';
import { useDispatch } from 'react-redux';
import { setPage } from '@global-state/redux/profileSlice';
import CurrentMedicationsDetails from './CurrentMedicationsDetails';

const CurrentMedications = () => {
  const [ready, setReady] = useState(false);
  const [medications, setMedications] = useState([]);
  const [fetchError, setFetchError] = useState();
  const [medicationModalIsOpen, setMedicationModalIsOpen] = useState(false);
  const { featureIsActive } = useFeatureFlag('polypharmacy'); // active if a user is a part of SCAN

  const { patientId } = useParams();
  const log = getLogger('FallsAnalysis');
  const dispatch = useDispatch();

  // FETCH FORMATTED CURRENT MEDICATIONS DATA FROM API
  useEffect(() => {
    const fetchData = async () => {
      const response = await getPatientCurrentMedications(patientId);
      const data = await response.json();

      setReady(true);

      if (data) {
        setMedications(sortBy(data, (item) => item.name.toLowerCase()));
      }
    };

    fetchData().catch((e) => {
      setFetchError('System error - unable to fetch current medication data.');
      log.error(e);
    });
  }, []);

  return (
    <div style={styles.container}>
      <Modal
        header={{
          title: 'Active Medications',
          centered: true,
        }}
        type="large"
        open={medicationModalIsOpen}
        onClose={() => setMedicationModalIsOpen(false)}
        modalStyles={{ height: '90%' }}
      >
        <CurrentMedicationsDetails patientId={patientId} />
      </Modal>
      {ready && !fetchError ? (
        <>
          <div style={styles.medList}>
            {medications.length > 0 &&
              medications.map((item) => {
                return (
                  <div style={styles.item} key={item.id}>
                    <Icon name="bullet" />
                    <Text>{item.name}</Text>
                  </div>
                );
              })}
          </div>
          {medications.length === 0 && (
            <Text>This participant does not currently have any active medications.</Text>
          )}
          {medications.length !== 0 && (
            <div
              style={styles.link}
              // onClick={() =>
              //   featureIsActive
              //     ? history.push(`/patient/${patientId}/medications`)
              //     : setMedicationModalIsOpen(true)
              // }
              role="none"
            >
              <Text
                type="subtitle"
                color="link"
                onClick={() => {
                  if (featureIsActive) {
                    dispatch(setPage('Medications'));
                  }
                  setMedicationModalIsOpen(true);
                }}
              >
                See Details
              </Text>
              <Icon
                size="small"
                name="arrow-right"
                color="link"
                style={{ fontWeight: 'bold' }}
                onClick={() => {
                  if (featureIsActive) {
                    dispatch(setPage('Medications'));
                  }
                  setMedicationModalIsOpen(true);
                }}
              />
            </div>
          )}
        </>
      ) : (
        <SpinnerOrError error={fetchError} />
      )}
    </div>
  );
};
const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    overflowY: 'auto',
  },
  medList: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    width: '100%',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'end',
  },
};

export default CurrentMedications;

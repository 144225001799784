import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import userSettingsPropType from 'Settings/userSettingsPropType';
import Centered from './Centered';

const CenteredSpinnerWelcome = ({ className, userSettings }) => {
  const { profile } = userSettings;
  if (!profile) {
    return null;
  }
  return (
    <Centered className={className}>
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex pb-5 mb-5">
          <h2 className="font-weight-bold text-secondary">
            Personalizing for {profile.firstName}
            ...
          </h2>
        </div>
        <div className="pb-5 mb-5">
          <Spinner animation="grow" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
        <div className="d-flex">
          <h4 className="font-weight-bold text-secondary">Arranging relevant data points.</h4>
        </div>
      </div>
    </Centered>
  );
};

CenteredSpinnerWelcome.propTypes = {
  className: PropTypes.string,
  userSettings: userSettingsPropType.isRequired,
};

CenteredSpinnerWelcome.defaultProps = {
  className: '',
};

const mapState = (state) => ({
  userSettings: state.userSettings,
});

export default connect(mapState)(CenteredSpinnerWelcome);

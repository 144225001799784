import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import QuadsHomepageSettingsBox from 'Settings/QuadsHomepageSettingsBox';
import { resetEdit } from '@global-state/redux/homepageSlice';
import '@intus-ui/styles/Utilities.scss';
import '@intus-ui/styles/Home.scss';

const EditHomeModal = ({ editHome, doResetHomeEdit }) => {
  if (!editHome) return null;

  const hide = () => {
    doResetHomeEdit();
  };

  return (
    <Modal
      dialogClassName="home-modal min-w-90"
      show={!!editHome}
      onHide={hide}
      scrollable
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="py-0 pl-4 pt-3 m-0" closeButton>
        <Modal.Title className="text-align-left text-secondary bg-light w-100 border-bottom border-secondary mb-0 pb-0">
          Edit Homepage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <QuadsHomepageSettingsBox editButton={false} hide={hide} />
      </Modal.Body>
    </Modal>
  );
};

EditHomeModal.propTypes = {
  doResetHomeEdit: PropTypes.func.isRequired,
  editHome: PropTypes.bool,
};

EditHomeModal.defaultProps = {
  editHome: false,
};

const mapState = (state) => ({
  error: state.homepage.error,
  editHome: state.homepage.editHome,
});

const mapDispatch = (dispatch) => ({
  doResetHomeEdit: () => dispatch(resetEdit()),
});

export default withRouter(connect(mapState, mapDispatch)(EditHomeModal));

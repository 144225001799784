import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { formatDate } from '@util/dateFunctions';

const DatePicker = ({ timeFilter, doShowDatePicker, disabled }) => (
  <button
    title={`Time Range: ${formatDate(timeFilter.startDate, 'MMM D, YYYY')} - ${formatDate(
      timeFilter.endDate,
      'MMM D, YYYY'
    )}`}
    type="button"
    className="w-100 border smaller-text outline-none bg-white filter-buttons px-3 py-2 rounded d-flex justify-content-start align-items-center h-100"
    onClick={() => doShowDatePicker()}
    disabled={disabled}
  >
    <DateRangeRoundedIcon fontSize="small" className="mr-2 text-muted" />
    <div>{formatDate(timeFilter.startDate, 'MMM D, YYYY')}</div>
    <ArrowRightAltIcon fontSize="small" className="mx-2 text-dark font-weight-light" />
    <div>{formatDate(timeFilter.endDate, 'MMM D, YYYY')}</div>
  </button>
);

DatePicker.propTypes = {
  timeFilter: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  doShowDatePicker: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  disabled: false,
};

const mapState = (state) => ({
  currentDashboard: state.dashboard.currentDash.id,
  userSettings: state.userSettings,
  timeFilter: state.filters.timeFilter,
});

export default connect(mapState)(DatePicker);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Modal, ToggleButton, ToggleButtonGroup, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import SubmitButton from '@intus-ui/components/SubmitButton';
import { sendNpsSurvey, setSurveyModal, setReporting } from '../@global-state/redux/helpSlice';

const scale = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const SurveyModal = ({
  show,
  closeAfterCompleting,
  closeWithoutCompleting,
  doSendNpsSurvey,
  startReporting,
}) => {
  const [radioValue, setRadioValue] = useState(null);

  return (
    <Modal show={show} onHide={closeWithoutCompleting} dialogClassName="min-w-70">
      <Modal.Header closeButton>
        <Modal.Title className="mt-5">
          <h4>Send us your feedback!</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center h-100">
        <Formik
          initialValues={{}}
          onSubmit={() => {
            doSendNpsSurvey(radioValue);
            closeAfterCompleting();
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="w-100 d-flex flex-column align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center w-75 pt-4 pb-5">
                <h5 className="pb-4 text-secondary">
                  How likely would you be to recommend Intus Care to another organization or
                  colleague?
                </h5>
                <Form.Row className="px-3">
                  <ToggleButtonGroup
                    name="npsGroup"
                    type="radio"
                    className="ml-1 w-100 overflow-wrap"
                  >
                    {scale.map((number) => (
                      <ToggleButton
                        variant="outline-primary"
                        className="mr-1"
                        label={number}
                        value={number}
                        key={number}
                        id={`inline-radio-${number}`}
                        onChange={(e) => {
                          setRadioValue(e.currentTarget.value);
                        }}
                      >
                        <b>{number}</b>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  <div
                    className="d-flex justify-content-between text-dark p-0 ml-2 mr-2 mb-4"
                    style={{ width: '100%' }}
                  >
                    <p style={{ fontSize: 'calc(3px+1em)' }}>Not likely</p>
                    <p style={{ fontSize: 'calc(3px+1em)' }}>Extremely likely</p>
                  </div>
                </Form.Row>
              </div>
              <Form.Row className="pb-5 d-flex flex-column align-items-center w-100">
                <SubmitButton
                  text="Send Feedback"
                  isSubmitting={isSubmitting}
                  formNotComplete={!radioValue}
                  className="mb-3 w-70"
                />
                <span className="pb-3">
                  <Button
                    variant="link"
                    className="px-1 py-0 m-0"
                    disabled={!radioValue}
                    onClick={() => {
                      startReporting();
                      handleSubmit();
                    }}
                  >
                    Click here
                  </Button>
                  <p className="text-muted">to submit and add more feedback.</p>
                </span>
              </Form.Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

SurveyModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeAfterCompleting: PropTypes.func.isRequired,
  closeWithoutCompleting: PropTypes.func.isRequired,
  doSendNpsSurvey: PropTypes.func.isRequired,
  startReporting: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  show: state.help.surveyModal,
});

const mapDispatch = (dispatch) => ({
  closeWithoutCompleting: () => {
    dispatch(setSurveyModal(false));
    dispatch(sendNpsSurvey(0));
  },
  closeAfterCompleting: () => {
    dispatch(setSurveyModal(false));
  },
  startReporting: () => dispatch(setReporting(true)),
  doSendNpsSurvey: (score) => dispatch(sendNpsSurvey(score)),
});

export default connect(mapState, mapDispatch)(SurveyModal);

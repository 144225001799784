import Checkbox from '@intus-ui/components/Checkbox';
import Icon from '@intus-ui/components/Icon';
import Text from '@intus-ui/components/Text';
import { input, textColors } from '@intus-ui/styles/SecondaryColors';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { orderBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

export type MultiselectProps<T extends string> = Omit<
  SelectProps<T[]>,
  'label' | 'items' | 'onChange' | 'value' | 'multiple' | 'renderValue'
> & {
  label?: React.ReactNode;
  items: T[];
  selectedItems: T[];
  /** True to disable the sorting of items on click */
  doNotSort?: boolean;
  allowSelectAll?: boolean;
  onChange: (selectedItems: T[]) => void;
  /** If set, this should return the text to display for each item in the dropdown menu OR in the input field */
  getItemText?: (item: T) => string;
  /** If set, this renders the item in the dropdown menu. DOES NOT affect the display in the input field. */
  renderItem?: (item: T | 'Select All' | 'Unselect All') => React.ReactNode;
  /** If set, renders the display text in the input */
  customInputDisplayText?: string;
};

/**
 * A component that renders a dropdown with checkboxes to select multiple items.
 *
 * See storybook for usage examples.
 */
export function Multiselect<T extends string>(props: MultiselectProps<T>) {
  const {
    label,
    customInputDisplayText,
    items,
    selectedItems,
    allowSelectAll,
    onChange,
    MenuProps,
    renderItem,
    doNotSort,
    getItemText,
    ...otherProps
  } = props;

  const areAllSelected = selectedItems.length === items.length;

  const [isOpen, setIsOpen] = useState(false);

  function getSortedItems() {
    if (doNotSort === true) {
      return items;
    }
    const selectedItemSet = new Set(selectedItems);
    // Sort selected items on top, and then sort alphabetically.
    const newSortedItems = orderBy(items, [
      (item) => !selectedItemSet.has(item),
      (item) => item.toLowerCase(),
    ]);
    return newSortedItems;
  }

  const [sortedItems, setSortedItems] = useState(() => getSortedItems());

  // If the items change we need to re-sort them.
  useEffect(() => {
    setSortedItems(getSortedItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleChange: SelectProps<T[]>['onChange'] = (event, child) => {
    const {
      target: { value },
    } = event;

    const reactChild = child as React.ReactElement;

    // This is super hacky but I cannot find another way to determine what specific item was clicked.
    const clickedAll =
      (reactChild.key?.includes('Select All') || reactChild.key?.includes('Unselect All')) ?? false;

    if (clickedAll && areAllSelected) {
      onChange([]);
    } else if (clickedAll && !areAllSelected) {
      onChange(items);
    } else {
      onChange(value as T[]);
    }
  };

  let inputDisplayText =
    customInputDisplayText ||
    selectedItems.map((i) => (getItemText != null ? getItemText(i) : i)).join(', ');

  if (areAllSelected && allowSelectAll) {
    inputDisplayText = 'All';
  }

  // Sort the items when the dropdown is closed.
  // We want the checked items to show up on top but we don't want to sort them as the user clicks them.
  function onCloseDropdown() {
    setSortedItems(getSortedItems());

    setIsOpen(false);
  }

  const itemsWithAll = useMemo(() => {
    const selectAllText = areAllSelected ? 'Unselect All' : 'Select All';
    return allowSelectAll ? [selectAllText, ...sortedItems] : sortedItems;
  }, [allowSelectAll, sortedItems, areAllSelected]);

  return (
    <Select<T[]>
      aria-label={`${label} ${inputDisplayText}`}
      IconComponent={(iconProps) => (
        <Icon
          name={isOpen ? 'caret-up' : 'caret-down'}
          color={isOpen ? input.active : textColors.caption}
          {...iconProps}
          style={{ ...iconProps.style, top: 'unset', transform: `scale(1.2)` }}
        />
      )}
      multiple
      value={selectedItems}
      onOpen={() => setIsOpen(true)}
      onClose={() => onCloseDropdown()}
      onChange={handleChange}
      // displayEmpty is needed to show the bold label text inside when nothing is selected.
      displayEmpty
      renderValue={() => (
        // We need an inline style here as the text overflow with ... is not working with the Text component.
        <span style={{ fontFamily: 'Inter', fontSize: 15 }}>
          {label && <Text type="subtitle">{label}:&nbsp;</Text>}
          {inputDisplayText}
        </span>
      )}
      {...otherProps}
      sx={{
        ...styles.input,
        ...otherProps.sx,
      }}
      MenuProps={{
        // Turn off the fading of the dropdown menu when it is opened or closed.
        // This causes weird issues where the dropdown moves around when it's closed then fades away.
        transitionDuration: 0,
        // Do not focus the last item by default when the dropdown opens.
        variant: 'menu',
        // Try and get the dropdown menu to show below the input field if it fits.
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        ...MenuProps,
        sx: { ...styles.menu, ...MenuProps?.sx },
      }}
    >
      {itemsWithAll.map((name) => {
        let itemText = name;
        if (name === 'Select All' || name === 'Unselect All') {
          itemText = name;
        } else {
          itemText = getItemText ? getItemText(name as T) : name;
        }

        return (
          <MenuItem
            key={name}
            value={name}
            disableRipple
            // Focus the item so we don't have 2 items colored at once
            onMouseEnter={(event) => (event.target as HTMLElement).focus()}
            onClick={(event) => {
              // Reset the focus to the <li> element under the mouse once React re-renders.
              // This prevents 2 items from being colored at once when clicking.
              setTimeout(() => {
                let elementUnderMouse = document.elementFromPoint(
                  event.clientX,
                  event.clientY
                ) as HTMLElement | null;

                while (elementUnderMouse != null && elementUnderMouse.nodeName !== 'LI') {
                  elementUnderMouse = elementUnderMouse.parentElement;
                }

                if (elementUnderMouse != null) {
                  elementUnderMouse.focus();
                }
              }, 1);
            }}
          >
            <Checkbox
              checked={
                name === 'Select All' || name === 'Unselect All'
                  ? areAllSelected
                  : selectedItems.includes(name as T)
              }
              onChange={() => {
                // Do nothing, material-ui will handle the change event.
              }}
            />
            {renderItem && renderItem(name as T)}
            {!renderItem && <Text type="body">{itemText}</Text>}
          </MenuItem>
        );
      })}
    </Select>
  );
}

const minWidth = 135;
const maxWidth = 250;

const styles = {
  input: {
    minWidth,
    maxWidth,
    backgroundColor: input.default,
  },
  menu: {
    '& .MuiMenu-paper': {
      backgroundColor: input.default,
      maxWidth,
      maxHeight: 260,
      '& .MuiList-root': {
        minWidth: 145,
      },
    },
  },
};

/* eslint-disable react/no-array-index-key */
import { EHRBenchmarkChartData } from '@api/benchmark/getEHRBenchmark';
import { Text } from '@intus-ui';
import { useReactChartTooltipV2 } from '@intus-ui/components/graphs/useReactChartTooltipV2';
import { formatDate, isSameDate, newDate } from '@util/dateFunctions';
import { ChartDataset } from 'chart.js';
import React, { FC, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

type BenchmarkBarChartProps = {
  yLabel: string;
  barData: EHRBenchmarkChartData[] | EHRBenchmarkChartData;
  lineData: EHRBenchmarkChartData[] | EHRBenchmarkChartData;
  chartTitle: string;
};

export const BenchmarkBarChart: FC<BenchmarkBarChartProps> = ({
  chartTitle,
  yLabel,
  barData,
  lineData,
}) => {
  const myBarData = Array.isArray(barData) ? barData : [barData];

  const myOrgData = useMemo(() => {
    const myBarData = Array.isArray(barData) ? barData : [barData];

    const datasets = myBarData.map((metric) => {
      const { label, data, color } = metric;

      const dataset: ChartDataset<'bar'> = {
        // Display the other org lines on top of the bars.
        order: 2,
        label,
        data: data.map((d) => d.value),
        // fill: false,
        backgroundColor: color,
        borderColor: color,
      };

      return dataset;
    });

    return datasets;
  }, [barData]);

  const otherOrgData = useMemo(() => {
    const myLineData = Array.isArray(lineData) ? lineData : [lineData];

    const datasets = myLineData.map((metric) => {
      const { label, data, color } = metric;

      const dataset: ChartDataset<'line'> = {
        order: 1,
        label,
        data: data.map((d) => d.value),
        fill: false,
        backgroundColor: color,
        borderColor: color,
        tension: 0,
        type: 'line',
      };

      return dataset;
    });

    return datasets;
  }, [lineData]);

  const { tooltipOptions, tooltipComponent } = useReactChartTooltipV2((dataIndex) => {
    return (
      <BenchmarkTooltip
        dataIndex={dataIndex}
        barData={barData}
        lineData={lineData}
        chartTitle={chartTitle}
      />
    );
  });

  return (
    <div style={{ width: '100%', height: '100%', paddingTop: 6, paddingBottom: 6 }}>
      {tooltipComponent}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <div style={{ flex: '1 1 100%', maxHeight: '90%', overflow: 'hidden' }}>
          <Bar
            data={{
              datasets: [...myOrgData, ...(otherOrgData as any)],
              labels: myBarData[0].data.map((d) => newDate(d.month).toDate()),
            }}
            options={{
              animation: false,
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: tooltipOptions,
              },
              scales: {
                x: {
                  offset: true,
                  type: 'time',
                  ticks: {
                    // Only show ticks for points in the data.
                    // Without this chart.js renders multiple ticks for each month if the chart range is a few months.
                    source: 'labels',
                    // If the chart is over a long time range, then start skipping months in the x-axis ticks.
                    autoSkip: true,
                    maxTicksLimit: 48,
                    maxRotation: 60,
                    minRotation: 0,
                    font: {
                      size: 12,
                    },
                    padding: 8,
                    callback: (_value, index, ticks) => {
                      const date = ticks[index].value;
                      // If we're in the current month, display MTD on the chart.
                      if (isSameDate(newDate(), date, 'month')) {
                        return 'MTD';
                      }
                      return formatDate(date, "MMM 'YY");
                    },
                  },
                  grid: {
                    display: true,
                    // Do not display a line going down passed the bottom of the axis to the date label.
                    drawTicks: false,
                  },
                  border: {
                    // Draws the line right above the x-axis
                    display: true,
                  },
                  title: {
                    display: false,
                  },
                },
                y: {
                  // The suggestedMax here is only used if all data points are below 5
                  // We do this to prevent the graph from looking bad for small numbers.
                  suggestedMax: 5,
                  suggestedMin: 0,
                  ticks: {
                    maxTicksLimit: 8,
                    font: {
                      size: 12,
                    },
                    // precision: 0,
                  },
                  grid: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: yLabel,
                    font: {
                      size: 12,
                      weight: 'bold',
                    },
                  },
                },
              },
            }}
          />
        </div>

        <div style={{ flex: '0 0 auto' }}>
          <BenchmarkChartLegend barData={barData} lineData={lineData} />
        </div>
      </div>
    </div>
  );
};

type BenchmarkTooltipProps = {
  chartTitle: string;
  barData: EHRBenchmarkChartData[] | EHRBenchmarkChartData;
  lineData: EHRBenchmarkChartData[] | EHRBenchmarkChartData;
  dataIndex: number;
};

const BenchmarkTooltip: FC<BenchmarkTooltipProps> = ({
  chartTitle,
  barData,
  lineData,
  dataIndex,
}) => {
  const myBarData = Array.isArray(barData) ? barData : [barData];
  const myLineData = Array.isArray(lineData) ? lineData : [lineData];

  const { month } = myBarData[0].data[dataIndex];

  const dateText = !isSameDate(newDate(), month, 'month')
    ? formatDate(month, "MMM 'YY")
    : `Data so far in ${formatDate(month, "MMM 'YY")} (MTD)`;

  return (
    <div>
      <div>
        <Text type="caption-bold">{dateText}</Text>
      </div>
      <div>
        <Text type="caption">{chartTitle}</Text>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 6, paddingTop: 5 }}>
        {myBarData.map((data, index) => {
          return (
            <BenchmarkTooltipLegend key={`barData-${index}`} data={data} dataIndex={dataIndex} />
          );
        })}

        {myLineData.map((data, index) => {
          return (
            <BenchmarkTooltipLegend
              key={`lineData-${index}`}
              showDot
              data={data}
              dataIndex={dataIndex}
            />
          );
        })}
      </div>
    </div>
  );
};

type BenchmarkTooltipLegendProps = {
  data: EHRBenchmarkChartData;
  dataIndex: number;
  showDot?: boolean;
};

const BenchmarkTooltipLegend: FC<BenchmarkTooltipLegendProps> = ({ data, dataIndex, showDot }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: data.color,
          width: 12,
          height: 12,
          borderRadius: showDot ? 1000 : 0,
        }}
      />
      <Text type="caption-bold" style={{ paddingLeft: 4 }}>
        {data.label}
      </Text>
      <Text type="caption" style={{ paddingLeft: 4 }}>
        {data.data[dataIndex].value}
      </Text>
    </div>
  );
};

type BenchmarkChartLegendProps = {
  barData: EHRBenchmarkChartData[] | EHRBenchmarkChartData;
  lineData: EHRBenchmarkChartData[] | EHRBenchmarkChartData;
};

const BenchmarkChartLegend: FC<BenchmarkChartLegendProps> = ({ barData, lineData }) => {
  const myBarData = Array.isArray(barData) ? barData : [barData];
  const myLineData = Array.isArray(lineData) ? lineData : [lineData];

  return (
    <div style={legendStyles.container}>
      {myBarData.map((data, index) => {
        return (
          <div style={legendStyles.item}>
            <BenchmarkLegendBox key={`barData-${index}`} data={data} />{' '}
          </div>
        );
      })}

      {myLineData.map((data, index) => {
        return (
          <div style={legendStyles.item}>
            <BenchmarkLegendBox key={`lineData-${index}`} showDot data={data} />{' '}
          </div>
        );
      })}
    </div>
  );
};

const legendStyles = {
  container: {
    paddingLeft: 55,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 24,
    rowGap: 4,
  },
  item: {
    flex: '1 1 40%',
  },
} satisfies InlineCss;

type BenchmarkLegendBoxProps = {
  data: EHRBenchmarkChartData;
  showDot?: boolean;
};

const BenchmarkLegendBox: FC<BenchmarkLegendBoxProps> = ({ data, showDot }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: data.color,
          borderRadius: showDot ? 1000 : 0,
          width: 12,
          height: 12,
          marginRight: 6,
        }}
      />
      <Text type="caption-bold" style={{ paddingRight: 4 }}>
        {data.label}
      </Text>
    </div>
  );
};

/* eslint-disable no-param-reassign */
import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

import { postPatientListAPI, getRecentStats } from '@api/api';
import { setDashError } from '@global-state/redux/dashboardSlice';

import { RiskBadge } from '@intus-ui';
import { relativePast } from '@util/dateFunctions';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    page: undefined,
    patientList: undefined,
    currPatient: undefined,
    stats: undefined,
    statTime: 'Past 3 months',
  },
  reducers: {
    // Navigates to the specified page.
    setPage(state, action) {
      state.page = action.payload;
    },
    // sets the current patient list. Used for previous and next buttons
    setPatientList(state, action) {
      state.patientList = action.payload;
    },
    // sets the current patient's id (the profile we are looking at)
    setCurrPatient(state, action) {
      state.currPatient = action.payload;
    },

    setStats(state, action) {
      state.stats = action.payload;
    },

    setStatTime(state, action) {
      state.statTime = action.payload;
    },

    clearStats(state) {
      state.stats = undefined;
      state.statTime = 'Past 3 months';
    },
  },
});

export default profileSlice.reducer;
export const { setPage, setPatientList, setCurrPatient, setStats, setStatTime, clearStats } =
  profileSlice.actions;

export const getPatientList = (idList) => async (dispatch) => {
  dispatch(setPatientList(undefined));
  try {
    const res = await postPatientListAPI(idList);
    if (res.ok) {
      const json = await res.json();
      const items = json.map((patient) => ({
        id: patient.id,
        name: `${patient.firstName} ${patient.lastName}`,
        memberId: patient.memberId,
        acuityScore: patient.acuityLevels[0].acuityScore || 0,
        /* eslint-disable  */
        riskBadge: <RiskBadge riskGroup={patient.acuityLevels[0].riskGroup} />,
        team: `${patient.team || ''}`,
        facility: `${patient.facility || ''}`,
      }));
      dispatch(setPatientList(items));
    } else {
      dispatch(setDashError('Could not fetch data.'));
    }
  } catch (err) {
    dispatch(setDashError('Could not fetch data.'));
  }
};

export const getPptRecentStats = (patientId, startDate) => async (dispatch) => {
  dispatch(clearStats());
  dispatch(setStatTime(startDate));
  const pastDate = relativePast(startDate, 'MM/DD/YYYY');
  try {
    const res = await getRecentStats(patientId, pastDate);
    if (res.ok) {
      const json = await res.json();
      dispatch(setStats(json));
    } else {
      dispatch(setDashError('Could not fetch data.'));
    }
  } catch (err) {
    dispatch(setDashError('Could not fetch data.'));
  }
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';

import Pin from 'assets/Intus/Pin.svg';
import Pin2 from 'assets/Intus/Pin1.svg';

import '@intus-ui/styles/ListItem.scss';
import '@intus-ui/styles/Utilities.scss';

const ListItem = ({ item, format, onClick, className, pinned, selected }) => {
  const [favorite, setFavorite] = useState(false);
  if (pinned) {
    return (
      <Row
        className={`d-flex flex-row align-items-center justify-content-end bg-white rounded-lg py-1 list-item box-shadow-cards ${className}`}
      >
        <Col md={1}>
          {!favorite && (
            <Button
              className="btn-link bg-white border-0"
              onClick={() => {
                setFavorite(true);
                selected.push(item.id);
              }}
            >
              <img src={Pin} className="patient-illus" alt="card selection example" />
            </Button>
          )}
          {favorite && (
            <Button
              className="btn-link bg-white border-0"
              onClick={() => {
                setFavorite(false);
                selected.filter((select) => select !== item.id);
              }}
            >
              <img src={Pin2} className="patient-illus" alt="card selection example" />
            </Button>
          )}
        </Col>
        <Col
          md={11}
          className="w-100 d-flex flex-row align-items-center justify-content-center bg-white rounded-lg "
        >
          <Row className="w-100 d-flex align-items-center">
            {format.map((formatItem) => (
              <Col
                key={`listItemColumn-${item.id}-${formatItem.nodeField}`}
                className={formatItem.className}
                lg={formatItem.lg}
                md={formatItem.md}
                sm={formatItem.sm}
                xs={formatItem.xs}
              >
                <h4 className="font-weight-normal">{item[formatItem.nodeField]}</h4>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    );
  }
  return (
    <Row
      className={`d-flex flex-row align-items-center justify-content-end bg-white rounded-lg py-1 ${
        onClick ? 'cursor-pointer' : ''
      } list-item box-shadow-cards ${className}`}
      onClick={
        onClick
          ? () => {
              onClick(item);
            }
          : undefined
      }
    >
      <Col
        lg={12}
        className={`w-100 d-flex flex-row align-items-center justify-content-center bg-white rounded-lg ${
          onClick ? 'pr-4 bg-primary' : ''
        }`}
      >
        <Row className="w-100 h-100 d-flex flex-row align-items-center justify-content-start">
          {format.map((formatItem) => (
            <Col
              key={`listItemColumn-${item.id}-${formatItem.nodeField}`}
              className={formatItem.className}
              style={formatItem.style}
              lg={formatItem.lg}
              md={formatItem.md}
              sm={formatItem.sm}
              xs={formatItem.xs}
            >
              <h4 className="font-weight-normal">{item[formatItem.nodeField]}</h4>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

ListItem.propTypes = {
  // This is object because it could be pretty much anything.
  item: PropTypes.instanceOf(Object).isRequired,
  format: PropTypes.arrayOf(
    PropTypes.shape({
      valueField: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])
        .isRequired,
      nodeField: PropTypes.node.isRequired,
      lg: PropTypes.number,
      md: PropTypes.number,
      sm: PropTypes.number,
      xs: PropTypes.number,
    }).isRequired
  ).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  pinned: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.object),
};

ListItem.defaultProps = {
  onClick: undefined,
  className: '',
  pinned: false,
  selected: undefined,
};

export default ListItem;

/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { range } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { chartColors } from './styles';
import { wrapTextForXAxis, formatTooltip } from './helpers';

const LocationChart = ({ locationData = [], id, maxValue = 'auto' }) => {
  const sortedKeys = [];
  for (let i = 0; i < locationData[0].length; i += 1) {
    const data = locationData[0][i];
    const sorted = Object.entries(data).sort((a, b) => b[1] - a[1]);
    sorted.forEach((item) => {
      if (!sortedKeys.includes(item[0]) && item[0] !== 'id') {
        sortedKeys.push(item[0]);
      }
    });
  }

  if (!locationData[0].length) return null;

  return (
    <ResponsiveBar
      animate={false}
      margin={{ top: 20, bottom: 30, left: 45 }}
      colors={[chartColors[0]]}
      tooltip={(point) => {
        let labelString = point.indexValue;
        if (point.indexValue !== point.id && point.id !== 'undefined') {
          labelString += `- ${point.id}`;
        }
        labelString += `: ${point.value}`;
        return formatTooltip(labelString, point, id);
      }}
      axisLeft={{
        tickValues: 10,
        tickSize: 0,
        legend: 'Number of Falls',
        legendPosition: 'middle',
        legendOffset: -30,
        enable: true,
        format: (e) => Math.floor(e) === e && e,
      }}
      gridYValues={range(1, maxValue + 1)}
      axisBottom={{
        tickSize: 0,
        // Formatting x axis labels. Longer text will wrap to the next line.
        format: (v) => {
          let lineLimit = 15;
          if (locationData[0].length <= 3) {
            lineLimit = 25;
          }
          if (locationData[0].length === 1) {
            lineLimit = 50;
          }
          if (locationData[0].length >= 4) {
            lineLimit = 10;
          }
          const textBreakup = wrapTextForXAxis(v, lineLimit);
          return (
            <>
              <tspan x={0} y={0}>
                {textBreakup[0]}
              </tspan>
              <tspan x={0} y={10}>
                {textBreakup[1]}
              </tspan>
            </>
          );
        },
      }}
      padding={0.5}
      innerPadding={1}
      data={locationData[0]}
      keys={sortedKeys}
      maxValue={maxValue}
      labelFormat={(label) => {
        if (label === '0') return '';
        return label;
      }}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: 'rgba(0, 0, 0, .1)',
              strokeWidth: 1,
            },
          },
        },
      }}
    />
  );
};

export default LocationChart;

import { useHistory } from 'react-router';

import { sumUp } from '@util';
import { getStringReplacement } from '@util/stringReplacements';

import Icon from '@intus-ui/components/Icon';
import ParticipantList from '@intus-ui/components/ParticipantList';
import Text from '@intus-ui/components/Text';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { createCustomColumns } from '@intus-ui/components/ParticipantList/helpers';

export const MetricParticipantList = ({
  metricData,
  selectedLabel,
  participantsList,
  handleOnClickCloseButton,
  onClickItem,
  style,
  ...rest
}) => {
  const history = useHistory();

  const columnConfig = getColumnConfig(metricData);
  const columnFormat = getColumnFormat(participantsList, metricData);

  const sortColumn = metricData?.data?.columns?.find((c) => c.sortOrder != null);

  const defaultSort =
    sortColumn != null
      ? { field: sortColumn.field, direction: sortColumn.sortOrder }
      : { field: 'count', direction: 'desc' };

  function defaultOnClickItem(ppt) {
    history.push(ppt.link ? ppt.link : `/patient/${ppt.id}/current-acuity`);
  }

  return (
    <div
      style={{
        ...styles.listContainer,
        border: `solid 2px ${selectedLabel ? '#2E62E7' : '#C8C8C8'}`,
        ...style,
      }}
    >
      <div style={styles.listTitle}>
        <Text type="subtitle" color="navy" style={{ flex: 1 }}>
          {selectedLabel != null ? selectedLabel : `All ${getStringReplacement('Participant', { plural: true})}`}
        </Text>

        {selectedLabel && (
          <Icon
            name="Remove"
            color="#9D9D9D"
            hoverColor="#9D9D9D"
            onClick={handleOnClickCloseButton}
          />
        )}
      </div>
      <ParticipantList
        participantList={participantsList}
        searchable={false}
        onClickItem={onClickItem ?? defaultOnClickItem}
        defaultSort={defaultSort}
        customColumnConfig={columnConfig}
        customFormat={columnFormat}
        {...rest}
      />
    </div>
  );
};

function getColumnConfig(metricData) {
  const defaultColumns = ['name'];

  const { columns } = metricData?.data ?? {};

  // We always default to a count column if the metric doesn't set any custom columns itself.
  if (columns == null) {
    defaultColumns.push('count');
  }

  const allColumns = [...defaultColumns, ...(columns ?? []).map((c) => c.field)];

  return {
    default: {
      columns: allColumns,
    },
  };
}

function getColumnFormat(participantsList, metricData) {
  const commonColumns = [
    {
      field: 'name',
      name: `${getStringReplacement('Participant')} Name (${participantsList?.length})`,
      flex: 3,
      align: 'start',
      addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
    },
  ];

  if (!metricData?.data?.columns || metricData.data.columns.length === 0) {
    // If there's no custom columns just use the name and count columns.
    commonColumns.push({
      field: 'count',
      name: `Count (${sumUp(participantsList?.map((ppt) => ppt?.count))})`,
      flex: 1,
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="count" id="count" /> }],
    });
    return commonColumns;
  }

  const customColumns = createCustomColumns(metricData.data.columns);
  return [...commonColumns, ...customColumns];
}

const styles = {
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    border: 'solid 2px #C8C8C8',
    padding: 5,
    width: '100%',
    height: '100%',
  },
  listTitle: {
    padding: '10px 20px 0px 20px',
    gap: 10,
    display: 'flex',
    alignItems: 'center',
  },
};

import { SidebarwithTabs } from '@intus-ui';
import EventGroups from './EventGroups';

const Sidebar = () => {
  const riskTrendlineTabs = [{ tab: 'Events', content: <EventGroups /> }];

  return <SidebarwithTabs items={riskTrendlineTabs} contentStyles={styles.contentStyles} />;
};

const styles = {
  contentStyles: {
    gap: 20,
  },
};

export default Sidebar;

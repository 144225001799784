import PropTypes from 'prop-types';

const LineDataPropType = PropTypes.shape({
  title: PropTypes.string,
  xTitle: PropTypes.string,
  yTitle: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.string,
        })
      ),
    })
  ),
  onClick: PropTypes.func,
  sliceTooltip: PropTypes.bool,
});

export default LineDataPropType;

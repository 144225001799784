import React from 'react';
import { range } from 'lodash';
import { ResponsiveBar } from '@nivo/bar';
import { chartColors } from './styles';
import { formatTooltip } from './helpers';

const SeverityChart = ({ severityData = [], id, maxValue = 'auto' }) => {
  if (!severityData.length) return null;

  return (
    <ResponsiveBar
      animate={false}
      margin={{ top: 20, bottom: 30, left: 45 }}
      label={(label) => {
        if (label.value === 0) return '';
        return label.formattedValue;
      }}
      colors={chartColors[0]}
      colorBy="fallCount"
      tooltip={(point) => {
        return formatTooltip(`${point.indexValue}: ${point.value}`, point, id);
      }}
      keys={['value']}
      indexBy="id"
      padding={0.5}
      data={severityData}
      maxValue={maxValue}
      axisLeft={{
        orient: 'left',
        tickValues: 10,
        legend: 'Number of Falls',
        legendPosition: 'middle',
        legendOffset: -30,
        tickSize: 0,
        format: (e) => Math.floor(e) === e && e,
      }}
      gridYValues={range(1, maxValue + 1)}
      innerPadding={1}
      axisBottom={{
        tickSize: 0,
      }}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: 'rgba(0, 0, 0, .1)',
              strokeWidth: 1,
            },
          },
        },
      }}
    />
  );
};

export default SeverityChart;

import { getIndicators } from '@global-state/redux/dashboardSlice';
import { getSessionUser, setSessionToken, setSessionUser } from '@util/session';
import { initializeUserSettings, getUserInfo } from '@global-state/redux/userSettingsSlice';
import {
  USER_ACCESS_GOD,
  USER_ACCESS_HEALTH_SYS_ADMIN,
  USER_ACCESS_TRACKING_ADMIN,
} from 'Settings/userSettingsPropType';
import { getTrackingEvent } from '@api/api';
import { toUTC, newDate, formatDate } from '@util/dateFunctions';
import { setIsHealthSysAdmin } from '@global-state/redux/filtersSlice';
import { setOrgId, setOrgName } from '@global-state/redux/welcomeSlice';
import * as userTracking from '@util/userTracking';

const onSuccessfulLogin = ({
  dispatch,
  history,
  queryParams,
  result,
  mustConfigureTwoFactorAuthentication,
}) => {
  const loginResult = { ...result };
  setSessionToken(loginResult.token, loginResult.rememberMeToken, result.email);
  // eslint-disable-next-line no-param-reassign
  delete loginResult.token;
  delete loginResult.rememberMeToken;

  setSessionUser(loginResult);
  dispatch(getIndicators());
  dispatch(getUserInfo(true));
  dispatch(initializeUserSettings(loginResult));

  if (process.env.NODE_ENV === 'production') {
    userTracking.identifyUser(loginResult);
    userTracking.logEvent('login', { appVersion: process.env.REACT_APP_VERSION });
  }

  if (!loginResult.twoFactorAuthenticationEnabled && mustConfigureTwoFactorAuthentication) {
    history.push('/twoFactorSetup');
  } else if (loginResult.access === USER_ACCESS_GOD) {
    history.push('/admin');
  } else if (loginResult.access === USER_ACCESS_TRACKING_ADMIN) {
    history.push('/userTrack');
  } else {
    getTrackingEvent({
      time: formatDate(toUTC(newDate())),
      page: 'Login',
      action: 'User Login',
    });
    const { setting, organization } = getSessionUser();

    if (loginResult.access === USER_ACCESS_HEALTH_SYS_ADMIN) {
      dispatch(setIsHealthSysAdmin(true));
      if (
        queryParams.has('redirectReason') &&
        queryParams.get('redirectReason') === 'sessionExpired' &&
        queryParams.has('redirectTo')
      ) {
        history.replace(queryParams.get('redirectTo'));
      } else {
        history.replace('/home');
      }
    } else if (setting?.welcomeFlowCompleted) {
      // "normal" login, handle potential resume-expired-session cases
      if (
        queryParams.has('redirectReason') &&
        queryParams.get('redirectReason') === 'sessionExpired' &&
        queryParams.has('redirectTo')
      ) {
        history.replace(queryParams.get('redirectTo'));
      } else {
        history.replace('/home');
      }
    } else {
      dispatch(setOrgName(organization.name));
      dispatch(setOrgId(organization.id));
      history.replace('/welcome');
    }
  }
};

export default onSuccessfulLogin;

import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Text from '@intus-ui/components/Text';
import Icon from '@intus-ui/components/Icon';
import { textColors } from '@intus-ui/styles/SecondaryColors';
import { isString } from 'lodash';
import TooltipComponent from '@intus-ui/components/TooltipComponent';

// Constants for default sizes
export const MINIMIZED_HEIGHT = '50px';
export const DEFAULT_WIDTH = '350px';
export const DEFAULT_HEIGHT = '90vh';

// Interface for props
export interface CollapsibleDrawerProps {
  width?: string;
  height?: string;
  onClose: () => void;
  onMinimize: () => void;
  open: boolean;
  minimized: boolean;
  children: React.ReactNode;
  header: React.ReactNode | string | null;
  closeButton?: boolean;
  minimizeButton?: boolean;
  animatedClose?: boolean;
  containerStyle?: React.CSSProperties;
}

// CollapsibleDrawer component
const CollapsibleDrawer: React.FC<CollapsibleDrawerProps> = ({
  open,
  minimized,
  children,
  onClose,
  onMinimize,
  width = DEFAULT_WIDTH,
  height = MINIMIZED_HEIGHT, // TODO : make this an optional customHeight
  header = '',
  closeButton = true,
  minimizeButton = true,
  containerStyle,
  animatedClose = false,
}) => {
  const [drawerHeight, setHeight] = useState(height);

  useEffect(() => {
    if (open) {
      // Brief delay for the drawer to start open animation
      const timer = setTimeout(() => {
        setHeight(height || DEFAULT_HEIGHT);
      }, 100);
      return () => clearTimeout(timer);
    }
    setHeight(MINIMIZED_HEIGHT);
    return () => {};
  }, [height, open]);

  useEffect(() => {
    if (minimized) {
      setHeight(MINIMIZED_HEIGHT);
    } else {
      setHeight(DEFAULT_HEIGHT);
    }
  }, [minimized]);

  const onCloseExt = () => {
    if (animatedClose) {
      setHeight(MINIMIZED_HEIGHT);
      setTimeout(onClose, 500);
    } else {
      onClose();
    }
  };

  if (!open) {
    return null;
  }

  return (
    <div
      style={{
        ...styles.drawer,
        ...containerStyle,
        width,
        height: drawerHeight,
        right: open ? 0 : `-${width}`,
      }}
    >
      {header && (
        <div style={styles.drawerTitleWrapper}>
          <div style={styles.drawerTitle}>
            {isString(header) ? (
              <Text type="title" color="navy">
                {header}
              </Text>
            ) : (
              header
            )}
          </div>
          <div style={styles.drawerTitleIcons}>
            {minimizeButton && (
              <TooltipComponent
                anchorId="minimize-drawer-button"
                delay={300}
                content={<Text type="caption">Collapse window</Text>}
                position="top"
                style={{ border: '1px solid  #ddd', padding: 10 }}
              >
                <IconButton id="minimize-drawer-button" onClick={onMinimize}>
                  {minimized ? (
                    <Icon name="CaretUp" color={textColors.body} hoverColor={textColors.navy} />
                  ) : (
                    <Icon name="CaretDown" color={textColors.body} hoverColor={textColors.navy} />
                  )}
                </IconButton>
              </TooltipComponent>
            )}
            {closeButton && (
              <TooltipComponent
                anchorId="close-drawer-button"
                delay={300}
                content={<Text type="caption">Close window</Text>}
                position="top"
                style={{ border: '1px solid  #ddd', padding: 10 }}
              >
                <IconButton id="close-drawer-button" onClick={onCloseExt}>
                  <CloseIcon />
                </IconButton>
              </TooltipComponent>
            )}
          </div>
        </div>
      )}
      <div style={styles.drawerContent}>{children}</div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  drawer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: '0 20px 0 0',
    border: '1px solid #9D9D9D',
    borderRadius: '10px 10px 0 0',
    width: '400px',
    height: '100%',
    backgroundColor: 'white',
    boxShadow: '0 0 10px rgba(0,0,0,0.2)',
    zIndex: 1000,
    transition: 'height 0.3s ease-in-out',
  },
  drawerTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 10px 10px 20px',
    borderBottom: '1px solid #ddd',
    height: '49px', // MINIMIZED_HEIGHT - 1 which leaves a 1px border
  },
  drawerTitle: {
    flex: 1,
  },
  drawerTitleIcons: {
    display: 'flex',
  },
  drawerContent: {
    height: '100%',
    // drawerContent styles should be added by children
  },
};

export default CollapsibleDrawer;

import { useState } from 'react';
import { Switch, Route, useHistory, useParams } from 'react-router-dom';

import { Button, Text, layout } from '@intus-ui';

const CohortsTabs = ({ items, topLeftContent }) => {
  const history = useHistory();
  const { tab: tabParam } = useParams();

  const tabIndex = items.findIndex((object) => {
    return object.tab.toLowerCase() === tabParam;
  });
  const [selected, setSelected] = useState(tabIndex || 0);

  return (
    <div style={styles.quad}>
      <div style={styles.header}>
        <div style={styles.topLeftContent}>{topLeftContent}</div>
        <div style={styles.tabs}>
          {items.map(({ tab, tabUrl }, index) => {
            const isActive = selected === index;

            return (
              <Tab
                key={JSON.stringify(tab)}
                name={tab}
                isActive={isActive}
                onClick={() => {
                  setSelected(index);
                  history.push(tabUrl);
                }}
              />
            );
          })}
        </div>
        {items[selected]?.rightButton ? (
          items[selected].rightButton
        ) : (
          <div style={styles.tabsHeaderSpacer} />
        )}
      </div>

      <div style={layout.container}>
        <Switch>
          {items.map(({ tabUrl }, index) => {
            return (
              <Route key={JSON.stringify(tabUrl)} exact path={tabUrl}>
                {items[index].content}
              </Route>
            );
          })}
        </Switch>
      </div>
    </div>
  );
};
const Tab = ({ name, isActive, onClick }) => (
  <Button onClick={() => onClick()} textOnly>
    <Text type="subtitle" color={isActive ? 'navy' : 'link'}>
      {name}
    </Text>
  </Button>
);

const styles = {
  quad: {
    ...layout.quad,
    overflow: 'auto',
    padding: '20px 15px 0px 15px',
  },
  header: {
    display: 'flex',
    padding: ' 0px 15px 0px 15px',
  },
  topLeftContent: {
    display: 'flex',
    alignItems: 'center',
  },
  tabs: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
  },
  tab: {
    backgroundColor: 'white',
    padding: '7.5px 15px',
    borderRadius: 5,
    cursor: 'pointer',
  },
  tabsHeaderSpacer: { width: 140 },
  activeTab: { backgroundColor: '#EFF0F7' },
};

export default CohortsTabs;

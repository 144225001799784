/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { asyncForEach } from '@util/utilFunctions';
import { getPatientHospitalizations, getPatientAcuity, getPatientAdmits } from '@api/api';
import { getFilledInIcdCodes } from '@util/icdCodes';
import { dateDifference, isSameDate, isAfterDate } from '@util/dateFunctions';
import { getLogger } from '@util/logger';
import { getFeatureFlag } from '@config';
import { isEmpty } from 'lodash';

const log = getLogger('homepageSlice');

const hospitalizations = createSlice({
  name: 'hospitalizations',
  initialState: {
    error: undefined,
    recentHosps: undefined,
  },
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },

    setRecentHosps(state, action) {
      state.recentHosps = action.payload;
    },

    clearHosps(state) {
      state.recentHosps = undefined;
    },

    setSelectedHosp(state, action) {
      state.selectedHosp = action.payload;
    },

    resetSelectedHosp(state) {
      state.selectedHosp = null;
    },
  },
});

export default hospitalizations.reducer;
export const { setError, setRecentHosps, clearHosps, setSelectedHosp, resetSelectedHosp } =
  hospitalizations.actions;

/**
 * Returns the difference in days between endDate and startDate
 * @param {String} startDate - the start date
 * @param {String} endDate - the end date
 */
const getLengthOfStay = (startDate, endDate) => {
  if (!endDate) return 0;
  return dateDifference(endDate, startDate, 'days');
};

const placeOfStayToAdmitType = (placeOfStay) => {
  if (placeOfStay.toLowerCase().startsWith('emerg')) return 'ER';
  if (placeOfStay.toLowerCase().startsWith('inpat')) return 'INP';
  if (placeOfStay.toLowerCase().startsWith('skill')) return 'SNF';
  if (placeOfStay.toLowerCase().startsWith('snf')) return 'SNF';
  if (placeOfStay.toLowerCase().includes('assist')) return 'ALF';
  return 'LTC';
};

const placeOfStayToAdmitDesc = (placeOfStay) => {
  if (placeOfStay.toLowerCase().startsWith('emerg')) return 'Emergency Room';
  if (placeOfStay.toLowerCase().startsWith('inpat')) return 'Inpatient';
  if (placeOfStay.toLowerCase().startsWith('skill')) return 'Skilled Nursing Facility';
  if (placeOfStay.toLowerCase().startsWith('snf')) return 'Skilled Nursing Facility';
  if (placeOfStay.toLowerCase().includes('assist')) return 'Assisted Living Facility';
  return 'Long-Term Care';
};

export const getRecentHospitalizations = (id) => async (dispatch) => {
  dispatch(clearHosps());
  dispatch(resetSelectedHosp());

  const errorMessage = 'Could not fetch Participant hospitalization info.';
  try {
    const usingAdmits = getFeatureFlag('admits').featureIsActive;

    const hospitalizationsResponse = usingAdmits
      ? await getPatientAdmits(id)
      : await getPatientHospitalizations(id);
    const acuityResponse = await getPatientAcuity(id);

    if (hospitalizationsResponse.ok) {
      const hospitalizationsData = await hospitalizationsResponse.json();
      // clone admits fields as hosp field so they work with current hosp references downstream
      if (usingAdmits) {
        hospitalizationsData.forEach((o) => {
          o.startDate = o.admitDate;
          o.endDate = o.dischargeDate;
          o.principalDX = o.principalDx;
          o.placeOfStay = o.admitFacility;
          o.dischargeStatusDesc = o.dischargeDisposition;
          o.admissionTypeDesc = o.admitType;
        });
      }

      for (const hospitalization of hospitalizationsData) {
        // Avoid nulls since they blow up.
        if (hospitalization.principalDX == null) {
          hospitalization.principalDX = '';
        }
      }

      hospitalizationsData.sort((a, b) => {
        const isSameDay = isSameDate(a.startDate, b.startDate, 'day');
        if (isSameDay) {
          if (!a.endDate && !!b.endDate) return 1;
          if (!!a.endDate && !b.endDate) return -1;
        }
        return dateDifference(b.startDate, a.startDate);
      });
      let acuityJson = [null];
      let hosps;
      if (acuityResponse.ok) {
        acuityJson = await acuityResponse.json();
        // sorting oldest to newest
        acuityJson.sort((a, b) => dateDifference(a.createdAt, b.createdAt));

        hosps = hospitalizationsData.map((hosp) => {
          const lengthOfStay = hosp.startDate
            ? getLengthOfStay(hosp.startDate, hosp.endDate)
            : null;
          let score = null;
          let livingSituation = null;
          acuityJson.forEach((acuityAssessment) => {
            if (isAfterDate(hosp.startDate, acuityAssessment.createdAt)) {
              score = acuityAssessment.acuityScore;
              livingSituation = acuityAssessment.livingSituation;
            }
          });

          return Object.assign(hosp, { riskScore: score, livingSituation, lengthOfStay });
        });
      } else {
        hosps = hospitalizationsData.map((hosp) => {
          const { startDate, endDate } = hosp;
          const lengthOfStay = startDate ? getLengthOfStay(startDate, endDate) : null;
          return Object.assign(hosp, { riskScore: acuityJson[0], lengthOfStay });
        });
      }

      let icdMap = [];
      if (
        hospitalizationsData.length > 0 &&
        hospitalizationsData.some((h) => !isEmpty(h.principalDX))
      ) {
        icdMap = await getFilledInIcdCodes(
          hospitalizationsData
            .filter((p) => !isEmpty(p.principalDX))
            .map((o) => o.principalDX.split(','))
            .flat()
        );
      }

      // Add message if there are multiple DXs to see more in admits tab
      const callback = async (hospitalization) => {
        if (hospitalization.principalDX?.includes(',')) {
          const firstDx = hospitalization.principalDX.split(',')[0];
          const formattedPrincipalDx = icdMap.find((o) => o.code === firstDx).description;
          hospitalization.formattedPrincipalDx = formattedPrincipalDx;
          hospitalization.formattedPrincipalDx += ' (See admits tab for more DXs)';
        } else {
          if (isEmpty(hospitalization.principalDX)) {
            hospitalization.formattedPrincipalDx = 'No diagnosis entered';
          } else {
            const formattedPrincipalDx = icdMap.find(
              (o) => o.code === hospitalization.principalDX
            )?.description;
            hospitalization.formattedPrincipalDx = formattedPrincipalDx;
          }
        }

        // Set formattedDxs property to be used in the DetailCard
        if (isEmpty(hospitalization.principalDX)) {
          hospitalization.formattedDxs = ['No diagnosis entered'];
        } else {
          hospitalization.formattedDxs = hospitalization.principalDX
            .split(',')
            .map((code) => {
              return icdMap.find((item) => item.code === code)?.description;
            })
            .filter((item) => typeof item === 'string');
        }
      };
      asyncForEach(hosps, callback).then(() => {
        const hospsWithIconText = hosps.map((hosp, hospIndex) => ({
          ...hosp,
          hospIndex,
          iconText: placeOfStayToAdmitType(hosp.placeOfStay),
          iconDesc: placeOfStayToAdmitDesc(hosp.placeOfStay),
        }));
        dispatch(setRecentHosps(hospsWithIconText));
      });
    } else {
      dispatch(setError(errorMessage));
    }
    // .then(() => { dispatch(setSelected({ item, modalTitle })); });
  } catch (err) {
    log.error(err);
  }
};

import PropTypes from 'prop-types';

const profileSubjectPropType = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  dateOfBirth: PropTypes.string,
  address: PropTypes.shape({
    city: PropTypes.string,
    secondaryAddress: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.number,
  }),
});

export default profileSubjectPropType;

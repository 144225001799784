import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Field } from 'formik';

const FormikFormControl = ({
  as,
  md,
  controlId,
  label,
  name,
  type,
  children,
  controlAs,
  autocomplete,
  disabled,
  rows,
  placeholder,
}) => (
  <Field name={name}>
    {({ field, form }) => {
      const isValid = !form.errors[field.name];
      const isInvalid = form.touched[field.name] && !isValid;
      return (
        <Form.Group as={as} md={md} controlId={controlId}>
          {type === 'checkbox' ? (
            <Form.Check
              {...field}
              type={type}
              id={field.name}
              label={label}
              disabled={disabled}
              isValid={form.touched[field.name] && isValid}
              isInvalid={isInvalid}
            />
          ) : (
            <>
              {label ? <Form.Label>{label}</Form.Label> : null}
              <Form.Control
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                value={form && form.values ? form.values[field.name] || '' : ''}
                type={type}
                isValid={form.touched[field.name] && isValid}
                isInvalid={isInvalid}
                feedback={form.errors[field.name]}
                as={controlAs}
                autoComplete={autocomplete}
                disabled={disabled}
                rows={rows}
                placeholder={placeholder}
              >
                {children}
              </Form.Control>
            </>
          )}

          <Form.Control.Feedback type="invalid">{form.errors[field.name]}</Form.Control.Feedback>
        </Form.Group>
      );
    }}
  </Field>
);

FormikFormControl.propTypes = {
  as: PropTypes.elementType,
  md: PropTypes.string,
  controlId: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  controlAs: PropTypes.string,
  autocomplete: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

FormikFormControl.defaultProps = {
  as: undefined,
  md: undefined,
  controlId: undefined,
  label: undefined,
  name: undefined,
  type: 'text',
  children: null,
  controlAs: undefined,
  autocomplete: undefined,
  disabled: false,
  rows: undefined,
  placeholder: undefined,
};

export default FormikFormControl;

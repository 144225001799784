import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { Text } from '@intus-ui';
import { useListContext } from '@intus-ui/components/List/Context';

// TODO: replace with a component that is currently being made without bootstrap
const StatusDropdown = () => {
  const { addFilter, filters } = useListContext();
  const [selected, setSelected] = useState('All');
  const filterOptions = ['Active', 'Inactive', 'All'];

  useEffect(() => {
    const selectedOption = filters.find((filter) => filter.type === 'status');
    if (selectedOption) setSelected(selectedOption?.value);
    else setSelected('All');
  }, [filters]);

  useEffect(() => {
    addFilter({ type: 'status', value: selected });
  }, [selected]);

  return (
    <DropdownButton
      size="sm"
      onSelect={(event) => {
        setSelected(event);
      }}
      id="status"
      title={selected}
    >
      {filterOptions?.map((option) => {
        return (
          <Dropdown.Item eventKey={option} key={JSON.stringify(option)}>
            <Text>{option}</Text>
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export default StatusDropdown;

import React, { ReactNode, useState } from 'react';

import { formatDate } from '@util/dateFunctions';
import { logEvent } from '@util/userTracking';

import { Modal, Text, DetailsButton } from '@intus-ui';

import ExecutiveViewChart from 'Utilization/ExecutiveView/ExecutiveViewChart';
import { useUtilizationContext } from 'Utilization/Context';
import {
  getAdmissionsChartConfig,
  getAdmittedPatientsMappedByGroup,
  getLosChartConfig,
} from 'Utilization/ExecutiveView/helperFunctions';
import ExecutiveViewChartDetails from 'Utilization/ExecutiveView/ExecutiveViewChartDetails';

import { styles } from '../styles';

const SeeMoreSection = ({
  leftLabel,
  seeMoreLabel,
  onClickSeeMore,
}: {
  leftLabel: string;
  seeMoreLabel: string;
  onClickSeeMore?: () => void;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: '10px',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Text type="caption" color="caption">
        {leftLabel}
      </Text>
      <DetailsButton onClick={onClickSeeMore} text={seeMoreLabel} />
    </div>
  );
};

interface WidgetProps {
  title: string;
  subTitle: string;
  children?: ReactNode;
}

const Widget: React.FC<WidgetProps> = ({ title, subTitle, children }) => {
  return (
    <div style={styles.widget}>
      <div style={styles.widgetHeader}>
        <Text type="subtitle" color="navy">
          {title}
        </Text>
        <Text type="caption" color="caption">
          {subTitle}
        </Text>
      </div>
      {children}
    </div>
  );
};

const ExecutiveView: React.FC = () => {
  const {
    admitsType,
    startDate,
    endDate,
    data: utilizationData,
    isHealthSystemAdmin,
  } = useUtilizationContext();

  const {
    admissionsByOrg,
    admissionsByType,
    daysByOrg,
    daysByType,
    utilizationTargetsByOrg,
    admits,
  } = utilizationData;

  // Get Admissions chart data
  const { admissionsLabels, admissionsDataPoints, admissionsTargets } = getAdmissionsChartConfig(
    isHealthSystemAdmin ? admissionsByOrg : admissionsByType, // If health system admin, use admissionsByOrg, else use admissionsByType
    utilizationTargetsByOrg,
    admitsType.toLowerCase(), // TODO replace with mapping
    isHealthSystemAdmin
  );

  // Get Length of Stay chart data
  const { losLabels, losDataPoints, losTargets } = getLosChartConfig(
    isHealthSystemAdmin ? daysByOrg : daysByType, // If health system admin, use daysByOrg, else use daysByType
    utilizationTargetsByOrg,
    admitsType.toLowerCase(), // TODO replace with mapping
    isHealthSystemAdmin
  );

  const dateRange = `${formatDate(startDate, 'M/D/YYYY')}  - ${formatDate(endDate, 'M/D/YYYY')}`;
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [chartData, setChartData] = useState<{
    labels: string[];
    dataPoints: number[];
    targets: number[];
  }>({ labels: [], dataPoints: [], targets: [] });

  const [admittedParticipants, setAdmittedParticipants] = useState({});

  const onClickAdmissionsSeeMore = () => {
    logEvent('Utilization: Admissions See More Clicked');

    // Set chart data
    setChartData({
      labels: admissionsLabels,
      dataPoints: admissionsDataPoints,
      targets: admissionsTargets,
    });
    // Set Patients List Data
    const admittedPatients = isHealthSystemAdmin
      ? getAdmittedPatientsMappedByGroup(admissionsByOrg, admits)
      : getAdmittedPatientsMappedByGroup(admissionsByType, admits);
    setAdmittedParticipants(admittedPatients);
    setModalTitle('Admissions Count');
    setOpenModal(true);
  };

  const onClickLosSeeMore = () => {
    logEvent('Utilization: LOS See More Clicked');

    // Set chart data
    setChartData({
      labels: losLabels,
      dataPoints: losDataPoints,
      targets: losTargets,
    });
    // Set Patients List Data
    const admittedPatients = isHealthSystemAdmin
      ? getAdmittedPatientsMappedByGroup(daysByOrg, admits)
      : getAdmittedPatientsMappedByGroup(daysByType, admits);
    setAdmittedParticipants(admittedPatients);
    setModalTitle('Length of Stay');
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        header={{
          title: (
            <Text type="title" color="navy">
              {modalTitle}
            </Text>
          ),
          centered: false,
        }}
        open={openModal}
        onClose={closeModal}
        type="large"
      >
        <ExecutiveViewChartDetails
          xAxisLabel={modalTitle}
          chartData={chartData}
          admittedParticipants={admittedParticipants}
        />
      </Modal>
      <Text type="subtitle">Executive View</Text>
      <div style={styles.executiveView}>
        <Widget title="Admissions Count" subTitle={dateRange}>
          <ExecutiveViewChart
            chartColor="tealBlue"
            labels={admissionsLabels}
            dataPoints={admissionsDataPoints}
            targets={admissionsTargets}
            xAxisLabel="Admissions Count"
          />

          <SeeMoreSection
            leftLabel={isHealthSystemAdmin ? `${admissionsLabels.length} total Orgs` : ''}
            seeMoreLabel="See more"
            onClickSeeMore={onClickAdmissionsSeeMore}
          />
        </Widget>
        <Widget title="Length of Stay" subTitle={dateRange}>
          <ExecutiveViewChart
            chartColor="tealBlue"
            labels={losLabels}
            dataPoints={losDataPoints}
            targets={losTargets}
            xAxisLabel="Length of Stay"
          />

          <SeeMoreSection
            leftLabel={isHealthSystemAdmin ? `${admissionsLabels.length} total Orgs` : ''}
            seeMoreLabel="See more"
            onClickSeeMore={onClickLosSeeMore}
          />
        </Widget>
      </div>
    </>
  );
};

export default ExecutiveView;

import { useDispatch } from 'react-redux';

import { getRecentEventsByType } from '@global-state/redux/updatesSlice';
import { useGetSessionUser } from '@util/session';

import SingleSelect from '@intus-ui/components/SingleSelect';
import { recentEventCategoriesToEventMap } from '@intus-ui/components/EventList/helpers';
import { useParticipantFilters } from '@intus-ui/components/filters';

const TimeFilter = () => {
  const dispatch = useDispatch();
  const defaultValue = 'Past 30 Days';

  const { selectedFilters } = useParticipantFilters();

  const user = useGetSessionUser();
  const { setting } = user;
  const categories = setting.defaultRecentUpdatesFilter || [];

  const selectedEventTypes = categories.map(
    (eventType) => recentEventCategoriesToEventMap[eventType]
  );

  const timeSpanToDaysMap = {
    'Past 7 Days': 7,
    'Past 30 Days': 30,
    'Past Year': 365,
  };

  return (
    <span key="time-filter" className="d-flex justify-content-left align-items-center">
      <SingleSelect
        options={['Past 7 Days', 'Past 30 Days', 'Past Year']}
        rightIcon
        defaultValue={defaultValue}
        onClickItem={(val) => {
          dispatch(
            getRecentEventsByType(selectedEventTypes, timeSpanToDaysMap[val], selectedFilters)
          );
        }}
      />
    </span>
  );
};

export default TimeFilter;

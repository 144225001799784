import { useParams } from 'react-router-dom';
import { getCohort } from '@api';
import { useQuery } from '@api/useQuery';

import { SpinnerOrError } from '@intus-ui';
import CreateRollingCohort from './CreateRollingCohort';

const EditRollingCohort = () => {
  const { cohortId } = useParams();
  const { data, error, loading } = useQuery(() => getCohort(cohortId));
  const cohortToEdit = { participants: data?.[0]?.patientIds, ...data?.[0] };

  if (error) {
    return <SpinnerOrError error="An error occurred loading the cohort" />;
  }
  if (loading) {
    return <SpinnerOrError />;
  }
  return <CreateRollingCohort cohortToEdit={cohortToEdit} />;
};
export default EditRollingCohort;

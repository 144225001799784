import layouts from '@intus-ui/layouts';
import { widgetSizes } from '@intus-ui/components/Widget/config';
import Card from '../Card';
import Text from '../Text';

const WidgetEmptyState = () => {
  const cardContentStyle = { ...styles.content, width: '100%', height: '100%' };
  const containerStyle = { ...styles.container, ...widgetSizes.small };

  return (
    <Card
      dropshadow
      hideHeaderDivider
      header={
        <Text type="subtitle" color="caption">
          Metric Card Preview
        </Text>
      }
      contentStyle={cardContentStyle}
      style={containerStyle}
    >
      <div>
        <Text>Please finish choosing your card category first.</Text>
      </div>
    </Card>
  );
};

const styles = {
  container: {
    backgroundColor: 'white',
  },
  content: {
    ...layouts.container,
    ...layouts.centeredContentContainer,
    gap: 10,
    height: '100%',
  },
};

export default WidgetEmptyState;

import { getStringReplacement } from '@util/stringReplacements';
import SortIcon from '@intus-ui/components/List/SortIcon';

/**
 * A list of all possible fields with their corresponding names, styles, and addOns
 * columnConfig selects specific fields from this to display
 * NOTE: *THIS* is where display order is controlled, not columnConfigs!
 */
export function format() {
  return [
    {
      field: 'pinnedIcon',
      name: 'Pin',
      flex: 1,
      align: 'start',
      addOns: [{ type: 'sort', element: <SortIcon key="pinned" id="pinned" /> }],
    },
    {
      field: 'name',
      name: `${getStringReplacement('Participant')} Name`,
      flex: 3,
      bg: 'lightpink',
      align: 'start',
      addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
    },
    {
      field: 'instanceCount',
      name: 'Count',
      flex: 1,
      align: 'end',
      addOns: [{ type: 'sort', element: <SortIcon key="instanceCount" id="instanceCount" /> }],
    },
    {
      field: 'age',
      name: 'Age',
      flex: 1,
      bg: 'lightyellow',
      align: 'end',
      paddingRight: '10px', // use this if you need more space between columns than the set gap
      addOns: [{ type: 'sort', element: <SortIcon key="age" id="age" /> }],
    },
    {
      field: 'livingSituation',
      name: 'Living Situation',
      flex: 3,
      bg: 'lightgreen',
      align: 'start',
    },
    {
      field: 'acuity',
      name: 'Acuity',
      flex: 1,
      bg: 'lightblue',
      align: 'end',
      addOns: [
        {
          type: 'sort',
          element: <SortIcon key="acuity" id="acuity" />,
        },
      ],
    },
    {
      field: 'scoreGroup',
      name: 'Score Group',
      flex: 3,
      bg: 'green',
      align: 'center',
    },
  ];
}

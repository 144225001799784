import { getStringReplacement } from '@util/stringReplacements';
import { layout, Text } from '@intus-ui';
import Pills from 'AdvancedCohort/Pills';

const FilterSummary = ({ filter, style }) => {
  if (filter == null) {
    return null;
  }
  const { groups } = filter;
  if (groups === undefined || groups?.length === 0) return null;

  const isFilterGroup = groups[0].operator;

  return (
    <div style={{ ...layout.rowContainer, flexWrap: 'wrap', gap: 10, ...style }}>
      {!isFilterGroup && (
        <Text type="body" color="caption">
          {getStringReplacement('Participant', {plural: true})} whose
        </Text>
      )}
      <Pills filter={filter} keyPrefix="filter-result-pills" />
    </div>
  );
};

export default FilterSummary;

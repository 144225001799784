import { MenuItem, Select } from '@mui/material';
import {
  INextFillDueStatus,
  allNextFillDueStatus,
} from 'Polypharmacy/components/NextFillDueColumn';
import React, { FC } from 'react';

export type NextFillDueSelectProps = {
  dueDateStatus: INextFillDueStatus;
  setDueDateStatus: (status: INextFillDueStatus) => void;
};

export const NextFillDueSelect: FC<NextFillDueSelectProps> = ({
  dueDateStatus,
  setDueDateStatus,
}) => {
  return (
    <Select
      label="Next Fill Due Status"
      value={dueDateStatus}
      onChange={(e) => {
        setDueDateStatus(e.target.value as INextFillDueStatus);
      }}
      sx={{ width: 150 }}
    >
      {allNextFillDueStatus
        // Don't show the Okay option
        .filter((status) => status !== 'Okay')
        .map((status) => {
          return (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          );
        })}
    </Select>
  );
};

import { deleteAPI, get, post, put } from '@api/apiMethods';
import { toISOString } from '@util/dateFunctions';
import produce from 'immer';

export const getInitiatives = () => get('initiatives');

export const putInitiative = (initiativeId, initiative) =>
  put(`initiatives/${initiativeId}`, initiative);

export const deleteInitiative = (initiativeId) => deleteAPI(`initiatives/${initiativeId}`);

export const getTrial = (initiativeId, trialId) =>
  get(`initiatives/${initiativeId}/trials/${trialId}`);

export const getMetricForTrial = (initiativeId, trialId, metricId, startDate) =>
  get(`initiatives/${initiativeId}/trials/${trialId}/metric/${metricId}?startDate=${startDate}`);

export const deleteTrial = (initiativeId, trialId) =>
  deleteAPI(`initiatives/${initiativeId}/trials/${trialId}`);

export const getTrialMetrics = () => get(`trial-metrics`);

export const postTrial = (trial) => {
  return post('initiatives/initiative-trial', convertDates(trial));
};

export const putTrial = (initiativeId, trial) =>
  put(`initiatives/${initiativeId}/trials/${trial.id}`, convertDates(trial));

export const postDownloadInitiative = async (initiativeId, startDate) =>
  post(`initiatives/${initiativeId}/download`, { startDate });

/**
 * Converts the trial dates into the correct ISO string in the format of 2022-01-15.
 */
function convertDates(trial) {
  return produce(trial, (draft) => {
    draft.cohorts.forEach((cohort) => {
      cohort.startDate = toISOString(cohort.startDate);
      cohort.endDate = toISOString(cohort.endDate);
    });
  });
}

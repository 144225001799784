import { quadColors } from '@intus-ui/styles/SecondaryColors';
import DateRange from '@intus-ui/components/DateRange';
import DropdownItem from './DropdownItem';
import { monthsOptions } from './config';

const DateRangeDropdownList = ({
  styles,
  showDateRange,
  onChange,
  onApplyRange,
  onCancelRange,
  setShowDateRange,
  filteredOptions,
  hasSuggestion,
  suggestionLabel,
  noMatchLabel,
  setIsCollapsed,
  dateRangeDirection = 'left',
  value,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        position: 'fixed',
        width: 'max-content',
        flexDirection: showDateRange && dateRangeDirection === 'left' ? 'row-reverse' : 'row',
        border: 'solid 1px #849095',
        backgroundColor: 'white',
        marginLeft: showDateRange && dateRangeDirection === 'left' ? '-334px' : 0,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          height: 'fit-content',
          borderColor: quadColors.disabled,
          overflowY: 'auto',
          maxHeight: 200,
          ...styles,
        }}
      >
        {filteredOptions.length === 0 ? (
          <DropdownItem
            empty
            hasSuggestion={hasSuggestion}
            suggestionLabel={suggestionLabel}
            noMatchLabel={noMatchLabel}
            dateRangeDirection={dateRangeDirection}
          />
        ) : (
          filteredOptions.map((option, i) => {
            const key = i;
            const isCustomRange = option.label === 'Custom Range';
            let fullOption = monthsOptions[option];

            if (!fullOption && isCustomRange) {
              fullOption = monthsOptions.customRange;
            }

            return (
              <DropdownItem
                key={key}
                index={i}
                suggestionLabel=""
                option={fullOption}
                onSelect={onChange}
                setIsCollapsed={setIsCollapsed}
                withCustomDateRanges={isCustomRange}
                showDateRange={showDateRange}
                setShowDateRange={setShowDateRange}
                dateRangeDirection={dateRangeDirection}
              />
            );
          })
        )}
      </div>
      {showDateRange && (
        <DateRange
          existingLabel={value}
          onChange={onChange}
          onApplyRange={onApplyRange}
          onCancelRange={onCancelRange}
          setShowDateRange={setShowDateRange}
          dateRangeDirection={dateRangeDirection}
        />
      )}
    </div>
  );
};

export default DateRangeDropdownList;

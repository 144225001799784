import { useHover } from '@intus-ui/hooks';
import PropTypes from 'prop-types';
import Text from '../Text';
import { pillTypes } from './pillConfig';

const Pill = ({ content, type = 'default', children, ...rest }) => {
  const [hoverRef, isHovered] = useHover();

  const { initialState, hoverState } = pillTypes[type];
  const styleConfig = {
    cursor: isHovered ? hoverState.cursor : initialState.cursor,
    backgroundColor: isHovered ? hoverState.backgroundColor : initialState.backgroundColor,
    borderColor: isHovered ? hoverState.borderColor : initialState.borderColor,
  };

  return (
    <div style={{ ...styles.pill, ...styleConfig }} ref={hoverRef} {...rest}>
      {content ? (
        <Text color={isHovered ? hoverState.textColor : initialState.textColor} type="subtitle">
          {content}
        </Text>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
};

const styles = {
  pill: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 8px',
    gap: 10,
    minWidth: 90,
    width: 'fit-content',
    height: 25,
    border: '2px solid',
    borderRadius: '15px',
  },
};

export default Pill;

Pill.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
};

import { Button, Modal, Text } from '@intus-ui';
import { useEffect, useState } from 'react';
import * as userTracking from '@util/userTracking';

const ShareModal = ({
  existingDashboard,
  openShareModal,
  setOpenShareModal,
  runUpdateDashboardQuery,
  updateDashboardError,
  updateDashboardLoading,
}) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (updateDashboardError?.toString().includes('Validation error'))
      setError(
        'Dashboard name already exists. Please rename this dashboard before changing the sharing settings.'
      );
    else if (updateDashboardError) setError('An error occurred while saving this dashboard.');
    else setError(null);
  }, [updateDashboardError]);

  const handleSaveShare = (isShared) => {
    const updatedDashboard = {
      ...existingDashboard,
      isShared,
    };
    userTracking.logEvent(`Dashboard: Changed Dashboard Sharing Settings`, {
      dashboardName: existingDashboard.name,
      dashboardId: existingDashboard.id,
      changedSharingSettingsTo: existingDashboard.isShared,
    });
    runUpdateDashboardQuery(updatedDashboard);
  };

  return (
    <Modal
      header={{ title: 'Share Dashboard', centered: true }}
      type="small"
      open={openShareModal}
      onClose={() => setOpenShareModal(false)}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '15px 10px',
          textAlign: 'center',
        }}
      >
        {existingDashboard?.isShared === true && (
          <Text>
            This dashboard will be made personal. Other users in your organization will not be able
            to view it. You can find it in the <Text type="subtitle">Personal Dashboards</Text> list
            in Custom Dashboards.
          </Text>
        )}
        {existingDashboard?.isShared === false && (
          <Text>
            This dashboard will be shared within your organization. You can find it in the{' '}
            <Text type="subtitle">Shared Dashboards</Text> list in Custom Dashboards.
          </Text>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Text type="caption" color="error">
            {error}
          </Text>
          <Button textOnly onClick={() => setOpenShareModal(false)} busy={updateDashboardLoading}>
            Cancel
          </Button>
          {existingDashboard?.isShared === true && (
            <Button
              type="submit"
              onClick={() => handleSaveShare(false)}
              busy={updateDashboardLoading}
            >
              Make Personal
            </Button>
          )}
          {existingDashboard?.isShared === false && (
            <Button
              type="submit"
              onClick={() => handleSaveShare(true)}
              busy={updateDashboardLoading}
            >
              Share
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;

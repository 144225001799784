import { useMemo } from 'react';

import BarChart from '@intus-ui/components/Widget/components/BarChart';
import { produce, setAutoFreeze } from 'immer';
import Text from '@intus-ui/components/Text';
import { usePaginatedChart } from '@intus-ui/components/Widget/utils/usePaginatedChart';

export const BreakdownWidget = ({ metric, metricData }) => {
  const numberOfBarsPerPage = metricData?.data?.barsPerPage ?? 5;

  const { totalPages, totalItems, paginatedChartData } = usePaginatedChart(
    metricData?.data?.chartData,
    numberOfBarsPerPage
  );

  let isHorizontalBarChart = true;
  if (metricData?.data?.chartDirection === 'vertical') {
    isHorizontalBarChart = false;
  }

  const paginatedMetricData = useMemo(() => {
    if (!metricData?.data?.chartData) return metricData;

    try {
      // By default, immer freezes objects it makes to make them immutable.
      // chart.js directly mutates the data array, so we need to disable freezing or chart.js throws errors.
      setAutoFreeze(false);

      return produce(metricData, (draft) => {
        draft.data.chartData = paginatedChartData;
      });
    } finally {
      setAutoFreeze(true);
    }
  }, [metricData, paginatedChartData]);

  return (
    <div style={styles.container}>
      <div style={{ flex: '1 1 100%' }}>
        {paginatedMetricData != null && (
          <BarChart
            metricConfig={metric.metricConfig}
            metricData={paginatedMetricData}
            isHorizontalBarChart={isHorizontalBarChart}
          />
        )}
      </div>

      {totalPages > 1 && (
        <div style={{ flex: '0 0 auto', marginTop: -5 }}>
          <Text type="caption" color="caption">
            {totalItems} total categories
          </Text>
        </div>
      )}

      {metricData?.data?.asteriskText && (
        <Text type="caption" color="caption">{`*${metricData?.data?.asteriskText}`}</Text>
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};

import { get } from '@api/apiMethods';

export type IMedicationClass = {
  gpi: string;
  gpiType: string;
  groupName: string;
};

export function getAllMedicationClasses() {
  return get<IMedicationClass[]>('polypharmacy/medication-classes');
}

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getLeaderboardTeamsData, getLeaderboardMonthlyComparison } from '@api/api';
import { getLogger } from '@util/logger';

const log = getLogger('leaderboardSlice');

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState: {
    leaderboardStatus: 'idle',
    leaderboardType: undefined,
    teamRows: undefined,
    facilityRows: undefined,
    orgRows: undefined,
    monthRows: undefined,
  },
  reducers: {
    setLeaderboardStatus(state, action) {
      state.leaderboardStatus = action.payload;
    },
    setLeaderboardType(state, action) {
      state.leaderboardType = action.payload;
    },
    setTeamRows(state, action) {
      state.teamRows = action.payload;
      state.leaderboardStatus = 'idle';
    },
    setFacilityRows(state, action) {
      state.facilityRows = action.payload;
      state.leaderboardStatus = 'idle';
    },
    setOrgRows(state, action) {
      state.orgRows = action.payload;
      state.leaderboardStatus = 'idle';
    },
    setMonthRows(state, action) {
      state.monthRows = action.payload;
      state.leaderboardStatus = 'idle';
    },
  },
});

export default leaderboardSlice.reducer;

export const {
  setLeaderboardStatus,
  setLeaderboardType,
  setTeamRows,
  setFacilityRows,
  setOrgRows,
  setMonthRows,
} = leaderboardSlice.actions;

const typeToSetRow = {
  teams: setTeamRows,
  facilities: setFacilityRows,
  organizations: setOrgRows,
};

export const filterActiveDashItems = (activeDashItems, start, endOfEndDate) => {
  const filteredActiveDashItems = activeDashItems.filter(
    (item) =>
      item.fetchInfo.dashboardType !== 'action' &&
      item.fetchInfo.dashboardType !== 'shared' &&
      item.fetchInfo.dashboardType !== 'personal'
  );
  // Filtering dash items by each kind of cohort
  const intusCohortsIndicatorInfo = activeDashItems.filter(
    (item) => item.fetchInfo.dashboardType === 'action'
  );

  const personalCohortsIndicatorInfo = activeDashItems.filter(
    (item) => item.fetchInfo.dashboardType === 'personal'
  );

  const sharedCohortsIndicatorInfo = activeDashItems.filter(
    (item) => item.fetchInfo.dashboardType === 'shared'
  );

  let indicatorInfo;
  if (start && endOfEndDate) {
    indicatorInfo = filteredActiveDashItems.map((item) => ({
      time: { start, end: endOfEndDate },
      fetchInfo: item.fetchInfo,
    }));
  } else {
    indicatorInfo = filteredActiveDashItems.map((item) => ({
      fetchInfo: item.fetchInfo,
    }));
  }

  const body = {
    indicatorInfo,
    intusCohortsIndicatorInfo,
    personalCohortsIndicatorInfo,
    sharedCohortsIndicatorInfo,
  };
  return body;
};

export const fetchLeaderboardItems =
  (activeDashItems, leaderboardType, leaderboardArray, start, end) => async (dispatch) => {
    if (activeDashItems.length && (leaderboardArray || []).length) {
      dispatch(setLeaderboardStatus('loading'));
      try {
        // Getting body with cohort and dash items separated
        const body = filterActiveDashItems(activeDashItems, start, end);
        if (leaderboardType === 'teams') body.teams = ['All', ...leaderboardArray];
        else if (leaderboardType === 'facilities') body.facilities = ['All', ...leaderboardArray];
        else if (leaderboardType === 'organizations')
          body.organizationIds = ['All', ...leaderboardArray];
        const res = await getLeaderboardTeamsData(body);
        if (res.ok) {
          const resJ = await res.json();
          const rows = resJ.map((item, index) => ({
            ...item,
            displayTitle: activeDashItems[index].displayTitle,
          }));
          dispatch(typeToSetRow[leaderboardType](rows));
        } else {
          dispatch(setLeaderboardStatus('error'));
        }
      } catch (error) {
        log.error(error);
        dispatch(setLeaderboardStatus('error'));
      }
    }
  };

export const fetchLeaderboardMonthlyComparison = (activeDashItems, filters) => async (dispatch) => {
  if (activeDashItems.length) {
    dispatch(setLeaderboardStatus('loading'));
    try {
      const body = filterActiveDashItems(activeDashItems);
      const res = await getLeaderboardMonthlyComparison({
        ...body,
        filters,
      });
      if (res.ok) {
        const resJ = await res.json();
        const rows = resJ.map((item, index) => ({
          ...item,
          displayTitle: activeDashItems[index].displayTitle,
        }));
        dispatch(setMonthRows(rows));
      } else {
        dispatch(setLeaderboardStatus('error'));
      }
    } catch (error) {
      log.error(error);
      dispatch(setLeaderboardStatus('error'));
    }
  }
};

import { relativePast } from '@util/dateFunctions';

export const monthsOptions = {
  1: {
    start: relativePast('Past 30 days'),
    end: new Date(),
    label: 'Past 30 Days',
    isRange: false,
  },
  3: {
    start: relativePast('Past 90 days'),
    end: new Date(),
    label: 'Past 90 Days',
    isRange: false,
  },
  6: {
    start: relativePast('Past 6 months'),
    end: new Date(),
    label: 'Past 6 Months',
    isRange: false,
  },
  12: {
    start: relativePast('Past 1 year'),
    end: new Date(),
    label: 'Past Year',
    isRange: false,
  },
  all: {
    start: null,
    end: new Date(),
    label: 'All Time',
    isRange: false,
  },
  customRange: {
    start: new Date(),
    end: new Date(),
    label: 'Custom Range',
    isRange: true,
  },
};

export function findMonthOptionByLabel(labelToFind) {
  const foundKey = Object.keys(monthsOptions).find((key) => {
    return monthsOptions[key].label === labelToFind;
  });

  return foundKey ? monthsOptions[foundKey] : null;
}

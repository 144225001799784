import { getSessionUser } from '@util/session';

import {
  USER_ACCESS_CAREGIVER,
  USER_ACCESS_GOD,
  USER_ACCESS_PIPELINE_GOD,
  USER_ACCESS_TRACKING_ADMIN,
} from 'Settings/userSettingsPropType';
import StatusCircle from '@intus-ui/components/StatusCircle';
import CaregiverSettings from 'Users/caregiver/caregiverList/caregiverSettings';
import PasswordReset from 'Users/caregiver/caregiverList/passwordReset';

export const formatUsers = (users) => {
  // Constructs the caregiver data that's given to List.
  const currUserId = getSessionUser().id;
  const items = users
    .filter((user) => {
      return (
        user.access !== USER_ACCESS_GOD &&
        user.access !== USER_ACCESS_TRACKING_ADMIN &&
        user.access !== USER_ACCESS_PIPELINE_GOD &&
        user.id !== currUserId
      );
    })
    .map((caregiver) => ({
      id: caregiver.id,
      name: `${caregiver.firstName} ${caregiver.lastName}`,
      organizationName: caregiver.organizationName,
      statusNode: <StatusCircle status={caregiver.status} />,
      status: caregiver.status,
      access: caregiver.access === USER_ACCESS_CAREGIVER ? 'User' : deCapitalize(caregiver.access),
      password: <PasswordReset caregiverID={caregiver.id} />,
      settings: <CaregiverSettings caregiverID={caregiver.id} />,
    }));
  return items;
};

const deCapitalize = (str) => str[0].toUpperCase() + str.slice(1).toLowerCase();

import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Button, Icon, Text } from '.';

const MoreActionsMenu = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMoreActionsMenuOpen = anchorEl != null;

  return (
    <>
      <Button
        id="more-actions-button"
        aria-controls={isMoreActionsMenuOpen ? 'more-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMoreActionsMenuOpen ? 'true' : undefined}
        secondary
        onClick={handleClick}
        style={{
          paddingRight: 0,
          gap: '2px',
          whiteSpace: 'nowrap',
          flexShrink: 0,
        }}
      >
        More actions
        <Icon style={{ marginLeft: -5 }} name="TripleVerticalDot" />
      </Button>
      <Menu
        id="more-actions-menu"
        anchorEl={anchorEl}
        open={isMoreActionsMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          style: {
            minWidth: 170,
          },
          'aria-labelledby': 'more-actions-button',
        }}
        slotProps={{
          paper: {
            style: {
              marginTop: 1,
              marginLeft: -5,
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.option}
            onClick={() => {
              option.onClick();
              handleClose();
            }}
          >
            <Text type="subtitle" color={option.color || 'navy'}>
              {option.option}
            </Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MoreActionsMenu;

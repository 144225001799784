import React, { useState } from 'react';
import PropTypes from 'prop-types';

// A button that uses secondary button styling according to style guide
// and toggles between pressed and not-pressed states
const ToggleButton = ({ name, onClick, children, toggleNames }) => {
  const [isPressed, setIsPressed] = useState(false);
  const onClickExt = () => {
    setIsPressed(!isPressed);
    onClick();
  };

  return (
    <button
      type="button"
      aria-pressed={isPressed}
      aria-label={`Click this button to toggle ${name} view`}
      title={`Click this button to toggle ${name} view`}
      name={name}
      className="default-button primary-button"
      onClick={onClickExt}
      onKeyPress={onClickExt}
    >
      {children && <div aria-hidden="true">{children}</div>}
      <p className="pl-1">
        {isPressed ? toggleNames[0] : toggleNames[1]} {name}
      </p>
    </button>
  );
};

ToggleButton.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ToggleButton.defaultProps = {
  children: undefined,
};

export default ToggleButton;

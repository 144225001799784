import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion';
import { Row, Container, Col } from 'react-bootstrap';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import { getFeatureFlag } from '@config';
import AcuityCards from './riskTrendLine/AcuityCards';

import '@intus-ui/styles/Utilities.scss';

import IntusLabelsPropType from './IntusLabelsPropType';
import { oldNamesToNewNames, newCategories } from './v2Utils/v2SubscoreConstants';

const getPptConditionsPerCategory = (allConditions, categoryName) => {
  return allConditions.filter((item) => {
    // Remove leading punctuation if it exists (used for new or removed conditions)
    const cleanedCondition = item.replace(/^[!?]/, '');
    // Check if the cleaned string is in the given category
    return newCategories.categoryToQuestionLabels[oldNamesToNewNames[categoryName]].includes(
      cleanedCondition
    );
  });
};

const ScoreBreakDown = ({ acuityLevelChanges, acuityLevels, selected }) => {
  const { featureIsActive: AcuityV2isActive } = getFeatureFlag('AcuityV2');

  // This hook is used for selecting the card to be animated to the middle
  // When selectedCard is null, nothing is animated to the middle
  // selectedCard will have the object type of: { title,, score, children, row }
  // because that is the required info to render an AcuityCards
  const [selectedCard, setSelectedCard] = useState(null);

  if (!acuityLevelChanges.length) return <CenteredSpinner className="w-100 h-100" />;

  const rows = [
    [{}, {}, {}],
    [{}, {}, {}],
  ];
  const rowsMap = {
    neurologicalAndBehavioralSubscore: [0, 1],
    recurrentSubscore: [1, 2],
    socialSubscore: [1, 1],
    coordinationComplexitySubscore: [0, 2],
    diseaseSubscore: [0, 0],
    externalCostSubscore: [1, 0],
  };
  Object.entries(acuityLevelChanges[selected]).forEach(([category, array], index) => {
    const itemWithIndex = { ...[category, array], index };
    rows[rowsMap[category][0]][rowsMap[category][1]] = itemWithIndex;
  });
  if (!rows.length) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center px-4 flex-column">
        <div>No Acuity data available for this participant.</div>
      </div>
    );
  }
  const allConditions = [];
  rows.forEach((row) => {
    row.forEach((item) => {
      allConditions.push(...item[1]);
    });
  });
  return (
    <Container fluid className="m-0 p-0 pb-4">
      <AnimateSharedLayout type="crossfade">
        {rows.map((rowItem, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={`score-row${i}`} className="m-0 w-100 h-50 py-0 px-3 d-flex">
            {rowItem.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col lg={4} className="py-2 px-2" key={`score-col${index}`}>
                <motion.div layoutId={item[0]}>
                  <AcuityCards
                    title={AcuityV2isActive ? oldNamesToNewNames[item[0]] : item[0]}
                    score={acuityLevels[item[0]]}
                    onClick={() =>
                      setSelectedCard({
                        title: AcuityV2isActive ? oldNamesToNewNames[item[0]] : item[0],
                        score: acuityLevels[item[0]],
                        children: AcuityV2isActive
                          ? [...getPptConditionsPerCategory(allConditions, item[0])]
                          : item[1],
                      })
                    }
                  >
                    {' '}
                    {AcuityV2isActive
                      ? [...getPptConditionsPerCategory(allConditions, item[0])]
                      : item[1]}
                  </AcuityCards>
                </motion.div>
              </Col>
            ))}
          </Row>
        ))}
        <AnimatePresence>
          {selectedCard && (
            <>
              {/* This section creates the transparent background */}
              <motion.div
                style={{
                  position: 'fixed',
                  pointerEvents: 'auto',
                  background: 'rgba(0,0,0,0.5)',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 2,
                }}
                onClick={() => setSelectedCard(null)}
              />
              <motion.div
                layoutId={selectedCard.title}
                style={{
                  position: 'absolute',
                  zIndex: 3,
                  top: '10%',
                  left: '50%',
                  marginLeft: '-200px',
                }}
              >
                <AcuityCards
                  animatedCard
                  title={selectedCard.title}
                  score={selectedCard.score}
                  onClick={() => setSelectedCard(null)}
                >
                  {' '}
                  {selectedCard.children}{' '}
                </AcuityCards>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
    </Container>
  );
};

ScoreBreakDown.propTypes = {
  acuityLevelChanges: PropTypes.arrayOf(IntusLabelsPropType),
  acuityLevels: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.number,
};

ScoreBreakDown.defaultProps = {
  acuityLevelChanges: [],
  selected: 0,
};

export default ScoreBreakDown;

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import logo from 'assets/logo.svg';
import '@intus-ui/styles/Login.scss';
import '@intus-ui/styles/Utilities.scss';

/**
 * This component is for a white card on an Intus blue background.
 * It's used for the log in and sign up pages, for example.
 * The name is kind of whacky, but what else would you call it?
 */
const WelcomeBox = ({ children, minWidth, buttons }) => (
  <div className="blue-background w-100 h-100 overflow-scroll">
    <div className="blue-background text-center w-100 h-100 p-5">
      <Container
        className={`d-flex flex-column min-height-100 hover-box bg-white p-5 justify-content-between ${minWidth}`}
      >
        <div className="d-flex justify-content-left">
          <img className="mr-3 mb-4" alt="IntusCare logo" src={logo} width="60" />
        </div>
        <div>{children}</div>
        <div className="w-100">{buttons}</div>
      </Container>
    </div>
  </div>
);

WelcomeBox.propTypes = {
  children: PropTypes.node.isRequired,
  minWidth: PropTypes.string,
};

WelcomeBox.defaultProps = {
  minWidth: 'w-75',
};

export default WelcomeBox;

import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@intus-ui';

const StatusCircle = ({ status }) => (
  <Icon name="bullet-large" className={status === 'ACTIVE' ? 'text-primary' : 'text-muted'} />
);

StatusCircle.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusCircle;

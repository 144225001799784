/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchEnrollments, getRecentEvents } from '@api/api';

const updatesSlice = createSlice({
  name: 'subscription',
  initialState: {
    updates: undefined,
    enrolled: undefined,
    disenrolled: undefined,
    inpatientAdmits: undefined,
    inpatientDischarges: undefined,
    erVisits: undefined,
    error: undefined,
    timeSpan: 30,
    enrollmentType: 'All',
    disenrollmentPopup: {
      show: false,
      id: undefined,
      type: undefined,
      name: undefined,
      date: undefined,
      disenrollmentReason: undefined,
      principalDX: undefined,
      startDate: undefined,
      endDate: undefined,
      placeOfStay: undefined,
    },
  },
  reducers: {
    // The payload should be a list of caregiverListCaregiverPropType.
    setUpdates(state, action) {
      state.updates = action.payload;
    },

    setEnrolled(state, action) {
      state.enrolled = action.payload;
    },

    setDisenrolled(state, action) {
      state.disenrolled = action.payload;
    },

    setInpatientAdmits(state, action) {
      state.inpatientAdmits = action.payload;
    },

    setInpatientDischarges(state, action) {
      state.inpatientDischarges = action.payload;
    },

    setErVisits(state, action) {
      state.erVisits = action.payload;
    },

    // This allows the caregiver list to display an error.
    setError(state, action) {
      state.error = action.payload;
    },

    clearEnrollmentsAndError(state) {
      state.updates = undefined;
      state.error = undefined;
    },

    clearEventsAndError(state) {
      state.enrolled = undefined;
      state.disenrolled = undefined;
      state.inpatientAdmits = undefined;
      state.inpatientDischarges = undefined;
      state.erVisits = undefined;
      state.error = undefined;
    },

    setTimeSpan(state, action) {
      state.timeSpan = action.payload;
    },

    setEnrollmentType(state, action) {
      state.enrollmentType = action.payload;
    },

    setDisenrollmentPopup(state, action) {
      state.disenrollmentPopup = action.payload;
    },

    resetDisenrollmentPopup(state) {
      state.disenrollmentPopup = {
        show: false,
        id: undefined,
        type: undefined,
        name: undefined,
        date: undefined,
        disenrollmentReason: undefined,
        principalDX: undefined,
        startDate: undefined,
        endDate: undefined,
        placeOfStay: undefined,
      };
    },
  },
});

export default updatesSlice.reducer;
export const {
  setUpdates,
  setEnrolled,
  setDisenrolled,
  setInpatientAdmits,
  setInpatientDischarges,
  setErVisits,
  setError,
  clearEnrollmentsAndError,
  clearEventsAndError,
  setTimeSpan,
  setEnrollmentType,
  setDisenrollmentPopup,
  resetDisenrollmentPopup,
} = updatesSlice.actions;

export const getEnrollments = (days, enrollmentType) => async (dispatch) => {
  dispatch(clearEnrollmentsAndError());
  dispatch(setTimeSpan(days));
  dispatch(setEnrollmentType(enrollmentType || 'All'));
  const errorMessage = 'There was a problem fetching the enrollment updates for this organization.';
  try {
    const body = {
      daysBefore: days || 28,
    };
    const result = await fetchEnrollments(body);
    if (result.ok) {
      const json = await result.json();
      dispatch(
        setUpdates(
          json
            .map((data) => ({
              id: data.id,
              firstName: data.firstName,
              lastName: data.lastName,
              disenrollmentDate: data.disenrollmentDate,
              disenrollmentReason: data.disenrollmentReason,
              enrollmentDate: data.enrollmentDate,
              status: data.status,
            }))
            .filter((data) => {
              if (enrollmentType === 'Disenrolled') {
                return data.disenrollmentDate !== null;
              }
              if (enrollmentType === 'Enrolled') {
                return data.disenrollmentDate === null;
              }
              if (enrollmentType === 'Admit' || enrollmentType === 'Discharged') {
                return data === null;
              }
              return true;
            })
        )
      );
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    dispatch(setError(errorMessage));
  }
};

export const getRecentEventsByType =
  (selectedEventTypes, daysBefore, filters) => async (dispatch) => {
    dispatch(clearEventsAndError());
    dispatch(setTimeSpan(daysBefore));
    const errorMessage = 'There was a problem fetching the recent events for this organization.';
    try {
      const result = await getRecentEvents(selectedEventTypes, daysBefore, filters);
      if (result.ok) {
        const json = await result.json();
        dispatch(setEnrolled(json.enrolled));
        dispatch(setDisenrolled(json.disenrolled));
        dispatch(setInpatientAdmits(json.inpatientAdmits));
        dispatch(setInpatientDischarges(json.inpatientDischarges));
        dispatch(setErVisits(json.erVisits));
      } else {
        dispatch(setError(errorMessage));
      }
    } catch (err) {
      dispatch(setError(errorMessage));
    }
  };

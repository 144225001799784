/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getPreview, getPopup } from '@api/api';
import { getLogger } from '@util/logger';
import { getPatientList } from '@global-state/redux/profileSlice';
import { getFeatureFlag } from '@config';
import { relativePast } from '@util/dateFunctions';
// eslint-disable-next-line import/no-cycle
import { getCard } from '../../Homes/cardMap';

const log = getLogger('homepageSlice');

const dashboardSlice = createSlice({
  name: 'homepage',
  initialState: {
    homeError: undefined,
    // so modal title is set and then patient list is set after for ex.
    selectedTitle: undefined,
    modalContent: undefined,
    editHome: false,
    selectedTab: 0,
    previewA: undefined,
    previewB: undefined,
    previewC: undefined,
  },
  reducers: {
    setHomeError(state, action) {
      state.homeError = action.payload;
    },

    // stores the data needed for each card preview in the format [{boxName, data}, ...]
    setPreviews(state, action) {
      state.previews = action.payload;
    },

    // sets the currently selected dashboard item (ie CKD, DIABETES)
    setSelected(state, action) {
      state.selectedTitle = action.payload;
    },
    // array of subcard objects [{title: string, content: ?, clickable}]
    setModalContent(state, action) {
      state.modalContent = action.payload;
    },

    resetModal(state) {
      state.selectedTitle = undefined;
      state.modalContent = undefined;
      state.homeError = undefined;
    },

    setPreviewA(state, action) {
      state.previewA = action.payload;
    },

    setPreviewB(state, action) {
      state.previewB = action.payload;
    },

    setPreviewC(state, action) {
      state.previewC = action.payload;
    },

    resetPreviews(state) {
      state.previewA = undefined;
      state.previewB = undefined;
      state.previewC = undefined;
    },

    setEdit(state) {
      state.editHome = true;
    },

    resetEdit(state) {
      state.editHome = false;
    },

    setSelectedTab(state, action) {
      state.selectedTab = parseInt(action.payload, 10);
    },
  },
});

export default dashboardSlice.reducer;
export const {
  setHomeError,
  setSelected,
  setModalContent,
  resetModal,
  setPreviews,
  setPreviewA,
  setPreviewB,
  setPreviewC,
  resetPreviews,
  addPreview,
  setEdit,
  resetEdit,
  setSelectedTab,
} = dashboardSlice.actions;

export const getModalContent = (boxName, startDate, endDate, filters) => async (dispatch) => {
  dispatch(resetModal());
  const errorMessage = 'Could not fetch information.';
  try {
    const hospitalization90isActive = getFeatureFlag(
      '90-days-hospitalization-analytics'
    ).featureIsActive;

    let localStartDate = startDate;
    if (boxName === 'hospitalizations' && hospitalization90isActive) {
      localStartDate = relativePast('Past 90 days', 'YYYY-MM-DD');
    }

    const res = await getPopup(boxName, localStartDate, endDate, filters);
    if (res.ok) {
      const json = await res.json();
      dispatch(setSelected({ boxName, title: getCard(boxName).title }));
      if (boxName === 'falls') {
        dispatch(
          getPatientList({
            patientList: json.frequentFallers.map((ppt) => ppt.id),
          })
        );
      } else if (boxName === 'riskAcuity') {
        dispatch(
          getPatientList({
            patientList: json.currentHighestAcuityParticipantList.map((ppt) => ppt.id),
          })
        );
      }
      dispatch(setModalContent(json));
    } else {
      dispatch(setHomeError(errorMessage));
    }
  } catch (err) {
    log.error(err);
  }
};

export const getCardPreview = (name, startDate, endDate, position, filters) => async (dispatch) => {
  const errorMessage = 'Could not fetch information.';
  try {
    const hospitalization90isActive = getFeatureFlag(
      '90-days-hospitalization-analytics'
    ).featureIsActive;

    let localStartDate = startDate;
    if (name === 'hospitalizations' && hospitalization90isActive) {
      localStartDate = relativePast('Past 90 days', 'YYYY-MM-DD');
    }
    const res0 = await getPreview(name, localStartDate, endDate, filters);
    if (res0.ok) {
      const json0 = await res0.json();
      if (position === 1) {
        dispatch(setPreviewA({ boxName: name, content: json0 }));
      } else if (position === 2) {
        dispatch(setPreviewB({ boxName: name, content: json0 }));
      } else if (position === 3) {
        dispatch(setPreviewC({ boxName: name, content: json0 }));
      }
    } else {
      dispatch(setHomeError(errorMessage));
    }
  } catch (err) {
    log.error(err);
  }
};

import React, { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';

type DonutChartProps = {
  info: number[];
  colors: string[];
  label: string[];
  options: ChartOptions<'doughnut'>;
  expand: boolean;
};

const DonutChart: FC<DonutChartProps> = (props) => {
  const { info, label, options, colors, expand = false } = props;

  if (!info) return null;

  const intusData: ChartData<'doughnut', number[], string> = {
    labels: label,
    datasets: [
      {
        label: 'Current Acuity Score Breakdown',
        backgroundColor: colors,
        data: info,
      },
    ],
  };

  // Default options
  const intusOptions: ChartOptions<'doughnut'> = {
    radius: '90%',
    cutout: '40%',
    responsive: false,
    maintainAspectRatio: true,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        position: 'bottom',
        rtl: false,
      },
    },
  };

  const emptyData = {
    labels: [],
    datasets: [
      {
        label: 'No Information Available',
        backgroundColor: ['gray'],
        data: [1],
      },
    ],
  };

  const isEmptyFunction = (data: number[]) => {
    let isEmpty = true;
    Object.entries(data).forEach((value) => {
      if (value[1] !== 0) {
        isEmpty = false;
      }
    });
    return isEmpty;
  };

  return (
    <div className={expand ? 'w-100 h-100' : ''}>
      {!isEmptyFunction(info) ? (
        <div className="w-100 h-100" style={{ display: 'flex', justifyContent: 'center' }}>
          <Doughnut
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            data={intusData}
            options={options || intusOptions}
          />
        </div>
      ) : (
        <div className="w-100 h-100">
          <h6 className="text-dark text-center align-self-start w-100 d-flex flex-column justify-content-center">
            No Information Available
          </h6>
          <Doughnut
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            data={emptyData}
            options={options || intusOptions}
          />
        </div>
      )}
    </div>
  );
};

export default DonutChart;

/* eslint-disable react-hooks/exhaustive-deps */

import { createContext, useContext, useMemo, useState, useEffect } from 'react';

import { useLazyQuery } from '@api/useQuery';
import { getUtilizationData } from '@api/analysis/utilization';

import { getSessionUser } from '@util/session';

import { formatDate, startOfDate, endOfDate } from '@util/dateFunctions';
import { useParticipantFilters } from '@intus-ui';

const allOrgString = 'All Organizations';

const admitTypeMap = {
  'Acute Inpatient': 'IP',
  'Assisted Living Facility': 'ALF',
  'ED to Inpatient': 'ED/IP',
  'ED to Observation': 'ED/OBS',
  'ED Treat-and-Release': 'ED',
  Hospice: 'HOSP',
  'Inpatient Psychiatric': 'IP PSYCH',
  'Inpatient Rehabilitation': 'REHAB',
  'Long Term Care': 'LTC',
  'Long Term Acute Care': 'LTAC',
  Observation: 'OBS',
  Other: 'U',
  Respite: 'RES',
  'Skilled Nursing Facility': 'SNF',
};

const trinityAdmitTypeMap = {
  'Inpatient Admissions': 'IP',
  'ED Treat-and-Release': 'ED',
  Observation: 'OBS',
  'Skilled Nursing Facility': 'SNF',
  'Long Term Care': 'LTC',
  'Assisted Living Facility': 'ALF',
  Respite: 'RES',
};

export const admitTypesList = Object.values(admitTypeMap);

const UtilizationContext = createContext();

export const Provider = ({ children }) => {
  const { healthSystemAdminOrganizations: allOrgs, selectedFilters } = useParticipantFilters();
  const [data, setData] = useState();
  const defaultStart = startOfDate(new Date(), 'month');
  const [orgId, setOrgId] = useState();
  const [orgName, setOrgName] = useState(allOrgString);
  const [admitsType, setAdmitsType] = useState(admitTypesList[0]);
  const { organization = 'All' } = selectedFilters;

  const [startDate, setFormattedStartDate] = useState(new Date(defaultStart));
  const [endDate, setFormattedEndDate] = useState(new Date());
  const setStartDate = (date) => setFormattedStartDate(new Date(startOfDate(date, 'month')));
  const setEndDate = (date) => setFormattedEndDate(new Date(endOfDate(date, 'month')));

  const session = getSessionUser();
  const { access: userAccess, organization: loggedInOrg } = session;
  const isHealthSystemAdmin = userAccess === 'HEALTHSYSTEMADMIN';
  const isTrinity = loggedInOrg?.name?.toLowerCase().startsWith('trinity');

  // FETCH UTILIZATION DATA
  const {
    data: utilizationData,
    loading,
    error,
    runQuery: fetchUtilizationData,
  } = useLazyQuery(() =>
    getUtilizationData({
      startDate: formatDate(startDate, 'YYYY-MM-DD'),
      endDate: formatDate(endDate, 'YYYY-MM-DD'),
      type: admitsType,
      orgIds: orgId || '',
    })
  );

  // SET DATA WHEN FETCHED
  useEffect(() => {
    setData(utilizationData);
  }, [setData, utilizationData]);

  // FETCH NEW DATA WHEN FILTERS CHANGE
  useEffect(() => {
    if (admitsType) fetchUtilizationData();
  }, [startDate, endDate, admitsType, orgId]);

  // FETCH NEW DATA WHEN ORG CHANGES
  useEffect(() => {
    const org = allOrgs.find((o) => o.id === Number(organization));
    setOrgName(org?.name || allOrgString);
    setOrgId(org?.id);
  }, [organization, setOrgName, setOrgId]);

  const contextValue = useMemo(() => {
    return {
      loading,
      error,
      data,
      setData,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      orgId,
      setOrgId,
      orgName,
      admitTypesList,
      admitsType,
      setAdmitsType,
      isHealthSystemAdmin,
      admitTypeMap: isTrinity ? trinityAdmitTypeMap : admitTypeMap,
    };
  }, [data, error, loading, admitsType, startDate, endDate, orgId, orgName]);

  return <UtilizationContext.Provider value={contextValue}>{children}</UtilizationContext.Provider>;
};

export const useUtilizationContext = () => {
  return useContext(UtilizationContext);
};

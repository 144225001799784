export const metrics = [
  {
    metricId: 'inpatient-admissions',
    metricConfig: {
      metricType: 'per100mm',
      showChart: true,
    },
    modal: {
      component: 'StatFocusView',
    },
  },
  {
    metricId: 'falls',
    metricConfig: {
      metricType: 'per100mm',
      showChart: true,
    },
    modal: {
      component: 'StatFocusView',
    },
  },
  {
    metricId: 'er-visits',
    metricConfig: {
      metricType: 'per100mm',
      showChart: true,
    },
    modal: {
      component: 'StatFocusView',
    },
  },
];

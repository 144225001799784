/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getPatientCarePlan } from '@api/api';
import { getLogger } from '@util/logger';

const log = getLogger('carePlanSlice');

const carePlanSlice = createSlice({
  name: 'carePlan',
  initialState: {
    error: undefined,
    carePlanInfo: undefined,
    selectedCarePlan: 0,
  },
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },

    setCarePlanInfo(state, action) {
      state.carePlanInfo = action.payload;
    },

    clearCarePlan(state) {
      state.carePlanInfo = undefined;
      state.selectedCarePlan = 0;
    },

    // Indicates which careplan we are selecting/viewing, based on array index
    setSelectedCarePlan(state, action) {
      state.selectedCarePlan = action.payload;
    },
  },
});

export default carePlanSlice.reducer;
export const { setError, setCarePlanInfo, clearCarePlan, setSelectedCarePlan } =
  carePlanSlice.actions;

export const getPatientCarePlanInfo = (id) => async (dispatch) => {
  dispatch(clearCarePlan());
  const errorMessage = 'Could not fetch Participant CarePlan Info.';
  try {
    const res = await getPatientCarePlan(id);
    if (res.ok) {
      const json = await res.json();
      dispatch(setCarePlanInfo(json));
    } else {
      dispatch(setError(errorMessage));
    }
  } catch (err) {
    log.error(err);
  }
};

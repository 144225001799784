import React, { useState, useEffect } from 'react';

import { Icon, Text } from '@intus-ui';
import { useHover, useOutsideClick } from '@intus-ui/hooks';
import './style.css';

const SearchInput = (props) => {
  const {
    placeholder = 'Search',
    handleSearch,
    onClose = () => {},
    searchTerm,
    closeIcon,
    disabled,
    onFocus = () => {},
    caption,
    success,
    error,
    containerStyle,
  } = props;
  const [hoverRef, isHovered] = useHover();
  const [isActive, setIsActive] = useState(false);

  const defaultContainerStyles = {
    ...styles.container,
    ...containerStyle,
  };

  // default styles
  const [style, setStyle] = useState({
    container: { ...defaultContainerStyles },
    icon: 'rgba(157, 157, 157, 1)',
    input: styles.input,
    captionColor: 'body',
  });

  // Sets isActive to false when search input is not clicked
  useOutsideClick(hoverRef, () => {
    setIsActive(false);
  });

  // set styles based on hover or active state
  useEffect(() => {
    if (disabled) {
      setStyle({
        container: { ...defaultContainerStyles, backgroundColor: '#EFF0F3' },
        icon: '#9D9D9D',
        input: { ...styles.input, color: '#C8C8C8', backgroundColor: '#EFF0F3' },
      });
    } else if (success) {
      setStyle({
        ...style,
        captionColor: 'success',
        icon: 'rgba(157, 157, 157, 1)',
        container: {
          ...defaultContainerStyles,
          border: '1px solid #4DC3CA',
          boxShadow: 'none',
        },
      });
    } else if (error) {
      setStyle({
        ...style,
        captionColor: 'error',
        icon: 'rgba(157, 157, 157, 1)',
        container: {
          ...defaultContainerStyles,
          border: '1px solid #CD3B3B',
          boxShadow: 'none',
        },
      });
    } else if (isActive)
      setStyle({
        container: {
          ...defaultContainerStyles,
          border: '1px solid #2E62E7',
          boxShadow: '0 0 0 2px #E4ECFF',
        },
        icon: '#2E62E7', // icon only changes color in active state
        input: styles.input,
      });
    else if (isHovered)
      setStyle({
        container: { ...defaultContainerStyles, backgroundColor: '#E4ECFF' },
        icon: 'rgba(157, 157, 157, 1)',
        input: styles.input,
      });
    else
      setStyle({
        container: defaultContainerStyles,
        icon: 'rgba(157, 157, 157, 1)',
        input: styles.input,
      });
  }, [searchTerm, error, success, disabled, isActive, isHovered]);

  const onFocusExt = () => {
    onFocus();
    setIsActive(true);
  };

  return (
    <div>
      <div className="searchinput-container" style={style.container} ref={hoverRef}>
        <Icon name="search" color={style.icon} style={{ minWidth: '22px' }} />
        <input
          style={style.input}
          type="text"
          placeholder={placeholder}
          onChange={(e) => handleSearch(e)}
          value={searchTerm}
          onFocus={onFocusExt}
          disabled={disabled}
        />
        {searchTerm !== '' && isActive && closeIcon ? (
          <Icon name="Remove" onClick={onClose} color={style.icon} style={{ minWidth: '22px' }} />
        ) : (
          <div style={{ minWidth: '22px' }} onFocus={onFocusExt} />
        )}
      </div>
      {caption && (
        <Text color={style.captionColor} type="caption">
          {caption}
        </Text>
      )}
    </div>
  );
};

const styles = {
  container: {},
  input: {
    all: 'unset',
    width: '100%',
  },
};
export default SearchInput;

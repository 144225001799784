import { useState, useEffect } from 'react';

import { getStringReplacement } from '@util/stringReplacements';
import { pluralize } from '@util/utilFunctions';

import { Text, Icon } from '@intus-ui';
import FilterRowSummary from './FilterRowSummary';

const Pills = ({ filter, summary, keyPrefix, focused }) => {
  const [groups, setGroups] = useState(filter?.groups || []);
  const [isExpanded, setIsExpanded] = useState(!!focused);

  const topOperator = filter?.groupsOperator;
  const isFilterGroup = groups?.length ? groups[0].operator : false;

  const handleExpandButton = () => {
    setIsExpanded(!isExpanded);
  };

  const hasMoreFilters = filter?.groups?.length > 2;
  const moreFiltersString = hasMoreFilters
    ? `+ ${filter.groups.length - 2} more ${pluralize('filter', filter.groups.length - 2)}`
    : '';

  useEffect(() => {
    const arr = isExpanded ? [...filter.groups] : [...groups].splice(0, 2);
    setGroups(arr);
  }, [focused, isExpanded]);

  return (
    <>
      {groups.map((condition, index) => {
        const isLast = index === groups.length - 1;
        const isFirst = index === 0;
        if (isFilterGroup) {
          return (
            <div key={`${keyPrefix}-${JSON.stringify(index)}`} style={styles.condition}>
              <div
                style={{
                  ...styles.conditionIntro,
                  ...((!isFirst || (!summary && isFirst)) && { ...styles.conditionIntroOperator }),
                }}
              >
                <Text type="body" color="caption">
                  {!summary && isFirst && `${getStringReplacement('Participant', {plural: true})} whose`}
                </Text>
                <Text color="body">{!isFirst && topOperator}</Text>
              </div>
              <div style={styles.roundedPill}>
                {condition.conditions.map((cdt, idx) => {
                  const isNestedLast = idx === condition.conditions.length - 1;
                  const isNestedFirst = idx === 0;
                  return (
                    <FilterRowSummary
                      key={JSON.stringify(cdt)}
                      isFirst={isFirst}
                      isLast={isLast}
                      isNestedFirst={isNestedFirst}
                      isNestedLast={isNestedLast}
                      condition={cdt}
                      operator={condition.operator}
                      topOperator={topOperator}
                    />
                  );
                })}
              </div>
            </div>
          );
        }

        return (
          <FilterRowSummary
            isFirst={isFirst}
            isLast={isLast}
            operator={condition.operator}
            topOperator={topOperator}
          />
        );
      })}

      {hasMoreFilters && !focused && (
        <Text
          type="subtitle"
          color={summary ? 'body' : 'link'}
          style={styles.expandButton}
          onClick={handleExpandButton}
        >
          {isExpanded ? 'see less' : `${moreFiltersString}`}
          {!summary && (
            <Icon
              name={isExpanded ? 'caret-up' : 'caret-down'}
              color="#2E62E7"
              hoverColor="#2E62E7"
            />
          )}
        </Text>
      )}
    </>
  );
};

const styles = {
  condition: {
    display: 'flex',
    alignItems: 'center',
  },
  conditionIntro: {
    flexWrap: 'nowrap',
  },
  conditionIntroOperator: {
    paddingRight: 10,
  },
  roundedPill: {
    flexWrap: 'wrap',
    backgroundColor: 'white',
    border: '1px solid #BACEFF',
    padding: '5px 5px 5px 10px',
    borderRadius: 20,
  },
  expandButton: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 6,
  },
};

export default Pills;

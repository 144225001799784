/* eslint-disable no-param-reassign */
import { dateDifference, newDate, subtractDate } from '@util/dateFunctions';

import { textColors } from '@intus-ui/styles/SecondaryColors';

import { ChartOptions } from 'chart.js';
import { getFeatureFlag } from '@config';
import { PROPORTION_VALUE } from './Context';

type ChartOptionsParams = {
  maxAcuity: number;
  earliestDate: Date | string;
  hoveredPoint: any;
  selectedPoint: any;
  setHoveredPoint: any;
  dateFilter: any;
  onClick: ChartOptions<'scatter'>['onClick'];
};

/**
 * Options specifically formatted for chartjs
 */
export const chartOptions = (chartOptions: ChartOptionsParams) => {
  const {
    maxAcuity,
    earliestDate,
    hoveredPoint,
    selectedPoint,
    setHoveredPoint,
    dateFilter,
    onClick,
  } = chartOptions;

  // Slightly relax date range, so that the first month shown is just after the y axis (more readable and corresponds with design)
  const amendedStart = dateFilter.start
    ? subtractDate(dateFilter.start, 1, 'day')
    : subtractDate(earliestDate, 1, 'day');

  const startDate = amendedStart.toDate();
  const endDate = dateFilter.end.toDate();
  const monthsCovered = dateDifference(endDate, startDate, 'month');

  
  const { featureIsActive: AcuityV2 } = getFeatureFlag('AcuityV2');
  const trendlineBottom = AcuityV2 ? -20 : 0;

  const customPluginOptions = {
    yAxisLimit: {
      maxAcuity,
    },
    drawDottedLines: {
      hoveredPoint,
      selectedPoint,
    },
    backgroundChartArea: {
      bottom: trendlineBottom,
      maxAcuity,
    },
    formatBadges: {
      hoveredPoint,
      selectedPoint,
    },
    highlightActiveBadge: {
      hoveredPoint,
    },
  };

  const options: ChartOptions<'scatter'> = {
    onClick,
    onHover: (event, elements, chart) => {
      const target = event.native!.target! as HTMLCanvasElement;

      if (elements.length) {
        const { datasetIndex } = elements[0];

        // IF HOVERED OVER THE ACUITY CHANGES, DO NOTHING
        if (datasetIndex === 0) return;

        const { index } = elements[0];
        const point = chart.config.data.datasets[datasetIndex].data[index] as any as {
          eventType: string;
          visible: boolean;
        };

        // IGNORE HIDDEN POINTS
        if (point.eventType === 'acuityScore' && !point.visible) {
          target.style.cursor = 'default';
          setHoveredPoint();
        } else {
          target.style.cursor = 'pointer';
          setHoveredPoint(point);
        }
      } else {
        target.style.cursor = 'default';
        if (hoveredPoint) setHoveredPoint();
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    plugins: {
      ...customPluginOptions,
      tooltip: {
        backgroundColor: 'white',
        yAlign: 'bottom',
        caretPadding: 15,
        xAlign: 'center',
        bodyFont: {
          family: "'Inter', 'Arial', sans-serif",
        },
        caretSize: 8,
        padding: {
          x: 15,
          y: 15,
        },
        displayColors: false,
        callbacks: {
          label() {
            return [
              'This spot marks an update to the',
              'Acuity Risk Model, which is a result',
              'of our algorithm learning. Some',
              'change in score may occur around',
              'this point. Model changes are rare,',
              'but important, as they allow us to',
              'better serve you and your IDT.',
              'Thank you!',
            ];
          },
          labelTextColor() {
            return '#404040';
          },
        },
        filter(tooltipItem) {
          const { datasetIndex } = tooltipItem;
          return datasetIndex === 0;
        },
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    showLine: true,
    scales: {
      x: {
        border: {
          display: true,
        },
        grid: {
          display: true,
          drawOnChartArea: false,
          lineWidth: 1,
          color: ['black'],
        },
        type: 'time',
        time: {
          unit: 'month',
        },
        min: amendedStart.toDate().valueOf(),
        max: endDate,
        ticks: {
          maxRotation: 0,
          callback: (value) => {
            const myDate = newDate(value);
            // SHOW ALL FOR A YEAR OR LESS
            if (monthsCovered <= 12) return myDate.format('MMM YYYY');

            // TRIM TO ONLY SHOW SOME LABELS FOR ALL TIME
            const skipCount = Math.floor(monthsCovered / 12);
            const monthsSinceStart = dateDifference(myDate, startDate, 'months');
            if (monthsSinceStart % skipCount === 0) return myDate.format('MMM YYYY');
            return undefined;
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: true,
          drawOnChartArea: false,
          lineWidth: 1,
          color: ['black'],
        },
        title: {
          display: true,
          text: '',
          font: {
            size: 15,
            family: 'Inter, Arial, sans-serif',
          },
          color: textColors.body,
        },
        min: trendlineBottom,
        max: maxAcuity * PROPORTION_VALUE,
        ticks: {
          font: {
            weight: 'bold',
          },

          callback: (value) => {
            if ((value as number) > maxAcuity) return undefined;
            return value;
          },
        },
      },
    },
  };
  return options;
};

import React from 'react';
import { Row } from 'react-bootstrap';
import '@intus-ui/styles/Utilities.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextData from 'Dashboard/QuadContent/TextData';
import SpinnerOrError from '@intus-ui/components/SpinnerOrError';
import BigTitle from 'Dashboard/QuadContent/BigTitle';
import { moneyAbbreviate } from '@util/utilFunctions';

const FinPreview = ({ content, error }) => (
  <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center py-3">
    <div className="pb-2 d-flex flex-column align-items-center">
      <BigTitle title="External Cost (Most Recent)" />
      <Row className="w-100 h-100 d-flex flex-column align-items-center justify-content-center pt-3">
        {content ? <TextData data={moneyAbbreviate(content)} /> : <SpinnerOrError error={error} />}
      </Row>
    </div>
  </div>
);

FinPreview.propTypes = {
  content: PropTypes.number,
  error: PropTypes.string,
};

FinPreview.defaultProps = {
  content: undefined,
  error: undefined,
};

const mapState = (state) => ({
  error: state.homepage.homeError,
});

export default connect(mapState)(FinPreview);

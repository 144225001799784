// types of pills available

export const pillTypes = {
  default: {
    initialState: {
      cursor: 'default',
      backgroundColor: '#FFFFFF',
      borderColor: '#BACEFF',
      // text colors should match textColors keys
      textColor: 'body',
    },
    hoverState: {
      cursor: 'default',
      backgroundColor: '#FFFFFF',
      borderColor: '#BACEFF',
      // text colors should match textColors keys
      textColor: 'body',
    },
  },
  group: {
    initialState: {
      cursor: 'default',
      backgroundColor: '#89C4E9',
      borderColor: '#007BC7',
      textColor: '#FFFFFF',
    },
    hoverState: {
      cursor: 'default',
      backgroundColor: '#89C4E9',
      borderColor: '#007BC7',
      textColor: '#FFFFFF',
    },
  },
};

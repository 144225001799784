import { useDispatch } from 'react-redux';
import BoxOnBlue from '@intus-ui/components/BoxOnBlue';
import { useHistory, useLocation } from 'react-router-dom';
import { useTwoFactorValidateMutation } from '@api/index';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import TwoFactorCode from './TwoFactorCode';
import onSuccessfulLogin from './onSuccessfulLogin';

const TwoFactorValidation = () => {
  const [errorText, setErrorText] = useState(null);
  const { state } = useLocation();
  const { jwt, allowsRememberMe } = state;
  const [validate, validateResult] = useTwoFactorValidateMutation();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);

  const [rememberMeChecked, setRememberMeChecked] = useState(false);
  const rememberMe = () => (
    <Form.Check
      className="mt-3"
      checked={rememberMeChecked}
      onChange={(e) => setRememberMeChecked(e.target.checked)}
      type="checkbox"
      label="Remember me for 30 days"
    />
  );

  return (
    <BoxOnBlue logo>
      <div>Please enter the current six-digit code from your authenticator app.</div>
      <TwoFactorCode
        className="mt-3"
        disabled={validateResult.isLoading}
        onChange={() => setErrorText(null)}
        onAllDigitsEntered={(code) => {
          validate({ token: code, jwt, rememberMe: rememberMeChecked }).then(({ data }) => {
            if (data?.token) {
              onSuccessfulLogin({
                dispatch,
                history,
                queryParams,
                result: data,
              });
            } else {
              setErrorText('Validation failed. Try again with the most recent token.');
            }
          });
        }}
      />
      {allowsRememberMe && rememberMe()}
      {errorText && <div className="mt-3 text-danger">{errorText}</div>}
    </BoxOnBlue>
  );
};

export default TwoFactorValidation;

import React from 'react';
import { Example } from './Example';
import styles from './Example.module.css';

export type ExampleModel = {
  text: string;
  value: string;
};

const EXAMPLES: ExampleModel[] = [
  {
    text: 'What are the requirements for a Quality Plan in PACE?',
    value: 'What are the requirements for a Quality Plan in PACE?',
  },
  {
    text: 'What advice do you have for managing a complex patient with diabetes, in detail?',
    value: 'What advice do you have for managing a complex patient with diabetes, in detail?',
  },
];

interface Props {
  onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
  return (
    <ul className={styles.examplesNavList}>
      {EXAMPLES.map((x, i) => (
        <li key={i}>
          <Example text={x.text} value={x.value} onClick={onExampleClicked} />
        </li>
      ))}
    </ul>
  );
};

export const gridContainer = {
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  padding: '0px 20px 0px 20px',
  gap: '10px',
  overflowY: 'auto',
};

export const chartColors = ['rgba(77, 195, 202, 0.75)'];

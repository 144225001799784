import React from 'react';
import { Text } from '@intus-ui';
import { adjustColorBrightness } from '@intus-ui/components/Widget/components/chartConfig';
import { defaultChartColors } from '@intus-ui/styles/SecondaryColors';

const BarChartTooltip = ({
  dataIndex = 0,
  targets,
  chartDataConfig,
  chartColor,
  top5PrincipalDxData,
}) => {
  const { labels = [], datasets = [] } = chartDataConfig;
  const { label, data = [] } = datasets[0] || {};
  const barLabel = (labels && labels[dataIndex]) || 'N/A';
  let datasetLabel = `${label}:` || 'N/A:';
  let barValue = (data && data[dataIndex]) || 'N/A';
  let target = targets && targets[dataIndex] ? parseFloat(targets && targets[dataIndex]) : null;
  let code = null;
  if (top5PrincipalDxData) {
    datasetLabel = `${top5PrincipalDxData[dataIndex].value} % of Admits`;
    code = `Code: ${top5PrincipalDxData[dataIndex].code}`;
    barValue = '';
    target = '';
  }

  let pctToTarget;
  let pctToTargetSuffix = '';
  if (target > 0) {
    pctToTarget = 100 - Math.round((barValue / target) * 100);
    if (pctToTarget > 0) {
      pctToTargetSuffix = `under target`;
    } else {
      pctToTargetSuffix = `over target`;
    }
  }

  const metricColor = defaultChartColors[chartColor] || '#fff';
  const varianceColor = barValue > target ? 'countRed' : 'countGreen';

  return (
    <div style={styles.barContainer}>
      <div style={styles.barValueContainer}>
        <ColorIndicator metricColor={metricColor} />
        <Text type="caption-bold" style={styles.barLabel}>
          {barLabel}
        </Text>
      </div>
      <div style={styles.barValueContainer}>
        <Text type="caption-bold" style={styles.barValueLabel}>
          {datasetLabel}
        </Text>
        <Text type="caption" style={styles.barValue}>
          {barValue.toLocaleString()}
          {datasetLabel.toLowerCase() === 'length of stay:' ? ' days' : ''}
        </Text>
      </div>

      {code && (
        <div style={styles.barValueContainer}>
          <Text type="caption" style={styles.barLabel}>
            {code}
          </Text>
        </div>
      )}

      {target ? (
        <>
          <div style={styles.barValueContainer}>
            <Text type="caption-bold" style={styles.barValueLabel}>
              Target:
            </Text>
            <Text type="caption" style={styles.barValue}>
              {target.toLocaleString()}
            </Text>
          </div>
          <div style={styles.barValueContainer}>
            <Text type="caption-bold" style={styles.barValueLabel}>
              Variance:
            </Text>
            <Text type="caption" style={styles.barValue}>
              {(target - barValue).toLocaleString()}
            </Text>
          </div>
          {pctToTarget && (
            <div style={styles.barValueContainer}>
              <Text type="caption-bold" style={styles.barValueLabel} color={varianceColor}>
                {`${Math.abs(pctToTarget).toLocaleString()}% ${pctToTargetSuffix}`}
              </Text>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

const styles = {
  barContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  colorIndicator: {
    width: '16px',
    height: '16px',
  },
  barLabel: {
    type: 'caption-bold',
  },
  barValueContainer: {
    display: 'flex',
    gap: 5,
  },
  barValueLabel: {
    type: 'caption-bold',
  },
  barValue: {
    type: 'caption',
  },
};

const ColorIndicator = ({ metricColor = '#FFFF' }) => {
  return (
    <div
      style={{
        width: '16px',
        height: '16px',
        backgroundColor: adjustColorBrightness(metricColor, 0.5),
        border: `1px solid ${metricColor}`,
      }}
    />
  );
};

export default BarChartTooltip;

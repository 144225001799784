import { getStringReplacement } from '@util/stringReplacements';

import SortIcon from '@intus-ui/components/List/SortIcon';
import Text from '@intus-ui/components/Text';

/**
 * A list of all possible fields with their corresponding names, styles, and addOns
 * columnConfig selects specific fields from this to display
 * NOTE: *THIS* is where display order is controlled, not columnConfigs!
 */
export const format = () => {
  return [
    {
      field: 'name',
      name: 'Medication Name',
      flex: '1 1 250px',
      bg: 'orange',
      addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
      render(row) {
        const { item } = row;
        return (
          <div style={{ display: 'flex', flex: 3, justifyContent: 'start', whiteSpace: 'normal' }}>
            <Text type="subtitle" style={{ overflow: 'wrap' }}>
              {item?.name}
            </Text>
          </div>
        );
      },
    },
    {
      field: 'fdaClass',
      name: 'FDA Class',
      flex: 1,
      bg: 'lightpink',
      align: 'start',
      textProperties: { type: 'body' },
    },
    {
      field: 'strength',
      name: 'Strength',
      flex: '0 0 95px',
      bg: 'lightgreen',
      align: 'end',
      textProperties: { type: 'body', style: { textAlign: 'right' } },
      render: (row) => {
        const { item } = row;
        return (
          <div style={{ display: 'flex', flex: 3, whiteSpace: 'normal', textAlign: 'end' }}>
            <Text type="body" style={{ overflow: 'wrap', textAlign: 'end' }}>
              {item?.strength}
            </Text>
          </div>
        );
      },
    },
    {
      field: 'sig',
      name: getStringReplacement('Instructions'),
      flex: 2,
      bg: 'lightblue',
      align: 'start',
      textProperties: { type: 'body' },
    },
    {
      field: 'riskFactors',
      name: 'Risk Factors',
      flex: 2,
      bg: 'green',
      align: 'start',
      textProperties: { type: 'body' },
      // TO DO: add back in once we have risk factors type
      // addOns: [{ type: 'filter', element: <RiskFactorsFilter /> }],
    },
    {
      field: 'dateOrdered',
      name: 'Date Ordered',
      flex: '0 0 150px',
      align: 'center',
      bg: 'lightyellow',
      addOns: [{ type: 'sort', element: <SortIcon key="dateOrdered" id="dateOrdered" /> }],
      textProperties: { type: 'body' },
      sortType: 'date',
    },
    {
      field: 'metricQuantity',
      name: 'Quantity',
      flex: '0 0 80px',
      align: 'end',
      bg: 'lightyellow',
      textProperties: { type: 'body' },
      render: (row) => {
        const medication = row.item;

        let quantity = medication?.metricQuantity;

        if (quantity != null) {
          quantity = parseFloat(quantity);
        }

        return <Text>{quantity}</Text>;
      },
    },
    {
      field: 'dateFilled',
      name: 'Date Filled',
      flex: '0 0 150px',
      align: 'center',
      bg: 'lightyellow',
      textProperties: { type: 'body' },
      addOns: [{ type: 'sort', element: <SortIcon key="dateFilled" id="dateFilled" /> }],
      render: (row) => {
        return <Text>{row.item.dateFilled}</Text>;
      },
    },
    {
      field: 'daysSupply',
      name: 'Days Supply',
      flex: '0 0 95px',
      align: 'end',
      bg: 'lightyellow',
      textProperties: { type: 'body' },
    },
    {
      field: 'pdc',
      name: 'PDC',
      flex: '0 0 65px',
      align: 'end',
      bg: 'lightyellow',
      textProperties: { type: 'body' },
      addOns: [{ type: 'sort', element: <SortIcon key="pdc" id="pdc" /> }],
      render: (row) => {
        return <Text> {parseFloat((row.item.pdc * 100).toFixed(0))}%</Text>;
      },
    },
    {
      field: 'physician',
      name: 'Prescriber',
      flex: '0 0 300px',
      align: 'start',
      bg: 'lightyellow',
      textProperties: { type: 'body' },
      addOns: [{ type: 'sort', element: <SortIcon key="physician" id="physician" /> }],
      render: (row) => {
        const { item } = row;
        return (
          <div style={{ display: 'flex', flex: 3, justifyContent: 'start', whiteSpace: 'normal' }}>
            <Text type="body" style={{ overflow: 'wrap' }}>
              {item?.physician}
            </Text>
          </div>
        );
      },
    },
  ];
};

import { connect } from 'react-redux';
import { Formik } from 'formik';

import { getUserInfo } from '@global-state/redux/userSettingsSlice';

import { Form, Col } from 'react-bootstrap';
import FormikFormControl from '@intus-ui/components/forms/FormikReactBootstrap/FormikFormControl';
import SettingsBox from './SettingsBox';

import userSettingsPropType, { USER_ACCESS_CAREGIVER } from './userSettingsPropType';

const AccountSettingsBox = ({ userSettings }) => {
  const { profile } = userSettings;

  if (!profile) {
    return null;
  }

  const profileCopy = { ...profile };
  if (profileCopy.access === USER_ACCESS_CAREGIVER) profileCopy.access = 'USER';

  return (
    <SettingsBox title="Account Info" adminAccess={false}>
      {() => (
        <Formik
          initialValues={{
            ...profileCopy,
            status: 'ACTIVE',
            appVersion: process.env.REACT_APP_VERSION,
          }}
          validateOnChange
        >
          <Form>
            <Form.Row>
              <FormikFormControl
                as={Col}
                md="6"
                label="Access"
                type="text"
                name="access"
                disabled
              />
              <FormikFormControl
                as={Col}
                md="6"
                label="Status"
                type="text"
                name="status"
                disabled
              />
            </Form.Row>
            <Form.Row>
              <FormikFormControl
                as={Col}
                md="6"
                label="Application Version"
                type="text"
                name="appVersion"
                disabled
              />
            </Form.Row>
          </Form>
        </Formik>
      )}
    </SettingsBox>
  );
};

AccountSettingsBox.propTypes = {
  userSettings: userSettingsPropType,
};

AccountSettingsBox.defaultProps = {
  userSettings: undefined,
};

const mapState = (state) => ({
  userSettings: state.userSettings,
});

const mapDispatch = (dispatch) => ({
  doGetUserLocalData: () => dispatch(getUserInfo()),
});

export default connect(mapState, mapDispatch)(AccountSettingsBox);

export const styles: { [key: string]: React.CSSProperties } = {
  sidebarContentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '15px',
    padding: '20px 15px 15px 15px',
    backgroundColor: 'white',
  },
  generalAnalysisItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  analyticsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  analyticsItem: {
    height: 'fit-content',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#C8C8C8',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '7px 14px',
    gap: 2.5,
  },
  analyticsStat: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'start',
    gap: '10px',
  },
  seeDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  hrLineStyle: {
    height: 0,
    width: '100%',
    margin: '0 0 0 15px',
    backgroundColor: '#C8C8C8',
  },
};

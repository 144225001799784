import { getSessionUser } from './session';

/**
 * Validates whether or not the user has route access based on input object
 */
const authenticateRoute = ({
  subscriptionType = undefined,
  permittedAccess = undefined,
  requiredFlag = undefined,
}) => {
  const session = getSessionUser();

  if (session && session?.access) {
    const {
      organization: { subscriptions },
      access,
      flags,
    } = session;
    const isSubscribed = subscriptionType ? subscriptions.includes(subscriptionType) : true;
    const hasAccess = permittedAccess ? permittedAccess.includes(access) : true;
    const hasRequiredFlag = requiredFlag ? flags?.includes(requiredFlag) : true;

    return isSubscribed && hasAccess && hasRequiredFlag;
  }

  return false;
};

export default authenticateRoute;

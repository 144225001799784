import PropTypes from 'prop-types';

export const FinModalPropType = PropTypes.shape({
  externalCostTimeLine: PropTypes.arrayOf(
    PropTypes.shape({
      externalCost: PropTypes.shape({
        month: PropTypes.number,
        cost: PropTypes.number,
      }),
      timeStamp: PropTypes.string,
    })
  ),
  averageCost: PropTypes.shape({
    avg: PropTypes.shape({ month: PropTypes.number, cost: PropTypes.number }),
  }),
  reimbursementType: PropTypes.shape({
    privatePay: PropTypes.number,
    medicare: PropTypes.number,
    dual: PropTypes.number,
    medicaid: PropTypes.number,
  }),
  costBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      amount: PropTypes.number,
      percent: PropTypes.number,
    })
  ),
});

export default FinModalPropType;

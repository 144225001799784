import { every, size, isArray } from 'lodash';
import * as pluralizeLib from 'pluralize';
import { getLogger } from '@util/logger';
import { isValidElement } from 'react';
import { formatDate, newDate, subtractDate, isAfterDate, isSameDate } from './dateFunctions';

const log = getLogger('utilFunctions');

/**
 * Converts a camelCase string to a Sentence Case string (ie: 'theDog' -> 'The Dog')
 * @param {String} x - the camelCase string to convert
 */
export const toSentenceCase = (x = '') => {
  const result = x.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * Converts kebab-case strings to a Pascal Case string (ie: 'arrow-down' -> 'ArrowDown')
 * @param {String} x - a kebab-case string to convert
 */
export const toPascalCase = (text) => {
  return text.replace(/(^\w|-\w)/g, clearAndUpper);
};

/**
 * Checks if all values in an array are 0 or if the array is empty.
 * @param {Array} dataArray - The array to check
 * @returns {Boolean} - True if all values are 0 or the array is empty, otherwise false
 */
export const isAllZeroOrEmpty = (dataArray) => {
  // Check if dataArray is not an array and return false
  if (!isArray(dataArray)) {
    return false;
  }
  return size(dataArray) === 0 || every(dataArray, (value) => value === 0);
};

/**
 * Converts camelCaseText to Title Case Text (ie: 'arrowDown' -> 'Arrow Down')
 * @param {String} x - a camelCaseText string to convert
 */
export const toTitleCase = (text = '') => {
  // safeguard against null values
  if (typeof text !== 'string' || text instanceof String) return text;

  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const clearAndUpper = (text) => {
  return text.replace(/-/, '').toUpperCase();
};

/**
 * Capitalizes the first letter in a string and lowercases the rest
 * @param {String} x - the string to convert
 */
export const capitalizeFirst = (str) => {
  return str?.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

/**
 * Capitalizes the first letter in every word in a sentence
 * @param {string} sentence
 * @returns {string} Title
 */
export const sentenceToTitle = (sentence) => {
  // Split the sentence into words
  const words = sentence.split(' ');

  // Loop through each word and capitalize the first letter
  const titleCaseWords = words.map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase());

  // Join the title case words back into a sentence
  const titleCaseSentence = titleCaseWords.join(' ');

  return titleCaseSentence;
};

/**
 * Converts a large number to its abbreviation.
 * Returns a number or string with no more than one decimal place.
 * LIMIT: 999 Trillion 999 Billion 999 Million 999 Thousand 999
 * (ie: 1100000 -> '1.1M')
 * @param {Number} n - the number to be abbreviated
 * @param {Number} decimalPlaces - the number of decimals to display
 *                                 if minimizeDecimalPlaces is not true
 * @param {Boolean} minimizeDecimalPlaces - when this is true, no more than
 *                                          one decimal place will be returned
 */
export const abbreviateNumber = (n, decimalPlaces, minimizeDecimalPlaces) => {
  // eslint-disable-next-line no-param-reassign
  n = Math.round(n);
  if (n >= 1e3 && n < 1e6) {
    const val = n / 1e3;
    if (val > 10 && minimizeDecimalPlaces) return `${+(n / 1e3).toFixed(0)}K`;
    return `${+(n / 1e3).toFixed(decimalPlaces)}K`;
  }
  if (n >= 1e6 && n < 1e9) {
    const val = n / 1e6;
    if (val > 10 && minimizeDecimalPlaces) return `${+(n / 1e6).toFixed(0)}M`;
    return `${+(n / 1e6).toFixed(decimalPlaces)}M`;
  }
  if (n >= 1e9 && n < 1e12) {
    const val = n / 1e9;
    if (val > 10 && minimizeDecimalPlaces) return `${+(n / 1e9).toFixed(0)}B`;
    return `${+(n / 1e9).toFixed(decimalPlaces)}B`;
  }
  if (n >= 1e12 && n < 1e15) {
    const val = n / 1e12;
    if (val > 10 && minimizeDecimalPlaces) return `${+(n / 1e12).toFixed(0)}T`;
    return `${+(n / 1e12).toFixed(decimalPlaces)}T`;
  }
  return n;
};

/**
 * Converts an array of numbers into a total sum (ie: [1, 2, 3] -> 6)
 * @param {Array} arr - the array of numbers to be summed
 */
export const sumUp = (arr) => {
  let sum = 0;
  arr?.forEach((item) => {
    sum += item;
  });
  return sum;
};

/**
 * Converts a Number to a readable String version of that number (ie: 1000000 -> '1,000,000')
 * @param {Number} x - the number to be converted
 */
export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * Converts a Number to a readable string with a money sign in front of it
 * (ie: 1000000 -> '$1,000,000')
 * @param {Number} x - the number to be converted
 */
export const moneyWithCommas = (x) => `$${numberWithCommas(x)}`;

/**
 * Converts a large Number to its abbreviation with a money sign in front of it
 * LIMIT: 999 Trillion 999 Billion 999 Million 999 Thousand 999
 * (ie: 1100000 -> '$1.1M')
 * @param {Number} n - The number to be converted
 * @param {Number} decimalPlaces - the number of decimals to display
 *                                 if minimizeDecimalPlaces is not true
 * @param {Boolean} minimizeDecimalPlaces - when this is true, no more than
 *                                          one decimal place will be returned
 */
export const moneyAbbreviate = (n, decimalPlaces, minimizeDecimalPlaces) =>
  `$${abbreviateNumber(n, decimalPlaces, minimizeDecimalPlaces)}`;

/**
* for getting a list of months before (good for charts axes)
* @param {Number} monthsBefore -# of months in past - the string indicating how long in past
RETURN: an array of months in string format
*/
export const getPrevMonths = (monthsBefore) => {
  const monthArray = [];
  let monthsCounter = monthsBefore;
  while (monthsCounter >= 0) {
    monthArray.push(formatDate(subtractDate(newDate(), monthsCounter, 'months'), 'MMM YYYY'));
    monthsCounter -= 1;
  }
  return monthArray;
};

/**
 * Sequentially applies an async callback function to each element of an array.
 * Useful for making api calls to a group of items in a specific order.
 *
 * @param {Array} array the array to iterate over
 * @param {Function} callback the function to apply to each element in the array
 */
export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
};

/**
 * Returns true if the content of two arrays is the same.

 * Note: Only works when array values are primitives (ie strings, numbers)
 * @param {Array} a the first array
 * @param {Array} b the second array
 */
export const arrayEquals = (a, b) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val, index) => val === b[index]);

/**
 * Returns the string argument, truncated to n character length with '...' at end.

 * @param {String} a the string to be truncated
 * @param {Number} b the desired # of characters
 */

export const truncate = (str, n, ellipse) =>
  str.length > n
    ? `${str.substr(0, Math.min(n, str.substr(0, n).lastIndexOf(' ')))}${ellipse ? '...' : ''}`
    : str;

/**
 * Returns a string argument with no punctuation and dashes in between words. Originally created for full story css-selectors for specific elements

 * @param {String} toConvert the string to be converted to a class name
 */

export const stringToClassname = (toConvert) => {
  return toConvert.replace(/_|,/g, ' ').replace(/\s+/g, '-').toLowerCase();
};

/**
 * Returns a string argument with only the first character capitalized

 * @param {String} toConvert the string to be converted
 */
export const capitalizeFirstChar = (toConvert = '') => {
  const str = toConvert.toLowerCase();
  return str.charAt(0).toUpperCase() + toConvert.slice(1);
};

/**
 * Returns array elements that starts with or includes the keyword

 * @param {String} keyword the search string
 * @param {Array} array the array of strings to search through
 * @param {String} arrayProp in case of array of objects, this represents the prop with the comparison string values
 */

export const getAgeFromDateOfBirthString = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export const searchFilter = (keyword, array, arrayProp) => {
  const searchTerm = keyword.replace(/\s+$/, ''); // removes trailing spaces
  const first = [];
  const others = [];
  for (let i = 0; i < array.length; i += 1) {
    if (arrayProp) {
      if (array[i][arrayProp].toLowerCase().startsWith(searchTerm.toLowerCase())) {
        first.push(array[i]);
      } else if (array[i][arrayProp].toLowerCase().includes(searchTerm.toLowerCase())) {
        others.push(array[i]);
      }
    } else if (array[i].toLowerCase().startsWith(searchTerm.toLowerCase())) {
      first.push(array[i]);
    } else if (array[i].toLowerCase().includes(searchTerm.toLowerCase())) {
      others.push(array[i]);
    }
  }
  first.sort((a, b) => (arrayProp ? a[arrayProp].localeCompare(b[arrayProp]) : a - b));
  others.sort((a, b) => (arrayProp ? a[arrayProp].localeCompare(b[arrayProp]) : a - b));
  const results = first.concat(others);
  return results;
};

/**
 * Converts a string to the plural form as required, based on the number provided (ie: 'time' -> 'times')
 * @param {String} string - the string to pluralize
 * @param {Number} number - the number to determine pluralized form
 * @param {Boolean} returnNumber - if true, return the number as well as the pluralized string
 */
export const pluralize = (string, number, returnNumber = false) => {
  return pluralizeLib(string, number, returnNumber);
};

/**
 * Returns a new array of elements sorted by date using the passed in dateKey
 * @param {Array} array - the array of values to sort
 * @param {String} dateKey - the key value by which to sort the array e.g. "createdAt"
 * @param {String} direction - the direction by which to sort the array. Can be desc or ascend. Defaults to desc.
 */
export const sortByDate = (array, dateKey, direction = 'desc') => {
  const arrayCopy = [...array];

  let after;
  let before;

  if (direction === 'desc') {
    after = -1;
    before = 1;
  } else if (direction === 'asc') {
    after = 1;
    before = -1;
  } else {
    after = -1;
    before = 1;
    log.warn('Invalid direction value provided. Defaulting to desc order.');
  }

  const sortedArray = arrayCopy?.sort((a, b) => {
    if (isAfterDate(a[dateKey], b[dateKey])) {
      return after;
    }
    if (isSameDate(a[dateKey], b[dateKey])) {
      return 0;
    }
    return before;
  });
  return sortedArray;
};

export const removeReactElementsFromObj = (obj) => {
  const objCopy = { ...obj };
  Object.keys(objCopy).forEach((key) => {
    if (isValidElement(obj[key])) delete objCopy[key];
    if (Array.isArray(obj[key])) delete objCopy[key];
  });
  return objCopy;
};

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { postPatientListAPI } from '@api';
import { ParticipantList } from '@intus-ui';

import { frequentFallersConfig } from '@intus-ui/components/ParticipantList/list/columnConfigs';
import { useDispatch } from 'react-redux';
import { setPage } from '@global-state/redux/profileSlice';

const FrequentFallersParticipantList = ({ data }) => {
  const history = useHistory();
  const [participantList, setParticipantList] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const pptIds = data.map((ppt) => ppt.id);
    postPatientListAPI({ patientList: [...pptIds] }).then(async (result) => {
      if (result.ok) {
        const json = await result.json();
        const pptListWithCount = json.map((ppt) => {
          const matchingPpt = data?.find((item) => item?.id === ppt.id);
          return { ...ppt, count: matchingPpt.count || 'Unknown' };
        });
        setParticipantList(pptListWithCount);
        setLoading(false);
      }
    });
  }, [data]);

  return (
    <ParticipantList
      searchable={false}
      participantList={participantList}
      customColumnConfig={frequentFallersConfig}
      onClickItem={(ppt) => {
        dispatch(setPage('Falls'));

        history.push(`/patient/${ppt.id}/falls`);
      }}
      defaultSort={{ field: 'instanceCount', direction: 'desc' }}
      loading={loading}
    />
  );
};

export default FrequentFallersParticipantList;

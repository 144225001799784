/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { asyncForEach } from '@util/utilFunctions';
import { getLogger } from '@util/logger';
import { getSessionUser } from '@util/session';

import { getCustomDashIndicatorsAPI, getOrgDashIndicatorsAPI } from '@api/api';

import { CUSTOM_DASH, ORG_DASH } from '../dashDataPropTypes/currentDashPropType';

const log = getLogger('spreadsheetModalSlice');

const spreadsheetModalSlice = createSlice({
  name: 'spreadsheetModal',
  initialState: {
    loadingStatus: 'not loaded',
    customItems: [],
    orgItems: [],
    mainCheckboxes: {},
    sectionCheckboxes: {},
  },
  reducers: {
    setLoadingStatus: (state, action) => {
      state.loadingStatus = action.payload;
    },
    addDashboard: (state, action) => {
      const { dashName, indicators, type, id } = action.payload;
      state.mainCheckboxes[id] = false;
      const dashInfo = { id, dashName, indicators };
      state.sectionCheckboxes[id] = dashInfo;
      if (type === ORG_DASH) state.orgItems = [id].concat(state.orgItems);
      if (type === CUSTOM_DASH) state.customItems = [id].concat(state.customItems);
    },
    removeSpreadsheetModalDashboard: (state, action) => {
      state.loadingStatus = 'loading';
      const { id, type } = action.payload;
      const newMain = { ...state.mainCheckboxes };
      const newSection = { ...state.sectionCheckboxes };
      delete newMain[id];
      delete newSection[id];
      if (type === ORG_DASH) {
        const index = state.orgItems.indexOf(id);
        if (index >= 0) {
          const newCustomItems = [...state.orgItems];
          newCustomItems.splice(index, 1);
          state.orgItems = newCustomItems;
        }
      } else if (type === CUSTOM_DASH) {
        const index = state.customItems.indexOf(id);
        if (index >= 0) {
          const newCustomItems = [...state.customItems];
          newCustomItems.splice(index, 1);
          state.customItems = newCustomItems;
        }
      }

      state.mainCheckboxes = newMain;
      state.sectionCheckboxes = newSection;
      state.loadingStatus = 'loaded';
    },
    updateDashboard: (state, action) => {
      const { id, dashName, indicators, type } = action.payload;
      if (type === ORG_DASH && !state.orgItems.includes(id)) {
        state.orgItems = [id].concat(state.orgItems);
      } else if (type === CUSTOM_DASH && !state.customItems.includes(id)) {
        state.customItems = [id].concat(state.customItems);
      }

      state.mainCheckboxes[id] = false;
      state.sectionCheckboxes[id] = { id, dashName, indicators };
      state.loadingStatus = 'loaded';
    },
    toggleMainCheckbox: (state, action) => {
      state.mainCheckboxes[action.payload] = !state.mainCheckboxes[action.payload];
    },
    resetMainCheckboxes: (state) => {
      const newMain = {};
      Object.keys(state.mainCheckboxes).forEach((dashId) => {
        newMain[dashId] = false;
      });
      state.mainCheckboxes = newMain;
    },
  },
});

export default spreadsheetModalSlice.reducer;

export const {
  setLoadingStatus,
  addDashboard,
  removeSpreadsheetModalDashboard,
  updateDashboard,
  toggleMainCheckbox,
  resetMainCheckboxes,
} = spreadsheetModalSlice.actions;

/**
 * Meant to be used as a callback to asyncForEach. This will get the indicators of a dashboard
 * and dispatch those indicators to the reducer to build up the spreadsheet checkboxes of
 * the inputed dashboard item.
 *
 * @param {Object} item the dashboard to fetch the indicators from
 * @param {Function} dispatch the function used to dispatch an action
 */

const fetchDashItems = async (item, dispatch) => {
  if (item.indicators.length) {
    try {
      const userId = getSessionUser().id;
      const orgId = getSessionUser().organization.id;
      const res = item.orgDash
        ? await getOrgDashIndicatorsAPI(orgId, item.id)
        : await getCustomDashIndicatorsAPI(userId, item.id);
      if (res.ok) {
        const resJ = await res.json();
        const dashItem = {
          type: item.orgDash ? ORG_DASH : CUSTOM_DASH,
          id: item.id,
          dashName: item.name,
          indicators: resJ.dashboardIndicators.map(({ displayTitle, fetchInfo }) => ({
            displayTitle,
            fetchInfo: { ...fetchInfo },
          })),
        };
        dispatch(addDashboard(dashItem));
      } else dispatch(setLoadingStatus('error'));
    } catch (e) {
      log.error(e);
      dispatch(setLoadingStatus('error'));
    }
  }
};

/**
 * Initializes state for Spreadsheet Modal. Will retrieve all the names of
 * the indicators necessary for the modal.
 * @param {Object} flatIndicators an object with access to all indicators
 * @param {Array} dashboards the array of dashboards to fetch
 */
export const initializeSpreadsheetModal = (flatIndicators, dashboards) => async (dispatch) => {
  dispatch(setLoadingStatus('loading'));
  if (!flatIndicators) {
    dispatch(setLoadingStatus('error'));
    return;
  }
  if (!Object.keys(flatIndicators).length) {
    dispatch(setLoadingStatus('error'));
    return;
  }

  // custom and org dashboards
  asyncForEach(dashboards, (item) => fetchDashItems(item, dispatch)).then(() =>
    dispatch(setLoadingStatus('loaded'))
  );
};

export const updateSpreadsheetModalDashboard = (updateInfo) => async (dispatch) => {
  const {
    id,
    changes: { name, indicators },
    orgDash,
  } = updateInfo;
  if (indicators && !indicators?.length) {
    // case where we remove all indicators
    dispatch(removeSpreadsheetModalDashboard(id));
    return;
  }
  try {
    dispatch(setLoadingStatus('loading'));
    const userId = getSessionUser().id;
    const orgId = getSessionUser().organization.id;
    const res = orgDash
      ? await getOrgDashIndicatorsAPI(orgId, id)
      : await getCustomDashIndicatorsAPI(userId, id);
    if (res.ok) {
      const resJ = await res.json();
      const dashItem = {
        type: orgDash ? ORG_DASH : CUSTOM_DASH,
        id,
        dashName: name,
        indicators: resJ.dashboardIndicators.map(({ displayTitle, fetchInfo }) => ({
          displayTitle,
          fetchInfo: { ...fetchInfo },
        })),
      };
      dispatch(updateDashboard(dashItem));
    } else dispatch(setLoadingStatus('error'));
  } catch (e) {
    log.error(e);
    dispatch(setLoadingStatus('error'));
  }
};

import { getSessionUser } from '@util/session';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const UnsavedChangesModal = ({
  when: displayModal,
  message = 'Are you sure you want to leave this page?',
  getMessage,
  shouldAllowNavigation,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (displayModal) {
      // catches navigation change
      const unblock = history.block((location) => {
        // Allow navigating away if the user has been logged out.
        // This prevents them from getting trapped if their session times out.
        if (
          getSessionUser() == null ||
          (shouldAllowNavigation && shouldAllowNavigation(location) === true)
        ) {
          return true;
        }
        return getMessage != null ? getMessage() : message;
      });

      // catches page reload
      const blockPageReload = (event) => {
        // Allow navigating away if the user has been logged out.
        // This prevents them from getting trapped if their session times out.
        if (getSessionUser() != null) {
          event.preventDefault();
          event.returnValue = getMessage != null ? getMessage() : message;
        }
      };

      window.addEventListener('beforeunload', blockPageReload);
      return () => {
        window.removeEventListener('beforeunload', blockPageReload);
        unblock();
      };
    }
    return null;
  }, [displayModal, message, getMessage]);

  return null;
};

export default UnsavedChangesModal;

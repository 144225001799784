import React, { CSSProperties, FC } from 'react';
import { Text, Divider } from '@intus-ui';
import SpinnerOrError from '../SpinnerOrError';
import './Card.scss';

type CardProps = {
  children: React.ReactNode;
  onClick?: () => void;
  dropshadow?: boolean;
  header?: React.ReactNode;
  hideHeaderDivider?: boolean;
  error?: string;
  loading?: boolean;
  fluid?: boolean;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  highlighted?: boolean;
};

const Card: FC<CardProps> = ({
  children,
  onClick,
  dropshadow,
  header,
  hideHeaderDivider,
  error,
  loading,
  fluid,
  style,
  contentStyle,
  highlighted,
}) => {
  // the baseStyle applies to the outer most div wrapper and controls width, height, display, padding, and border radius
  const baseStyle = style ? { ...defaultBaseStyle, ...style } : defaultBaseStyle;

  // typeStyle sets the base style for each type of card
  let typeStyle: CSSProperties = {};
  // typeClassName sets the state styling for interactions with each type of card
  let typeClassName;
  if (dropshadow) {
    typeStyle = dropshadowStyle;
    typeClassName = onClick ? 'dropshadowCard' : '';
  } else {
    typeClassName = onClick ? 'flatCard' : 'flatCardNonClickable';
  }

  const spinnerChild = (
    <div style={{ width: '100%', height: '100%', padding: fluid ? '0px' : '25px 45px' }}>
      <SpinnerOrError icon error={error} />
    </div>
  );

  return (
    <div
      onClick={onClick}
      onKeyDown={onClick}
      // TODO: what is this role ?
      role="none"
      style={{
        ...typeStyle,
        width: fluid ? '100%' : 'fit-content',
        border: highlighted ? '2px solid #2E62E7' : undefined,
        ...baseStyle,
      }}
      className={typeClassName}
    >
      {header && (
        <div>
          {typeof header === 'string' ? (
            <Text type="title" color={error ? 'caption' : 'navy'}>
              {header}
            </Text>
          ) : (
            header
          )}
          {!hideHeaderDivider && <Divider />}
        </div>
      )}
      <div style={contentStyle}>{error || loading || !children ? spinnerChild : children}</div>
    </div>
  );
};

const defaultBaseStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 15px',
  borderRadius: '10px',
};

const dropshadowStyle = {
  boxShadow: '2px 2px 16px 0px rgba(157, 157, 157, 0.22)',
};

export default Card;

import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, layout, SearchInput, SpinnerOrError, Text } from '@intus-ui';

import { InitiativesSideBar } from 'Initiatives/InitiativesListView/InitiativesSideBar';
import { input } from '@intus-ui/styles/SecondaryColors';
import { useQuery } from '@api/useQuery';
import { getInitiatives } from '@api/initiatives';
import { InitiativesListCard } from 'Initiatives/InitiativesListView/InitiativesListCard';
import * as userTracking from '@util/userTracking';

const InitiativesListView = () => {
  const history = useHistory();
  const [searchKeyword, setSearchKeyword] = useState('');

  const { data, loading, error } = useQuery(() => getInitiatives());

  const onCreateInitiative = () => {
    userTracking.logEvent('Initiatives: clicked "Create Initiative" button');
    sessionStorage.removeItem('initiativeFormValues');
    history.push('/initiatives/create');
  };

  // Filter the initiatives based on the search input field.
  const displayedInitiatives = useMemo(() => {
    if (data == null) {
      return [];
    }

    if (searchKeyword == null || searchKeyword.trim().length === 0) {
      return data;
    }

    return data.filter((initiative) =>
      initiative.name.toLowerCase().includes(searchKeyword.trim().toLowerCase())
    );
  }, [data, searchKeyword]);

  const handleOnChangeSearch = (e) => {
    const keyword = e.target.value;

    setSearchKeyword(keyword);
  };

  return (
    <div style={styles.container}>
      <InitiativesSideBar />
      <div style={styles.body}>
        <div style={styles.subHeader}>
          <div style={styles.createInitiativesContainer}>
            <Text type="title" color="navy">
              Initiatives
            </Text>
            <Button type="button" onClick={onCreateInitiative}>
              Create Initiative
            </Button>
          </div>
          <div style={styles.searchContainer}>
            <SearchInput
              value={searchKeyword}
              handleSearch={handleOnChangeSearch}
              containerStyle={styles.searchInput}
            />
          </div>
        </div>

        <div style={styles.initiativesList}>
          {loading && <SpinnerOrError />}
          {error && <SpinnerOrError error="An error occurred loading the initiatives" />}
          {!loading &&
            data != null &&
            displayedInitiatives.map((initiative) => {
              return <InitiativesListCard key={initiative.id} initiative={initiative} />;
            })}

          {!loading && !displayedInitiatives.length && (
            <div style={styles.emptyState}>
              <Text type="caption">
                No Initiatives found. Click Create Initiative button to create your first one.
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InitiativesListView;

const styles = {
  container: {
    ...layout.container,
    alignItems: 'flex-start',
    flexDirection: 'row',
    height: '100%',
    backgroundColor: '#F6F7FA',
    paddingTop: 15,
    paddingRight: 25,
    gap: 20,
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 21,
    height: '100%',
  },
  subHeader: {
    width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    padding: '0px, 0px, 0px, 10px',
    justifyContent: 'space-between',
  },
  createInitiativesContainer: {
    flex: '1 1 50%',
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  searchContainer: {
    flex: '0',
    minWidth: 350,
  },
  searchInput: {
    backgroundColor: input.default,
  },
  initiativesList: {
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  emptyState: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

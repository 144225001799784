import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { setShowNotification } from '../../@global-state/redux/notificationSlice';

const NotificationModal = ({ hide, notification }) => {
  if (!notification) {
    return null;
  }
  return (
    <Modal show onHide={hide}>
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-5">{notification}</Modal.Body>
    </Modal>
  );
};

NotificationModal.propTypes = {
  hide: PropTypes.func.isRequired,
  notification: PropTypes.string,
};

NotificationModal.defaultProps = {
  notification: undefined,
};

const mapState = (state) => ({
  notification: state.notification.notificationBody,
});

const mapDispatch = (dispatch) => ({
  hide: () => dispatch(setShowNotification(undefined)),
});

export default connect(mapState, mapDispatch)(NotificationModal);

import { Text, TooltipComponent, Icon } from '@intus-ui';
import { textColors } from '@intus-ui/styles/SecondaryColors';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { widgetSizes } from '@intus-ui/components/Widget/config';
import BarChart from './BarChart';

export function getStatWidgetSize(metricConfig) {
  if (metricConfig?.showChart) {
    return widgetSizes.large;
  }

  return widgetSizes.small;
}

export const StatWidget = ({ metric, metricData }) => {
  const { title, tooltip, data, subtext } = metricData?.data || {};
  const textColor = data?.color === 'danger' ? 'danger' : 'body';
  const showArrowUpwardIcon = data?.changeDirection === 'increase';
  const showArrowDownwardIcon = data?.changeDirection === 'decrease';

  let fontSize = 72;
  const fontLength = data?.value?.toString().length ?? 0;
  if (fontLength >= 4) {
    fontSize = 64;
  }
  if (fontLength >= 6) {
    fontSize = 52;
  }
  if (fontLength >= 8) {
    fontSize = 40;
  }

  return (
    <div style={styles.wrapper}>
      {metric.metricConfig?.showChart ? (
        <BarChart metricConfig={metric.metricConfig} metricData={metricData} isTimeAxis />
      ) : (
        <div style={styles.statContainer}>
          {tooltip?.tooltipText && (
            <div style={styles.tooltip}>
              <Text type="subtitle">{title}</Text>
              <TooltipComponent
                anchorId="test-tooltip"
                content={<Text>{tooltip?.tooltipText}</Text>}
                position="top"
              >
                <Icon
                  size="small"
                  id="info-tooltip"
                  name="Info"
                  color="#052d8f"
                  hoverColor="#052d8f"
                />
              </TooltipComponent>
            </div>
          )}
          <div style={styles.statContent}>
            {showArrowUpwardIcon && (
              <ArrowUpwardIcon style={{ fontSize: `4.5em`, color: textColors[textColor] }} />
            )}

            {showArrowDownwardIcon && (
              <ArrowDownwardIcon style={{ fontSize: `4.5em`, color: textColors[textColor] }} />
            )}

            <Text type="display" color={textColor} style={{ fontSize }}>
              {data?.value}
            </Text>
          </div>
          {subtext && (
            <Text type="body" style={{ textAlign: 'center' }}>
              {subtext}
            </Text>
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  statContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    width: '100%',
  },
  tooltip: {
    display: 'flex',
    gap: 5,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

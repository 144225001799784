import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, Icon } from '@intus-ui';

import '@intus-ui/styles/Settings.scss';

const SettingsBox = ({ children, title, cancel, adminAccess, editButton }) => {
  const [editable, setEditable] = useState(!editButton);

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-end py-2">
        <h4 className="text-dark font-weight-normal p-2">{title}</h4>
        {editButton && adminAccess && editable && (
          <Button
            textOnly
            onClick={() => {
              cancel();
              setEditable(!editable);
            }}
          >
            <Icon name="remove" color="#ea4040" />
          </Button>
        )}
        {editButton && adminAccess && !editable && (
          <Button
            secondary
            onClick={() => {
              setEditable(!editable);
            }}
          >
            <Icon name="edit" />
            Edit
          </Button>
        )}
      </div>
      <div
        className={[
          'border rounded-corners shadow-sm p-3 bg-white h-100 mb-5',
          editable ? `${editButton ? 'blue-border' : 'box-shadow'}` : '',
        ].join(' ')}
      >
        <div className="p-3">{children({ editable, setEditable })}</div>
      </div>
    </div>
  );
};

SettingsBox.propTypes = {
  title: PropTypes.string.isRequired,
  cancel: PropTypes.func,
  children: PropTypes.func,
  adminAccess: PropTypes.bool,
  editButton: PropTypes.bool,
};

SettingsBox.defaultProps = {
  children: () => {},
  cancel: () => {},
  adminAccess: true,
  editButton: true,
};

const Buttons = ({ submit, cancel, disabled, errorMessage, submitText }) => (
  <ButtonGroup>
    {errorMessage && <h6 className="pr-3 text-danger">{errorMessage}</h6>}
    <Button secondary onClick={cancel}>
      Cancel
    </Button>
    <Button onClick={submit} disabled={disabled}>
      {submitText}
    </Button>
  </ButtonGroup>
);

Buttons.propTypes = {
  submit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Buttons.defaultProps = {
  disabled: false,
  errorMessage: '',
  submitText: 'Save Changes',
};

SettingsBox.Buttons = Buttons;
export default SettingsBox;

import { Text, Icon, Input } from '@intus-ui';

import { filterRowSchemas } from '../../filterRowConfig';

const DeleteFilterRowButton = ({ handleDeleteFilter }) => (
  <div style={styles.deleteFilterRowButton}>
    <Icon
      name="Remove"
      color="#9D9D9D"
      height="13px"
      width="13px"
      hoverColor="#052d8f"
      onClick={handleDeleteFilter}
    />
  </div>
);

const FilterInputText = ({
  isSingleFilterRow,
  type,
  filter,
  parentIndex,
  handleDeleteFilter,
  operators,
  operator,
  handleChangeOperator,
  isFirst,
  isSecond,
  searchTerm,
  handleChangeSearchTerm,
}) => {
  const placeholder =
    filterRowSchemas(type)[type]?.components?.FilterInputText?.placeholder || 'Enter search string';

  return (
    <div style={styles.thirdFilterRow}>
      {!isSingleFilterRow && (
        <div id="secondFilterRowSpacer" style={styles.filterRowSpacer}>
          {isSecond && (
            <Input
              id="operator"
              name="operator"
              type="select"
              rightIcon="caret-down"
              options={operators.reverse()}
              value={operator}
              onChange={handleChangeOperator}
              style={{ width: 90 }}
            />
          )}
          {!isFirst && !isSecond && (
            <div style={{ paddingLeft: 5 }}>
              <Text type="body">{operator}</Text>
            </div>
          )}
        </div>
      )}

      <input
        type="text"
        className="hover-input-light-blue"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => handleChangeSearchTerm(e.target.value)}
        style={{
          height: 35,
          width: 325,
          paddingLeft: 15,
          backgroundColor: 'white',
          border: '1px solid #9D9D9D',
          borderRadius: 5,
        }}
      />
      {filter.groups[parentIndex].conditions.length > 1 && (
        <DeleteFilterRowButton handleDeleteFilter={handleDeleteFilter} />
      )}
    </div>
  );
};

const styles = {
  filterRowWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  radioButtonWrapper: {
    display: 'flex',
    gap: 10,
  },
  secondFilterRow: {
    display: 'flex',
  },
  filterRowSpacer: {
    width: 100,
  },
  thirdFilterRow: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  deleteFilterRowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
export default FilterInputText;

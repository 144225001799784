/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { getLogger } from '@util/logger';
import { getSessionUser, setSessionUser, clearSession } from '@util/session';

import {
  updateUserAPI,
  getAllHomePageBoxes,
  getAllJobTypes,
  getFacilitiesByOrg,
  getTeamsByOrg,
} from '@api/api';
import { setShowNotification } from '@global-state/redux/notificationSlice';
import { setDefaultTeam, setDefaultFacility } from '@global-state/redux/filtersSlice';
import { getIndicators } from '@global-state/redux/dashboardSlice';

import { NAV_BAR_ITEM_NAME } from '../../Settings/userSettingsPropType';

const log = getLogger('userSettingsSlice');

const userSettingsSlice = createSlice({
  name: 'userSettingsSlice',
  initialState: {
    profile: undefined,
    subscriptions: undefined,
    homePageBox: undefined,
    jobTypes: undefined,
    teams: undefined,
    facilities: undefined,
    navigation: undefined,
  },
  reducers: {
    // This is the information the server sends when the user logs in.
    initializeUserSettings(state, { payload }) {
      state.profile = {
        id: payload.id,
        firstName: payload.firstName,
        lastName: payload.lastName,
        phoneNumber: payload.phoneNumber,
        email: payload.email,
        access: payload.access,
        organizationId: payload.organizationId,
        jobType: payload.jobType,
        setting: {
          team: payload.setting?.team,
          facility: payload.setting?.facility,
          homeBoxes: payload.setting?.homeBoxes,
          segmentationView: payload.setting?.segmentationView,
          welcomeFlowCompleted: payload.setting?.welcomeFlowCompleted,
        },
      };

      state.subscriptions = payload.organization.subscriptions;
    },
    setHomePageBoxes(state, action) {
      state.homePageBox = action.payload;
    },

    setJobTypes(state, action) {
      state.jobTypes = action.payload;
    },

    setTeams(state, action) {
      state.teams = action.payload;
    },

    setFacilities(state, action) {
      state.facilities = action.payload;
    },

    setProfile(state, action) {
      state.profile = {
        ...(state.profile || {}),
        ...action.payload,
      };
    },

    resetProfile(state) {
      state.profile = undefined;
    },

    setMostRecentNavbarItem: (state, action = { payload: NAV_BAR_ITEM_NAME.GENERAL }) => {
      state.navigation = action.payload;
    },
  },
});

export default userSettingsSlice.reducer;

export const {
  setProfile,
  setHomePageBoxes,
  initializeUserSettings,
  setJobTypes,
  setTeams,
  setFacilities,
  resetProfile,
  setMostRecentNavbarItem,
} = userSettingsSlice.actions;

export const getUserInfo =
  (setFilters = false) =>
  async (dispatch) => {
    const user = getSessionUser();
    dispatch(getIndicators());
    if (setFilters) {
      // The or case of all default should only be hit on first login for welcome flow, otherwise should never be null
      dispatch(setDefaultTeam(user.setting?.team || 'All'));
      dispatch(setDefaultFacility(user.setting?.facility || 'All'));
    }
    dispatch(setProfile(user));
  };

export const getHomePageBox = () => async (dispatch) => {
  try {
    const res = await getAllHomePageBoxes();
    if (res.ok) {
      const json = await res.json();
      dispatch(setHomePageBoxes(json));
    } else {
      log.error('error fetching homepage boxes');
    }
  } catch (error) {
    log.error(error);
  }
};

export const getJobTypes = () => async (dispatch) => {
  try {
    const res = await getAllJobTypes();
    if (res.ok) {
      const json = await res.json();
      dispatch(setJobTypes(json));
    } else {
      log.error('error fetching job types');
    }
  } catch (error) {
    log.error(error);
  }
};

export const updateUserSettings = (body) => async (dispatch) => {
  try {
    const response = await updateUserAPI(body, body.id);

    // Checks for any user related errors
    const is400Error = response.status >= 400 && response.status <= 499;
    if (is400Error) {
      // This means the token has expired. Kick them out!
      clearSession();
    } else if (response.ok) {
      const json = await response.json();
      setSessionUser(json);
      dispatch(setProfile(json));
    } else {
      dispatch(
        setShowNotification(
          'The user information could not be updated. Please refresh the page and try again.'
        )
      );
    }
  } catch (err) {
    log.error(err);
  }
};

export const getTeams = (id) => async (dispatch) => {
  try {
    const res = await getTeamsByOrg(id);
    if (res.ok) {
      const resJ = await res.json();
      dispatch(setTeams(resJ));
    } else {
      log.error('error fetching teams');
    }
  } catch (error) {
    log.error(error);
  }
};

export const getFacilities = (id) => async (dispatch) => {
  try {
    const res = await getFacilitiesByOrg(id);
    if (res.ok) {
      const resJ = await res.json();
      dispatch(setFacilities(resJ));
    } else {
      log.error('error fetching facilities');
    }
  } catch (error) {
    log.error(error);
  }
};

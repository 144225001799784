import { useFeatureFlag } from '@util';
import { formatDate } from '@util/dateFunctions';

import { Icon, Text, Modal, MedicationsList } from '@intus-ui';

import { EHR_TRUCHART } from 'Settings/userSettingsPropType';
import { useGetSessionUser } from '@util/session';
import { useState } from 'react';
import { hrLineStyle } from './styles';

export const FallsIncidentCard = ({ incident }) => {
  const { location, harmType, notes, date, time, activeMedications } = incident;

  const [medicationModalIsOpen, setMedicationModalIsOpen] = useState(false);

  // ENABLE MEDICATIONS FOR TRUCHART ONLY
  const { featureIsActive: medicationsFlagIsActive } = useFeatureFlag('medications');
  const {
    organization: { subscriptions },
  } = useGetSessionUser();
  const isTruChartSubscriber = subscriptions.includes(EHR_TRUCHART);
  let medicationsIsActive = false;
  if (isTruChartSubscriber && medicationsFlagIsActive) {
    medicationsIsActive = true;
  }

  return (
    <div>
      {medicationsIsActive && (
        <Modal
          header={{
            title: `Medications at time of fall on ${incident.date}`,
            centered: true,
          }}
          type="large"
          open={medicationModalIsOpen}
          onClose={() => setMedicationModalIsOpen(false)}
        >
          <MedicationsList data={activeMedications} />
        </Modal>
      )}
      <div style={styles.groupHeaderContainer}>
        <Text>{date}</Text>
        <hr style={hrLineStyle} />
      </div>

      <div style={styles.cardStyle}>
        <Text type="subtitle">{harmType} Fall</Text>

        <div style={styles.listItem}>
          <Icon name="calendar" style={styles.listIcon} />

          <Text style={styles.listText}>
            {date}
            {time && `, ${time}`}
          </Text>
        </div>

        <div style={styles.listItem}>
          <Icon name="location" style={styles.listIcon} />
          <Text style={styles.listText}>{location}</Text>
        </div>

        <div style={styles.listItem}>
          <Icon name="note" style={styles.listIcon} />
          <Text style={styles.listText}>{notes}</Text>
        </div>

        {medicationsIsActive && (
          <div style={{ ...styles.listItem, justifyContent: 'space-between' }}>
            <div>
              <Icon name="pill-bottle" style={styles.listIcon} />
              <Text style={styles.listText}>{`${activeMedications.length} meds`}</Text>
            </div>
            <div>
              {activeMedications.length > 0 && (
                <Text onClick={() => setMedicationModalIsOpen(true)} type="subtitle" color="link">
                  {`See ${formatDate(date, 'MM/DD/YY')} Meds`}
                  <Icon
                    onClick={() => setMedicationModalIsOpen(true)}
                    name="ArrowRight"
                    color="link"
                    size="small"
                  />
                </Text>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  groupHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'between',
    alignItems: 'center',
    marginBottom: 10,
  },
  cardStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    border: '1px solid #9D9D9D',
    borderRadius: 5,
    padding: '10px 15px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'start',
  },
  listIcon: {
    color: '#97999b',
    width: 22,
    height: 22,
  },
  listText: {
    marginLeft: 10,
    lineHeight: '19.5px',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
};

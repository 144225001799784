export const allStarsMeasures = [
  'D08_DiabetesMedicationAdherence',
  'D09_RASAMedicationAdherence',
  'D10_StatinsMedicationAdherence',
  'MIPS_PrimaryPreventionStatinMedicationAdherence',
] as const;

export type IAllStarsMeasures = (typeof allStarsMeasures)[number];

export const starsMeasureToName: Record<IAllStarsMeasures, string> = {
  D08_DiabetesMedicationAdherence: 'Diabetes Medications',
  D09_RASAMedicationAdherence: 'Hypertension (RAS Antagonists)',
  D10_StatinsMedicationAdherence: 'Cholesterol (Statins)',
  MIPS_PrimaryPreventionStatinMedicationAdherence: 'Statins for primary prevention',
};

// This is the list of years we have implemented the STARS measures for.
// We need to manually add the list of NDCs for each measure every year and make sure the technical spec has not changed.
export const starsMeasuresPossibleYears = [2023, 2024];

import { useEffect } from 'react';
import { getPolypharmacyTopPrescribed } from '@api';
import { useLazyQuery } from '@api/useQuery';
import { List, SpinnerOrError, Text } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';

const columnConfig = {
  default: {
    columns: ['medicationName', 'fillCount', 'patientCount'],
    breakpoints: [
      {
        minWidth: 700,
        columns: ['medicationName', 'fillCount', 'patientCount'],
      },
      {
        minWidth: 500,
        columns: ['medicationName', 'fillCount', 'patientCount'],
      },
    ],
  },
};

const format = [
  {
    field: 'medicationName',
    name: 'Medication Name',
    flex: 2,
    align: 'start',
    addOns: [{ type: 'sort', element: <SortIcon key="medicationName" id="medicationName" /> }],
  },
  {
    field: 'fillCount',
    name: 'Claims Count',
    flex: 2,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="fillCount" id="fillCount" /> }],
  },
  {
    field: 'patientCount',
    name: 'Patient Count',
    flex: 2,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="patientCount" id="patientCount" /> }],
  },
];

const Table = ({ filters }) => {
  const { data, loading, error, runQuery } = useLazyQuery(() =>
    getPolypharmacyTopPrescribed(filters)
  );

  useEffect(() => {
    runQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  if (error) {
    return <SpinnerOrError error={error} />;
  }

  return (
    <List
      data={data?.medications || []}
      loading={loading}
      format={format}
      columnConfig={columnConfig.default}
      listStyle="striped"
      emptyListMessage="No data."
      defaultSort={{ field: 'fillCount', direction: 'desc' }}
    />
  );
};

const TopPrescribed = ({ filters }) => {
  return (
    <div style={{ padding: 10 }}>
      <Text type="headline" style={{ marginBottom: 10 }}>
        Top Prescribed Medications
      </Text>
      <Table filters={filters} />
    </div>
  );
};

export default TopPrescribed;

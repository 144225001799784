import React from 'react';
import { useHover } from '@intus-ui/hooks';
import { Text, layout, Icon } from '@intus-ui';
import { riskColors } from '@intus-ui/styles/SecondaryColors';

const ChangeEventCard = ({ event, summary }) => {
  const { title, current, previous, type, keywords, date } = event;
  const [hoverRef, isHovered] = useHover();
  const [hoverSubCard, subCardIsHovered] = useHover();
  const isProgressNote = type === 'progressNote';

  // warning display logic
  const hasWarning = current?.severity === 'high';
  const colorOnHover = hasWarning && isHovered ? riskColors.highest : '#C8C8C8';

  let infosOrWarningIcon = { name: 'Bullet', color: '#FFFFFF', hoverColor: '#FFFFFF' };
  if (hasWarning)
    infosOrWarningIcon = { name: 'Alert', color: riskColors.highest, hoverColor: 'red' };

  if (!hasWarning && subCardIsHovered)
    infosOrWarningIcon = { name: 'Info', color: riskColors.moderate, hoverColor: '#052d8f ' };
  if (summary) {
    return (
      <div ref={hoverSubCard} style={{ ...styles.container, borderColor: colorOnHover }}>
        <div style={styles.title}>
          <Text type="caption-bold">{title}</Text>
          <div ref={hoverRef}>
            <Icon
              name={infosOrWarningIcon.name}
              color={infosOrWarningIcon.color}
              hoverColor={infosOrWarningIcon.hoverColor}
              // eslint-disable-next-line no-console
              onClick={() => console.log('Open CRI Threshold Modal')}
            />
          </div>
        </div>
        <div style={styles.content}>
          {isProgressNote ? (
            <div>
              <Text type="caption-bold">Keywords: </Text>
              <Text type="caption">{keywords.join(' | ')}</Text>
            </div>
          ) : (
            <>
              <Text type="caption">Current: {current?.value}</Text>
              <Text type="caption">Previous: {previous?.value}</Text>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div ref={hoverSubCard} style={{ ...styles.container, borderColor: colorOnHover }}>
      {isProgressNote ? (
        <div style={{ display: 'flex', gap: 5 }}>
          <Text type="caption">{date.latest}</Text>
          <Text type="caption-bold">{title}</Text>
          <Text type="caption">{keywords.join(' | ')}</Text>
        </div>
      ) : (
        <div style={{ display: 'flex', gap: 5 }}>
          <Text type="caption">{current.date}</Text>
          <Text type="caption-bold">{title}</Text>
          <Text type="caption">Current: {current?.value}</Text>
          <Text type="caption">Previous: {previous?.value}</Text>
          <Text type="caption">Severity: {previous?.severity}</Text>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #C8C8C8',
    borderRadius: 6,
    padding: 5,
    gap: 5,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
  },
  content: { ...layout.container, overflowY: 'auto', gap: 2 },
};
export default ChangeEventCard;

import { Settings24Regular } from '@fluentui/react-icons';
import { Button } from '@fluentui/react-components';
import React from 'react';
import styles from './SettingsButton.module.css';

interface Props {
  className?: string;
  onClick: () => void;
}

export const SettingsButton = ({ className, onClick }: Props) => {
  return (
    <div className={`${styles.container} ${className ?? ''}`}>
      <Button icon={<Settings24Regular />} onClick={onClick}>
        Advanced settings
      </Button>
    </div>
  );
};

import { getStringReplacement } from '@util/stringReplacements';
import { Button, Text, Icon } from '@intus-ui';
import TooltipComponent from '@intus-ui/components/TooltipComponent';

export const renderTitleElement = (data, title = getStringReplacement('Participant', {plural: true})) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      <Text type="headline" color="body" style={{ fontSize: '18px', fontWeight: '700' }}>
        {title}
      </Text>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          alignItems: 'center',
        }}
      >
        <Icon name="people" color="caption" />
        <Text color="caption">{data?.length}</Text>
      </div>
    </div>
  );
};

export const renderAddButton = (rowData, handleAddPpt) => {
  return (
    <Button secondary onClick={() => handleAddPpt(rowData)}>
      <Icon name="add" />
    </Button>
  );
};

export const renderRemoveButton = (rowData, handleRemovePpt) => {
  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', minWidth: '70px' }}>
      <Button secondary onClick={() => handleRemovePpt(rowData)}>
        <Icon name="remove" />
      </Button>
    </div>
  );
};

export const appendNameToData = (data) => {
  if (data)
    return data?.map((item) => {
      const newItem = { name: `${item.firstName} ${item.lastName}`, ...item };
      return newItem;
    });
  return [];
};

export const renderSharedCheckbox = (
  checkboxComponent,
  isSharedCheckboxEnabled,
  tooltipMessage
) => {
  if (!isSharedCheckboxEnabled) {
    return (
      <TooltipComponent anchorId="sharedCheckbox" content={tooltipMessage}>
        {checkboxComponent}
      </TooltipComponent>
    );
  }
  return checkboxComponent;
};

/**
 * Returns true if we're creating a cohort from an initiative and need
 * to navigate back to the initiative page after creating the cohort.
 */
export function isCreatingCohortFromInitiative() {
  return sessionStorage.getItem('initiativeFormValues') != null;
}

export function returnToInitiativeFromCohortCreation(history, cohortToSave) {
  const initiativeFormValues = JSON.parse(sessionStorage.getItem('initiativeFormValues'));
  if (initiativeFormValues.initiativeId) {
    // Editing an existing initiative
    history.replace(
      `/initiatives/${initiativeFormValues.initiativeId}/trials/${initiativeFormValues.id}/edit?cohortId=${cohortToSave.id}`
    );
  } else {
    // Creating a new initiative
    history.replace(`/initiatives/create?cohortId=${cohortToSave.id}`);
  }
}

import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { sortByDate } from '@util/utilFunctions';
import { getFilledInIcdCodes } from '@util/icdCodes';

import { getLogger } from '@util/logger';

import { SidebarwithTabs } from '@intus-ui';
import FallsTab from './FallsTab';
import AnalyticsTab from './AnalyticsTab';

const log = getLogger('FallsDashboard');

const getAcuityIcdCodes = (patient) => {
  const sortedAcuityLevels = sortByDate(patient.acuityLevels, 'createdAt', 'desc');
  return _.get(sortedAcuityLevels, '[0].icdCodes', []);
};

const FallAnalysisSidebar = ({ fallData, timeRange, sortedData, currentMedications }) => {
  const [codes, setCodes] = useState([]);

  const patient = useSelector((state) => state.patientDetail.patient);

  const icdCodes = getAcuityIcdCodes(patient);

  useEffect(() => {
    /**
     * retrieves a list of labels based on a list of icd10 codes
     * @param {String[]} icdCodes - the list of codes to fetch
     */

    const getIcdCodeLabels = async (acuityCodes) => {
      try {
        let resultCodes = await getFilledInIcdCodes(acuityCodes);
        resultCodes = resultCodes.filter((code) => code.fallsRiskFactors !== null);
        setCodes(resultCodes);
      } catch (e) {
        log.error(e);
      }
    };

    if (icdCodes.length) {
      getIcdCodeLabels(icdCodes);
    }

    return () => {
      setCodes([]);
    };
  }, [icdCodes]);

  const fallsTabs = [
    {
      tab: 'Analytics',
      content: (
        <AnalyticsTab
          fallData={fallData}
          sortedData={sortedData}
          timeFrame={timeRange}
          codes={codes}
          currentMedications={currentMedications}
        />
      ),
    },
    { tab: 'Falls', content: <FallsTab incidents={fallData} /> },
  ];

  return <SidebarwithTabs items={fallsTabs} />;
};

export default FallAnalysisSidebar;

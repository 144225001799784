import { useEffect } from 'react';
import { getPolypharmacyPatients } from '@api';
import { useLazyQuery } from '@api/useQuery';
import { List, SpinnerOrError, Text } from '@intus-ui';
import SortIcon from '@intus-ui/components/List/SortIcon';
import { useHistory } from 'react-router';

const columnConfig = {
  default: {
    columns: [
      'name',
      'memberId',
      'age',
      'gender',
      'frequentFaller',
      'advancedIllness',
      'emergentVisit',
      'inpatient',
      'uniqueNumClaims',
      'statins',
      'hypertensives',
      // 'insulin',
      'antipsychotics',
      'anticoagulation',
      'sulfonylurea',
      // 'numActiveMedications',
    ],
    breakpoints: [
      {
        minWidth: 700,
        columns: [
          'name',
          'memberId',
          'age',
          'gender',
          'frequentFaller',
          'advancedIllness',
          'emergentVisit',
          'inpatient',
          'uniqueNumClaims',
          'statins',
          'hypertensives',
          'oralAntiDiabetes',
          // 'insulin',
          'antipsychotics',
          'anticoagulation',
          'sulfonylurea',
          // 'numActiveMedications',
        ],
      },
      {
        minWidth: 500,
        columns: ['name', 'statins', 'oralAntiDiabetes', 'antipsychotics'],
      },
    ],
  },
};

const format = [
  {
    field: 'name',
    name: 'Patient Full Name',
    flex: 0.7,
    align: 'start',
    customStyles: {
      width: '500px',
    },
    addOns: [{ type: 'sort', element: <SortIcon key="name" id="name" /> }],
  },
  {
    field: 'memberId',
    name: 'Member ID',
    flex: 1,
    align: 'center',
  },
  {
    field: 'uniqueNumClaims',
    name: '# Medications',
    flex: 0.6,
    align: 'end',
    customStyles: {
      width: '50px',
    },
    addOns: [{ type: 'sort', element: <SortIcon key="uniqueNumClaims" id="uniqueNumClaims" /> }],
  },
  {
    field: 'statins',
    name: '# Statins',
    flex: 0.6,
    align: 'end',
    customStyles: {
      width: '80px',
    },
    addOns: [{ type: 'sort', element: <SortIcon key="statins" id="statins" /> }],
  },
  {
    field: 'hypertensives',
    name: '# Hypertensives',
    flex: 0.7,
    align: 'end',
    customStyles: {
      width: '500px',
    },
    addOns: [{ type: 'sort', element: <SortIcon key="hypertensives" id="hypertensives" /> }],
  },
  {
    field: 'antipsychotics',
    name: '# Antipsychotics',
    flex: 0.7,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="antipsychotics" id="antipsychotics" /> }],
  },
  {
    field: 'age',
    name: 'Age',
    flex: 0.2,
    align: 'center',
    addOns: [{ type: 'sort', element: <SortIcon key="age" id="age" /> }],
  },
  {
    field: 'gender',
    name: 'Gender',
    flex: 0.5,
    align: 'center',
    addOns: [{ type: 'sort', element: <SortIcon key="gender" id="gender" /> }],
  },
  {
    field: 'frequentFaller',
    name: 'Frequent Faller Dx',
    flex: 0.8,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="frequentFaller" id="frequentFaller" /> }],
  },

  {
    field: 'advancedIllness',
    name: 'Advanced Illness Dx',
    flex: 0.8,
    align: 'end',
    customStyles: {
      width: '90px',
    },
    addOns: [{ type: 'sort', element: <SortIcon key="advancedIllness" id="advancedIllness" /> }],
  },
  {
    field: 'emergentVisit',
    name: '# ED Visits',
    flex: 0.5,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="emergentVisit" id="emergentVisit" /> }],
  },
  {
    field: 'inpatient',
    name: '# Inpatient',
    flex: 0.5,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="inpatient" id="inpatient" /> }],
  },
  {
    field: 'numActiveMedications',
    name: '# Active Medications',
    flex: 1,
    align: 'end',
    addOns: [
      { type: 'sort', element: <SortIcon key="numActiveMedications" id="numActiveMedications" /> },
    ],
  },
  {
    field: 'anticoagulation',
    name: '# Anticoagulation',
    flex: 0.7,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="anticoagulation" id="anticoagulation" /> }],
  },
  {
    field: 'sulfonylurea',
    name: '# Sulfonylurea',
    flex: 0.6,
    align: 'end',
    addOns: [{ type: 'sort', element: <SortIcon key="sulfonylurea" id="sulfonylurea" /> }],
  },
];

const Table = ({ filters }) => {
  const history = useHistory();

  const { data, loading, error, runQuery } = useLazyQuery(() =>
    getPolypharmacyPatients(filters, true)
  );

  useEffect(() => {
    runQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  if (loading) {
    return <SpinnerOrError />;
  }

  if (error) {
    return <SpinnerOrError error={error} />;
  }

  return (
    <div style={{ width: 'fit-content' }}>
      <List
        data={data}
        loading={loading}
        format={format}
        columnConfig={columnConfig.default}
        listStyle="striped"
        emptyListMessage="No data."
        defaultSort={{ field: 'uniqueNumClaims', direction: 'desc' }}
        customStyle={{ minWidth: '80%' }}
        onClickItem={(ppt) => {
          history.push(`/patient/${ppt.id}/current-acuity`);
        }}
        clickable
      />
    </div>
  );
};

const Patients = ({ filters }) => {
  return (
    <div style={{ padding: 10 }}>
      <Text type="headline" style={{ marginBottom: 10 }}>
        Polypharmacy Patients (10+ Active Medications)
      </Text>
      <Table filters={filters} />
    </div>
  );
};

export default Patients;

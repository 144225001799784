import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import OrganizationList from 'Admin/admin/OrganizationList';
import { getOrganizations } from 'Admin/UserTracking/AdminSlice';
import OrganizationListPropType from 'Admin/admin/OrganizationListPropType';
import CenteredSpinner from '@intus-ui/components/CenteredSpinner';
import Centered from '@intus-ui/components/Centered';
import AddOrganizationModal from 'Admin/admin/AddOrganizationModal';
import { getSessionUser } from '@util/session';

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      currOrg: undefined,
    };
  }

  componentDidMount() {
    const { doGetOrgs } = this.props;
    doGetOrgs();
  }

  componentDidUpdate() {
    // for displaying the correct name of the current org on initial render
    // (once the orgs are fetched we will be able to display the correct current org)
    const { currOrg } = this.state;
    const { orgs } = this.props;
    if (currOrg === undefined && orgs) {
      const currOrgId = getSessionUser().organizationId;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currOrg: orgs.filter((item) => item.id === currOrgId)[0].name });
    } else if (currOrg !== undefined && orgs) {
      const activeOrgs = orgs.filter(({ status }) => status === 'ACTIVE').map(({ name }) => name);
      if (!activeOrgs.includes(currOrg) && activeOrgs.length) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ currOrg: activeOrgs[0] });
      }
    }
  }

  render() {
    const { orgs } = this.props;
    const { show, currOrg } = this.state;
    let content;

    if (!orgs) {
      // If organizations array is undefined, shows a loading spinner.
      content = <CenteredSpinner className="d-flex flex-grow-1" />;
    } else if (!orgs.length) {
      content = (
        <Centered className="d-flex flex-grow-1">
          <AddOrganizationModal show={show} hide={() => this.setState({ show: false })} />
          <p>There are no organizations.</p>
          <Button onClick={() => this.setState({ show: true })} className="rounded-lg">
            Add an Organization
          </Button>
        </Centered>
      );
    } else {
      content = <OrganizationList changeOrg={(newOrg) => this.setState({ currOrg: newOrg })} />;
    }
    return (
      <div className="w-100 h-100 d-flex flex-column bg-light justify-content-start align-items-center overflow-hidden">
        <div className="d-flex flex-shrink-1 pt-2">Current Organization: {currOrg}</div>
        <div className="w-100 d-flex flex-grow-1 overflow-hidden">{content}</div>
      </div>
    );
  }
}

Admin.propTypes = {
  doGetOrgs: PropTypes.func.isRequired,
  orgs: PropTypes.arrayOf(OrganizationListPropType.isRequired),
};

Admin.defaultProps = {
  orgs: undefined,
};

const mapState = (state) => ({
  orgs: state.admin.orgs,
});

const mapDispatch = (dispatch) => ({
  doGetOrgs: () => dispatch(getOrganizations()),
});

export default connect(mapState, mapDispatch)(Admin);
